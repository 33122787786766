import EditUserForm from '@components/forms/EditUserForm';
import { EcoCard } from '@components/shared';
import EcoScrollView from '@components/views/EcoScrollView';
import { useQuery } from '@tanstack/react-query';
import { getUser, getUsers } from '@utils/api/user';
import { Gap } from '@utils/layout';
import { isEmpty } from 'lodash';
import React from 'react';
import { View } from 'react-native';
import { BackLink } from '../navigation/BackLink';
import { GlobalScreenProps } from '../navigation/types';

export default function UserScreen({ route }: GlobalScreenProps): JSX.Element {
  const id = decodeURIComponent(route?.params?.id);
  const { data: user, refetch: refetchUser, isLoading } = useQuery(['user', id], () => getUser(id));
  const { refetch: refetchUsers } = useQuery(['users'], getUsers);

  const onSuccess = () => {
    refetchUser();
    refetchUsers();
  };

  return (
    <EcoScrollView>
      <View style={Gap()}>
        <View className="flex-row">
          <BackLink href="/users" label="Back to Users" />
        </View>
        <EcoCard title="Edit User" isLoading={isLoading}>
          {!isEmpty(user) && <EditUserForm user={user} onSuccess={onSuccess} />}
        </EcoCard>
      </View>
    </EcoScrollView>
  );
}
