import { EcoButton, EcoCard, EcoIcon, EcoInput, EcoRadioButtons, EcoText, FormPicker } from '@components/shared';
import { CustomInvoicePayload } from '@ecocart/entities';
import { useOverlay } from '@hooks/useOverlay';
import { useRewardsModal } from '@hooks/useRewardsModal';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createInvoice } from '@utils/api/invoice';
import { getInvoices, getPaymentMethods, hasAtLeastOneValidPaymentMethod } from '@utils/api/payments';
import { Gap } from '@utils/layout';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Pressable, View } from 'react-native';
import DateTimePicker from 'react-native-ui-datepicker';
import * as Yup from 'yup';
import { InvoiceConfirmForm } from './InvoiceConfirmForm';

const ecoDropdownItems = ['Please select', 'EcoCart+', 'Offsets', 'Custom Deal', 'Other (Please specify)'] as const;
dayjs.extend(isSameOrAfter);

export const CustomInvoiceForm = ({ shopName, primaryPaymentMethod }: { shopName: string; primaryPaymentMethod: string }): JSX.Element => {
  const { data: paymentMethods } = useQuery(['getPaymentMethods', shopName], () => getPaymentMethods(shopName));
  const hasAValidPaymentMethod = hasAtLeastOneValidPaymentMethod(paymentMethods);

  const initialValues = {
    amount_usd: '',
    start_date: undefined,
    end_date: undefined,
    due_date: '',
    auto_pay: hasAValidPaymentMethod,
    store_domain: '',
    memo: ''
  };

  const [showDatePickerForStartDate, setShowDatePickerForStartDate] = useState(false);
  const [showDatePickerForEndDate, setShowDatePickerForEndDate] = useState(false);
  const [memoPickerValue, setMemoPickerValue] = useState<(typeof ecoDropdownItems)[number]>(ecoDropdownItems[0]);
  const [showInvoiceConfirmForm, setShowInvoiceConfirmForm] = useState(false);
  const { showSuccess, showWarning } = useOverlay();
  const { hideRewardsFormModal } = useRewardsModal();
  const { refetch: refetchHistory } = useQuery(['getInvoices', shopName], () => getInvoices(shopName));

  const { mutate, isLoading: isSubmitting } = useMutation(createInvoice, {
    onSuccess: () => {
      showSuccess('Success! Your invoice has been submitted successfully.');
      refetchHistory();
    },
    onError: (error: Error) => {
      showWarning(`An error occurred while submitting your invoice. ${error.message}`);
    },
    onSettled: () => {
      hideRewardsFormModal();
    }
  });

  const onSubmit = () => {
    if (!shopName) return;
    const { start_date, end_date, amount_usd, auto_pay, memo } = formik.values;
    const payload: CustomInvoicePayload = {
      store_domain: shopName,
      auto_pay,
      amount_usd,
      start_date: start_date ? dayjs(start_date).format('YYYY-MM-DD') : undefined, // using dayjs to format date because the datepicker uses dayjs under the hood
      end_date: start_date ? dayjs(end_date).format('YYYY-MM-DD') : undefined,
      memo
    };

    mutate(payload);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      amount_usd: Yup.number().typeError('Amount must be a number.').min(0, 'Minimum amount is $0').required('Required'),
      start_date: Yup.date().test(
        'must have a start date when end date is specified',
        'Start date is required when start date is specified',
        function (value) {
          const { end_date } = this.parent;
          return end_date ? Boolean(value) : true;
        }
      ),
      end_date: Yup.date()
        .test('must have an end date when start date is specified', 'End date is required when start date is specified', function (value) {
          const { start_date } = this.parent;
          return start_date ? Boolean(value) : true;
        })
        .test('date-is-greater-or-equal', 'End date must not be before the start date', function (value) {
          const { start_date } = this.parent;
          return dayjs(value).isSameOrAfter(dayjs(start_date), 'day');
        }),
      memo: Yup.string().required('Required')
    })
  });

  const { handleSubmit, handleChange, handleBlur, errors, touched, setFieldValue, values, setTouched, validateForm } = formik;

  return (
    <EcoCard className="p-6" size="lg" titleSize="2xl" title={showInvoiceConfirmForm ? 'Confirm Invoice' : 'New Manual Invoice'}>
      <View className="py-4" style={Gap(2)}>
        {showInvoiceConfirmForm ? (
          <InvoiceConfirmForm
            paymentMethods={paymentMethods}
            setShowInvoiceConfirmForm={setShowInvoiceConfirmForm}
            values={values}
            onSubmit={onSubmit}
            primaryPaymentMethod={primaryPaymentMethod}
          />
        ) : (
          <form onSubmit={handleSubmit}>
            <View style={Gap(6)}>
              <View className="flex-row items-center" style={Gap(8)}>
                <View style={Gap(2)} className="flex-1">
                  <View className="flex-row items-center" style={Gap(2)}>
                    <EcoText>Billing Start</EcoText>
                    <Pressable
                      className="text-secondary-500"
                      onPress={() => {
                        setShowDatePickerForEndDate(false);
                        setShowDatePickerForStartDate(true);
                      }}
                    >
                      <EcoIcon name="calendar_month" className="text-secondary-500" />
                    </Pressable>
                  </View>
                  <EcoText>{values.start_date ? dayjs(values.start_date).format('MMM DD, YYYY') : '-'}</EcoText>
                </View>
                <View className="flex-1" style={Gap(2)}>
                  <View className="flex-row items-center" style={Gap(2)}>
                    <EcoText>Billing End</EcoText>
                    <Pressable
                      className="text-secondary-500"
                      onPress={() => {
                        setShowDatePickerForStartDate(false);
                        setShowDatePickerForEndDate(true);
                      }}
                    >
                      <EcoIcon name="calendar_month" className="text-secondary-500" />
                    </Pressable>
                  </View>
                  <EcoText>{values.end_date ? dayjs(values.end_date).format('MMM DD, YYYY') : '-'}</EcoText>
                </View>
              </View>
              {errors.start_date && (
                <EcoText fontWeight="medium" color="danger">
                  {String(errors.start_date)}
                </EcoText>
              )}
              {errors.end_date && (
                <EcoText fontWeight="medium" color="danger">
                  {String(errors.end_date)}
                </EcoText>
              )}
              {showDatePickerForStartDate && (
                <View className="border border-gray-200 rounded-sm p-3">
                  <EcoText fontSize="lg" fontWeight="semibold" className="self-center mb-2">
                    Select Start Date
                  </EcoText>
                  <DateTimePicker
                    calendarTextStyle={{ fontFamily: 'sora-regular' }}
                    selectedTextStyle={{ fontFamily: 'sora-regular' }}
                    headerTextStyle={{ fontFamily: 'sora-regular' }}
                    weekDaysTextStyle={{ fontFamily: 'sora-regular' }}
                    mode="single"
                    date={values.start_date}
                    maxDate={dayjs().add(1, 'day')}
                    onChange={({ date }) => setFieldValue('start_date', date)}
                  />
                  <EcoButton
                    className="self-end"
                    isDisabled={Boolean(errors.start_date)}
                    onPress={() => setShowDatePickerForStartDate(false)}
                    size="sm"
                    variant="outlined"
                  >
                    OK
                  </EcoButton>
                </View>
              )}
              {showDatePickerForEndDate && (
                <View className="border border-gray-200 rounded-sm p-3">
                  <EcoText fontSize="lg" fontWeight="semibold" className="self-center mb-2">
                    Select End Date
                  </EcoText>
                  <DateTimePicker
                    calendarTextStyle={{ fontFamily: 'sora-regular' }}
                    selectedTextStyle={{ fontFamily: 'sora-regular' }}
                    headerTextStyle={{ fontFamily: 'sora-regular' }}
                    weekDaysTextStyle={{ fontFamily: 'sora-regular' }}
                    mode="single"
                    date={values.end_date}
                    maxDate={dayjs().add(1, 'day')}
                    onChange={({ date }) => setFieldValue('end_date', date)}
                  />
                  <EcoButton
                    isDisabled={Boolean(errors.end_date)}
                    className="self-end"
                    onPress={() => setShowDatePickerForEndDate(false)}
                    size="sm"
                    variant="outlined"
                  >
                    OK
                  </EcoButton>
                </View>
              )}

              <EcoInput
                startAdornment="$"
                type="currency"
                label="Invoice Balance"
                value={values.amount_usd}
                onChangeText={(value) => {
                  handleChange('amount_usd')(value);
                }}
                keyboardType="numeric"
                errors={errors}
                touched={touched}
                onBlur={handleBlur('amount')}
                field="amount_usd"
              />
              <View style={Gap(2)}>
                <EcoText>Auto charge this invoice?</EcoText>
                <EcoText fontSize="sm" color="subdued">
                  (Must have at least one valid payment method to enable auto charge)
                </EcoText>
                <EcoRadioButtons
                  className="flex-col mt-2"
                  style={Gap()}
                  value={values.auto_pay}
                  onValueChange={(value: string) => {
                    setFieldValue('auto_pay', value);
                  }}
                  items={[hasAValidPaymentMethod && { value: true, label: 'On' }, { value: false, label: 'Off' }].filter(Boolean)}
                />
              </View>
              <View style={Gap(2)}>
                <FormPicker
                  label="Memo"
                  items={ecoDropdownItems}
                  value={memoPickerValue}
                  onValueChange={(val) => {
                    setFieldValue('memo', val === 'Other (Please specify)' ? '' : val);
                    setMemoPickerValue(val);
                  }}
                  touched={touched}
                  errors={errors}
                  field="memo"
                />
                {memoPickerValue === 'Other (Please specify)' && (
                  <EcoInput
                    label="Memo"
                    placeholder="Memo..."
                    value={values.memo}
                    onChangeText={(value) => setFieldValue('memo', value)}
                    multiline
                    numberOfLines={5}
                    field="memo"
                  />
                )}
              </View>
              <View className="flex-row justify-end" style={Gap()}>
                <EcoButton onPress={hideRewardsFormModal} variant="ghost" colorScheme="danger" size="sm">
                  Cancel
                </EcoButton>
                <EcoButton
                  size="sm"
                  isLoading={isSubmitting}
                  onPress={() => {
                    validateForm().then((errors) => {
                      if (Object.keys(errors).length === 0) {
                        setShowInvoiceConfirmForm(true);
                      } else {
                        // Ensure all fields are touched to show errors
                        setTouched({
                          amount_usd: true,
                          memo: true
                        });
                      }
                    });
                  }}
                >
                  Next
                </EcoButton>
              </View>
            </View>
          </form>
        )}
      </View>
    </EcoCard>
  );
};
