import { EcoText } from '@components/shared';
import { Gap } from '@utils/layout';
import { View } from 'react-native';

interface InternalToolsCardProps {
  componentName: string;
  children: React.ReactNode;
}
export function InternalToolsCard({ componentName, children }: InternalToolsCardProps): JSX.Element {
  return (
    <View className="py-2" style={Gap()}>
      <EcoText fontSize="xl" fontWeight="semibold">
        {componentName}
      </EcoText>
      {children}
    </View>
  );
}
