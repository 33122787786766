import { EcoInput, EcoPill, FormPicker } from '@components/shared';
import { EcoPillVariant, ECO_PILL_VARIANTS } from '@components/shared/EcoPill';
import { Gap } from '@utils/layout';
import { useState } from 'react';
import { View } from 'react-native';
import { ConfigContainer } from '../views/ConfigContainer';
import { DemoContainer } from '../views/DemoContainer';
import { InternalToolsCard } from '../views/InternalToolsCard';

export function DSPill(): JSX.Element {
  const [variant, setVariant] = useState<EcoPillVariant>('primary');
  const [text, setText] = useState('Lorem Ipsum');

  return (
    <InternalToolsCard componentName="EcoPill">
      <DemoContainer>
        {/* Actual demo component */}
        <EcoPill className="w-max" variant={variant}>
          {text}
        </EcoPill>
        {/* End of actual demo component */}
      </DemoContainer>

      <ConfigContainer>
        {/* Configuration component */}
        <View className="flex-row" style={Gap()}>
          <EcoInput className="w-1/2" label="Text" value={text} onChangeText={setText} />
          <FormPicker label="Variant" items={Object.keys(ECO_PILL_VARIANTS)} value={variant} onValueChange={setVariant} />
        </View>
        {/* End of Configuration component */}
      </ConfigContainer>
    </InternalToolsCard>
  );
}
