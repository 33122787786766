import { CashbackPricingMode } from '@ecocart/entities';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { ObjectShape, OptionalObjectSchema } from 'yup/lib/object';

/* ---------------------------------- regex --------------------------------- */

export type RexExpType = 'EMAIL' | 'US_PHONE_NUMBER' | 'NUMBER';

export const REGEX: Record<RexExpType, RegExp> = {
  US_PHONE_NUMBER: new RegExp('^[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}$'),
  EMAIL: new RegExp('^(([^<>()[].,;:s@"]+(.[^<>()[].,;:s@"]+)*)|(".+"))@(([^<>()[].,;:s@"]+.)+[^<>()[].,;:s@"]{2,})$'),
  NUMBER: new RegExp('^[0-9]+$')
};

/* ----------------------------------- Yup ---------------------------------- */

export type ValidationFieldNames =
  | 'password'
  | 'createPassword'
  | 'confirmPassword'
  | 'confirmed'
  | 'email'
  | 'id'
  | 'sku'
  | 'confirmEmailAddress'
  | 'identity'
  | 'userType'
  | 'card_number'
  | 'exp_monthyear'
  | 'cvc'
  | 'cashbackPercentage'
  | 'cashbackFlatRateAmount'
  | 'flatRateCartAmount'
  | 'defaultItemWeightKg'
  | 'merchants'
  | 'elements'
  | 'ecoIncentivesCartValueThreshold'
  | 'ecoIncentivesNumberOfTrees'
  | 'offsetMultiplier'
  | 'ecoRewardsPercentage'
  | 'orderNumber'
  | 'issue'
  | 'issueDetails'
  | 'claimFormUrl'
  | 'resolution';

const cssTest = (cssProperty: string) =>
  Yup.string().test(cssProperty, 'Invalid CSS value', (val) => {
    // if the input is clear, it's still valid bc the fields aren't required
    if (!val) return true;
    return window.CSS.supports(cssProperty, val);
  });

const wrapperStyles = Yup.object().shape({
  backgroundSize: cssTest('background-size'),
  backgroundImage: Yup.string()
    // to extract only the url part from `url('some url')` before validation
    .transform((val) => val.match(/'(.*?)'/)[1])
    .url('Invalid URL'),
  backgroundColor: cssTest('background-color'),
  zIndex: cssTest('z-index'),
  width: cssTest('width'),
  margin: cssTest('margin'),
  position: cssTest('position'),
  display: cssTest('display'),
  justifyContent: cssTest('justify-content'),
  minHeight: cssTest('min-height')
});

const containerStyles = Yup.object().shape({
  maxWidth: cssTest('max-width'),
  paddingLeft: cssTest('padding-left'),
  paddingRight: cssTest('padding-right'),
  paddingTop: cssTest('padding-top'),
  paddingBottom: cssTest('padding-bottom'),
  padding: cssTest('padding'),
  margin: cssTest('margin'),
  backgroundColor: cssTest('background-color'),
  borderWidth: cssTest('border-width'),
  borderColor: cssTest('border-color'),
  borderRadius: cssTest('border-radius'),
  minHeight: cssTest('min-height')
});

const headerStyles = Yup.object().shape({
  color: cssTest('color'),
  fontSize: cssTest('font-size'),
  lineHeight: cssTest('line-height'),
  fontWeight: cssTest('font-weight'),
  fontFamily: cssTest('font-family'),
  textTransform: cssTest('text-transform')
});

const subHeaderStyles = Yup.object().shape({
  color: cssTest('color'),
  fontSize: cssTest('font-size'),
  lineHeight: cssTest('line-height'),
  fontWeight: cssTest('font-weight'),
  fontFamily: cssTest('font-family'),
  textTransform: cssTest('text-transform')
});

const contentStyles = Yup.object().shape({
  flexBasis: cssTest('flex-basis'),
  gap: cssTest('gap')
});

const imgStyles = Yup.object().shape({
  flexBasis: cssTest('flex-basis'),
  // padding top here is used for aspect ratio so it is a percentage
  paddingTop: Yup.string().test('padding-top', 'Must be a percentage', (val) => {
    if (!val) return true;
    return val.includes('%') && val.length > 1;
  })
});

const upsellStyles = Yup.object().shape({
  borderWidth: cssTest('border-width'),
  borderRadius: cssTest('border-radius')
});

const upsellActiveStyles = Yup.object().shape({
  color: cssTest('color'),
  backgroundColor: cssTest('background-color')
});

const socialProofStyles = Yup.object().shape({
  fontFamily: cssTest('font-family'),
  fontSize: cssTest('font-size'),
  lineHeight: cssTest('line-height'),
  fontWeight: cssTest('font-weight'),
  color: cssTest('color'),
  backgroundColor: cssTest('background-color'),
  padding: cssTest('padding'),
  margin: cssTest('margin'),
  width: cssTest('width'),
  zIndex: cssTest('z-index'),
  borderWidth: cssTest('border-width'),
  borderColor: cssTest('border-color'),
  borderRadius: cssTest('border-radius')
});

export const CreateValidationSchema = (validationSchemaReqired: Array<ValidationFieldNames>): OptionalObjectSchema<ObjectShape> => {
  const ValidationObj: Record<ValidationFieldNames, any> = {
    password: Yup.string().trim().required('Enter password'),
    orderNumber: Yup.string().trim().required('Enter order number'),
    issue: Yup.string().trim().required('Choose an issue'),
    issueDetails: Yup.string().trim().required('Enter issue details'),
    resolution: Yup.string().trim().required('Choose a resolution'),
    createPassword: Yup.string()
      .trim()
      .required('Enter password')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.{8,})/,
        'Must contain (8) characters, (1) uppercase, (1) lowercase, (1) number and (1) special character'
      ),
    confirmPassword: Yup.string()
      .required('Re-enter password')
      .oneOf([Yup.ref('createPassword'), ''], 'Passwords must match'),
    email: Yup.string().required('Enter email').email('Must be a valid email').max(255),
    id: Yup.string().required('Enter email').email('Must be a valid email').max(255),
    sku: Yup.string().required('Enter Sku').max(50),
    confirmEmailAddress: Yup.string()
      .required('Re-enter email')
      .oneOf([Yup.ref('email'), ''], 'Emails must match'),
    identity: Yup.string().required('Enter email or phone').max(255),
    userType: Yup.string().required('Select User Type'),
    card_number: Yup.string()
      .transform((val) => val.replace(/\s/g, ''))
      .required('Enter a valid credit card number')
      .max(16),
    exp_monthyear: Yup.string().required('Enter Exp MM/YY').length(5),
    cvc: Yup.string().required('Enter CVC').min(3).max(4),
    confirmed: Yup.string().required('Confirmation is required'),
    cashbackPercentage: Yup.number().when(['cashbackEnabled', 'cashbackPricingMode'], {
      is: (cashbackEnabled: boolean, cashbackPricingMode: CashbackPricingMode) => cashbackEnabled && cashbackPricingMode === 'pct_aov',
      then: Yup.number()
        .required('Cashback Percentage is required')
        .min(0, 'Cashback Percentage must be at least 0')
        .max(1, 'Cashback Percentage must be at most 1')
    }),
    cashbackFlatRateAmount: Yup.number().when(['cashbackEnabled', 'cashbackPricingMode'], {
      is: (cashbackEnabled: boolean, cashbackPricingMode: CashbackPricingMode) => cashbackEnabled && cashbackPricingMode === 'flat_rate',
      then: Yup.number().required('Cashback Flat Rate Amount is required')
    }),
    flatRateCartAmount: Yup.number().required('Amount must be between $1.04 and $5.00').min(1.04).max(5),
    defaultItemWeightKg: Yup.number().required('Amount must be at least 0.001').min(0.001),
    merchants: Yup.object().test('merchant-test', (val) => !isEmpty(val)),
    ecoIncentivesCartValueThreshold: Yup.number().typeError('This field must be a valid number. Amount must be at least 0').min(0),
    ecoIncentivesNumberOfTrees: Yup.number().typeError('This field must be a valid number. Amount must be at least 0').min(0),
    offsetMultiplier: Yup.number()
      .typeError('This field must be a valid number.')
      .min(1, 'Amount must be at least 1.')
      .max(4, 'Amount must not exceed 4.'),
    ecoRewardsPercentage: Yup.number()
      .typeError('This field must be a valid number.')
      .min(0, 'Amount must be at least 0.')
      .max(1, 'Amount must not exceed 1.'),
    elements: Yup.object().shape({
      wrapper: Yup.object().shape({
        styles: wrapperStyles,
        overrides: Yup.object().shape({
          sm: Yup.object().shape({
            styles: wrapperStyles
          }),
          md: Yup.object().shape({
            styles: wrapperStyles
          }),
          lg: Yup.object().shape({
            styles: wrapperStyles
          })
        })
      }),
      container: Yup.object().shape({
        styles: containerStyles,
        overrides: Yup.object().shape({
          sm: Yup.object().shape({
            styles: containerStyles
          }),
          md: Yup.object().shape({
            styles: containerStyles
          }),
          lg: Yup.object().shape({
            styles: containerStyles
          })
        })
      }),
      header: Yup.object().shape({
        styles: headerStyles,
        overrides: Yup.object().shape({
          sm: Yup.object().shape({
            styles: headerStyles
          }),
          md: Yup.object().shape({
            styles: headerStyles
          }),
          lg: Yup.object().shape({
            styles: headerStyles
          })
        })
      }),
      subHeader: Yup.object().shape({
        styles: subHeaderStyles,
        overrides: Yup.object().shape({
          sm: Yup.object().shape({
            styles: subHeaderStyles
          }),
          md: Yup.object().shape({
            styles: subHeaderStyles
          }),
          lg: Yup.object().shape({
            styles: subHeaderStyles
          })
        })
      }),
      content: Yup.object().shape({
        styles: contentStyles,
        overrides: Yup.object().shape({
          md: Yup.object().shape({
            styles: contentStyles
          })
        })
      }),
      cta: Yup.object().shape({
        text: Yup.object({
          en: Yup.string()
        }),
        custom: Yup.object({
          url: Yup.string().url('Invalid URL')
        }),
        styles: Yup.object().shape({
          color: cssTest('color'),
          fontSize: cssTest('font-size'),
          lineHeight: cssTest('line-height'),
          fontWeight: cssTest('font-weight'),
          fontFamily: cssTest('font-family'),
          borderColor: cssTest('border-color'),
          borderRadius: cssTest('border-radius'),
          backgroundColor: cssTest('background-color')
        })
      }),
      cta2: Yup.object().shape({
        text: Yup.object({
          en: Yup.string()
        }),
        custom: Yup.object({
          url: Yup.string().url('Invalid URL')
        }),
        styles: Yup.object().shape({
          color: cssTest('color'),
          fontSize: cssTest('font-size'),
          lineHeight: cssTest('line-height'),
          fontWeight: cssTest('font-weight'),
          fontFamily: cssTest('font-family'),
          borderColor: cssTest('border-color'),
          borderRadius: cssTest('border-radius'),
          backgroundColor: cssTest('background-color')
        })
      }),
      logo: Yup.object().shape({
        custom: Yup.object().shape({ url: Yup.string().url('Invalid URL') })
      }),
      logo2: Yup.object().shape({
        custom: Yup.object().shape({ url: Yup.string().url('Invalid URL') })
      }),
      img: Yup.object().shape({
        styles: imgStyles,
        overrides: Yup.object().shape({
          md: Yup.object().shape({
            styles: imgStyles
          })
        }),
        custom: Yup.object().shape({
          url: Yup.string().url('Invalid URL')
        })
      }),
      upsell: Yup.object().shape({
        styles: upsellStyles,
        custom: Yup.object().shape({
          styles: upsellActiveStyles
        })
      }),
      social_proof: Yup.object().shape({
        styles: socialProofStyles,
        overrides: Yup.object().shape({
          md: Yup.object().shape({
            styles: socialProofStyles
          })
        })
      }),
      amount: Yup.object().shape({
        styles: Yup.object().shape({
          color: cssTest('color'),
          fontSize: cssTest('font-size'),
          lineHeight: cssTest('line-height'),
          fontWeight: cssTest('font-weight'),
          fontFamily: cssTest('font-family')
        })
      }),
      footer: Yup.object().shape({
        styles: Yup.object().shape({
          color: cssTest('color'),
          fontSize: cssTest('font-size'),
          lineHeight: cssTest('line-height'),
          fontWeight: cssTest('font-weight'),
          fontFamily: cssTest('font-family')
        })
      })
    }),
    claimFormUrl: Yup.string().url('Invalid URL')
  };

  const validationSchemaBuilder = () => {
    return (validationSchemaReqired || []).reduce((returnedSchema: any, currentValue) => {
      returnedSchema[currentValue] = ValidationObj[currentValue];
      return returnedSchema;
    }, {});
  };

  return Yup.object(validationSchemaBuilder());
};

export const YEAR_MONTH_MASK = [/\d/, /\d/, '/', /\d/, /\d/];
export const USD_UNDER_10_MASK = ['$', /\d/, '.', /\d/, /\d/];
