import { Contact, EmailTemplateName, FullContact } from '@ecocart/entities';
import { DataQuery, GLOBAL_PUBLIC_CONFIG, createDataQueryString } from '@ecocart/universal-utils';
import { CONTACTS_API, apiCall } from './api';
export interface ChartRequest {
  merchantId: string;
  startDate?: string;
  endDate?: string;
}
export interface ManageSubscriptionParams {
  email: string;
  emailTemplateName: EmailTemplateName;
  merchant: string;
}

export type ManageMerchantSubscriptionParams = Omit<ManageSubscriptionParams, 'email'>;

export const subscribe = ({ email, emailTemplateName, merchant }: ManageSubscriptionParams): Promise<Contact | null> => {
  if (!email || !emailTemplateName || !merchant) return Promise.resolve(null);
  return apiCall<Contact>('PUT', `${CONTACTS_API}/subscribe`, {
    email,
    templateName: emailTemplateName,
    merchant
  });
};

export const unsubscribe = ({ email, emailTemplateName, merchant }: ManageSubscriptionParams): Promise<Contact | null> => {
  if (!email || !emailTemplateName || !merchant) return Promise.resolve(null);
  return apiCall<Contact>(
    'PUT',
    `${CONTACTS_API}/unsubscribe`,
    {
      email,
      templateName: emailTemplateName,
      merchant
    },
    {
      'X-Eco-PubKey': GLOBAL_PUBLIC_CONFIG.X_ECO_PUB_KEY
    }
  );
};

export const subscribeMerchantContacts = ({ emailTemplateName, merchant }: ManageMerchantSubscriptionParams): Promise<Contact | null> => {
  if (!emailTemplateName || !merchant) return Promise.resolve(null);
  return apiCall<Contact>('PUT', `${CONTACTS_API}/subscribe/merchant`, {
    templateName: emailTemplateName,
    merchant
  });
};

export const unsubscribeMerchantContacts = ({ emailTemplateName, merchant }: ManageMerchantSubscriptionParams): Promise<Contact | null> => {
  if (!emailTemplateName || !merchant) return Promise.resolve(null);
  return apiCall<Contact>('PUT', `${CONTACTS_API}/unsubscribe/merchant`, {
    templateName: emailTemplateName,
    merchant
  });
};

export const getContacts = (dataQuery?: DataQuery<Contact>): Promise<FullContact[]> => {
  if (dataQuery?.filters?.length === 0) {
    delete dataQuery.filters;
  }

  return apiCall<FullContact[]>(
    'GET',
    dataQuery ? `${CONTACTS_API}/contact?query=${createDataQueryString(dataQuery)}` : `${CONTACTS_API}/contact`
  );
};
