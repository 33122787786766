import { EcoAvatar, FormPicker } from '@components/shared';
import { useState } from 'react';
import { AVATAR_SIZES, AvatarSize } from '../shared/EcoAvatar';
import { ConfigContainer } from '../views/ConfigContainer';
import { DemoContainer } from '../views/DemoContainer';
import { InternalToolsCard } from '../views/InternalToolsCard';

export function DSAvatar(): JSX.Element {
  const [size, setSize] = useState<AvatarSize>('lg');

  return (
    <InternalToolsCard componentName="EcoAvatar">
      <DemoContainer>
        {/* Actual demo component */}
        <EcoAvatar name="Dane Baker" size={size} />
        {/* End of actual demo component */}
      </DemoContainer>

      <ConfigContainer>
        {/* Configuration component */}
        <FormPicker label="Font Size" items={[...AVATAR_SIZES]} value={size} onValueChange={(val) => setSize(val)} />
        {/* End of Configuration component */}
      </ConfigContainer>
    </InternalToolsCard>
  );
}
