import EditABTestForm from '@components/forms/EditABTestForm';
import EditMerchantWidgetSettingsForm from '@components/forms/EditMerchantWidgetSettingsForm';
import { EcoButton, EcoTab, EcoTabContent, EcoTabs } from '@components/shared';
import EcoScrollView from '@components/views/EcoScrollView';
import { CheckoutWidgetDesigner } from '@components/widgets/CheckoutWidgetDesigner';
import { EcoIncentivesWidgetDesigner } from '@components/widgets/EcoIncentivesWidgetDesigner';
import { EducationTouchpointDesigner } from '@components/widgets/EducationTouchpointDesigner';
import { EnhancedPdpDesigner } from '@components/widgets/EnhancedPdpDesigner';
import { ImpactCounterDesigner } from '@components/widgets/ImpactCounterDesigner';
import { ImpactReceiptDesigner } from '@components/widgets/ImpactReceiptDesigner';
import { PackageProtectionDesigner } from '@components/widgets/PackageProtectionDesigner';
import { PromoWidgetDesigner } from '@components/widgets/PromoWidgetDesigner';
import { SimpleCheckoutWidgetDesigner } from '@components/widgets/SimpleCheckoutDesigner';
import { WidgetABTestStatus } from '@components/widgets/WidgetABTestStatus';
import { WidgetList } from '@components/widgets/WidgetList';
import { Experiment, Merchant, Template, TemplateType } from '@ecocart/entities';
import { TemplateToTypeMap } from '@ecocart/fe-utils';
import { useFeaturePermission } from '@hooks/useFeaturePermission';
import { useOverlay } from '@hooks/useOverlay';
import { BackLink } from '@navigation/BackLink';
import { KebabTemplate, SpaceToKebabCase } from '@navigation/LinkingConfiguration';
import { ScreenName } from '@navigation/StackNavigators';
import { GlobalScreenProps } from '@navigation/types';
import { useNavigation } from '@react-navigation/native';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from '@tanstack/react-query';
import { getMerchant, getMerchantAdmin, updateMerchant } from '@utils/api/merchant';
import { EcoCartApiMerchantTabs, EcoCartGlobalMerchantTabs } from '@utils/constants/tabs';
import { Gap } from '@utils/layout';
import { kebabCase } from 'lodash';
import React, { useEffect } from 'react';
import { ScrollView, View } from 'react-native';

const WIDGET_COMPONENTS = [
  'Global Settings',
  // 'A/B Testing', // TODO Evaluate Drop
  'Impact Counter',
  'Simple Checkout',
  'Checkout Widget',
  'Package Protection', // TODO changed from 'Order Protection'
  'Promo',
  'Eco-Incentives',
  'Eco-Network',
  'Education Touchpoint',
  'Impact Receipt',
  'Enhanced PDP'
] as const;
type WidgetComponent = (typeof WIDGET_COMPONENTS)[number];
type ComponentMap = {
  [key in SpaceToKebabCase<WidgetComponent | 'a/b-testing' | 'global-settings'>]?: {
    label: WidgetComponent;
    component: JSX.Element;
    template?: Template | '';
    screen: ScreenName;
    params: {
      shopName: string;
      template?: KebabTemplate;
    };
  };
};

interface ContextProps {
  refetch?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<Merchant, unknown>>;
  merchant?: Merchant;
  shopName?: string;
}

export const MerchantContext = React.createContext<ContextProps>({});

const getTemplatesByType = (type: TemplateType): Template[] => {
  return Object.keys(TemplateToTypeMap).reduce((acc, template) => {
    if (type === TemplateToTypeMap[template as Template]) {
      acc.push(template as Template);
    }
    return acc;
  }, [] as Template[]);
};

export default function MerchantWidgetsScreen({ route }: GlobalScreenProps): JSX.Element {
  const navigation = useNavigation();
  const { showSuccess, handleError } = useOverlay();
  const showTabs = !route?.params?.widgetKey;
  const shopName = route?.params?.shopName;
  const { data: merchant, refetch } = useQuery(['merchant', shopName], () => getMerchant(route?.params?.shopName));
  const { data: merchantAdmin } = useQuery(['merchantAdmin', shopName], () => getMerchantAdmin(shopName));
  const isPermissioned = useFeaturePermission('qa');
  const EcoCartMerchantTabs = merchantAdmin?.platform === 'api' ? EcoCartApiMerchantTabs : EcoCartGlobalMerchantTabs;

  const handleEndTest = async () => {
    const isConfirmed = confirm(`Are you sure you want end test ${merchant?.experiment?.key} for ${shopName}?`);
    if (!isConfirmed) return;

    try {
      await updateMerchant(shopName, { experiment: null }, merchant);
      showSuccess('Successfully ended A/B test');
      refetch();
    } catch (error: any) {
      handleError(error);
    }
  };

  const componentMap: ComponentMap = {
    'global-settings': {
      label: WIDGET_COMPONENTS[0],
      component: (
        <View className="flex flex-col pt-2">
          {merchant?.experiment?.key ? (
            <WidgetABTestStatus
              experiment={merchant?.experiment as Experiment}
              primaryButton={{ label: 'End Test', onPress: handleEndTest }}
            />
          ) : null}
          <EditABTestForm />
          <EditMerchantWidgetSettingsForm />
        </View>
      ),
      template: '',
      screen: 'MerchantWidgets',
      params: {
        shopName
      }
    },
    // TODO Evaluate Drop
    // 'a/b-testing': {
    //   label: WIDGET_COMPONENTS[1],
    //   component: <ConfigureWidgetTestingForm />,
    //   template: '',
    //   screen: 'MerchantWidgetTesting',
    //   params: {
    //     shopName
    //   }
    // },
    'impact-counter': {
      label: WIDGET_COMPONENTS[1],
      component: <WidgetList template={getTemplatesByType('impact')} editorComponent={ImpactCounterDesigner} />,
      template: 'impact_counter',
      screen: 'MerchantWidgetTemplates',
      params: {
        shopName,
        template: kebabCase('impact_counter') as KebabTemplate
      }
    },
    'simple-checkout': {
      label: WIDGET_COMPONENTS[2],
      component: <WidgetList template="simple_checkout" editorComponent={SimpleCheckoutWidgetDesigner} />,
      template: 'simple_checkout',
      screen: 'MerchantWidgetTemplates',
      params: {
        shopName,
        template: kebabCase('simple_checkout') as KebabTemplate
      }
    },
    'checkout-widget': {
      label: WIDGET_COMPONENTS[3],
      component: <WidgetList template={getTemplatesByType('checkout')} editorComponent={CheckoutWidgetDesigner} />,
      template: 'checkout_widget',
      screen: 'MerchantWidgetTemplates',
      params: {
        shopName,
        template: kebabCase('checkout_widget') as KebabTemplate
      }
    },
    'order-protection': {
      label: WIDGET_COMPONENTS[4],
      component: <WidgetList template={getTemplatesByType('order_protection')} editorComponent={PackageProtectionDesigner} />,
      template: 'order_protection_checkout',
      screen: 'MerchantWidgetTemplates',
      params: {
        shopName,
        template: 'order-protection' as KebabTemplate
      }
    },
    'promo': {
      label: WIDGET_COMPONENTS[5],
      component: <WidgetList template={getTemplatesByType('promo')} editorComponent={PromoWidgetDesigner} />,
      template: 'promo',
      screen: 'MerchantWidgetTemplates',
      params: {
        shopName,
        template: 'promo'
      }
    },
    'eco-incentives': {
      label: WIDGET_COMPONENTS[6],
      component: <WidgetList template={getTemplatesByType('incentives')} editorComponent={EcoIncentivesWidgetDesigner} />,
      template: 'eco_incentives',
      screen: 'MerchantWidgetTemplates',
      params: {
        shopName,
        template: 'eco-incentives'
      }
    },
    'eco-network': {
      label: WIDGET_COMPONENTS[7],
      component: <WidgetList template={getTemplatesByType('network')} />,
      template: 'eco_network',
      screen: 'MerchantWidgetTemplates',
      params: {
        shopName,
        template: 'eco-network'
      }
    },
    'education-touchpoint': {
      label: WIDGET_COMPONENTS[8],
      component: isPermissioned ? (
        <WidgetList template={getTemplatesByType('education')} editorComponent={EducationTouchpointDesigner} />
      ) : (
        <></>
      ),
      template: 'education_touchpoint',
      screen: 'MerchantWidgetTemplates',
      params: {
        shopName,
        template: 'education-touchpoint'
      }
    },
    'impact-receipt': {
      label: WIDGET_COMPONENTS[9],
      component: isPermissioned ? <WidgetList template={getTemplatesByType('receipt')} editorComponent={ImpactReceiptDesigner} /> : <></>,
      template: 'impact_receipt',
      screen: 'MerchantWidgetTemplates',
      params: {
        shopName,
        template: 'impact-receipt'
      }
    },
    'enhanced-pdp': {
      label: WIDGET_COMPONENTS[9],
      component: isPermissioned ? (
        <WidgetList template={getTemplatesByType('enhanced_pdp')} editorComponent={EnhancedPdpDesigner} />
      ) : (
        <></>
      ),
      template: 'enhanced_pdp',
      screen: 'MerchantWidgetTemplates',
      params: {
        shopName,
        template: 'enhanced-pdp'
      }
    }
  };

  const getComponentKey = (): keyof ComponentMap => {
    if (!route.params.template) {
      return route.name === 'MerchantWidgetTesting' ? 'a/b-testing' : 'global-settings';
    }

    return kebabCase(route.params.template) as keyof ComponentMap;
  };

  const componentElm = componentMap[getComponentKey()];

  useEffect(() => {
    refetch();
  }, [route, refetch]);

  return (
    <EcoScrollView>
      <View className="flex-row">
        <BackLink href="/merchants" label="Back to Merchants" />
      </View>
      <EcoTabs
        tabs={EcoCartMerchantTabs.map(({ label, screenName }) => {
          return {
            label,
            screenName,
            screenParams: { shopName }
          } as EcoTab;
        })}
        prefix="Merchant"
        activeScreen="MerchantWidgets"
      >
        <EcoTabContent>
          <View style={Gap(2)}>
            {showTabs && (
              <ScrollView horizontal>
                <View style={Gap(2)} className="flex-row">
                  {Object.entries(componentMap).map(([key, value]) => (
                    <EcoButton
                      size="sm"
                      key={key}
                      variant={value.label === componentElm?.label ? 'filled' : 'outlined'}
                      onPress={() => {
                        navigation.navigate(value.screen as any, value.params);
                      }}
                    >
                      {value.label}
                    </EcoButton>
                  ))}
                </View>
              </ScrollView>
            )}
            <MerchantContext.Provider value={{ merchant, refetch }}>
              <View>{componentElm?.component}</View>
            </MerchantContext.Provider>
          </View>
        </EcoTabContent>
      </EcoTabs>
    </EcoScrollView>
  );
}
