import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MINUTE } from '@utils/time';
import { useFonts } from 'expo-font';
import 'react-native-reanimated';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import GlobalContextProvider from './context/global';
import { OverlayProvider } from './context/overlay';
import { TooltipProvider } from './context/tooltip';
import './input.css';
import { AppNavigator } from './navigation/AppNavigator';

function App(): JSX.Element | null {
  const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: 30 * MINUTE } } });

  const [fontsLoaded] = useFonts({
    'sora-regular': require('./assets/fonts/Sora-Regular.ttf'),
    'sora-medium': require('./assets/fonts/Sora-Medium.ttf'),
    'sora-semibold': require('./assets/fonts/Sora-SemiBold.ttf')
  });

  if (!fontsLoaded) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <QueryClientProvider client={queryClient}>
          <GlobalContextProvider>
            <OverlayProvider>
              <TooltipProvider>
                <AppNavigator />
              </TooltipProvider>
            </OverlayProvider>
          </GlobalContextProvider>
        </QueryClientProvider>
      </SafeAreaProvider>
    );
  }
}

export default App;
