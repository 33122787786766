import { Session } from '@context/global';
import { CurrencyCode } from '@ecocart/entities';
import { isValidJSONString } from '@ecocart/universal-utils';
import { isCreditCardExpired } from '@utils/credit-card';
import { isEmpty } from 'lodash';
import { apiCall, PAYMENTS_API } from './api';

export const hasAtLeastOnePaymentMethod = (paymentMethods: PaymentMethods | null | undefined): boolean => {
  return !(isEmpty(paymentMethods?.bank_accounts) && isEmpty(paymentMethods?.cards) && !paymentMethods?.shopify_charge);
};

export const hasAtLeastOneValidPaymentMethod = (paymentMethods: PaymentMethods | null | undefined): boolean => {
  if (
    !isEmpty(paymentMethods?.bank_accounts) &&
    (paymentMethods?.bank_accounts || []).some(({ setup_intent_status }) => setup_intent_status === 'succeeded')
  ) {
    return true;
  }

  if (!isEmpty(paymentMethods?.cards) && (paymentMethods?.cards || []).some((card) => !isCreditCardExpired(card))) {
    return true;
  }

  if (paymentMethods?.shopify_charge && paymentMethods?.shopify_charge.status === 'active') {
    return true;
  }

  return false;
};

export const getDefaultPaymentMethod = (
  paymentMethods: PaymentMethods | null | undefined
): { type: PaymentType; data: BankAccount | CreditCard | ShopifyCharge } | null => {
  if (!hasAtLeastOnePaymentMethod(paymentMethods)) return null;

  if (!isEmpty(paymentMethods?.bank_accounts) && (paymentMethods?.bank_accounts || []).some((account) => account.default)) {
    const data = (paymentMethods?.bank_accounts || []).find((account) => account.default);
    return data ? { type: 'bank_account', data } : null;
  }

  if (!isEmpty(paymentMethods?.cards) && (paymentMethods?.cards || []).some((card) => card.default)) {
    const data = (paymentMethods?.cards || []).find((card) => card.default);
    return data ? { type: 'credit_card', data } : null;
  }

  if (paymentMethods?.shopify_charge && paymentMethods?.shopify_charge.default) {
    return { type: 'shopify_charge', data: paymentMethods.shopify_charge };
  }

  return null;
};

export const getDefaultPaymentMethodLabel = (paymentMethods: PaymentMethods | null | undefined): string => {
  const defaultPaymentMethod = getDefaultPaymentMethod(paymentMethods);

  switch (defaultPaymentMethod?.type) {
    case 'credit_card':
      return `[Card] ****${(defaultPaymentMethod.data as CreditCard).last4}`;
    case 'bank_account':
      return `[Account] ****${(defaultPaymentMethod.data as BankAccount).last4}`;
    case 'shopify_charge':
      return `[Shopify] ${(defaultPaymentMethod.data as ShopifyCharge).name.split(' ')?.[0] || ''}****`;
    default:
      return '';
  }
};

export interface Stripe {
  collectBankAccountForSetup: (account: {
    clientSecret: string;
    params: {
      payment_method_type: 'us_bank_account';
      payment_method_data: {
        billing_details: {
          name: string;
          email: string;
        };
        expand: ['payment_method'];
      };
    };
  }) => Promise<{
    setupIntent: SetupIntent;
    error: { message: string };
  }>;
  confirmUsBankAccountSetup: (client_secret: string) => {
    setupIntent: SetupIntent;
    error: { message: string };
  };
}

export type AddCreditCard = CreditCard | StripeCustomer;

export type PaymentType = 'credit_card' | 'bank_account' | 'shopify_charge';

export class StripeCustomer {
  identifier?: string = '';
  store_domain: string = 'ecocart-merch.myshopify.com';
  customer_email: string = 'engineering@ecocart.io';
  customer_name: string = 'John Doe';
  created_at?: string = '2022-01-01';

  constructor() {}
}
export class CreditCard {
  identifier?: string = '61c6acd4-51dd-4d59-bd5e-407b13d5b3bc';
  card_number?: string = '4242424242424242';
  last4?: string = '4242';
  brand?: string = 'Visa';
  country?: string = 'United States';
  exp_month: string = '8';
  exp_year: string = '2023';
  cvc?: string = '314';
  default?: boolean = true;

  constructor() {}
}

export type AccountType = 'checking' | 'saving';

export class BankAccount {
  account_type: AccountType = 'checking';
  bank_name: string = 'STRIPE TEST BANK';
  default: boolean = true;
  identifier: string = 'aa0f8c25-3f3e-4c9f-b216-d3ad9e612865';
  last4: string = '3335';
  setup_intent_status: SetupIntentStatus = 'requires_confirmation';

  constructor() {}
}

type ShopifyChargeStatus = 'pending' | 'active' | 'declined' | 'cancelled' | 'expired' | 'frozen';

export class ShopifyCharge {
  identifier: string = '0312e6a3-00ec-4472-ab8b-2fb0ffb91520';
  name: string = 'Ecocart Recurring Charge';
  capped_amount: number = 200000;
  status: ShopifyChargeStatus = 'pending';
  currency: CurrencyCode = 'USD';
  confirmation_url: string = `https://ecocart-merch.myshopify.com/admin/charges/3334905/24323325997/RecurringApplicationCharge/confirm_recurring_application_charge?signature=BAh7BzoHaWRsKwgtgMipBQA6EmF1dG9fYWN0aXZhdGVU--3645387922bfa02d553ea2cf1a40df1a2116c2b4`;
  default: boolean = false;

  constructor() {}
}

export interface PaymentMethods {
  customer: StripeCustomer;
  bank_accounts: BankAccount[];
  cards: CreditCard[];
  shopify_charge: ShopifyCharge;
}

// CURRENT = "current"  # ---- current invoice
// FLIGHT = "flight"  # ---- not a current invoice but hasn't been processed yet
// PENDING = "pending"  # ---- invoice processed but not paid yet
// PAID = "paid"  # ---- invoice paid via card
// FAILED = "failed"
export type InvoiceStatus = 'current' | 'flight' | 'processing' | 'paid' | 'unpaid' | 'failed';

export class Invoice {
  status: InvoiceStatus = 'processing';
  currency: CurrencyCode = 'USD';
  identifier: string = '';
  store_domain: string = '';
  total_amount: number = 0;
  total_amount_usd: number = 0;
  total_carbon: number = 0;
  hosted_invoice_url: string | null = '';
  stripe_pdf_url: string | null = '';
  start_date: string = '';
  end_date: string = '';
  stripe_invoice_id: string | null = '';
  total_refunds_amount_usd: number = 0;
  refunds: InvoiceRefund[] = [];

  constructor() {}
}

interface InvoiceRefund {
  amount_usd: number;
  memo: string;
  user: string;
  status: string;
  created_at: string;
}

export class InvoicePaymentMethod {
  payment_method_type: 'card' | 'us_bank_account' | 'shopify_charge' | null = null;
  card: CreditCard | null = null;
  bank_account: BankAccount | null = null;
  shopify_charge: ShopifyCharge | null = null;
}

export interface CurrentInvoice {
  store_domain: string;
  invoice_id: string;
  start_date: string;
  end_date: string;
  confirmed_orders: number;
  total_amount_charged_usd: number;
  customer_offset_amount_usd: number;
  merchant_offset_amount_usd: number;
  merchant_eco_incentives_contribution_usd: number;
}

export type SetupIntentStatus = 'requires_payment_method' | 'requires_confirmation' | 'requires_action' | 'pending' | 'succeeded';

export interface SetupIntent {
  id: string;
  object: string;
  automatic_payment_methods: null;
  cancellation_reason: null;
  client_secret: string;
  created: number;
  description: null;
  last_setup_error: null;
  livemode: boolean;
  next_action: null;
  payment_method: string;
  payment_method_options: {
    us_bank_account: {
      verification_method: string;
    };
  };
  payment_method_types: string[];
  status: SetupIntentStatus;
  usage: string;
}

export interface AddBankAccountRequest {
  store_domain: string;
  setup_intent_id: string;
  payment_method_id: string;
  setup_intent_status: SetupIntentStatus;
}

interface StripeInfo {
  setup_intent_client_secret: string;
  stripe_publishable_key: string;
}

/* ------------------------------- credit card ------------------------------ */
export const addCreditCard = (creditCard: AddCreditCard): Promise<PaymentMethods> => {
  return apiCall<PaymentMethods>('POST', `${PAYMENTS_API}/cards`, creditCard);
};

export const deleteCreditCard = (creditCard: AddCreditCard, shopName: string): Promise<PaymentMethods> => {
  return apiCall<PaymentMethods>('DELETE', `${PAYMENTS_API}/cards/${creditCard.identifier}`, { store_domain: shopName });
};

export const setCreditCardAsDefault = (creditCard: CreditCard, shopName: string): Promise<PaymentMethods> => {
  return apiCall<PaymentMethods>('PUT', `${PAYMENTS_API}/cards/${creditCard.identifier}`, { default: true, store_domain: shopName });
};

/* ------------------------------ bank account ------------------------------ */
export const addBankAccount = (request: AddBankAccountRequest): Promise<StripeInfo> => {
  return apiCall<StripeInfo>('POST', `${PAYMENTS_API}/bank-accounts`, request);
};

export const deleteBankAccount = (bankAccount: BankAccount, shopName: string): Promise<Record<'success' | 'error', string>> => {
  return apiCall<Record<'success' | 'error', string>>('DELETE', `${PAYMENTS_API}/bank-accounts/${bankAccount.identifier}`, {
    store_domain: shopName
  });
};

export const setBankAsDefault = (bankAccount: BankAccount, shopName: string): Promise<PaymentMethods> => {
  return apiCall<PaymentMethods>('PUT', `${PAYMENTS_API}/bank-accounts/${bankAccount.identifier}`, {
    default: true,
    store_domain: shopName
  });
};

/* -------------------------- shopify charge (pay) -------------------------- */
export const fetchShopifyChargeConnectionUrl = (shopName: string, returnUrl: string): Promise<ShopifyCharge> => {
  return apiCall<ShopifyCharge>('POST', `${PAYMENTS_API}/shopify-charge`, {
    store_domain: shopName,
    return_url: returnUrl
  });
};

export const deleteShopifyCharge = (shopifyCharge: ShopifyCharge, shopName: string): Promise<Record<'success' | 'error', string>> => {
  return apiCall<Record<'success' | 'error', string>>('DELETE', `${PAYMENTS_API}/shopify-charge/${shopifyCharge.identifier}`, {
    store_domain: shopName
  });
};

export const setShopifyChargeAsDefault = (shopifyCharge: ShopifyCharge, shopName: string): Promise<ShopifyCharge> => {
  return apiCall<ShopifyCharge>('PUT', `${PAYMENTS_API}/shopify-charge/${shopifyCharge.identifier}`, {
    default: true,
    store_domain: shopName
  });
};

/* -------------------------------- customer -------------------------------- */
export const createCustomer = (session: Session): Promise<PaymentMethods | null> => {
  if (session.user?.userType === 'ecocart_admin') return Promise.resolve(null);

  // use store contact email for shopify user
  const email = session.user.type === 'SHOPIFY_USER' ? session.merchantAdmin?.contactEmail : session.user?.id;

  return apiCall<PaymentMethods>('POST', `${PAYMENTS_API}/customers`, {
    store_domain: session?.merchantAdmin?.shopName,
    customer_email: email,
    customer_name: session.user?.firstName + ' ' + session.user?.lastName,
    currency: session.merchantAdmin?.currencyCode || 'USD'
  });
};

export const getPaymentMethods = (shopName?: string): Promise<PaymentMethods | null> => {
  if (!shopName) return Promise.resolve(null);

  return apiCall<PaymentMethods>('GET', `${PAYMENTS_API}/customers/${shopName}`);
};

/**
 * For merchant admin only
 * @param session optional Session
 * @returns Promise<CreditCard[]>
 */
export const getPaymentMethodsOrCreateCustomer = (session?: Session | null): Promise<PaymentMethods | null> => {
  return new Promise((resolve, reject) => {
    const shopName = session?.merchantAdmin?.shopName;

    if (!shopName) {
      reject();
      return;
    }

    getPaymentMethods(shopName)
      .then((paymentMethods) => resolve(paymentMethods))
      .catch((error) => {
        const errorMessage = isValidJSONString(error?.message) ? JSON.parse(error.message).error : '';

        if (errorMessage.includes('store_domain') && errorMessage.includes('does not exist')) {
          createCustomer(session)
            .then(() => {
              resolve(null);
            })
            .catch((postError) => {
              console.error({ postError });
              resolve(null);
            });
        } else {
          console.error({ error });
          resolve(null);
        }
      });
  });
};

export const getInvoiceCSVDownloadUrl = (shopName: string, invoiceId: string): Promise<string> => {
  return apiCall<Record<'download_url', string>>('GET', `${PAYMENTS_API}/invoices/${shopName}/${invoiceId}/csv`).then(
    (res) => res?.download_url ?? ''
  );
};

export const getInvoicePDFDownloadUrl = (shopName: string, invoiceId: string): Promise<string> => {
  return apiCall<Record<'download_url', string>>('GET', `${PAYMENTS_API}/invoices/${shopName}/${invoiceId}/pdf`).then(
    (res) => res?.download_url ?? ''
  );
};

export const getStripeInfo = (shopName: string): Promise<StripeInfo> => {
  return apiCall<StripeInfo>('GET', `${PAYMENTS_API}/bank-accounts/config/${shopName}`);
};

/* -------------------------------- invoices -------------------------------- */
interface PayInvoiceResponse {
  type: InvoiceStatus;
  success?: string;
  error?: string;
}
export const payInvoice = (shopName: string, invoiceId: string): Promise<PayInvoiceResponse> => {
  return apiCall<PayInvoiceResponse>('POST', `${PAYMENTS_API}/invoices/${shopName}/${invoiceId}/pay`);
};

export const getInvoicePaymentMethod = (shopName: string, invoiceId: string): Promise<InvoicePaymentMethod> => {
  return apiCall<InvoicePaymentMethod>('GET', `${PAYMENTS_API}/invoices/${shopName}/${invoiceId}/payment_method`);
};

export const getInvoices = (shopName: string): Promise<Invoice[]> => {
  return apiCall<Record<'invoices', Invoice[]>>('GET', `${PAYMENTS_API}/invoices/${shopName}`).then((res) => {
    // TODO: discuss status-filtering (removal of 'current' | 'flight' status) on the backend, w @nabin
    return (
      (res?.invoices ?? [])
        .filter((invoice) => ['processing', 'paid', 'unpaid', 'failed'].includes(invoice.status))
        // hardcode currency to USD for now, per @nabin
        .map((invoice) => ({ ...invoice, total_amount: invoice.total_amount_usd, currency: 'USD' }))
    );
  });
};

/* ---------------------------- current invoice ----------------------------- */
export const getCurrentInvoice = (shopName: string): Promise<CurrentInvoice> => {
  return apiCall<CurrentInvoice>('GET', `${PAYMENTS_API}/invoices/current-invoice/${shopName}/details`);
};

/* ----------------------------- invoice refund ----------------------------- */
export const createInvoiceRefund = (
  shopName: string,
  invoiceId: string,
  amount: number,
  memo: string,
  session?: Session | null
): Promise<InvoiceRefund> => {
  return apiCall<InvoiceRefund>('POST', `${PAYMENTS_API}/refunds`, {
    store_domain: shopName,
    invoice_id: invoiceId,
    amount_usd: amount,
    memo,
    user: session?.user.id
  });
};
