import { EcoButton, EcoCard, EcoInput, EcoRadioButtons, EcoText } from '@components/shared';
import { FormCheckbox } from '@components/shared/FormCheckbox';
import { BankAccount } from '@utils/api/payments';
import { GlobalFormProps } from '@utils/prop-types';
import React, { useState } from 'react';
import { View } from 'react-native';

interface Props extends GlobalFormProps {
  bankAccount: BankAccount;
  handleHideModal: () => void;
  handleSetAsDefault: () => Promise<void>;
  handleShowDeleteModal: () => void;
}

export default function EditBankAccountForm({
  bankAccount,
  handleHideModal,
  handleSetAsDefault,
  handleShowDeleteModal
}: Props): JSX.Element {
  const [isDefault, setIsDefault] = useState(bankAccount.default || false);
  const isValidUpdate = isDefault && !bankAccount.default;

  return (
    <EcoCard className="md:w-[525px]">
      <EcoText fontWeight="semibold" fontSize="xl" textAlign="center">
        Edit Bank Account
      </EcoText>
      <View className="mb-2">
        <EcoInput label="Financial Institution" value={bankAccount.bank_name} disabled={true} />
      </View>
      <View className="mb-2">
        <EcoRadioButtons
          value={bankAccount.account_type}
          onValueChange={() => {}}
          disabled={true}
          items={[
            { value: 'checking', label: 'Checking Account' },
            { value: 'saving', label: 'Savings Account' }
          ]}
        />
      </View>
      <View className="mb-2">
        <EcoInput label="Card number" value={'**** **** **** ' + bankAccount.last4} disabled={true} />
      </View>
      <View className="mt-2 mb-4">
        <FormCheckbox
          value={isDefault}
          disabled={!!bankAccount.default}
          onValueChange={(val: boolean) => setIsDefault(val)}
          label="Set as default payment"
        />
      </View>
      <View className="flex flex-row">
        <EcoButton
          className="flex-1 mr-2"
          nativeID="add-payment-card-modal__add-card-button"
          colorScheme="danger"
          variant="outlined"
          onPress={handleShowDeleteModal}
        >
          Delete
        </EcoButton>
        <EcoButton
          className="flex-1 ml-2"
          nativeID="add-payment-card-modal__add-card-button"
          colorScheme="default"
          variant="filled"
          isDisabled={!isValidUpdate}
          onPress={() => {
            handleSetAsDefault();
            handleHideModal();
          }}
        >
          Save
        </EcoButton>
      </View>
    </EcoCard>
  );
}
