import { MerchantAdmin, Project } from '@ecocart/entities';
import { useQuery } from '@tanstack/react-query';
import { getMerchantAdmin, updateMerchantAdmin } from '@utils/api/merchant';
import { sendMerchantAdminUpdateMessage } from '@utils/api/slack';
import { useState } from 'react';
import { useGlobal } from './useGlobal';
import { useOverlay } from './useOverlay';

interface ReturnType {
  isSubmitting: boolean;
  handleSelectProject: (project: Project) => void;
}

export const useSelectProject = (shopName = ''): ReturnType => {
  const { session, updateSessionMerchantAdmin } = useGlobal();
  const { showSuccess, handleError } = useOverlay();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const isEcoCartAdmin = session?.user.userType === 'ecocart_admin';
  const { refetch } = useQuery(['merchantAdmin', shopName], () => getMerchantAdmin(shopName));

  const handleSelectProject = async (project: Project) => {
    if (!shopName || !project?.id) {
      handleError('There was error selecting this project, please try again.');
      return;
    }

    setIsSubmitting(true);

    const merchantAdminProps: Partial<MerchantAdmin> = {
      shopName,
      project: {
        id: project.id,
        name: project.name,
        url: project.url,
        images: project.images.slice(0, 3),
        location: project.location,
        verifications: project.verifications,
        description: project.description,
        goals: project.goals
      }
    };

    try {
      await updateMerchantAdmin(merchantAdminProps.shopName as string, merchantAdminProps);
      await sendMerchantAdminUpdateMessage('project_change', session, { merchantAdmin: merchantAdminProps });

      if (!isEcoCartAdmin) updateSessionMerchantAdmin(merchantAdminProps);

      showSuccess('Successfully changed project');
      setIsSubmitting(false);
      refetch();
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  return { isSubmitting, handleSelectProject };
};
