import { EcoLoadingScreen, EcoText } from '@components/shared';
import { Contact, EMAIL_TEMPLATE_NAMES } from '@ecocart/entities';
import { GlobalScreenProps } from '@navigation/types';
import { useNavigation } from '@react-navigation/native';
import { useMutation } from '@tanstack/react-query';
import { ManageSubscriptionParams, unsubscribe } from '@utils/api/email';

import { Gap } from '@utils/layout';
import { useEffect } from 'react';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

export default function EmailUnsubscribeScreen({ route }: GlobalScreenProps): JSX.Element {
  const navigation = useNavigation();
  const { email, template, merchant } = route.params;

  const mutation = useMutation<Contact | null, Error, ManageSubscriptionParams>(unsubscribe);

  useEffect(() => {
    async function initUnsubscribe() {
      if (!EMAIL_TEMPLATE_NAMES.includes(template)) {
        navigation.navigate('Login');
        return;
      }
      mutation.mutate({ email, emailTemplateName: template, merchant });
    }
    initUnsubscribe();
  }, []);

  return (
    <SafeAreaView className="justify-center flex flex-row items-center w-full h-full">
      <View className="items-center justify-center" style={Gap()}>
        <View style={Gap(2)}>
          {mutation.isLoading && <EcoLoadingScreen />}
          {mutation.isSuccess && (
            <EcoText textAlign="center" fontSize="xl">
              <EcoText color="accent-primary" fontSize="xl">
                {email}
              </EcoText>{' '}
              has been removed from our subscription list.
            </EcoText>
          )}
          {mutation.isError && (
            <EcoText textAlign="center" fontSize="xl">
              Error unsubscribing email. Please check the url or try again later.
            </EcoText>
          )}
        </View>
      </View>
    </SafeAreaView>
  );
}
