import { apiCall, MANUFACTURING_API } from './api';

const CLEAR_HEADERS = { 'X-Eco-JWT': '', 'X-Eco-Shopify-Access-Token': '' };

export interface EmissionFactors {
  name: string;
  slug: string;
  emission_factor: number;
  emission_unit: string;
}

export const getEmissionFactors = (): Promise<EmissionFactors[]> => {
  return apiCall<Record<'items', EmissionFactors[]>>(
    'GET',
    `${MANUFACTURING_API}/product/config/emission-factors/`,
    {},
    CLEAR_HEADERS
  ).then((res) => res?.items ?? []);
};
