import { PayorMode } from '@ecocart/entities';
import { IconName } from './EcoIcon.utils';

export interface EcoRadioButtonItem {
  value: string | number;
  label: string;
  description?: string;
  iconName?: IconName;
  disabled?: boolean;
}

export const hydrateRadioButtonData = (value: string): EcoRadioButtonItem => {
  return {
    ...RADIO_BUTTON_MAPPINGS[value],
    value
  } as EcoRadioButtonItem;
};

export const RADIO_BUTTON_MAPPINGS: Record<PayorMode | 'carbon_neutral' | 'flat_rate' | string, Partial<EcoRadioButtonItem>> = {
  carbon_neutral: {
    label: 'Carbon Neutral',
    description: "Offset each order's estimated carbon footprint",
    iconName: undefined
  },
  climate_positive: {
    label: 'Climate Positive',
    description: 'Doubles the amount of carbon each order will offset',
    iconName: undefined
  },
  flat_rate: {
    label: 'Flat Rate',
    description: 'Offer your customers a flat-rate carbon offset amount to opt-in to',
    iconName: undefined
  },
  no_matching: {
    label: 'None',
    description: 'Contribution matching is not offered',
    iconName: undefined
  },
  customer_matching: {
    label: 'Customer Matching',
    description: 'Match the donations your customers make at checkout and double your impact',
    iconName: undefined
  },
  customer_paying: {
    label: 'Customer Paying',
    description: 'Your customers can pay to offset the carbon footprint of their purchase',
    iconName: undefined
  },
  merchant_matching: {
    label: 'Merchant Matching',
    description: 'Match the donations your customers make at checkout and double your impact',
    iconName: undefined
  },
  merchant_paying: {
    label: 'Merchant Paying',
    description: 'Make the biggest impact by paying to offset every order on behalf of your customers',
    iconName: undefined
  },
  opt_in: {
    label: 'Opt-in',
    description: 'The option to offset an order is turned off at checkout',
    iconName: undefined
  },
  opt_out: {
    label: 'Opt-out',
    description: 'The option to offset an order is turned on at checkout',
    iconName: undefined
  },
  defra_store: {
    label: 'Spend-Based LCA by Store',
    description:
      "Spend-based calculations based on the merchants assigned store category utilizing the GHG Protocol-compliant Defra/DECC's GHG Conversion Factors",
    iconName: undefined
  },
  defra_product: {
    label: 'Spend-Based LCA by Product',
    description:
      "Sector specific spend-based calculations based on the assigned product category utilizing the GHG Protocol-compliant Defra/DECC's GHG Conversion Factors",
    iconName: undefined
  },
  lca: {
    label: 'LCA',
    description:
      'Legacy manufacturing calculation system that uses product-level categorizations based on text and image recognition mapped to a fixed emission amount. Stores with individual LCAs also use this mode.',
    iconName: undefined,
    disabled: true
  }
};
