import { EcoSkeleton, EcoText } from '@components/shared';
import { Platform } from '@ecocart/entities';
import { ApiMerchantActivityCard, GlobalMerchantActivityCard } from '@utils/constants/merchant-admin-fields';
import { format } from 'date-fns';
import { View } from 'react-native';
import { twMerge } from 'tailwind-merge';

interface CardProps {
  label: string;
  value: string;
}

interface ConfigProps {
  updatedAt?: number;
  createdAt?: number;
  variantIds?: string[];
  ecocartActive?: boolean;
  ecocartEnabled?: boolean;
  platform?: Platform;
}

const getClass = (value: string): { container: string } => {
  const lcValue = value.toLowerCase();
  if (['invalid', 'inactive', 'hidden'].some((neg) => lcValue.includes(neg))) return { container: 'border border-danger-700 bg-danger-50' };
  if (['valid', 'active', 'visible'].some((pos) => lcValue.includes(pos))) return { container: 'border border-success bg-success-50' };
  return { container: '' };
};

export function MerchantActivityCardDetails({ label, value }: CardProps): JSX.Element {
  return (
    <View className={twMerge('rounded-sm flex-1 p-4 h-[100px] flex-col justify-center items-center bg-white', getClass(value).container)}>
      <EcoText fallback={<EcoSkeleton mode="dark" height="xl" />} fontSize="xl" fontWeight="semibold">
        {value}
      </EcoText>
      <EcoText fontSize="xs" fallback={<EcoSkeleton mode="dark" height="sm" />} className="capitalize">
        {label}
      </EcoText>
    </View>
  );
}
const ConfigMap: Record<Platform, string[]> = {
  Shopify: GlobalMerchantActivityCard,
  Shopify_headless: GlobalMerchantActivityCard,
  Wix: GlobalMerchantActivityCard,
  WooCommerce: GlobalMerchantActivityCard,
  BigCommerce: GlobalMerchantActivityCard,
  Custom: GlobalMerchantActivityCard,
  api: ApiMerchantActivityCard
};

export const useMerchantActivityCardConfig = ({
  updatedAt,
  createdAt,
  variantIds,
  ecocartActive,
  ecocartEnabled,
  platform
}: ConfigProps): CardProps[] => {
  const config = [
    { label: 'Last Updated', value: updatedAt ? format(new Date(updatedAt), 'MMM d, yyyy') : '' },
    { label: 'Initial Install', value: createdAt ? format(new Date(createdAt), 'MMM d, yyyy') : '' },
    { label: 'Store Variants', value: variantIds?.[0] ? `Valid (${variantIds.length})` : 'Invalid' },
    { label: 'EcoCart', value: ecocartActive ? 'Active' : 'Inactive' },
    { label: 'Widgets', value: ecocartEnabled ? 'Visible' : 'Hidden' }
  ];

  const platformConfig = config.filter((field) => ConfigMap[platform || 'Shopify'].includes(field.label));
  return platformConfig;
};
