import { GlobalStylesProps } from '@utils/prop-types';
import React, { ReactNode } from 'react';
import { KeyboardAvoidingView, Platform } from 'react-native';

interface Props extends GlobalStylesProps {
  children?: ReactNode;
}

export const EcoKeyboardAvoidingView = ({ style, children }: Props): JSX.Element => {
  return (
    <KeyboardAvoidingView style={style} behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
      {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss}> */}
      {children}
      {/* </TouchableWithoutFeedback> */}
    </KeyboardAvoidingView>
  );
};
