import { Widget } from '@ecocart/entities';
import { IMAGE_ASSETS } from '@ecocart/universal-utils';

export type ECLogo = 'white' | 'black' | 'standard' | 'inverted';

export const getInitialValues = (): Widget => {
  const widget = new Widget();
  widget.name = 'Impact Counter';
  widget.elements = {
    ...widget.elements,
    logo: { custom: { url: IMAGE_ASSETS.ECOCART_BLACK } },
    // to make sure content is vertically center when min height is set
    wrapper: { styles: { display: 'flex', alignItems: 'center' } }
  };
  widget.template = 'impact_counter';
  return widget;
};

export const getLogoValueFromURL = (url?: string): ECLogo => {
  switch (url) {
    case IMAGE_ASSETS.ECOCART_BLACK:
      return 'black';
    case IMAGE_ASSETS.ECOCART:
      return 'standard';
    case IMAGE_ASSETS.ECOCART_COLOR_WHITE:
      return 'inverted';
    case IMAGE_ASSETS.ECOCART_WHITE:
    default:
      return 'white';
  }
};

export type MerchantLogoSize = 'default' | 'sm' | 'md' | 'lg' | 'xl';
export const SizeMap: Record<MerchantLogoSize, string> = {
  default: '',
  sm: '60px',
  md: '80px',
  lg: '100px',
  xl: '120px'
};

type MerchantLogoItem = {
  label: string;
  value: MerchantLogoSize;
};

export const MERCHANT_LOGO_ITEMS: MerchantLogoItem[] = [
  {
    label: 'Default',
    value: 'default'
  },
  {
    label: 'Small',
    value: 'sm'
  },
  {
    label: 'Medium',

    value: 'md'
  },
  {
    label: 'Large',
    value: 'lg'
  },
  {
    label: 'XLarge',
    value: 'xl'
  }
];

export type FloatingImagePositionValue = 'default' | 'center' | 'left' | 'right' | 'top' | 'bottom';

type FloatingImagePosition = {
  label: string;
  value: FloatingImagePositionValue;
};

export const FLOATING_IMAGE_POSITIONS: FloatingImagePosition[] = [
  {
    label: 'Default',
    value: 'default'
  },
  {
    label: 'Left',
    value: 'left'
  },
  {
    label: 'Right',
    value: 'right'
  },
  {
    label: 'Center',
    value: 'center'
  },
  {
    label: 'Top',
    value: 'top'
  },
  {
    label: 'Bottom',
    value: 'bottom'
  }
];
