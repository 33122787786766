import { default as SustainabilityJourney } from '@components/dashboards/components/SustainabilityJourney';
import WelcomeBanner from '@components/dashboards/components/WelcomeBanner';
import { useGlobal } from '@hooks/useGlobal';
import { ScrollView, View } from 'react-native';
import ProjectFundingList from './components/ProjectFundingList';

export default function MerchantAdminDashboard(): JSX.Element {
  const { session } = useGlobal();

  return (
    <ScrollView className="pb-11">
      <WelcomeBanner merchantAdmin={session?.merchantAdmin} />
      <View className="bg-tertiary-50">
        <SustainabilityJourney merchantAdmin={session?.merchantAdmin} />
        <ProjectFundingList merchantAdmin={session?.merchantAdmin} />
      </View>
    </ScrollView>
  );
}
