import { GlobalStylesProps } from '@utils/prop-types';
import { FontSize, colors } from '@utils/tailwind';
import { ReactNode } from 'react';
import { View } from 'react-native';
import { twMerge } from 'tailwind-merge';
import { nativeID } from '../../utils/types/nativeID';
import { EcoHeadings } from './EcoHeadings';
import { EcoLoader } from './EcoLoader';

type Size = 'sm' | 'md' | 'lg' | 'xl' | 'full';
interface Props extends GlobalStylesProps {
  children: ReactNode;
  title?: string;
  wrapperClassName?: string;
  titleSize?: FontSize;
  titleClassName?: string;
  subTitle?: string;
  topRightComponent?: JSX.Element;
  size?: Size;
  isLoading?: boolean;
  nativeID?: nativeID;
}

const sizeMap: Record<Size, string> = {
  sm: 'w-full sm:w-[350px]',
  md: 'w-full sm:w-[350px] md:w-[450px]',
  lg: 'w-full sm:w-[400px] md:w-[550px]',
  xl: 'w-full sm:w-[400px] md:w-[650px]',
  full: 'w-full'
};

export function EcoCard({
  nativeID,
  size = 'full',
  wrapperClassName,
  topRightComponent,
  children,
  title,
  titleSize = '4xl',
  titleClassName,
  subTitle,
  style,
  isLoading
}: Props): JSX.Element {
  return (
    <View className={twMerge('shrink rounded-sm bg-white p-7', sizeMap[size], wrapperClassName)} style={style} nativeID={nativeID}>
      {(title || subTitle || topRightComponent) && (
        <View className="mb-2">
          <EcoHeadings
            title={title}
            subTitle={subTitle}
            topRightComponent={topRightComponent}
            titleSize={titleSize}
            titleClassName={titleClassName}
          />
        </View>
      )}
      {isLoading ? <EcoLoader size="large" color={colors.primary[400]} className="py-9" /> : children}
    </View>
  );
}
