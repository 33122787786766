import { EcoButton, EcoCard, EcoIcon, EcoInput, EcoText } from '@components/shared';
import { FormCheckbox } from '@components/shared/FormCheckbox';
import { ShopifyCharge } from '@utils/api/payments';
import { GlobalFormProps } from '@utils/prop-types';
import React, { useState } from 'react';
import { Linking, Pressable, View } from 'react-native';

interface Props extends GlobalFormProps {
  shopifyCharge: ShopifyCharge;
  handleHideModal: () => void;
  handleSetAsDefault: () => Promise<void>;
  handleShowDeleteModal: () => void;
}

export default function EditShopifyCharge({
  shopifyCharge,
  handleHideModal,
  handleSetAsDefault,
  handleShowDeleteModal
}: Props): JSX.Element {
  const [isDefault, setIsDefault] = useState(shopifyCharge.default || false);
  const isValidUpdate = isDefault && !shopifyCharge.default;

  return (
    <EcoCard className="md:w-[525px]">
      <EcoText fontWeight="semibold" fontSize="xl" textAlign="center">
        Edit Shopify Billing
      </EcoText>
      <View className="mb-2">
        <EcoInput label="Subscription Name" value={shopifyCharge.name} disabled={true} />
      </View>
      <View className="mt-2 mb-4">
        <FormCheckbox
          value={isDefault}
          disabled={!!shopifyCharge.default || shopifyCharge.status !== 'active'}
          onValueChange={(val: boolean) => setIsDefault(val)}
          label="Set as default payment"
        />
      </View>
      <View className="flex flex-row">
        <EcoButton
          className="flex-1 mr-2"
          nativeID="add-payment-card-modal__add-card-button"
          colorScheme="danger"
          variant="outlined"
          onPress={handleShowDeleteModal}
        >
          Delete
        </EcoButton>
        <EcoButton
          className="flex-1 ml-2"
          nativeID="add-payment-card-modal__add-card-button"
          colorScheme="default"
          variant="filled"
          isDisabled={!isValidUpdate}
          onPress={() => {
            handleSetAsDefault();
            handleHideModal();
          }}
        >
          Save
        </EcoButton>
      </View>
      <View className="flex flex-row items-center justify-center pt-6 pb-2">
        <Pressable onPress={() => Linking.openURL(shopifyCharge.confirmation_url)} className="flex flex-row items-center">
          <EcoText className="text-accent-secondary" fontSize="sm">
            Manage Subscription On Shopify
          </EcoText>
          <EcoIcon className="text-accent-secondary ml-1" size="lg" name="open_in_new" />
        </Pressable>
      </View>
    </EcoCard>
  );
}
