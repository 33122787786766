import { EcoButton, EcoCard, EcoLoader, EcoText } from '@components/shared';
import { Merchant, MerchantAdmin } from '@ecocart/entities';
import { useGlobal } from '@hooks/useGlobal';
import { useOverlay } from '@hooks/useOverlay';
import { getMerchantAdmin } from '@utils/api/merchant';
import { regenerateShopifyVariants } from '@utils/api/shopify';
import { sendMerchantAdminUpdateMessage } from '@utils/api/slack';
import { colors } from '@utils/tailwind';
import { useState } from 'react';

interface Props {
  merchant: Merchant | undefined;
  merchantIsLoading: boolean;
  merchantAdmin: MerchantAdmin | undefined;
}

export default function ShopifyVariantsCard({ merchant, merchantIsLoading, merchantAdmin }: Props): JSX.Element {
  const [isRegenerating, setIsRegenerating] = useState<boolean>(false);
  const { showSuccess, showWarning, handleError } = useOverlay();
  const { session } = useGlobal();

  const handleRegenerateVariantsPress = async () => {
    const isConfirmed = confirm(
      `Are you sure you want to regenerate ${merchantAdmin?.shopName}'s variants? This process will effect their shopify store and may take a few minutes to complete!`
    );
    if (!isConfirmed || !merchantAdmin?.shopName || !merchantAdmin?.accessToken) return;

    try {
      const dbMerchantAdmin = await getMerchantAdmin(merchantAdmin?.shopName);

      if (dbMerchantAdmin.setupStep === 'variant_creation') {
        showWarning(`System is currently creating ${merchantAdmin?.shopName}'s variants. Please try again shortly.`);
      } else {
        setIsRegenerating(true);
        await regenerateShopifyVariants(merchantAdmin.shopName, merchantAdmin.accessToken, merchantAdmin.manufacturingCalculationType);
        setIsRegenerating(false);
        await sendMerchantAdminUpdateMessage('shopify_variants_regenerated', session, { merchantAdmin });
        showSuccess('Successfully regenerated variants');
      }
    } catch (error) {
      handleError(error as any);
      setIsRegenerating(false);
    }
  };

  return (
    <EcoCard
      title={`Shopify Variants ${merchant?.variantIds?.[0] ? '(available)' : '(unavailable)'}`}
      topRightComponent={
        <EcoButton isDisabled={isRegenerating} size="sm" onPress={handleRegenerateVariantsPress}>
          Regenerate Variants
        </EcoButton>
      }
    >
      {merchantIsLoading ? (
        <EcoLoader size="large" className="py-9" color={colors.primary[400]} />
      ) : (
        <EcoText fontSize="base" color="subdued">
          {(merchant?.variantIds || []).join(', ')}
        </EcoText>
      )}
    </EcoCard>
  );
}
