import { EcoAvatar, EcoButton, EcoIcon, EcoImage, EcoLink, EcoText } from '@components/shared';
import { useNavigation } from '@react-navigation/native';
import { PUBLIC_IMG_URL } from '@utils/constants/config';
import { SIDE_NAV_WIDTH, TOP_NAV_HEIGHT } from '@utils/constants/navigation';
import { Gap } from '@utils/layout';
import { storage, StorageKey } from '@utils/storage';
import { BoxShadow } from '@utils/styles/box-shadow';
import { Pressable, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useGlobal } from '../hooks/useGlobal';
import { useOverlay } from '../hooks/useOverlay';
import { useWindow } from '../hooks/useWindow';

export function EcoCartAdminHeader(): JSX.Element {
  const { session, logout } = useGlobal();
  const { showMenu, hideMenu } = useOverlay();
  const { isDesktopWeb, isWide } = useWindow();
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();
  const user = session?.user;
  const userFullName = user?.firstName + ' ' + user?.lastName;

  const handleLogout = async () => {
    await storage.removeItem(StorageKey.login_redirect);
    logout();
    hideMenu();
  };

  const handleOpenMenu = () => {
    if (!session) return;

    showMenu({
      content: (
        <View style={BoxShadow('softdeep')} className="p-3 rounded-sm bg-white">
          <View className="p-3 flex-row justify-start" style={Gap()}>
            <EcoAvatar name={userFullName} className="mr-4" />
            <View style={Gap()}>
              <View>
                <EcoText fontWeight="semibold">{userFullName}</EcoText>
                <EcoText fontSize="sm">{session?.merchantAdmin?.companyName}</EcoText>
                {/* <EcoText fontSize="sm">
							Admin
						</EcoText> */}
              </View>
              <EcoButton
                size="sm"
                onPress={() => {
                  navigation.navigate('UsersTab', { screen: 'User', params: { id: session?.user.id } });
                  hideMenu();
                }}
              >
                View Profile
              </EcoButton>
            </View>
          </View>

          <Pressable onPress={handleLogout}>
            <View className="justify-start flex-row items-center p-3" style={Gap(2)}>
              <EcoIcon name="logout" className="text-gray-700" />
              <EcoText fontSize="sm" color="disabled-inverted">
                Log out
              </EcoText>
            </View>
          </Pressable>
        </View>
      ),
      config: {
        position: {
          top: insets.top + TOP_NAV_HEIGHT,
          right: 16,
          left: 0
        }
      }
    });
  };

  return (
    <View className="z-[10001] w-full" style={{ height: TOP_NAV_HEIGHT }}>
      {session ? (
        <View className="flex-row justify-between items-center w-full h-full">
          {isDesktopWeb ? (
            <EcoLink href="/home" style={{ width: SIDE_NAV_WIDTH, height: TOP_NAV_HEIGHT }}>
              <EcoImage width={50} height={TOP_NAV_HEIGHT} resizeMode="contain" src={PUBLIC_IMG_URL + '/ecocart-icon.svg'} />
            </EcoLink>
          ) : null}
          <View className="px-4 grow h-full border-b border-gray-100 justify-end items-center flex-row">
            <Pressable onPress={handleOpenMenu}>
              <View className="flex-row items-center" style={Gap(2)}>
                <EcoAvatar
                  name={userFullName}
                  // src={session?.merchantAdmin?.logoUrl || DEFAULT_SHOP_LOGO}
                />
                {isWide ? (
                  <View>
                    <EcoText fontWeight="semibold">{userFullName}</EcoText>
                    <EcoText fontSize="sm">{session?.merchantAdmin?.companyName}</EcoText>
                  </View>
                ) : null}
              </View>
            </Pressable>
          </View>
        </View>
      ) : null}
    </View>
  );
}
