import { OverlayComponentProps } from '@context/overlay';
import { useWindow } from '@hooks/useWindow';
import React, { useCallback, useEffect, useState } from 'react';
import { Pressable, TouchableWithoutFeedback, View } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withSpring, withTiming } from 'react-native-reanimated';
import { EcoIcon } from './EcoIcon';

interface Props extends OverlayComponentProps {}

const FADE_DURATION = 250;
const backgroundColor = 'rgba(0,0,0,.3)';

export function EcoModal({ hide, children, nativeID }: Props): JSX.Element {
  const { height } = useWindow();
  const [asyncChildren, setAsyncChildren] = useState<React.ReactNode>();

  const display = useSharedValue<number | 'flex' | 'none' | undefined>('none');
  const translateY = useSharedValue(height);
  const opacity = useSharedValue(0);

  useEffect(() => {
    if (children) {
      fadeIn();
    } else {
      fadeOut();
    }
  }, [children]);

  const fadeIn = useCallback(() => {
    setAsyncChildren(children);

    translateY.value = withSpring(0, { damping: 100 });
    opacity.value = withTiming(1, { duration: FADE_DURATION });
    display.value = 'flex';
  }, [children]);

  const fadeOut = useCallback(() => {
    translateY.value = withTiming(height * 1.5, { duration: FADE_DURATION });
    opacity.value = withTiming(0, { duration: FADE_DURATION });

    setTimeout(() => {
      display.value = 'none';
      setAsyncChildren(children);
    }, FADE_DURATION);
  }, []);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        // TODO: Enhancement - add confirmation modal to confirm closing
        fadeOut();
      }
    }
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [fadeOut]);

  const backdropStyle = useAnimatedStyle(() => ({
    display: display.value,
    opacity: opacity.value
  }));

  const contentStyle = useAnimatedStyle(() => ({
    opacity: opacity.value,
    transform: [{ translateY: translateY.value }]
  }));

  return (
    <TouchableWithoutFeedback onPress={hide}>
      <Animated.View
        style={[
          {
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            backgroundColor,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1000
          },
          backdropStyle
        ]}
      >
        <TouchableWithoutFeedback>
          <Animated.View style={contentStyle}>
            {asyncChildren ? (
              <>
                <Pressable
                  nativeID={`${nativeID}__close-button`}
                  className="absolute mt-5 mr-5 top-0 right-0 z-[1] p-2 bg-white hover:bg-gray-50 transition-colors duration-300 rounded-full"
                  onPress={hide}
                >
                  <EcoIcon name="close" size="4xl" className="text-gray-600" />
                </Pressable>
                <View className="items-center">{asyncChildren}</View>
              </>
            ) : null}
          </Animated.View>
        </TouchableWithoutFeedback>
      </Animated.View>
    </TouchableWithoutFeedback>
  );
}
