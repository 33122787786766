import { EcoTab, EcoTabContent, EcoTable, EcoTabs, EcoTag } from '@components/shared';
import { ColumnConfig } from '@components/shared/EcoTable.utils';
import EcoScrollView from '@components/views/EcoScrollView';
import { User } from '@ecocart/entities';
import { DataQuery } from '@ecocart/universal-utils';
import { BackLink } from '@navigation/BackLink';
import { GlobalScreenProps } from '@navigation/types';
import { useNavigation } from '@react-navigation/native';
import { useQuery } from '@tanstack/react-query';
import { getMerchantAdmin } from '@utils/api/merchant';
import { getUsers } from '@utils/api/user';
import { EcoCartApiMerchantTabs, EcoCartGlobalMerchantTabs } from '@utils/constants/tabs';
import { format } from 'date-fns';
import React from 'react';
import { View } from 'react-native';

interface UserUI extends User {
  merchantsArr: string[];
}

export default function MerchantUsersScreen({ route }: GlobalScreenProps): JSX.Element {
  const shopName = route?.params?.shopName;
  const navigation = useNavigation();
  const { data, isLoading } = useQuery(['users'], getUsers);
  const { data: merchantAdmin } = useQuery(['merchantAdmin', shopName], () => getMerchantAdmin(shopName));
  const EcoCartMerchantTabs = merchantAdmin?.platform === 'api' ? EcoCartApiMerchantTabs : EcoCartGlobalMerchantTabs;

  const hydrateForUI = (user: User): UserUI => {
    return {
      ...user,
      merchantsArr: Object.keys(user.merchants).filter(Boolean)
    };
  };

  const defaultDataQuery: DataQuery<UserUI> = {
    sorts: [{ dir: 'desc', field: 'updatedAt' }]
  };

  const columnConfig: ColumnConfig<UserUI>[] = [
    {
      field: 'active',
      format: ({ active }) => <EcoTag type={active ? 'info' : 'error'}>{active ? 'Active' : 'Inactive'}</EcoTag>
    },
    { field: 'id', searchable: true, onPress: ({ id }) => navigation.navigate('UsersTab', { screen: 'User', params: { id } }), flex: 2 },
    { field: 'firstName', format: ({ firstName, lastName }) => `${firstName} ${lastName}`, searchable: true },
    {
      field: 'createdAt',
      title: 'Created',
      format: ({ createdAt }) => (createdAt ? format(new Date(createdAt), 'MMM d, yyyy') : '--')
    },
    {
      field: 'updatedAt',
      title: 'Updated',
      format: ({ updatedAt }) => (updatedAt ? format(new Date(updatedAt), 'MMM d, yyyy') : '--')
    },
    { field: 'userType', searchable: true },
    { field: 'merchantsArr', title: 'merchants', flex: 2, searchable: true }
  ];

  return (
    <EcoScrollView>
      <View className="flex-row">
        <BackLink href="/merchants" label="Back to Merchants" />
      </View>
      <EcoTabs
        tabs={EcoCartMerchantTabs.map(({ label, screenName }) => {
          return {
            label,
            screenName,
            screenParams: { shopName }
          } as EcoTab;
        })}
        prefix="Merchant"
        activeScreen="MerchantUsers"
      >
        <EcoTabContent>
          <EcoTable
            data={(data || []).map(hydrateForUI).filter(({ merchantsArr }) => merchantsArr.includes(shopName))}
            isLoading={isLoading}
            columns={columnConfig}
            defaultDataQuery={defaultDataQuery}
          />
        </EcoTabContent>
      </EcoTabs>
    </EcoScrollView>
  );
}
