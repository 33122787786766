import Container from '@components/dashboards/components/Container';
import DateRangePicker from '@components/dashboards/components/DateRangePicker';
import { EcoIcon, EcoText, EcoTooltip } from '@components/shared';
import { DATE_PICKER_VALUES, DatePickerValue, MerchantAdmin } from '@ecocart/entities';
import { useFeaturePermission } from '@hooks/useFeaturePermission';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Gap } from '@utils/layout';
import { GlobalStylesProps } from '@utils/prop-types';
import { useFormik } from 'formik';
import { noop } from 'lodash';
import { View } from 'react-native';
import { CoreMetricsCards } from './CoreMetricsCards';
import { PerformanceChart } from './PerformanceChart';
import RepeatCustomerRate from './RepeatCustomerRate';

interface Props extends GlobalStylesProps {
  merchantAdmin?: MerchantAdmin;
}

export default function SustainabilityJourney({ merchantAdmin }: Props): JSX.Element {
  const route = useRoute();

  const navigation = useNavigation();
  let dateRangeVal = DATE_PICKER_VALUES.find((val) => val === 'YTD') as DatePickerValue; // defaults to YTD
  const period = (route.params as { period: DatePickerValue })?.period;

  if (DATE_PICKER_VALUES.includes(period)) {
    dateRangeVal = period;
  }

  const formik = useFormik({
    initialValues: { dateRange: dateRangeVal },
    enableReinitialize: true,
    onSubmit: noop
  });

  const { values } = formik;
  const { dateRange } = values;
  const { isFeaturePermissioned } = useFeaturePermission();

  return (
    <Container className="mt-8">
      <View style={Gap(6)}>
        <View className="flex-row justify-between w-full items-center">
          <EcoText fontSize="xl" fontWeight="medium">
            Your Sustainability Journey
          </EcoText>
          <View className="flex-row items-center">
            <DateRangePicker
              value={dateRange}
              onDateClick={(val) => {
                navigation.setParams({ period: val });
              }}
            />
            <EcoTooltip
              placement="left"
              tooltip={
                <EcoText fontSize="sm" color="enabled-inverted">
                  'All Time' displays data since installation dating back to 1/1/2023
                </EcoText>
              }
            >
              <EcoIcon name="info" className="text-tertiary-500" />
            </EcoTooltip>
          </View>
        </View>
        <View className="flex-row flex-wrap" style={Gap(6)}>
          <CoreMetricsCards dateRange={dateRange} merchantAdmin={merchantAdmin} />
        </View>
        <View className="lg:flex-row w-full flex-wrap" style={Gap(6)}>
          {isFeaturePermissioned && <RepeatCustomerRate merchantAdmin={merchantAdmin} />}
          <PerformanceChart dateRange={dateRange} merchantAdmin={merchantAdmin} />
        </View>
      </View>
    </Container>
  );
}
