import { CurrencyCode, MerchantStatsPeriod, UserType } from '@ecocart/entities';

type ExcludeMessage<T> = T extends { message: string } ? never : keyof T;

type MerchantStatsKeys = ExcludeMessage<MerchantStatsPeriod>;

interface CardProps {
  key: MerchantStatsKeys;
  title: string;
  amount: number | undefined;
  trend: number | undefined;
  currencyCode?: CurrencyCode;
  format?: 'decimal' | 'currency' | 'percent' | 'unit';
  badge?: string;
  minimumFractionDigits: number;
  maximumFractionDigits: number;
  permissions: UserType[];
}

export const baseCardsConfig: CardProps[] = [
  {
    key: 'totalCarbonOffset' as MerchantStatsKeys,
    title: 'Carbon Offset',
    amount: undefined,
    trend: undefined,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    permissions: ['api_admin', 'merchant_admin', 'ecocart_admin']
  },
  {
    key: 'totalOffsetOrderAmount' as MerchantStatsKeys,
    title: 'Contributions',
    format: 'currency',
    amount: undefined,
    trend: undefined,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    permissions: ['merchant_admin', 'ecocart_admin']
  },
  {
    key: 'totalOffsetOrderCount' as MerchantStatsKeys,
    title: 'Total Offset Orders',
    amount: undefined,
    trend: undefined,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    permissions: ['api_admin', 'merchant_admin', 'ecocart_admin']
  },
  // Disabling it until backend has more accurate data
  // {
  //   key: 'adoptionRate',
  //   title: 'Adoption Rate',
  //   format: 'percent',
  //   amount: undefined,
  //   trend: undefined,
  //   minimumFractionDigits: 2,
  //   maximumFractionDigits: 2,
  //   permissions: ['merchant_admin']
  // },
  {
    key: 'averageCarbonOffset' as MerchantStatsKeys,
    title: 'Avg Offset',
    amount: undefined,
    trend: undefined,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    permissions: ['api_admin', 'merchant_admin', 'ecocart_admin']
  }
  // Disabling it until backend has more accurate data
  // {
  //   key: 'averageOffsetOrderAmount' as MerchantStatsKeys,
  //   title: 'Avg Offset Cost',
  //   format: 'currency',
  //   amount: undefined,
  //   trend: undefined,
  //   minimumFractionDigits: 2,
  //   maximumFractionDigits: 2,
  //   permissions: ['merchant_admin', 'ecocart_admin']
  // }
];
