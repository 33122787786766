import { EcoButton, EcoImage, EcoRadioButtonCards, EcoText } from '@components/shared';
import { IMAGE_ASSETS } from '@ecocart/universal-utils';
import { useClaimFormContext } from '@screens/ClaimsScreen';
import { ClaimIssueType } from '@utils/constants/claims';
import { Gap } from '@utils/layout';
import { CreateValidationSchema } from '@utils/validation';
import { useFormik } from 'formik';
import { View } from 'react-native';

export const ClaimsFormIssueItem = (): JSX.Element => {
  const { setStep, claimDetails, setClaimDetails } = useClaimFormContext();

  const onSubmit = () => {
    setStep('IssueDetails');
    setClaimDetails({ ...claimDetails, ...formik.values });
  };

  const formik = useFormik({
    initialValues: {
      issue: claimDetails.issue
    },
    onSubmit,
    validateOnChange: true,
    validationSchema: CreateValidationSchema(['issue'])
  });

  return (
    <>
      <View>
        <EcoImage src={claimDetails.companyLogo} height={40} resizeMode="contain" />
        <EcoText fontSize="lg" textAlign="center" color="subdued">
          Order Number {claimDetails.orderNumber}
        </EcoText>
        <View className="mt-6" style={Gap(1)}>
          <EcoText fontSize="4xl" fontWeight="semibold" textAlign="center">
            What's the issue?
          </EcoText>
          <EcoText fontSize="lg" textAlign="center" color="subdued">
            Tell us what went wrong with your package
          </EcoText>
        </View>
      </View>

      <View className="my-6 min-h-[250px] justify-center">
        <form onSubmit={formik.handleSubmit}>
          <View style={Gap(4)}>
            <EcoRadioButtonCards
              cardClassName="min-h-0 p-4"
              direction="vertical"
              itemStyle={{ justifyContent: 'start' }}
              value={formik.values.issue}
              items={Object.entries(ClaimIssueType).map(([key, value]) => ({
                value: key,
                label: value
              }))}
              onValueChange={(value) => {
                formik.setFieldValue('issue', value);
              }}
            />
            <View>
              <EcoButton onPress={formik.handleSubmit} fullWidth className="mt-4" isDisabled={!formik.values.issue}>
                Continue
              </EcoButton>
            </View>
          </View>
        </form>
      </View>
      <View className="flex-row justify-center items-center mt-8">
        <EcoText fontSize="xs">Powered By</EcoText>
        <EcoImage src={IMAGE_ASSETS.ECOCART} height={14} width={100} resizeMode="contain" />
      </View>
    </>
  );
};
