import { EcoImage, EcoText } from '@components/shared';
import { useRewardsModal } from '@hooks/useRewardsModal';
import { PUBLIC_IMG_URL } from '@utils/constants/config';
import { Gap } from '@utils/layout';
import { View } from 'react-native';
import { RewardCard, RewardCardProps } from './RewardCard';

export const REWARD_DESCRIPTIONS = {
  tapcart: 'Build stronger customer relationships with mobile apps',
  klaviyo: 'Marketing Automation for Email Marketing, SMS & CDP',
  marketing_spend: 'Leverage your EcoCart rewards to cover ad spend or agency fees',
  giveaways: 'Team up with EcoCart to create a giveaway champaign to drive sales',
  treet: 'Most flexible branded resale solution for modern brands',
  maergo: 'Maergo provides affordable & low-carbon nationwide delivery',
  buy_from_ecocart: "You can purchase certified carbon credits from Ecocart's portfolio of vetted offsetting projects",
  life_cycle_analysis: 'The Ecocart Plus subscription supports multiple Life Cycle Analysis of your products'
};

export const RewardsRedemption = (): JSX.Element => {
  const { showRewardsFormModal } = useRewardsModal();

  const marketCardConfig: RewardCardProps[] = [
    {
      title: 'Tapcart',
      description: REWARD_DESCRIPTIONS.tapcart,
      logo: <EcoImage width={120} height={28} src={PUBLIC_IMG_URL + '/tapcart.svg'} />,
      ctaButtonProps: { onPress: () => showRewardsFormModal('tapcart') }
    },
    {
      title: 'Klaviyo',
      description: REWARD_DESCRIPTIONS.klaviyo,
      logo: <EcoImage width={91} height={28} src={PUBLIC_IMG_URL + '/klaviyo.svg'} />,
      ctaButtonProps: { onPress: () => showRewardsFormModal('klaviyo') }
    },
    {
      title: 'Cover your marketing spend',
      description: REWARD_DESCRIPTIONS.marketing_spend,
      ctaButtonProps: { onPress: () => showRewardsFormModal('marketing_spend') }
    },
    {
      title: 'Giveaways',
      description: REWARD_DESCRIPTIONS.giveaways,
      ctaButtonProps: { onPress: () => showRewardsFormModal('giveaways') }
    }
  ];

  const sustainabilityCardConfig: RewardCardProps[] = [
    {
      title: 'Treet',
      description: REWARD_DESCRIPTIONS.treet,
      logo: <EcoImage width={88} height={24} src={PUBLIC_IMG_URL + '/treet.png'} />,
      ctaButtonProps: { onPress: () => showRewardsFormModal('treet') }
    },
    {
      title: 'Maergo',
      description: REWARD_DESCRIPTIONS.maergo,
      logo: <EcoImage width={103} height={24} src={PUBLIC_IMG_URL + '/maergo.svg'} />,
      ctaButtonProps: { onPress: () => showRewardsFormModal('maergo') }
    },
    {
      title: 'Buy carbon offsets from us',
      description: REWARD_DESCRIPTIONS.buy_from_ecocart,
      ctaButtonProps: { onPress: () => showRewardsFormModal('buy_from_ecocart') }
    },
    {
      title: 'Life Cycle analysis',
      description: REWARD_DESCRIPTIONS.life_cycle_analysis,
      ctaButtonProps: { onPress: () => showRewardsFormModal('life_cycle_analysis') }
    }
  ];

  return (
    <View style={Gap(3)} className="mt-5">
      <EcoText fontSize="xl" fontWeight="medium">
        Redeem your rewards
      </EcoText>
      <View style={Gap()}>
        <EcoText fontSize="lg" color="subdued">
          Marketing
        </EcoText>
        <View className="flex-row flex-wrap" style={Gap(4)}>
          {marketCardConfig.map((config) => (
            <RewardCard key={config.title} {...config} />
          ))}
        </View>
      </View>

      <View style={Gap()} className="mt-4">
        <EcoText fontSize="lg" color="subdued">
          Sustainability
        </EcoText>
        <View className="flex-row flex-wrap" style={Gap(4)}>
          {sustainabilityCardConfig.map((config) => (
            <RewardCard key={config.title} {...config} />
          ))}
        </View>
      </View>
    </View>
  );
};
