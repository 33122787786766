import { EcoButton, EcoInput, FormError } from '@components/shared';
import { User } from '@ecocart/entities';
import { useOverlay } from '@hooks/useOverlay';
import { createSelf, getUser } from '@utils/api/user';
import { Gap } from '@utils/layout';
import { GlobalFormProps } from '@utils/prop-types';
import { CreateValidationSchema } from '@utils/validation';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { View } from 'react-native';

interface Props extends GlobalFormProps {
  user: Partial<User>;
  shpat: string;
  userIsReauthenticating: boolean;
}

export default function NewMemberForm({ user, shpat, userIsReauthenticating, onSuccess, onError }: Props): JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { handleError } = useOverlay();

  const onSubmit = async (_user: User) => {
    setIsSubmitting(true);

    try {
      if (!_user.userType) _user.userType = 'merchant_admin';
      const response = await createSelf(_user, shpat);

      !!onSuccess && onSuccess({ ...response, ..._user });
      setIsSubmitting(false);
    } catch (error: any) {
      if (error.message.includes('User Already Exists')) {
        // ** consider: add DELETE,PUT /user/:id/merchant-access/:shopName
        // ** or something similar, so that avoid needing existing merchants to save
        // fetch current user, since we're adding to existing.
        const self = await getUser(_user.id, shpat);
        // if set in form, update firstName, lastName
        if (_user.firstName) self.firstName = _user.firstName;
        if (_user.lastName) self.firstName = _user.lastName;
        onError && onError(self);
      } else {
        handleError(error);
      }
      setIsSubmitting(false);
    }
  };

  return (
    <Formik initialValues={{ ...new User(), ...user }} validationSchema={CreateValidationSchema(['id'])} onSubmit={onSubmit}>
      {({ handleChange, handleBlur, handleSubmit, values, errors, touched, isValid }) => (
        <View style={Gap(2)}>
          <EcoInput
            className="w-full"
            label="First Name"
            value={values.firstName}
            onChangeText={handleChange('firstName')}
            placeholder="First Name"
          />
          <EcoInput
            className="w-full"
            label="Last Name"
            value={values.lastName}
            onChangeText={handleChange('lastName')}
            placeholder="Last Name"
          />
          <View>
            <EcoInput
              className="w-full"
              label="Email"
              value={values.id}
              onChangeText={handleChange('id')}
              onBlur={handleBlur('id')}
              placeholder="Email"
            />
            <FormError errors={errors} touched={touched} field="id" />
          </View>
          <EcoButton
            nativeID={userIsReauthenticating ? 'signup-form__reauthenticate-button' : 'signup-form__create-account-button'}
            className="mt-4"
            fullWidth
            isDisabled={!isValid || isSubmitting}
            isLoading={isSubmitting}
            onPress={handleSubmit}
          >
            {userIsReauthenticating ? 'Reauthenticate' : 'Create Account'}
          </EcoButton>
        </View>
      )}
    </Formik>
  );
}
