import { GlobalStylesProps } from '@utils/prop-types';
import React from 'react';
import { View } from 'react-native';

interface ContainerProp extends GlobalStylesProps {
  children: React.ReactNode;
}

export default function Container({ children, style }: ContainerProp): JSX.Element {
  return (
    <View className="px-4 md:px-8 lg:px-11" style={style}>
      <View className="w-full lg:max-w-[1180px] mx-auto">{children}</View>
    </View>
  );
}
