import { Gap } from '@utils/layout';
import { View } from 'react-native';
import { EcoImage } from './EcoImage';
import { EcoText } from './EcoText';

interface EcoEmptyStateProps {
  imgSrc?: string;
  title: string;
  subTitle?: string;
}

export default function EcoEmptyState({ imgSrc, title, subTitle }: EcoEmptyStateProps): JSX.Element {
  return (
    <View className="items-center max-w-[430px]" style={Gap(5)}>
      {imgSrc && <EcoImage resizeMode="contain" height={120} width={200} src={imgSrc} />}
      <View style={Gap()}>
        <EcoText fontSize="4xl" fontWeight="semibold" textAlign="center">
          {title}
        </EcoText>
        {subTitle && (
          <EcoText color="subdued" fontSize="lg" textAlign="center">
            {subTitle}
          </EcoText>
        )}
      </View>
    </View>
  );
}
