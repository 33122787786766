import { EcoPicker, EcoPickerProps } from '@components/shared/EcoPicker';
import { EcoText } from '@components/shared/EcoText';
import clsx from 'clsx';
import { get } from 'lodash';
import * as React from 'react';
import { StyleProp, View } from 'react-native';
import { twMerge } from 'tailwind-merge';
import { FormErrorProps } from './FormError';

interface FormPickerProps extends EcoPickerProps, Partial<FormErrorProps> {
  label: string;
  disabled?: boolean;
  style?: StyleProp<any>;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  hasError?: boolean;
}

export function FormPicker({
  label,
  items,
  style,
  labelClassName,
  inputClassName,
  disabled,
  hasError,
  errors,
  field,
  touched,
  ...otherProps
}: FormPickerProps): JSX.Element {
  const errorMsg = get(errors, field || '') as string | undefined;
  const showError = hasError || (errorMsg && field && touched?.[field]);
  return (
    <View className={twMerge('h-max flex-auto')} style={style}>
      {label ? (
        <EcoText
          fontSize="xs"
          color={hasError ? 'danger' : 'subdued'}
          className={clsx('py-1', labelClassName, disabled && 'text-gray-300', showError && 'text-danger')}
        >
          {label}
        </EcoText>
      ) : null}
      <EcoPicker className={twMerge('m-0', inputClassName, !!showError && 'border-danger-700')} items={items} {...otherProps} />
      {showError ? (
        <EcoText fontSize="2xs" color="danger" className="pb-1 mt-1">
          {errorMsg || 'Invalid selection'}
        </EcoText>
      ) : (
        <></>
      )}
    </View>
  );
}
