import { EcoText } from '@components/shared/EcoText';
import { colors } from '@utils/tailwind';
import { nativeID } from '@utils/types/nativeID';
import clsx from 'clsx';
import { noop } from 'lodash';
import { useRef } from 'react';
import { Pressable, View } from 'react-native';
import { useActive, useHover } from 'react-native-web-hooks';

interface Props {
  nativeID?: nativeID;
  label: string;
  isDisabled?: boolean;
  isMatch?: boolean;
  onPress?: () => void;
}

export function EcoTab({ nativeID, label, onPress = noop, isDisabled, isMatch }: Props): JSX.Element {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const isActive = useActive(ref);

  const getTabTextColor = () => {
    if (!isDisabled && !isMatch && !isHovered && !isActive) return colors.gray[500];
    if (isDisabled) return colors.gray[200];
    if (isMatch || (isHovered && !isDisabled)) return colors.secondary[500];
    if (isActive) return colors.secondary[700];
  };

  return (
    <Pressable
      accessibilityRole="tab"
      className={clsx('relative justify-center items-center px-6 py-4', {
        'bg-gray-50': isDisabled
      })}
      disabled={isDisabled}
      style={{ outlineOffset: '-2px' }}
      nativeID={nativeID}
      onPress={onPress}
      ref={ref}
    >
      <EcoText fontSize="base" textAlign="center" fontWeight="semibold" style={{ color: getTabTextColor() }}>
        {label}
      </EcoText>
      {/* Underline when it's a match. Deliberately use this approach instead of setting border directly in "Pressable" so that the tab content doesn't jump */}
      <View
        className={clsx('w-full absolute bottom-0 left-0 z-30', {
          'border-b-2 border-secondary-500': isMatch && !isHovered,
          'border-b border-gray-100': !isMatch || isHovered
        })}
      />
    </Pressable>
  );
}
