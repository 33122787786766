import { EcoAccordion, EcoButton, EcoCard, EcoInput, EcoRadioButtons, EcoText, EcoToggle, EcoWidget, FormPicker } from '@components/shared';
import { MerchantFileUploader } from '@components/uploaders/MerchantFileUploader';
import { ConfigContainer } from '@components/views/ConfigContainer';
import { LOCALES, Merchant } from '@ecocart/entities';
import { IMAGE_ASSETS, translations } from '@ecocart/universal-utils';
import { useFeaturePermission } from '@hooks/useFeaturePermission';
import { useOverlay } from '@hooks/useOverlay';
import { BackLink } from '@navigation/BackLink';
import { useNavigation } from '@react-navigation/native';
import { MerchantContext } from '@screens/MerchantWidgetsScreen';
import { updateMerchant } from '@utils/api/merchant';
import { Gap } from '@utils/layout';
import { CreateValidationSchema } from '@utils/validation';
import { useFormik } from 'formik';
import { get, pick } from 'lodash';
import { useContext, useState } from 'react';
import { View } from 'react-native';
import { VariantValue, getBodyPlaceholder, getInitialValues } from './EducationTouchpointDesigner.utils';
import {
  alignmentItems,
  convertCountryCodeToEnglishName,
  fieldPaths,
  getPlainImageUrl,
  getProps,
  getSelectedAlignmentValue,
  getTranslationPath,
  getZIndexProps,
  handleAlignmentChange,
  handleBgImgUrlChange,
  handleTypeCaseChange,
  typeCaseItems
} from './utils';

export function EducationTouchpointDesigner({ widgetKey }: { widgetKey: string }): JSX.Element {
  const isPermissioned = useFeaturePermission('qa');
  const navigation = useNavigation();
  const { showSuccess, handleError } = useOverlay();
  const { merchant } = useContext(MerchantContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // logo variant aka attribution
  const [variant, setVariant] = useState<VariantValue>('standard');
  const { widgetMap } = merchant || {};
  const isValidWidgetKey = widgetMap && widgetKey in widgetMap;

  if (!isValidWidgetKey) {
    navigateToWidgetList();
  }

  function navigateToWidgetList() {
    if (!merchant) return;
    return navigation.navigate('MerchantWidgetTemplates', { shopName: merchant?.shopName, template: 'promo' });
  }

  const onSubmit = async () => {
    if (!merchant) return;

    setIsSubmitting(true);
    const updatedMerchant: Partial<Merchant> = {
      widgetMap: {
        ...merchant.widgetMap,
        [widgetKey]: values
      }
    };

    try {
      await updateMerchant(merchant.shopName, updatedMerchant);
      showSuccess('Successfully updated merchant');
      setIsSubmitting(false);
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: widgetMap?.[widgetKey] || getInitialValues(),
    onSubmit,
    validateOnChange: true,
    validationSchema: CreateValidationSchema(['elements']),
    enableReinitialize: true
  });

  if (!isPermissioned) return <></>;

  const { values, handleChange, setFieldValue, isValid, handleSubmit } = formik;

  const handleLogoChange = (variant: VariantValue) => {
    let newECLogoUrl;

    switch (variant) {
      case 'black_logo':
        newECLogoUrl = IMAGE_ASSETS.ECOCART_BLACK;
        break;
      case 'standard':
        newECLogoUrl = IMAGE_ASSETS.ECOCART;
        break;
      case 'white':
        newECLogoUrl = IMAGE_ASSETS.ECOCART_WHITE;
        break;
      default:
        break;
    }
    setFieldValue(fieldPaths.logoURL, newECLogoUrl);
  };

  if (!get(values, fieldPaths.logoURL)) {
    handleLogoChange(variant);
  }

  function handleBgImgChange(val?: string) {
    handleBgImgUrlChange(formik, val);
    handleChange(fieldPaths.wrapperBorderRadius)(val ? get(values, fieldPaths.containerBorderRadius, '') : '');
  }

  function getLogoVariantName() {
    switch (get(values, fieldPaths.logoURL)) {
      case IMAGE_ASSETS.ECOCART_BLACK:
        return 'black_logo';
      case IMAGE_ASSETS.ECOCART_WHITE:
        return 'white';
      default:
        return 'standard';
    }
  }

  const subHeaderEnabled = get(values, fieldPaths.subHeaderDisabled) !== true;

  return (
    <>
      <View className="pb-4">
        <BackLink onPress={navigateToWidgetList} label="Back To Widgets" />
      </View>

      <View style={Gap()}>
        <EcoCard>
          <EcoText fontSize="xl" fontWeight="semibold" className="mb-2">
            Preview
          </EcoText>
          <View className="w-full">{values && merchant && <EcoWidget widget={values} merchant={merchant} />}</View>
        </EcoCard>
        <EcoCard>
          <ConfigContainer>
            {/* Configuration component */}
            <form onSubmit={formik.handleSubmit}>
              <View style={Gap(8)}>
                <View style={Gap(6)}>
                  <View style={Gap()}>
                    <EcoText fontSize="xl" fontWeight="medium">
                      Widget Name
                    </EcoText>
                    <EcoInput label="Name" {...getProps(formik, fieldPaths.name)} />
                  </View>

                  <View style={Gap()}>
                    <EcoText fontSize="xl" fontWeight="medium">
                      Container
                    </EcoText>

                    <View style={Gap(2)}>
                      <EcoText fontSize="lg" fontWeight="medium">
                        Merchant Assets
                      </EcoText>

                      <View style={Gap()}>
                        <View className="flex-row items-end" style={Gap()}>
                          <EcoInput
                            label="Background Image"
                            className="w-2/3"
                            {...getProps(formik, fieldPaths.backgroundImg)}
                            value={getPlainImageUrl(get(values, fieldPaths.backgroundImg) as string)}
                            onChangeText={handleBgImgChange}
                            placeholder="https://"
                          />
                          <MerchantFileUploader
                            transparentErrorColor
                            {...pick(getProps(formik, fieldPaths.backgroundImg), ['errors', 'touched', 'field'])}
                            onSuccess={handleBgImgChange}
                            onError={function (error: any): void {
                              throw new Error(`Error was thrown when uploading background image file. Erorr is ${error}`);
                            }}
                            shopName={merchant?.shopName || ''}
                            fileName={`${widgetKey}_background`}
                          />
                        </View>
                        <EcoInput
                          style={{ flex: 1 }}
                          label="Background Color"
                          {...getProps(formik, fieldPaths.backgroundColor)}
                          placeholder="#123456"
                        />
                      </View>
                    </View>
                  </View>

                  <View style={Gap(4)}>
                    <View>
                      <EcoText fontSize="lg" fontWeight="medium">
                        Styles
                      </EcoText>
                      <View className="flex-row" style={Gap()}>
                        <EcoInput
                          label="Z-Index"
                          className="w-1/5"
                          {...getZIndexProps(formik, fieldPaths.wrapperZIndex)}
                          placeholder="10, 20, 100, etc"
                        />
                        <EcoInput
                          className="flex-1"
                          label="Border Width"
                          {...getProps(formik, fieldPaths.wrapperBorderWidth)}
                          placeholder="1px"
                        />
                        <EcoInput
                          className="flex-1"
                          label="Border Color"
                          {...getProps(formik, fieldPaths.wrapperBorderColor)}
                          placeholder="#0F8354"
                        />
                        <EcoInput
                          className="flex-1"
                          label="Border Radius"
                          {...getProps(formik, fieldPaths.wrapperBorderRadius)}
                          placeholder="4px"
                        />
                      </View>
                    </View>

                    <View style={Gap(2)}>
                      <EcoText fontSize="lg" fontWeight="medium">
                        Behavior
                      </EcoText>
                      <View>
                        <EcoText fontSize="base" fontWeight="medium">
                          Mobile
                        </EcoText>
                        <View className="flex-row flex-wrap" style={Gap()}>
                          <EcoInput className="flex-1" label="Margins" {...getProps(formik, fieldPaths.wrapperMargin)} placeholder="0px" />
                          <EcoInput
                            className="flex-1"
                            label="Padding"
                            {...getProps(formik, fieldPaths.containerPadding)}
                            placeholder="12px"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Width"
                            {...getProps(formik, fieldPaths.wrapperWidth)}
                            placeholder="100px, 50%, etc"
                          />
                          <FormPicker
                            inputClassName="flex-1"
                            label="Default Alignment"
                            items={alignmentItems}
                            value={getSelectedAlignmentValue(values, 'wrapper', 'mobile')}
                            onValueChange={(val) => handleAlignmentChange(formik, val, 'mobile', 'wrapper')}
                          />
                          <EcoInput
                            className="flex-1"
                            label="Z-Index"
                            {...getZIndexProps(formik, fieldPaths.wrapperZIndex)}
                            placeholder="10, 20, 100, etc"
                          />
                        </View>
                      </View>
                      <View>
                        <EcoText fontSize="base" fontWeight="medium">
                          Desktop
                        </EcoText>
                        <View className="flex-row flex-wrap" style={Gap()}>
                          <EcoInput
                            className="flex-1"
                            label="Margins"
                            {...getProps(formik, fieldPaths.wrapperMarginMD)}
                            placeholder="0px"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Padding"
                            {...getProps(formik, fieldPaths.containerPaddingMD)}
                            placeholder="12px"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Width"
                            {...getProps(formik, fieldPaths.wrapperWidthMD)}
                            placeholder="100px, 50%, etc"
                          />
                          <FormPicker
                            inputClassName="flex-1"
                            label="Default Alignment"
                            items={alignmentItems}
                            value={getSelectedAlignmentValue(values, 'wrapper', 'desktop')}
                            onValueChange={(val) => handleAlignmentChange(formik, val, 'desktop', 'wrapper')}
                          />
                          <EcoInput
                            className="flex-1"
                            label="Z-Index"
                            {...getZIndexProps(formik, fieldPaths.wrapperZIndexMD)}
                            placeholder="10, 20, 100, etc"
                          />
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={Gap()}>
                    <EcoText fontSize="xl" fontWeight="medium">
                      Content
                    </EcoText>
                    <View>
                      <EcoText fontSize="lg" fontWeight="medium">
                        Header
                      </EcoText>
                      <View style={Gap()}>
                        <EcoInput
                          className="flex-1 mb-3 md:mb-0"
                          label="Header - English"
                          {...getProps(formik, getTranslationPath('header', 'en'))}
                          placeholder={translations.en.template_yourOrderWillOffsetAmountCarbon}
                        />

                        <EcoAccordion header="Show Translations">
                          {LOCALES.map((locale) => (
                            <EcoInput
                              key={`header-${locale}`}
                              className="flex-1"
                              label={`Header - ${convertCountryCodeToEnglishName(locale)}`}
                              {...getProps(formik, getTranslationPath('header', locale))}
                              placeholder={translations[locale].template_offsetYourOrderAtCheckoutWith}
                            />
                          ))}
                        </EcoAccordion>
                      </View>
                    </View>

                    <View>
                      <EcoText fontWeight="medium">Styles</EcoText>
                      <View className="flex-row" style={Gap()}>
                        <EcoInput
                          className="flex-1"
                          label="Font Family"
                          {...getProps(formik, fieldPaths.headerFontFamily)}
                          placeholder="system-ui"
                        />
                        <EcoInput
                          className="flex-1"
                          label="Font Size"
                          {...getProps(formik, fieldPaths.headerFontSize)}
                          placeholder="14px"
                        />
                        <EcoInput
                          className="flex-1"
                          label="Line Height"
                          {...getProps(formik, fieldPaths.headerLineHeight)}
                          placeholder="16px"
                        />
                        <EcoInput
                          className="flex-1"
                          label="Font Weight"
                          {...getProps(formik, fieldPaths.headerFontWeight)}
                          placeholder="300, 500, bold..."
                        />
                        <EcoInput
                          className="flex-1"
                          label="Font Color"
                          {...getProps(formik, fieldPaths.headerColor)}
                          placeholder="#333333"
                        />
                        <FormPicker
                          label="Type Case"
                          inputClassName="flex-1"
                          items={typeCaseItems}
                          value={get(values, fieldPaths.headerTextTransform, '') as string}
                          onValueChange={(val) => handleTypeCaseChange(formik, val, 'header')}
                        />
                      </View>
                    </View>
                  </View>

                  <View style={Gap()}>
                    <View className="flex-row items-center" style={Gap()}>
                      <EcoText fontSize="xl" fontWeight="medium">
                        Body
                      </EcoText>
                      <EcoToggle
                        value={subHeaderEnabled}
                        onValueChange={(val: boolean) => {
                          setFieldValue(fieldPaths.subHeaderDisabled, !val);
                        }}
                      />
                    </View>

                    {subHeaderEnabled && (
                      <View style={Gap()}>
                        <View>
                          <EcoText fontWeight="medium">Body Content</EcoText>

                          <EcoInput
                            className="flex-1 mb-2"
                            label="Body - English"
                            {...getProps(formik, getTranslationPath('subHeader', 'en'))}
                            placeholder={getBodyPlaceholder('en')}
                          />
                          <EcoAccordion header="Show Translations">
                            {LOCALES.map((locale) => (
                              <EcoInput
                                key={`body-${locale}`}
                                className="flex-1 mb-3 md:mr-3 md:mb-0"
                                label={`Body - ${convertCountryCodeToEnglishName(locale)}`}
                                {...getProps(formik, getTranslationPath('subHeader', locale))}
                                placeholder={getBodyPlaceholder(locale)}
                              />
                            ))}
                          </EcoAccordion>
                        </View>
                        <View>
                          <EcoText fontWeight="medium">Styles</EcoText>

                          <View className="flex-row" style={Gap()}>
                            <EcoInput
                              className="flex-1"
                              label="Font Family"
                              {...getProps(formik, fieldPaths.subHeaderFontFamily)}
                              placeholder="Roboto"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Size"
                              {...getProps(formik, fieldPaths.subHeaderFontSize)}
                              placeholder="11px"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Line Height"
                              {...getProps(formik, fieldPaths.subHeaderLineHeight)}
                              placeholder="14px"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Weight"
                              {...getProps(formik, fieldPaths.subHeaderFontWeight)}
                              placeholder="300, 500, bold..."
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Color"
                              {...getProps(formik, fieldPaths.subHeaderColor)}
                              placeholder="#636363"
                            />
                            <FormPicker
                              label="Type Case"
                              inputClassName="flex-1"
                              items={typeCaseItems}
                              value={get(values, fieldPaths.subHeaderTextTransform, '') as string}
                              onValueChange={(val) => handleTypeCaseChange(formik, val, 'subheader')}
                            />
                          </View>
                        </View>
                      </View>
                    )}
                  </View>
                  <View style={Gap()}>
                    <EcoText fontSize="xl" fontWeight="medium">
                      Attribution
                    </EcoText>
                    <EcoRadioButtons
                      value={getLogoVariantName()}
                      items={[
                        { label: 'Standard', value: 'standard' },
                        { label: 'Black Logo', value: 'black_logo' },
                        { label: 'White', value: 'white' }
                      ]}
                      onValueChange={(variant: VariantValue) => {
                        setVariant(variant);
                        handleLogoChange(variant);
                      }}
                    />
                  </View>
                </View>

                <EcoButton isDisabled={isSubmitting || !isValid} onPress={handleSubmit}>
                  Save
                </EcoButton>
              </View>
            </form>

            {/* End of Configuration component */}
          </ConfigContainer>
        </EcoCard>
      </View>
    </>
  );
}
