import { FontSize, fontSize, TextAlign } from '@utils/tailwind';

export const fontSizeMap: Record<FontSize, string> = {
  '2xs': 'text-2xs',
  'xs': 'text-xs',
  'sm': 'text-sm',
  'base': 'text-base',
  'lg': 'text-lg',
  'xl': 'text-xl',
  '2xl': 'text-2xl',
  '3xl': 'text-3xl',
  '4xl': 'text-4xl',
  '5xl': 'text-5xl',
  '6xl': 'text-6xl',
  '7xl': 'text-7xl',
  '8xl': 'text-8xl',
  '9xl': 'text-9xl',
  '10xl': 'text-10xl',
  '11xl': 'text-11xl'
};

export const getFontSizeFromKey = (key: FontSize): number | undefined => {
  if (!key || !fontSize[key]?.[0]) return;
  return Number(fontSize[key][0].replace('px', ''));
};

export const textAlignMap: Record<TextAlign, string> = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right'
};
