import { DatePickerValue, Locale, MerchantOrderCountData, UserType } from '@ecocart/entities';
import { convertKgToLb, formatNumber, getWeightUnit } from '@ecocart/universal-utils';
import { colors, fontFamily } from '@utils/tailwind';
import { Chart, ChartOptions } from 'chart.js';
import { format, parse } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { omit, pick } from 'lodash';

// Manually created type for the tooltip context. To be confirmed
interface TooltipContext {
  chart: Chart;
  label: string;
  parsed: { [key: string]: any };
  raw: any;
  formattedValue: string;
  dataset: object;
  datasetIndex: number;
  dataIndex: number;
  element: any; // or a more specific type if available
}

// Keeping only the dates so that when it's in loading state, we are not displaying wrong labels/legends
export const DUMMY_CHART_DATA = new Array(9).fill('1970-01-01').map((date) => ({ date }));

export type ChartData = Array<{
  date: string;
  offset_carbon?: string | number;
  total_carbon?: string | number;
  ecocart_orders?: string | number;
  total_orders?: string | number;
}>;
export interface Dataset {
  label?: string;
  key: string;
  backgroundColor?: string | string[];
  borderColor?: string | string[];
  data: (number | string | undefined)[];
}

export const snakeCaseToSpaced = (text: string): string => {
  return text
    .split('_')
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join(' ');
};

export const convertEcoDataToChartJSData = <T extends any[]>(
  data: T = DUMMY_CHART_DATA as T
): { labels: string[]; datasets: Dataset[] } => {
  const initialValue = {
    labels: [] as any[],
    datasets: Object.keys(data[0])
      .filter((prop) => prop !== 'date')
      .map(
        (key) =>
          ({
            key,
            label: snakeCaseToSpaced(key),
            data: []
          } as Dataset)
      )
  };

  return data.reduce((acc, item) => {
    const { date, ...dataProps } = item;
    // API uses UTC time to query and also returns dates in UTC format
    // That's why we need to keep the dates in UTC time so that the dates are shifted by the timezone
    acc.labels = [...acc.labels, utcToZonedTime(date, 'UTC')];

    for (const [key, value] of Object.entries(dataProps)) {
      // First find the element in the data array to update based on the item key
      // The object key can get out of order, and if we don't do this, the data may be flipped
      const itemToUpdate = acc.datasets.find((item: Dataset) => item.key === key);
      itemToUpdate.data = [...itemToUpdate.data, value];
    }
    return acc;
  }, initialValue);
};

// Used by existing Total Emissions, Customer Adoption Rate and CO2 Capture charts
// To be deprecated once we have fully migrated to the new merchant dashboard
export const legacyConvertEcoDataToChartJSData = <T extends any[]>(
  data: T = DUMMY_CHART_DATA as T
): { labels: string[]; datasets: Dataset[] } => {
  return data.reduce(
    (acc, item) => {
      const { date, ...dataProps } = item;
      const formattedDate = format(utcToZonedTime(date, 'UTC'), 'MMM d');
      acc.labels.push(formattedDate);
      Object.keys(dataProps).forEach((prop: string, index) => {
        (acc.datasets[index].data as any).push(dataProps[prop]);
      });
      return acc;
    },
    {
      labels: [] as any[],
      datasets: Object.keys(data[0])
        .filter((prop) => prop !== 'date')
        .map(
          (key) =>
            ({
              key,
              label: snakeCaseToSpaced(key),
              data: []
            } as Dataset)
        )
    }
  );
};

export const defaultPlugins = {
  legend: {
    display: false
  }
};

export const defaultGrid = {
  lineWidth: 0,
  borderWidth: 0
};

export const defaultTicks = {
  font: {
    family: fontFamily['sora-regular'],
    size: 10,
    color: colors.gray[600]
  }
};

// Default config for vertical grid line to show when tooltip is active
// Inspired by https://www.youtube.com/watch?v=rLUwF1UQcbI and some tweaks were made
export const tooltipLine = {
  id: 'tooltipLine',
  // beforeDraw to draw the line on top of the tooltip
  beforeDraw: (chart: Chart & { tooltip: any }): void => {
    const { ctx, tooltip, chartArea } = chart;
    if (tooltip?._active && tooltip._active.length && ctx) {
      ctx.save();
      const activePoint = tooltip._active[0];

      ctx.beginPath();
      ctx.moveTo(activePoint.element.x, chartArea.top);
      ctx.lineTo(activePoint.element.x, chartArea.bottom);
      ctx.lineWidth = 2;
      ctx.strokeStyle = '#011732';
      ctx.stroke();
      ctx.restore();

      ctx.beginPath();
      ctx.setLineDash([5, 7]);
      ctx.moveTo(activePoint.element.x, chartArea.top);
      ctx.lineTo(activePoint.element.x, chartArea.bottom);
      ctx.lineWidth = 2;
      ctx.strokeStyle = '#011732';
      ctx.stroke();
      ctx.restore();
    }
  }
};

type AdminTypes = Extract<UserType, 'api_admin' | 'merchant_admin' | 'ecocart_admin'>;
type Options = { userType?: UserType; locale?: Locale };
type MerchantOrderCountDataWithoutTotal = Omit<MerchantOrderCountData | ChartData, 'total_orders'>;

const dataKeysByUserType: Record<AdminTypes, ('total_orders' | 'ecocart_orders' | 'carbon_offset' | 'adoption_rate')[]> = {
  merchant_admin: ['total_orders', 'ecocart_orders', 'adoption_rate'],
  ecocart_admin: ['total_orders', 'ecocart_orders', 'adoption_rate'],
  api_admin: ['ecocart_orders', 'carbon_offset']
};

const generateRows = (dataPoints: any[], { userType, locale }: Options) => {
  if (userType === 'ecocart_admin' || !userType || !locale) return [];

  return (
    dataKeysByUserType[userType]
      .map((key) => {
        const data = dataPoints.find((dp) => dp.dataset.key === key);
        if (!data) return;
        // Adoption rate is a computed value from total orders and ecocart orders
        if (key === 'adoption_rate') {
          const total = dataPoints.find((dp) => dp.dataset.key === 'total_orders');
          const ecocartOrders = dataPoints.find((dp) => dp.dataset.key === 'ecocart_orders');
          // if it is division by 0, we don't return anything in this map function
          if (total.raw === 0) return;
          return { label: snakeCaseToSpaced(key), value: `${((ecocartOrders.raw / total.raw) * 100).toFixed(2)}%` };
        }
        // Note: data from backend is in kg
        if (key === 'carbon_offset') {
          const unit = getWeightUnit(locale);
          return {
            label: snakeCaseToSpaced(key),
            value: unit === 'lb' ? `${formatNumber(convertKgToLb(data.raw))} lb` : `${data.formattedValue}kg`
          };
        }
        return { label: data.dataset.label, value: data.formattedValue };
      })
      // Filter out any undefined values caused by division of 0
      .filter(Boolean) as { label: string; value: string }[]
  );
};

const getOrCreateTooltip = (chart: Chart) => {
  let tooltipEl = chart.canvas.parentNode?.querySelector('div');

  const toolTipStyles: Partial<CSSStyleDeclaration> = {
    background: '#011732',
    width: '180px',
    color: 'white',
    opacity: '1',
    padding: '12px',
    pointerEvents: 'none',
    borderRadius: '8px',
    position: 'absolute',
    transform: 'translate(-50%, -120%)',
    transition: 'all .1s ease'
  };

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    Object.assign(tooltipEl.style, toolTipStyles);

    const divRoot = document.createElement('div');
    divRoot.style.margin = '0px';

    tooltipEl.appendChild(divRoot);
    chart.canvas.parentNode?.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const rowDivStyles: Partial<CSSStyleDeclaration> = {
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: '5px',
  marginBottom: '10px'
};

const labelAndValueDivStyles: Partial<CSSStyleDeclaration> = {
  fontSize: '12px',
  lineHeight: '16px'
};

const labelDivStyles = { ...labelAndValueDivStyles, fontFamily: 'sora-regular' };
const valueDivStyles = { ...labelAndValueDivStyles, fontFamily: 'sora-semibold' };

const externalTooltipHandler = (context: { chart: Chart; tooltip: any }, options: Options): void => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity == '0') {
    tooltipEl.style.opacity = '0';
    return;
  }

  // Set Text
  if (tooltip.body) {
    const rows = options.userType && options.locale ? generateRows(tooltip.dataPoints, options) : [];
    // Tooltip title
    const titleWrapper = document.createElement('div');
    const titleStyles: Partial<CSSStyleDeclaration> = {
      fontFamily: 'sora-semibold',
      fontSize: '10px',
      lineHeight: '12px',
      marginBottom: '10px'
    };
    Object.assign(titleWrapper.style, titleStyles);
    titleWrapper.innerHTML = format(parse(tooltip.title, 'MMM dd, yyyy, h:mm:ss a', new Date()), 'MMM dd yyyy');

    // Data Rows
    const rowsWrapper = document.createElement('div');
    rows.forEach((row) => {
      const rowDiv = document.createElement('div');
      Object.assign(rowDiv.style, rowDivStyles);

      const labelDiv = document.createElement('div');
      const valueDiv = document.createElement('div');

      Object.assign(labelDiv.style, labelDivStyles);
      Object.assign(valueDiv.style, valueDivStyles);

      labelDiv.innerText = row.label;
      valueDiv.innerText = row.value;

      rowDiv.appendChild(labelDiv);
      rowDiv.appendChild(valueDiv);
      rowsWrapper.appendChild(rowDiv);
    });

    const divRoot = tooltipEl.querySelector('div');

    // Remove old children
    if (divRoot) {
      while (divRoot.firstChild) {
        divRoot.firstChild.remove();
      }

      // Add new children
      divRoot.appendChild(titleWrapper);
      divRoot.appendChild(rowsWrapper);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 8 + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  }
};

const scaleOptions: ChartOptions['scales'] = {
  day: {
    type: 'time',
    time: {
      unit: 'day',
      displayFormats: {
        day: 'MMM dd'
      }
    }
  },
  week: {
    type: 'time',
    time: {
      unit: 'week',
      displayFormats: {
        week: 'MMM dd'
      }
    }
  },
  month: {
    type: 'time',
    time: {
      unit: 'month',
      displayFormats: {
        month: 'MMM yyyy'
      }
    }
  }
};

const getChartScaleKey = (dateRange: DatePickerValue): keyof typeof scaleOptions => {
  if (['6M', '1Y', 'YTD', 'ALL_TIME'].includes(dateRange)) return 'month';
  if (['1M', '3M'].includes(dateRange)) return 'week';
  if (dateRange === '1W') return 'day';
  return 'day';
};

export const getChartOptions = (
  dateRange: DatePickerValue,
  { userType, locale }: { userType?: UserType; locale?: Locale }
): ChartOptions => {
  const options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false
    },
    plugins: {
      tooltip: {
        mode: 'index',
        enabled: false,
        intersect: false,
        position: 'nearest',
        padding: 12,
        backgroundColor: '#011732',
        bodyColor: 'white',
        cornerRadius: 8,
        displayColors: false,
        yAlign: 'bottom',
        external: (args) => externalTooltipHandler(args, { userType, locale })
      },
      ...defaultPlugins
    },
    scales: {
      x: {
        grid: defaultGrid,
        ticks: defaultTicks,
        ...scaleOptions[getChartScaleKey(dateRange)]
      },
      y: {
        grid: defaultGrid,
        ticks: defaultTicks
      }
    },
    elements: {
      point: {
        radius: 0
      }
    }
  };

  return options;
};

const dataForUserType: Record<AdminTypes, ('total_orders' | 'ecocart_orders' | 'carbon_offset')[]> = {
  api_admin: ['carbon_offset', 'ecocart_orders'],
  merchant_admin: ['total_orders', 'ecocart_orders'],
  ecocart_admin: ['total_orders', 'ecocart_orders']
};

export function filterDataByUserType(
  data?: MerchantOrderCountDataWithoutTotal[],
  options?: Options
): Partial<MerchantOrderCountDataWithoutTotal>[] | undefined {
  const { userType, locale } = options || {};
  if (!data || !userType || !locale || !(['api_admin', 'merchant_admin', 'ecocart_admin'] as UserType[]).includes(userType)) return;

  const keys = dataForUserType[userType as AdminTypes];

  return data.map((d) => pick(d, ['date', ...keys]));
}

export function removeTotalOrdersFromData(
  data?: MerchantOrderCountData[] | ChartData | null
): MerchantOrderCountDataWithoutTotal[] | undefined {
  if (!data) return;
  return data.map((d) => omit(d, 'total_orders'));
}

export const getBarChartOptions = (labelFormatter?: (context: TooltipContext) => string): ChartOptions => {
  return {
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          font: {
            family: 'sora-regular',
            size: 10
          }
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true
        },
        ticks: {
          font: {
            family: 'sora-regular',
            size: 10
          }
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
        caretSize: 0,
        displayColors: false,
        backgroundColor: '#011732',
        cornerRadius: 8,
        padding: 12,
        bodyFont: {
          size: 12,
          lineHeight: '14px',
          family: 'sora-semibold'
        },
        bodyAlign: 'center',
        titleFont: {
          size: 12,
          lineHeight: '14px',
          family: 'sora-semibold'
        },
        titleAlign: 'center',
        callbacks: {
          label: function (context: any) {
            return labelFormatter?.(context) || context.formattedValue;
          }
        },
        position: 'nearest',
        yAlign: 'bottom'
      }
    }
  };
};
