import ProjectForm from '@components/forms/ProjectForm';
import { EcoButton, EcoCard, EcoImage, EcoTable } from '@components/shared';
import { ColumnConfig } from '@components/shared/EcoTable.utils';
import EcoScrollView from '@components/views/EcoScrollView';
import { Project, ProjectFlat } from '@ecocart/entities';
import { useNavigation } from '@react-navigation/native';
import { useQuery } from '@tanstack/react-query';
import { getProjects } from '@utils/api/projects';
import { omit } from 'lodash';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useOverlay } from '../hooks/useOverlay';
import { GlobalScreenProps } from '../navigation/types';

function initProject(): Omit<Project, 'createdAt' | 'updatedAt' | 'totalCO2Offset'> {
  const project = new Project();
  const _project = omit(project, ['createdAt', 'updatedAt', 'totalCO2Offset']);
  _project.active = false;
  _project.id = uuidv4();
  return _project;
}

export default function ManageProjectsScreen({}: GlobalScreenProps): JSX.Element {
  const { hideModal, showModal } = useOverlay();
  const navigation = useNavigation();
  const { data, isLoading, refetch } = useQuery<Project[]>(['projects'], getProjects);

  const columnConfig: ColumnConfig<ProjectFlat>[] = [
    { field: 'images', flex: 0.5, format: ({ images }) => <EcoImage src={images[0]} width={50} /> },
    {
      field: 'name',
      searchable: true,
      onPress: ({ id }) => navigation.navigate('ManageProject', { id }),
      flex: 3
    },
    { field: 'location', searchable: true, flex: 3 },
    { field: 'type', flex: 2 },
    { field: 'active', flex: 1 }
  ];

  const handleCreateNewProject = () => {
    const project = initProject();

    showModal({
      nativeID: 'new-project-modal',
      content: (
        <EcoCard title="New Project" size="xl" style={{ overflowY: 'auto', maxHeight: '90vh' }}>
          <ProjectForm
            project={project}
            onSuccess={() => {
              hideModal();
              refetch();
            }}
          />
        </EcoCard>
      )
    });
  };

  return (
    <EcoScrollView>
      <EcoCard
        title="Project"
        topRightComponent={
          <EcoButton size="md" onPress={handleCreateNewProject}>
            Create New Project
          </EcoButton>
        }
      >
        <EcoTable
          data={data}
          isLoading={isLoading}
          columns={columnConfig}
          defaultDataQuery={{
            take: 50,
            sorts: [
              { field: 'active', dir: 'desc' },
              { field: 'name', dir: 'asc' }
            ]
          }}
        />
      </EcoCard>
    </EcoScrollView>
  );
}
