import { StyleProp } from 'react-native';

export type BoxShadowType = 'none' | 'default' | 'softdeep' | 'deep';

const Height: Record<BoxShadowType, number> = { none: 0, default: 4, softdeep: 10, deep: 0 };
const ShadowColor: Record<BoxShadowType, 'transparent' | 'black'> = {
  none: 'transparent',
  default: 'black',
  softdeep: 'black',
  deep: 'black'
};
const ShadowOpacity: Record<BoxShadowType, number> = { none: 0, default: 0.15, softdeep: 0.1, deep: 0.3 };
const ShadowRadius: Record<BoxShadowType, number> = { none: 0, default: 8, softdeep: 8.3, deep: 8.3 };
const Elevation: Record<BoxShadowType, number> = { none: 0, default: 8, softdeep: 0, deep: 1 };

export const BoxShadow = (boxShadowType: BoxShadowType = 'default'): StyleProp<any> => {
  const boxShadowCssObject = {
    shadowColor: ShadowColor[boxShadowType],
    shadowOffset: {
      width: 0,
      height: Height[boxShadowType]
    },
    shadowOpacity: ShadowOpacity[boxShadowType],
    shadowRadius: ShadowRadius[boxShadowType]
  };

  if (boxShadowType !== 'default') {
    return { ...boxShadowCssObject, elevation: Elevation[boxShadowType] };
  }
  return boxShadowCssObject;
};
