import { Gap } from '@utils/layout';
import { GlobalStylesProps } from '@utils/prop-types';
import clsx from 'clsx';
import { View } from 'react-native';
import { EcoIcon } from './EcoIcon';
import { IconName, IconVariant } from './EcoIcon.utils';
import { EcoText } from './EcoText';

export const STATUS_BEACON_TYPES = ['active', 'inactive', 'error', 'info'] as const;
export type StatusBeaconType = (typeof STATUS_BEACON_TYPES)[number];

interface Props extends GlobalStylesProps {
  type?: StatusBeaconType;
  text: string;
}

const ColorMap: Record<StatusBeaconType, { dark: string; light: string; iconName: IconName; iconVariant: IconVariant }> = {
  active: {
    dark: 'success-700',
    light: 'success-50',
    iconName: 'circle',
    iconVariant: 'filled'
  },
  inactive: {
    dark: 'gray-700',
    light: 'gray-100',
    iconName: 'circle',
    iconVariant: 'outlined'
  },
  error: {
    dark: 'danger-700',
    light: 'danger-50',
    iconName: 'circle',
    iconVariant: 'filled'
  },
  info: {
    dark: 'secondary-500',
    light: 'secondary-50',
    iconName: 'cached',
    iconVariant: 'outlined'
  }
};

export function EcoStatusBeacon({ type, text, style }: Props): JSX.Element {
  if (!type) return <></>;

  const { dark, light, iconName, iconVariant } = ColorMap[type];

  return (
    <View className={clsx('py-2 px-3 rounded-lg flex flex-row justify-center items-center', `bg-${light}`)} style={[Gap(1), style]}>
      <EcoIcon size="sm" name={iconName} variant={iconVariant} className={`text-${dark}`} />
      <EcoText fontSize="sm" fontWeight="medium" color={dark as any}>
        {text}
      </EcoText>
    </View>
  );
}
