import { EcoLoader } from '@components/shared/EcoLoader';
import { Dataset } from '@utils/charts';
import { colors } from '@utils/tailwind';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip
} from 'chart.js';
import 'chartjs-adapter-date-fns';

import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { StyleProp } from 'react-native';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend, TimeScale);

interface Props {
  isLoading?: boolean;
  type: 'bar' | 'line' | 'donut';
  data: {
    labels: string[];
    datasets: Dataset[];
  };
  options: any;
  plugins?: any[];
  loaderHeight?: number;
  style?: StyleProp<any>;
}

export function EcoChart({ type, options, data, plugins, style, isLoading, loaderHeight = 350 }: Props): JSX.Element {
  if (isLoading) return <EcoLoader size="large" color={colors.primary.DEFAULT} style={{ height: loaderHeight }} />;
  if (type === 'bar') {
    return <Bar style={style} options={options} plugins={plugins} data={data} />;
  } else if (type === 'line') {
    return <Line style={style} options={options} plugins={plugins} data={data} />;
  } else if (type === 'donut') {
    return <Doughnut style={style} options={options} plugins={plugins} data={data} />;
  } else {
    return <></>;
  }
}
