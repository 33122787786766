import { EcoChart, EcoText } from '@components/shared';
import { formatNumber } from '@ecocart/universal-utils';
import { useRewards } from '@hooks/useRewards';
import { getBarChartOptions } from '@utils/charts';
import { Gap } from '@utils/layout';
import { colors } from '@utils/tailwind';
import { View } from 'react-native';
import { getMonthLabelsFromCurrentYear } from './RewardsChart.utils';

export const RewardsChart = ({ shopName }: { shopName: string }): JSX.Element => {
  const { monthlyValues, isFetching } = useRewards(shopName);

  if (!shopName) return <></>;

  return (
    <View style={Gap(4)} className="bg-white rounded-md p-4">
      <EcoText fontSize="lg" fontWeight="medium">
        Amount earned each month (USD)
      </EcoText>

      <EcoChart
        style={{ maxHeight: 300, padding: 16 }}
        isLoading={isFetching}
        type="bar"
        options={getBarChartOptions((context) => formatNumber(context.raw, { style: 'currency', currency: 'USD' }))}
        data={{
          labels: getMonthLabelsFromCurrentYear(),
          datasets: [
            {
              backgroundColor: colors.algea[500],
              borderColor: colors.algea[500],
              data: monthlyValues,
              key: 'amount_earned_each_month'
            }
          ]
        }}
      />
    </View>
  );
};
