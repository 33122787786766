import { EcoTab } from '@components/shared';
import { MerchantTouchPointsTabs } from '@utils/constants/tabs';
import { useGlobal } from './useGlobal';

const tabMap: Record<'merchant_admin' | 'ecocart_admin', EcoTab[]> = {
  merchant_admin: MerchantTouchPointsTabs,
  // TODO: Add EcoCartGlobalMerchantTabs
  ecocart_admin: MerchantTouchPointsTabs
};

export const useTouchPointsTabs = (): EcoTab[] => {
  const { session } = useGlobal();
  return session?.user.userType && session?.user.userType !== 'api_admin' ? tabMap[session.user.userType] : [];
};
