import { EcoCard, EcoImage, EcoKeyboardAvoidingView } from '@components/shared';
import { IMAGE_ASSETS } from '@ecocart/universal-utils';

import { ClaimsFormConfirmation } from '@components/forms/ClaimsFormConfirmation';
import { ClaimsFormIssueDetail } from '@components/forms/ClaimsFormIssueDetail';
import { ClaimsFormIssueItem } from '@components/forms/ClaimsFormIssueItem';
import { ClaimsFormLogin } from '@components/forms/ClaimsFormLogin';
import { ClaimsFormResolution } from '@components/forms/ClaimsFormResolution';
import { ClaimsFormReview } from '@components/forms/ClaimsFormReview';
import { BoxShadow } from '@utils/styles/box-shadow';
import { ClaimContext, ClaimFormValues, Step } from '@utils/types/claims';
import { createContext, useContext, useState } from 'react';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

export const ClaimFormContext = createContext<ClaimContext | null>(null);

export const initialFormValues: ClaimFormValues = {
  orderNumber: '',
  email: '',
  issue: '',
  issueDetails: '',
  resolution: '',
  fileUrl: '',
  shopName: '',
  localFileUri: '', // for file upload preview from local machine
  file: null // for file upload,
};

export const useClaimFormContext = (): ClaimContext => {
  const context = useContext(ClaimFormContext);
  if (context === undefined) {
    throw new Error('useCount must be used within a ClaimFormContext');
  }
  return context as ClaimContext;
};

export default function ClaimsScreen(): JSX.Element {
  const [step, setStep] = useState<Step>('Login');
  const [claimDetails, setClaimDetails] = useState<ClaimFormValues>(initialFormValues);

  const components: Record<Step, JSX.Element> = {
    Login: <ClaimsFormLogin />,
    IssueItem: <ClaimsFormIssueItem />,
    IssueDetails: <ClaimsFormIssueDetail />,
    Resolution: <ClaimsFormResolution />,
    Review: <ClaimsFormReview />,
    Confirmation: <ClaimsFormConfirmation />
  };

  const formikContextValue = {
    claimDetails,
    setClaimDetails,
    step,
    setStep
  };

  return (
    <SafeAreaView>
      <View className="min-h-screen items-center flex-col justify-center bg-gray-50 p-8">
        <EcoImage
          resizeMode="contain"
          src="https://ecocart-public-images.s3.amazonaws.com/Boxes+bg.svg"
          className="mb-8 fixed bottom-0 left-0 right-0"
          height={120}
        />
        <View>
          <View className="flex-row justify-center mb-8">
            <EcoImage src={IMAGE_ASSETS.ECOCART} width={124} height={20} />
          </View>
          <EcoKeyboardAvoidingView>
            <EcoCard size="md" wrapperClassName="pt-8" style={BoxShadow()}>
              <ClaimFormContext.Provider value={formikContextValue}>{components[step]}</ClaimFormContext.Provider>
            </EcoCard>
          </EcoKeyboardAvoidingView>
        </View>
      </View>
    </SafeAreaView>
  );
}
