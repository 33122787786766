import InstallScriptCard from '@components/cards/InstallScriptCard';
import ShopifyScopesCard from '@components/cards/ShopifyScopesCard';
import ShopifyScriptCard from '@components/cards/ShopifyScriptCard';
import ShopifyVariantsCard from '@components/cards/ShopifyVariantsCard';
import EditShopifyProductForm from '@components/forms/EditShopifyProductForm';
import EditSkuForm from '@components/forms/EditSkuForm';
import { EcoCard } from '@components/shared';
import { Merchant, MerchantAdmin } from '@ecocart/entities';

interface Props {
  shopName: string;
  merchant: Merchant | undefined;
  merchantIsLoading: boolean;
  merchantAdmin: MerchantAdmin | undefined;
  refetch: () => void;
}

interface configProps {
  formName: string;
  component: JSX.Element | null;
}

export const useMerchantSetupConfig = ({ shopName, merchant, merchantIsLoading, merchantAdmin }: Props): configProps[] => {
  const config: configProps[] = [
    {
      formName: 'Shopify SKU',
      component: <EcoCard title="Shopify SKU">{merchantAdmin && <EditSkuForm merchantAdmin={merchantAdmin} />}</EcoCard>
    },
    {
      formName: 'Shopify Product',
      component: <EcoCard title="Shopify Product">{merchantAdmin && <EditShopifyProductForm merchantAdmin={merchantAdmin} />}</EcoCard>
    },
    {
      formName: 'Shopify Variants',
      component: <ShopifyVariantsCard merchant={merchant} merchantIsLoading={merchantIsLoading} merchantAdmin={merchantAdmin} />
    },
    {
      formName: 'Shopify Scopes',
      component: <ShopifyScopesCard shopName={shopName} />
    },
    {
      formName: 'Shopify Scripts',
      component: <ShopifyScriptCard shopName={shopName} />
    },
    {
      formName: 'Install Script',
      component: <InstallScriptCard shopName={shopName} />
    }
  ];

  return config;
};
