import { WidgetCommandCenter } from '@components/widgets/WidgetCommandCenter';
import { User } from '@ecocart/entities';
import { Gap } from '@utils/layout';
import React from 'react';
import { View } from 'react-native';
import { EcoPageTitle } from '../shared/EcoPageTitle';

interface Props {
  user: User;
}

export default function EcoCartAdminDashboard({ user }: Props): JSX.Element {
  return (
    <View style={Gap()}>
      <EcoPageTitle className="mb-5" title={`Hello, ${user?.firstName}!`} />
      <WidgetCommandCenter />
    </View>
  );
}
