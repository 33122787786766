import { RewardsChart } from '@components/rewards/RewardsChart';
import { RewardsFAQButton } from '@components/rewards/RewardsFAQButton';
import { RewardsOverview } from '@components/rewards/RewardsOverview';
import { RewardsRedemption } from '@components/rewards/RewardsRedemption';
import { RewardsScreenSubtitle } from '@components/rewards/RewardsScreenSubtitle';
import { EcoButton } from '@components/shared';
import { EcoPageTitle } from '@components/shared/EcoPageTitle';
import EcoScrollView from '@components/views/EcoScrollView';
import { useGlobal } from '@hooks/useGlobal';
import { useNavigation } from '@react-navigation/native';
import { Gap } from '@utils/layout';
import React from 'react';
import { View } from 'react-native';
import { GlobalScreenProps } from '../navigation/types';

export default function RewardsScreen({}: GlobalScreenProps): JSX.Element {
  const { session } = useGlobal();
  const navigation = useNavigation();

  const shopName = session?.merchantAdmin?.shopName;
  if (!session?.merchantAdmin?.enableEcoRewards || !shopName) return <></>;

  const openHistoryPage = () => {
    navigation.navigate('RewardsHistory');
  };

  return (
    <EcoScrollView>
      <View className="flex-row items-start justify-between">
        <EcoPageTitle className="mb-5" title="Rewards" subTitle={<RewardsScreenSubtitle />} />
        <View className="flex-row gap-2">
          <EcoButton onPress={openHistoryPage} size="sm" variant="outlined">
            View History
          </EcoButton>
          <RewardsFAQButton />
        </View>
      </View>
      <View style={Gap(4)}>
        <RewardsOverview shopName={shopName} />
        <RewardsChart shopName={shopName} />
        <RewardsRedemption />
      </View>
    </EcoScrollView>
  );
}
