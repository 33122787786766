import LoginCard from '@components/cards/LoginCard';
import ResetPasswordForm from '@components/forms/ResetPasswordForm';
import { EcoIcon, EcoKeyboardAvoidingView, EcoLink, EcoText } from '@components/shared';
import { Gap } from '@utils/layout';
import { LoginScreenView } from '@utils/styles/screen-views';
import { colors } from '@utils/tailwind';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useGlobal } from '../hooks/useGlobal';
import { GlobalScreenProps } from '../navigation/types';

export default function ResetPasswordScreen({ route }: GlobalScreenProps): JSX.Element {
  const { login } = useGlobal();

  const email = route.params?.email ? decodeURIComponent(route.params.email) : undefined;
  const verificationCode = route.params?.verificationCode ? decodeURIComponent(route.params.verificationCode) : undefined;

  const handleOnSuccess = async ({ email, password }: { email: string; password: string }): Promise<void> => {
    await login({ email, password });
  };

  return (
    <SafeAreaView style={[LoginScreenView, { width: '100%', height: '100%', backgroundColor: colors.tertiary[800] }]}>
      <EcoKeyboardAvoidingView className="flex-row justify-center items-center w-full h-full">
        {!!email && !!verificationCode ? (
          <LoginCard title="Reset Password">
            <ResetPasswordForm email={email} verificationCode={verificationCode} onSuccess={handleOnSuccess} />
            <View className="flex-row justify-center">
              <EcoLink nativeID="reset-password-form__back-to-login" href="/login" className="pt-4 flex-row justify-center items-center">
                <EcoIcon name="chevron_left" className="text-secondary-500" />
                <EcoText color="link" className="pl-2">
                  Back to Login
                </EcoText>
              </EcoLink>
            </View>
          </LoginCard>
        ) : (
          <LoginCard title="">
            <View style={Gap(6)}>
              <EcoText color="danger" textAlign="center">
                Your reset password link is invalid.
              </EcoText>
              <EcoLink nativeID="reset-password-form__back-to-login" href="/login" className="pt-4 flex-row justify-center items-center">
                <EcoIcon name="chevron_left" className="text-secondary-500" />
                <EcoText color="link" className="pl-2">
                  Back to Login
                </EcoText>
              </EcoLink>
            </View>
          </LoginCard>
        )}
      </EcoKeyboardAvoidingView>
    </SafeAreaView>
  );
}
