import { Project } from '@ecocart/entities';
import { apiCall, PROJECTS_API } from './api';

export const getProject = (id: string): Promise<Project> => {
  return apiCall('GET', `${PROJECTS_API}/projects/${id}`);
};

export const updateProject = (project: Project): Promise<Project> => {
  return apiCall('PUT', `${PROJECTS_API}/projects`, project);
};

export const getProjects = (): Promise<Project[]> => {
  return apiCall('GET', `${PROJECTS_API}/projects`);
};
