import AddCreditCardForm from '@components/forms/AddCreditCardForm';
import ConfirmAddBankAccountForm from '@components/forms/ConfirmAddBankAccountForm';
import SelectPaymentMethodForm from '@components/forms/SelectPaymentMethodForm';
import { addBankAccount, AddBankAccountRequest, getStripeInfo, PaymentType, Stripe } from '@utils/api/payments';
import { useRef } from 'react';
import { useGlobal } from './useGlobal';
import { useOverlay } from './useOverlay';

type OnComplete = (paymentType: PaymentType | null) => void;

export const usePaymentMethod = (): {
  showSelectPaymentMethodModal: (onComplete: OnComplete) => void;
} => {
  const stripe = useRef<Stripe>();
  const { session } = useGlobal();
  const shopName = session?.merchantAdmin?.shopName || '';
  const fullName = `${session?.user?.firstName} ${session?.user?.lastName}`;
  const email = (session?.user.type === 'SHOPIFY_USER' ? session.merchantAdmin?.contactEmail : session?.user?.id) ?? '';

  const { showModal, hideModal, handleError } = useOverlay();

  const showSelectPaymentMethodModal = (onComplete: OnComplete): void => {
    const onSelectPaymentSuccess = (paymentType: PaymentType) => {
      switch (paymentType) {
        case 'bank_account':
          handleAddBankAccount(onComplete);
          hideModal();
          break;
        case 'credit_card':
          handleAddCreditCard(onComplete);
          break;
        default:
          onComplete && onComplete(null);
          break;
      }
    };

    showModal({
      nativeID: 'select-payment-method-modal',
      content: <SelectPaymentMethodForm shopName={shopName} onSuccess={onSelectPaymentSuccess} />
    });
  };

  const confirmedAddBankAccount = async (stripeClientSecret: string, details: AddBankAccountRequest, onComplete: OnComplete) => {
    hideModal();

    try {
      await stripe.current?.confirmUsBankAccountSetup(stripeClientSecret);
      await addBankAccount(details);

      onComplete('bank_account');
    } catch (error) {
      console.error('error confirming bank account', error);
      onComplete(null);
    }
  };

  const handleAddBankAccount = async (onComplete: OnComplete): Promise<void> => {
    const { stripe_publishable_key, setup_intent_client_secret } = await getStripeInfo(shopName);
    stripe.current = window.Stripe(stripe_publishable_key);

    const { error, setupIntent } = await stripe.current.collectBankAccountForSetup({
      clientSecret: setup_intent_client_secret,
      params: {
        payment_method_type: 'us_bank_account',
        payment_method_data: {
          billing_details: {
            name: fullName,
            email
          },
          expand: ['payment_method']
        }
      }
    });

    if (error) {
      console.error('error in stripe collectBankAccountForSetup', error);
      // PaymentMethod collection failed for some reason.
      handleError('There was an error adding bank account, please try again.');
    }

    // TODO decide if we want to handle this state
    // else if (setupIntent.status === 'requires_payment_method') {
    //   // Customer canceled the hosted verification modal. Present them with other
    //   // payment method type options.
    // } else
    if (setupIntent?.status === 'requires_confirmation') {
      // We collected an account - possibly instantly verified, but possibly
      // manually-entered. Display payment method details and mandate text
      // to the customer and confirm the intent once they accept
      // the mandate.

      const details: AddBankAccountRequest = {
        store_domain: shopName,
        setup_intent_id: setupIntent.id,
        payment_method_id: setupIntent.payment_method,
        setup_intent_status: setupIntent.status
      };

      showModal({
        nativeID: 'confirm-add-bank-account-modal',
        content: (
          <ConfirmAddBankAccountForm
            onSuccess={() => confirmedAddBankAccount(setup_intent_client_secret, details, onComplete)}
            onCancel={() => onComplete(null)}
          />
        )
      });
    }
  };

  const handleAddCreditCard = (onComplete: OnComplete): void => {
    showModal({
      nativeID: 'add-card-modal',
      content: <AddCreditCardForm onSuccess={() => onComplete('credit_card')} />
    });
  };

  return { showSelectPaymentMethodModal };
};
