import { EcoTab } from '@components/shared';

export const MerchantSettingsTabs: EcoTab[] = [
  {
    nativeID: 'merchant-settings__general-settings-tab',
    label: 'General Settings',
    screenName: 'SettingsGeneral'
  },
  {
    nativeID: 'merchant-settings__store-details-tab',
    label: 'Store Details',
    screenName: 'SettingsStore'
  },
  {
    nativeID: 'merchant-settings__user-settings-tab',
    label: 'User Settings',
    screenName: 'SettingsUser'
  }
];

export const MerchantTouchPointsTabs: EcoTab[] = [
  {
    nativeID: 'merchant-touchpoints-homepage-tab',
    label: 'Home Page',
    screenName: 'TouchPointsHomepage'
  },
  {
    nativeID: 'merchant-touchpoints-product-page-tab',
    label: 'Product Page',
    screenName: 'TouchPointsProductPage'
  },
  {
    nativeID: 'merchant-touchpoints-slide-out-cart-tab',
    label: 'Slide Out Cart',
    screenName: 'TouchPointsSlideOutCart'
  },
  {
    nativeID: 'merchant-touchpoints-cart-page-tab',
    label: 'Cart Page',
    screenName: 'TouchPointsCartPage'
  },
  {
    nativeID: 'merchant-touchpoints-checkout-page-tab',
    label: 'Checkout',
    screenName: 'TouchPointsCheckoutPage'
  },
  {
    nativeID: 'merchant-touchpoints-post-purchase-page-tab',
    label: 'Post-purchase',
    screenName: 'TouchPointsPostPurchasePage'
  },
  {
    nativeID: 'merchant-touchpoints-other-pages-tab',
    label: 'Other Pages',
    screenName: 'TouchPointsOtherPages'
  }
];

export const ApiMerchantSettingsTabs = [
  ...MerchantSettingsTabs,
  {
    nativeID: 'merchant-settings__api-key-tab',
    label: 'API Key',
    screenName: 'SettingsApiKey'
  }
] as EcoTab[];

export const EcoCartGlobalMerchantTabs: EcoTab[] = [
  {
    label: 'Settings',
    screenName: 'MerchantSettings'
  },
  {
    label: 'Dashboard',
    screenName: 'MerchantDashboard'
  },
  {
    label: 'Projects',
    screenName: 'MerchantProjects'
  },
  {
    label: 'Rewards',
    screenName: 'MerchantRewards'
  },
  {
    label: 'Billing',
    screenName: 'MerchantBilling'
  },
  {
    label: 'Widgets',
    screenName: 'MerchantWidgets'
  },
  {
    label: 'Users',
    screenName: 'MerchantUsers'
  },
  {
    label: 'Offsetting',
    screenName: 'MerchantOffsetting'
  },
  {
    label: 'Protection',
    screenName: 'MerchantProtection'
  },
  {
    label: 'Setup',
    screenName: 'MerchantSetup'
  },
  {
    label: 'Cashback',
    screenName: 'MerchantCashbackSetting'
  }
];

export const EcoCartApiMerchantTabs: EcoTab[] = [
  {
    label: 'Settings',
    screenName: 'MerchantSettings'
  },
  {
    label: 'Dashboard',
    screenName: 'MerchantDashboard'
  },
  {
    label: 'Projects',
    screenName: 'MerchantProjects'
  },
  {
    label: 'Rewards',
    screenName: 'MerchantRewards'
  },
  {
    label: 'Billing',
    screenName: 'MerchantBilling'
  },
  {
    label: 'Users',
    screenName: 'MerchantUsers'
  },
  {
    label: 'Offsetting',
    screenName: 'MerchantOffsetting'
  },
  {
    label: 'Protection',
    screenName: 'MerchantProtection'
  },
  {
    label: 'Cashback',
    screenName: 'MerchantCashbackSetting'
  }
];

export const InternalToolsTabs: EcoTab[] = [
  {
    label: 'Translations',
    screenName: 'Translations'
  },
  {
    label: 'Design System',
    screenName: 'DesignSystem'
  }
];
