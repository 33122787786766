import React from 'react';
import { Image, ImageResizeMode, StyleProp, View } from 'react-native';

interface Props {
  src?: string;
  width?: number | string;
  height?: number | string;
  resizeMode?: ImageResizeMode;
  enableSkeletonLoader?: boolean;
  style?: StyleProp<any>;
  className?: string;
}
export function EcoImage({
  src = '',
  width = '100%',
  height,
  enableSkeletonLoader = true,
  style,
  resizeMode = 'cover'
}: Props): JSX.Element {
  if (!height) height = width;

  return (
    <View style={[{ width, height }, style]}>
      {enableSkeletonLoader && !src ? (
        // eslint-disable-next-line react-native/no-color-literals
        <View className="animate-pulse w-full h-full" style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }} />
      ) : (
        <Image accessibilityRole="image" className="w-full h-full" resizeMode={resizeMode} source={{ uri: src }} />
      )}
    </View>
  );
}
