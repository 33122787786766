import React from 'react';
import { View } from 'react-native';
import { EcoIFrameProps } from './EcoIFrame.utils';

export function EcoIFrame({ href, style, iFrameStyle, onLoad }: EcoIFrameProps): JSX.Element {
  return href ? (
    <View className="bg-white" style={style}>
      <iframe height="100%" onLoad={(e) => onLoad && onLoad(e)} width="100%" style={iFrameStyle} src={href} title={href}></iframe>
    </View>
  ) : (
    <></>
  );
}
