import { VerificationType } from '@ecocart/entities';

export const getVerificationDescription = (type: VerificationType): string => {
  switch (type) {
    case 'American Carbon Registry':
      return 'Founded in 1996 as the first private voluntary offset program in the world, ACR has twenty-five years of unparalleled experience in the development of rigorous, science-based carbon offset standards and methodologies as well as operational experience in carbon offset project registration, verification oversight and offset issuance.';
    case 'Gold Standard':
      return 'Gold Standard was established in 2003 to ensure projects that reduced carbon emissions featured the highest levels of environmental integrity and also contributed to sustainable development. With the adoption of the Paris Climate Agreement and the Sustainable Development Goals, they launched a best practice standard for climate and sustainable development interventions, Gold Standard for the Global Goals, to maximize impact, creating value for people around the world and the planet we share.';
    case 'BC Carbon Registry':
      return 'The BC Carbon Registry is established under the Greenhouse Gas Industrial Reporting and Control Act. The BC Carbon Registry enables the issuance, transfer, and retirement of compliance units used to fulfill the compliance obligation of regulated operations under the Act, and supports the Province of British Columbia’s annual commitment to have a carbon neutral public sector. Projects that follow the BC Carbon Offset Protocol are verified under ISO 14064-2.';
    case 'CDM':
      return 'The Clean Development Mechanism (CDM) is a United Nations-run carbon offset scheme that allows countries to fund greenhouse gas emissions-reducing projects in other countries. CDM offset credits are verified and certified by authorized third parties. The CDM is also meant to deliver sustainable development benefits to the host country. CDM projects generate emissions credits called Certified Emission Reductions (CERs), which are then bought and traded.';
    case 'CSA Group':
      return 'CSA Group provides registry services to clients across the globe. Their independent, transparent registry listings have grown over the past ten years and now operate across multiple sectors. They provide a platform for organizations to showcase emission reductions, carbon offset projects, sustainable products and building accessibility. CSA Group registries work to create a better, safer, more sustainable world by maintaining and expanding this valuable offering.';
    case 'Climate Action Reserve':
      return 'Climate Action Reserve encourages action to reduce greenhouse gas emissions by ensuring the environmental integrity and financial benefit of emissions reduction projects. It was founded in 2001 and establishes high quality standards for carbon offset projects, oversees independent third-party verification bodies, issues carbon credits generated from such projects and tracks the transaction of credits over time in a transparent, publicly-accessible system.';
    case 'VERRA':
    case 'Verified Carbon Standard':
      return 'Founded in 2005, Verra is a global leader helping tackle the world’s most intractable environmental and social challenges by developing and managing standards that help the private sector, countries, and civil society achieve ambitious sustainable development and climate action goals. Verra’s global standards and frameworks serve as linchpins for channeling finance towards high-impact activities that tackle some of the most pressing environmental issues of our day.';
    default:
      return '';
  }
};
