import { Dataset } from '@utils/charts';
import { Gap } from '@utils/layout';
import { View } from 'react-native';
import { EcoText } from './EcoText';

interface Props {
  datasets: Dataset[];
}

export function EcoChartLegend({ datasets }: Props): JSX.Element {
  return (
    <View className="flex-row justify-start items-start" style={Gap(5)}>
      {datasets.map(({ label, backgroundColor }, i) => (
        <View key={'legend-item' + i} style={Gap(1)} className="items-center flex-row">
          <View
            style={{
              height: 8,
              width: 8,
              borderRadius: 8,
              backgroundColor: Array.isArray(backgroundColor) ? backgroundColor[0] : backgroundColor
            }}
          />
          <EcoText fontSize="sm" color="subdued">
            {label}
          </EcoText>
        </View>
      ))}
    </View>
  );
}
