import EditCreditCardForm from '@components/forms/EditCreditCardForm';
import { EcoButton, EcoCard, EcoImage, EcoPill, EcoText } from '@components/shared';
import { useOverlay } from '@hooks/useOverlay';
import { useWindow } from '@hooks/useWindow';
import { CreditCard, StripeCustomer } from '@utils/api/payments';
import { getCreditCardBrandLogo, isCreditCardExpired } from '@utils/credit-card';
import { Gap } from '@utils/layout';
import clsx from 'clsx';
import { useEffect } from 'react';
import { View } from 'react-native';

interface Props {
  customer: StripeCustomer;
  creditCard: CreditCard;
  handleSetAsDefault: () => Promise<void>;
  handleDelete: () => Promise<void>;
  readOnly?: boolean;
  setPrimaryPaymentMethod: (paymentMethod: string) => void;
}

export default function CreditCardCard({
  customer,
  creditCard,
  setPrimaryPaymentMethod,
  handleSetAsDefault,
  handleDelete,
  readOnly = false
}: Props): JSX.Element {
  const { showModal, hideModal } = useOverlay();
  const handleHideModal = () => hideModal();
  const { isWide } = useWindow();
  const isExpired = isCreditCardExpired(creditCard);

  const handleShowEditModal = () => {
    showModal({
      nativeID: 'edit-credit-card-modal',
      content: (
        <EditCreditCardForm
          creditCard={creditCard}
          handleHideModal={handleHideModal}
          handleSetAsDefault={handleSetAsDefault}
          handleShowDeleteModal={handleShowDeleteModal}
        />
      )
    });
  };

  useEffect(() => {
    if (creditCard.default) {
      setPrimaryPaymentMethod('**** **** **** ' + creditCard.last4 || '');
    }
  }, [creditCard.default, creditCard.last4, setPrimaryPaymentMethod]);

  const handleShowDeleteModal = () => {
    showModal({
      nativeID: 'delete-credit-card-confirmation-modal',
      content: (
        <EcoCard className="md:w-[525px]">
          <EcoText className="m-6" fontWeight="semibold" fontSize="xl" textAlign="center">
            Are you sure you want to delete {creditCard.brand || 'the credit card'} ending in {creditCard?.last4}?
          </EcoText>
          <View className="flex flex-row">
            <EcoButton
              className="flex-1 mr-2"
              nativeID="delete-credit-card-confirmation-modal__cancel-button"
              variant="outlined"
              onPress={handleShowEditModal}
            >
              Cancel
            </EcoButton>
            <EcoButton
              className="flex-1 ml-2"
              nativeID="delete-credit-card-confirmation-modal__delete-button"
              colorScheme="danger"
              onPress={() => {
                handleDelete();
                handleHideModal();
              }}
            >
              Delete
            </EcoButton>
          </View>
        </EcoCard>
      )
    });
  };

  return (
    <View
      className={clsx(
        'flex flex-row flex-wrap justify-between items-center p-3 mt-1 sm:p-5 rounded-sm border-[1px]',
        isExpired ? 'border-danger-500' : 'border-gray-100'
      )}
    >
      <View className="flex flex-row justify-center items-center">
        <EcoImage resizeMode="contain" height={isWide ? 40 : 30} width={isWide ? 60 : 50} src={getCreditCardBrandLogo(creditCard.brand)} />
        <View className="flex flex-col items-start mx-4" style={Gap(1)}>
          <View className={clsx({ 'opacity-50': isExpired }, 'flex flex-row items-center gap-2')}>
            <EcoText fontWeight="semibold">{customer.customer_name}</EcoText>
            {creditCard?.default ? (
              <EcoPill variant="primary" className="ml-2">
                Primary
              </EcoPill>
            ) : null}
          </View>
          <EcoText className={clsx({ 'opacity-50': isExpired })} fontSize="sm">
            **** **** **** {creditCard.last4}
          </EcoText>
          {isExpired ? (
            <EcoText color="danger">Expired</EcoText>
          ) : (
            <EcoText fontSize="sm" color="subdued">
              Expiration {creditCard.exp_month}/{creditCard.exp_year.slice(-2)}
            </EcoText>
          )}
        </View>
      </View>
      {isExpired && !readOnly ? (
        <View className="flex justify-end items-end">
          <EcoButton
            nativeID="payment-card__delete-button"
            onPress={handleShowDeleteModal}
            colorScheme="danger"
            variant="outlined"
            size="sm"
          >
            Delete
          </EcoButton>
        </View>
      ) : null}
      {!isExpired && !readOnly ? (
        <View className="flex justify-end items-end">
          <EcoButton nativeID="payment-card__edit-button" onPress={handleShowEditModal} colorScheme="default" variant="outlined" size="sm">
            Edit
          </EcoButton>
        </View>
      ) : null}
    </View>
  );
}
