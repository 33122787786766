import { EcoCard, EcoSkeleton, EcoText } from '@components/shared';
import { MerchantAdmin } from '@ecocart/entities';
import { useWindow } from '@hooks/useWindow';
import { CurrentInvoice } from '@utils/api/payments';
import { Gap } from '@utils/layout';
import { StyleProp, View } from 'react-native';
import { offsetCardsConfig, pkgProtectionCardsConfig } from './CurrentInvoiceCard.utils';

interface Props {
  merchantAdmin: MerchantAdmin;
  currentInvoice?: CurrentInvoice;
  style?: StyleProp<any>;
  className?: string;
}

export default function CurrentInvoiceCard({ merchantAdmin, currentInvoice, style }: Props): JSX.Element {
  const { width, breakpointWidths } = useWindow();
  const mdScreen = width >= breakpointWidths.md;
  const lgScreen = width >= breakpointWidths.lg;
  const baseCardsConfig = merchantAdmin.pkgProtectionEnabled ? pkgProtectionCardsConfig : offsetCardsConfig;

  const cardsConfig = currentInvoice
    ? baseCardsConfig.map((config) => {
        return {
          ...config,
          value: config.format(currentInvoice)
        };
      })
    : baseCardsConfig;

  const getFontSize = () => {
    if (lgScreen) return '5xl';
    if (mdScreen) return '4xl';
    return '2xl';
  };

  return (
    <View className="flex-row flex-wrap" style={Gap(6)}>
      {cardsConfig.map((config) => {
        return (
          <EcoCard key={config.title} className="py-6 min-h-[100px] min-w-[200px] items-left flex-1" style={Gap(2)}>
            <EcoText fontSize="sm" fontWeight="regular" color="subdued">
              {config.title}
            </EcoText>
            <EcoText fontSize={getFontSize()} fontWeight="medium" fallback={<EcoSkeleton height="3xl" />}>
              {config.value}
            </EcoText>
          </EcoCard>
        );
      })}
    </View>
  );
}
