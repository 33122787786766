import { EcoImage, EcoText } from '@components/shared';
import { MerchantAdmin } from '@ecocart/entities';
import { useNavigation } from '@react-navigation/native';
import { useQuery } from '@tanstack/react-query';
import { getProject } from '@utils/api/projects';
import { Gap } from '@utils/layout';
import { GlobalStylesProps } from '@utils/prop-types';
import { colors } from '@utils/tailwind';
import { startCase } from 'lodash';
import React from 'react';
import { Pressable, View } from 'react-native';
import Container from './Container';

interface Props extends GlobalStylesProps {
  merchantAdmin?: MerchantAdmin;
}

export default function ProjectFundingList({ merchantAdmin }: Props): JSX.Element {
  const navigation = useNavigation();

  const projectId = merchantAdmin?.project.id || '';
  const { data: project } = useQuery(['project', projectId], () => getProject(projectId));

  // TODO update project onPress to have projectId parameter when more projects rendered in project funding table
  const onPress = () => {
    if (project == null) {
      return;
    }
    return navigation.navigate('ProjectsTab', { screen: 'Project', params: { id: project.id } });
  };
  return (
    <Container className="mt-6 relative z-[-1]">
      <View className="bg-white rounded-md p-5">
        <EcoText fontSize="lg" fontWeight="semibold" color="enabled">
          Project Funding
        </EcoText>
        <View className="pb-6" style={Gap(6)}>
          <EcoText fontSize="sm" fontWeight="regular" color="subdued">
            See where your contributions have gone
          </EcoText>
        </View>
        <View style={Gap(6)}>
          {(project ? [project] : []).map((currentProject) => {
            return (
              <View className="flex-row w-full items-center" key={currentProject.name} style={Gap(2)}>
                <View className="h-full">
                  <EcoImage width={50} height={50} className="rounded-sm overflow-hidden" src={currentProject.images[0]} />
                </View>

                <View className="flex-col lg:flex-row items-start flex-1 lg:items-center" style={Gap(1)}>
                  <View className="md:w-auto">
                    <Pressable className="flex-col" onPress={onPress} style={Gap(1)}>
                      <EcoText color="link" fontWeight="semibold">
                        {currentProject.name}
                      </EcoText>
                      <EcoText className="shrink flex-1">{currentProject.location}</EcoText>
                    </Pressable>
                  </View>
                  <View className="flex-row min-w-fit flex-1 flex-wrap justify-start lg:justify-end" style={Gap(2)}>
                    <View className="lg:min-w-[190px]">
                      {currentProject.type ? (
                        <EcoText
                          className="bg-primary-50 py-1 px-2 rounded-lg items-center h-max w-max flex-none"
                          style={{ color: colors.primary[800] }}
                          fontSize="sm"
                          fontWeight="regular"
                        >
                          {currentProject.type}
                        </EcoText>
                      ) : null}
                    </View>

                    <View className="flex-row shrink items-center lg:min-w-[190px]" style={Gap(1)}>
                      {currentProject.verifications.length > 0 && (
                        <EcoText
                          className="bg-tertiary-50 py-1 px-2 rounded-lg items-center h-max flex-none"
                          style={{ color: colors.tertiary[800] }}
                          fontSize="sm"
                        >
                          {startCase(currentProject.verifications[0])}
                        </EcoText>
                      )}
                      {currentProject.verifications.length > 1 && (
                        <EcoText color="enabled" fontSize="sm">
                          +{currentProject.verifications.length - 1}
                        </EcoText>
                      )}
                    </View>
                  </View>
                </View>
              </View>
            );
          })}
        </View>
      </View>
    </Container>
  );
}
