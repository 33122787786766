import { GlobalStylesProps } from '@utils/prop-types';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { StyleProp, View } from 'react-native';
import { EcoText } from './EcoText';

export type EcoTagType = 'success' | 'error' | 'warning' | 'info' | 'primary';

interface Props extends GlobalStylesProps {
  type: EcoTagType;
  children?: ReactNode;
}

export function EcoTag({ type = 'primary', style, children }: Props): JSX.Element {
  const styleMap: Record<EcoTagType, StyleProp<any>> = {
    success: 'bg-success-500 text-white',
    error: 'bg-danger-500 text-white',
    warning: 'bg-warning-500 text-white',
    info: 'bg-secondary-500 text-white',
    primary: 'bg-primary-500 text-white'
  };

  return (
    <View className={clsx('py-1 px-3 rounded-md flex flex-row items-center w-max', styleMap[type])} style={style}>
      {typeof children === 'string' ? <EcoText className={styleMap[type]}>{children}</EcoText> : children}
    </View>
  );
}
