import { GlobalStylesProps } from '@utils/prop-types';
import { FontColor } from '@utils/tailwind';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { View } from 'react-native';
import { EcoText } from './EcoText';

export type EcoPillVariant = 'primary' | 'secondary' | 'warning' | 'danger';

export const ECO_PILL_VARIANTS: Record<EcoPillVariant, Record<'bgColor' | 'color', string>> = {
  primary: {
    bgColor: 'bg-primary-400',
    color: 'enabled-inverted'
  },
  secondary: {
    bgColor: 'bg-secondary-300',
    color: 'enabled-inverted'
  },
  warning: {
    bgColor: 'bg-warning-500',
    color: 'enabled'
  },
  danger: {
    bgColor: 'bg-danger-500',
    color: 'enabled-inverted'
  }
};

interface Props extends GlobalStylesProps {
  children?: ReactNode;
  variant?: EcoPillVariant;
}

export function EcoPill({ style, variant = 'primary', children }: Props): JSX.Element {
  return (
    <View className={clsx('py-1 px-2 rounded-lg justify-center items-center', ECO_PILL_VARIANTS[variant].bgColor)} style={style}>
      <EcoText className="w-full" fontSize="sm" fontWeight="medium" color={ECO_PILL_VARIANTS[variant].color as FontColor}>
        {children}
      </EcoText>
    </View>
  );
}
