import { Gap } from '@utils/layout';
import { colors } from '@utils/tailwind';
import React, { useState } from 'react';
import { Pressable, TouchableWithoutFeedback, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { EcoButton } from './EcoButton';
import { EcoIcon } from './EcoIcon';
import { EcoInput } from './EcoInput';
import { EcoTag } from './EcoTag';
import { EcoText } from './EcoText';

export interface EcoMultiSelectItem<T = string> {
  disabled?: boolean;
  value: T;
  label: string;
}

interface EcoMultiSelect {
  items: EcoMultiSelectItem[];
  selectedItems: EcoMultiSelectItem[];
  onSelectedItemsChange: (items: EcoMultiSelectItem[]) => void;
  label?: string;
  selectText?: string;
  searchInputPlaceholderText?: string;
  selectedText?: string;
  submitButtonText?: string;
}

export function EcoMultiSelect({
  items,
  selectedItems = [],
  onSelectedItemsChange,
  searchInputPlaceholderText = 'Search',
  selectText = 'Select',
  label = '',
  submitButtonText = 'Done'
}: EcoMultiSelect): JSX.Element {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selector, setSelector] = useState<boolean>(false);

  const onChangeInput = (value: string) => {
    setSearchTerm(value);
  };

  const toggleSelector = () => {
    setSelector(!selector);
  };

  const submitSelection = () => {
    toggleSelector();
    setSearchTerm('');
  };

  const isSelected = (_item: EcoMultiSelectItem): boolean => {
    return selectedItems.some((item) => item.value === _item.value);
  };

  const addItem = (_item: EcoMultiSelectItem): void => {
    if (!isSelected(_item)) {
      onSelectedItemsChange([...selectedItems, _item]);
    }
  };

  const removeItem = (_item: EcoMultiSelectItem) => {
    onSelectedItemsChange(selectedItems.filter((item) => item.value !== _item.value));
  };

  const toggleItem = (_item: EcoMultiSelectItem): void => {
    if (isSelected(_item)) {
      removeItem(_item);
    } else {
      addItem(_item);
    }
  };

  const SelectedItems = () => {
    return (
      <View className="flex flex-row flex-wrap items-center" style={Gap(1)}>
        <Pressable onPress={toggleSelector}>
          <EcoIcon name="search" />
        </Pressable>
        {selectedItems.map((item) => {
          return (
            <EcoTag type="info" className="flex flex-row items-center" style={Gap()} key={crypto.randomUUID()}>
              <EcoText color="white" fontSize="sm">
                {item.label}
              </EcoText>
              <Pressable onPress={() => removeItem(item)}>
                <EcoIcon className="text-white" name="close" size="lg" />
              </Pressable>
            </EcoTag>
          );
        })}
      </View>
    );
  };

  const Row = (item: EcoMultiSelectItem) => {
    return (
      <Pressable disabled={item.disabled} onPress={() => toggleItem(item)}>
        <View className="flex flex-row justify-between items-center p-2">
          <EcoText
            style={[
              isSelected(item) ? { color: colors.gray[200] } : { color: colors.black },
              item.disabled ? { color: colors.gray[200] } : {}
            ]}
          >
            {item.label}
          </EcoText>
          {isSelected(item) && <EcoIcon name="check" size="sm" />}
        </View>
      </Pressable>
    );
  };

  const filterItemsPartial = (searchTerm: string) => {
    const filteredItems: EcoMultiSelectItem[] = [];
    items.forEach((item) => {
      const parts = searchTerm.trim().split(/[ \-:]+/);
      const regex = new RegExp(`(${parts.join('|')})`, 'ig');
      if (regex.test(item.label)) {
        filteredItems.push(item);
      }
    });
    return filteredItems;
  };

  const renderItems = (): JSX.Element => {
    return (
      <FlatList
        className="border rounded-xs mt-1"
        data={searchTerm ? filterItemsPartial(searchTerm) : items}
        extraData={selectedItems}
        keyExtractor={(item, index) => index.toString()}
        renderItem={(rowData) => Row(rowData.item)}
        nestedScrollEnabled
      />
    );
  };

  const SelectorView = (): JSX.Element => (
    <View>
      <View className="flex flex-row justify-between items-center" style={Gap(1)}>
        <EcoInput autoFocus onChangeText={onChangeInput} placeholder={searchInputPlaceholderText} value={searchTerm} />
        <EcoButton onPress={() => submitSelection()}>{submitButtonText}</EcoButton>
      </View>
      <View>{renderItems()}</View>
    </View>
  );

  const DefaultView = (): JSX.Element => (
    <View className="py-2 input-base">
      {selectedItems.length ? (
        SelectedItems()
      ) : (
        <TouchableWithoutFeedback onPress={toggleSelector}>
          <View className="flex flex-row items-center" style={Gap(1)}>
            <EcoIcon name="search" />
            <EcoText>{selectText}</EcoText>
          </View>
        </TouchableWithoutFeedback>
      )}
    </View>
  );

  return (
    <View>
      {label && (
        <EcoText fontSize="sm" className="text-black py-1">
          {label}
        </EcoText>
      )}
      {selector ? SelectorView() : DefaultView()}
    </View>
  );
}
