import { EcoButton, EcoCard, EcoIcon, EcoImage, EcoText } from '@components/shared';
import { EcoPageTitle } from '@components/shared/EcoPageTitle';
import EcoScrollView from '@components/views/EcoScrollView';
import { useFeaturePermission } from '@hooks/useFeaturePermission';
import { useOverlay } from '@hooks/useOverlay';
import { useQuery } from '@tanstack/react-query';
import { getRechargeAuthorizationUrl, isRebuyInstalled, isRechargeInstalled } from '@utils/api/integrations';
import { PUBLIC_IMG_URL } from '@utils/constants/config';
import { Gap } from '@utils/layout';
import clsx from 'clsx';
import { Linking, Pressable, View } from 'react-native';
import { useGlobal } from '../hooks/useGlobal';
import { GlobalScreenProps } from '../navigation/types';

interface Integration {
  logo: string;
  name: string;
  description: string;
  appStoreUrl: string;
  learnMoreUrl: string;
  connectUrl: string;
  manageUrl: string;
  isConnected: boolean;
  isInstallationRequired: boolean;
  apiEndpoint?: string;
  snippet?: string;
}

export default function IntegrationsScreen({}: GlobalScreenProps): JSX.Element {
  const { session } = useGlobal();
  const { showSuccess, showWarning } = useOverlay();
  const shopName = session?.merchantAdmin?.shopName || '';
  const { data: rechargeIsInstalled } = useQuery(['isRechargeInstalled', shopName], () => isRechargeInstalled(shopName));
  const { data: rechargeAuthorizationUrl = '' } = useQuery(['getRechargeAuthorizationUrl', shopName], () =>
    getRechargeAuthorizationUrl(shopName)
  );
  const { data: rebuyIsInstalled } = useQuery(['isRebuyInstalled', shopName], () => isRebuyInstalled(shopName));
  const isPermissioned = useFeaturePermission('qa');
  const integrations: Integration[] = [
    // TODO Evaluate whether staying long term
    // {
    //   logo: `${PUBLIC_IMG_URL}/shopify.svg`,
    //   name: 'Shopify',
    //   description: 'Lorem impsum Lorem impsum Lorem impsum Lorem impsum Lorem impsum Lorem impsum',
    //   onPress: noop
    // },
    {
      logo: `${PUBLIC_IMG_URL}/recharge.svg`,
      name: 'Recharge',
      description: 'Recharge is the leading subscriptions payments platform',
      appStoreUrl: 'https://apps.shopify.com/subscription-payments',
      learnMoreUrl: 'https://ecocart.readme.io/docs/recharge',
      connectUrl: rechargeAuthorizationUrl,
      manageUrl: 'https://admin.rechargeapps.com/admin/login',
      isConnected: rechargeIsInstalled || false,
      isInstallationRequired: true
    },
    {
      logo: `${PUBLIC_IMG_URL}/rebuy.svg`,
      name: 'Rebuy',
      description: 'A Fully-Integrated Suite of Omnichannel Personalization Solutions',
      appStoreUrl: 'https://apps.shopify.com/rebuy',
      learnMoreUrl: 'https://ecocart.readme.io/docs/rebuy',
      connectUrl: '',
      manageUrl: 'https://rebuyengine.com/shopify/auth/login',
      isConnected: rebuyIsInstalled || false,
      isInstallationRequired: true
    },
    isPermissioned && {
      logo: `${PUBLIC_IMG_URL}/klaviyo.svg`,
      name: 'Klaviyo',
      description: 'Klaviyo automates marketing for businesses, boosting customer engagement and sales',
      appStoreUrl: 'https://apps.shopify.com/klaviyo-email-marketing',
      learnMoreUrl: 'https://ecocart.readme.io/docs/klaviyo',
      connectUrl: '',
      manageUrl: 'https://www.klaviyo.com/login',
      isConnected: false,
      isInstallationRequired: true,
      apiEndpoint: `https://merchants-analytics.ecocart.io/stats/klaviyo/${shopName}`,
      snippet: `<table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation"
      style="max-width:37.5em;margin:0 auto;background-color:#f5f5f5">
      <tbody>
        <tr style="width:100%">
          <td>
            <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
              <tbody style="width:100%">
                <tr style="width:100%">
                  <td data-id="__react-email-column" style="padding:16px 17px">
                    <h2 style="color:#000;font-size:26px;line-height:120%;font-weight:700;margin:0;word-wrap:keep-all">Our
                      Sustainability Journey</h2>
                    <p
                      style="font-size:10px;line-height:12px;margin:0;font-weight:400;color:#949494;margin-top:12px;margin-bottom:24px">
                      Powered By<a href="https://ecocart.io"
                        style="color:#067df7;text-decoration:none;vertical-align:middle" target="_blank"><img alt="EcoCart"
                          height="10" src="https://ecocart-public-images.s3.amazonaws.com/ecocart-logo.png"
                          style="display:inline-block;outline:none;border:none;text-decoration:none;margin-left:4px"
                          width="65" /></a></p>
                    <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation"
                      style="margin-bottom:16px;background-color:#fff;border-radius:6px">
                      <tbody style="width:100%">
                        <tr style="width:100%">
                          <td width="75" valign="top" data-id="__react-email-column" style="padding:16px"><img height="75"
                              src="https://ecocart-public-images.s3.amazonaws.com/carbon.png"
                              style="display:block;outline:none;border:none;text-decoration:none;border-radius:100%;height:75px;width:75px"
                              width="75" /></td>
                          <td data-id="__react-email-column"
                            style="padding-right:16px;padding-top:16px;padding-bottom:16px">
                            <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0"
                              role="presentation">
                              <tbody style="width:100%">
                                <tr style="width:100%">
                                  <p
                                    style="font-size:20px;line-height:140%;margin:0;padding:0;font-weight:600;color:#0A0A0A;word-wrap:keep-all">
                                    {{feeds.merchant.allTimeTotalOffset}}</p>
                                </tr>
                              </tbody>
                            </table>
                            <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0"
                              role="presentation">
                              <tbody style="width:100%">
                                <tr style="width:100%">
                                  <td data-id="__react-email-column">
                                    <p
                                      style="font-size:12px;line-height:140%;margin:0;font-weight:500;color:#333333;display:inline;word-wrap:keep-all">
                                      The {{feeds.merchant.companyName}} community has offset
                                      {{feeds.merchant.allTimeTotalOffset}} of carbon</p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation"
                      style="margin-bottom:16px;background-color:#fff;border-radius:6px">
                      <tbody style="width:100%">
                        <tr style="width:100%">
                          <td width="75" valign="top" data-id="__react-email-column" style="padding:16px"><img height="75"
                              src="https://ecocart-public-images.s3.amazonaws.com/trees.png"
                              style="display:block;outline:none;border:none;text-decoration:none;border-radius:100%;height:75px;width:75px"
                              width="75" /></td>
                          <td data-id="__react-email-column"
                            style="padding-right:16px;padding-top:16px;padding-bottom:16px">
                            <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0"
                              role="presentation">
                              <tbody style="width:100%">
                                <tr style="width:100%">
                                  <p
                                    style="font-size:20px;line-height:140%;margin:0;padding:0;font-weight:600;color:#0A0A0A;word-wrap:keep-all">
                                    {{feeds.merchant.equivalency.trees}} trees</p>
                                </tr>
                              </tbody>
                            </table>
                            <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0"
                              role="presentation">
                              <tbody style="width:100%">
                                <tr style="width:100%">
                                  <td data-id="__react-email-column">
                                    <p
                                      style="font-size:12px;line-height:140%;margin:0;font-weight:500;color:#333333;display:inline;word-wrap:keep-all">
                                      This is equivalent to the amount of carbon {{feeds.merchant.equivalency.trees}} trees
                                      can absorb in one year</p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation"
                      style="margin-bottom:0;background-color:#fff;border-radius:6px">
                      <tbody style="width:100%">
                        <tr style="width:100%">
                          <td width="75" valign="top" data-id="__react-email-column" style="padding:16px"><img height="75"
                              src="{{feeds.merchant.project.images.0}}"
                              style="display:block;outline:none;border:none;text-decoration:none;border-radius:100%;height:75px;width:75px"
                              width="75" /></td>
                          <td data-id="__react-email-column"
                            style="padding-right:16px;padding-top:16px;padding-bottom:16px">
                            <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0"
                              role="presentation">
                              <tbody style="width:100%">
                                <tr style="width:100%">
                                  <p
                                    style="font-size:20px;line-height:140%;margin:0;padding:0;font-weight:600;color:#0A0A0A;word-wrap:keep-all">
                                    Together, we can support the {{feeds.merchant.project.name}}</p>
                                </tr>
                              </tbody>
                            </table>
                            <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0"
                              role="presentation">
                              <tbody style="width:100%">
                                <tr style="width:100%">
                                  <td data-id="__react-email-column"><a href="{{feeds.merchant.project.url}}"
                                      style="color:#333333 !important;text-decoration:underline;vertical-align:middle"
                                      target="_blank">
                                      <p
                                        style="font-size:12px;line-height:140%;margin:0;font-weight:500;color:#333333;display:inline;word-wrap:keep-all">
                                        Learn more about this project</p>
                                    </a></td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>`
    }
  ].filter(Boolean) as Integration[];

  const linkTo = (url: string, target: '_blank' | '_self' = '_blank') => {
    switch (target) {
      case '_self':
        location.href = url;
        return;
      case '_blank':
      default:
        Linking.openURL(url);
        return;
    }
  };

  const handleTextCopy = (text: string = '') => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          showSuccess('Copied!');
        })
        .catch(() => {
          showWarning('Failed to copy text to clipboard. Please try again or contact support.');
        });
    }
  };

  return (
    <EcoScrollView>
      <>
        <EcoPageTitle
          className="mb-5"
          title="Integrations"
          subTitle="Easy-to-use integrations that connect EcoCart with your ecommerce stack"
        />
        <EcoCard className="flex flex-col md:flex-row" style={Gap()}>
          {integrations.map(
            ({
              name,
              logo,
              description,
              appStoreUrl,
              learnMoreUrl,
              connectUrl,
              manageUrl,
              isConnected,
              isInstallationRequired,
              apiEndpoint,
              snippet
            }) => (
              <View
                className={clsx(
                  isConnected ? 'border-2 border-success' : 'border border-gray-100',
                  'rounded-sm p-5 max-w-[270px] flex flex-col justify-between'
                )}
                style={Gap(4)}
                key={name}
              >
                <View className="flex flex-col" style={Gap(2)}>
                  <View className="flex flex-row items-center" style={Gap(2)}>
                    <EcoImage className="rounded-sm" resizeMode="contain" height={40} width={40} src={logo} />
                    <View>
                      <EcoText fontWeight="medium">{name}</EcoText>
                      <Pressable className="flex flex-row items-center" style={Gap(2)} onPress={() => linkTo(appStoreUrl)}>
                        <EcoText fontSize="xs" className="underline">
                          Go To App
                        </EcoText>
                        <EcoIcon name="open_in_new" size="lg" />
                      </Pressable>
                    </View>
                  </View>
                  <EcoText fontSize="sm">{description}</EcoText>
                  {apiEndpoint && (
                    <Pressable onPress={() => handleTextCopy(apiEndpoint)}>
                      <View className="flex-row items-center" style={Gap(2)}>
                        <EcoText fontSize="xs" color="subdued">
                          Copy Feed URL to clipboard
                        </EcoText>
                        <EcoIcon name="content_copy" size="lg" />
                      </View>
                    </Pressable>
                  )}
                  {snippet && (
                    <Pressable onPress={() => handleTextCopy(snippet)}>
                      <View className="flex-row items-center" style={Gap(2)}>
                        <EcoText fontSize="xs" color="subdued">
                          Copy Email HTML Code
                        </EcoText>
                        <EcoIcon name="content_copy" size="lg" />
                      </View>
                    </Pressable>
                  )}
                  {isInstallationRequired && (
                    <EcoText fontSize="xs" color="subdued">
                      App installation required
                    </EcoText>
                  )}
                </View>
                <View className="flex flex-row justify-between" style={Gap()}>
                  {learnMoreUrl ? (
                    <EcoButton
                      className="flex-1"
                      size="sm"
                      variant="outlined"
                      colorScheme={isConnected ? 'success' : 'default'}
                      onPress={() => linkTo(learnMoreUrl)}
                    >
                      Learn More
                    </EcoButton>
                  ) : null}
                  {!isConnected && connectUrl ? (
                    <EcoButton
                      className="flex-1"
                      size="sm"
                      variant="outlined"
                      colorScheme="default"
                      onPress={() => linkTo(connectUrl, '_self')}
                    >
                      Connect
                    </EcoButton>
                  ) : null}
                  {isConnected ? (
                    <EcoButton className="flex-1" size="sm" variant="outlined" colorScheme="success" onPress={() => linkTo(manageUrl)}>
                      Manage
                    </EcoButton>
                  ) : null}
                </View>
              </View>
            )
          )}
        </EcoCard>
      </>
    </EcoScrollView>
  );
}
