import { EcoCard, EcoText } from '@components/shared';
import { useQuery } from '@tanstack/react-query';
import { getShopifyScripts } from '@utils/api/shopify';
import { FontColor } from '@utils/tailwind';
import { View } from 'react-native';

interface Props {
  shopName: string;
}

export default function ShopifyScriptCard({ shopName }: Props): JSX.Element {
  const { data: scripts, isLoading: scriptsAreLoading } = useQuery(['scripts', shopName], () => getShopifyScripts(shopName));

  const getInStoreScriptData = (_scripts: any): { message: string; fontColor: FontColor } => {
    if (scriptsAreLoading) {
      return { message: 'Loading...', fontColor: 'subdued' };
    } else {
      const hasScript = (_scripts?.script_tags?.[0]?.src || '').includes('ecocart.js');
      return { message: hasScript ? 'Enabled' : 'Disabled', fontColor: hasScript ? 'accent-primary' : 'danger' };
    }
  };

  return (
    <EcoCard title="Shopify Scripts">
      <View className="flex-row flex-start">
        <EcoText className="w-[80px]" color="subdued">
          In-Store:
        </EcoText>
        <EcoText fontWeight="semibold" color={getInStoreScriptData(scripts).fontColor}>
          {getInStoreScriptData(scripts).message}
        </EcoText>
      </View>
      <View className="flex-row flex-start">
        <EcoText className="w-[80px]" color="subdued">
          Checkout:
        </EcoText>
        <EcoText fontWeight="semibold" color="subdued">
          Unknown
        </EcoText>
      </View>
    </EcoCard>
  );
}
