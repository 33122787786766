import { EcoButton, EcoInput, EcoTabContent, EcoTabs } from '@components/shared';
import EcoScrollView from '@components/views/EcoScrollView';
import { useGlobal } from '@hooks/useGlobal';
import { useOverlay } from '@hooks/useOverlay';
import { useSettingsTabs } from '@hooks/useSettingsTabs';
import { GlobalScreenProps } from '@navigation/types';
import { useQuery } from '@tanstack/react-query';
import { getMerchant } from '@utils/api/merchant';
import { Gap } from '@utils/layout';
import { View } from 'react-native';

export default function SettingsApiKeyScreen({}: GlobalScreenProps): JSX.Element {
  const tabs = useSettingsTabs();
  const { session } = useGlobal();
  const { showSuccess, handleError } = useOverlay();
  const shopName = session?.merchantAdmin?.shopName || '';
  const { data: merchant } = useQuery(['merchant', shopName], () => getMerchant(shopName));
  const apiKey = merchant?.ecoCalculationApiKey || '';

  const handlePress = async () => {
    try {
      await navigator.clipboard.writeText(apiKey);
      showSuccess('API key copied');
    } catch (err) {
      handleError('Something went wrong. Please try again.');
    }
  };

  return (
    <EcoScrollView>
      <EcoTabs tabs={tabs} prefix="Settings" activeScreen="SettingsApiKey">
        <EcoTabContent title="Api Key" subTitle="Your API Key">
          <View className="w-full h-[1px] bg-black/[.1]" />
          <View className="flex-row items-end w-full" style={Gap()}>
            <EcoInput className="w-1/2" label="API Key" value={apiKey} disabled />
            <EcoButton variant="outlined" rightIcon="content_copy" onPress={handlePress}>
              Copy
            </EcoButton>
          </View>
        </EcoTabContent>
      </EcoTabs>
    </EcoScrollView>
  );
}
