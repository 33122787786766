import { EcoCard, EcoText, EcoTooltip } from '@components/shared';
import { EcoButton, EcoButtonProps } from '@components/shared/EcoButton';
import { useGlobal } from '@hooks/useGlobal';
import { useRewards } from '@hooks/useRewards';
import { useQuery } from '@tanstack/react-query';
import { getInvoices } from '@utils/api/payments';
import { Gap } from '@utils/layout';
import { isBefore, parseISO, startOfMonth } from 'date-fns';
import { View } from 'react-native';

export interface RewardCardProps {
  title: string;
  description: string;
  logo?: JSX.Element;
  ctaButtonProps: EcoButtonProps;
}

const TOOLTIP_CONTENT = {
  insufficientBalance: {
    title: 'Insufficient Balance',
    description: 'You do not have enough balance to redeem rewards'
  },
  unpaidInvoices: {
    title: 'Unpaid Invoices',
    description: 'You have unpaid invoices from previous months. Please check the "Billing" section for more details'
  },
  ecoCartAdmin: {
    title: 'EcoCart Admin',
    description: 'EcoCart Admins cannot redeem rewards'
  }
};

export const RewardCard = (props: RewardCardProps): JSX.Element => {
  const { session } = useGlobal();
  const { title, description, logo, ctaButtonProps } = props;
  const isEcoCartAdmin = session?.user.userType === 'ecocart_admin';
  const shopName = session?.merchantAdmin?.shopName || '';
  const { redeemableAmount } = useRewards(shopName);
  const { data: invoices } = useQuery(['getInvoices', shopName], () => getInvoices(shopName), { enabled: !!shopName });

  const startOfCurrentMonth = startOfMonth(new Date());

  const hasUnpaidInvoices = (invoices || []).some((invoice) => {
    const invoiceDate = parseISO(invoice.start_date);
    return ['unpaid', 'failed'].includes(invoice.status) && isBefore(invoiceDate, startOfCurrentMonth); // excluding current month
  });

  let tooltipKey: keyof typeof TOOLTIP_CONTENT = 'insufficientBalance';
  if (hasUnpaidInvoices) tooltipKey = 'unpaidInvoices';
  if (isEcoCartAdmin) tooltipKey = 'ecoCartAdmin';

  const isDisabled = !redeemableAmount || hasUnpaidInvoices || isEcoCartAdmin;

  return (
    <EcoCard
      className="p-4 justify-between rounded-sm border border-gray-200 flex-1 basis-full sm:basis-2/5 lg:basis-[200px]"
      style={Gap(6)}
    >
      <View style={Gap(6)}>
        {logo}
        <View style={Gap(2)}>
          <EcoText fontSize="lg" fontWeight="medium">
            {title}
          </EcoText>
          <EcoText color="subdued" fontSize="sm">
            {description}
          </EcoText>
        </View>
      </View>
      <EcoTooltip
        tooltip={
          <View style={Gap(2)} className="w-[300px]">
            <EcoText fontSize="lg" fontWeight="medium" color="enabled-inverted">
              {TOOLTIP_CONTENT[tooltipKey].title}
            </EcoText>
            <EcoText fontSize="sm" color="enabled-inverted">
              {TOOLTIP_CONTENT[tooltipKey].description}
            </EcoText>
          </View>
        }
        placement="top"
      >
        <EcoButton {...ctaButtonProps} isDisabled={isDisabled} variant="outlined" fullWidth size="sm">
          Redeem
        </EcoButton>
      </EcoTooltip>
    </EcoCard>
  );
};
