import { EcoButton, EcoCard, EcoInput, EcoPicker, EcoText, FormButtons, FormPicker } from '@components/shared';
import { PERMISSIONS, Permission, User } from '@ecocart/entities';
import { useOverlay } from '@hooks/useOverlay';
import { availableUserTypes, createUser } from '@utils/api/user';
import { Gap } from '@utils/layout';
import { GlobalFormProps } from '@utils/prop-types';
import { CreateValidationSchema } from '@utils/validation';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { View } from 'react-native';
import AddMerchantForm from './AddMerchantForm';

export default function CreateUserForm({ onSuccess }: GlobalFormProps): JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { showSuccess, handleError } = useOverlay();

  const onSubmit = async (user: User) => {
    setIsSubmitting(true);

    try {
      const response = await createUser(user);
      showSuccess('Successfully created user');
      setIsSubmitting(false);
      !!onSuccess && onSuccess(response);
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  const getUpdatedPerm = (permissions: Permission[], i: number, text: string): Permission[] => {
    permissions[i] = text as unknown as Permission;
    return permissions;
  };

  return (
    <Formik initialValues={new User()} onSubmit={onSubmit} validateOnMount validationSchema={CreateValidationSchema(['id'])}>
      {({ handleChange, setFieldValue, handleSubmit, isValid, values }) => (
        <View style={Gap(2)}>
          <EcoInput label="First Name" value={values.firstName} onChangeText={handleChange('firstName')} placeholder="First Name" />
          <EcoInput label="Last Name" value={values.lastName} onChangeText={handleChange('lastName')} placeholder="Last Name" />
          <EcoInput label="Email" value={values.id} onChangeText={handleChange('id')} placeholder="Email" />
          <FormPicker
            label="User Type"
            items={availableUserTypes}
            value={values.userType as unknown as string}
            onValueChange={handleChange('userType')}
          />
          <EcoCard subTitle="Permission">
            {values.permissions.map((permission, i) => (
              <EcoPicker
                className="mb-2"
                key={'permission' + i}
                items={PERMISSIONS as unknown as string[]}
                value={permission as unknown as string}
                onValueChange={(text) => setFieldValue('permissions', getUpdatedPerm(values.permissions, i, text))}
              />
            ))}
            <EcoButton className="w-max" onPress={() => setFieldValue('permissions', [...values.permissions, 'get_all_merchants'])}>
              Add Permission
            </EcoButton>
          </EcoCard>
          {['merchant_admin', 'api_admin'].includes(values.userType) && (
            <EcoCard subTitle="Merchants">
              <View style={Gap(2)}>
                {Object.keys(values.merchants || {}).map((shopName) => (
                  <View key={shopName} className="flex-row justify-between items-center">
                    <EcoText fontSize="lg">{shopName}</EcoText>
                    <EcoButton
                      className="w-max"
                      onPress={() => {
                        const merchants = { ...values.merchants };
                        delete merchants[shopName];
                        setFieldValue('merchants', merchants);
                      }}
                      colorScheme="danger"
                    >
                      delete
                    </EcoButton>
                  </View>
                ))}
                <AddMerchantForm
                  onSuccess={(shopName: string) => {
                    setFieldValue('merchants', { ...values.merchants, [shopName]: true });
                  }}
                />
              </View>
            </EcoCard>
          )}
          <FormButtons>
            <EcoButton
              isDisabled={!isValid || (values.userType === 'merchant_admin' && !Object.keys(values.merchants).length) || isSubmitting}
              isLoading={isSubmitting}
              onPress={handleSubmit}
            >
              Create User
            </EcoButton>
          </FormButtons>
        </View>
      )}
    </Formik>
  );
}
