import DownloadBackupCard from '@components/cards/DownloadBackupCard';
import { EcoButton, EcoCard, EcoTable } from '@components/shared';
import { ColumnConfig, FilterConfig, filterConfigToFilters } from '@components/shared/EcoTable.utils';
import EcoScrollView from '@components/views/EcoScrollView';
import { LOCALES, MerchantAdminFlat, PAYOR_MODES, PLATFORMS } from '@ecocart/entities';
import { DataQuery } from '@ecocart/universal-utils';
import { useFeaturePermission } from '@hooks/useFeaturePermission';
import { useNavigation } from '@react-navigation/native';
import { useQuery } from '@tanstack/react-query';
import { getMerchantAdmins } from '@utils/api/merchant';
import { snakeCaseToSpaced } from '@utils/charts';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useOverlay } from '../hooks/useOverlay';
import { GlobalScreenProps } from '../navigation/types';

export default function MerchantsScreen({}: GlobalScreenProps): JSX.Element {
  const { isFeaturePermissioned } = useFeaturePermission();
  const [filterConfig, setFilterConfig] = useState<FilterConfig<MerchantAdminFlat>[]>([
    {
      field: 'active',
      operator: 'eq',
      value: true,
      options: [true, false].map((active) => ({ label: active ? 'Installed' : 'Uninstalled', value: active })),
      enabled: true
    },
    {
      field: 'platform',
      operator: 'eq',
      value: '',
      options: PLATFORMS.map((platform) => ({ label: platform, value: platform })),
      enabled: false
    },
    {
      field: 'locale',
      operator: 'eq',
      value: '',
      options: LOCALES.map((locale) => ({ label: locale, value: locale })),
      enabled: false
    },
    {
      field: 'payorMode',
      operator: 'eq',
      value: '',
      options: PAYOR_MODES.map((payorMode) => ({ label: snakeCaseToSpaced(payorMode), value: payorMode })),
      enabled: false
    }
  ]);

  const defaultDataQuery: DataQuery<MerchantAdminFlat> = {
    fields: [
      'shopName',
      'companyName',
      'systemVersion',
      'ecocartEnabled',
      'projectName',
      'active',
      'updatedAt',
      'createdAt',
      'accessToken'
    ],
    sorts: [{ dir: 'desc', field: 'updatedAt' }],
    filters: filterConfigToFilters(filterConfig)
  };

  const { data, isLoading } = useQuery(['merchantAdmins', JSON.stringify(filterConfigToFilters(filterConfig))], () =>
    getMerchantAdmins(defaultDataQuery)
  );
  const navigation = useNavigation();
  const { showModal } = useOverlay();

  const handleUpdateFilterConfig = (_filterConfig: FilterConfig<MerchantAdminFlat>[]) => {
    setFilterConfig([..._filterConfig]);
  };

  const columnConfig: ColumnConfig<MerchantAdminFlat>[] = [
    {
      field: 'active',
      flex: 1
    },
    {
      field: 'shopName',
      onPress: ({ shopName }) => {
        navigation.navigate('MerchantSettings', { shopName });
      },
      searchable: true,
      flex: 2
    },
    { field: 'companyName', searchable: true, flex: 2 },
    { field: 'accessToken', searchable: true, flex: 2 },
    { field: 'projectName', searchable: true, flex: 2 },
    {
      field: 'createdAt',
      title: 'Created',
      flex: 2,
      format: ({ createdAt }) => (createdAt ? format(new Date(createdAt), 'MMM d, yyyy') : '--')
    },
    {
      field: 'updatedAt',
      title: 'Updated',
      flex: 2,
      format: ({ updatedAt }) => (updatedAt ? format(new Date(updatedAt), 'MMM d, yyyy') : '--')
    },
    { field: 'ecocartEnabled', title: 'Show Widgets' }
  ];

  const handleShowBackupModal = () => {
    showModal({
      content: <DownloadBackupCard />
    });
  };

  return (
    <EcoScrollView>
      <EcoCard
        title="Merchants"
        topRightComponent={<>{isFeaturePermissioned ? <EcoButton onPress={handleShowBackupModal}>Backups</EcoButton> : null}</>}
      >
        <EcoTable
          isLoading={isLoading}
          data={data as MerchantAdminFlat[]}
          columns={columnConfig}
          filterConfig={filterConfig}
          defaultDataQuery={defaultDataQuery}
          onFilterConfigChange={handleUpdateFilterConfig}
        />
      </EcoCard>
    </EcoScrollView>
  );
}
