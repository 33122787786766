import EditUserForm from '@components/forms/EditUserForm';
import { EcoTabContent, EcoTabs } from '@components/shared';
import EcoScrollView from '@components/views/EcoScrollView';
import { User } from '@ecocart/entities';
import { useGlobal } from '@hooks/useGlobal';
import { useSettingsTabs } from '@hooks/useSettingsTabs';
import { GlobalScreenProps } from '@navigation/types';
import { View } from 'react-native';

export default function SettingsUserScreen({}: GlobalScreenProps): JSX.Element {
  const { session, updateSessionUser } = useGlobal();
  const tabs = useSettingsTabs();

  return (
    <EcoScrollView>
      <EcoTabs tabs={tabs} prefix="Settings" activeScreen="SettingsUser">
        <EcoTabContent title="User Settings" subTitle="Edit your information for your account">
          <View className="w-full h-[1px] bg-black/[.1]" />
          <EditUserForm user={session?.user as User} onSuccess={updateSessionUser} />
        </EcoTabContent>
      </EcoTabs>
    </EcoScrollView>
  );
}
