import { ProjectList } from '@components/cards/ProjectList';
import { EcoTabContent, EcoTabs } from '@components/shared';
import EcoScrollView from '@components/views/EcoScrollView';
import { useQuery } from '@tanstack/react-query';
import { getMerchantAdmin } from '@utils/api/merchant';
import { EcoCartApiMerchantTabs, EcoCartGlobalMerchantTabs } from '@utils/constants/tabs';
import React from 'react';
import { View } from 'react-native';
import { BackLink } from '../navigation/BackLink';
import { GlobalScreenProps } from '../navigation/types';

export default function MerchantProjectsScreen({ route }: GlobalScreenProps): JSX.Element {
  const shopName = route?.params?.shopName;
  const { data: merchantAdmin } = useQuery(['merchantAdmin', shopName], () => getMerchantAdmin(shopName));
  const EcoCartMerchantTabs = merchantAdmin?.platform === 'api' ? EcoCartApiMerchantTabs : EcoCartGlobalMerchantTabs;

  return (
    <EcoScrollView>
      <View className="flex-row">
        <BackLink href="/merchants" label="Back to Merchants" />
      </View>
      <EcoTabs
        tabs={EcoCartMerchantTabs.map(({ label, screenName }) => {
          return {
            label,
            screenName,
            screenParams: { shopName }
          };
        })}
        prefix="Merchant"
        activeScreen="MerchantProjects"
      >
        <EcoTabContent>
          <ProjectList className="bg-gray-50 p-2" merchantAdmin={merchantAdmin} />
        </EcoTabContent>
      </EcoTabs>
    </EcoScrollView>
  );
}
