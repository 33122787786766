import { DATE_PICKER_VALUES, DatePickerValue } from '@ecocart/entities';
import { useGlobal } from '@hooks/useGlobal';
import { format, isBefore, startOfToday, startOfYear, sub } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const DATE_FORMAT = 'yyyy-MM-dd';
// Per backend, setting the beginning date to `undefined` will return all data for "ALL TIME" for each merchant
export const BEGINNING_DATE = undefined;

export const useGetDateRange = (
  dateRange: DatePickerValue
): { startDate?: number; endDate: number; formattedStartDate?: string; formattedEndDate: string } => {
  const { session } = useGlobal();
  const { merchantAdmin } = session || {};

  const getStartDate = (selectedDate: Date): Date => {
    if (merchantAdmin?.createdAt == undefined) return selectedDate;

    const merchantSignupDate = new Date(merchantAdmin.createdAt);
    // if the selected date is before the createdAt date, we will use the createdAt date because we don't want
    // to show data before the merchant was created, which is just null
    if (isBefore(selectedDate, merchantSignupDate)) {
      return merchantSignupDate;
    }
    return selectedDate;
  };

  // Using startOfToday to make the date query more static and not dependent on timestamp
  // because if we use new Date(), each time it will have a different timestamp
  const today = startOfToday();
  // backend requires date in UTC. Get the UTC time equivalent
  const utcDate = utcToZonedTime(today, 'UTC');

  const utcDate_Jan1_2023 = utcToZonedTime(new Date('2023-01-01'), 'UTC');

  // default value
  let startDate: number | undefined = today.getTime();

  switch (dateRange) {
    case '1M':
      startDate = getStartDate(sub(utcDate, { months: 1 })).getTime();
      break;
    case '3M':
      startDate = getStartDate(sub(utcDate, { months: 3 })).getTime();
      break;
    case '6M':
      startDate = getStartDate(sub(utcDate, { months: 6 })).getTime();
      break;
    case 'YTD':
      startDate = getStartDate(startOfYear(utcDate)).getTime();
      break;
    case 'ALL_TIME':
      startDate = getStartDate(startOfYear(utcDate_Jan1_2023)).getTime();
      break;
    case '1W':
    default:
      // Last week
      startDate = getStartDate(sub(utcDate, { weeks: 1 })).getTime();
      break;
  }

  return {
    startDate,
    endDate: utcDate.getTime(),
    formattedStartDate: startDate === undefined ? undefined : format(startDate, DATE_FORMAT),
    formattedEndDate: format(utcDate, DATE_FORMAT)
  };
};

interface DateRangePickerConfig {
  label: string;
  value: DatePickerValue;
  isSelected: boolean;
}

export const dateRangeItems: DateRangePickerConfig[] = DATE_PICKER_VALUES.map((item, idx) => ({
  label: item.replace('_', ' '),
  value: item,
  isSelected: idx === 0
}));

export interface DateButtonProps extends DateRangePickerConfig {
  onPress: (val: string) => void;
}

export interface DateRangePickerProp {
  value: DatePickerValue;
  onDateClick: (val: string) => void;
}
