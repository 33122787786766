import { IconName } from './EcoIcon.utils';

export const ALERT_VARIANTS = ['info', 'success', 'warning', 'danger'] as const;
export type AlertVariant = (typeof ALERT_VARIANTS)[number];

export const AlertColorMap: Record<AlertVariant, string> = {
  info: 'bg-secondary-50 border-secondary-900 border border-secondary-900',
  success: 'bg-success-50 border-success-900 border border-success-900',
  warning: 'bg-warning-50 border-warning-900 border border-warning-900',
  danger: 'bg-danger-50 border-danger-900 border border-danger-900'
};

export const IconColorMap: Record<AlertVariant, string> = {
  info: 'text-secondary-600',
  success: 'text-success-500',
  warning: 'text-warning-600',
  danger: 'text-danger-700'
};

export const CloseIconColorMap: Record<AlertVariant, string> = {
  info: 'text-secondary-500',
  success: 'text-success-900',
  warning: 'text-warning-900',
  danger: 'text-danger-900'
};

export const TextColorMap: Record<AlertVariant, string> = {
  info: 'text-secondary-900',
  success: 'text-success-900',
  warning: 'text-warning-900',
  danger: 'text-danger-900'
};

export const IconNameMap: Record<AlertVariant, IconName> = {
  info: 'info',
  success: 'check_circle',
  warning: 'warning',
  danger: 'dangerous'
};

export const BorderColorMap: Record<AlertVariant, string> = {
  info: 'border-secondary-600',
  success: 'border-success-500',
  warning: 'border-warning-500',
  danger: 'border-danger-700'
};
