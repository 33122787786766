import { EcoAlert, EcoInput, FormPicker } from '@components/shared';
import { Gap } from '@utils/layout';
import { useState } from 'react';
import { View } from 'react-native';
import { ALERT_VARIANTS, AlertVariant } from '../shared/EcoAlert.utils';
import { ConfigContainer } from '../views/ConfigContainer';
import { DemoContainer } from '../views/DemoContainer';
import { InternalToolsCard } from '../views/InternalToolsCard';

export function DSAlert(): JSX.Element {
  const [title, setTitle] = useState('80 Char Max. System Maintenance in Progress.');
  const [subTitle, setSubTitle] = useState('120 Char Max. Emission reporting is temporarily unavailable');
  const [showPrimaryBtn, setShowPrimaryBtn] = useState<'true' | 'false'>('true');
  const [showSecondaryBtn, setShowSecondaryBtn] = useState<'true' | 'false'>('true');
  const [closeable, setCloseable] = useState<'true' | 'false'>('true');
  const [variant, setVariant] = useState<AlertVariant>('success');

  const handleSetCloseable = (val: 'true' | 'false') => {
    if (val === 'true') return setCloseable('true');
    return setCloseable('false');
  };
  const handleSetPrimaryBtn = (val: 'true' | 'false') => {
    if (val === 'true') return setShowPrimaryBtn('true');
    return setShowPrimaryBtn('false');
  };
  const handleSetSecondaryBtn = (val: 'true' | 'false') => {
    if (val === 'true') return setShowSecondaryBtn('true');
    return setShowSecondaryBtn('false');
  };

  return (
    <InternalToolsCard componentName="EcoAlert">
      <DemoContainer>
        {/* Actual demo component */}
        <EcoAlert
          closeable={closeable === 'true'}
          variant={variant}
          primaryButton={showPrimaryBtn === 'true' ? { label: 'Primary', onPress: () => alert('Primary button') } : undefined}
          secondaryButton={showSecondaryBtn === 'true' ? { label: 'Secondary', onPress: () => alert('Secondary button!') } : undefined}
          title={title}
          subtitle={subTitle}
        />
        {/* End of actual demo component */}
      </DemoContainer>

      <ConfigContainer>
        {/* Configuration component */}
        <View style={Gap()}>
          <EcoInput label="Title" value={title} onChangeText={(v: string) => setTitle(v)} placeholder="Enter some text" />
          <EcoInput label="Subtitle" value={subTitle} onChangeText={(v: string) => setSubTitle(v)} placeholder="Enter some text" />
          <View className="flex-row flex-wrap items-center" style={Gap()}>
            <FormPicker
              className="flex-1"
              label="Closeable"
              items={['true', 'false']}
              value={closeable}
              onValueChange={(val) => handleSetCloseable(val)}
            />
            <FormPicker
              className="flex-1"
              label="Show Primary Button"
              items={['true', 'false']}
              value={showPrimaryBtn}
              onValueChange={(val) => handleSetPrimaryBtn(val)}
            />
            <FormPicker
              className="flex-1"
              label="Show Secondary Button"
              items={['true', 'false']}
              value={showSecondaryBtn}
              onValueChange={(val) => handleSetSecondaryBtn(val)}
            />
            <FormPicker
              className="flex-1"
              label="Variant"
              items={[...ALERT_VARIANTS]}
              value={variant}
              onValueChange={(val) => setVariant(val)}
            />
          </View>
        </View>
        {/* End of Configuration component */}
      </ConfigContainer>
    </InternalToolsCard>
  );
}
