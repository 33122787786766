import { getEquivalencyValue } from '@ecocart/universal-utils';
import { CardProps } from './WelcomeBanner';

export interface TotalCarbonOffset {
  value: number;
  units: 'lb' | 'kg';
  trend: number;
}

export const generateCardConfigs = (totalCarbonOffset: TotalCarbonOffset): CardProps[] => {
  return [
    {
      title: getEquivalencyValue(totalCarbonOffset, 'LBS_OF_CO2_TO_MILES'),
      body: 'Miles Driven By An Average Gasoline Vehicle',
      iconName: 'directions_car',
      tooltip: 'CO2 emissions per vehicle are estimated based on EPA guidelines and gasoline emission factors.'
    },
    {
      title: getEquivalencyValue(totalCarbonOffset, 'LBS_OF_CO2_TO_HOME_ELECTRICITY'),
      body: "US Homes' Electricity Use For One Year",
      iconName: 'bolt',
      tooltip: 'CO2 emissions per home are estimated by multiplying average electricity usage by the national emission rate.'
    },
    {
      title: getEquivalencyValue(totalCarbonOffset, 'LBS_OF_CO2_TO_SMARTPHONE_CHARGED'),
      body: 'Smartphones Charged',
      iconName: 'charging_station',
      tooltip: 'The estimated amount of energy needed to charge a fully depleted smartphone battery is approximately 11.6 Watt-hours.'
    },
    {
      title: getEquivalencyValue(totalCarbonOffset, 'LBS_OF_CO2_TO_SEEDLINGS'),
      body: 'Tree Seedlings Grown For 10 Years',
      iconName: 'psychiatry',
      tooltip: 'The estimated sequestration rates of medium growth, 10-year old, coniferous and deciduous trees is 23.2 and 38lbs CO2e/year'
    },
    {
      title: getEquivalencyValue(totalCarbonOffset, 'LBS_OF_CO2_TO_FOREST_ACRES'),
      body: 'Acres Of U.S. Forests In One Year',
      iconName: 'forest',
      tooltip: 'The conversion factor for carbon sequestered in one year by one acre of U.S. Forest is -0.84 metric ton CO2.'
    },
    {
      title: getEquivalencyValue(totalCarbonOffset, 'LBS_OF_CO2_TO_WIND_TURBINES'),
      body: 'Wind Turbines Running For One Year',
      iconName: 'wind_power',
      tooltip:
        'Average electricity generated per wind turbine is determined by operational hours per year, turbine capacity, and US wind capacity factor.'
    }
  ];
};
