import { ButtonSize, EcoButtonProps } from '@components/shared/EcoButton';
import { EcoFilePicker, MIME_TYPES } from '@components/shared/EcoFilePicker';
import { FormError, FormErrorProps } from '@components/shared/FormError';
import { ENV } from '@utils/api/api';
import { uploadClaimsFile, uploadMerchantFile } from '@utils/api/file';
import * as DocumentPicker from 'expo-document-picker';
import { View } from 'react-native';

type MerchantFileUploaderProps = Partial<FormErrorProps> & {
  onSuccess: (fileUrl: string) => void;
  onError: (error: any) => void;
  onPick?: (uri: string, formData: FormData) => void;
  shopName: string;
  /* fileName should not include file extension */
  fileName: string;
  size?: ButtonSize;
  /* if true, the error message will still take up space but not visible. Useful if we need to maintain spacing  */
  transparentErrorColor?: boolean;
  // Public S3 bucket or Claims S3 bucket
  target?: 'public' | 'claims';
  buttonProps?: Omit<EcoButtonProps, 'onPress'>;
  type?: keyof typeof MIME_TYPES | (keyof typeof MIME_TYPES)[];
  shouldUploadFile?: boolean;
};

const fileNamePassedNameCheck = (fileName: string): boolean => {
  return !fileName.includes('.');
};

export const convertUriToBlob = async (uri: string): Promise<Blob> => fetch(uri).then((file) => file.blob());

export function MerchantFileUploader({
  errors,
  type,
  touched,
  field,
  onPick,
  onSuccess,
  onError,
  shopName,
  fileName,
  size = 'md',
  target = 'public',
  shouldUploadFile = true,
  transparentErrorColor = false,
  ...buttonProps
}: MerchantFileUploaderProps): JSX.Element {
  if (!fileNamePassedNameCheck(fileName)) {
    console.error(
      `fileName inserted contains file extensions ${fileName}. Please do not add file extension (.i.e. 'pdf', '.jpeg', '.png') to file name entered`
    );
  }

  const uploadFile = async (result: DocumentPicker.DocumentResult) => {
    try {
      if (result.type === 'success') {
        if (result.size && result.size > 5000000) {
          onError('File size is too large. Please upload a file less than 5MB');
          return;
        }

        const fileBlob = await convertUriToBlob(result.uri);
        const fileType: string = '' + fileBlob.type;
        const environment = ENV === 'PROD' ? '' : '-' + ENV.toLowerCase();
        const fileExtension = (Object.keys(MIME_TYPES) as Array<string>).find((key) => MIME_TYPES[key] === fileType);

        if (fileExtension) {
          const fileNameWithExtension = fileName + environment + '.' + fileExtension;
          const formData = new FormData();
          formData.append('file', fileBlob, fileNameWithExtension);
          if (shouldUploadFile) {
            // TODO properly return the merchantLogoUrl
            const logoUrl = target === 'public' ? await uploadMerchantFile(formData, shopName) : await uploadClaimsFile(formData, shopName);
            onSuccess(logoUrl);
          }
          onPick?.(result.uri, formData);
        } else {
          console.error(
            `FileType of uploaded file is currently not supported. Please upload a file with type: ${Object.keys(
              MIME_TYPES
            )}. The fileType you uploaded was ${fileType}. Thank you!`
          );
        }
      }
    } catch (err) {
      onError(err);
      console.error('error encountered error is', err);
    }
  };

  return (
    <View>
      <EcoFilePicker type={type} onPick={uploadFile} onError={onError} size={size} label="Upload File" {...buttonProps} />
      {errors && touched && field && (
        // eslint-disable-next-line react-native/no-color-literals
        <FormError style={transparentErrorColor ? { color: 'transparent' } : {}} errors={errors} touched={touched} field={field} />
      )}
    </View>
  );
}
