import { DataQuery, Filter, pascalToSpaced } from '@ecocart/universal-utils';
import { useOverlay } from '@hooks/useOverlay';
import { useWindow } from '@hooks/useWindow';
import { FlashList } from '@shopify/flash-list';
import { Gap } from '@utils/layout';
import { BoxShadow } from '@utils/styles/box-shadow';
import { colors } from '@utils/tailwind';
import clsx from 'clsx';
import Fuse from 'fuse.js';
import { orderBy } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { Pressable, StyleProp, View } from 'react-native';
import { EcoButton } from './EcoButton';
import { EcoCheckbox } from './EcoCheckbox';
import { EcoIcon } from './EcoIcon';
import { EcoInput } from './EcoInput';
import { EcoLoader } from './EcoLoader';
import { EcoPicker } from './EcoPicker';
import { EcoPill } from './EcoPill';
import { ColumnConfig, FilterConfig, evalDataQueryFilters, filterConfigToFilters } from './EcoTable.utils';
import { EcoTag } from './EcoTag';
import { EcoText } from './EcoText';
import { EcoToggle } from './EcoToggle';

interface Props<T extends Record<string, any>> {
  data?: T[];
  isLoading?: boolean;
  defaultDataQuery?: DataQuery;
  columns?: ColumnConfig<T>[];
  style?: StyleProp<any>;
  filterConfig?: FilterConfig<T>[];
  onFilterConfigChange?: (config: FilterConfig<T>[]) => void;
  /** Callback function to trigger when the row checkbox is clicked. If provided, rows will render checkbox */
  onRowClick?: (row: T, isAllSelected: boolean) => void;
  /** Callback function to trigger when the select all checkbox is clicked. If provided, the header will render checkbox */
  onSelectAllRowsClick?: (selectedRows: T[], isSelected: boolean) => void;
}

function FilterEditor({
  filters,
  filterConfig: _filterConfig,
  onSetFilterConfig
}: {
  filters: Filter<any>[] | undefined;
  filterConfig: FilterConfig<any>[];
  onSetFilterConfig: (filterConfig: FilterConfig<any>[]) => void;
}): JSX.Element {
  const hydrateFilterConfig = (_filterConfig: FilterConfig<any>[]): FilterConfig<any>[] => {
    return _filterConfig.map((config) => {
      const filter = filters?.find((filter) => filter.field === config.field);

      if (filter) {
        config.enabled = true;
        config.value = filter.value;
      }

      return config;
    });
  };
  const [filterConfig, setFilterConfig] = useState(hydrateFilterConfig(_filterConfig));

  return (
    <View className="flex-column w-full" style={Gap(2)}>
      {filterConfig?.map((config) => (
        <View key={'filter_config' + String(config.field)}>
          <EcoText color="subdued" fontSize="xs">
            {pascalToSpaced(String(config.field))}
          </EcoText>
          <View key={`config-${String(config.field)}`} style={Gap(2)} className="flex-row justify-between items-center">
            <EcoPicker
              className="flex-1"
              style={{ maxWidth: 255 }}
              value={config.value}
              items={[{ value: undefined, label: '--' }, ...config.options] as any}
              onValueChange={(value) =>
                setFilterConfig((prev) => prev.map((c) => (c.field === config.field ? { ...c, value, enabled: value !== undefined } : c)))
              }
            />
            <View>
              {config.enabled ? (
                <EcoButton
                  size="sm"
                  variant="ghost"
                  colorScheme="danger"
                  onPress={() =>
                    setFilterConfig((prev) =>
                      prev.map((c) => (c.field === config.field ? ({ ...c, enabled: false, value: undefined } as any) : c))
                    )
                  }
                >
                  ❌
                </EcoButton>
              ) : null}
            </View>
          </View>
        </View>
      ))}
      <EcoButton className="mt-2" fullWidth={true} onPress={() => onSetFilterConfig(filterConfig)}>
        Set Filters
      </EcoButton>
    </View>
  );
}

function FilterPills({
  filters,
  filterConfig,
  onRemoveFilter
}: {
  filters: Filter[] | undefined;
  filterConfig: FilterConfig<any>[] | undefined;
  onRemoveFilter: (filterIndex: number) => void;
}): JSX.Element {
  const getLabel = (filter: Filter): string | any => {
    const foundFilterConfigOptions = (filterConfig || []).find((config) => config.field === filter.field)?.options;
    return (foundFilterConfigOptions || []).find((option) => option.value === filter.value)?.label || filter.value;
  };

  return (
    <View className="flex-row items-center" style={Gap(1)}>
      {filters?.map((filter, index) => (
        <EcoTag key={'filter_pill' + String(filter.field)} type="info">
          <View className="flex-row items-center" style={Gap()}>
            <View className="flex-row items-center">
              <EcoText color="white" fontWeight="semibold">
                {getLabel(filter)}
              </EcoText>
            </View>
            <Pressable onPress={() => onRemoveFilter(index)} className="-mr-1">
              <EcoIcon name="close" className="text-white" />
            </Pressable>
          </View>
        </EcoTag>
      ))}
    </View>
  );
}

export function EcoTable<T extends Record<string, any>>({
  isLoading,
  data,
  columns = [],
  defaultDataQuery = {},
  filterConfig,
  onFilterConfigChange,
  onRowClick,
  onSelectAllRowsClick
}: Props<T>): JSX.Element {
  const addFilterRef = useRef<any>(null);
  const { showMenu, hideMenu } = useOverlay();
  const { width } = useWindow();
  const [availableData, setAvailableData] = useState(data);
  const [selectedRows, setSelectedRows] = useState<Set<T>>(new Set());

  // Individual row checkbox
  const handleRowClick = (row: T, isSelected: boolean) => {
    const newSelectedRows = new Set([...selectedRows]);
    if (isSelected) {
      newSelectedRows.add(row);
    } else {
      newSelectedRows.delete(row);
    }
    setSelectedRows(newSelectedRows);
    onRowClick?.(row, isSelected);
  };

  // Check all checkbox
  const handleSelectAllRowsClick = (isSelected: boolean) => {
    if (!tableData) return;
    const allSelectedRows = isSelected ? [...tableData] : [];
    setSelectedRows(allSelectedRows.length ? new Set(allSelectedRows) : new Set());
    onSelectAllRowsClick?.(allSelectedRows, isSelected);
  };

  const [dataQuery, setDataQuery] = useState<DataQuery>({
    ...{
      search: {
        fields: (columns || []).map(({ field }) => field),
        value: ''
      },
      sorts: [],
      take: 10,
      skip: 0
    },
    ...defaultDataQuery
  });

  const sortField = (field: string): void => {
    const dir = (dataQuery.sorts || []).some((sort) => sort.field === field && sort.dir === 'asc') ? 'desc' : 'asc';
    setDataQuery({ ...dataQuery, sorts: [{ field, dir }] });
  };

  /* -------------------------- DataQuery components -------------------------- */
  const dataQuerySearch = useMemo(() => {
    return DataQuerySearch;
  }, [dataQuery.search, DataQuerySearch]);

  function DataQuerySearch(): JSX.Element {
    const handleQueryChange = (value: string) => {
      setDataQuery({ ...dataQuery, search: { fields: dataQuery.search?.fields || [], value } });
    };

    return (
      <View className="w-1/2">
        <EcoInput value={dataQuery.search?.value} onChangeText={handleQueryChange} placeholder="Search..." />
      </View>
    );
  }

  const dataQueryFilters = useMemo(() => {
    return DataQueryFilter;
  }, [dataQuery.filters, DataQueryFilter]);

  const handleShowFilterMenu = () => {
    if (!filterConfig) return;

    const { top, left, height, width: buttonWidth } = addFilterRef.current?.getBoundingClientRect() || {};
    const right = width - left - buttonWidth;

    const handleOnFilterSave = (filterConfig: FilterConfig<T>[]) => {
      setDataQuery({ ...dataQuery, filters: filterConfigToFilters(filterConfig) });
      onFilterConfigChange && onFilterConfigChange(filterConfig);
      hideMenu();
    };

    showMenu({
      content: (
        <View style={[BoxShadow('softdeep'), { width: 280 }]} className="p-3 rounded-sm bg-white">
          <FilterEditor filters={dataQuery.filters} filterConfig={filterConfig} onSetFilterConfig={handleOnFilterSave} />
        </View>
      ),
      config: { position: { top: top + height, right } }
    });
  };

  const handleRemoveFilter = (index: number) => {
    if (filterConfig) {
      filterConfig[index].enabled = false;
      setDataQuery({ ...dataQuery, filters: filterConfigToFilters(filterConfig) });
      onFilterConfigChange && onFilterConfigChange(filterConfig);
    }
  };

  function DataQueryFilter(): JSX.Element {
    return filterConfig ? (
      <View className="flex-1 flex-row justify-between items-center" style={Gap(2)}>
        <FilterPills filters={dataQuery.filters} filterConfig={filterConfig} onRemoveFilter={handleRemoveFilter} />
        <View ref={addFilterRef}>
          <EcoButton variant="outlined" onPress={handleShowFilterMenu}>
            Add Filter
          </EcoButton>
        </View>
      </View>
    ) : (
      <></>
    );
  }

  function ListHeaderComponent() {
    const firstSort = dataQuery?.sorts?.[0];

    return (
      <View className="flex-row pl-4 pr-2 justify-center bg-gray-50 rounded-tr-sm rounded-tl-sm border border-gray-100">
        {onSelectAllRowsClick && (
          <View className="py-3 justify-center pr-2">
            <EcoCheckbox value={isAllSelected} onValueChange={handleSelectAllRowsClick} disabled={tableData.length === 0} />
          </View>
        )}
        {columns.map(({ title, field, align = 'flex-start', flex = 1, style, className }, index) => {
          return (
            <View
              className={clsx('pr-4', className)}
              style={[{ alignItems: align }, { flex: flex ?? 1 }, style]}
              key={`column-${String(field)}-${index}`}
            >
              <Pressable
                onPress={() => sortField(String(field))}
                className="flex-row py-4"
                style={[{ flex }, style]}
                key={String(field) + index}
              >
                <EcoText fontWeight="semibold" style={style}>
                  {title ?? pascalToSpaced(String(field))}
                </EcoText>
                {firstSort?.field === field ? (
                  <EcoIcon name={firstSort.dir === 'asc' ? 'expand_less' : 'expand_more'} className="pl-2 text-lg" />
                ) : null}
              </Pressable>
            </View>
          );
        })}
      </View>
    );
  }

  const getStats = () => {
    const skip = (dataQuery as any).skip;
    const take = (dataQuery as any).take;
    const lastPageItem = skip * take + take;
    const totalItems = (availableData || []).length;

    const stats: any = {
      firstPageItem: skip * take + 1,
      lastPageItem: lastPageItem > totalItems ? totalItems : lastPageItem,
      totalItems
    };

    stats.isFirstPage = skip === 0;
    stats.isLastPage = stats.firstPageItem + take > stats.totalItems;

    return stats;
  };

  function ListFooterComponent() {
    const { isFirstPage, firstPageItem, lastPageItem, isLastPage, totalItems } = getStats();

    return (
      <View className="flex flex-row justify-end items-center w-full p-3 rounded-br-sm rounded-bl-sm border-l border-b border-r border-gray-100">
        <View className="flex-row items-center" style={Gap(2)}>
          <EcoText>Showing</EcoText>
          <EcoPicker
            items={[5, 10, 25, 50, 100, 1000]}
            className="min-h-[0] py-1"
            value={dataQuery.take || 10}
            onValueChange={(value) => setDataQuery({ ...dataQuery, take: Number(value) })}
          />
          <EcoText>Items</EcoText>
        </View>
        <View className="flex-row items-center ml-4" style={Gap(2)}>
          <EcoButton
            size="sm"
            variant="outlined"
            isDisabled={isFirstPage}
            onPress={() => setDataQuery({ ...dataQuery, skip: (dataQuery as any).skip - 1 })}
            leftIcon="chevron_left"
          />
          <EcoText>{`${firstPageItem} - ${lastPageItem} / ${totalItems}`}</EcoText>
          <EcoButton
            isDisabled={isLastPage}
            size="sm"
            variant="outlined"
            leftIcon="chevron_right"
            onPress={() => setDataQuery({ ...dataQuery, skip: (dataQuery as any).skip + 1 })}
          />
        </View>
      </View>
    );
  }

  function Content<T extends Record<string, any>>({ item, column }: { item: T; column: ColumnConfig<T> }): JSX.Element {
    const { format, onPress, field, style, type } = column;
    // returns entire item for the format function
    const formattedValue = format?.(item) ?? (item[field] || '--');

    if (type === 'toggle')
      return <EcoToggle disabled={column.disabled} value={formattedValue} onValueChange={(val: boolean) => onPress?.(item, val)} />;
    if (typeof formattedValue === 'boolean') return formattedValue ? <EcoText fontSize="xl">✅</EcoText> : <EcoText>❌</EcoText>;
    if (Array.isArray(formattedValue))
      return (
        <View style={[Gap(), style]}>
          {formattedValue.map((val: string) => (
            <EcoPill key={val}>{val}</EcoPill>
          ))}
        </View>
      );

    if (onPress) {
      return (
        <EcoText
          color="link"
          className="cursor-pointer"
          style={style}
          onPress={() => {
            onPress(item);
          }}
          fontSize="base"
        >
          {formattedValue}
        </EcoText>
      );
    }
    return (
      <EcoText style={style} fontSize="base">
        {formattedValue}
      </EcoText>
    );
  }

  const tableData = useMemo(() => {
    const searchableKeys = columns.map((col) => Boolean(col.searchable) && (col.dataKey ?? col.field)).filter(Boolean) as string[];
    // Fuzzy search
    const fuse = new Fuse(data || [], {
      isCaseSensitive: false,
      threshold: 0.3,
      keys: searchableKeys
    });

    const filteredData = () => {
      const take = dataQuery.take || 10;
      const skipCount = (dataQuery.skip || 0) * take;
      const searchTerm = dataQuery.search?.value;

      let _availableData = orderBy(
        searchTerm ? fuse.search(searchTerm).map((result) => result.item) : data,
        (dataQuery.sorts || []).map(({ field }) => field),
        (dataQuery.sorts || []).map(({ dir }) => dir)
      );

      if (filterConfig) {
        _availableData = evalDataQueryFilters<T>(_availableData, dataQuery.filters);
      }

      // set value (before being paged/sliced) for use in footer stats
      setAvailableData(_availableData);

      return _availableData.slice(skipCount, skipCount + take);
    };

    return filteredData();
  }, [columns, data, dataQuery]);

  const isAllSelected = tableData.length > 0 && selectedRows.size === tableData.length;

  return (
    <>
      {isLoading ? (
        <EcoLoader size="large" className="py-9" color={colors.primary[400]} />
      ) : (
        <View className="flex flex-col">
          <View className="flex-row justify-between items-center mb-3" style={Gap(2)}>
            {dataQuerySearch()}
            {dataQueryFilters()}
          </View>
          <FlashList
            data={tableData}
            estimatedItemSize={45}
            keyExtractor={(item: any, index: number) => item?.id || String(index)}
            ListHeaderComponent={ListHeaderComponent}
            extraData={selectedRows}
            renderItem={({ item }) => (
              <View className="flex-row justify-evenly py-2 pr-2 pl-4 border-r border-l border-b border-gray-100">
                {onRowClick && (
                  <View className="justify-center pr-2">
                    <EcoCheckbox value={selectedRows.has(item)} onValueChange={(isSelected) => handleRowClick(item, isSelected)} />
                  </View>
                )}

                {columns.map((column, index) => (
                  <View
                    className="pr-4 justify-center"
                    style={[{ alignItems: column.align }, { flex: column.flex ?? 1 }, column.style]}
                    key={`column-${String(column.field)}-${index}`}
                  >
                    <Content item={item} column={column} />
                  </View>
                ))}
              </View>
            )}
            ListFooterComponent={ListFooterComponent}
          />
        </View>
      )}
    </>
  );
}
