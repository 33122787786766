import { useOverlay } from '@hooks/useOverlay';
import { FlashList } from '@shopify/flash-list';
import { BoxShadow } from '@utils/styles/box-shadow';
import { colors } from '@utils/tailwind';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Pressable, View } from 'react-native';
import { EcoInput, EcoInputProps } from './EcoInput';
import { EcoLoader } from './EcoLoader';
import { EcoText } from './EcoText';

interface Props extends EcoInputProps {
  onChangeSelection?: ((text: string) => void) | undefined;
  searchCall: <T = string[]>(query: string) => Promise<T>;
}

export function EcoAutocomplete({ value, style, onChangeText, onChangeSelection, searchCall, ...otherProps }: Props): JSX.Element {
  const ref = useRef<any>(null);
  const [query, setQuery] = useState<string>();
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const { showMenu, hideMenu } = useOverlay();

  useEffect(() => {
    setQuery(value || '');
  }, [value]);

  const fetchResults = async (_query: string) => {
    if (!ref.current.value) return;

    handleOpenMenu([], true);
    const options = await searchCall(_query);
    handleOpenMenu(options);
    if (!ref.current.value) hideMenu();
  };

  const debouncedFetchResults = useCallback(debounce(fetchResults, 750), []);

  const handleQueryChange = (val: string): void => {
    onChangeText && onChangeText(val);

    setQuery(val);
    ref.current.value = val;
    setIsSelected(false);
    if (!val) hideMenu();
  };

  const handleSelection = (val: string): void => {
    setIsSelected(true);
    setQuery(val);
    hideMenu();
    onChangeSelection && onChangeSelection(val);
  };

  useEffect(() => {
    if (!query || isSelected) return;

    debouncedFetchResults(query);
  }, [query, isSelected, debouncedFetchResults]);

  const handleOpenMenu = (options: string[], isLoading: boolean = false) => {
    const { top, left, width, height } = ref.current?.getBoundingClientRect() || {};

    showMenu({
      content: isLoading ? (
        <View style={[BoxShadow('softdeep'), { width }]} className="p-3 rounded-sm bg-white">
          <EcoLoader size="small" color={colors.primary[400]} />
        </View>
      ) : (
        <View style={[BoxShadow('softdeep'), { width }]} className="p-3 rounded-sm bg-white max-h-[250px]">
          {options?.length ? (
            <FlashList
              data={options}
              keyExtractor={(item) => item}
              renderItem={({ item }: { item: string; index: number }) => (
                <Pressable className="z-[1000] py-1" key={item} onPress={() => handleSelection(item)}>
                  <EcoText>{item}</EcoText>
                </Pressable>
              )}
            />
          ) : (
            <EcoText>No Results</EcoText>
          )}
        </View>
      ),
      config: { position: { top: top + height, left: left } }
    });
  };

  return (
    <View style={style} className="z-[1000] border-black" ref={ref}>
      <EcoInput value={query} onChangeText={handleQueryChange} {...otherProps} />
    </View>
  );
}
