import { EcoInput, FormPicker } from '@components/shared';
import { Gap } from '@utils/layout';
import { useState } from 'react';
import { View } from 'react-native';
import { FormPassword } from '../shared/FormPassword';
import { ConfigContainer } from '../views/ConfigContainer';
import { DemoContainer } from '../views/DemoContainer';
import { InternalToolsCard } from '../views/InternalToolsCard';

export function DSEcoInput(): JSX.Element {
  const [label, setLabel] = useState('');
  const [startAdornment, setStartAdornment] = useState('$');
  const [endAdornment, setEndAdornment] = useState('USD');
  const [value, setValue] = useState('');
  const [disabled, setDisabled] = useState<'true' | 'false'>('false');

  const handleSetDisabled = (val: 'true' | 'false') => {
    if (val === 'true') return setDisabled('true');
    return setDisabled('false');
  };

  return (
    <View style={Gap()}>
      <InternalToolsCard componentName="EcoInput">
        <DemoContainer>
          {/* Actual demo component */}
          <EcoInput
            label={label}
            value={value}
            onChangeText={setValue}
            disabled={disabled === 'true'}
            startAdornment={startAdornment !== '' ? startAdornment : undefined}
            endAdornment={endAdornment !== '' ? endAdornment : undefined}
          />
          {/* End of actual demo component */}
        </DemoContainer>
      </InternalToolsCard>
      <InternalToolsCard componentName="FormPassword">
        <DemoContainer>
          {/* Actual demo component */}
          <FormPassword label={label} value={value} onChangeText={setValue} />
          {/* End of actual demo component */}
        </DemoContainer>
      </InternalToolsCard>

      <InternalToolsCard componentName="EcoInput">
        <DemoContainer>
          {/* Actual demo component */}
          <EcoInput label="Field Label" value={value} onChangeText={setValue} />
          {/* End of actual demo component */}
        </DemoContainer>
      </InternalToolsCard>

      <ConfigContainer>
        {/* Configuration component */}
        <View style={Gap()} className="flex-row">
          <EcoInput label="Label" value={label} onChangeText={(v: string) => setLabel(v)} placeholder="Enter some text" />
          <EcoInput
            label="Start Adornment"
            value={startAdornment}
            onChangeText={(v: string) => setStartAdornment(v)}
            placeholder="Enter some text"
          />
          <EcoInput
            label="End Adornment"
            value={endAdornment}
            onChangeText={(v: string) => setEndAdornment(v)}
            placeholder="Enter some text"
          />
          <FormPicker
            className="flex-1"
            label="Disabled"
            items={['true', 'false']}
            value={disabled}
            onValueChange={(val) => handleSetDisabled(val)}
          />
        </View>
        {/* End of Configuration component */}
      </ConfigContainer>
    </View>
  );
}
