import { screens } from '@utils/tailwind';
import Constants from 'expo-constants';
import { Dimensions, Platform } from 'react-native';

export const Window = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height
};

export const isIos = Platform.OS === 'ios';
export const isAndroid = Platform.OS === 'android';
export const isWeb = Platform.OS === 'web';
export const isNative = !isWeb;
export const DESKTOP_WIDTH = Number((screens.md || '').replace('px', ''));
export const isDesktop = Window.width >= DESKTOP_WIDTH;
export const isDesktopWeb = isDesktop && isWeb;
export const isExpoGo = Constants.appOwnership === 'expo';
