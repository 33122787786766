import { EcoButton, EcoInput } from '@components/shared';
import { useOverlay } from '@hooks/useOverlay';
import { forgotPassword } from '@utils/api/auth';
import { Gap } from '@utils/layout';
import { GlobalFormProps } from '@utils/prop-types';
import { CreateValidationSchema } from '@utils/validation';
import { Formik } from 'formik';
import { noop } from 'lodash';
import React, { useState } from 'react';
import { View } from 'react-native';

export default function ForgotPasswordForm({ onSuccess = noop }: GlobalFormProps): JSX.Element {
  const { showSuccess, handleError } = useOverlay();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmit = async ({ email }: { email: string }): Promise<void> => {
    setIsSubmitting(true);

    try {
      const response = await forgotPassword(email);
      showSuccess('Please check your email for password reset instructions');
      onSuccess && onSuccess(response);
    } catch (error: any) {
      handleError(
        (error?.message || '').includes('UserNotFoundException')
          ? `If a matching account was found, an email wil be sent to ${email} with password reset instructions`
          : error
      );
      setIsSubmitting(false);
    }
  };

  return (
    <Formik
      validateOnMount
      initialValues={{
        email: ''
      }}
      validationSchema={CreateValidationSchema(['email'])}
      onSubmit={onSubmit}
    >
      {({ handleChange, handleSubmit, handleBlur, values, isValid, errors, touched }) => (
        <View className="w-full" style={Gap()}>
          <EcoInput
            label="Email"
            value={values.email}
            onChangeText={handleChange('email')}
            onBlur={handleBlur('email')}
            placeholder="user@example.com"
            autoComplete="email"
            keyboardType="email-address"
            errors={errors}
            touched={touched}
            field="email"
          />
          <EcoButton
            nativeID="forgot-password-form__submit-button"
            fullWidth
            isDisabled={!isValid}
            isLoading={isSubmitting}
            onPress={handleSubmit}
          >
            Send
          </EcoButton>
        </View>
      )}
    </Formik>
  );
}
