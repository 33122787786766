import { TooltipContext } from '@context/tooltip';
import { Gap } from '@utils/layout';
import React, { ReactNode, useContext } from 'react';
import { ScrollView, StyleProp, View } from 'react-native';

interface Props {
  children: ReactNode;
  style?: StyleProp<any>;
  className?: string;
}

export default function EcoScrollView({ style, children }: Props): JSX.Element {
  const _tooltipContext = useContext(TooltipContext);
  const { hideTooltip } = _tooltipContext || {};

  const handleScroll = () => {
    hideTooltip?.();
  };

  return (
    <ScrollView
      contentContainerStyle={{ flexDirection: 'row', justifyContent: 'center' }}
      onScroll={handleScroll}
      scrollEventThrottle={500}
    >
      <View className="p-4 sm:p-7 max-w-[1440px] w-full" style={[Gap(), style]}>
        {children}
      </View>
    </ScrollView>
  );
}
