import clsx from 'clsx';
import { ReactNode, useRef, useState } from 'react';
import { Animated, Easing, Pressable, View } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { EcoIcon } from './EcoIcon';
import { EcoText } from './EcoText';

const DURATION = 200;

type Props = {
  header: string;
  children: ReactNode;
};

export const EcoAccordion = ({ header, children }: Props): JSX.Element => {
  const [isExpanded, setExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const animation = useRef(new Animated.Value(0)).current;

  const toggleAccordion = () => {
    setExpanded(!isExpanded);
    Animated.timing(animation, {
      toValue: isExpanded ? 0 : 1,
      duration: DURATION,
      easing: Easing.linear,
      useNativeDriver: true
    }).start();
  };

  return (
    <View>
      <Pressable onPress={toggleAccordion} ref={ref} className="w-max mb-2">
        <View className="flex-row justify-start items-center">
          <EcoText
            fontSize="sm"
            textAlign="center"
            fontWeight="medium"
            color={isHovered ? 'link-hover' : 'link'}
            className={clsx('text-lg mr-1')}
          >
            {header}
          </EcoText>
          <EcoIcon
            name={isExpanded ? 'expand_less' : 'expand_more'}
            className={clsx(isHovered ? 'text-secondary-600' : 'text-secondary-500')}
          />
        </View>
      </Pressable>

      <Animated.View
        style={{
          opacity: animation,
          maxHeight: animation.interpolate({
            inputRange: [0, 1],
            outputRange: [0, contentHeight]
          })
        }}
      >
        <View
          onLayout={({ nativeEvent }) => {
            setContentHeight(nativeEvent.layout.height);
          }}
        >
          {children}
        </View>
      </Animated.View>
    </View>
  );
};
