import { EcoChart, EcoChartLegend, EcoIcon, EcoSkeleton, EcoText, EcoTooltip } from '@components/shared';
import { MerchantAdmin } from '@ecocart/entities';
import { formatNumber } from '@ecocart/universal-utils';
import { useWindow } from '@hooks/useWindow';
import { useQuery } from '@tanstack/react-query';
import { getReturnCustomerRates } from '@utils/api/reporting';
import { Gap } from '@utils/layout';
import { GlobalStylesProps } from '@utils/prop-types';
import { colors } from '@utils/tailwind';
import { View } from 'react-native';
import {
  Category,
  Title,
  TooltipContent,
  donutChartConfig,
  generateChartJSData,
  getDifference,
  getSummaryText,
  parseDecimalToPercentage
} from './RepeatCustomerRate.utils';

function Card({
  accentColor,
  title,
  sum,
  category,
  total
}: {
  accentColor: string;
  category: Category;
  title?: string;
  sum: number;
  total: number;
}): JSX.Element {
  const dataNotAvailable = Number.isNaN(sum) || Number.isNaN(total);

  return (
    <View className="py-2 px-3 bg-white rounded-sm flex-1 border" style={[Gap(1), { borderColor: accentColor }]}>
      {title && (
        <EcoText fontSize="sm" color="subdued">
          {title}
        </EcoText>
      )}
      {dataNotAvailable ? (
        <View className="w-1/2">
          <EcoSkeleton height="sm" noOfLines={2} spacing={2} />
        </View>
      ) : (
        <>
          <EcoText fontSize="2xl" className="text-gray-800">
            {total === 0 ? 'N/A' : formatNumber(sum / total, { style: 'percent', maximumFractionDigits: 1, minimumFractionDigits: 1 })}
          </EcoText>
          <View className="flex-row justify-between">
            <EcoText fontSize="sm" color="subdued">
              <EcoText fontSize="sm" color={category === 'non-ecocart-repeats' ? 'accent-secondary' : 'accent-primary'}>
                {formatNumber(sum)}
              </EcoText>{' '}
              of {formatNumber(total)}
            </EcoText>
            <EcoTooltip
              tooltip={
                <View className="w-[300px]" style={Gap()}>
                  {TooltipContent[category].title}
                  {TooltipContent[category].body}
                </View>
              }
            >
              <EcoIcon name="info" className="text-gray-600" />
            </EcoTooltip>
          </View>
        </>
      )}
    </View>
  );
}

interface Props extends GlobalStylesProps {
  merchantAdmin?: MerchantAdmin;
}

export default function RepeatCustomerRate({ merchantAdmin }: Props): JSX.Element {
  const shopName = merchantAdmin?.shopName || '';
  const { data, isFetching } = useQuery(['getOrders', shopName], () => getReturnCustomerRates(shopName));
  const hasNoData = data && 'message' in data && data.message === 'No data found for this request';
  const chartJSData = generateChartJSData(data);
  const difference = getDifference(data);
  const isPositive = difference >= 0;
  const { isAboveSMScreen } = useWindow();

  return (
    <View className="p-5 bg-white rounded-md flex-1 w-full" style={Gap(1)}>
      <View className="flex flex-col" style={Gap(2)}>
        <View className="flex-row justify-between flex-wrap items-center" style={Gap()}>
          <EcoText fontSize="lg" fontWeight="medium">
            Repeat Customer Rate
          </EcoText>
          <View className="flex-row items-center" style={Gap(1)}>
            <EcoIcon name="lock_clock" className="text-gray-300" />
            <EcoText fontSize="sm" color="disabled">
              All Time
            </EcoText>
          </View>
        </View>
      </View>
      {hasNoData ? (
        <View style={Gap(2)} className="justify-center items-center max-w-[344px] mx-auto flex-1">
          <EcoIcon name="warning" variant="filled" className="text-warning-500" size="7xl" />
          <EcoText fontSize="base" color="enabled">
            Sorry, not enough data yet!
          </EcoText>
          <EcoText fontSize="sm" color="subdued" textAlign="center">
            Repeat Customer data often requires monitoring shopper behavior over a long period of time and varies from store to store.
          </EcoText>
        </View>
      ) : (
        <View>
          <EcoChartLegend datasets={chartJSData.datasets} />
          <View style={Gap(2)} className="max-w-[310px] mx-auto mt-6 flex-1">
            <View className="relative mx-auto">
              <EcoChart
                isLoading={isFetching}
                loaderHeight={190}
                type="donut"
                style={{ maxHeight: 150, maxWidth: isAboveSMScreen ? 350 : 250 }}
                options={donutChartConfig.options}
                data={chartJSData}
              />
              {data && (
                <View
                  className="flex-row absolute bottom-0 left-1/2 items-center"
                  // @ts-expect-error - TS doesn't like translateX/Y
                  style={{ transform: [{ translateX: '-50%' }] }}
                >
                  <EcoIcon
                    size="5xl"
                    name={isPositive ? 'arrow_drop_up' : 'arrow_drop_down'}
                    className={isPositive ? 'text-algea-600' : 'text-danger-700'}
                  />
                  <EcoText
                    fontSize={isAboveSMScreen ? '5xl' : '4xl'}
                    style={{ color: isPositive ? colors.algea[600] : colors.danger[700] }}
                  >
                    {parseDecimalToPercentage(Math.abs(difference))}
                  </EcoText>
                </View>
              )}
            </View>
            {!data ? null : (
              <View className="flex-row items-center" style={[Gap(1), { width: '97%' }]}>
                {data && (
                  <EcoText fontSize="sm" textAlign="center" color="subdued">
                    {getSummaryText(difference)}
                  </EcoText>
                )}
                <EcoTooltip
                  tooltip={
                    <View className="w-[300px]" style={Gap()}>
                      {TooltipContent.summary.title}
                      {TooltipContent.summary.body}
                    </View>
                  }
                >
                  <EcoIcon name="info" className="text-gray-600" />
                </EcoTooltip>
              </View>
            )}
          </View>
          <View className="flex-row mt-6" style={Gap(3)}>
            {chartJSData.datasets.map((dataset, index) => (
              <Card
                key={dataset.key}
                category={dataset.key as Category}
                title={Title[dataset.key as Category]}
                sum={Number(dataset.data[0])}
                total={Number(dataset.data[1])}
                accentColor={index === 0 ? colors.secondary[500] : colors.algea[500]}
              />
            ))}
          </View>
        </View>
      )}
    </View>
  );
}
