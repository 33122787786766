import { RewardRedemptionForm } from '@components/forms/RewardRedemptionForm';
import { RedemptionType } from '@ecocart/entities';
import { useOverlay } from './useOverlay';

export const useRewardsModal = (): {
  showRewardsFormModal: (redemptionType: RedemptionType) => void;
  hideRewardsFormModal: () => void;
} => {
  const { showModal, hideModal } = useOverlay();

  const showRewardsFormModal = (redemptionType: RedemptionType): void => {
    showModal({
      nativeID: 'select-redemption-modal',
      content: <RewardRedemptionForm redemptionType={redemptionType} />
    });
  };

  return { showRewardsFormModal, hideRewardsFormModal: hideModal };
};
