import { EcoButton, EcoCard, EcoIcon, EcoImage, EcoInput, EcoMultiSelect, EcoText, FormButtons } from '@components/shared';
import { EcoMultiSelectItem } from '@components/shared/EcoMultiSelect';
import { FormCheckbox } from '@components/shared/FormCheckbox';
import { Impact, Project, UNGoal, UN_GOALS, VERIFICATION_TYPES, VerificationType } from '@ecocart/entities';
import { useOverlay } from '@hooks/useOverlay';
import { updateProject } from '@utils/api/projects';
import { Gap } from '@utils/layout';
import { GlobalFormProps } from '@utils/prop-types';
import { BoxShadow } from '@utils/styles/box-shadow';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Pressable, View } from 'react-native';

/* ------------------------------- for project verification ------------------------------ */

const verificationItems: EcoMultiSelectItem<VerificationType>[] = VERIFICATION_TYPES.map((type) => ({ value: type, label: type }));
const UNGoalsItems: EcoMultiSelectItem<UNGoal>[] = UN_GOALS.map((goal) => ({ value: goal, label: goal }));

interface Props extends GlobalFormProps {
  project: Project;
}

export default function ProjectForm({ project, onSuccess }: Props): JSX.Element {
  // ensure impacts array has two elements, so that it's easier to work with inside form
  project.impacts = [
    { ...new Impact(), ...project.impacts[0] },
    { ...new Impact(), ...project.impacts?.[1] }
  ];

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { handleError, showSuccess } = useOverlay();
  const onSubmit = async (_project: Project) => {
    setIsSubmitting(true);

    try {
      await updateProject(_project);
      showSuccess('Successfully updated project');
      setIsSubmitting(false);
      onSuccess && onSuccess(_project);
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  return (
    <Formik initialValues={project} onSubmit={onSubmit}>
      {({ handleChange, setFieldValue, handleSubmit, values, isValid }) => (
        <View style={Gap(2)}>
          <EcoInput label="Name" value={values.name} placeholder="Name" onChangeText={handleChange('name')} />
          <FormCheckbox
            label="This is a Portfolio of Multiple Projects"
            value={values.isPortfolio}
            onValueChange={(value: boolean) => setFieldValue('isPortfolio', value)}
          />
          <EcoInput
            label="Description"
            multiline
            numberOfLines={8}
            value={values.description}
            onChangeText={handleChange('description')}
            placeholder="Description"
          />

          {values.impacts.map((_, idx) => (
            <EcoCard
              key={crypto.randomUUID()}
              title={`Impact ${idx + 1}`}
              className="border-[1px] border-gray-100"
              style={BoxShadow('none')}
            >
              <EcoInput
                label="Amount"
                placeholder="Amount"
                value={String(values.impacts[idx].amount)}
                onChangeText={(value) => {
                  setFieldValue(`impacts.${idx}.amount`, value);
                }}
              />
              <EcoInput
                label="Label"
                placeholder="Label"
                value={values.impacts[idx].label}
                onChangeText={(value) => {
                  setFieldValue(`impacts.${idx}.label`, value);
                }}
              />
              <EcoInput
                label="Icon"
                placeholder="Icon"
                value={String(values.impacts[idx].icon)}
                onChangeText={(value) => {
                  setFieldValue(`impacts.${idx}.icon`, value);
                }}
              />
            </EcoCard>
          ))}

          <EcoInput label="Location" value={values.location} onChangeText={handleChange('location')} placeholder="Location" />
          <EcoInput label="Url" value={values.url} onChangeText={handleChange('url')} placeholder="Url" />
          <EcoInput label="Type" value={values.type} onChangeText={handleChange('type')} placeholder="Type" />
          <EcoMultiSelect
            items={verificationItems}
            selectedItems={values.verifications.map((value) => ({ value, label: value }))}
            onSelectedItemsChange={(items) => {
              setFieldValue(
                'verifications',
                items.map(({ value }) => value)
              );
            }}
            label="Verifications"
            selectText="Pick Verifications"
            searchInputPlaceholderText="Search Verifications..."
          />
          <EcoMultiSelect
            items={UNGoalsItems}
            selectedItems={(values.goals || []).map((value) => ({ value, label: value }))}
            onSelectedItemsChange={(items) => {
              setFieldValue(
                'goals',
                items.map(({ value }) => value)
              );
            }}
            label="UN Sustainable Development Goals"
            selectText="Pick Goals"
            searchInputPlaceholderText="Search Goals..."
          />
          <View style={Gap(5)} className="mt-4">
            <View style={Gap()}>
              <EcoText fontWeight="semibold" fontSize="lg">
                Images
              </EcoText>
              {values.images.map((url, index) => (
                <View key={crypto.randomUUID()} className="flex flex-row mb-2">
                  <EcoImage src={values.images[index]} className="mr-2" width={70} height={70} />
                  <EcoInput
                    label={`Image ${index + 1}`}
                    value={values.images[index]}
                    onChangeText={handleChange(`images[${index}]`)}
                    placeholder="Image"
                    className="flex-1 mr-2"
                  />
                  <Pressable
                    className="self-center mt-4"
                    onPress={() => setFieldValue('images', [...values.images.filter((_, i) => i !== index)])}
                  >
                    <EcoIcon name="delete" className="text-danger-500" size="3xl" />
                  </Pressable>
                </View>
              ))}
              <EcoButton size="md" onPress={() => setFieldValue('images', [...values.images, ''])}>
                Add Image
              </EcoButton>
            </View>
            <View style={Gap()}>
              <EcoText fontWeight="semibold" fontSize="lg">
                Benefits
              </EcoText>
              {values.benefits.map((benefit, index) => (
                <View key={crypto.randomUUID()} className="flex flex-row mb-2">
                  <EcoInput
                    multiline
                    numberOfLines={3}
                    label={`Benefit ${index + 1}`}
                    value={benefit}
                    onChangeText={handleChange(`benefits[${index}]`)}
                    placeholder="Benefit"
                    className="flex-1 mr-2"
                  />

                  <Pressable
                    className="self-center mt-4"
                    onPress={() => setFieldValue('benefits', [...values.benefits.filter((_, i) => i !== index)])}
                  >
                    <EcoIcon name="delete" className="text-danger-500" size="3xl" />
                  </Pressable>
                </View>
              ))}
              <EcoButton size="md" onPress={() => setFieldValue('benefits', [...values.benefits, ''])}>
                Add Benefit
              </EcoButton>
            </View>
          </View>

          <FormButtons>
            <EcoButton isDisabled={!isValid || isSubmitting} isLoading={isSubmitting} onPress={handleSubmit}>
              Save
            </EcoButton>
          </FormButtons>
        </View>
      )}
    </Formik>
  );
}
