import { EcoIcon, EcoText } from '@components/shared';
import { IconName } from '@components/shared/EcoIcon.utils';
import { BottomTabBarProps, BottomTabNavigationOptions, createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {
  createDrawerNavigator,
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerNavigationOptions
} from '@react-navigation/drawer';
import { ParamListBase, RouteProp } from '@react-navigation/native';
import { createStackNavigator, StackNavigationOptions, TransitionSpecs } from '@react-navigation/stack';
import { TOP_NAV_HEIGHT } from '@utils/constants/navigation';
import { Gap } from '@utils/layout';
import { GlobalStylesProps } from '@utils/prop-types';
import { colors } from '@utils/tailwind';
import clsx from 'clsx';
import { useRef } from 'react';
import { Pressable, View } from 'react-native';
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context';
import { useActive, useHover } from 'react-native-web-hooks';
import { useGlobal } from '../hooks/useGlobal';
import { ApiAdminHeader } from './Header.ApiAdmin';
import { EcoCartAdminHeader } from './Header.EcoCartAdmin';
import { MerchantAdminHeader } from './Header.MerchantAdmin';
import { ScreenName } from './StackNavigators';

export interface StackConfig {
  name: ScreenName;
  component: JSX.Element | any;
  options?: ScreenOptions | any;
}

interface GeneratorProps {
  config: StackConfig[] | any[];
  screenOptions: ScreenOptions;
}

export type ScreenOptions =
  | StackNavigationOptions
  | ((props: { route: RouteProp<ParamListBase, string>; navigation: any }) => StackNavigationOptions);

// copied of CardStyleInterpolators.forFadeFromCenter
function forFadeFromCenter({ current }: { current: any; next: any; layouts: any }) {
  const { progress } = current;

  return {
    cardStyle: {
      opacity: progress.interpolate({
        inputRange: [0, 0.5, 0.9, 1],
        outputRange: [0, 0.25, 0.7, 1]
      })
    },
    overlayStyle: {
      opacity: progress.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 0],
        extrapolate: 'clamp'
      })
    }
  };
}

export const animatedScreenOptions: ScreenOptions = {
  animationEnabled: true,
  cardStyleInterpolator: forFadeFromCenter as any,
  transitionSpec: {
    open: TransitionSpecs.FadeInFromBottomAndroidSpec,
    close: TransitionSpecs.FadeOutToBottomAndroidSpec
  }
};

export const generateStackNavigator = (config: StackConfig[], screenOptions: ScreenOptions = globalScreenOptions): (() => JSX.Element) => {
  const createStack = createStackNavigator();
  const Navigator = createStack.Navigator;
  const Screen = createStack.Screen;
  const initalStackName = config?.[0]?.name;

  if (!initalStackName) {
    console.error('This stack is improperly configured, missing first stack name');
    return false as any;
  }

  return () => (
    <Navigator initialRouteName={initalStackName} screenOptions={{ ...screenOptions, ...animatedScreenOptions }}>
      {config.map((_screen, i) => (
        <Screen
          key={initalStackName + '_' + i}
          name={_screen.name}
          component={_screen.component}
          options={_screen.options as ScreenOptions}
        />
      ))}
    </Navigator>
  );
};

export const generateGroupedStackNavigator = (groupStackConfig: GeneratorProps[]): (() => JSX.Element) => {
  const createStack = createStackNavigator();
  const Navigator = createStack.Navigator;
  const initalStackName = groupStackConfig?.[0]?.config?.[0]?.name;

  if (!initalStackName) {
    console.error('This stack is improperly configured, missing first stack name');
    return false as any;
  }

  return () => (
    <Navigator initialRouteName={initalStackName} screenOptions={animatedScreenOptions}>
      {groupStackConfig.map(({ config, screenOptions }, i) => generateStackGroup(config, screenOptions, i)())}
    </Navigator>
  );
};

export const generateStackGroup = (
  config: StackConfig[],
  screenOptions: ScreenOptions = globalScreenOptions,
  i: number
): (() => JSX.Element) => {
  const createStack = createStackNavigator();
  const Screen = createStack.Screen;
  const Group = createStack.Group;
  const initalStackName = config?.[0]?.name;

  if (!initalStackName) {
    console.error('This stack is improperly configured, missing first stack name');
    return false as any;
  }

  return () => (
    <Group screenOptions={screenOptions} key={'group_' + i}>
      {config.map((_screen, i) => (
        <Screen
          key={initalStackName + '_' + i}
          name={_screen.name}
          component={_screen.component}
          options={_screen.options as ScreenOptions}
        />
      ))}
    </Group>
  );
};

export const generateBottomTabNavigator = (
  config: StackConfig[],
  screenOptions: BottomTabNavigationOptions = {
    tabBarShowLabel: false,
    headerShown: false
  }
): (() => JSX.Element) => {
  const createTab = createBottomTabNavigator();
  const Navigator = createTab.Navigator;
  const Screen = createTab.Screen;
  const initalStackName = 'Tabs';

  if (!initalStackName) {
    console.error('This stack is improperly configured, missing first stack name');
    return false as any;
  }

  return () => (
    <Navigator initialRouteName={initalStackName} screenOptions={screenOptions} tabBar={(props) => <CustomTabContent {...props} />}>
      {config.map((_screen, i) => (
        <Screen
          key={initalStackName + '_' + i}
          name={_screen.name}
          component={_screen.component}
          options={_screen.options as BottomTabNavigationOptions}
        />
      ))}
    </Navigator>
  );
};

export const generateDrawerNavigator = (config: StackConfig[], screenOptions: DrawerNavigationOptions = {}): (() => JSX.Element) => {
  const createDrawer = createDrawerNavigator();
  const Navigator = createDrawer.Navigator;
  const Screen = createDrawer.Screen;
  const initalStackName = 'Tabs';

  if (!initalStackName) {
    console.error('This stack is improperly configured, missing first stack name');
    return false as any;
  }

  return () => (
    <Navigator
      drawerContent={(props) => <CustomDrawerContent {...props} />}
      initialRouteName={initalStackName}
      screenOptions={screenOptions}
    >
      {config.map((_screen, i) => (
        <Screen
          key={initalStackName + '_' + i}
          name={_screen.name}
          component={_screen.component}
          options={_screen.options as DrawerNavigationOptions}
        />
      ))}
    </Navigator>
  );
};

/* ----------------------------- screen options ----------------------------- */
export const globalScreenOptions = {
  headerShown: false
};

export const cardStyle = { backgroundColor: colors.gray[50] };

export const NavIcon = (iconName: IconName) => {
  return ({ focused }: { focused: boolean }): JSX.Element => (
    <EcoIcon name={iconName} className={clsx('text-3xl', focused ? 'text-secondary-500' : 'text-black opacity-50')} />
  );
};

export const CustomHeader = (): JSX.Element => {
  const { session } = useGlobal();
  const getHeader = () => {
    switch (session?.user?.userType) {
      case 'api_admin':
        return <ApiAdminHeader />;
        break;
      case 'ecocart_admin':
        return <EcoCartAdminHeader />;
      case 'merchant_admin':
      default:
        return <MerchantAdminHeader />;
    }
  };

  return <SafeAreaView>{getHeader()}</SafeAreaView>;
};

interface NavButtonProps extends GlobalStylesProps {
  isActive: boolean;
  onPress: () => void;
  textClassName?: string;
  options: (DrawerNavigationOptions | BottomTabNavigationOptions) & { id?: string };
}

const NavButton = ({ onPress, options, isActive, style, textClassName }: NavButtonProps) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const isPressed = useActive(ref);
  const color = isHovered || isActive ? 'text-secondary-500' : 'text-black opacity-60';

  const getButtonColor = () => {
    if (isPressed) return 'bg-secondary-500/[.25] border-none';
    if (isHovered || isActive) return 'bg-secondary-500/[.1] border-none';
    return 'bg-white';
  };

  return (
    <Pressable
      ref={ref}
      onPress={onPress}
      className={clsx('justify-center items-center rounded-sm', getButtonColor())}
      style={[style, Gap(1)]}
      nativeID={options.id}
    >
      <View className="items-center" style={Gap(1)}>
        <EcoIcon className={`pointer-events-none ${color}`} name={(options as any).iconName} size="3xl" />
        <EcoText textAlign="center" className={textClassName + ' ' + color} fontSize="sm" fontWeight={isActive ? 'semibold' : 'regular'}>
          {options.title}
        </EcoText>
      </View>
    </Pressable>
  );
};

const CustomDrawerContent = ({ state, descriptors }: DrawerContentComponentProps) => {
  const activeRouteState = state.routes[state.index];
  const insets = useSafeAreaInsets();

  return (
    <>
      {/* divider */}
      <View className="border-t border-t-gray-100 mx-2" style={{ paddingTop: insets.top, marginTop: TOP_NAV_HEIGHT }}></View>
      <DrawerContentScrollView className="bg-white">
        {Object.values(descriptors).map(({ navigation, options, route }) => {
          const isActive = activeRouteState.name.toLowerCase() === route.name.toLowerCase();

          return (
            <NavButton
              className="mx-2 mt-2 p-3 py-1 sm:py-4 w-[75px] h-[75px]"
              options={options}
              isActive={isActive}
              key={route.name}
              onPress={() => navigation.navigate(route.name, route.params)}
            />
          );
        })}
      </DrawerContentScrollView>
    </>
  );
};

const CustomTabContent = ({ state, descriptors }: BottomTabBarProps) => {
  const activeRouteState = state.routes[state.index];

  return (
    <View className="px-2 pb-1 bg-white border-t border-t-gray-100 flex-row justify-between" style={Gap(1)}>
      {Object.values(descriptors).map(({ navigation, options, route }) => {
        const isActive = activeRouteState.name.toLowerCase() === route.name.toLowerCase();

        return (
          <NavButton
            className="mx-1 mt-1 p-2 flex-1"
            textClassName="px-2 flex-1"
            options={options}
            isActive={isActive}
            key={route.name}
            onPress={() => navigation.navigate(route.name, route.params)}
          />
        );
      })}
    </View>
  );
};
