import { fontSizeMap, textAlignMap } from '@components/shared/EcoText.utils';
import { GlobalStylesProps } from '@utils/prop-types';
import { FontColor, FontSize, FontWeight, TextAlign } from '@utils/tailwind';
import { nativeID } from '@utils/types/nativeID';
import { ReactNode } from 'react';
import { Text, TextProps } from 'react-native';
import { twMerge } from 'tailwind-merge';

type EcoTextProps = TextProps &
  GlobalStylesProps & {
    children: ReactNode;
    fallback?: ReactNode;
    color?: FontColor;
    fontSize?: FontSize;
    fontWeight?: FontWeight;
    textAlign?: TextAlign;
    underline?: boolean;
    nativeID?: nativeID;
    textClassNames?: string;
  };

export function EcoText({
  nativeID,
  children,
  fallback,
  color = 'enabled',
  fontSize = 'base',
  fontWeight = 'regular',
  textAlign = 'left',
  underline,
  style,
  textClassNames,
  onPress
}: EcoTextProps): JSX.Element {
  // be able to render the number 0 as it is falsy
  let _children = children;
  if (typeof _children === 'number' && _children === 0) _children = String(_children);

  return (
    <Text
      nativeID={nativeID}
      className={twMerge(
        `font-sora-${fontWeight}`,
        fontSizeMap[fontSize],
        textAlignMap[textAlign],
        underline && 'underline',
        `text-${color}`,
        textClassNames
      )}
      style={style}
      onPress={onPress}
      selectable
    >
      {_children || fallback}
    </Text>
  );
}
