import { Gap } from '@utils/layout';
import { GlobalStylesProps } from '@utils/prop-types';
import { LineHeight, lineHeight } from '@utils/tailwind';
import clsx from 'clsx';
import { View } from 'react-native';

interface Props extends GlobalStylesProps {
  noOfLines?: number;
  height?: LineHeight;
  spacing?: number;
  mode?: 'dark' | 'light';
}

export function EcoSkeleton({ mode = 'light', spacing = 3, noOfLines = 1, height = 'md' }: Props): JSX.Element {
  return (
    <View style={Gap(spacing)} className="w-full">
      {Array.from({ length: noOfLines }, (_, i) => (
        <View
          key={crypto.randomUUID()}
          className={clsx('rounded-xs animate-pulse', i === noOfLines - 1 || noOfLines === 1 ? 'w-1/2' : 'w-4/5')}
          // eslint-disable-next-line react-native/no-color-literals
          style={{ height: lineHeight[height], backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)' }}
        />
      ))}
    </View>
  );
}
