import { EcoText } from '@components/shared';
import { ReturnCustomerResponse } from '@ecocart/entities';
import { formatNumber } from '@ecocart/universal-utils';
import { Dataset } from '@utils/charts';
import { colors } from '@utils/tailwind';

export type Category = 'non-ecocart-repeats' | 'ecocart-repeats' | 'summary';
type TooltipContent = Record<Category, { title: JSX.Element; body: JSX.Element }>;

export const Title: Record<Category, string> = {
  'non-ecocart-repeats': 'Non-EcoCart Repeats',
  'ecocart-repeats': 'EcoCart Repeats',
  'summary': 'Repeat Customer Impact'
};

export const TooltipContent: TooltipContent = {
  'summary': {
    title: (
      <EcoText fontSize="lg" fontWeight="semibold" color="enabled-inverted">
        {Title.summary}
      </EcoText>
    ),
    body: (
      <EcoText fontSize="sm" color="enabled-inverted">
        This shows the % difference between repeat purchasers who have used EcoCart vs those who have not.
      </EcoText>
    )
  },
  'non-ecocart-repeats': {
    title: (
      <EcoText fontSize="lg" fontWeight="semibold" color="enabled-inverted">
        {Title['non-ecocart-repeats']}
      </EcoText>
    ),
    body: (
      <>
        <EcoText fontSize="sm" color="enabled-inverted">
          Non-EcoCart Repeats are defined as customers that have made multiple purchases* but have never offset their order
        </EcoText>
        <EcoText fontSize="sm" color="enabled-inverted">
          *Since EcoCart has been installed on your store.
        </EcoText>
      </>
    )
  },
  'ecocart-repeats': {
    title: (
      <EcoText fontSize="lg" fontWeight="semibold" color="enabled-inverted">
        {Title['ecocart-repeats']}
      </EcoText>
    ),
    body: (
      <EcoText fontSize="sm" color="enabled-inverted">
        EcoCart Repeats are defined as customers that have offset their order at least one time across all of their orders{' '}
      </EcoText>
    )
  }
};

export const generateChartJSData = (data?: ReturnCustomerResponse | null): { datasets: Dataset[] } => {
  const nonEcocartRepeatCustomers = !data ? 0 : data.non_ecocart_customers_repurchase_rate * data?.total_non_ecocart_customers;
  const ecocartRepeatCustomers = !data ? 0 : data.ecocart_customers_repurchase_rate * data.total_ecocart_customers;

  return {
    datasets: [
      {
        label: Title['non-ecocart-repeats'],
        data: !data ? [undefined, undefined] : [nonEcocartRepeatCustomers, data.total_non_ecocart_customers],
        backgroundColor: [colors.secondary[500], colors.tertiary[50]],
        key: 'non-ecocart-repeats' as Category
      },
      {
        label: Title['ecocart-repeats'],
        data: !data ? [undefined, undefined] : [ecocartRepeatCustomers, data.total_ecocart_customers],
        backgroundColor: [colors.algea[500], colors.tertiary[50]],
        key: 'ecocart-repeats' as Category
      }
    ]
  };
};

export const donutChartConfig = {
  type: 'donut',
  options: {
    circumference: 180,
    rotation: -90,
    responsive: true,
    plugins: {
      tooltip: {
        enabled: false
      },
      legend: {
        display: false
      }
    }
  }
};

export const parseDecimalToPercentage = (value: number): string => {
  return formatNumber(value, { style: 'percent', maximumFractionDigits: 1, minimumFractionDigits: 1 });
};

export const getDifference = (data?: ReturnCustomerResponse | null): number => {
  return !data
    ? 0
    : (data?.ecocart_customers_repurchase_rate - data?.non_ecocart_customers_repurchase_rate) / data?.non_ecocart_customers_repurchase_rate;
};

export const getSummaryText = (value: number): string => {
  const isPositive = value >= 0;
  return `Customers that offset their order at least once are repeat customers ${parseDecimalToPercentage(Math.abs(value))} ${
    isPositive ? 'more' : 'less'
  } often`;
};
