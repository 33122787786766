import { apiCall } from './api';

/* -------------------------------- merchant -------------------------------- */
export interface BackupDate {
  ChecksumAlgorithm: any[];
  ETag: string;
  Key: string;
  LastModified: string;
  Size: number;
  StorageClass: 'STANDARD';
}
export const getBackupDates = (entity: string): Promise<BackupDate[]> => {
  const queryParams = new URLSearchParams({ entity });
  return apiCall<BackupDate[]>('GET', `http://localhost:3000/backup/dates?${queryParams}`);
};

export const getBackup = <T>(key: string): Promise<T> => {
  const queryParams = new URLSearchParams({ key });
  return apiCall<T>('GET', `http://localhost:3000/backup?${queryParams}`);
};
