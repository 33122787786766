import { useOverlay } from '@hooks/useOverlay';
import { useWindow } from '@hooks/useWindow';
import { BoxShadow } from '@utils/styles/box-shadow';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { Pressable, View } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { EcoButton } from './EcoButton';
import { EcoDropdownProps } from './EcoDropdown.utils';
import { EcoText } from './EcoText';

function MenuItem(props: { label: string; disabled?: boolean; onPress: () => void }): JSX.Element {
  const { label, disabled, onPress } = props;

  const itemRef = useRef<View>(null);
  const isHovered = useHover(itemRef);

  return (
    <Pressable
      accessibilityState={{ disabled }}
      disabled={disabled}
      ref={itemRef}
      onPress={onPress}
      className={clsx('py-3 px-4', isHovered ? 'bg-gray-50' : 'bg-white')}
    >
      <EcoText color={disabled ? 'disabled' : 'enabled'}>{label}</EcoText>
    </Pressable>
  );
}

export function EcoDropdown(props: EcoDropdownProps): JSX.Element {
  const { children, items, showDownArrow = true, menuWrapperStyles, ...rest } = props;
  const { showMenu, hideMenu } = useOverlay();
  const dropdownRef = useRef<any>(null);
  const [layout, setLayout] = useState<any>();
  const { width } = useWindow();
  const animationFrameRef = useRef(0);

  useEffect(() => {
    const updateElementPosition = () => {
      if (dropdownRef.current) {
        const rect = dropdownRef.current.getBoundingClientRect();
        setLayout({
          top: rect.top,
          left: rect.left,
          width: rect.width,
          height: rect.height
        });
      }
      animationFrameRef.current = requestAnimationFrame(updateElementPosition);
    };

    animationFrameRef.current = requestAnimationFrame(updateElementPosition);

    return () => {
      cancelAnimationFrame(animationFrameRef.current);
    };
  }, []);

  return (
    <View ref={dropdownRef}>
      <EcoButton
        {...rest}
        rightIcon={showDownArrow ? 'expand_more' : undefined}
        onPress={() => {
          showMenu({
            content: (
              <View
                className="flex flex-col rounded-xs overflow-hidden mt-1 w-max"
                style={[BoxShadow(), { minWidth: layout.width }, menuWrapperStyles]}
              >
                {items.map((item) => (
                  <MenuItem
                    key={item.label}
                    label={item.label}
                    disabled={item.disabled}
                    onPress={() => {
                      item.onPress();
                      hideMenu();
                    }}
                  />
                ))}
              </View>
            ),
            config: {
              position: {
                top: layout.top + layout.height,
                right: width - layout.left - layout.width
              }
            }
          });
        }}
      >
        {children}
      </EcoButton>
    </View>
  );
}
