import { Locale, Widget } from '@ecocart/entities';
import { translations } from '@ecocart/universal-utils';

export const getInitialValues = (): Widget => {
  const widget = new Widget();
  widget.name = 'Education Touchpoint Widget';
  widget.template = 'education_touchpoint';
  return widget;
};

export const VARIANTS_LABELS = ['Standard', 'Black Logo', 'White'] as const;
export const VARIANTS_VALUES = ['standard', 'black_logo', 'white'] as const;
export type VariantLabel = (typeof VARIANTS_LABELS)[number];
export type VariantValue = (typeof VARIANTS_VALUES)[number];

export const getBodyPlaceholder = (locale: Locale = 'en'): string => {
  return translations[locale].template_byCheckingOutWithEcocartYourOrderWillHelp;
};
