import { EcoCard, EcoLoader, EcoText } from '@components/shared';
import { useQuery } from '@tanstack/react-query';
import { getShopifyScopes } from '@utils/api/shopify';
import { colors } from '@utils/tailwind';

interface Props {
  shopName: string;
}

const FULFILLMENT_SCOPES = [
  'read_merchant_managed_fulfillment_orders',
  // 'write_merchant_managed_fulfillment_orders', ** DROPPED May 25, 2023
  'read_assigned_fulfillment_orders',
  // 'write_assigned_fulfillment_orders', ** DROPPED May 25, 2023
  'read_third_party_fulfillment_orders',
  // 'write_third_party_fulfillment_orders', ** DROPPED May 25, 2023
  'read_fulfillments',
  'write_fulfillments'
];

export default function ShopifyScopesCard({ shopName }: Props): JSX.Element {
  const { data: scopes, isLoading: scopesAreLoading } = useQuery(['scopes', shopName], () => getShopifyScopes(shopName));

  return (
    <EcoCard
      title={`Shopify Scopes ${FULFILLMENT_SCOPES.every((scope) => (scopes || []).includes(scope)) ? '(available)' : '(unavailable)'}`}
    >
      {scopesAreLoading ? (
        <EcoLoader size="large" className="py-9" color={colors.primary[400]} />
      ) : (
        <EcoText fontSize="base" color="subdued">
          {(scopes || []).join(', ')}
        </EcoText>
      )}
    </EcoCard>
  );
}
