import { EcoCard, EcoLoader } from '@components/shared';
import EcoEmptyState from '@components/shared/EcoEmptyState';
import { Template } from '@ecocart/entities';
import { mergeWidgetMapData } from '@ecocart/fe-utils';
import { orderBy } from '@ecocart/universal-utils';
import { useRoute } from '@react-navigation/native';
import { MerchantContext } from '@screens/MerchantWidgetsScreen';
import { Gap } from '@utils/layout';
import { useContext } from 'react';
import { View } from 'react-native';
import { WidgetDemo } from './WidgetDemo';

export function WidgetList({
  template,
  editorComponent
}: {
  template: Template | Template[];
  editorComponent?: React.ComponentType<{ widgetKey: string }>;
}): JSX.Element {
  const { merchant } = useContext(MerchantContext);
  const route = useRoute();
  const { widgetKey } = route.params as { widgetKey?: string };

  if (!merchant) {
    return <EcoLoader size="large" />;
  }

  if (editorComponent) {
    const EditorComponent = editorComponent;

    if (widgetKey) {
      return <EditorComponent widgetKey={widgetKey} />;
    }
  }

  const widgets = mergeWidgetMapData(merchant?.widgetMap || {}, merchant.widgetPlacements || []);

  const filteredWidgets = widgets.filter((widget) =>
    Array.isArray(template) ? template.includes(widget.template) : template === widget.template
  );
  const sortedWidgets = orderBy(filteredWidgets, 'name', 'asc');

  return (
    <View style={Gap(5)} key={`widgetWrapper-${template}`}>
      {sortedWidgets.length > 0 ? (
        sortedWidgets.map((widget, idx) => (
          <EcoCard key={`widgetCard-${idx}`}>
            <View className="flex-row justify-between">
              <WidgetDemo key={`widgetDemo-${idx}`} merchant={merchant} widget={widget} />
            </View>
          </EcoCard>
        ))
      ) : (
        <EcoCard>
          <View className="flex-row items-center justify-center py-9">
            <EcoEmptyState title="Not found" subTitle="Please create new ones from the wizard" />
          </View>
        </EcoCard>
      )}
    </View>
  );
}
