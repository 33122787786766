import { OverlayComponentProps } from '@context/overlay';
import React, { useEffect, useState } from 'react';
import { Pressable, StyleProp, TouchableWithoutFeedback } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withSpring, withTiming } from 'react-native-reanimated';

interface Props extends OverlayComponentProps {}

const FADE_DURATION = 200;
const SLIDE_OFFSET = 20;

const FULL_SCREEN: StyleProp<any> = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1000
};

export function EcoMenu({ hide, children, config }: Props): JSX.Element {
  const TOP_POSITION = config?.position?.top || 0;
  const RIGHT_POSITION = config?.position?.right || 0;
  const LEFT_POSITION = config?.position?.left || 0;

  const [asyncChildren, setAsyncChildren] = useState<React.ReactNode>();

  const translateY = useSharedValue(0 - SLIDE_OFFSET);
  const opacity = useSharedValue(0);
  const top = useSharedValue(TOP_POSITION);
  const right = useSharedValue(RIGHT_POSITION);
  const left = useSharedValue(LEFT_POSITION);

  useEffect(() => {
    if (config?.position?.top !== undefined) top.value = config.position.top;
    if (config?.position?.right !== undefined) right.value = config.position.right;
    if (config?.position?.left !== undefined) left.value = config.position.left;
  }, [config?.position]);

  useEffect(() => {
    if (children) {
      fadeIn();
    } else {
      fadeOut();
    }
  }, [children]);

  const fadeIn = () => {
    setAsyncChildren(children);

    translateY.value = withSpring(0, { damping: 100 });
    opacity.value = withTiming(1, { duration: FADE_DURATION });
  };

  const fadeOut = () => {
    translateY.value = withSpring(0 - SLIDE_OFFSET);
    opacity.value = withTiming(0, { duration: FADE_DURATION });

    setTimeout(() => {
      setAsyncChildren(children);
    }, FADE_DURATION);
  };

  const backdropStyle = useAnimatedStyle(() => ({
    opacity: opacity.value
  }));

  const contentStyle = useAnimatedStyle(() => ({
    opacity: opacity.value,
    transform: [{ translateY: translateY.value }],
    top: top.value,
    ...(LEFT_POSITION ? { left: left.value } : { right: right.value })
  }));

  return (
    <Animated.View style={[{ display: asyncChildren ? 'flex' : 'none' }, FULL_SCREEN, backdropStyle]}>
      <Pressable onPress={hide} style={FULL_SCREEN}>
        <TouchableWithoutFeedback>
          <Animated.View style={[{ position: 'absolute', zIndex: 100001 }, contentStyle]}>{asyncChildren}</Animated.View>
        </TouchableWithoutFeedback>
      </Pressable>
    </Animated.View>
  );
}
