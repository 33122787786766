import { EcoAlert, EcoMenu, EcoModal, EcoToast } from '@components/shared';
import { HttpError } from '@utils/api/api';
import { BoxShadow } from '@utils/styles/box-shadow';
import { nativeID } from '@utils/types/nativeID';
import React, { ReactNode, createContext, useCallback, useState } from 'react';
import { RootNavigation } from '../navigation/RootNavigation';

export interface OverlayContextProps {
  showModal: React.Dispatch<React.SetStateAction<OverlayAction | undefined>>;
  hideModal: () => void;
  showMenu: React.Dispatch<React.SetStateAction<OverlayAction | undefined>>;
  hideMenu: () => void;
  showToast: React.Dispatch<React.SetStateAction<OverlayAction | undefined>>;
  hideToast: () => void;
  showPopover: (id: PopoverID) => void;
  hidePopover: (hideId: PopoverID) => void;
  activePopovers: PopoverID[];
  showSuccess: (message: string, config?: any) => void;
  showWarning: (message: string, config?: any) => void;
  showInfo: (message: string, config?: any) => void;
  handleError: (message: string) => void;
}

export const OverlayContext = createContext(null as any);

type PopoverID = 'billing_added';

type ToastType = 'success' | 'warning' | 'danger' | 'default';

export interface ModalConfig {
  disableSwipeClose?: boolean; // disable swipe
  topPx?: number;
  rightPx?: number;
  leftPx?: number;
}

interface OverlayAction {
  content: ReactNode | undefined;
  type?: ToastType;
  config?: any;
  nativeID?: nativeID;
}

export interface OverlayComponentProps {
  hide: () => void;
  config?: any;
  children: React.ReactNode;
  type?: ToastType;
  nativeID?: nativeID;
}

export const OverlayProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [modal, showModal] = useState<OverlayAction>();
  const hideModal = useCallback(() => showModal(undefined), []);
  const [toast, showToast] = useState<OverlayAction>();
  const hideToast = useCallback(() => showToast(undefined), []);
  const [menu, showMenu] = useState<OverlayAction>();
  const hideMenu = useCallback(() => showMenu(undefined), []);
  const [activePopovers, setActivePopovers] = useState<PopoverID[]>([]);
  const showPopover = useCallback((addId: PopoverID) => {
    setActivePopovers([...activePopovers, addId]);
  }, []);
  const hidePopover = useCallback((removeId: PopoverID) => {
    setActivePopovers(activePopovers.filter((id) => id !== removeId));
  }, []);

  const showSuccess = (message: string, config?: any) => {
    return showToast({
      type: 'success',
      content: (
        <EcoAlert
          contentAlignment="center"
          title={message}
          variant="success"
          closeable={false}
          shouldTruncate={config?.shouldTruncate || false}
          style={[BoxShadow(), { border: 'none' }]}
        />
      ),
      config
    });
  };

  const showWarning = (message: string, config?: any) => {
    return showToast({
      type: 'warning',
      content: (
        <EcoAlert
          contentAlignment="center"
          title={message}
          variant="warning"
          closeable={false}
          style={[BoxShadow(), { border: 'none' }]}
          shouldTruncate={config?.shouldTruncate || false}
        />
      ),
      config
    });
  };

  const showInfo = (message: string, config?: any) => {
    return showToast({
      type: 'default',
      content: (
        <EcoAlert
          contentAlignment="center"
          title={message}
          variant="info"
          closeable={false}
          style={[BoxShadow(), { border: 'none' }]}
          shouldTruncate={config?.shouldTruncate || false}
        />
      ),
      config
    });
  };

  const handleError = (error: string | HttpError) => {
    let message = 'Request failed, try again?';
    let statusCode = 400;

    if (error instanceof HttpError) {
      message = error.message;
      statusCode = (error as HttpError).statusCode;

      if (statusCode === 401) {
        RootNavigation.navigate('Login');
      }
    } else {
      message = (error as any)?.message || error;
    }

    return showToast({
      type: 'danger',
      content: (
        <EcoAlert
          contentAlignment="center"
          title={message}
          variant="danger"
          closeable={false}
          shouldTruncate={false}
          style={{ border: 'none' }}
        />
      )
    });
  };

  const value: OverlayContextProps = {
    showModal,
    hideModal,
    showToast,
    hideToast,
    showMenu,
    hideMenu,
    showPopover,
    hidePopover,
    activePopovers,
    showSuccess,
    showWarning,
    showInfo,
    handleError
  };

  return (
    <OverlayContext.Provider value={value}>
      <EcoModal nativeID={modal?.nativeID} hide={hideModal} config={modal?.config}>
        {modal?.content}
      </EcoModal>
      <EcoToast hide={hideToast} config={toast?.config} type={toast?.type}>
        {toast?.content}
      </EcoToast>
      <EcoMenu hide={hideMenu} config={menu?.config}>
        {menu?.content}
      </EcoMenu>
      {children}
    </OverlayContext.Provider>
  );
};
