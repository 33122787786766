import { FINANCIAL_PROPS, ManufacturingCalculationType, MerchantAdmin } from '@ecocart/entities';
import { BIN_PACK_API, apiCall } from './api';

export const shouldTriggerBinPackUpdate = (merchantAdmin: Partial<MerchantAdmin>, dbMerchantAdmin: Partial<MerchantAdmin>): boolean => {
  return FINANCIAL_PROPS.some((prop) => {
    return (!!merchantAdmin[prop] || merchantAdmin[prop] === false) && dbMerchantAdmin[prop] !== merchantAdmin[prop];
  });
};

export const triggerBinPackUpdate = (shopName: string, manufacturingCalculationType: ManufacturingCalculationType): Promise<void> => {
  if (!shopName) return new Promise((resolve) => resolve(undefined as any));

  return apiCall(
    'POST',
    `${BIN_PACK_API}/toggle_merchant`,
    { merchant: shopName, onboarding: true, manufacturing_calculation_type: manufacturingCalculationType },
    { 'X-Eco-JWT': '', 'X-Eco-Shopify-Access-Token': '' }
  );
};
