import { RewardsChart } from '@components/rewards/RewardsChart';
import { RewardsFAQButton } from '@components/rewards/RewardsFAQButton';
import { RewardsOverview } from '@components/rewards/RewardsOverview';
import { RewardsRedemption } from '@components/rewards/RewardsRedemption';
import { RewardsScreenSubtitle } from '@components/rewards/RewardsScreenSubtitle';
import { EcoButton, EcoTab, EcoTabContent, EcoTabs } from '@components/shared';
import { EcoPageTitle } from '@components/shared/EcoPageTitle';
import EcoScrollView from '@components/views/EcoScrollView';
import { BackLink } from '@navigation/BackLink';
import { GlobalScreenProps } from '@navigation/types';
import { useNavigation } from '@react-navigation/native';
import { useQuery } from '@tanstack/react-query';
import { getMerchantAdmin } from '@utils/api/merchant';
import { EcoCartApiMerchantTabs, EcoCartGlobalMerchantTabs } from '@utils/constants/tabs';
import React from 'react';

import { View } from 'react-native';

export default function MerchantRewardsScreen({ route }: GlobalScreenProps): JSX.Element {
  const shopName = route?.params?.shopName;
  const navigation = useNavigation();
  const { data: merchantAdmin } = useQuery(['merchantAdmin', shopName], () => getMerchantAdmin(shopName));
  const EcoCartMerchantTabs = merchantAdmin?.platform === 'api' ? EcoCartApiMerchantTabs : EcoCartGlobalMerchantTabs;

  const openHistoryPage = () => {
    navigation.navigate('MerchantRewardsHistory', { shopName });
  };

  return (
    <EcoScrollView>
      <View className="flex-row">
        <BackLink href="/merchants" label="Back to Merchants" />
      </View>
      <EcoTabs
        tabs={EcoCartMerchantTabs.map(({ label, screenName }) => {
          return {
            label,
            screenName,
            screenParams: { shopName }
          } as EcoTab;
        })}
        prefix="Merchant"
        activeScreen="MerchantRewards"
      >
        <EcoTabContent>
          <View className="flex-row items-start justify-between">
            <EcoPageTitle className="mb-5" title="Rewards" subTitle={<RewardsScreenSubtitle />} />
            <View className="flex-row gap-2">
              <EcoButton onPress={openHistoryPage} size="sm" variant="outlined">
                View History
              </EcoButton>
              <RewardsFAQButton />
            </View>
          </View>
          <RewardsOverview shopName={shopName} />
          <RewardsChart shopName={shopName} />
          <RewardsRedemption />
        </EcoTabContent>
      </EcoTabs>
    </EcoScrollView>
  );
}
