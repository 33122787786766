import { EcoSkeleton, FormPicker } from '@components/shared';
import { Gap } from '@utils/layout';
import { LINE_HEIGHTS, LineHeight, SPACING_SIZES, SpacingSize } from '@utils/tailwind';
import { useState } from 'react';
import { View } from 'react-native';
import { ConfigContainer } from '../views/ConfigContainer';
import { DemoContainer } from '../views/DemoContainer';
import { InternalToolsCard } from '../views/InternalToolsCard';

export function DSSkeleton(): JSX.Element {
  const [noOfLines, setNoOfLines] = useState<number>(2);
  const [spacing, setSpacing] = useState<SpacingSize>(2);
  const [height, setHeight] = useState<LineHeight>('md');
  const [mode, setMode] = useState<'light' | 'dark'>('light');

  return (
    <InternalToolsCard componentName="EcoSkeleton">
      <DemoContainer>
        {/* Actual demo component */}
        <EcoSkeleton noOfLines={Number(noOfLines)} spacing={Number(spacing)} height={height} mode={mode} />
      </DemoContainer>

      <ConfigContainer>
        {/* Configuration component */}
        <View className="flex-row" style={Gap()}>
          <FormPicker label="Height" items={[...LINE_HEIGHTS]} value={height} onValueChange={setHeight} />
          <FormPicker
            label="No of Lines"
            items={Array.from({ length: 10 }, (_, i) => i + 1)}
            value={noOfLines}
            onValueChange={setNoOfLines}
          />
          <FormPicker label="Spacing" items={[...SPACING_SIZES]} value={spacing} onValueChange={setSpacing} />
          <FormPicker label="Mode" items={['dark', 'light']} value={mode} onValueChange={setMode} />
        </View>
        {/* End of Configuration component */}
      </ConfigContainer>
    </InternalToolsCard>
  );
}
