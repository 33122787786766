import { FontSize } from '@utils/tailwind';
import clsx from 'clsx';
import { View } from 'react-native';
import { EcoText } from './EcoText';

type Props = {
  title?: string;
  subTitle?: string;
  titleSize?: FontSize;
  topRightComponent?: JSX.Element;
  titleClassName?: string;
  subTitleClassName?: string;
};

export function EcoHeadings({
  title,
  titleSize = '4xl',
  titleClassName,
  subTitle,
  subTitleClassName,
  topRightComponent
}: Props): JSX.Element {
  return (
    <>
      <View className="flex flex-row w-full items-start justify-between">
        {title ? (
          <EcoText fontSize={titleSize} fontWeight="semibold" className={titleClassName}>
            {title}
          </EcoText>
        ) : null}
        {topRightComponent ? topRightComponent : null}
      </View>
      {subTitle ? (
        <EcoText fontSize="lg" className={clsx('max-w-[620px] text-subdued', subTitleClassName)}>
          {subTitle}
        </EcoText>
      ) : null}
    </>
  );
}
