import { MerchantAdmin, Permission, User, UserType } from '@ecocart/entities';
import { RoutePath } from '@navigation/LinkingConfiguration';
import { getAvailableAuth, throwHttpError } from '@utils/api/api';
import { AddlLoginData, LoginArgs, LoginResult, signIn } from '@utils/api/auth';
import { getMerchantAdmin } from '@utils/api/merchant';
import { getPaymentMethodsOrCreateCustomer, hasAtLeastOnePaymentMethod, hasAtLeastOneValidPaymentMethod } from '@utils/api/payments';
import { getSelf } from '@utils/api/user';
import { isWeb } from '@utils/constants/device';
import { StorageKey, storage } from '@utils/storage';
import { ReactNode, createContext, useEffect, useState } from 'react';
import { logUserEvt } from '../utils/log';

export interface Session {
  user: User;
  merchantAdmin?: MerchantAdmin;
}

export class Statuses {
  isBillingEnabled: boolean | null = null;
  hasAValidPaymentMethod: boolean | null = null;
  areVariantsCreated: boolean | null = null;
  isOnboardingComplete: boolean | null = null;

  constructor() {}
}

export interface Entitlements {
  permissions: Permission[];
  userType: UserType;
}

export interface GlobalContextProps {
  statuses: Statuses; // used for broad app-level settings (non-user)
  setStatuses: React.Dispatch<React.SetStateAction<Statuses>>;
  entitlements: Entitlements | null; // used for app layout / navigation
  session: Session | undefined | null;
  updateSessionUser: (updatedUserProps: Partial<User>) => void;
  updateSessionMerchantAdmin: (updatedMerchantAdminProps: Partial<MerchantAdmin>) => void;
  login: ({ email, password }: LoginArgs) => Promise<{ cognitoJWT: string; result: LoginResult; addlData?: AddlLoginData }>;
  logout: () => void;
  initUser: (user: User) => void;
}

export const GlobalContext = createContext(null as any);

const GlobalContextProvider = (props: { children: ReactNode }): JSX.Element => {
  const [session, setSession] = useState<Session | undefined | null>(null);
  const [statuses, setStatuses] = useState<Statuses>(new Statuses());
  const [entitlements, setEntitlements] = useState<Entitlements | null>(null);

  const login = async ({ email, password }: LoginArgs): Promise<{ cognitoJWT: string; result: LoginResult }> => {
    try {
      const res = await signIn({ email, password });

      const redirectUrl = await storage.getItem(StorageKey.login_redirect);

      if (redirectUrl) {
        await storage.removeItem(StorageKey.login_redirect);
        location.href = redirectUrl;
      } else if (res?.cognitoJWT) {
        const _user = await getSelf();
        initUser(_user);
      }

      return res;
    } catch (error: any) {
      throwHttpError(error.result);
      return error;
    }
  };

  const logout = async () => {
    await Promise.all([
      storage.removeItem(StorageKey.cognito_jwt),
      storage.removeItem(StorageKey.cognito_access_token),
      storage.removeItem(StorageKey.cognito_expire),
      storage.removeItem(StorageKey.cognito_refresh),
      storage.removeItem(StorageKey.shpat)
    ]);
    initSession(undefined);
  };

  const updateSessionUser = (updatedUserProps: Partial<User>): void => {
    if (!updatedUserProps) return;

    setSession({ ...session, user: { ...(session?.user || {}), ...updatedUserProps } } as Session);
  };

  const updateSessionMerchantAdmin = (updatedMerchantAdminProps: Partial<MerchantAdmin>): void => {
    if (!updatedMerchantAdminProps) return;

    const merchantAdmin = { ...(session?.merchantAdmin || {}), ...updatedMerchantAdminProps };

    setSession({ ...session, merchantAdmin } as Session);

    // store for redirect-back
    storage.setItem(StorageKey.last_active_shop_name, merchantAdmin.shopName);
  };

  const initUser = async (user: User): Promise<void> => {
    if (user) {
      if (['merchant_admin', 'api_admin'].includes(user.userType)) {
        const lastActiveShopName = (await storage.getItem(StorageKey.last_active_shop_name)) as string;
        const shopName = user?.merchants?.[lastActiveShopName] ? lastActiveShopName : Object.keys(user?.merchants || {})?.[0];
        const merchantAdmin = await getMerchantAdmin(shopName);
        // ensure correct userType for api_admin
        if (merchantAdmin.platform === 'api') user.userType = 'api_admin';
        initSession({ user, merchantAdmin });
      } else {
        initSession({ user });
      }
    }
  };

  const initSession = async (_session: Session | null | undefined): Promise<void> => {
    window.session = _session;

    if (Array.isArray(window.dataLayer)) {
      const email = _session?.user?.id || 'n/a';
      const shop_name = _session?.merchantAdmin?.shopName || 'n/a';

      window.dataLayer.push({
        email,
        shop_name
      });

      // 'login' event is to track when the login is successful.
      // We don't track logout or invalid session
      if (_session) {
        logUserEvt('login');
      }
    }

    if (_session) {
      setEntitlements({ userType: _session.user.userType, permissions: _session.user.permissions });
    }

    if (_session?.merchantAdmin) {
      const paymentMethods = await getPaymentMethodsOrCreateCustomer(_session);

      setStatuses({
        areVariantsCreated: !!_session.merchantAdmin?.variantIds?.[0],
        isOnboardingComplete: _session.merchantAdmin.isOnboardingComplete,
        isBillingEnabled: hasAtLeastOnePaymentMethod(paymentMethods),
        hasAValidPaymentMethod: hasAtLeastOneValidPaymentMethod(paymentMethods)
      });
    }

    setSession(_session);
  };

  useEffect(() => {
    (async () => {
      if (isWeb) {
        const searchParams = new URLSearchParams(location.search);
        const shpat = searchParams.has('shpat') ? searchParams.get('shpat') : null;
        const clearSessionRoutes: RoutePath[] = [
          '/shopify',
          '/new-member',
          '/welcome',
          '/forgot-password',
          '/reset-password',
          '/unsubscribe',
          '/claims'
        ];

        // apply shpat (aka user.type === 'SHOPIFY_USER') auto-login on home page only
        if (shpat && location.pathname === '/home') {
          await storage.setItem(StorageKey.shpat, shpat);
          // remove shpat query param from visible url + history
          window.history.pushState(null, '', window.location.pathname);
        } else if ((clearSessionRoutes as string[]).includes(location.pathname)) {
          await logout();
        }
      }

      try {
        const auth = await getAvailableAuth();

        if (auth) {
          const _user = await getSelf();
          initUser(_user);
        } else {
          logout();
        }
      } catch (error: any) {
        if ((error?.message || '').toLowerCase().includes('unauthorized')) {
          storage.setItem(StorageKey.login_redirect, location.href);
          logout();
        }
      }
    })();
  }, []);

  const value: GlobalContextProps = {
    login,
    logout,
    session,
    entitlements,
    statuses,
    setStatuses,
    initUser,
    updateSessionUser,
    updateSessionMerchantAdmin
  };

  return <GlobalContext.Provider value={value}>{props.children}</GlobalContext.Provider>;
};

export default GlobalContextProvider;
