import { EcoChart, EcoChartLegend, EcoText } from '@components/shared';
import { DatePickerValue, MerchantAdmin } from '@ecocart/entities';
import { useGlobal } from '@hooks/useGlobal';
import { useQuery } from '@tanstack/react-query';
import { getOrders } from '@utils/api/reporting';
import { convertEcoDataToChartJSData, filterDataByUserType, getChartOptions, removeTotalOrdersFromData, tooltipLine } from '@utils/charts';
import { getDummyEmptyData } from '@utils/constants/chart';
import { colors } from '@utils/tailwind';
import { View } from 'react-native';
import { useGetDateRange } from './DateRangePicker.utils';

export function PerformanceChart({ merchantAdmin, dateRange }: { merchantAdmin?: MerchantAdmin; dateRange: DatePickerValue }): JSX.Element {
  const { session } = useGlobal();
  const shopName = merchantAdmin?.shopName || '';
  const { startDate } = useGetDateRange(dateRange);
  const { data, isFetching } = useQuery({
    queryKey: ['getOrders', shopName, dateRange],
    queryFn: () => getOrders(shopName, dateRange),
    keepPreviousData: true
  });

  const userType = session?.user.userType;
  const locale = merchantAdmin?.locale;
  const hasNoData = data && 'message' in data;
  const _data = hasNoData && startDate ? getDummyEmptyData(startDate) : data;

  // removing total orders from data until backend is ready
  const filteredData = filterDataByUserType(removeTotalOrdersFromData(_data), { userType, locale });
  const chartJSData = convertEcoDataToChartJSData(filteredData);

  chartJSData.datasets = chartJSData.datasets.map((dataset, index) => ({
    borderColor: index % 2 === 0 ? colors.algea[500] : colors.secondary[500],
    backgroundColor: index % 2 === 0 ? colors.algea[500] : colors.secondary[500],
    tension: 0.5,
    ...dataset
  }));

  return (
    <View className="p-5 bg-white rounded-md flex-1 w-full">
      <View className="flex flex-col">
        <View className="flex-row justify-between flex-wrap items-center">
          <EcoText fontSize="lg" fontWeight="medium">
            EcoCart Performance
          </EcoText>
          <EcoChartLegend datasets={chartJSData.datasets} />
        </View>
      </View>
      <View className="pt-3 flex-1">
        <EcoChart
          isLoading={isFetching}
          type="line"
          options={getChartOptions(dateRange, { userType, locale })}
          plugins={[tooltipLine]}
          data={chartJSData}
        />
      </View>
    </View>
  );
}
