import { colors } from '@utils/tailwind';
import { nativeID } from '@utils/types/nativeID';
import { View } from 'react-native';
import Toggle from 'react-native-toggle-element';
export interface EcoToggleProps {
  value: boolean;
  disabled?: boolean;
  onValueChange: (value: boolean) => void;
  nativeID?: nativeID;
}

const thumbSize = 24;

export function EcoToggle({ value, nativeID, disabled = false, onValueChange }: EcoToggleProps): JSX.Element {
  return (
    <View nativeID={`${nativeID}--${value ? 'on' : 'off'}`}>
      <Toggle
        value={value}
        disabled={disabled}
        trackBarStyle={{
          borderColor: disabled ? colors.gray[500] : colors.secondary[500],
          borderWidth: 2,
          backgroundColor: value ? colors.secondary[500] : colors.white,
          padding: 1
        }}
        thumbStyle={{ backgroundColor: disabled ? colors.gray[500] : value ? colors.white : colors.secondary[500] }}
        trackBar={{ height: thumbSize, width: 50, borderWidth: 3 }}
        disabledStyle={{ opacity: 0.5 }}
        thumbButton={{ height: thumbSize, width: thumbSize }}
        onPress={(isOn) => onValueChange(isOn as boolean)}
      />
    </View>
  );
}
