import { createContext, ReactNode } from 'react';
import { EcoPickerProps } from './EcoPicker';
import { TooltipProps } from './EcoTooltip';

export interface FormFieldsConfig<T = Record<string, any>> {
  field: Partial<keyof T>;
  type?: 'input' | 'toggle' | 'checkbox' | 'picker' | 'picker-other'; // defaults to 'input'
  label?: string;
  disabled?: boolean;
  items?: EcoPickerProps['items'];
  helperText?: string;
  placeholder?: string;
  tooltipConfig?: Omit<TooltipProps, 'children'>;
}

interface Props {
  children: ReactNode;
  onEnter: () => void;
}

interface Context {
  onEnter: () => void;
}

export const FormContext = createContext<Context | null>(null);

export function EcoForm({ children, onEnter }: Props): JSX.Element {
  const value = { onEnter };
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
}
