import { EcoImage, EcoText } from '@components/shared';
import { GLOBAL_PUBLIC_CONFIG } from '@ecocart/universal-utils';
import { useNavigation } from '@react-navigation/native';
import { SHOPIFY_NODE_API, throwHttpError } from '@utils/api/api';
import { getMerchant } from '@utils/api/merchant';
import { getShopifySession } from '@utils/api/shopify';
import { PUBLIC_IMG_URL } from '@utils/constants/config';
import { Gap } from '@utils/layout';
import { logUserEvt } from '@utils/log';
import { LoginScreenView } from '@utils/styles/screen-views';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { GlobalScreenProps } from '../navigation/types';

export default function ShopifyScreen({ route }: GlobalScreenProps): JSX.Element {
  const navigation = useNavigation();
  const shopName = route?.params?.shop;
  const session = route?.params?.session || undefined;
  const hmac = route?.params?.hmac || undefined;
  const timestamp = route?.params?.timestamp || undefined;
  const host = route?.params?.host || undefined;

  useEffect(() => {
    if (!shopName) return;

    logUserEvt('initiate_shopify_login');

    const shopifyOAuthRoute = `${SHOPIFY_NODE_API}/shop/authorize?shop=${encodeURIComponent(shopName)}&ecoPubKey=${
      GLOBAL_PUBLIC_CONFIG.X_ECO_PUB_KEY
    }`;

    // const { shop, code, hmac, timestamp, host, locale, session, embedded } = route.params || {};

    // -- ONBOARDING --
    // https://app.ecocart.io/shopify?
    // hmac=5c486bfce77b38cf03f9c99ce99a8f52f0573c0d642a96f03bf93d31eee0ba09&
    // host=ZWNvY2FydC1tZXJjaC5teXNob3BpZnkuY29tL2FkbWlu&
    // shop=ecocart-merch.myshopify.com&
    // timestamp=1670882727

    // -- LOGIN --
    // https://app.ecocart.io/shopify?
    // hmac=558e1f6b52ac861b43369ea9033ed4cd70f2100746c126f11af2e386eb44ad89&
    // host=ZWNvY2FydC1tZXJjaC5teXNob3BpZnkuY29tL2FkbWlu&
    // session=f63bb8b17eaab953cb8e81f7e58a88d50092d885f1d091f42776400601f2e3a1&
    // shop=ecocart-merch.myshopify.com&
    // timestamp=1670882599

    getMerchant(shopName)
      .then(() => {
        getShopifySession({ shopName, session, hmac, timestamp, host })
          .then(({ accessToken, requiresReauthentication }) => {
            if (requiresReauthentication) {
              location.href = shopifyOAuthRoute;
            } else if (accessToken) {
              location.href = `/home?shpat=${accessToken}`;
            } else {
              navigation.navigate('Login');
            }
          })
          .catch((error) => throwHttpError(error));
      })
      .catch((error) => {
        location.href = shopifyOAuthRoute;
        console.error(error);
      });
    return () => {};
  }, [shopName]);

  return (
    <SafeAreaView style={LoginScreenView} className="justify-center flex flex-row items-center w-full h-full">
      <View className="items-center justify-center" style={Gap()}>
        <EcoImage width={400} height={100} src={`${PUBLIC_IMG_URL}/airplane.svg`} />
        <View style={Gap(2)}>
          <EcoText textAlign="center" fontSize="6xl" fontWeight="semibold">
            Fetching data...
          </EcoText>
          <EcoText textAlign="center" fontSize="base">
            Please wait while we analyze your store information
          </EcoText>
        </View>
      </View>
    </SafeAreaView>
  );
}
