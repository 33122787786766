import { Gap } from '@utils/layout';
import { nativeID } from '@utils/types/nativeID';
import clsx from 'clsx';
import React, { ReactNode, useCallback, useRef } from 'react';
import { AccessibilityRole, Pressable, StyleProp } from 'react-native';
import { useActive, useHover } from 'react-native-web-hooks';
import {
  ButtonIconsOnlySizeStylesMap,
  ButtonSizeStylesMap,
  ButtonTextSizeMap,
  iconDimensionMap,
  iconLeftSpacingMap,
  iconRightSpacingMap,
  useButtonStyles
} from './EcoButton.utils';
import { EcoIcon } from './EcoIcon';
import { IconName } from './EcoIcon.utils';
import { EcoLoader } from './EcoLoader';
import { EcoText } from './EcoText';

export const BUTTON_SIZES = ['sm', 'md', 'lg'] as const;
export type ButtonSize = (typeof BUTTON_SIZES)[number];

const BUTTON_VARIANTS = ['filled', 'outlined', 'ghost'] as const;
type ButtonVariant = (typeof BUTTON_VARIANTS)[number];

const BUTTON_COLOR_SCHEME = ['default', 'success', 'warning', 'danger'] as const;
type ButtonColorScheme = (typeof BUTTON_COLOR_SCHEME)[number];

export interface EcoButtonProps {
  size?: ButtonSize;
  className?: string;
  children?: ReactNode;
  leftIcon?: IconName;
  rightIcon?: IconName;
  /** The left icon will stick to the left if true, and content will be centered */
  stickyLeftIcon?: boolean;
  /** The right icon will stick to the left if true, and content will be centered */
  stickyRightIcon?: boolean;
  onPress: () => void;
  variant?: ButtonVariant;
  colorScheme?: ButtonColorScheme;
  style?: StyleProp<any>;
  isDisabled?: boolean;
  isLoading?: boolean;
  showLoadingOnPress?: boolean;
  fullWidth?: boolean;
  accessibilityRole?: AccessibilityRole;
  nativeID?: nativeID;
  iconClassNames?: string;
}

export function EcoButton({
  onPress,
  children,
  leftIcon,
  rightIcon,
  style,
  stickyLeftIcon,
  stickyRightIcon,
  size = 'md',
  variant = 'filled',
  colorScheme = 'default',
  isDisabled = false,
  isLoading = false,
  fullWidth = false,
  accessibilityRole = 'button',
  iconClassNames,
  nativeID
}: EcoButtonProps): JSX.Element {
  const { ButtonStylesMap } = useButtonStyles();
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const isActive = useActive(ref);

  const handlePress = useCallback(() => {
    onPress ? onPress() : alert();
  }, [onPress]);

  const getState = () => {
    // These logical orders are set intentionally and should not be changed.
    if (isLoading) return 'loading';
    if (isDisabled) return 'disabled';
    if (isActive) return 'active';
    if (isHovered) return 'hover';
    return 'base';
  };

  const state = getState();
  const buttonStyles = ButtonStylesMap[colorScheme][variant][state].button;
  const textStyles = ButtonStylesMap[colorScheme][variant][state].text;
  const iconsOnly = !children || React.Children.count(children) === 0;
  const leftIconStyles = { fontSize: iconDimensionMap[size], lineHeight: iconDimensionMap[size] };
  const rightIconStyles = { fontSize: iconDimensionMap[size], lineHeight: iconDimensionMap[size] };

  return (
    <Pressable
      className={clsx(
        fullWidth ? 'w-full' : 'w-max',
        'flex rounded-xs outline-none flex-row justify-center items-center relative',
        buttonStyles,
        iconsOnly ? ButtonIconsOnlySizeStylesMap[size] : ButtonSizeStylesMap[size]
      )}
      style={[Gap(1), style]}
      nativeID={nativeID}
      accessibilityRole={accessibilityRole}
      accessibilityState={{ disabled: isDisabled }}
      disabled={isDisabled || isLoading}
      onPress={handlePress}
      ref={ref}
    >
      {isLoading ? (
        <EcoLoader color={ButtonStylesMap[colorScheme][variant][state].text} />
      ) : (
        <>
          {leftIcon && (
            <EcoIcon
              size={size === 'sm' ? 'xl' : '2xl'}
              name={leftIcon}
              className={clsx(
                stickyLeftIcon && `absolute left-0 top-1/2 -translate-y-1/2 ${iconLeftSpacingMap[size]}`,
                leftIconStyles,
                textStyles,
                iconClassNames
              )}
            />
          )}
          {children && (
            <EcoText fontSize="lg" textAlign="center" fontWeight="medium" className={clsx(ButtonTextSizeMap[size], textStyles)}>
              {children}
            </EcoText>
          )}
          {rightIcon && (
            <EcoIcon
              size={size === 'sm' ? 'xl' : '2xl'}
              name={rightIcon}
              className={clsx(
                stickyRightIcon && `absolute right-0 top-1/2 translate-x-1/2 -translate-y-1/2 ${iconRightSpacingMap[size]}`,
                rightIconStyles,
                textStyles
              )}
            />
          )}
        </>
      )}
    </Pressable>
  );
}
