import EditMerchantAdminForm from '@components/forms/EditMerchantAdminForm';
import { EcoLoader, EcoTab, EcoTabContent, EcoTabs } from '@components/shared';
import { FormFieldsConfig } from '@components/shared/EcoForm';
import { EcoPickerItem } from '@components/shared/EcoPicker';
import EcoScrollView from '@components/views/EcoScrollView';
import { CASHBACK_PRICING_MODES, MerchantAdmin } from '@ecocart/entities';
import { BackLink } from '@navigation/BackLink';
import { GlobalScreenProps } from '@navigation/types';
import { useQuery } from '@tanstack/react-query';
import { getMerchantAdmin } from '@utils/api/merchant';
import { EcoCartApiMerchantTabs, EcoCartGlobalMerchantTabs } from '@utils/constants/tabs';
import { View } from 'react-native';

const pricingModeItems: EcoPickerItem[] = [
  { label: 'Percentage AOV', value: CASHBACK_PRICING_MODES[0] },
  { label: 'Flat Rate', value: CASHBACK_PRICING_MODES[1] }
];

const formFieldsConfig: FormFieldsConfig<Partial<MerchantAdmin>>[] = [
  {
    field: 'cashbackEnabled',
    type: 'checkbox'
  },
  {
    field: 'cashbackPricingMode',
    type: 'picker',
    items: pricingModeItems
  },
  {
    field: 'cashbackPercentage',
    label: 'Enter cashback percentage (0-1)',
    placeholder: '0.05',
    helperText: 'Example: Enter 0.05 for 5% cashback on order value'
  },
  {
    field: 'cashbackFlatRateAmount',
    label: 'Enter cashback flat rate amount',
    placeholder: '5',
    helperText: 'Example: Enter 5 for $5.00 cashback'
  }
];

export default function MerchantCashbackSettingScreen({ route }: GlobalScreenProps): JSX.Element {
  const shopName = route?.params?.shopName;
  const { data: merchantAdmin, refetch } = useQuery(['merchantAdmin', shopName], () => getMerchantAdmin(shopName));
  const EcoCartMerchantTabs = merchantAdmin?.platform === 'api' ? EcoCartApiMerchantTabs : EcoCartGlobalMerchantTabs;

  return (
    <EcoScrollView>
      <View className="flex-row">
        <BackLink href="/merchants" label="Back to Merchants" />
      </View>
      <EcoTabs
        tabs={EcoCartMerchantTabs.map(({ label, screenName }) => {
          return {
            label,
            screenName,
            screenParams: { shopName }
          } as EcoTab;
        })}
        prefix="Merchant"
        activeScreen="MerchantCashbackSetting"
      >
        <EcoTabContent title="Edit Cashback Settings">
          {merchantAdmin ? (
            <EditMerchantAdminForm merchantAdmin={merchantAdmin} formFieldsConfig={formFieldsConfig} onSuccess={refetch} />
          ) : (
            <EcoLoader size="large" />
          )}
        </EcoTabContent>
      </EcoTabs>
    </EcoScrollView>
  );
}
