import EditManufacturingForm from '@components/forms/EditManufacturingForm';
import EditMerchantAdminForm from '@components/forms/EditMerchantAdminForm';
import { EcoLoader, EcoTab, EcoTabContent, EcoTabs } from '@components/shared';
import { FormFieldsConfig } from '@components/shared/EcoForm';
import { EcoSeparator } from '@components/shared/EcoSeparator';
import EcoScrollView from '@components/views/EcoScrollView';
import { FLAT_RATE_MODES, MerchantAdmin, PRICING_MODES } from '@ecocart/entities';
import { BackLink } from '@navigation/BackLink';
import { GlobalScreenProps } from '@navigation/types';
import { useQuery } from '@tanstack/react-query';
import { getMerchantAdmin } from '@utils/api/merchant';
import { EcoCartApiMerchantTabs, EcoCartGlobalMerchantTabs } from '@utils/constants/tabs';
import { View } from 'react-native';

const formFieldsConfig: FormFieldsConfig<Partial<MerchantAdmin>>[] = [
  {
    field: 'enableManufacturing',
    type: 'toggle'
  },
  {
    field: 'enableShipping',
    type: 'toggle'
  },
  {
    field: 'defaultItemWeightKg'
  },
  {
    field: 'ecoIncentivesEnabled',
    type: 'checkbox'
  },
  {
    field: 'ecoIncentivesCartValueThreshold'
  },
  {
    field: 'ecoIncentivesNumberOfTrees'
  },
  {
    field: 'offsetMultiplier',
    type: 'picker-other',
    items: [
      {
        label: '1x - Carbon Neutral',
        value: 1
      },
      {
        label: '2x - Climate Positive',
        value: 2
      },
      {
        label: 'Other - Please specify',
        value: 'other'
      }
    ]
  },
  {
    field: 'pricingMode',
    type: 'picker',
    items: PRICING_MODES
  },
  {
    field: 'flatRateMode',
    type: 'picker',
    items: FLAT_RATE_MODES
  },
  {
    field: 'flatRatePercentage',
    label: 'Flat Rate Percentage (only relevant when "Flat Rate Mode" is "percentage")'
  },
  {
    field: 'flatRateLineItemAmount',
    label: 'Flat Rate Line Item Amount (only relevant when "Flat Rate Mode" is "line_item_basis")'
  },
  {
    field: 'flatRateCartAmount',
    label: 'Flat Rate Cart Amount (only relevant when "Flat Rate Mode" is "cart_basis")'
  }
];

export default function MerchantOffsettingScreen({ route }: GlobalScreenProps): JSX.Element {
  const shopName = route?.params?.shopName;
  const { data: merchantAdmin, refetch } = useQuery(['merchantAdmin', shopName], () => getMerchantAdmin(shopName));
  const EcoCartMerchantTabs = merchantAdmin?.platform === 'api' ? EcoCartApiMerchantTabs : EcoCartGlobalMerchantTabs;

  return (
    <EcoScrollView>
      <View className="flex-row">
        <BackLink href="/merchants" label="Back to Merchants" />
      </View>
      <EcoTabs
        tabs={EcoCartMerchantTabs.map(({ label, screenName }) => {
          return {
            label,
            screenName,
            screenParams: { shopName }
          } as EcoTab;
        })}
        prefix="Merchant"
        activeScreen="MerchantOffsetting"
      >
        <EcoTabContent title="Edit Offsetting Settings">
          {merchantAdmin ? (
            <>
              <EditMerchantAdminForm merchantAdmin={merchantAdmin} formFieldsConfig={formFieldsConfig} onSuccess={refetch} />
              <EcoSeparator />
              <EditManufacturingForm merchantAdmin={merchantAdmin} onSuccess={refetch} />
            </>
          ) : (
            <EcoLoader size="large" />
          )}
        </EcoTabContent>
      </EcoTabs>
    </EcoScrollView>
  );
}
