import { StyleProp, View } from 'react-native';

interface Props {
  percentage: number;
  style?: StyleProp<any>;
}

export function EcoProgressBar({ percentage = 50, style }: Props): JSX.Element {
  return (
    <View className="overflow-hidden max-w-[600px] h-[10px] w-full rounded-md bg-gray-100" style={style}>
      <View className="h-full rounded-md bg-primary" style={{ width: `${percentage}%` }}></View>
    </View>
  );
}
