import { EcoAlert, EcoCard, EcoText } from '@components/shared';
import { useOverlay } from '@hooks/useOverlay';
import { SECOND } from '@utils/time';
import { Clipboard, Pressable } from 'react-native';

interface Props {
  shopName: string;
}

export default function InstallScriptCard({ shopName }: Props): JSX.Element {
  const { showToast } = useOverlay();

  const INSTALL_SCRIPT = `<!-- EcoCart Carbon Offset -->
    <script async src="https://widget.ecocart.tools/ecocart.js?shop_name=${shopName}"></script>
  <!-- End EcoCart -->`;

  const handleClipboardPress = () => {
    Clipboard.setString(INSTALL_SCRIPT);
    showToast({
      content: <EcoAlert variant="info" closeable={false} subtitle="Script copied to clipboard!" />,
      config: { dismissTimeMs: 10 * SECOND }
    });
  };

  return (
    <Pressable onPress={handleClipboardPress}>
      <EcoCard title="Click Script to Copy">
        <EcoText fontWeight="semibold" fontSize="sm">
          {INSTALL_SCRIPT}
        </EcoText>
      </EcoCard>
    </Pressable>
  );
}
