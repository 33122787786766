import { EcoInput, EcoText, FormPicker } from '@components/shared';
import { Gap } from '@utils/layout';
import { FontColor, FontSize, FontWeight, FONT_COLORS, FONT_SIZES, FONT_WEIGHTS, TextAlign } from '@utils/tailwind';
import { useState } from 'react';
import { View } from 'react-native';
import { ConfigContainer } from '../views/ConfigContainer';
import { DemoContainer } from '../views/DemoContainer';
import { InternalToolsCard } from '../views/InternalToolsCard';

export function DSTypography(): JSX.Element {
  const [size, setSize] = useState<FontSize>('lg');
  const [weight, setWeight] = useState<FontWeight>('regular');
  const [content, setContent] = useState('Lorem ipsum dolor sit amet consectetur adipisicing elit');
  const [textAlign, setTextAlign] = useState<TextAlign>('left');
  const [color, setColor] = useState<FontColor>('enabled');
  const [underline, setUnderline] = useState<'true' | 'false'>('false');

  const handleSetUnderline = (val: 'true' | 'false') => {
    if (val === 'true') return setUnderline('true');
    return setUnderline('false');
  };

  return (
    <InternalToolsCard componentName="EcoText">
      <DemoContainer>
        {/* Actual demo component */}
        <EcoText fontWeight={weight} fontSize={size} textAlign={textAlign} color={color} underline={underline === 'true'}>
          {content}
        </EcoText>
        {/* End of actual demo component */}
      </DemoContainer>

      <ConfigContainer>
        {/* Configuration component */}
        <View style={Gap()}>
          <EcoInput
            label="Text"
            multiline
            numberOfLines={5}
            value={content}
            onChangeText={(v: string) => setContent(v)}
            placeholder="Enter some text"
          />

          <View className="items-center flex-wrap flex-row" style={Gap()}>
            <FormPicker className="flex-1" label="Font Size" items={[...FONT_SIZES]} value={size} onValueChange={(val) => setSize(val)} />
            <FormPicker
              className="flex-1"
              label="Font Weight"
              items={[...FONT_WEIGHTS]}
              value={weight}
              onValueChange={(val) => setWeight(val)}
            />
            <FormPicker
              className="flex-1"
              label="Text Align"
              items={['left', 'center', 'right'] as TextAlign[]}
              value={textAlign}
              onValueChange={(val) => setTextAlign(val)}
            />
            <FormPicker
              className="flex-1"
              label="Color"
              items={[...FONT_COLORS] as FontColor[]}
              value={color}
              onValueChange={(val) => setColor(val)}
            />
            <FormPicker
              className="flex-1"
              label="Underline"
              items={['true', 'false']}
              value={underline}
              onValueChange={(val) => handleSetUnderline(val)}
            />
          </View>
        </View>
        {/* End of Configuration component */}
      </ConfigContainer>
    </InternalToolsCard>
  );
}
