import { OverlayComponentProps } from '@context/overlay';
import { useWindow } from '@hooks/useWindow';
import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import Animated, { runOnJS, useAnimatedStyle, useSharedValue, withSpring, withTiming } from 'react-native-reanimated';
import { SafeAreaView } from 'react-native-safe-area-context';
import { EcoButton } from './EcoButton';

interface Props extends OverlayComponentProps {}

const FADE_DURATION = 150;

export function EcoToast({ children, hide, config, type = 'default' }: Props): JSX.Element {
  const { height } = useWindow();
  const [asyncChildren, setAsyncChildren] = useState<React.ReactNode>();
  const dismissTimeMs = config?.dismissTimeMs ?? 7000;

  const translateY = useSharedValue(-height);
  const opacity = useSharedValue(0);

  useEffect(() => {
    if (children) {
      fadeIn();
    } else {
      fadeOut();
    }
  }, [children]);

  const fadeIn = useCallback(() => {
    setAsyncChildren(children);

    translateY.value = withSpring(0, { damping: 100 });
    opacity.value = withTiming(1, { duration: FADE_DURATION });

    setTimeout(() => {
      fadeOut();
    }, dismissTimeMs);
  }, [children]);

  const fadeOut = useCallback(() => {
    translateY.value = withSpring(-height);
    opacity.value = withTiming(1, { duration: FADE_DURATION });

    setTimeout(() => {
      setAsyncChildren(children);
    }, FADE_DURATION);
  }, []);

  const panGesture = Gesture.Pan()
    .onUpdate(({ translationY }) => (translateY.value = translationY))
    .onEnd(() => runOnJS(fadeOut)());

  const animatedStyle = useAnimatedStyle(() => ({
    opacity: opacity.value,
    transform: [{ translateY: translateY.value }]
  }));

  return (
    <GestureDetector gesture={panGesture}>
      <Animated.View style={[{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 1000 }, animatedStyle]}>
        <SafeAreaView className="absolute top-0 right-0 left-0">
          <View className="flex flex-row items-center p-2">
            <View className="w-full">{asyncChildren}</View>
            <EcoButton onPress={fadeOut} className="mx-[-55px]" leftIcon="close" variant="ghost" colorScheme={type} />
          </View>
        </SafeAreaView>
      </Animated.View>
    </GestureDetector>
  );
}
