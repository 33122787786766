import { EcoButton, EcoImage, EcoPill, EcoSkeleton, EcoText } from '@components/shared';
import { Project } from '@ecocart/entities';
import { useGlobal } from '@hooks/useGlobal';
import { useSelectProject } from '@hooks/useSelectProject';
import { useNavigation } from '@react-navigation/native';
import { Gap } from '@utils/layout';
import { logUserEvt } from '@utils/log';
import { kebabCase } from 'lodash';
import { Pressable, StyleProp, View } from 'react-native';

interface Props {
  project: Project;
  style?: StyleProp<any>;
  isSelected?: boolean;
  shopName?: string;
  className?: string;
}

export default function ProjectCard({ isSelected, project, shopName, style }: Props): JSX.Element {
  const { isSubmitting, handleSelectProject } = useSelectProject(shopName);
  const { session } = useGlobal();
  const isEcoCartAdmin = session?.user.userType === 'ecocart_admin';
  const navigation = useNavigation();

  const onCardPress = () => {
    if (isEcoCartAdmin) return navigation.navigate('ManageProjectsTab', { screen: 'ManageProject', params: { id: project?.id } });

    logUserEvt('project_list', { project_name: project?.name });
    return navigation.navigate('Project', { id: project?.id });
  };

  return (
    <Pressable
      nativeID={`project-list__${kebabCase(project?.name)}`}
      className="flex-row justify-between rounded-sm bg-white"
      style={style}
      onPress={onCardPress}
      disabled={!project?.id}
    >
      <View className="p-4 shrink flex-row justify-between w-full">
        <View className="flex flex-row mr-2 flex-5">
          <EcoImage width={100} className="mr-4" src={project?.images[0]} />
          <View className="flex-1" style={Gap(1)}>
            {project ? (
              <>
                {project.isPortfolio && (
                  <EcoText color="accent-secondary" fontSize="sm">
                    Portfolio
                  </EcoText>
                )}
                <EcoText fallback={<EcoSkeleton />} fontSize="2xl" fontWeight="semibold">
                  {project.name}
                </EcoText>
                <EcoText fallback={<EcoSkeleton />} fontSize="sm" fontWeight="semibold">
                  {project.location}
                </EcoText>

                <View className="flex-row justify-between">
                  <EcoPill>{project.type}</EcoPill>
                </View>
              </>
            ) : (
              <EcoSkeleton noOfLines={4} height="xs" spacing={3} />
            )}
          </View>
        </View>

        {isEcoCartAdmin ? (
          !isSelected ? (
            <EcoButton isLoading={isSubmitting} size="sm" style={{ flex: 1 }} onPress={() => handleSelectProject(project)}>
              Select Project
            </EcoButton>
          ) : null
        ) : null}
      </View>
    </Pressable>
  );
}
