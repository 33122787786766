import { EcoButton, EcoImage, EcoRadioButtonCards, EcoText } from '@components/shared';
import { IMAGE_ASSETS } from '@ecocart/universal-utils';
import { useClaimFormContext } from '@screens/ClaimsScreen';
import { ClaimResolutionType } from '@utils/constants/claims';
import { Gap } from '@utils/layout';
import { CreateValidationSchema } from '@utils/validation';
import { useFormik } from 'formik';
import { View } from 'react-native';

export const ClaimsFormResolution = (): JSX.Element => {
  const { setStep, claimDetails, setClaimDetails } = useClaimFormContext();

  const onSubmit = () => {
    setStep('Review');
    setClaimDetails({ ...claimDetails, ...formik.values });
  };

  const formik = useFormik({
    initialValues: {
      resolution: claimDetails.resolution
    },
    onSubmit,
    validateOnChange: true,
    validationSchema: CreateValidationSchema(['resolution'])
  });

  return (
    <>
      <View>
        <View className="mt-6" style={Gap(1)}>
          <EcoText fontSize="4xl" fontWeight="semibold" textAlign="center">
            What's your preference?
          </EcoText>
          <EcoText fontSize="lg" textAlign="center" color="subdued">
            What would you prefer if your claim is accepted?
          </EcoText>
        </View>
      </View>

      <View className="my-6 min-h-[250px] justify-center">
        <form onSubmit={formik.handleSubmit}>
          <View style={Gap(4)}>
            <EcoRadioButtonCards
              cardClassName="min-h-0 p-4"
              direction="vertical"
              itemStyle={{ justifyContent: 'start' }}
              value={formik.values.resolution || ''}
              iconPosition="left"
              items={Object.entries(ClaimResolutionType).map(([key, value]) => ({
                ...value,
                value: key
              }))}
              onValueChange={(value) => {
                formik.setFieldValue('resolution', value);
              }}
            />
            <View>
              <EcoButton onPress={formik.handleSubmit} fullWidth className="mt-4" isDisabled={!formik.values.resolution}>
                Continue
              </EcoButton>
              <EcoButton
                variant="ghost"
                onPress={() => {
                  setStep('IssueDetails');
                }}
                fullWidth
                className="mt-4"
              >
                Back
              </EcoButton>
            </View>
          </View>
        </form>
      </View>
      <View className="flex-row justify-center items-center mt-8">
        <EcoText fontSize="xs">Powered By</EcoText>
        <EcoImage src={IMAGE_ASSETS.ECOCART} height={14} width={100} resizeMode="contain" />
      </View>
    </>
  );
};
