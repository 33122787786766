import { EcoSkeleton } from '@components/shared/EcoSkeleton';
import clsx from 'clsx';
import { StyleProp, View } from 'react-native';
import { EcoText } from './EcoText';

interface Props {
  title: string;
  subTitle?: JSX.Element | string;
  style?: StyleProp<any>;
  titleClassName?: StyleProp<any>;
  subTitleClassName?: StyleProp<any>;
  className?: string;
}

export function EcoPageTitle({ title, subTitle, titleClassName, subTitleClassName, style }: Props): JSX.Element {
  return (
    <View style={style}>
      <EcoText fallback={<EcoSkeleton height="4xl" />} className={clsx('mb-2', titleClassName)} fontSize="4xl" fontWeight="semibold">
        {title}
      </EcoText>
      {subTitle && (
        <EcoText color="disabled-inverted" className={subTitleClassName} fallback={<EcoSkeleton />}>
          {subTitle}
        </EcoText>
      )}
    </View>
  );
}
