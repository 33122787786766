import { EcoAlert, EcoCard, EcoDropdown, EcoIcon, EcoTab, EcoTabContent, EcoTable, EcoTabs } from '@components/shared';
import { EcoDropDownItem } from '@components/shared/EcoDropdown.utils';
import { ColumnConfig } from '@components/shared/EcoTable.utils';
import EcoScrollView from '@components/views/EcoScrollView';
import { Contact, EmailTemplateName, FullContact, Merchant } from '@ecocart/entities';
import { useFeaturePermission } from '@hooks/useFeaturePermission';
import { useOverlay } from '@hooks/useOverlay';
import { BackLink } from '@navigation/BackLink';
import { GlobalScreenProps } from '@navigation/types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ManageMerchantSubscriptionParams, getContacts, subscribeMerchantContacts, unsubscribeMerchantContacts } from '@utils/api/email';
import { getMerchantAdmin } from '@utils/api/merchant';
import { EcoCartApiMerchantTabs, EcoCartGlobalMerchantTabs } from '@utils/constants/tabs';
import { format, isValid, toDate } from 'date-fns';
import { View } from 'react-native';

interface FlattenedContact {
  email: string;
  merchant: Merchant['shopName'];
  lastContacted: number;
  templateName: EmailTemplateName;
  templateId: string;
  active: boolean;
}

export default function MerchantCampaignsScreen({ route }: GlobalScreenProps): JSX.Element {
  const shopName = route?.params?.shopName;
  const { data, refetch, isLoading } = useQuery<FullContact[]>(['contacts', shopName], () =>
    getContacts({ filters: [{ field: 'merchant', operator: 'eq', value: shopName }] })
  );
  const { data: merchantAdmin } = useQuery(['merchantAdmin', shopName], () => getMerchantAdmin(shopName));
  const { isFeaturePermissioned } = useFeaturePermission('qa');
  const { handleError, showSuccess, showInfo } = useOverlay();
  const EcoCartMerchantTabs = merchantAdmin?.platform === 'api' ? EcoCartApiMerchantTabs : EcoCartGlobalMerchantTabs;

  const mutation = useMutation<Contact | null, Error, ManageMerchantSubscriptionParams & { subscribe: boolean }>(
    (params) => (params.subscribe ? subscribeMerchantContacts(params) : unsubscribeMerchantContacts(params)),
    {
      onSuccess: () => {
        showSuccess('Updated all users subscriptions');
        refetch();
      },
      onError: () => {
        handleError('Error updating all users subscription. Please try again later.');
      }
    }
  );

  const columnConfig: ColumnConfig<FlattenedContact>[] = [
    {
      field: 'email',
      searchable: true,
      flex: 2
    },
    {
      field: 'merchant',
      searchable: true,
      flex: 3
    },
    {
      field: 'templateName',
      searchable: true,
      flex: 2
    },
    {
      field: 'templateId',
      searchable: true,
      flex: 2
    },
    {
      field: 'lastContacted',
      title: 'Last Contacted (Local Time)',
      searchable: true,
      format: ({ lastContacted }) => {
        const date = toDate(Number(lastContacted));
        if (!isValid(date) || !lastContacted) return '--';
        return format(date, 'MMM d, yyyy - h:mm:ss a');
      },
      flex: 3
    },
    {
      field: 'active',
      flex: 0.5
    },
    {
      field: 'more',
      title: '',
      flex: 1,
      format: (contact: FlattenedContact) => {
        const dropdownItems: EcoDropDownItem[] = [
          {
            label: contact.active ? 'Unsubscribe' : 'Subscribe',
            onPress: () => {
              showInfo('Updating user subscription');
              mutation.mutate({
                merchant: contact.merchant,
                emailTemplateName: contact.templateName,
                subscribe: !contact.active
              });
            }
          }
        ];

        const showDropdown = contact.templateId && contact.templateName;

        return showDropdown ? (
          <View className="flex items-end justify-center">
            <EcoDropdown items={dropdownItems} variant="ghost" showDownArrow={false} size="sm" isDisabled={mutation.isLoading}>
              <EcoIcon name="more_vert" size="2xl" className="text-gray" />
            </EcoDropdown>
          </View>
        ) : (
          <></>
        );
      }
    }
  ];

  const flattenedData: FlattenedContact[] = (data || []).reduce((acc: FlattenedContact[], curr: FullContact) => {
    return [...acc, ...curr.campaigns.map((campaign) => ({ email: curr.email, ...campaign }))];
  }, []);

  function SubUnsubMerchantContactsDropdown(): JSX.Element {
    const firstUser = flattenedData[0];
    const dropdownItems: EcoDropDownItem[] = [
      {
        label: firstUser?.active ? 'Unsubscribe All Contacts' : 'Subscribe All Contacts',
        onPress: () => {
          showInfo('Updating all contacts subscription');
          mutation.mutate({
            merchant: shopName,
            emailTemplateName: firstUser?.templateName || 'impact_report', // TODO Support multiple templates
            subscribe: !firstUser?.active
          });
        }
      }
    ];

    const showDropdown = !!firstUser;

    return showDropdown ? (
      <View className="flex items-end justify-center">
        <EcoDropdown items={dropdownItems} variant="ghost" showDownArrow={false} size="sm" isDisabled={mutation.isLoading}>
          <EcoIcon name="more_vert" size="2xl" className="text-gray" />
        </EcoDropdown>
      </View>
    ) : (
      <></>
    );
  }

  return (
    <EcoScrollView>
      <View className="flex-row">
        <BackLink href="/merchants" label="Back to Merchants" />
      </View>
      <EcoTabs
        tabs={EcoCartMerchantTabs.map(({ label, screenName }) => {
          return {
            label,
            screenName,
            screenParams: { shopName }
          } as EcoTab;
        })}
        prefix="Merchant"
        activeScreen="MerchantCampaigns"
      >
        <EcoTabContent>
          <EcoCard title="Contacts" topRightComponent={<SubUnsubMerchantContactsDropdown />} style={{ padding: 0 }}>
            {isFeaturePermissioned ? (
              <EcoTable data={flattenedData} isLoading={isLoading} columns={columnConfig} />
            ) : (
              <EcoAlert title="Coming Soon" variant="warning" closeable={false} className="mt-2" />
            )}
          </EcoCard>
        </EcoTabContent>
      </EcoTabs>
    </EcoScrollView>
  );
}
