import MerchantActivityCard from '@components/cards/MerchantActivityCard';
import EditMerchantAdminForm from '@components/forms/EditMerchantAdminForm';
import { useFormFieldsConfig } from '@components/forms/EditMerchantAdminForm.utils';
import { EcoLoader, EcoTabContent, EcoTabs } from '@components/shared';
import EcoScrollView from '@components/views/EcoScrollView';
import { BackLink } from '@navigation/BackLink';
import { GlobalScreenProps } from '@navigation/types';
import { useQuery } from '@tanstack/react-query';
import { getMerchantAdmin } from '@utils/api/merchant';
import { EcoCartApiMerchantTabs, EcoCartGlobalMerchantTabs } from '@utils/constants/tabs';
import { View } from 'react-native';

export default function MerchantSettingsScreen({ route }: GlobalScreenProps): JSX.Element {
  const shopName = route?.params?.shopName;
  const { data: merchantAdmin, refetch } = useQuery(['merchantAdmin', shopName], () => getMerchantAdmin(shopName));
  const formFieldsConfig = useFormFieldsConfig(merchantAdmin);
  const EcoCartMerchantTabs = merchantAdmin?.platform === 'api' ? EcoCartApiMerchantTabs : EcoCartGlobalMerchantTabs;

  return (
    <EcoScrollView>
      <View className="flex-row">
        <BackLink href="/merchants" label="Back to Merchants" />
      </View>
      <EcoTabs
        tabs={EcoCartMerchantTabs.map(({ label, screenName }) => {
          return {
            label,
            screenName,
            screenParams: { shopName }
          };
        })}
        prefix="Merchant"
        activeScreen="MerchantSettings"
      >
        <EcoTabContent title="Edit Merchant">
          {merchantAdmin ? (
            <>
              <MerchantActivityCard merchantAdmin={merchantAdmin} />
              <EditMerchantAdminForm merchantAdmin={merchantAdmin} formFieldsConfig={formFieldsConfig} onSuccess={refetch} />
            </>
          ) : (
            <EcoLoader size="large" />
          )}
        </EcoTabContent>
      </EcoTabs>
    </EcoScrollView>
  );
}
