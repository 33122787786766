import { eachMonthOfInterval, endOfYear, format, startOfYear } from 'date-fns';

export const getMonthLabelsFromCurrentYear = (): string[] => {
  const now = new Date();
  const start = startOfYear(now);
  const end = endOfYear(now);
  const months = eachMonthOfInterval({ start, end });

  // Format each month as 'MMM ''yy' (e.g., 'May '23')
  return months.map((month) => format(month, "MMM ''yy"));
};
