import { updateMerchantAdmin } from '@utils/api/merchant';
import { useGlobal } from './useGlobal';

interface ReturnType {
  updateMerchantAdminOnboardingStatus: (status: boolean) => Promise<void>;
}

export const useUpdateMerchantAdminOnboarding = (): ReturnType => {
  const { session, updateSessionMerchantAdmin } = useGlobal();

  const { merchantAdmin } = session || {};

  const updateMerchantAdminOnboardingStatus = async (status: boolean) => {
    if (!merchantAdmin) return;
    try {
      const response = await updateMerchantAdmin(merchantAdmin.shopName, { ...merchantAdmin, isOnboardingComplete: status });
      updateSessionMerchantAdmin(response);
    } catch (error: any) {
      console.error(error);
    }
  };

  return { updateMerchantAdminOnboardingStatus };
};
