import { EcoButton, EcoCard, EcoLoader } from '@components/shared';
import { useGlobal } from '@hooks/useGlobal';
import { useOverlay } from '@hooks/useOverlay';
import { usePaymentMethod } from '@hooks/usePaymentMethod';
import {
  BankAccount,
  CreditCard,
  PaymentMethods,
  ShopifyCharge,
  deleteBankAccount,
  deleteCreditCard,
  deleteShopifyCharge,
  hasAtLeastOnePaymentMethod,
  setBankAsDefault,
  setCreditCardAsDefault,
  setShopifyChargeAsDefault
} from '@utils/api/payments';
import { sendMerchantAdminUpdateMessage } from '@utils/api/slack';
import { Gap } from '@utils/layout';
import { GlobalStylesProps } from '@utils/prop-types';
import { colors } from '@utils/tailwind';
import { View } from 'react-native';
import EcoEmptyState from '../shared/EcoEmptyState';
import BankAccountCard from './BankAccountCard';
import CreditCardCard from './CreditCardCard';
import ShopifyChargeCard from './ShopifyChargeCard';

interface Props extends GlobalStylesProps {
  shopName: string;
  paymentMethods: PaymentMethods | null | undefined;
  paymentMethodsAreLoading: boolean;
  refetchPaymentMethods: () => void;
  readOnly?: boolean;
  setPrimaryPaymentMethod: (paymentMethod: string) => void;
}

export default function PaymentMethodsCard({
  paymentMethods,
  paymentMethodsAreLoading,
  refetchPaymentMethods,
  setPrimaryPaymentMethod,
  shopName,
  style,
  readOnly = false
}: Props): JSX.Element {
  const { session } = useGlobal();
  const { user } = session || {};
  const { showSelectPaymentMethodModal } = usePaymentMethod();
  const { hideModal, showSuccess, handleError } = useOverlay();
  const showShopifyChargeCard = paymentMethods?.shopify_charge && user?.userType !== 'api_admin';

  const handleAfterCardAdded = async () => {
    refetchPaymentMethods();
    hideModal();

    await sendMerchantAdminUpdateMessage('merchant_added_billing_info', session, {});
  };

  const handleAddPaymentMethod = () => {
    showSelectPaymentMethodModal((paymentType) => {
      if (paymentType) handleAfterCardAdded();
    });
  };

  const handleSetAsDefault = ({
    creditCard,
    bankAccount,
    shopifyCharge,
    shopName
  }: {
    creditCard?: CreditCard;
    bankAccount?: BankAccount;
    shopifyCharge?: ShopifyCharge;
    shopName: string;
  }) => {
    return async () => {
      try {
        if (creditCard) {
          await setCreditCardAsDefault(creditCard, shopName);
        }

        if (bankAccount) {
          await setBankAsDefault(bankAccount, shopName);
        }

        if (shopifyCharge) {
          await setShopifyChargeAsDefault(shopifyCharge, shopName);
        }

        showSuccess('Successfully updated default payment method');
        refetchPaymentMethods();
      } catch (error: any) {
        handleError(error);
      }
    };
  };

  const handleDelete = ({
    creditCard,
    bankAccount,
    shopifyCharge,
    shopName
  }: {
    creditCard?: CreditCard;
    bankAccount?: BankAccount;
    shopifyCharge?: ShopifyCharge;
    shopName: string;
  }) => {
    return async () => {
      try {
        if (creditCard) {
          await deleteCreditCard(creditCard, shopName);
        }

        if (bankAccount) {
          await deleteBankAccount(bankAccount, shopName);
        }

        if (shopifyCharge) {
          await deleteShopifyCharge(shopifyCharge, shopName);
        }

        showSuccess('Successfully deleted payment method');
        refetchPaymentMethods();
        hideModal();
      } catch (error: any) {
        alert(error);
        handleError(error);
      }
    };
  };

  if (paymentMethods?.customer && hasAtLeastOnePaymentMethod(paymentMethods)) {
    return (
      <EcoCard
        style={style}
        title="Payment Method"
        subTitle="Change how you pay"
        topRightComponent={
          !readOnly ? (
            <EcoButton
              isDisabled={user?.userType === 'ecocart_admin'}
              nativeID="payment-card__add-button"
              colorScheme="default"
              size="sm"
              onPress={handleAddPaymentMethod}
              leftIcon="add"
            >
              Add Payment Method
            </EcoButton>
          ) : (
            <></>
          )
        }
      >
        <View style={Gap()} nativeID="payment-methods">
          {(paymentMethods?.cards || []).map((creditCard, index) => (
            <View key={'cc' + index}>
              <CreditCardCard
                customer={paymentMethods.customer}
                creditCard={creditCard}
                handleSetAsDefault={handleSetAsDefault({ creditCard, shopName })}
                handleDelete={handleDelete({ creditCard, shopName })}
                readOnly={readOnly}
                setPrimaryPaymentMethod={setPrimaryPaymentMethod}
              />
            </View>
          ))}
          {(paymentMethods?.bank_accounts || []).map((bankAccount, index) => (
            <View key={'ba' + index}>
              <BankAccountCard
                customer={paymentMethods.customer}
                bankAccount={bankAccount}
                handleSetAsDefault={handleSetAsDefault({ bankAccount, shopName })}
                handleDelete={handleDelete({ bankAccount, shopName })}
                readOnly={readOnly}
                setPrimaryPaymentMethod={setPrimaryPaymentMethod}
              />
            </View>
          ))}
          {showShopifyChargeCard ? (
            <ShopifyChargeCard
              shopifyCharge={paymentMethods.shopify_charge}
              handleSetAsDefault={handleSetAsDefault({ shopifyCharge: paymentMethods.shopify_charge, shopName })}
              handleDelete={handleDelete({ shopifyCharge: paymentMethods.shopify_charge, shopName })}
              readOnly={readOnly}
              setPrimaryPaymentMethod={setPrimaryPaymentMethod}
            />
          ) : null}
        </View>
      </EcoCard>
    );
  }

  return (
    <EcoCard wrapperClassName="py-8">
      <View className="flex flex-col items-center" style={Gap(5)}>
        {paymentMethodsAreLoading ? (
          <EcoLoader size="large" className="py-9" color={colors.primary[400]} />
        ) : (
          <View className="flex flex-col items-center" style={Gap(4)}>
            <EcoEmptyState
              title="Add Billing"
              subTitle="Get started and add your billing information to collect donations made by your customers!"
              imgSrc="https://ecocart-public-images.s3.amazonaws.com/billing.svg"
            />

            <EcoButton
              isDisabled={user?.userType === 'ecocart_admin'}
              nativeID="payment-card__setup-billing-button"
              onPress={handleAddPaymentMethod}
            >
              Set up billing info
            </EcoButton>
          </View>
        )}
      </View>
    </EcoCard>
  );
}
