import { TranslateProps } from '@ecocart/entities';
import OpenAI from 'openai';
import { apiCall, POWER_TOOLS_NODE_API } from './api';

/* -------------------------------- merchant -------------------------------- */
export const getTranslations = ({ text, targetLocale }: TranslateProps): Promise<OpenAI.Chat.Completions.ChatCompletion | null> => {
  if (!prompt) return Promise.resolve(null);
  return apiCall<OpenAI.Chat.Completions.ChatCompletion>('POST', `${POWER_TOOLS_NODE_API}/translate`, {
    text,
    targetLocale
  });
};
