import { BillingDashboard } from '@components/dashboards/BillingDashboard';
import { EcoCard, EcoTabContent, EcoTabs } from '@components/shared';
import { FormCheckbox } from '@components/shared/FormCheckbox';
import EcoScrollView from '@components/views/EcoScrollView';
import { useGlobal } from '@hooks/useGlobal';
import { useQuery } from '@tanstack/react-query';
import { getMerchantAdmin, updateMerchantAdmin } from '@utils/api/merchant';
import { getCurrentInvoice } from '@utils/api/payments';
import { sendMerchantAdminUpdateMessage } from '@utils/api/slack';
import { EcoCartApiMerchantTabs, EcoCartGlobalMerchantTabs } from '@utils/constants/tabs';
import { Gap } from '@utils/layout';
import React from 'react';
import { View } from 'react-native';
import { BackLink } from '../navigation/BackLink';
import { GlobalScreenProps } from '../navigation/types';

export default function MerchantBillingScreen({ route }: GlobalScreenProps): JSX.Element {
  const shopName = route?.params?.shopName;
  const { session } = useGlobal();
  const { data: merchantAdmin, refetch: refetchMerchantAdmin } = useQuery(['merchantAdmin', shopName], () => getMerchantAdmin(shopName));
  const { data: currentInvoice } = useQuery(['getCurrentInvoice', shopName], () => getCurrentInvoice(shopName));
  const EcoCartMerchantTabs = merchantAdmin?.platform === 'api' ? EcoCartApiMerchantTabs : EcoCartGlobalMerchantTabs;

  const handleConfirmUpdateBillingDisabled = async (shouldBillingBeDisabled: any) => {
    const isConfirmed = confirm(`Are you sure you want ${shouldBillingBeDisabled ? 'disable' : 'enable'} billing for ${shopName}?`);
    if (!isConfirmed || !shopName) return;

    const updatedMerchantAdminProps = { isBillingDisabled: shouldBillingBeDisabled };
    await updateMerchantAdmin(shopName, updatedMerchantAdminProps);

    await sendMerchantAdminUpdateMessage('merchant_settings_change', session, {
      merchantAdmin: updatedMerchantAdminProps,
      dbMerchantAdmin: merchantAdmin
    });

    refetchMerchantAdmin();
  };

  return (
    <EcoScrollView>
      <View className="flex-row">
        <BackLink href="/merchants" label="Back to Merchants" />
      </View>
      <EcoTabs
        tabs={EcoCartMerchantTabs.map(({ label, screenName }) => {
          return {
            label,
            screenName,
            screenParams: { shopName }
          };
        })}
        prefix="Merchant"
        activeScreen="MerchantBilling"
      >
        <EcoTabContent title="Payment Method" subTitle="Merchant's payment info">
          <View style={Gap(6)} className="bg-gray-50 p-2">
            <EcoCard title="Settings">
              {merchantAdmin && (
                <FormCheckbox
                  label="Disable Billing for Merchant"
                  disabled={!merchantAdmin.isBillingDisabled}
                  value={merchantAdmin.isBillingDisabled}
                  onValueChange={handleConfirmUpdateBillingDisabled}
                />
              )}
            </EcoCard>
            {merchantAdmin ? <BillingDashboard merchantAdmin={merchantAdmin} currentInvoice={currentInvoice} readOnly={true} /> : null}
          </View>
        </EcoTabContent>
      </EcoTabs>
    </EcoScrollView>
  );
}
