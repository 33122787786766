import { EcoCard, EcoText } from '@components/shared';
import { Gap } from '@utils/layout';
import { GlobalFormProps } from '@utils/prop-types';
import React from 'react';
import { View } from 'react-native';
import EditPaymentMethodForm from './EditPaymentMethodForm.web';

interface Props extends GlobalFormProps {}

export default function AddCreditCardForm({ onSuccess }: Props): JSX.Element {
  return (
    <EcoCard style={Gap()}>
      <View className="flex flex-col items-center">
        <EcoText fontSize="2xl" fontWeight="semibold">
          Add a New Card
        </EcoText>
        <EcoText fontSize="sm">Add a new card to complete billing setup</EcoText>
      </View>
      <EditPaymentMethodForm onSuccess={onSuccess} />
    </EcoCard>
  );
}
