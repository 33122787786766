import { MAP_WIDTH } from '@components/cards/ProjectList';
import ProjectForm from '@components/forms/ProjectForm';
import { EcoCard, EcoImage, EcoLoader, EcoMap } from '@components/shared';
import EcoScrollView from '@components/views/EcoScrollView';
import { Project } from '@ecocart/entities';
import { useWindow } from '@hooks/useWindow';
import { BackLink } from '@navigation/BackLink';
import { GlobalScreenProps } from '@navigation/types';
import { useNavigation } from '@react-navigation/native';
import { useQuery } from '@tanstack/react-query';
import { getProject } from '@utils/api/projects';
import React from 'react';
import { View } from 'react-native';
import { MarkerConfig } from './ProjectsScreen';

export default function ManageProjectScreen({ route }: GlobalScreenProps): JSX.Element {
  const id = route?.params?.id;
  const { isDesktopWeb, height } = useWindow();
  const { data: project, refetch } = useQuery(['project', id], () => getProject(id));
  const navigation = useNavigation();

  const convertProjectToMarkerConfig = (project: Project): MarkerConfig => {
    return {
      title: project?.name,
      description: project?.description,
      img: project?.images?.[0] || '',
      onPress: () => navigation.navigate('Project', { id: project.id }),
      coordinates: project?.coordinates
    };
  };

  return (
    <>
      <EcoScrollView style={{ marginRight: isDesktopWeb ? MAP_WIDTH : 0, flex: 1, overflow: 'unset' }}>
        <View className="flex-row">
          <BackLink href="/manage-projects" label="Back to Manage Projects" />
        </View>
        <EcoCard title="Edit Project">
          {project ? (
            <>
              <EcoImage src={project.images[0]} className="w-full mb-4 object-cover" height={200} />
              <ProjectForm project={project} onSuccess={refetch} />
            </>
          ) : (
            <EcoLoader size="large" />
          )}
        </EcoCard>
      </EcoScrollView>
      {isDesktopWeb && !!project && !project?.isPortfolio ? (
        <View style={{ position: 'absolute', top: 0, bottom: 0, right: 0, width: MAP_WIDTH }}>
          <EcoMap center={project?.coordinates} width={MAP_WIDTH} height={height} markers={[convertProjectToMarkerConfig(project)]} />
        </View>
      ) : null}
    </>
  );
}
