import { EcoCard, EcoText } from '@components/shared';
import { EcoSeparator } from '@components/shared/EcoSeparator';
import { useOverlay } from '@hooks/useOverlay';
import { Gap } from '@utils/layout';
import { Linking, Pressable, View } from 'react-native';

export const RewardsScreenSubtitle = (): JSX.Element => {
  const { showModal } = useOverlay();
  const openFAQPage = () => Linking.openURL('https://support.ecocart.io/knowledge/faq-ecocart-rewards?hs_preview=GXbsZqcz-164047940272');

  const handleShowLearnMoreModal = () => {
    showModal({
      nativeID: 'what-is-ecocart-rewards-modal',
      content: (
        <EcoCard size="lg">
          <View style={Gap(4)}>
            <EcoText fontSize="lg">What is EcoCart Rewards?</EcoText>
            <EcoSeparator />
            <EcoText>
              Earn rewards for your customers' carbon offsets! EcoCart Rewards allows you to earn a share of our profits. Use your rewards
              for various purposes to enhance your sustainability and marketing efforts.
            </EcoText>
            <EcoText>
              Got more questions? Please check out the{' '}
              <Pressable onPress={openFAQPage}>
                <EcoText color="link">FAQ page.</EcoText>
              </Pressable>
            </EcoText>
          </View>
        </EcoCard>
      )
    });
  };

  return (
    <>
      <EcoText>Earn cash when your customers offset their purchases. </EcoText>
      <Pressable onPress={handleShowLearnMoreModal}>
        <EcoText className="underline">Learn more</EcoText>
      </Pressable>
    </>
  );
};
