import { EcoButton, EcoInput, EcoRadioButtons, EcoText, FormButtons, FormPicker } from '@components/shared';
import { formatNumber } from '@ecocart/universal-utils';
import { useGlobal } from '@hooks/useGlobal';
import { useOverlay } from '@hooks/useOverlay';
import { useQuery } from '@tanstack/react-query';
import { Invoice, createInvoiceRefund, getInvoicePaymentMethod } from '@utils/api/payments';
import { Gap } from '@utils/layout';
import { GlobalFormProps } from '@utils/prop-types';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { useState } from 'react';
import { View } from 'react-native';

const REFUND_REASONS = ['Promo / Discount', 'Test Orders', 'Incorrect Calculations', 'Other'];

interface Props extends GlobalFormProps {
  shopName: string;
  invoice: Invoice;
}

export default function InvoiceRefundForm({ shopName, invoice, onCancel, onSuccess }: Props): JSX.Element {
  const { data: invoicePaymentMethod } = useQuery(['getInvoicePaymentMethod', shopName, invoice.identifier], () =>
    getInvoicePaymentMethod(shopName, invoice.identifier)
  );
  const [isPartial, setIsPartial] = useState<boolean>(false);
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { showSuccess, handleError } = useOverlay();
  const { session } = useGlobal();

  let paymentMethod: string = '';
  switch (invoicePaymentMethod?.payment_method_type) {
    case 'card':
      paymentMethod = invoicePaymentMethod.card?.last4 ? `**** **** **** ${invoicePaymentMethod.card?.last4}` : paymentMethod;
      break;
    case 'us_bank_account':
      paymentMethod = invoicePaymentMethod?.bank_account?.bank_name || paymentMethod;
      break;
    case 'shopify_charge':
      paymentMethod = invoicePaymentMethod?.shopify_charge?.name || paymentMethod;
      break;
    default:
      break;
  }

  const onSubmit = async ({ refundType, partialAmount, memo }: { refundType: string; partialAmount: number; memo: string }) => {
    setIsSubmitting(true);
    try {
      const amount = refundType === 'full' ? invoice.total_amount_usd : partialAmount;
      await createInvoiceRefund(shopName, invoice.identifier, amount, memo, session);
      showSuccess('Successfully issued refund');
      setIsSubmitting(false);
      onSuccess?.();
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  const handleValidation = (values: Record<string, any>) => {
    const errors: Record<string, string> = {};
    if (!values.refundType) {
      errors.refundType = 'Refund type is required';
    }
    if (values.refundType === 'partial' && (!values.partialAmount || values.partialAmount == 0)) {
      errors.partialAmount = 'Amount is required for Partial Refund';
    }
    if (!values.memo) {
      errors.memo = 'Reason is required';
    }
    if (Number(values.partialAmount) + Number(invoice.total_refunds_amount_usd) > Number(invoice.total_amount_usd)) {
      errors.partialAmount = 'Refund amount cannot exceed original amount';
    }
    if (values.refundType === 'full' && invoice.total_refunds_amount_usd) {
      errors.refundType = 'Refund amount cannot exceed original amount';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      refundType: '',
      partialAmount: 0,
      memo: 'Promo / Discount'
    },
    onSubmit,
    validateOnMount: true,
    validate: handleValidation,
    enableReinitialize: true
  });
  const { values, isValid, errors, setFieldValue, handleChange, handleSubmit } = formik;

  const content = !isPreview ? (
    <>
      <View className="flex-direction-row" style={Gap(4)}>
        <EcoRadioButtons
          className="flex-col"
          value={values.refundType}
          onValueChange={(value: string) => {
            setFieldValue('refundType', value);
            setIsPartial(value === 'partial');
          }}
          items={[
            { value: 'full', label: 'Full Refund' },
            { value: 'partial', label: 'Partial Refund' }
          ]}
        />
        {errors.partialAmount ? (
          <EcoText color="danger" fontSize="xs" className="pl-6">
            {errors.partialAmount}
          </EcoText>
        ) : null}
        <EcoInput
          placeholder="Partial Refund Amount"
          value={String(values.partialAmount)}
          disabled={!isPartial}
          startAdornment="$"
          className="pl-6"
          onChangeText={handleChange('partialAmount')}
        />
      </View>
      <View>
        <FormPicker label="Reason for refund" value={values.memo} items={REFUND_REASONS} onValueChange={handleChange('memo')} />
      </View>
      <View>
        <FormButtons className="justify-between" style={Gap(6)}>
          <EcoButton onPress={() => onCancel?.()} variant="outlined" colorScheme="danger">
            Cancel
          </EcoButton>
          <EcoButton isDisabled={!isValid || isSubmitting} onPress={() => setIsPreview(true)}>
            Next
          </EcoButton>
        </FormButtons>
      </View>
    </>
  ) : (
    <>
      <View>
        <EcoText fontSize="sm" fontWeight="medium">
          Refund Amount
        </EcoText>
        <EcoText fontSize="lg" color="subdued">
          {formatNumber(values.refundType === 'full' ? invoice.total_amount_usd : values.partialAmount, {
            style: 'currency',
            currency: 'usd'
          })}{' '}
          {values.refundType === 'full' ? 'Full Refund' : 'Partial Refund'}
        </EcoText>
      </View>
      {paymentMethod ? (
        <View>
          <EcoText fontSize="sm" fontWeight="medium">
            Payment Method
          </EcoText>
          <EcoText fontSize="lg" color="subdued">
            {paymentMethod}
          </EcoText>
        </View>
      ) : null}
      <View>
        <EcoText fontSize="sm" fontWeight="medium">
          Reason for Refund
        </EcoText>
        <EcoText fontSize="lg" color="subdued">
          {values.memo}
        </EcoText>
      </View>
      <View>
        <FormButtons className="justify-between" style={Gap(6)}>
          <EcoButton onPress={() => setIsPreview(false)} variant="outlined">
            Edit Refund
          </EcoButton>
          <EcoButton isDisabled={!isValid || isSubmitting} isLoading={isSubmitting} onPress={handleSubmit}>
            Confirm Refund
          </EcoButton>
        </FormButtons>
      </View>
    </>
  );

  return (
    <View style={Gap(6)}>
      <View>
        <EcoText fontSize="sm" fontWeight="medium">
          Invoice Number
        </EcoText>
        <EcoText fontSize="lg" color="subdued">
          {invoice.identifier}
        </EcoText>
      </View>
      <View className="flex-row" style={Gap(6)}>
        <View>
          <EcoText fontSize="sm" fontWeight="medium">
            Billing Period
          </EcoText>
          <EcoText fontSize="lg" color="subdued">
            {`${format(new Date(invoice.start_date), 'MMM dd, yyyy')} - ${format(new Date(invoice.end_date), 'MMM dd, yyyy')}`}
          </EcoText>
        </View>
        <View>
          <EcoText fontSize="sm" fontWeight="medium">
            Original Amount
          </EcoText>
          <EcoText fontSize="lg" color="subdued">
            {formatNumber(invoice.total_amount_usd, { style: 'currency', currency: 'usd' })}
          </EcoText>
        </View>
      </View>
      {content}
    </View>
  );
}
