import { EcoButton, EcoCard, EcoInput, EcoText, EcoWidget } from '@components/shared';
import { Merchant } from '@ecocart/entities';
import { useOverlay } from '@hooks/useOverlay';
import { BackLink } from '@navigation/BackLink';
import { useNavigation } from '@react-navigation/native';
import { MerchantContext } from '@screens/MerchantWidgetsScreen';
import { updateMerchant } from '@utils/api/merchant';
import { Gap } from '@utils/layout';
import { CreateValidationSchema } from '@utils/validation';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { View } from 'react-native';
import { ConfigContainer } from '../views/ConfigContainer';
import { getInitialValues } from './ImpactCounterDesigner.utils';
import { fieldPaths, getProps } from './utils';

export function ImpactReceiptDesigner({ widgetKey }: { widgetKey: string }): JSX.Element {
  const { showSuccess, handleError } = useOverlay();
  const navigation = useNavigation();
  const { merchant, refetch } = useContext(MerchantContext);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { widgetMap } = merchant || {};
  const isValidWidgetKey = widgetMap && widgetKey in widgetMap;
  if (merchant && !isValidWidgetKey) {
    navigateToWidgetList();
  }

  function navigateToWidgetList() {
    if (!merchant) return;
    return navigation.navigate('MerchantWidgetTemplates', { shopName: merchant?.shopName, template: 'impact-counter' });
  }

  const onSubmit = async () => {
    if (!merchant) return;

    setIsSubmitting(true);

    const updatedMerchant: Partial<Merchant> = {
      widgetMap: {
        ...merchant.widgetMap,
        [widgetKey]: values
      }
    };

    try {
      await updateMerchant(merchant.shopName, updatedMerchant, merchant);
      showSuccess('Successfully updated merchant');
      setIsSubmitting(false);
      refetch && refetch();
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: widgetMap?.[widgetKey] || getInitialValues(),
    onSubmit,
    validateOnChange: true,
    validationSchema: CreateValidationSchema(['elements'])
  });

  const { values, isValid, handleSubmit } = formik;

  return (
    <>
      <View className="pb-4 flex-row">
        <BackLink onPress={navigateToWidgetList} label="Back To Widgets" />
      </View>
      <View style={Gap()}>
        <EcoCard>
          <EcoText fontSize="xl" fontWeight="semibold" className="mb-2">
            Preview
          </EcoText>
          <View>{values && merchant && <EcoWidget widget={values} merchant={merchant} />}</View>
        </EcoCard>
        <EcoCard>
          <ConfigContainer>
            <>
              <EcoText fontSize="xl" fontWeight="semibold" className="my-6">
                Configurations
              </EcoText>
              {/* Configuration component */}
              <form onSubmit={formik.handleSubmit}>
                <View style={Gap()}>
                  <View style={Gap()}>
                    <View style={Gap()}>
                      <EcoText fontSize="lg" fontWeight="medium">
                        Container
                      </EcoText>
                      <View style={Gap()}>
                        <EcoText fontSize="base" fontWeight="medium">
                          Styles
                        </EcoText>
                        <View className="flex-row" style={Gap()}>
                          <EcoInput
                            className="flex-1"
                            label="Margins"
                            {...getProps(formik, fieldPaths.containerMargin)}
                            placeholder="0px"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Padding"
                            {...getProps(formik, fieldPaths.containerPadding)}
                            placeholder="20px"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Border Width"
                            {...getProps(formik, fieldPaths.containerBorderWidth)}
                            placeholder="0px"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Border Color"
                            {...getProps(formik, fieldPaths.containerBorderColor)}
                            placeholder="#0F8354"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Border Radius"
                            {...getProps(formik, fieldPaths.containerBorderRadius)}
                            placeholder="4px"
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                  <EcoButton isDisabled={isSubmitting || !isValid} onPress={handleSubmit}>
                    Save
                  </EcoButton>
                </View>
              </form>
            </>
            {/* End of Configuration component */}
          </ConfigContainer>
        </EcoCard>
      </View>
    </>
  );
}
