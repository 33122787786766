import { EcoAccordion, EcoButton, EcoCard, EcoInput, EcoRadioButtons, EcoText, EcoToggle, EcoWidget, FormPicker } from '@components/shared';
import { ConfigContainer } from '@components/views/ConfigContainer';
import { LOCALES, Merchant } from '@ecocart/entities';
import { IMAGE_ASSETS, translations } from '@ecocart/universal-utils';
import { useOverlay } from '@hooks/useOverlay';
import { BackLink } from '@navigation/BackLink';
import { useNavigation } from '@react-navigation/native';
import { MerchantContext } from '@screens/MerchantWidgetsScreen';
import { updateMerchant } from '@utils/api/merchant';
import { Gap } from '@utils/layout';
import { CreateValidationSchema } from '@utils/validation';
import { useFormik } from 'formik';
import { get } from 'lodash';
import { useContext, useState } from 'react';
import { View } from 'react-native';
import { getInitialValues } from './EcoIncentivesWidgetDesigner.utils';
import {
  convertCountryCodeToEnglishName,
  ECLogoVariantValue,
  fieldPaths,
  getProps,
  getTranslationPath,
  TextTransformValues,
  typeCaseItems
} from './utils';

export function EcoIncentivesWidgetDesigner({ widgetKey }: { widgetKey: string }): JSX.Element {
  const navigation = useNavigation();
  const { showSuccess, handleError } = useOverlay();
  const { merchant, refetch } = useContext(MerchantContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [variant, setVariant] = useState<ECLogoVariantValue>('on_pill');
  const { widgetMap } = merchant || {};
  const isValidWidgetKey = widgetMap && widgetKey in widgetMap;

  if (!isValidWidgetKey) {
    navigateToWidgetList();
  }

  function navigateToWidgetList() {
    if (!merchant) return;
    return navigation.navigate('MerchantWidgetTemplates', { shopName: merchant?.shopName, template: 'eco-incentives' });
  }

  const onSubmit = async () => {
    if (!merchant) return;

    setIsSubmitting(true);

    const updatedMerchant: Partial<Merchant> = {
      widgetMap: {
        ...merchant.widgetMap,
        [widgetKey]: values
      }
    };

    try {
      await updateMerchant(merchant.shopName, updatedMerchant, merchant);
      showSuccess('Successfully updated merchant');
      setIsSubmitting(false);
      refetch && refetch();
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: widgetMap?.[widgetKey] || getInitialValues(),
    onSubmit,
    validateOnChange: true,
    validationSchema: CreateValidationSchema(['elements']),
    enableReinitialize: true
  });

  const { values, setFieldValue, isValid, handleSubmit } = formik;

  // when variant changes, set the corresponding color and EC logo
  const handleVariantChange = (variant: ECLogoVariantValue) => {
    let newHeaderColor;
    let newECLogoUrl;
    let newLogoPaddingHorizontal;
    let newLogoPaddingVertical;
    let newLogoBorderRadius;
    let newLogoBorderBackgroundColor;

    switch (variant) {
      case 'on_pill':
        newECLogoUrl = IMAGE_ASSETS.ON_PILL_LOGO;
        newLogoPaddingHorizontal = '8px';
        newLogoPaddingVertical = '6px';
        newLogoBorderRadius = '4px';
        newLogoBorderBackgroundColor = '#0F8354';
        break;
      case 'black_logo':
        newECLogoUrl = IMAGE_ASSETS.ECOCART_BLACK;
        break;
      case 'standard':
        newECLogoUrl = IMAGE_ASSETS.ECOCART;
        break;
      case 'white':
        newECLogoUrl = IMAGE_ASSETS.ECOCART_WHITE;
        break;
      default:
        break;
    }
    setFieldValue(fieldPaths.headerColor, newHeaderColor);
    setFieldValue(fieldPaths.logoURL, newECLogoUrl);
    setFieldValue(fieldPaths.logoPaddingLeft, newLogoPaddingHorizontal);
    setFieldValue(fieldPaths.logoPaddingRight, newLogoPaddingHorizontal);
    setFieldValue(fieldPaths.logoPaddingTop, newLogoPaddingVertical);
    setFieldValue(fieldPaths.logoPaddingBottom, newLogoPaddingVertical);
    setFieldValue(fieldPaths.logoBorderRadius, newLogoBorderRadius);
    setFieldValue(fieldPaths.logoBorderBackgroundColor, newLogoBorderBackgroundColor);
  };

  if (!get(values, fieldPaths.logoURL)) {
    handleVariantChange(variant);
  }

  const isLogoActive = get(values, fieldPaths.logoDisable) !== true;
  const subheaderCompleteTranslationKey =
    merchant?.payorMode === 'customer_paying'
      ? 'template_hoorayYourOrderWillPlantTreesMaximizeImpact'
      : 'template_hoorayYourOrderWillPlantTreesMerchantWillAlsoOffset';

  return (
    <>
      <View className="pb-4">
        <BackLink onPress={navigateToWidgetList} label="Back To Widgets" />
      </View>

      <View style={Gap()}>
        <EcoCard>
          <EcoText fontSize="xl" fontWeight="semibold" className="mb-2">
            Preview
          </EcoText>
          <View className="w-full">{values && merchant && <EcoWidget widget={values} merchant={merchant} />}</View>
        </EcoCard>
        <EcoCard>
          <ConfigContainer>
            {/* Configuration component */}
            <form onSubmit={formik.handleSubmit}>
              <View style={Gap(8)}>
                <View style={Gap(8)}>
                  <View>
                    <EcoText fontSize="xl" fontWeight="medium">
                      Widget Name
                    </EcoText>
                    <EcoInput label="Name" {...getProps(formik, fieldPaths.name)} />
                  </View>

                  <View style={Gap()}>
                    <EcoText fontSize="xl" fontWeight="medium">
                      Container
                    </EcoText>

                    <View style={Gap(2)}>
                      <EcoText fontSize="lg" fontWeight="medium">
                        Style
                      </EcoText>

                      <View className="flex-row flex-wrap" style={Gap()}>
                        <EcoInput
                          className="flex-1"
                          label="Background Color"
                          {...getProps(formik, fieldPaths.containerBackgroundColor)}
                          placeholder="#FFF"
                        />
                        <EcoInput
                          className="flex-1"
                          label="Border Width"
                          {...getProps(formik, fieldPaths.containerBorderWidth)}
                          placeholder="0px"
                        />
                        <EcoInput
                          className="flex-1"
                          label="Border Color"
                          {...getProps(formik, fieldPaths.containerBorderColor)}
                          placeholder="#FFFFFF"
                        />
                        <EcoInput
                          className="flex-1"
                          label="Border Radius"
                          {...getProps(formik, fieldPaths.containerBorderRadius)}
                          placeholder="#FFFFFF"
                        />
                        <EcoInput
                          className="flex-1"
                          label="Padding"
                          {...getProps(formik, fieldPaths.containerPadding)}
                          placeholder="16px"
                        />
                      </View>
                    </View>
                  </View>

                  <View style={Gap()}>
                    <EcoText fontSize="xl" fontWeight="medium">
                      Header
                    </EcoText>
                    <View>
                      <EcoText fontSize="lg" fontWeight="medium">
                        Content
                      </EcoText>
                      <View style={Gap()}>
                        <EcoInput
                          className="flex-1 mb-3 md:mr-3 md:mb-0"
                          label="Header - English"
                          {...getProps(formik, getTranslationPath('header', 'en'))}
                          placeholder={translations.en.template_werePlantingTreesOnOrders}
                        />

                        <EcoAccordion header="Show Translations">
                          {LOCALES.map((locale) => (
                            <EcoInput
                              key={`header-${locale}`}
                              className="flex-1"
                              label={`Header - ${convertCountryCodeToEnglishName(locale)}`}
                              {...getProps(formik, getTranslationPath('header', locale))}
                              placeholder={translations[locale].template_offsetYourOrderAtCheckoutWith}
                            />
                          ))}
                        </EcoAccordion>
                      </View>
                    </View>

                    <View>
                      <EcoText fontSize="lg" fontWeight="medium">
                        Style
                      </EcoText>
                      <View className="flex-wrap" style={Gap()}>
                        <View className="flex-row flex-wrap" style={Gap()}>
                          <EcoInput label="Font Family" {...getProps(formik, fieldPaths.headerFontFamily)} placeholder="system-ui" />
                          <EcoInput label="Font Size" {...getProps(formik, fieldPaths.headerFontSize)} placeholder="14px" />
                          <EcoInput label="Line Height" {...getProps(formik, fieldPaths.headerLineHeight)} placeholder="16px" />
                          <EcoInput
                            label="Font Weight"
                            {...getProps(formik, fieldPaths.headerFontWeight)}
                            placeholder="300, 500, bold..."
                          />
                          <EcoInput label="Font Color" {...getProps(formik, fieldPaths.headerColor)} placeholder="#333333" />
                        </View>
                        <View className="lg:pr-2 lg:w-1/5">
                          <FormPicker
                            label="Type Case"
                            items={typeCaseItems}
                            value={get(values, fieldPaths.headerTextTransform, '') as string}
                            onValueChange={(val: TextTransformValues) => setFieldValue(fieldPaths.headerTextTransform, val)}
                          />
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={Gap(4)}>
                    <View className="flex-row" style={Gap()}>
                      <EcoText fontSize="xl" fontWeight="medium">
                        Attribution
                      </EcoText>
                      <EcoToggle value={isLogoActive} onValueChange={(value: boolean) => setFieldValue(fieldPaths.logoDisable, !value)} />
                    </View>
                    <EcoRadioButtons
                      value={variant}
                      items={[
                        { label: 'On Pill', value: 'on_pill' },
                        { label: 'Standard', value: 'standard' },
                        { label: 'Black Logo', value: 'black_logo' },
                        { label: 'White', value: 'white' }
                      ]}
                      onValueChange={(variant: ECLogoVariantValue) => {
                        setVariant(variant);
                        handleVariantChange(variant);
                      }}
                    />
                  </View>
                </View>

                <View style={Gap()}>
                  <EcoText fontSize="xl" fontWeight="medium">
                    Progress Bar
                  </EcoText>
                  <View>
                    <EcoText fontSize="lg" fontWeight="medium">
                      Content
                    </EcoText>
                    <View style={Gap()}>
                      <EcoInput
                        className="flex-1 mb-3 md:mr-3 md:mb-0"
                        label="In Progress - English"
                        {...getProps(formik, getTranslationPath('subheader_in_progress', 'en'))}
                        placeholder={translations.en.template_youreValueAwayFromPlantingTrees}
                      />

                      <EcoAccordion header="Show Translations">
                        {LOCALES.map((locale) => (
                          <EcoInput
                            key={`subheader_in_progress-${locale}`}
                            className="flex-1"
                            label={`In Progress - ${convertCountryCodeToEnglishName(locale)}`}
                            {...getProps(formik, getTranslationPath('subheader_in_progress', locale))}
                            placeholder={translations[locale].template_youreValueAwayFromPlantingTrees}
                          />
                        ))}
                      </EcoAccordion>
                    </View>
                    <View style={Gap()}>
                      <EcoInput
                        className="flex-1 mb-3 md:mr-3 md:mb-0"
                        label="Completed - English"
                        {...getProps(formik, getTranslationPath('subheader_complete', 'en'))}
                        placeholder={translations.en[subheaderCompleteTranslationKey]}
                      />

                      <EcoAccordion header="Show Translations">
                        {LOCALES.map((locale) => (
                          <EcoInput
                            key={`subheader_complete-${locale}`}
                            className="flex-1"
                            label={`Completed - ${convertCountryCodeToEnglishName(locale)}`}
                            {...getProps(formik, getTranslationPath('subheader_complete', locale))}
                            placeholder={translations[locale][subheaderCompleteTranslationKey]}
                          />
                        ))}
                      </EcoAccordion>
                    </View>
                  </View>
                  <View style={Gap(7)}>
                    <View>
                      <EcoText fontSize="lg" fontWeight="medium">
                        Style
                      </EcoText>
                      <View className="flex-wrap" style={Gap()}>
                        <View className="flex-row flex-wrap" style={Gap()}>
                          <EcoInput label="Font Family" {...getProps(formik, fieldPaths.subheaderFontFamily)} placeholder="system-ui" />
                          <EcoInput label="Font Size" {...getProps(formik, fieldPaths.subheaderFontSize)} placeholder="14px" />
                          <EcoInput label="Line Height" {...getProps(formik, fieldPaths.subheaderLineHeight)} placeholder="16px" />
                          <EcoInput
                            label="Font Weight"
                            {...getProps(formik, fieldPaths.subheaderFontWeight)}
                            placeholder="300, 500, bold..."
                          />
                          <EcoInput label="Font Color" {...getProps(formik, fieldPaths.subheaderColor)} placeholder="#333333" />
                        </View>
                        <View className="lg:pr-2 lg:w-1/5">
                          <FormPicker
                            label="Type Case"
                            items={typeCaseItems}
                            value={get(values, fieldPaths.subheaderTextTransform, '') as string}
                            onValueChange={(val: TextTransformValues) => setFieldValue(fieldPaths.subheaderTextTransform, val)}
                          />
                        </View>
                      </View>
                    </View>

                    <View>
                      <EcoText fontSize="lg" fontWeight="medium">
                        Filled Progress Bar
                      </EcoText>
                      <View className="flex-wrap" style={Gap()}>
                        <View className="flex-row" style={Gap()}>
                          <EcoInput
                            className="flex-2"
                            label="Background Color"
                            {...getProps(formik, fieldPaths.progressBarFilledBackgroundColor)}
                            placeholder="#0F8354"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Border Width"
                            {...getProps(formik, fieldPaths.progressBarFilledBorderWidth)}
                            placeholder="1px"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Border Color"
                            {...getProps(formik, fieldPaths.progressBarFilledBorderColor)}
                            placeholder="#0F8354"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Border Radius"
                            {...getProps(formik, fieldPaths.progressBarFilledBorderRadius)}
                            placeholder="0px 0px 0px 0px"
                          />
                        </View>
                      </View>
                    </View>

                    <View>
                      <EcoText fontSize="lg" fontWeight="medium">
                        Empty Progress Bar
                      </EcoText>
                      <View className="flex-wrap" style={Gap()}>
                        <View className="flex-row" style={Gap()}>
                          <EcoInput
                            label="Background Color"
                            {...getProps(formik, fieldPaths.progressBarBackgroundColor)}
                            placeholder="#0F8354"
                          />
                          <EcoInput label="Border Width" {...getProps(formik, fieldPaths.progressBarBorderWidth)} placeholder="1px" />
                          <EcoInput label="Border Color" {...getProps(formik, fieldPaths.progressBarBorderColor)} placeholder="#0F8354" />
                          <EcoInput
                            label="Border Radius"
                            {...getProps(formik, fieldPaths.progressBarBorderRadius)}
                            placeholder="0px 0px 0px 0px"
                          />
                        </View>
                        <View className="flex-row md:w-1/2 md:pr-2" style={Gap()}>
                          <EcoInput label="Height" {...getProps(formik, fieldPaths.progressBarHeight)} placeholder="10px" />
                          <EcoInput label="Margin" {...getProps(formik, fieldPaths.progressBarMargin)} placeholder="0px 0px 0px 0px" />
                        </View>
                      </View>
                    </View>

                    <View>
                      <EcoText fontSize="lg" fontWeight="medium">
                        Footer
                      </EcoText>

                      <View className="flex-row flex-wrap" style={Gap()}>
                        <EcoInput label="Font Family" {...getProps(formik, fieldPaths.footerFontFamily)} placeholder="system-ui" />
                        <EcoInput label="Font Size" {...getProps(formik, fieldPaths.footerFontSize)} placeholder="12px" />
                        <EcoInput label="Line Height" {...getProps(formik, fieldPaths.footerLineHeight)} placeholder="16px" />
                        <EcoInput label="Font Weight" {...getProps(formik, fieldPaths.footerFontWeight)} placeholder="300, 500, bold..." />
                        <EcoInput label="Font Color" {...getProps(formik, fieldPaths.footerColor)} placeholder="#333333" />
                      </View>
                    </View>
                  </View>
                </View>

                <EcoButton isDisabled={isSubmitting || !isValid} onPress={handleSubmit}>
                  Save
                </EcoButton>
              </View>
            </form>

            {/* End of Configuration component */}
          </ConfigContainer>
        </EcoCard>
      </View>
    </>
  );
}
