import { User, UserType } from '@ecocart/entities';
import { GLOBAL_PUBLIC_CONFIG } from '@ecocart/universal-utils';
import { StorageKey } from '../storage';
import { USER_MGMT_API, apiCall, getAvailableAuth, throwHttpError } from './api';

export const availableUserTypes: UserType[] = ['merchant_admin', 'ecocart_admin'];

export const getSelf = (): Promise<User> => {
  return getAvailableAuth().then((auth) => {
    if (auth?.[StorageKey.cognito_jwt]) {
      return apiCall<User>('GET', `${USER_MGMT_API}/user`);
    } else if (auth?.[StorageKey.shpat]) {
      return apiCall<User>('GET', `${USER_MGMT_API}/shopify-user/${auth[StorageKey.shpat]}`, undefined, {
        'X-Eco-PubKey': GLOBAL_PUBLIC_CONFIG.X_ECO_PUB_KEY
      });
    } else {
      throwHttpError('missing required tokens');
      return new Promise((reject) => reject(null as any));
    }
  });
};

export const createSelf = (user: User, shpat: string): Promise<User> => {
  return apiCall<User>('POST', `${USER_MGMT_API}/user/signUp`, user, {
    'X-Eco-Shopify-Access-Token': shpat
  });
};

export const getUser = (id: string, shpat?: string): Promise<User> => {
  const headers = shpat ? { 'X-Eco-Shopify-Access-Token': shpat } : (null as any);
  return apiCall<User>('GET', `${USER_MGMT_API}/user/${id}`, null, headers);
};

export const getUsers = (): Promise<User[]> => {
  return apiCall<User[]>('GET', `${USER_MGMT_API}/users`);
};

export const createUser = (user: User): Promise<User> => {
  return apiCall<User>('POST', `${USER_MGMT_API}/user/signUp`, user);
};

export const updateUser = (id: string, userProps: Partial<User>): Promise<User> => {
  return apiCall<User>('PUT', `${USER_MGMT_API}/user/${id}`, userProps);
};

export const addMerchantAccessToUser = (id: string, userProps: Partial<User>, shpat: string): Promise<User> => {
  return apiCall<User>('PUT', `${USER_MGMT_API}/user/${id}`, userProps, {
    'X-Eco-Shopify-Access-Token': shpat
  });
};

export const deleteUser = (id: string): Promise<User> => {
  return apiCall('DELETE', `${USER_MGMT_API}/user/${id}`);
};

export const resendVerificationLink = (email: string): Promise<any> => {
  return apiCall('GET', `${USER_MGMT_API}/resend/${email}`, undefined, {
    'X-Eco-PubKey': GLOBAL_PUBLIC_CONFIG.X_ECO_PUB_KEY
  });
};
