import { EcoButton, EcoText } from '@components/shared';
import { Merchant, Template, WidgetUI } from '@ecocart/entities';
import { TemplateToTypeMap } from '@ecocart/fe-utils';
import { fromBase64, isValidBase64 } from '@ecocart/universal-utils';
import { KebabTemplate } from '@navigation/LinkingConfiguration';
import { useNavigation } from '@react-navigation/native';
import { Gap } from '@utils/layout';
import { kebabCase, startCase } from 'lodash';
import { View } from 'react-native';
import { EcoWidget } from '../shared/EcoWidget.web';
import { MOCK_CALCULATION, MOCK_CART } from './utils';

interface Props {
  widget: WidgetUI;
  merchant: Merchant;
}

const templatesHidingEditButton: Template[] = ['two', 'three', 'four', 'seven'];

export function WidgetDemo({ widget, merchant }: Props): JSX.Element {
  const showEditButton = !templatesHidingEditButton.includes(widget.template);
  const { shopName } = merchant;
  const navigation = useNavigation();
  const widgetName = widget.name ? (isValidBase64(widget.name) ? fromBase64(widget.name) : widget.name) : '';
  const getTemplateName = () => {
    const widgetTemplate = kebabCase(widget.template);

    if (widget.template.includes('ab_test_pdp')) return kebabCase(TemplateToTypeMap[widget.template]);
    if (widget.template.includes('order_protection_checkout')) return kebabCase(TemplateToTypeMap[widget.template]);
    if (widget.template.includes('ab_test_widget')) return 'checkout_widget';
    return widgetTemplate;
  };

  return (
    <View className="w-full">
      <View className="flex-row" style={Gap(5)}>
        <View>
          <EcoText fontSize="xs">NAME</EcoText>
          <EcoText fontSize="base" fontWeight="semibold">
            {widgetName}
          </EcoText>
        </View>
        <View>
          <EcoText fontSize="xs">PAGE TYPE</EcoText>
          <EcoText fontSize="base" fontWeight="semibold">
            {widget.pageType ? startCase(widget.pageType.toLowerCase()) : 'N/A'}
          </EcoText>
        </View>
        <View>
          <EcoText fontSize="xs">PAGE PATH</EcoText>
          <EcoText fontSize="base" fontWeight="semibold">
            {widget.pathname || 'N/A'}
          </EcoText>
        </View>
      </View>
      <View className="flex-row justify-between items-center mt-5">
        <View className="flex-9">
          <EcoWidget widget={widget} merchant={merchant} cart={MOCK_CART} calculation={MOCK_CALCULATION} />
        </View>

        {showEditButton && (
          <View className="flex-1 ml-6">
            <EcoButton
              variant="outlined"
              onPress={() =>
                navigation.navigate('MerchantWidgetEditor', {
                  shopName,
                  template: getTemplateName() as KebabTemplate,
                  widgetKey: widget.widgetKey
                })
              }
            >
              Edit
            </EcoButton>
          </View>
        )}
      </View>
    </View>
  );
}
