import { EcoButton, EcoCard, EcoText } from '@components/shared';
import { EcoPageTitle } from '@components/shared/EcoPageTitle';
import { ColumnConfig } from '@components/shared/EcoTable.utils';
import { EcoTableNew } from '@components/shared/EcoTableNew';
import EcoScrollView from '@components/views/EcoScrollView';
import { pruneEmpty } from '@ecocart/universal-utils';
import { useGlobal } from '@hooks/useGlobal';
import { useNavigation } from '@react-navigation/native';
import { useQuery } from '@tanstack/react-query';
import { getClaims } from '@utils/api/claim';
import { Gap } from '@utils/layout';
import { Claim } from '@utils/types/claims';
import { format } from 'date-fns';
import { startCase } from 'lodash';
import React, { useEffect } from 'react';
import { View } from 'react-native';

const columnConfig: ColumnConfig<Claim>[] = [
  {
    field: 'identifier',
    title: 'Claim ID',
    flex: 4.5,
    searchable: true
  },
  {
    field: 'order_number',
    title: 'Order No.',
    flex: 1.5,
    searchable: true
  },
  {
    field: 'customer_email',
    title: 'Customer',
    searchable: true,
    flex: 3,
    format: ({ customer_email, customer_name }) => (
      <View>
        {customer_name && <EcoText>{customer_name}</EcoText>}
        <EcoText color={customer_name ? 'subdued' : 'enabled'}>{customer_email}</EcoText>
      </View>
    )
  },
  {
    field: 'status',
    title: 'Status',
    flex: 3,
    searchable: true,
    format: ({ status }) => startCase(status)
  },
  {
    field: 'issue_type',
    title: 'Reason',
    flex: 3,
    format: ({ issue_type }) => startCase(issue_type)
  },
  {
    field: 'created_at',
    title: 'Submitted On',
    flex: 2,
    searchable: true,
    format: ({ created_at }) => format(new Date(created_at), 'MMM d, yyyy')
  }
];

const STATUSES = ['all', 'new', 'pending', 'approved', 'denied', 'closed'] as const;

type StatusButtonType = (typeof STATUSES)[number];

export default function ClaimsHomeScreen({ route }): JSX.Element {
  const { session } = useGlobal();
  const [selectedStatus, setSelectedStatus] = React.useState<StatusButtonType>('all');
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState(10);
  const navigation = useNavigation();

  const { data, isFetching } = useQuery(['claims', session?.merchantAdmin?.shopName, page, selectedStatus, limit], {
    queryFn: async () => {
      const query = {
        status: selectedStatus === 'all' ? undefined : selectedStatus,
        limit,
        page
      };

      return await getClaims(session?.merchantAdmin?.shopName || '', pruneEmpty(query) as Record<string, string | number | string[]>);
    },
    enabled: Boolean(session?.merchantAdmin?.shopName)
  });

  const handleStatusChange = (status: StatusButtonType) => {
    setSelectedStatus(status);
  };

  useEffect(() => {
    if (!session?.merchantAdmin?.pkgProtectionEnabled) {
      // navigate to Home
      navigation.navigate('Home');
    }
  }, [session?.merchantAdmin?.pkgProtectionEnabled, navigation]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };
  const handleLimitChange = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <EcoScrollView>
      <View className="flex-row items-start justify-between">
        <EcoPageTitle className="mb-5" title="Claims" />
      </View>
      <EcoCard>
        <EcoTableNew
          defaultDataQuery={{ sorts: [{ field: 'created_at', dir: 'desc' }] }}
          data={data?.claims}
          isLoading={isFetching}
          columns={columnConfig}
          filterButtons={
            <View className="flex-row" style={Gap(2)}>
              {STATUSES.map((status) => (
                <EcoButton
                  key={status}
                  variant={selectedStatus === status ? 'filled' : 'outlined'}
                  onPress={() => handleStatusChange(status)}
                >
                  {startCase(status)}
                </EcoButton>
              ))}
            </View>
          }
          onPageChange={handlePageChange}
          onLimitChange={handleLimitChange}
          currentPage={page}
          isFirstPage={page === 1}
          isLastPage={!data?.last_claim_id}
        />
      </EcoCard>
    </EcoScrollView>
  );
}
