import { EcoIcon, EcoSkeleton, EcoText } from '@components/shared';
import { IconName } from '@components/shared/EcoIcon.utils';
import { MerchantAdmin } from '@ecocart/entities';
import { IMPERIAL_LOCALES, convertKgToLb, formatNumber, getWeightUnit } from '@ecocart/universal-utils';
import { useGlobal } from '@hooks/useGlobal';
import { useWindow } from '@hooks/useWindow';
import { useQuery } from '@tanstack/react-query';
import { getStats } from '@utils/api/reporting';
import { GlobalStylesProps } from '@utils/prop-types';
import { FontSize, screens } from '@utils/tailwind';
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Animated, LayoutChangeEvent, NativeScrollEvent, NativeSyntheticEvent, Pressable, ScrollView, View } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import Container from './Container';
import { TotalCarbonOffset, generateCardConfigs } from './WelcomeBanner.utils';

export interface CardProps {
  title?: string;
  body: string;
  iconName: IconName;
  tooltip: string;
}

function Card({ title, body, tooltip, iconName }: CardProps): JSX.Element {
  const lgScreen = window.matchMedia(`(min-width: ${screens.lg})`).matches;
  const [tooltipHeight, setTooltipHeight] = useState(0);
  const [displayState, setDisplayState] = useState<'body' | 'tooltip'>('body');
  const fadeTooltipAnim = useRef(new Animated.Value(0)).current;
  const fadeBodyAnim = useRef(new Animated.Value(0)).current;
  const ref = useRef(null);
  const isHovered = useHover(ref);

  const fadeInTooltip = () => {
    Animated.timing(fadeTooltipAnim, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true
    }).start();
  };

  const fadeOutTooltip = () => {
    Animated.timing(fadeTooltipAnim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true
    }).start();
  };

  const fadeInBody = useCallback(() => {
    Animated.timing(fadeBodyAnim, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true
    }).start();
  }, [fadeBodyAnim]);

  const fadeOutBody = () => {
    Animated.timing(fadeBodyAnim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true
    }).start();
  };

  useEffect(() => {
    fadeInBody();
  }, [fadeInBody]);

  if (isHovered && lgScreen) {
    fadeInTooltip();
    fadeOutBody();
  }

  if (!isHovered && lgScreen) {
    fadeOutTooltip();
    fadeInBody();
  }

  const handlePress = () => {
    if (displayState === 'body') {
      fadeInTooltip();
      fadeOutBody();
      setDisplayState('tooltip');
    } else {
      fadeOutTooltip();
      fadeInBody();
      setDisplayState('body');
    }
  };

  return (
    <Pressable
      ref={ref}
      onPress={handlePress}
      onBlur={() => {
        fadeOutTooltip();
        fadeInBody();
        setDisplayState('body');
      }}
      className="cursor-pointer rounded-md py-3 pl-3 pr-2 lg:pl-4 relative overflow-hidden h-[175px] basis-[175px]"
    >
      <>
        <View
          className="absolute inset-0 bg-white transition-opacity duration-300 ease"
          style={{ opacity: !isHovered || (!lgScreen && displayState === 'body') ? 0.1 : 0.15 }}
        />
        <View className="mb-2 rounded-xs p-2 relative overflow-hidden w-max">
          <View className="absolute inset-0 bg-white opacity-10" />
          <EcoIcon name={iconName} className="text-algea-400" />
        </View>
        <View className="justify-between flex-1">
          <View>
            <Animated.View
              style={{
                opacity: fadeTooltipAnim,
                maxHeight: fadeTooltipAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, tooltipHeight]
                })
              }}
            >
              <EcoText
                onLayout={({ nativeEvent }) => {
                  setTooltipHeight(nativeEvent.layout.height);
                }}
                color="enabled-inverted"
                fontSize="sm"
                className="mb-2 capitalize transition-all"
              >
                {tooltip}
              </EcoText>
            </Animated.View>
            <Animated.View
              style={{
                opacity: fadeBodyAnim
              }}
            >
              <EcoText
                color="enabled-inverted"
                fallback={<EcoSkeleton mode="dark" height={lgScreen ? '2xl' : 'md'} />}
                fontSize={lgScreen ? '5xl' : '3xl'}
              >
                {title}
              </EcoText>
              <EcoText color="enabled-inverted" fontSize={lgScreen ? 'sm' : 'xs'} className="mb-2 capitalize transition-all">
                {body}
              </EcoText>
              <EcoIcon name="info" className="text-white text-xs" />
            </Animated.View>
          </View>
        </View>
      </>
    </Pressable>
  );
}

interface Props extends GlobalStylesProps {
  merchantAdmin?: MerchantAdmin;
}

export default function WelcomeBanner({ merchantAdmin }: Props): JSX.Element {
  const { session } = useGlobal();
  const { isDesktopWeb } = useWindow();
  const [gap, setGap] = useState<number>(24);
  const [titleSize, setTitleSize] = useState<FontSize>('4xl');
  const [subtitleSize, setSubtitleSize] = useState<FontSize>('8xl');
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(isDesktopWeb);
  const scrollViewRef = useRef<ScrollView>(null);
  const shopName = merchantAdmin?.shopName || '';
  const { data, isFetching } = useQuery(['getStats', shopName], () => getStats(shopName));

  const hasNoData = data && 'message' in data;
  const allTimeData = data?.find((period) => period.period === 'ALL_TIME');
  const totalOffsetOrderCount = hasNoData ? { value: 0, trend: 0 } : allTimeData?.totalOffsetOrderCount;
  const totalCarbonOffset =
    hasNoData && !isFetching
      ? ({ value: 0, units: 'kg', trend: 0 } as TotalCarbonOffset)
      : !hasNoData
      ? allTimeData?.totalCarbonOffset
      : undefined;
  const { isAboveXLScreen, isAboveLGScreen } = useWindow();
  const cardConfig: CardProps[] = totalCarbonOffset ? generateCardConfigs({ ...totalCarbonOffset, units: 'kg' }) : [];
  const locale = merchantAdmin?.locale;
  const isImperial = locale && IMPERIAL_LOCALES.includes(locale);

  const handleScrollToStart = () => {
    scrollViewRef.current?.scrollTo({ x: 0, y: 0, animated: true });
  };

  const handleScrollToEnd = () => {
    scrollViewRef.current?.scrollToEnd();
  };

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    // mobile view should not display arrows
    if (!isDesktopWeb) return;

    const offsetX = event.nativeEvent.contentOffset.x;
    const contentSizeWidth = event.nativeEvent.contentSize.width;
    const layoutSizeWidth = event.nativeEvent.layoutMeasurement.width;

    if (contentSizeWidth) setShowLeftArrow(true);
    setShowRightArrow(true);

    if (offsetX <= 0) {
      setShowLeftArrow(false);
    } else if (offsetX >= contentSizeWidth - layoutSizeWidth) {
      setShowRightArrow(false);
    }
  };

  const handleScrollViewLayout = (event: LayoutChangeEvent) => {
    // visible width
    const { width } = event.nativeEvent.layout;
    // get the full width of the ScrollView including the overflown content!
    const scrollContent = scrollViewRef.current?.getScrollableNode();
    const fullWidth = scrollContent.scrollWidth;

    setShowRightArrow(fullWidth > width);
  };

  useEffect(() => {
    const handleResize = () => {
      setGap(isAboveLGScreen ? 24 : 8);
      setTitleSize(isAboveXLScreen ? '4xl' : isAboveLGScreen ? '3xl' : 'lg');
      setSubtitleSize(isAboveXLScreen ? '8xl' : isAboveLGScreen ? '7xl' : '4xl');
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [isAboveLGScreen, isAboveXLScreen]);

  const totalCarbonOffsetValue = isImperial ? convertKgToLb(totalCarbonOffset?.value || 0) : totalCarbonOffset?.value || 0;

  return (
    <View className={clsx('bg-secondary-900 pb-6 pt-8 lg:pb-8 lg:pt-11')}>
      <Container>
        <EcoText fontSize={titleSize} color="enabled-inverted" className="mb-6 xl:mb-8">
          Welcome back, {session?.user.firstName} {session?.user.lastName}!
        </EcoText>
        <View className="flex-row mb-6 xl:mb-8">
          {!isFetching ? (
            <EcoText fontSize={subtitleSize} color="enabled-inverted" className="leading-3xl lg:leading-8xl">
              To date, {merchantAdmin?.companyName} has offset
              <EcoText fontSize={subtitleSize} color="accent-algea">
                {` ${formatNumber(totalCarbonOffsetValue, { maximumFractionDigits: 0 })} ${getWeightUnit(locale || 'en')} `}
              </EcoText>
              <EcoText fontSize={subtitleSize} color="enabled-inverted">
                of carbon dioxide (CO<sub>2</sub>) across
              </EcoText>
              <EcoText fontSize={subtitleSize} color="accent-algea">
                {` ${formatNumber(totalOffsetOrderCount?.value || 0, { maximumFractionDigits: 0 })} orders`}
              </EcoText>
            </EcoText>
          ) : (
            <EcoSkeleton noOfLines={2} height={isAboveLGScreen ? '6xl' : '2xl'} spacing={isAboveLGScreen ? 6 : 2} mode="dark" />
          )}
        </View>
        <EcoText fontWeight="medium" fontSize="sm" color="enabled-inverted" className="uppercase mb-4 leading-md">
          Which is similar to offsetting:
        </EcoText>
      </Container>
      <View className="relative pl-4 md:pl-8 lg:pl-11 2xl:pl-0">
        <View className="w-full xl:max-w-[1180px] mx-auto overflow-visible">
          {showLeftArrow && (
            <Pressable
              onPress={handleScrollToStart}
              className="flex-row items-center justify-center rounded-full bg-secondary-500 w-[44px] h-[44px] absolute left-0 top-1/2 z-10 -translate-y-1/2"
              accessibilityLabel="scroll to start"
            >
              <EcoIcon name="arrow_back" className="text-white" />
            </Pressable>
          )}
          {showRightArrow && (
            <Pressable
              onPress={handleScrollToEnd}
              className="flex-row items-center justify-center rounded-full bg-secondary-500 w-[44px] h-[44px] absolute right-0 top-1/2 z-10 -translate-y-1/2 mr-4 md:mr-8 lg:mr-11"
              accessibilityLabel="scroll to end"
            >
              <EcoIcon name="arrow_forward" className="text-white" />
            </Pressable>
          )}
          <ScrollView horizontal ref={scrollViewRef} onScroll={handleScroll} scrollEventThrottle={16} onLayout={handleScrollViewLayout}>
            <View className="flex-row w-min" style={{ gap }}>
              {cardConfig.map(({ title, body, iconName, tooltip }) => (
                <Card title={title} body={body} iconName={iconName} key={body} tooltip={tooltip} />
              ))}
            </View>
          </ScrollView>
        </View>
      </View>
    </View>
  );
}
