export type UserEvt = 'login' | 'initiate_shopify_login' | 'signup_complete' | 'ecocart_settings' | 'project_list' | 'select_project';

export const logUserEvt = (evt: UserEvt, params?: Record<string, any>): void => {
  if (!evt) return;

  window.dataLayer.push({
    event: evt,
    user_type: window.session?.user?.type || 'n/a',
    shop_name: window.session?.merchantAdmin?.shopName || 'n/a',
    ...params
  });
};
