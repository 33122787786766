import { useWindow } from '@hooks/useWindow';
import { Gap } from '@utils/layout';
import { GlobalStylesProps } from '@utils/prop-types';
import { colors } from '@utils/tailwind';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { StyleProp, View } from 'react-native';
import { EcoHeadings } from './EcoHeadings';
import { EcoLoader } from './EcoLoader';

interface Props extends GlobalStylesProps {
  children: ReactNode;
  style?: StyleProp<any>;
  title?: string;
  subTitle?: string;
  isLoading?: boolean;
}

export function EcoTabContent({ title, subTitle, children, isLoading, style }: Props): JSX.Element {
  const { isDesktopWeb } = useWindow();

  return (
    <View className={clsx('shrink bg-white p-7', { 'rounded-sm rounded-tl-none': isDesktopWeb })} style={[Gap(7), style]}>
      {(title || subTitle) && (
        <View className="shrink">
          <EcoHeadings title={title} subTitle={subTitle} />
        </View>
      )}
      {isLoading ? <EcoLoader size="large" color={colors.primary[400]} className="py-9" /> : children}
    </View>
  );
}
