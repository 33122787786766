import React, { useState } from 'react';
import { Pressable, View } from 'react-native';
import { EcoIcon } from './EcoIcon';
import { EcoInput, EcoInputProps } from './EcoInput';

interface FormPasswordProps extends EcoInputProps {
  value: string;
  label: string;
  autoFocus?: boolean;
  onChangeText?: any;
  placeholder?: string;
  onBlur?: any;
}

export function FormPassword({ label, value, autoFocus, onChangeText, placeholder, onBlur, ...rest }: FormPasswordProps): JSX.Element {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <View className="relative">
      <EcoInput
        endAdornment={
          <Pressable onPress={toggleShowPassword}>
            <EcoIcon className="text-gray-600" name={showPassword ? 'visibility' : 'visibility_off'} />
          </Pressable>
        }
        label={label || 'Password'}
        value={value}
        autoFocus={autoFocus}
        onChangeText={onChangeText}
        onBlur={onBlur}
        placeholder={placeholder || 'Password'}
        secureTextEntry={!showPassword}
        style={{ minHeight: 0 }}
        placeholderTextColor={'rgba(0, 0, 0, .5)'}
        {...rest}
      />
    </View>
  );
}
