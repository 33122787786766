import { colors } from '@utils/tailwind';
import { View } from 'react-native';
import { EcoLoader } from './EcoLoader';

interface Props {}

export function EcoLoadingScreen({}: Props): JSX.Element {
  return (
    <View className="justify-center items-center flex-row w-full h-full">
      <EcoLoader size="large" color={colors.primary[400]} />
    </View>
  );
}
