import { EcoButton, EcoCheckbox, EcoIcon, EcoImage, EcoLink, EcoText } from '@components/shared';
import { convertUriToBlob } from '@components/uploaders/MerchantFileUploader';
import { IMAGE_ASSETS } from '@ecocart/universal-utils';
import { useOverlay } from '@hooks/useOverlay';
import { initialFormValues, useClaimFormContext } from '@screens/ClaimsScreen';
import { useMutation } from '@tanstack/react-query';
import { submitClaim } from '@utils/api/claim';
import { Gap } from '@utils/layout';
import { colors } from '@utils/tailwind';
import { CreateValidationSchema } from '@utils/validation';
import { useFormik } from 'formik';
import { useState } from 'react';
import { View } from 'react-native';
import { ClaimIssueType, ClaimResolutionType } from '../../utils/constants/claims';

export const ClaimsFormReview = (): JSX.Element => {
  const { setStep, claimDetails, setClaimDetails } = useClaimFormContext();
  const { handleError } = useOverlay();
  const [confirm, setConfirm] = useState(false);

  const { mutate, isLoading } = useMutation(submitClaim, {
    onSuccess: async () => {
      setClaimDetails(initialFormValues);
      setStep('Confirmation');
    },
    onError: (error: Error) => {
      const validationError = JSON.parse(error.message);
      handleError(validationError.Message);
    }
  });

  const onSubmit = async () => {
    if (!claimDetails.issue || !claimDetails.resolution) return;

    const formData = new FormData();
    formData.append('customer_email', claimDetails.email);
    formData.append('order_number', claimDetails.orderNumber);
    formData.append('description', claimDetails.issueDetails);
    formData.append('issue_type', claimDetails.issue);
    formData.append('resolution_type', claimDetails.resolution);

    if (claimDetails.file && claimDetails.localFileUri) {
      const fileBlob = await convertUriToBlob(claimDetails.localFileUri);
      formData.append('evidence', fileBlob, claimDetails.file.name);
    }

    mutate(formData);
  };

  const formik = useFormik({
    initialValues: {
      resolution: claimDetails.resolution
    },
    onSubmit,
    validateOnChange: true,
    validationSchema: CreateValidationSchema(['resolution'])
  });

  return (
    <>
      <View>
        <View className="mt-6" style={Gap(1)}>
          <EcoText fontSize="4xl" fontWeight="semibold" textAlign="center">
            Please double check before submitting your claim
          </EcoText>
        </View>
      </View>

      <View className="my-6 min-h-[250px] justify-center">
        <form onSubmit={formik.handleSubmit}>
          <View style={Gap(6)}>
            <View style={Gap(2)}>
              <EcoText fontSize="base" color="subdued">
                What's wrong with the package?
              </EcoText>
              <View className="flex-row justify-between items-center">
                <View className="flex-row justify-between items-center w-full">
                  {claimDetails.issue ? <EcoText fontSize="base">{ClaimIssueType[claimDetails.issue]}</EcoText> : null}
                  <EcoButton onPress={() => setStep('IssueItem')} variant="ghost" size="sm">
                    <EcoIcon name="border_color" className="text-subdued" size="3xl" />
                  </EcoButton>
                </View>
              </View>
            </View>
            <View style={Gap(2)}>
              <EcoText fontSize="base" color="subdued">
                Your resolution preference
              </EcoText>
              <View className="flex-row justify-between items-center">
                <View className="flex-row justify-between items-center w-full">
                  <View className="flex-row items-center" style={Gap()}>
                    {claimDetails.resolution ? (
                      <>
                        <EcoIcon name={ClaimResolutionType[claimDetails.resolution].iconName} size="4xl" />
                        <EcoText fontSize="base">{ClaimResolutionType[claimDetails.resolution].label}</EcoText>
                      </>
                    ) : null}
                  </View>
                  <EcoButton onPress={() => setStep('Resolution')} variant="ghost" size="sm">
                    <EcoIcon name="border_color" className="text-subdued" size="3xl" />
                  </EcoButton>
                </View>
              </View>
            </View>
            <View style={Gap(2)} className="flex-row items-start">
              <EcoCheckbox color={colors.gray[500]} value={confirm} onChange={() => setConfirm(!confirm)} />
              <EcoText fontSize="base" color="subdued">
                I confirm this information is accurate and agree to Ecocart's{' '}
                <EcoLink href="https://ecocart.io/terms-conditions/">
                  <EcoText fontSize="base" color="link">
                    terms and conditions
                  </EcoText>
                </EcoLink>
              </EcoText>
            </View>

            <View>
              <EcoButton isDisabled={!confirm} isLoading={isLoading} onPress={formik.handleSubmit} fullWidth className="mt-4">
                Submit Claim
              </EcoButton>
              <EcoButton variant="ghost" onPress={() => setStep('Resolution')} fullWidth className="mt-4">
                Back
              </EcoButton>
            </View>
          </View>
        </form>
      </View>
      <View className="flex-row justify-center items-center mt-8">
        <EcoText fontSize="xs">Powered By</EcoText>
        <EcoImage src={IMAGE_ASSETS.ECOCART} height={14} width={100} resizeMode="contain" />
      </View>
    </>
  );
};
