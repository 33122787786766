import { EcoText } from '@components/shared';
import { Gap } from '@utils/layout';
import { colors } from '@utils/tailwind';
import clsx from 'clsx';
import { capitalize, isPlainObject } from 'lodash';
import { View } from 'react-native';

import { DemoContainer } from '../views/DemoContainer';
import { InternalToolsCard } from '../views/InternalToolsCard';

/**
 * +++++ These are the missing colors in the page because they are not being used anywhere in the app,
 *       and also because tailwind doesn't support dynamic classname generation +++++
 * bg-primary-50 bg-primary-100 bg-primary-200 bg-primary-600 bg-primary-700 bg-primary-800 bg-primary-900
 * bg-secondary-100 bg-secondary-300 bg-secondary-800
 * bg-black
 * bg-tertiary-50 bg-tertiary-300 bg-tertiary-400 bg-tertiary-500 bg-tertiary-600 bg-tertiary-700 bg-tertiary-900 bg-tertiary
 * bg-algea-50 bg-algea-100 bg-algea-200 bg-algea-300 bg-algea-400 bg-algea bg-algea-600 bg-algea-700 bg-algea-800 bg-algea-900
 * bg-gray-300 bg-gray-500 bg-gray-700 bg-gray-800 bg-gray-900 bg-gray
 * bg-success-200 bg-success-300 bg-success-400 bg-success-800 bg-success-900
 * bg-warning-200 bg-warning-300 bg-warning-400 bg-warning-800 bg-warning-900
 * bg-danger-200 bg-danger-400 bg-danger-900 bg-danger
 * bg-inherit bg-transparent
 * bg-current
 */

export function ColorPalette(): JSX.Element {
  const defaultClassName = 'rounded-sm py-3 px-4 w-[100px] lg:w-[150px] h-[50px]';
  const colorKeys = Object.keys(colors);

  return (
    <InternalToolsCard componentName="Color Palette">
      <DemoContainer>
        {/* Actual demo component */}
        <View className="flex-row flex-wrap" style={Gap(8)}>
          {colorKeys.map((color, idx) => {
            if (!isPlainObject(colors[color]))
              return (
                <View style={Gap(2)} key={`color-${color}-${idx}`} className="w-full lg:w-5/12">
                  <EcoText fontWeight="semibold">{capitalize(color)}</EcoText>
                  <View className="flex-row items-center" style={Gap(2)}>
                    <View className={clsx(`bg-${color}`, defaultClassName)} />
                    <EcoText className="min-w-[100px]">{colors[color]}</EcoText>
                    <EcoText>{color}</EcoText>
                  </View>
                </View>
              );
            return (
              <View style={Gap(2)} key={`color-${color}`} className="w-full lg:w-5/12">
                <EcoText fontWeight="semibold">{capitalize(color)}</EcoText>
                {Object.keys(colors[color]).map((shade, idx) => {
                  const className = shade === 'DEFAULT' ? `bg-${color}` : `bg-${color}-${shade}`;

                  return (
                    <View className="flex-row items-center" key={`color-${color}-${shade}-${idx}`} style={Gap(2)}>
                      <View className={clsx(className, defaultClassName)} />
                      <EcoText className="min-w-[100px]">{`${colors[color][shade]}`}</EcoText>
                      <EcoText>{`${color}/${shade}`}</EcoText>
                    </View>
                  );
                })}
              </View>
            );
          })}
        </View>
        {/* End of actual demo component */}
      </DemoContainer>
    </InternalToolsCard>
  );
}
