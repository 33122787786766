import { EcoIcon, EcoLink } from '@components/shared';
import { EcoText } from '@components/shared/EcoText';
import { Gap } from '@utils/layout';
import React from 'react';
import { Pressable } from 'react-native';
import { nativeID } from '../utils/types/nativeID';
import { RoutePath } from './LinkingConfiguration';

interface Props {
  href?: RoutePath;
  label: string;
  nativeID?: nativeID;
  onPress?: () => void;
  params?: Record<string, string>;
}

export function BackLink({ href, label, nativeID, onPress = () => {}, params }: Props): JSX.Element {
  if (href)
    return (
      <EcoLink nativeID={nativeID} href={href} className="flex-row items-center pb-4" params={params} style={Gap(1)}>
        <EcoIcon name="arrow_back" className="text-secondary-500" />
        <EcoText color="accent-secondary">{label}</EcoText>
      </EcoLink>
    );
  return (
    <Pressable onPress={onPress} className="flex-row items-center" style={Gap(1)}>
      <EcoIcon name="arrow_back" className="text-secondary-500" />
      <EcoText color="accent-secondary">{label}</EcoText>
    </Pressable>
  );
}
