import { apiCall, SLIM_API } from './api';

// TODO have the uploadMerchantFile method return the url for the uploaded logo
export const uploadMerchantFile = (file: FormData, shopName: string): Promise<string> => {
  return apiCall<string>('PUT', `${SLIM_API}/merchant/${shopName}/upload`, file, {
    'Content-Type': 'multipart/form-data'
  });
};

export const uploadClaimsFile = (file: FormData, shopName: string): Promise<string> => {
  return apiCall<string>('PUT', `${SLIM_API}/merchant/${shopName}/claims-upload`, file, {
    'Content-Type': 'multipart/form-data'
  });
};
