import CurrentInvoiceCard from '@components/cards/CurrentInvoiceCard';
import InvoicesCard from '@components/cards/InvoicesCard';
import PaymentMethodsCard from '@components/cards/PaymentMethodsCard';
import { MerchantAdmin } from '@ecocart/entities';
import { useGlobal } from '@hooks/useGlobal';
import { useQuery } from '@tanstack/react-query';
import { CurrentInvoice, getPaymentMethods, hasAtLeastOnePaymentMethod, hasAtLeastOneValidPaymentMethod } from '@utils/api/payments';
import { Gap } from '@utils/layout';
import { useEffect, useState } from 'react';
import { View } from 'react-native';

interface Props {
  merchantAdmin: MerchantAdmin;
  currentInvoice?: CurrentInvoice;
  readOnly?: boolean;
}

export function BillingDashboard({ merchantAdmin, currentInvoice, readOnly = false }: Props): JSX.Element {
  const { shopName } = merchantAdmin || {};
  const {
    data: paymentMethods,
    isLoading: paymentMethodsAreLoading,
    refetch: refetchPaymentMethods
  } = useQuery(['getPaymentMethods', shopName], () => getPaymentMethods(shopName));
  const { session, statuses, setStatuses } = useGlobal();
  const { user } = session || {};
  // this is to help show the primary payment method in the custom invoice confirmation form
  const [primaryPaymentMethod, setPrimaryPaymentMethod] = useState('');

  useEffect(() => {
    // this is a check for userType who are required to have a paymentMethod
    if (paymentMethodsAreLoading || user?.userType === 'ecocart_admin') return;

    const isBillingEnabled = hasAtLeastOnePaymentMethod(paymentMethods);
    const hasAValidPaymentMethod = hasAtLeastOneValidPaymentMethod(paymentMethods);

    setStatuses({ ...statuses, isBillingEnabled, hasAValidPaymentMethod });
  }, [paymentMethodsAreLoading, paymentMethods]);

  return (
    <View style={Gap(6)}>
      <CurrentInvoiceCard merchantAdmin={merchantAdmin} currentInvoice={currentInvoice} />
      <InvoicesCard
        className="w-full"
        shopName={shopName}
        primaryPaymentMethod={primaryPaymentMethod}
        paymentMethods={paymentMethods}
        refetchPaymentMethods={refetchPaymentMethods}
      />
      <PaymentMethodsCard
        shopName={shopName}
        readOnly={readOnly}
        paymentMethods={paymentMethods}
        paymentMethodsAreLoading={paymentMethodsAreLoading}
        refetchPaymentMethods={refetchPaymentMethods}
        setPrimaryPaymentMethod={setPrimaryPaymentMethod}
      />
    </View>
  );
}
