import {
  EcoAccordion,
  EcoAlert,
  EcoButton,
  EcoCard,
  EcoInput,
  EcoRadioButtons,
  EcoText,
  EcoToggle,
  EcoWidget,
  FormPicker
} from '@components/shared';
import { ConfigContainer } from '@components/views/ConfigContainer';
import { LOCALES, Locale, Merchant } from '@ecocart/entities';
import { IMAGE_ASSETS, translations } from '@ecocart/universal-utils';
import { useFeaturePermission } from '@hooks/useFeaturePermission';
import { useOverlay } from '@hooks/useOverlay';
import { BackLink } from '@navigation/BackLink';
import { useNavigation } from '@react-navigation/native';
import { MerchantContext } from '@screens/MerchantWidgetsScreen';
import { updateMerchant } from '@utils/api/merchant';
import { Gap } from '@utils/layout';
import { colors } from '@utils/tailwind';
import { CreateValidationSchema } from '@utils/validation';
import { useFormik } from 'formik';
import { get } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { CheckMarkColor, CheckboxPosition, ImpactBoostActiveFontColor, Theme, getInitialValues } from './CheckoutWidgetDesigner.utils';
import {
  MOCK_CALCULATION,
  MOCK_CART,
  alignmentItems,
  alignmentItemsNoneOrCenter,
  convertCountryCodeToEnglishName,
  fieldPaths,
  getProps,
  getSelectedAlignmentValue,
  getTranslationPath,
  getZIndexProps,
  handleAlignmentChange,
  handleTypeCaseChange,
  typeCaseItems
} from './utils';

export function CheckoutWidgetDesigner({ widgetKey }: { widgetKey: string }): JSX.Element {
  const { showSuccess, handleError } = useOverlay();
  const { merchant, refetch } = useContext(MerchantContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { widgetMap } = merchant || {};
  const initialValues = widgetMap?.[widgetKey] || getInitialValues(merchant);
  const isHeaderWhite = get(initialValues, fieldPaths.headerColor) === colors.white;
  const [theme, setTheme] = useState<Theme>(isHeaderWhite ? 'dark' : 'light');
  const isCheckMarkBlack = get(initialValues, fieldPaths.checkMarkColor) === colors.black;
  const [checkMarkColor, setCheckMarkColor] = useState<CheckMarkColor>(isCheckMarkBlack ? 'black' : 'white');
  const isValidWidgetKey = widgetMap && widgetKey in widgetMap;
  const navigation = useNavigation();
  const [checkboxPosition, setCheckboxPosition] = useState<CheckboxPosition>('left');
  const { isFeaturePermissioned } = useFeaturePermission();
  const isABTest = widgetMap?.[widgetKey]?.template.includes('ab_test');

  if (merchant && !isValidWidgetKey) {
    navigateToWidgetList();
  }

  function navigateToWidgetList() {
    if (!merchant) return;
    return navigation.navigate('MerchantWidgetTemplates', { shopName: merchant?.shopName, template: 'checkout-widget' });
  }

  const onSubmit = async () => {
    if (!merchant) return;

    setIsSubmitting(true);

    const updatedMerchant: Partial<Merchant> = {
      widgetMap: {
        ...merchant.widgetMap,
        [widgetKey]: values
      }
    };

    try {
      await updateMerchant(merchant.shopName, updatedMerchant, merchant);
      showSuccess('Successfully updated merchant');
      setIsSubmitting(false);
      refetch && refetch();
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validateOnChange: true,
    validationSchema: CreateValidationSchema(['elements']),
    enableReinitialize: true
  });
  const { values, errors, handleChange, setFieldValue, isValid, handleSubmit } = formik;

  function handleThemeChange(val: Theme) {
    if (val === theme) return;

    setTheme(val);
    const isDarkTheme = val === 'dark';
    setFieldValue(fieldPaths.headerColor, isDarkTheme ? colors.white : undefined);
    setFieldValue(fieldPaths.subHeaderColor, isDarkTheme ? colors.white : undefined);
    setFieldValue(fieldPaths.containerBorderColor, isDarkTheme ? colors.gray[300] : undefined);
    setFieldValue(fieldPaths.impactBoostFontColor, isDarkTheme ? colors.white : undefined);
    setFieldValue(fieldPaths.footerColor, isDarkTheme ? colors.gray[400] : undefined);
    setFieldValue(fieldPaths.checkMarkColor, undefined);
    setFieldValue(fieldPaths.checkMarkBackgroundColor, undefined);
    handleChange(fieldPaths.logoURL)(isDarkTheme ? IMAGE_ASSETS.ECOCART_COLOR_WHITE : IMAGE_ASSETS.ECOCART);
  }

  useEffect(() => {
    const isCheckMarkWhite = checkMarkColor === 'white';
    setFieldValue(fieldPaths.checkMarkColor, isCheckMarkWhite ? colors.white : colors.black);
  }, [checkMarkColor, setFieldValue, handleChange]);

  const subHeaderEnabled = get(values, fieldPaths.subHeaderDisabled) !== true;
  // Impact Boost aka Upsell is disabled by default
  const upsellEnabled = get(values, fieldPaths.impactBoostDisabled) === false;
  const priceEnabled = get(values, fieldPaths.amountDisabled) !== true;
  const showUpsellToggle = isFeaturePermissioned && merchant?.payorMode === 'customer_paying';

  //Social Proof
  const socialProofEnabled = get(values, fieldPaths.socialProofDisabled) === false;

  const getBodyPlaceholder = (locale: Locale) => {
    switch (merchant?.payorMode) {
      case 'customer_paying':
        return translations[locale].template_offsetTwiceAsMuchCarbonAsYourOrderEmits;
      case 'merchant_matching':
        return translations[locale].template_merchantWillDoubleTheImpactByMatching;
      case 'merchant_paying':
        return translations[locale].template_merchantWillOffsetTwiceAsMuch;
      case 'customer_matching':
        return translations[locale].template_merchantIsOffsettingYourOrdersCarbonFootprintMatchTheirContributionDouble;
      default:
        return '';
    }
  };

  useEffect(() => {
    setFieldValue(fieldPaths.checkBoxOrder, checkboxPosition === 'right' ? '2' : '');
  }, [checkboxPosition, setFieldValue]);

  return (
    <>
      <View className="pb-4">
        <BackLink onPress={navigateToWidgetList} label="Back To Widgets" />
      </View>
      <View style={Gap()}>
        <EcoCard style={theme === 'dark' ? { backgroundColor: colors.black } : {}}>
          {isABTest && <EcoAlert variant="warning" title="This is an AB Test widget" closeable={false} className="mb-3" />}
          <EcoText fontSize="xl" fontWeight="semibold" className="mb-2" color={theme === 'dark' ? 'enabled-inverted' : undefined}>
            Preview
          </EcoText>
          <View>
            {values && merchant && <EcoWidget widget={values} merchant={merchant} cart={MOCK_CART} calculation={MOCK_CALCULATION} />}
          </View>
        </EcoCard>
        <EcoCard>
          <ConfigContainer>
            {/* Configuration component */}
            <form onSubmit={formik.handleSubmit}>
              <View style={Gap(8)}>
                <View style={Gap(6)}>
                  <View style={Gap()} className="border-gray-200 border rounded-sm p-4">
                    <View style={Gap()}>
                      <EcoText fontSize="xl" fontWeight="medium">
                        Widget Name
                      </EcoText>
                      <EcoInput label="Name" {...getProps(formik, fieldPaths.name)} />
                    </View>

                    <View style={Gap()}>
                      <EcoText fontSize="lg" fontWeight="medium">
                        Theme
                      </EcoText>
                      <EcoRadioButtons
                        value={theme}
                        items={[
                          { label: 'Light Mode', value: 'light' },
                          { label: 'Dark Mode', value: 'dark' }
                        ]}
                        onValueChange={handleThemeChange}
                      />
                    </View>
                  </View>

                  <View style={Gap()} className="border-gray-200 border rounded-sm p-4">
                    <EcoText fontSize="xl" fontWeight="medium">
                      Container
                    </EcoText>
                    <View style={Gap(4)}>
                      <View>
                        <EcoText fontSize="lg" fontWeight="medium">
                          Style
                        </EcoText>
                        <View className="flex-row flex-wrap" style={Gap()}>
                          <EcoInput
                            className="flex-1"
                            label="Background Color"
                            {...getProps(formik, fieldPaths.containerBackgroundColor)}
                            placeholder="#FFFFFF"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Border Width"
                            {...getProps(formik, fieldPaths.containerBorderWidth)}
                            placeholder="1px"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Border Color"
                            {...getProps(formik, fieldPaths.containerBorderColor)}
                            placeholder="#0F8354"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Border Radius"
                            {...getProps(formik, fieldPaths.containerBorderRadius)}
                            placeholder="4px"
                          />
                        </View>
                      </View>
                      <View style={Gap(2)}>
                        <EcoText fontSize="lg" fontWeight="medium">
                          Behavior
                        </EcoText>
                        <View style={Gap(4)}>
                          <View>
                            <EcoText fontSize="base" fontWeight="medium">
                              Mobile
                            </EcoText>
                            <View className="flex-row flex-wrap" style={Gap()}>
                              <EcoInput
                                className="flex-1"
                                label="Margins"
                                {...getProps(formik, fieldPaths.containerMargin)}
                                placeholder="0px"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Padding"
                                {...getProps(formik, fieldPaths.containerPadding)}
                                placeholder="12px"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Width"
                                {...getProps(formik, fieldPaths.wrapperWidth)}
                                placeholder="100px, 50%, etc"
                              />
                              <View>
                                <FormPicker
                                  inputClassName="flex-1"
                                  label="Default Alignment"
                                  items={alignmentItems}
                                  value={getSelectedAlignmentValue(values, 'wrapper', 'mobile')}
                                  onValueChange={(val) => handleAlignmentChange(formik, val, 'mobile')}
                                  hasError={get(errors, fieldPaths.wrapperDisplay) || get(errors, fieldPaths.wrapperJustifyContent)}
                                />
                              </View>
                              <EcoInput
                                className="flex-1"
                                label="Z-Index"
                                {...getZIndexProps(formik, fieldPaths.wrapperZIndex)}
                                placeholder="10, 20, 100, etc"
                              />
                            </View>
                          </View>
                          <View>
                            <EcoText fontSize="base" fontWeight="medium">
                              Desktop
                            </EcoText>
                            <View className="flex-row flex-wrap" style={Gap()}>
                              <EcoInput
                                className="flex-1"
                                label="Margins"
                                {...getProps(formik, fieldPaths.containerMarginMD)}
                                placeholder="0px"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Padding"
                                {...getProps(formik, fieldPaths.containerPaddingMD)}
                                placeholder="12px"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Width"
                                {...getProps(formik, fieldPaths.wrapperWidthMD)}
                                placeholder="100px, 50%, etc"
                              />
                              <View>
                                <FormPicker
                                  inputClassName="flex-1"
                                  label="Default Alignment"
                                  items={alignmentItems}
                                  value={getSelectedAlignmentValue(values, 'wrapper', 'desktop')}
                                  onValueChange={(val) => handleAlignmentChange(formik, val, 'desktop', 'wrapper')}
                                  hasError={get(errors, fieldPaths.wrapperDisplayMD) || get(errors, fieldPaths.wrapperJustifyContentMD)}
                                />
                              </View>
                              <EcoInput
                                className="flex-1"
                                label="Z-Index"
                                {...getZIndexProps(formik, fieldPaths.wrapperZIndexMD)}
                                placeholder="10, 20, 100, etc"
                              />
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>

                  {!isABTest && (
                    <>
                      <View style={Gap(2)} className="border-gray-200 border rounded-sm p-4">
                        <EcoText fontSize="xl" fontWeight="medium">
                          Content
                        </EcoText>
                        <View style={Gap()}>
                          <View>
                            <EcoText fontWeight="medium">Alignment</EcoText>
                            <FormPicker
                              className="w-1/5"
                              label="Content Alignment"
                              items={alignmentItemsNoneOrCenter}
                              value={getSelectedAlignmentValue(values, 'container', 'mobile')}
                              onValueChange={(val) => handleAlignmentChange(formik, val, 'mobile', 'container')}
                            />
                          </View>
                          <View>
                            <View>
                              <EcoText fontWeight="medium">Checkbox</EcoText>
                              <View style={Gap()}>
                                <View className="flex-row" style={Gap()}>
                                  <EcoInput
                                    className="flex-1"
                                    label="Checkbox Fill"
                                    {...getProps(formik, fieldPaths.checkMarkBackgroundColor)}
                                    placeholder={colors.primary[400]}
                                  />
                                  <EcoInput
                                    className="flex-1"
                                    label="Checkbox Border Color"
                                    {...getProps(formik, fieldPaths.checkMarkBorderColor)}
                                    placeholder={colors.gray[800]}
                                  />
                                </View>
                                <View style={Gap()}>
                                  <View style={Gap(4)}>
                                    <EcoText fontSize="sm" className="mt-1">
                                      Checkmark Color
                                    </EcoText>
                                    <EcoRadioButtons
                                      value={checkMarkColor}
                                      items={[
                                        { label: 'White', value: 'white' },
                                        { label: 'Black', value: 'black' }
                                      ]}
                                      onValueChange={(val: CheckMarkColor) => setCheckMarkColor(val)}
                                    />
                                  </View>
                                  <View style={Gap()}>
                                    <EcoText fontSize="sm" className="mt-1">
                                      Checkbox Position
                                    </EcoText>
                                    <EcoRadioButtons
                                      value={checkboxPosition}
                                      items={[
                                        { label: 'Left', value: 'left' },
                                        { label: 'Right', value: 'right' }
                                      ]}
                                      onValueChange={(val: CheckboxPosition) => setCheckboxPosition(val)}
                                    />
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>

                      <View style={Gap(2)} className="border-gray-200 border rounded-sm p-4">
                        <EcoText fontSize="xl" fontWeight="medium">
                          Header
                        </EcoText>
                        <View style={Gap()}>
                          <View>
                            <EcoText fontWeight="medium">Content</EcoText>
                            <EcoInput
                              className="flex-1 mb-3 md:mr-3 md:mb-0"
                              label="Header - English"
                              {...getProps(formik, getTranslationPath('header', 'en'))}
                              placeholder={translations.en.template_reduceMyOrderCO2}
                            />
                          </View>
                          <EcoAccordion header="Show Translations">
                            {LOCALES.map((locale) => (
                              <EcoInput
                                key={`header-${locale}`}
                                className="flex-1"
                                label={`Header - ${convertCountryCodeToEnglishName(locale)}`}
                                {...getProps(formik, getTranslationPath('header', locale))}
                                placeholder={translations[locale].template_reduceMyOrderCO2}
                              />
                            ))}
                          </EcoAccordion>
                        </View>

                        <View>
                          <EcoText fontWeight="medium">Style</EcoText>
                          <View className="flex-row" style={Gap()}>
                            <EcoInput
                              className="flex-1"
                              label="Font Family"
                              {...getProps(formik, fieldPaths.headerFontFamily)}
                              placeholder="system-ui"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Size"
                              {...getProps(formik, fieldPaths.headerFontSize)}
                              placeholder="14px"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Line Height"
                              {...getProps(formik, fieldPaths.headerLineHeight)}
                              placeholder="16px"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Weight"
                              {...getProps(formik, fieldPaths.headerFontWeight)}
                              placeholder="300, 500, bold..."
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Color"
                              {...getProps(formik, fieldPaths.headerColor)}
                              placeholder="#333333"
                            />
                            <FormPicker
                              label="Type Case"
                              items={typeCaseItems}
                              value={get(values, fieldPaths.headerTextTransform, '') as string}
                              onValueChange={(val) => handleTypeCaseChange(formik, val, 'header')}
                            />
                          </View>
                        </View>
                      </View>

                      <View style={Gap()} className="border-gray-200 border rounded-sm p-4">
                        <View className="flex-row items-center" style={Gap()}>
                          <EcoText fontSize="xl" fontWeight="medium">
                            Body
                          </EcoText>
                          <EcoToggle
                            value={subHeaderEnabled}
                            onValueChange={(val: boolean) => {
                              setFieldValue(fieldPaths.subHeaderDisabled, !val);
                            }}
                          />
                        </View>

                        {subHeaderEnabled && (
                          <View style={Gap()}>
                            <View>
                              <EcoText fontWeight="medium">Body Content</EcoText>

                              <EcoInput
                                className="flex-1 mb-2 md:mr-3"
                                label="Body - English"
                                {...getProps(formik, getTranslationPath('subHeader', 'en'))}
                                placeholder={getBodyPlaceholder('en')}
                              />
                              <EcoAccordion header="Show Translations">
                                {LOCALES.map((locale) => (
                                  <EcoInput
                                    key={`body-${locale}`}
                                    className="flex-1 mb-3 md:mr-3 md:mb-0"
                                    label={`Body - ${convertCountryCodeToEnglishName(locale)}`}
                                    {...getProps(formik, getTranslationPath('subHeader', locale))}
                                    placeholder={getBodyPlaceholder(locale)}
                                  />
                                ))}
                              </EcoAccordion>
                            </View>
                            <View>
                              <View>
                                <EcoText fontWeight="medium">Body Styles</EcoText>
                                <View className="flex-row" style={Gap()}>
                                  <EcoInput
                                    className="flex-1"
                                    label="Font Family"
                                    {...getProps(formik, fieldPaths.subHeaderFontFamily)}
                                    placeholder="Roboto"
                                  />
                                  <EcoInput
                                    className="flex-1"
                                    label="Font Size"
                                    {...getProps(formik, fieldPaths.subHeaderFontSize)}
                                    placeholder="11px"
                                  />
                                  <EcoInput
                                    className="flex-1"
                                    label="Line Height"
                                    {...getProps(formik, fieldPaths.subHeaderLineHeight)}
                                    placeholder="14px"
                                  />
                                  <EcoInput
                                    className="flex-1"
                                    label="Font Weight"
                                    {...getProps(formik, fieldPaths.subHeaderFontWeight)}
                                    placeholder="300, 500, bold..."
                                  />
                                  <EcoInput
                                    className="flex-1"
                                    label="Font Color"
                                    {...getProps(formik, fieldPaths.subHeaderColor)}
                                    placeholder="#636363"
                                  />
                                  <FormPicker
                                    label="Type Case"
                                    items={typeCaseItems}
                                    hasError={get(errors, fieldPaths.subHeaderTextTransform)}
                                    value={get(values, fieldPaths.subHeaderTextTransform, '') as string}
                                    onValueChange={(val) => handleTypeCaseChange(formik, val, 'subheader')}
                                  />
                                </View>
                              </View>
                            </View>
                          </View>
                        )}
                      </View>

                      <View style={Gap()} className="border-gray-200 border rounded-sm p-4">
                        <View className="flex-row" style={Gap()}>
                          <EcoText fontSize="xl" fontWeight="medium">
                            Price
                          </EcoText>
                          <EcoToggle
                            value={priceEnabled}
                            onValueChange={(val: boolean) => {
                              setFieldValue(fieldPaths.amountDisabled, !val);
                            }}
                          />
                        </View>

                        {priceEnabled && (
                          <View>
                            <EcoText fontWeight="medium">Style</EcoText>
                            <View className="flex-row flex-wrap" style={Gap()}>
                              <EcoInput
                                className="flex-1"
                                label="Font Family"
                                {...getProps(formik, fieldPaths.amountFontFamily)}
                                placeholder="Roboto"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Font Size"
                                {...getProps(formik, fieldPaths.amountFontSize)}
                                placeholder="11px"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Line Height"
                                {...getProps(formik, fieldPaths.amountLineHeight)}
                                placeholder="14px"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Font Weight"
                                {...getProps(formik, fieldPaths.amountFontWeight)}
                                placeholder="300, 500, bold..."
                              />
                              <EcoInput
                                className="flex-1"
                                label="Font Color"
                                {...getProps(formik, fieldPaths.amountColor)}
                                placeholder="#636363"
                              />
                            </View>
                          </View>
                        )}
                      </View>

                      {showUpsellToggle && (
                        <View style={Gap()} className="border-gray-200 border rounded-sm p-4">
                          <View className="flex-row" style={Gap()}>
                            <EcoText fontSize="xl" fontWeight="medium">
                              Impact Boost
                            </EcoText>
                            <EcoToggle
                              value={upsellEnabled}
                              onValueChange={(val: boolean) => {
                                setFieldValue(fieldPaths.impactBoostDisabled, !val);
                              }}
                            />
                          </View>

                          {upsellEnabled && (
                            <View style={Gap(5)}>
                              <View>
                                <EcoText fontWeight="medium" fontSize="lg">
                                  Selection Style
                                </EcoText>
                                <View className="flex-row" style={Gap()}>
                                  <View>
                                    <EcoInput
                                      className="flex-1"
                                      label="Fill Color"
                                      {...getProps(formik, fieldPaths.checkMarkBackgroundColor)}
                                      placeholder={colors.primary[400]}
                                    />
                                  </View>
                                  <View style={Gap()}>
                                    <EcoText fontSize="sm" className="mt-1">
                                      Text Color
                                    </EcoText>
                                    <EcoRadioButtons
                                      value={values?.elements?.upsell?.custom?.activeFontColor === colors.black ? 'black' : 'white'}
                                      items={[
                                        { label: 'White', value: 'white' },
                                        { label: 'Black', value: 'black' }
                                      ]}
                                      onValueChange={(val: ImpactBoostActiveFontColor) => {
                                        setFieldValue(fieldPaths.impactBoostActiveFontColor, val === 'black' ? colors.black : colors.white);
                                      }}
                                    />
                                  </View>
                                </View>
                              </View>

                              <View className="sm:w-1/2">
                                <EcoText fontWeight="medium" fontSize="lg">
                                  Button Style
                                </EcoText>
                                <View style={Gap()} className="flex-row">
                                  <EcoInput
                                    className="flex-1"
                                    label="Border Width"
                                    {...getProps(formik, fieldPaths.impactBoostBorderWidth)}
                                    placeholder="1px"
                                  />
                                  <EcoInput
                                    className="flex-1"
                                    label="Border Radius"
                                    {...getProps(formik, fieldPaths.impactBoostBorderRadius)}
                                    placeholder="0px, 0px, 4px, 4px"
                                  />
                                </View>
                              </View>
                            </View>
                          )}
                        </View>
                      )}

                      <View style={Gap()} className="border-gray-200 border rounded-sm p-4">
                        <EcoText fontSize="lg" fontWeight="medium">
                          Attribution
                        </EcoText>
                        <View style={Gap()} className="mb-2">
                          <EcoRadioButtons
                            value={get(values, fieldPaths.poweredByDisabled) === true ? 'simple' : 'standard'}
                            items={[
                              { label: 'Standard Attribution', value: 'standard' },
                              { label: 'Simple Attribution', value: 'simple' }
                            ]}
                            onValueChange={(value: 'simple' | 'standard') => {
                              setFieldValue(fieldPaths.poweredByDisabled, value === 'simple');
                            }}
                          />
                        </View>
                        <View style={Gap()}>
                          <EcoText fontWeight="medium">Logo</EcoText>
                          <EcoRadioButtons
                            value={values.elements?.logo?.custom?.url === IMAGE_ASSETS.ECOCART_BLACK ? 'mono_logo' : 'standard_logo'}
                            items={[
                              { label: 'Standard Logo', value: 'standard_logo' },
                              { label: 'Monochromatic Logo', value: 'mono_logo' }
                            ]}
                            onValueChange={(value: 'mono_logo' | 'standard_logo') =>
                              handleChange('elements.logo.custom.url')(
                                value === 'mono_logo' ? IMAGE_ASSETS.ECOCART_BLACK : IMAGE_ASSETS.ECOCART
                              )
                            }
                          />
                        </View>

                        <View>
                          <EcoText fontWeight="medium">Attribution Style</EcoText>
                          <View className="flex-row" style={Gap()}>
                            <EcoInput
                              className="flex-1"
                              label="Font Family"
                              {...getProps(formik, fieldPaths.footerFontFamily)}
                              placeholder="system-ui"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Size"
                              {...getProps(formik, fieldPaths.footerFontSize)}
                              placeholder="11px"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Line Height"
                              {...getProps(formik, fieldPaths.footerLineHeight)}
                              placeholder="14px"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Weight"
                              {...getProps(formik, fieldPaths.footerFontWeight)}
                              placeholder="300, 500, bold..."
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Color"
                              {...getProps(formik, fieldPaths.footerColor)}
                              placeholder="#F5F5F5"
                            />
                            <FormPicker
                              label="Type Case"
                              items={typeCaseItems}
                              value={get(values, fieldPaths.footerTextTransform, '') as string}
                              onValueChange={(val) => handleTypeCaseChange(formik, val, 'footer')}
                            />
                          </View>
                        </View>
                      </View>

                      <View style={Gap(4)} className="border-gray-200 border rounded-sm p-4">
                        <View className="flex-row" style={Gap()}>
                          <EcoText fontSize="xl" fontWeight="medium">
                            Social Proof
                          </EcoText>
                          <EcoToggle
                            value={socialProofEnabled}
                            onValueChange={(val: boolean) => {
                              setFieldValue(fieldPaths.socialProofDisabled, !val);
                            }}
                          />
                        </View>
                        {socialProofEnabled && (
                          <View>
                            <View style={Gap(2)}>
                              <EcoText fontSize="xl" fontWeight="medium">
                                Content
                              </EcoText>

                              <EcoText fontSize="lg" fontWeight="medium">
                                Header Style
                              </EcoText>
                            </View>

                            <View className="flex-row" style={Gap()}>
                              <EcoInput
                                label="Font Family"
                                {...getProps(formik, fieldPaths.socialProofFontFamily)}
                                placeholder="system-ui"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Font Size"
                                {...getProps(formik, fieldPaths.socialProofFontSize)}
                                placeholder="11px"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Line Height"
                                {...getProps(formik, fieldPaths.socialProofLineHeight)}
                                placeholder="14px"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Font Weight"
                                {...getProps(formik, fieldPaths.socialProofFontWeight)}
                                placeholder="300, 500, bold..."
                              />
                              <EcoInput
                                className="flex-1"
                                label="Font Color"
                                {...getProps(formik, fieldPaths.socialProofFontColor)}
                                placeholder="#F5F5F5"
                              />
                              <FormPicker
                                label="Type Case"
                                items={typeCaseItems}
                                value={get(values, fieldPaths.socialProofTextTransform, '') as string}
                                onValueChange={(val) => handleTypeCaseChange(formik, val, 'social_proof')}
                              />
                            </View>
                            <View className="pt-6 pb-2" style={Gap(4)}>
                              <EcoText fontSize="xl" fontWeight="medium">
                                Container
                              </EcoText>
                              <EcoText fontWeight="medium" fontSize="lg">
                                Behavior
                              </EcoText>
                            </View>

                            <EcoText fontWeight="medium">Mobile</EcoText>
                            <View className="flex-row flex-wrap" style={Gap()}>
                              <EcoInput
                                className="flex-1"
                                label="Margins"
                                {...getProps(formik, fieldPaths.socialProofMargin)}
                                placeholder="20px"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Padding"
                                {...getProps(formik, fieldPaths.socialProofPadding)}
                                placeholder="12px"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Width"
                                {...getProps(formik, fieldPaths.socialProofWidth)}
                                placeholder="100%"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Z-Index"
                                {...getProps(formik, fieldPaths.socialProofZIndex)}
                                placeholder="10, 20, 100, etc"
                              />
                            </View>
                            <EcoText className="pt-4" fontWeight="medium">
                              Desktop
                            </EcoText>
                            <View className="flex-row flex-wrap" style={Gap()}>
                              <EcoInput
                                className="flex-1"
                                label="Margins"
                                {...getProps(formik, fieldPaths.socialProofMarginMD)}
                                placeholder="20px"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Padding"
                                {...getProps(formik, fieldPaths.socialProofPaddingMD)}
                                placeholder="12px"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Width"
                                {...getProps(formik, fieldPaths.socialProofWidthMD)}
                                placeholder="100%"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Z-Index"
                                {...getProps(formik, fieldPaths.socialProofZIndexMD)}
                                placeholder="10, 20, 100, etc"
                              />
                            </View>
                            <EcoText className="pt-4" fontSize="lg" fontWeight="medium">
                              Style
                            </EcoText>
                            <View className="flex-row" style={Gap()}>
                              <EcoInput
                                className="flex-1"
                                label="Background Color"
                                {...getProps(formik, fieldPaths.socialProofBackgroundColor)}
                                placeholder="#F5F5F5"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Border Width"
                                {...getProps(formik, fieldPaths.socialProofBorderWidth)}
                                placeholder="1px"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Border Color"
                                {...getProps(formik, fieldPaths.socialProofBorderColor)}
                                placeholder="#FFFFFF"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Border Radius"
                                {...getProps(formik, fieldPaths.socialProofBorderRadius)}
                                placeholder="0px, 0px, 4px, 4px"
                              />
                            </View>
                          </View>
                        )}
                      </View>

                      <View style={Gap()} className="border-gray-200 border rounded-sm p-4">
                        <EcoText fontSize="lg" fontWeight="medium">
                          Learn More Modal
                        </EcoText>
                        <View style={Gap()}>
                          <View>
                            <EcoText fontWeight="medium">Content</EcoText>
                            <EcoInput
                              className="flex-1 mb-3 md:mr-3 md:mb-0"
                              label="Sub-header Text - English"
                              {...getProps(formik, getTranslationPath('modal_subheader', 'en'))}
                              placeholder={translations.en.template_ecocartPartnersWithCertifiedProjects}
                            />
                          </View>
                          <EcoAccordion header="Show Translations">
                            {LOCALES.map((locale) => (
                              <EcoInput
                                key={`body-${locale}`}
                                className="flex-1 mb-3 md:mr-3 md:mb-0"
                                label={`Sub-header Text - ${convertCountryCodeToEnglishName(locale)}`}
                                {...getProps(formik, getTranslationPath('modal_subheader', locale))}
                                placeholder={translations[locale].template_ecocartPartnersWithCertifiedProjects}
                              />
                            ))}
                          </EcoAccordion>
                        </View>
                      </View>
                    </>
                  )}
                </View>

                <EcoButton isDisabled={isSubmitting || !isValid} onPress={handleSubmit}>
                  Save
                </EcoButton>
              </View>
            </form>

            {/* End of Configuration component */}
          </ConfigContainer>
        </EcoCard>
      </View>
    </>
  );
}
