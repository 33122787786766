import { EcoAlert } from '@components/shared';
import { AlertProps } from '@components/shared/EcoAlert';
import { Experiment } from '@ecocart/entities';

interface Props extends AlertProps {
  experiment: Experiment;
}

export function WidgetABTestStatus({ experiment, primaryButton, secondaryButton, style }: Props): JSX.Element {
  if (!experiment?.key) return <></>;

  const variations =
    experiment.variations?.length > 1 && experiment.weights?.length > 1
      ? experiment.variations.map((variation, i) => `${variation} (${experiment.weights[i] * 100}%)`).join(' | ')
      : '';

  return (
    <EcoAlert
      title="Running A/B Test"
      subtitle={`${experiment.key} ${variations}`}
      variant="warning"
      closeable={false}
      shouldTruncate={false}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
    />
  );
}
