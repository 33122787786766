import { EcoButton, EcoCard, EcoDropdown, EcoIcon, EcoPill, EcoTable, EcoText, EcoTooltip } from '@components/shared';
import { useFetchAndZipFiles } from '@hooks/useFetchAndZipFiles';
import { useOverlay } from '@hooks/useOverlay';
import { useWindow } from '@hooks/useWindow';
import { useQuery } from '@tanstack/react-query';
import {
  Invoice,
  InvoiceStatus,
  PaymentMethods,
  getDefaultPaymentMethodLabel,
  getInvoiceCSVDownloadUrl,
  getInvoicePDFDownloadUrl,
  getInvoices,
  payInvoice
} from '@utils/api/payments';
import { Gap } from '@utils/layout';
import { format, parse } from 'date-fns';
import { startCase } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleProp, View } from 'react-native';

import { DATE_FORMAT } from '@components/dashboards/components/DateRangePicker.utils';
import InvoiceRefundForm from '@components/forms/InvoiceRefundForm';
import { EcoDropDownItem } from '@components/shared/EcoDropdown.utils';
import EcoEmptyState from '@components/shared/EcoEmptyState';
import { EcoPillVariant } from '@components/shared/EcoPill';
import { ColumnConfig } from '@components/shared/EcoTable.utils';
import { formatNumber } from '@ecocart/universal-utils';
import { useCustomInvoiceModal } from '@hooks/useCustomInvoiceModal';
import { useFeaturePermission } from '@hooks/useFeaturePermission';
import { useGlobal } from '@hooks/useGlobal';
import { usePaymentMethod } from '@hooks/usePaymentMethod';
import { HttpError } from '@utils/api/api';
import { ScrollView } from 'react-native-gesture-handler';

interface Props {
  shopName: string;
  paymentMethods: PaymentMethods | null | undefined;
  refetchPaymentMethods: () => void;
  style?: StyleProp<any>;
  className?: string;
  primaryPaymentMethod: string;
}

interface InvoiceUI extends Invoice {
  dateRange: string;
  cta: JSX.Element | undefined;
  more: JSX.Element | undefined;
}

function getDateRange(invoice: Invoice) {
  if (!invoice.start_date && !invoice.end_date) return '-';

  const startDate = parse(invoice.start_date, 'yyyy-MM-dd HH:mm:ss', new Date());
  const DATE_FORMAT = 'MMM dd, yy';

  if (!invoice.end_date) return format(startDate, DATE_FORMAT);

  const endDate = parse(invoice.end_date, 'yyyy-MM-dd HH:mm:ss', new Date());
  return format(startDate, DATE_FORMAT) + ' - ' + format(endDate, DATE_FORMAT);
}

const getInvoiceStatusPillVariant = (status: InvoiceStatus): EcoPillVariant => {
  switch (status) {
    case 'paid':
      return 'primary';
    case 'processing':
      return 'secondary';
    case 'failed':
    case 'unpaid':
    default:
      return 'danger';
  }
};

export default function InvoicesCard({ primaryPaymentMethod, shopName, paymentMethods, refetchPaymentMethods, style }: Props): JSX.Element {
  const { statuses, session } = useGlobal();
  const { isWide } = useWindow();
  const { data: invoices, isFetching, refetch, error } = useQuery(['getInvoices', shopName], () => getInvoices(shopName));
  const [selectedInvoices, setSelectedInvoices] = useState<Invoice[]>([]);
  const { showSuccess, showInfo, handleError, showModal, hideModal } = useOverlay();
  const [{ isFetching: isFetchingData }, fetchAndZipFiles] = useFetchAndZipFiles();
  const { showSelectPaymentMethodModal } = usePaymentMethod();
  const { showCustomInvoiceModal } = useCustomInvoiceModal();
  const { isFeaturePermissioned } = useFeaturePermission();
  const isEcoCartAdmin = session?.user.userType === 'ecocart_admin';

  const onRowClick = useCallback(
    (row: Invoice, isSelected: boolean) => {
      const newSelectedInvoices = new Set([...selectedInvoices]);
      if (isSelected) {
        newSelectedInvoices.add(row);
      } else {
        newSelectedInvoices.delete(row);
      }
      setSelectedInvoices([...newSelectedInvoices]);
    },
    [selectedInvoices]
  );

  const onSelectAllRowsClick = useCallback((selectedRows: Invoice[]) => {
    const newSelectedInvoices = new Set([...selectedRows]);
    setSelectedInvoices([...newSelectedInvoices]);
  }, []);

  const doPayInvoice = async (identifier: string) => {
    try {
      const successMessageStr = await payInvoice(shopName, identifier).then(JSON.stringify);
      if (successMessageStr.includes('processing')) {
        showInfo('Thank You! Your payment is processing.');
      } else {
        showSuccess('Thank You! Your Payment Has Been Processed.');
      }
      refetch();
    } catch (error: any) {
      handleError('Payment Failed. Please try again or enter a new payment method to ensure uninterrupted service.');
    }
  };

  const confirmPayNow = (invoice: Invoice) => {
    const defaultPaymentMethodLabel = getDefaultPaymentMethodLabel(paymentMethods);

    const onConfirm = () => {
      doPayInvoice(invoice.identifier);
      hideModal();
    };

    const onCancel = () => {
      hideModal();
    };

    showModal({
      nativeID: 'confirm-pay-invoice',
      content: (
        <EcoCard className="flex flex-col p-6 md:w-[525px]" style={Gap(4)}>
          <View className="flex flex-col items-center justify-center" style={Gap(1)}>
            <EcoText fontSize="2xl" fontWeight="semibold">
              Confirm Payment
            </EcoText>
            <EcoText fontSize="lg" color="subdued" textAlign="center">
              To switch to a different payment method, simply go back and edit your preferred payment option.
            </EcoText>
          </View>
          <View className="flex flex-row justify-between w-full mt-2">
            <EcoText fontSize="lg">Default Payment Method</EcoText>
            <EcoText fontSize="lg" color="subdued">
              {defaultPaymentMethodLabel}
            </EcoText>
          </View>
          <View className="flex flex-row justify-between w-full mb-2">
            <EcoText fontSize="lg">Invoice Amount:</EcoText>
            <EcoText fontSize="lg" fontWeight="semibold">
              {formatNumber(invoice.total_amount, { style: 'currency', currency: invoice.currency })}
            </EcoText>
          </View>
          <View className="flex flex-row" style={Gap(4)}>
            <EcoButton className="flex-1" variant="outlined" onPress={onCancel}>
              Cancel
            </EcoButton>
            <EcoButton className="flex-1" colorScheme="default" onPress={onConfirm}>
              Pay Invoice
            </EcoButton>
          </View>
        </EcoCard>
      )
    });
  };

  const handlePayInvoice = useCallback(
    async (invoice: Invoice): Promise<void> => {
      if (statuses.hasAValidPaymentMethod) {
        confirmPayNow(invoice);
      } else {
        showSelectPaymentMethodModal((paymentType) => {
          if (paymentType) {
            refetchPaymentMethods();
            confirmPayNow(invoice);
          }

          hideModal();
        });
      }
    },
    [handleError, refetch, shopName, paymentMethods, showSuccess, statuses.hasAValidPaymentMethod]
  );

  const handleRefundInvoice = (invoice: Invoice): void => {
    showModal({
      content: (
        <EcoCard title="Issue Refund" size="lg">
          <InvoiceRefundForm
            shopName={shopName}
            invoice={invoice}
            onCancel={hideModal}
            onSuccess={() => {
              hideModal();
              refetch();
            }}
          />
        </EcoCard>
      )
    });
  };

  const columnConfig: ColumnConfig<InvoiceUI>[] = useMemo(
    () => [
      {
        field: 'total_amount',
        title: 'Amount',
        flex: 1,
        align: 'flex-end',
        searchable: true,
        format: (invoice: Invoice) => formatNumber(invoice.total_amount, { style: 'currency', currency: invoice.currency })
      },
      { field: 'identifier', title: 'Invoice Number', searchable: true, flex: 3 },
      {
        field: 'end_date',
        searchable: true,
        title: 'Billing Period',
        flex: 2,
        format: (invoice: Invoice) => getDateRange(invoice)
      },
      {
        field: 'status',
        title: 'Status',
        flex: 2,
        format: (invoice: Invoice) => {
          return (
            <View style={Gap(2)} className="flex-row">
              <EcoPill variant={getInvoiceStatusPillVariant(invoice.status)} className="w-max">
                {startCase(invoice.status)}
              </EcoPill>
              {invoice.total_refunds_amount_usd ? (
                <EcoTooltip
                  tooltip={
                    <EcoText fontSize="sm" color="success-inverted">
                      Total Refund Amount: {formatNumber(invoice.total_refunds_amount_usd, { style: 'currency', currency: 'usd' })}
                    </EcoText>
                  }
                >
                  <EcoPill variant="warning" className="w-max">
                    {invoice.total_refunds_amount_usd < invoice.total_amount_usd ? 'Partial Refund' : 'Full Refund'}
                  </EcoPill>
                </EcoTooltip>
              ) : null}
            </View>
          );
        }
      },
      {
        field: 'cta',
        title: 'Action',
        flex: 1,
        format: (invoice: Invoice) =>
          ['failed', 'unpaid'].includes(invoice.status) ? (
            <EcoButton size="sm" onPress={() => handlePayInvoice(invoice)}>
              Pay Now
            </EcoButton>
          ) : null
      },
      {
        field: 'more',
        title: '',
        flex: 1,
        format: (invoice: Invoice) => {
          const isRefundButtonDisabled = !(invoice.stripe_invoice_id && invoice.total_amount_usd > invoice.total_refunds_amount_usd);
          const ecoDropdownItems: EcoDropDownItem[] = [
            {
              label: 'Download Transaction History',
              onPress: () => handleExport('csv', invoice)
            },
            {
              label: 'Download Invoice',
              onPress: () => handleExport('pdf', invoice)
            }
          ];
          if (session?.user.userType === 'ecocart_admin' && isFeaturePermissioned) {
            ecoDropdownItems.push({
              label: 'Issue Refund',
              disabled: isRefundButtonDisabled,
              onPress: () => handleRefundInvoice(invoice)
            });
          }
          return (
            <View className="flex items-end justify-center">
              <EcoDropdown items={ecoDropdownItems} variant="ghost" showDownArrow={false} size="sm" menuWrapperStyles={{ width: '250px' }}>
                <EcoIcon name="more_vert" size="2xl" className="text-gray" />
              </EcoDropdown>
            </View>
          );
        }
      }
    ],
    [handlePayInvoice, handleRefundInvoice]
  );

  useEffect(() => {
    if (isFetchingData) showSuccess('Exporting data...');
  }, [isFetchingData, showSuccess]);

  const handleExport = async (exportType: 'csv' | 'pdf', invoice?: Invoice): Promise<void> => {
    if (!exportType || !['csv', 'pdf'].includes(exportType)) return;
    try {
      const exportFunction = exportType === 'csv' ? getInvoiceCSVDownloadUrl : getInvoicePDFDownloadUrl;
      const invoices = invoice ? [invoice] : selectedInvoices;
      const urls = await Promise.all(
        invoices.map((invoice) => exportFunction(shopName, invoice.identifier).then((downloadUrl) => downloadUrl))
      );

      fetchAndZipFiles(urls, {
        filename: `${shopName}_billing_history_${exportType}_${format(Date.now(), DATE_FORMAT)}`,
        type: exportType,
        ids: invoices.map(
          (invoice) =>
            `${invoice.identifier}_${format(new Date(invoice.start_date), DATE_FORMAT)}_${format(new Date(invoice.end_date), DATE_FORMAT)}`
        )
      });
    } catch (error: any) {
      handleError('There was an error exporting invoices. Please try again.');
    }
  };

  const content = useMemo(() => {
    if ((error as HttpError)?.message.includes('not authorized')) {
      return (
        <View className="items-center justify-center py-8" style={Gap(2)}>
          <EcoIcon name="warning" className="text-warning-500" size="6xl" />
          <EcoEmptyState title="Not authorized" subTitle="You are not authorized to view billing history" />
        </View>
      );
    }

    if (invoices && invoices.length > 0) {
      const Container = isWide ? React.Fragment : ScrollView;
      const containerProps: StyleProp<any> = isWide
        ? {}
        : { horizontal: true, contentContainerStyle: { flexGrow: 1 }, style: { overflow: 'hidden' } };

      return (
        <Container {...containerProps}>
          <EcoTable
            data={invoices}
            defaultDataQuery={{ sorts: [{ field: 'end_date', dir: 'desc' }] }}
            onSelectAllRowsClick={onSelectAllRowsClick}
            onRowClick={onRowClick}
            isLoading={isFetching}
            columns={columnConfig}
          />
        </Container>
      );
    }
    return (
      <View className="py-8 justify-center items-center">
        <EcoEmptyState title="No invoices" subTitle="There are no invoices available to display" />
      </View>
    );
  }, [columnConfig, error, invoices, isFetching, isWide, onRowClick, onSelectAllRowsClick]);

  return (
    <EcoCard
      nativeID="billing-history"
      isLoading={isFetching}
      style={style}
      title="Billing History"
      subTitle="View and export your transactions"
      topRightComponent={
        <View className="flex-row" style={Gap()}>
          {isEcoCartAdmin ? (
            <EcoButton size="sm" onPress={() => showCustomInvoiceModal(shopName, primaryPaymentMethod)}>
              Manual Invoice
            </EcoButton>
          ) : null}
          {invoices && invoices.length > 0 ? (
            <EcoDropdown
              size="sm"
              leftIcon="upload"
              items={[
                {
                  label: 'Export CSV',
                  disabled: selectedInvoices.length === 0,
                  onPress: () => handleExport('csv')
                },
                {
                  label: 'Export PDF',
                  disabled: selectedInvoices.length === 0,
                  onPress: () => handleExport('pdf')
                }
              ]}
            >
              Export
            </EcoDropdown>
          ) : null}
        </View>
      }
    >
      {content}
    </EcoCard>
  );
}
