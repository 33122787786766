import { EcoAvatar, EcoButton, EcoIcon, EcoImage, EcoLink, EcoText } from '@components/shared';
import { useNavigation } from '@react-navigation/native';
import { getMerchantAdmin } from '@utils/api/merchant';
import { DEFAULT_AVATAR, PUBLIC_IMG_URL } from '@utils/constants/config';
import { SIDE_NAV_WIDTH, TOP_NAV_HEIGHT } from '@utils/constants/navigation';
import { Gap } from '@utils/layout';
import { StorageKey, storage } from '@utils/storage';
import { BoxShadow } from '@utils/styles/box-shadow';
import clsx from 'clsx';
import { Linking, Pressable, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useGlobal } from '../hooks/useGlobal';
import { useOverlay } from '../hooks/useOverlay';
import { useWindow } from '../hooks/useWindow';

export function ApiAdminHeader(): JSX.Element {
  const { session, updateSessionMerchantAdmin, logout } = useGlobal();
  const { showMenu, hideMenu } = useOverlay();
  const { isDesktopWeb, isWide } = useWindow();
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();
  const user = session?.user;
  const userFullName = user?.firstName + ' ' + user?.lastName;

  const handleSetActiveMerchant = (shopName: string) => {
    if (shopName === session?.merchantAdmin?.shopName) return;

    getMerchantAdmin(shopName).then((merchantAdmin) => {
      updateSessionMerchantAdmin(merchantAdmin);
      hideMenu();
    });
  };

  const handleLogout = async () => {
    await storage.removeItem(StorageKey.login_redirect);
    logout();
    hideMenu();
  };

  const isActiveStore = (shopName: string): boolean => shopName === session?.merchantAdmin?.shopName;

  const handleOpenMenu = () => {
    if (!session) return;

    showMenu({
      content: (
        <View className="p-3 rounded-sm bg-white" style={BoxShadow('softdeep')}>
          <View className="p-3 flex-row justify-start">
            <EcoAvatar name={userFullName} className="mr-4" src={DEFAULT_AVATAR} />
            <View style={Gap(3)}>
              <View>
                <EcoText fontWeight="semibold">{userFullName}</EcoText>
                <EcoText fontSize="sm">{session?.merchantAdmin?.companyName}</EcoText>
              </View>
              <EcoButton
                nativeID="profile-menu__view-profile-button"
                size="sm"
                onPress={() => {
                  navigation.navigate('SettingsTab', { screen: 'SettingsUser' });
                  hideMenu();
                }}
              >
                View Profile
              </EcoButton>
            </View>
          </View>
          <View className="rounded-sm bg-gray-50 overflow-hidden">
            <View style={Gap(2)} className="justify-start items-center flex-row p-3 border-b border-gray-100">
              <EcoIcon className="pointer-event-none text-gray-700" name="storefront" />
              <EcoText fontSize="sm" color="disabled-inverted">
                Stores
              </EcoText>
            </View>
            {Object.keys(user?.merchants || {}).map((shopName) => (
              <Pressable key={shopName} onPress={() => handleSetActiveMerchant(shopName)} nativeID="profile-menu__shop-name">
                <View style={Gap(2)} className="flex-row justify-between items-center p-3">
                  <View style={Gap(2)} className="flex-row justify-start items-center">
                    <EcoAvatar
                      size="md"
                      name={isActiveStore(shopName) ? session?.merchantAdmin?.companyName : shopName.replace('.myshopify.com', '')}
                      className={clsx('pointer-event-none', isActiveStore(shopName) && 'border-[1px] border-primary-300')}
                    />
                    <View>
                      <EcoText fontSize="sm" color="disabled-inverted">
                        {isActiveStore(shopName) ? session?.merchantAdmin?.companyName : shopName.replace('.myshopify.com', '')}
                      </EcoText>
                      {isActiveStore(shopName) ? (
                        <EcoText fontSize="2xs" className="text-primary">
                          Active
                        </EcoText>
                      ) : null}
                    </View>
                  </View>
                  {isActiveStore(shopName) ? <EcoIcon className="text-gray-700" name="check" /> : null}
                </View>
              </Pressable>
            ))}
          </View>

          <Pressable
            nativeID="profile-menu__settings-button"
            onPress={() => {
              navigation.navigate('SettingsTab', { screen: 'SettingsGeneral' });
              hideMenu();
            }}
          >
            <View style={Gap(2)} className="flex-row justify-start items-center p-3 rounded-xs hover:bg-gray-100">
              <EcoIcon className="pointer-event-none text-gray-700" name="settings" />
              <EcoText fontSize="sm" color="disabled-inverted">
                Settings
              </EcoText>
            </View>
          </Pressable>

          <Pressable onPress={() => Linking.openURL('https://support.ecocart.io/knowledge')}>
            <View style={Gap(2)} className="flex-row justify-start items-center p-3 rounded-xs hover:bg-gray-100">
              <EcoIcon className="pointer-event-none text-gray-700" name="help" />
              <EcoText fontSize="sm" color="disabled-inverted">
                Help Center
              </EcoText>
            </View>
          </Pressable>

          <Pressable onPress={handleLogout} nativeID="profile-menu__logout-button">
            <View style={Gap(2)} className="flex-row justify-start items-center p-3 rounded-xs hover:bg-gray-100">
              <EcoIcon className="pointer-event-none text-gray-700" name="logout" />
              <EcoText fontSize="sm" color="disabled-inverted">
                Log out
              </EcoText>
            </View>
          </Pressable>
        </View>
      ),
      config: {
        position: {
          top: insets.top + TOP_NAV_HEIGHT,
          right: 16
        }
      }
    });
  };

  return (
    <View className="z-[10001] w-full" style={{ height: TOP_NAV_HEIGHT }}>
      {session?.merchantAdmin ? (
        <View className="flex-row justify-between items-center w-full h-full">
          {isDesktopWeb ? (
            <EcoLink href="/home" style={{ width: SIDE_NAV_WIDTH, height: TOP_NAV_HEIGHT }} className="flex-row justify-center">
              <EcoImage width={50} height={TOP_NAV_HEIGHT} resizeMode="contain" src={PUBLIC_IMG_URL + '/ecocart-icon.svg'} />
            </EcoLink>
          ) : null}

          <View className="flex-row justify-end items-center px-4 grow h-full border-b border-gray-100">
            <View style={Gap(8)} className="flex-row items-center">
              <EcoLink href="https://app.swaggerhub.com/apis-docs/EcoCartSciences/ecocart-financial-orchestration/0.0.7">
                API Documentation
              </EcoLink>

              <Pressable onPress={handleOpenMenu} nativeID="profile-menu">
                <View className="flex-row items-center" style={Gap(2)}>
                  <EcoAvatar name={userFullName} />
                  {isWide ? (
                    <View>
                      <EcoText fontWeight="semibold">{userFullName}</EcoText>
                      <EcoText fontSize="sm">{session.merchantAdmin.companyName}</EcoText>
                    </View>
                  ) : null}
                </View>
              </Pressable>
            </View>
          </View>
        </View>
      ) : null}
    </View>
  );
}
