import { EcoButton } from '@components/shared';
import { Linking } from 'react-native';

export const RewardsFAQButton = (): JSX.Element => {
  const openFAQPage = () => Linking.openURL('https://support.ecocart.io/knowledge/faq-ecocart-rewards?hs_preview=GXbsZqcz-164047940272');

  return (
    <EcoButton onPress={openFAQPage} rightIcon="open_in_new" size="sm" variant="ghost">
      FAQ
    </EcoButton>
  );
};
