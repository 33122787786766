import { MAP_WIDTH } from '@components/cards/ProjectList';
import { EcoButton, EcoCard, EcoImage, EcoMap, EcoPill, EcoSkeleton, EcoText } from '@components/shared';
import { EcoPageTitle } from '@components/shared/EcoPageTitle';
import { Project } from '@ecocart/entities';
import { getVerificationLogo } from '@ecocart/universal-utils';
import { useNavigation } from '@react-navigation/native';
import { useQuery } from '@tanstack/react-query';
import { getProject } from '@utils/api/projects';
import { Gap } from '@utils/layout';
import { logUserEvt } from '@utils/log';
import { getVerificationDescription } from '@utils/projects';
import { noop } from 'lodash';
import React from 'react';
import { Pressable, ScrollView, View } from 'react-native';
import { twMerge } from 'tailwind-merge';
import { useGlobal } from '../hooks/useGlobal';
import { useOverlay } from '../hooks/useOverlay';
import { useSelectProject } from '../hooks/useSelectProject';
import { useWindow } from '../hooks/useWindow';
import { BackLink } from '../navigation/BackLink';
import { GlobalScreenProps } from '../navigation/types';
import { MarkerConfig } from './ProjectsScreen';

export default function ProjectScreen({ route }: GlobalScreenProps): JSX.Element {
  const id = route?.params?.id;
  const { isDesktopWeb, height } = useWindow();
  const { session } = useGlobal();
  const { showModal } = useOverlay();
  const { data: project } = useQuery(['project', id], () => getProject(id));
  const isCurrentlyActive = session?.merchantAdmin?.project?.name === project?.name;
  const navigation = useNavigation();
  const isEcoCartAdmin = session?.user.userType === 'ecocart_admin';
  const shopName = isEcoCartAdmin ? route.params.shopName : session?.merchantAdmin?.shopName;
  const { isSubmitting, handleSelectProject } = useSelectProject(shopName);

  const convertProjectToMarkerConfig = (project: Project): MarkerConfig => {
    return {
      title: project.name,
      description: project.description,
      img: project.images?.[0] || '',
      onPress: () => navigation.navigate('Project', { id: project.id }),
      coordinates: project.coordinates
    };
  };

  return (
    <View className={twMerge('flex-1 max-w-[1440px] w-full flex mx-auto')}>
      <ScrollView className="flex-1 px-2 p-4 sm:p-7" style={[{ marginRight: isDesktopWeb ? MAP_WIDTH : 0 }, Gap()]}>
        <View className="flex-row">
          <BackLink nativeID="back-to-projects-selection" href="/projects" label="Back to Projects Selection" />
        </View>
        <View style={Gap(5)}>
          <EcoPageTitle title="Project Selection" subTitle="Select high-quality EcoCart projects - work to combat climate change." />
          <EcoCard className="p-0 overflow-hidden">
            <View className="h-[200px]">
              <EcoImage src={project?.images[0]} />
            </View>
            <View style={Gap()} className="py-6">
              {project?.isPortfolio ? <EcoText color="accent-secondary">Portfolio</EcoText> : null}
              <EcoText fallback={<EcoSkeleton height="2xl" />} fontSize="6xl" fontWeight="semibold">
                {project?.name}
              </EcoText>
              <EcoText fallback={<EcoSkeleton height="xs" />}>{project?.location}</EcoText>
              <View className="flex-row items-center justify-between">
                <EcoPill>{project?.type}</EcoPill>
                <EcoButton
                  isDisabled={isCurrentlyActive || isSubmitting || !project}
                  isLoading={isSubmitting}
                  onPress={() => {
                    isCurrentlyActive ? noop() : logUserEvt('select_project', { project_name: project?.name });
                    handleSelectProject(project as Project);
                  }}
                >
                  {isCurrentlyActive ? 'Active Project' : 'Select Project'}
                </EcoButton>
              </View>
            </View>
          </EcoCard>
          <EcoCard className="p-7" title="About" titleSize="4xl">
            <EcoText fallback={<EcoSkeleton height="sm" noOfLines={6} />} fontSize="lg">
              {project?.description}
            </EcoText>
          </EcoCard>
          {project?.impacts?.length ? (
            <EcoCard className="p-7" title="Impact" titleSize="4xl">
              <View className="flex flex-row justify-evenly items-center gap-4">
                {(project.impacts || []).map((impact, i) => (
                  <View className="items-center" key={impact.label + i}>
                    <EcoImage
                      src={
                        impact.icon ||
                        'https://ecocart.io/wp-content/themes/flynt/dist/Components/OffsetProjectContent/Assets/impact/earth-0babde0137.svg'
                      }
                      width={120}
                      resizeMode="contain"
                    />
                    <EcoText fallback={<EcoSkeleton height="3xl" />} fontSize="4xl" fontWeight="semibold">
                      {typeof impact.amount === 'number' ? `${new Intl.NumberFormat().format(Math.round(impact.amount))}+` : impact.amount}
                    </EcoText>
                    <EcoText fallback={<EcoSkeleton />} fontSize="sm">
                      {impact.label}
                    </EcoText>
                  </View>
                ))}
              </View>
            </EcoCard>
          ) : null}
          {project?.benefits?.length ? (
            <EcoCard className="p-7" title="Benefits" titleSize="4xl">
              <View style={Gap()}>
                {project.benefits.map((benefit, i) => (
                  <View key={benefit + i} className="flex flex-row items-start">
                    <View className="rounded-full bg-primary-500 w-[8px] h-[8px] mr-3 mt-2"></View>
                    <EcoText fallback={<EcoSkeleton height="sm" />} key={benefit + i} fontSize="lg">
                      {benefit}
                    </EcoText>
                  </View>
                ))}
              </View>
            </EcoCard>
          ) : null}
          {project?.images?.length ? (
            <EcoCard className="p-7" title="Photos" titleSize="4xl">
              <ScrollView horizontal={true} style={Gap()} className="flex-row">
                {(project.images || []).map((image, i) => (
                  <Pressable
                    key={image + i}
                    onPress={() =>
                      showModal({
                        content: (
                          <View>
                            <EcoImage src={image} width={500} />
                          </View>
                        )
                      })
                    }
                  >
                    <EcoImage src={image} width={100} />
                  </Pressable>
                ))}
              </ScrollView>
            </EcoCard>
          ) : null}
          {project?.verifications?.length ? (
            <EcoCard title="Verifications" titleSize="4xl" style={Gap(6)}>
              {project.verifications.map((type) => (
                <View key={type} style={Gap(6)} className="flex flex-row items-start">
                  <EcoText className="flex-1 text-justify" fallback={<EcoSkeleton height="lg" />} fontSize="lg" textAlign="left">
                    {getVerificationDescription(type)}
                  </EcoText>
                  <EcoImage resizeMode="contain" src={getVerificationLogo(type)} width={100} />
                </View>
              ))}
            </EcoCard>
          ) : null}
        </View>
      </ScrollView>
      {isDesktopWeb && project && !project?.isPortfolio ? (
        <View style={{ position: 'absolute', top: 0, bottom: 0, right: 0, width: MAP_WIDTH }}>
          <EcoMap center={project?.coordinates} width={MAP_WIDTH} height={height} markers={[convertProjectToMarkerConfig(project)]} />
        </View>
      ) : null}
    </View>
  );
}
