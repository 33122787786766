import CreateUserForm from '@components/forms/CreateUserForm';
import { EcoButton, EcoCard, EcoTable, EcoTag } from '@components/shared';
import { ColumnConfig, FilterConfig, filterConfigToFilters } from '@components/shared/EcoTable.utils';
import EcoScrollView from '@components/views/EcoScrollView';
import { User } from '@ecocart/entities';
import { DataQuery } from '@ecocart/universal-utils';
import { useNavigation } from '@react-navigation/native';
import { useQuery } from '@tanstack/react-query';
import { getUsers } from '@utils/api/user';
import { format } from 'date-fns';
import React from 'react';
import { useGlobal } from '../hooks/useGlobal';
import { useOverlay } from '../hooks/useOverlay';
import { GlobalScreenProps } from '../navigation/types';

interface UserUI extends User {
  merchantsArr: string[];
}

const hydrateForUI = (user: User): UserUI => {
  return {
    ...user,
    merchantsArr: Object.keys(user.merchants).filter(Boolean)
  };
};

export default function UsersScreen({}: GlobalScreenProps): JSX.Element {
  const { session } = useGlobal();
  const { showModal, hideModal } = useOverlay();
  const { data, refetch, isLoading } = useQuery(['users'], getUsers);
  const navigation = useNavigation();
  const filterConfig: FilterConfig<UserUI>[] = [
    {
      field: 'active',
      operator: 'eq',
      value: true,
      options: [true, false].map((active) => ({ label: active ? 'Active' : 'Inactive', value: active })),
      enabled: true
    }
  ];
  const defaultDataQuery: DataQuery<UserUI> = {
    sorts: [{ dir: 'desc', field: 'updatedAt' }],
    filters: filterConfigToFilters(filterConfig)
  };

  const columnConfig: ColumnConfig<UserUI>[] = [
    {
      field: 'active',
      format: ({ active }) => <EcoTag type={active ? 'info' : 'error'}>{active ? 'Active' : 'Inactive'}</EcoTag>
    },
    { field: 'id', searchable: true, onPress: ({ id }) => navigation.navigate('User', { id }), flex: 2 },
    { field: 'firstName', format: ({ firstName, lastName }) => `${firstName} ${lastName}`, searchable: true },
    {
      field: 'createdAt',
      title: 'Created',
      format: ({ createdAt }) => (createdAt ? format(new Date(createdAt), 'MMM d, yyyy') : '--')
    },
    {
      field: 'updatedAt',
      title: 'Updated',
      format: ({ updatedAt }) => (updatedAt ? format(new Date(updatedAt), 'MMM d, yyyy') : '--')
    },
    { field: 'permissions', searchable: true },
    { field: 'userType', searchable: true },
    { field: 'merchantsArr', title: 'merchants', flex: 2, searchable: true }
  ];

  const handleCreateNewUser = () => {
    showModal({
      content: (
        <EcoCard title="Create New User" size="lg">
          <CreateUserForm
            onSuccess={() => {
              hideModal();
              refetch();
            }}
          />
        </EcoCard>
      )
    });
  };

  return (
    <EcoScrollView>
      <EcoCard
        title="Users"
        topRightComponent={
          session?.user?.permissions.includes('create_all_users') ? (
            <EcoButton size="md" onPress={handleCreateNewUser}>
              Create New User
            </EcoButton>
          ) : (
            <></>
          )
        }
      >
        <EcoTable
          data={(data || []).map(hydrateForUI)}
          isLoading={isLoading}
          columns={columnConfig}
          defaultDataQuery={defaultDataQuery}
          filterConfig={filterConfig}
        />
      </EcoCard>
    </EcoScrollView>
  );
}
