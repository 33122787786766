import LoginCard from '@components/cards/LoginCard';
import UpdateTempPasswordForm from '@components/forms/UpdateTempPasswordForm';
import { EcoButton, EcoIcon, EcoKeyboardAvoidingView, EcoLink, EcoLoader, EcoText } from '@components/shared';
import { User } from '@ecocart/entities';
import { useQuery } from '@tanstack/react-query';
import { throwHttpError } from '@utils/api/api';
import { AddlLoginData, LoginResult, signIn } from '@utils/api/auth';
import { sendMerchantAdminUpdateMessage } from '@utils/api/slack';
import { resendVerificationLink } from '@utils/api/user';
import { Gap } from '@utils/layout';
import { LoginScreenView } from '@utils/styles/screen-views';
import { colors } from '@utils/tailwind';
import { useState } from 'react';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useGlobal } from '../hooks/useGlobal';
import { useOverlay } from '../hooks/useOverlay';
import { GlobalScreenProps } from '../navigation/types';

const titleMap: Partial<Record<LoginResult, string>> = {
  EXPIRED_CREDENTIALS: 'Verification Link Expired',
  PASSWORD_ATTEMPTS_EXCEEDED: 'Welcome to EcoCart!',
  INVALID_CREDENTIALS: 'Welcome to EcoCart!'
};

const decodeURIComponentSafe = (value: string): string => {
  return value ? decodeURIComponent(value.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) : value;
};

export default function WelcomeScreen({ route }: GlobalScreenProps): JSX.Element {
  const email = route.params?.email ? decodeURIComponent(route.params.email) : undefined;
  const password = route.params?.otk ? decodeURIComponentSafe(route.params.otk) : undefined;
  const isWelcomeLink = route.path.includes('welcome?');

  const { login } = useGlobal();
  const { handleError, showSuccess, showWarning } = useOverlay();
  const { data, error } = useQuery(['doSignIn', email, password], () => signIn({ email: email || '', password: password || '' }), {
    retry: 0,
    staleTime: 0,
    refetchOnWindowFocus: false
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const errorResult = (error as { result: LoginResult })?.result;
  const title = titleMap?.[errorResult] || 'Welcome to EcoCart!';

  const onSubmit = async ({ email, password }: any) => {
    try {
      await login({ email, password });
      await sendMerchantAdminUpdateMessage('user_email_confirmed', { user: { id: email } as User }, {});
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  const resendCode = async () => {
    setIsSubmitting(true);

    if (email) {
      setIsSubmitting(true);
      try {
        await resendVerificationLink(email);
        showSuccess('Verification link sent');
      } catch (error) {
        showWarning('Something went wrong. Please try again or contact your administrator.');
        throwHttpError((error as { result: LoginResult }).result);
      }
      setIsSubmitting(false);
    }
  };

  const ErrorContent = ({ errorResult }: { errorResult: LoginResult }) => {
    if (errorResult === 'EXPIRED_CREDENTIALS') {
      return (
        <View style={Gap(6)}>
          <EcoText color="danger" textAlign="center">
            Your verification link has expired. Please request a new verification link below
          </EcoText>
          <EcoButton className="w-full" isDisabled={isSubmitting} onPress={resendCode}>
            Resend Verification
          </EcoButton>
        </View>
      );
    }

    if (errorResult === 'INVALID_CREDENTIALS') {
      return (
        <View style={Gap(6)}>
          {isWelcomeLink && (
            <EcoText textAlign="center" color="danger">
              The verification link is invalid or your account has already been verified. Please click the link below to log in.
            </EcoText>
          )}
          <EcoLink
            nativeID="login-error-invalid-credentials__back-to-login-button"
            href="/login"
            className="flex-row align-center justify-center mt-2"
          >
            <EcoIcon name="chevron_left" className="text-secondary-500" />
            <EcoText color="link" className="ml-2">
              Back to Login
            </EcoText>
          </EcoLink>
        </View>
      );
    }

    if (errorResult === 'PASSWORD_ATTEMPTS_EXCEEDED') {
      return (
        <View className="flex-row justify-center">
          <EcoLink
            nativeID="login-error-pw-attempt-exceeded__back-to-login-button"
            href="/login"
            className="flex-row align-center justify-center mt-2"
          >
            <EcoIcon name="chevron_left" className="text-secondary-500" />
            <EcoText color="link" className="ml-2">
              Back to Login
            </EcoText>
          </EcoLink>
        </View>
      );
    }

    return <EcoLoader size="large" color={colors.primary[400]} />;
  };

  return (
    <SafeAreaView style={[LoginScreenView, { width: '100%', height: '100%', backgroundColor: colors.tertiary[800] }]}>
      <EcoKeyboardAvoidingView className="flex-row justify-center items-center w-full h-full">
        <LoginCard title={title}>
          <View style={Gap(6)} className="items-center">
            {data ? (
              <View style={Gap(2)}>
                <EcoText textAlign="center">Enter a password below to get started</EcoText>
                <UpdateTempPasswordForm
                  email={email}
                  addlData={data.addlData as AddlLoginData}
                  onSuccess={(newPassword: string) => {
                    onSubmit({ email, password: newPassword });
                  }}
                />
              </View>
            ) : (
              <ErrorContent errorResult={errorResult} />
            )}
          </View>
        </LoginCard>
      </EcoKeyboardAvoidingView>
    </SafeAreaView>
  );
}
