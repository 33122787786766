import { EcoButton, EcoRadioButtonCards, EcoText, FormPicker } from '@components/shared';
import { hydrateRadioButtonData } from '@components/shared/EcoRadioButtons.utils';
import { FormCheckbox } from '@components/shared/FormCheckbox';
import { MANUFACTURING_CALCULATION_TYPES, MerchantAdmin } from '@ecocart/entities';
import { useGlobal } from '@hooks/useGlobal';
import { useOverlay } from '@hooks/useOverlay';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getEmissionFactors } from '@utils/api/manufacturing';
import { updateMerchantAdmin } from '@utils/api/merchant';
import { sendMerchantAdminUpdateMessage } from '@utils/api/slack';
import { triggerIngestion } from '@utils/api/spider';
import { GlobalFormProps } from '@utils/prop-types';
import { Formik } from 'formik';
import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { View } from 'react-native';

interface Props extends GlobalFormProps {
  merchantAdmin: MerchantAdmin;
}

export default function EditManufacturingForm({ merchantAdmin, onSuccess }: Props): JSX.Element {
  const { data: emissionFactors } = useQuery(['getEmissionFactors'], () => getEmissionFactors());
  const [isCalculationTypeChanged, setIsCalculationTypeChanged] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { handleError, showSuccess, showWarning } = useOverlay();
  const { session } = useGlobal();

  const storeTypes = (emissionFactors || []).map((item) => {
    return {
      label: item.name,
      value: item.slug
    };
  });

  const mutation = useMutation<
    MerchantAdmin,
    Error,
    { dbMerchantAdmin: MerchantAdmin; calculationType: string; storeType: string; offsetCapEnabled: boolean }
  >(
    ({ calculationType, storeType, offsetCapEnabled }) => {
      const _merchantAdmin = cloneDeep(merchantAdmin);
      _merchantAdmin.manufacturingStoreType = storeType;
      _merchantAdmin.manufacturingOffsetCapEnabled = offsetCapEnabled;
      if (isCalculationTypeChanged) _merchantAdmin.setupStep = calculationType === 'lca' ? 'product_ingestion' : 'variant_creation';

      return updateMerchantAdmin(_merchantAdmin.shopName, _merchantAdmin);
    },
    {
      onSuccess: async (newMerchantAdmin, { dbMerchantAdmin }) => {
        showSuccess('Updated merchant admin!');
        onSuccess?.();
        await sendMerchantAdminUpdateMessage('merchant_settings_change', session, {
          merchantAdmin: newMerchantAdmin,
          dbMerchantAdmin
        });
      },
      onError: (error) => {
        handleError(error.message);
      }
    }
  );

  const onSubmit = async ({
    calculationType,
    storeType,
    offsetCapEnabled
  }: {
    calculationType: string;
    storeType: string;
    offsetCapEnabled: boolean;
  }) => {
    setIsSubmitting(true);
    let isConfirmed = true;
    const dbMerchantAdmin = cloneDeep(merchantAdmin);

    if (isCalculationTypeChanged) {
      if (merchantAdmin.setupStep !== 'complete') {
        showWarning(`System currently is in '${merchantAdmin.setupStep}' state. Please try again shortly.`);
        setIsSubmitting(false);
        return;
      }

      if (calculationType === 'lca') {
        isConfirmed = confirm(
          'Are you sure you would like to change the manufacturing calculation mode to LCA? Upon clicking "Yes", ' +
            'EcoCart will be paused for up to 5 minutes while the store is analyzed using the legacy method. This mode is not recommended.'
        );
      }
    }

    if (isConfirmed) {
      try {
        if (isCalculationTypeChanged) await triggerIngestion(merchantAdmin.shopName, calculationType);
      } catch (error: any) {
        handleError(error);
        setIsSubmitting(false);
        return;
      }
      mutation.mutate({ dbMerchantAdmin, calculationType, storeType, offsetCapEnabled });
    }
    setIsCalculationTypeChanged(false);
    setIsSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        calculationType: merchantAdmin.manufacturingCalculationType,
        storeType: merchantAdmin.manufacturingStoreType,
        offsetCapEnabled: merchantAdmin.manufacturingOffsetCapEnabled
      }}
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ handleChange, handleSubmit, setFieldValue, values, isValid }) => (
        <View className="flex flex-col">
          <EcoText fontSize="xs" color="subdued" className="py-1">
            Calculation Type
          </EcoText>
          <EcoRadioButtonCards
            itemStyle={{ maxWidth: 260 }}
            className="mb-5"
            dotPosition="none"
            value={values.calculationType}
            items={MANUFACTURING_CALCULATION_TYPES.map(hydrateRadioButtonData)}
            onValueChange={(value: string) => {
              setFieldValue('calculationType', value);
              if (values.calculationType !== value) setFieldValue('storeType', 'unspecified');
              setIsCalculationTypeChanged(value !== merchantAdmin.manufacturingCalculationType);
            }}
          />
          <FormPicker
            label="Store Type"
            items={storeTypes}
            value={values.storeType}
            onValueChange={handleChange('storeType')}
            className="mb-5"
          />
          <View className="mb-3">
            <FormCheckbox
              label="Enable per-item price caps (limits item price to average price for DEFRA category)"
              value={values.offsetCapEnabled}
              onValueChange={(value: boolean) => setFieldValue('offsetCapEnabled', value)}
            />
          </View>
          <EcoButton className="w-max mt-2" isDisabled={!isValid || isSubmitting} isLoading={isSubmitting} onPress={handleSubmit}>
            Save
          </EcoButton>
        </View>
      )}
    </Formik>
  );
}
