import { colors } from '@utils/tailwind';
import { ButtonSize } from './EcoButton';

export const BUTTON_VARIANTS = ['filled', 'outlined', 'ghost'] as const;
export type ButtonVariant = (typeof BUTTON_VARIANTS)[number];

export const BUTTON_COLOR_SCHEME = ['default', 'success', 'warning', 'danger'] as const;
export type ButtonColorScheme = (typeof BUTTON_COLOR_SCHEME)[number];

export const BUTTON_STATES = ['base', 'hover', 'disabled', 'loading', 'active'] as const;
export type ButtonState = (typeof BUTTON_STATES)[number];

type EcoButtonStylesMap = {
  [key in ButtonColorScheme]: {
    [key in ButtonVariant]: {
      [key in ButtonState]: {
        button: string;
        text: string;
      };
    };
  };
};

export const iconDimensionMap: Record<ButtonSize, number> = {
  sm: 12,
  md: 16,
  lg: 18
};

export const ButtonSizeStylesMap: Record<ButtonSize, string> = {
  sm: 'py-2 px-4 h-[32px]',
  md: 'py-3 px-6 h-[44px]',
  lg: 'py-4 px-8 h-[56px]'
};

export const ButtonIconsOnlySizeStylesMap: Record<ButtonSize, string> = {
  sm: 'p-2 w-[32px] h-[32px]',
  md: 'p-3 w-[44px] h-[44px]',
  lg: 'p-4 w-[56px] h-[56px]'
};

export const ButtonTextSizeMap: Record<ButtonSize, string> = {
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-lg'
};

export const iconLeftSpacingMap: Record<ButtonSize, string> = {
  sm: 'ml-4',
  md: 'ml-6',
  lg: 'ml-8'
};

export const iconRightSpacingMap: Record<ButtonSize, string> = {
  sm: 'mr-4',
  md: 'mr-6',
  lg: 'mr-8'
};

export const useButtonStyles = (): Record<'ButtonStylesMap', EcoButtonStylesMap> => {
  const ButtonStylesMap: EcoButtonStylesMap = {
    default: {
      filled: {
        base: {
          button: 'bg-secondary border border-secondary',
          text: 'text-white'
        },
        hover: {
          button: 'bg-secondary-600 border border-secondary-600',
          text: 'text-white'
        },
        disabled: {
          button: 'bg-secondary-200 border border-secondary-200',
          text: 'text-white'
        },
        loading: {
          button: 'bg-secondary-200 border border-secondary-200',
          text: colors.white
        },
        active: {
          button: 'bg-secondary-700 border border-secondary-700',
          text: 'text-white'
        }
      },
      outlined: {
        base: {
          button: 'bg-transparent border border-secondary-500',
          text: 'text-secondary-500'
        },
        hover: {
          button: 'bg-secondary-500/[.1] border border-secondary-600',
          text: 'text-secondary-500'
        },
        disabled: {
          button: 'bg-transparent border border-secondary-200',
          text: 'text-secondary-200'
        },
        loading: {
          button: 'bg-transparent border border-secondary-200',
          text: colors.secondary[200]
        },
        active: {
          button: 'bg-secondary-500/[.2] border border-secondary-600',
          text: 'text-secondary-500'
        }
      },
      ghost: {
        base: {
          button: 'border-transparent border bg-transparent',
          text: 'text-secondary-500'
        },
        hover: {
          button: 'bg-secondary-500/[.1] border border-transparent',
          text: 'text-secondary-500'
        },
        disabled: {
          button: 'border-transparent border bg-transparent',
          text: 'text-secondary-200'
        },
        loading: {
          button: 'border-transparent border bg-transparent',
          text: colors.secondary[200]
        },
        active: {
          button: 'bg-secondary-500/[.2] border border-transparent',
          text: 'text-secondary-500'
        }
      }
    },
    success: {
      filled: {
        base: {
          button: 'bg-success border border-success',
          text: 'text-white'
        },
        hover: {
          button: 'bg-success-600 border border-success-600',
          text: 'text-white'
        },
        disabled: {
          button: 'bg-success-100 border border-success-100',
          text: 'text-white'
        },
        loading: {
          button: 'bg-success-100 border border-success-100',
          text: colors.white
        },
        active: {
          button: 'bg-success-700 border border-success-700',
          text: 'text-white'
        }
      },
      outlined: {
        base: {
          button: 'bg-transparent border border-success-700',
          text: 'text-success-700'
        },
        hover: {
          button: 'bg-success/[.15] border border-success-700',
          text: 'text-success-700'
        },
        disabled: {
          button: 'bg-transparent border border-success-100',
          text: 'text-success-100'
        },
        loading: {
          button: 'bg-transparent border border-success-100',
          text: colors.success[100]
        },
        active: {
          button: 'bg-success/[.25] border border-success-700',
          text: 'text-success-700'
        }
      },
      ghost: {
        base: {
          button: 'border-transparent border bg-transparent',
          text: 'text-success-700'
        },
        hover: {
          button: 'bg-success/[.15] border border-transparent',
          text: 'text-success-700'
        },
        disabled: {
          button: 'border-transparent border bg-transparent',
          text: 'text-success-100'
        },
        loading: {
          button: 'border-transparent border bg-transparent',
          text: colors.success[100]
        },
        active: {
          button: 'bg-success/[.25] border border-transparent',
          text: 'text-success-700'
        }
      }
    },
    warning: {
      filled: {
        base: {
          button: 'bg-warning border border-warning',
          text: 'text-white'
        },
        hover: {
          button: 'bg-warning-600 border border-warning-600',
          text: 'text-white'
        },
        disabled: {
          button: 'bg-warning-100 border border-warning-100',
          text: 'text-white'
        },
        loading: {
          button: 'bg-warning-100 border border-warning-100',
          text: colors.white
        },
        active: {
          button: 'bg-warning-700 border border-warning-700',
          text: 'text-white'
        }
      },
      outlined: {
        base: {
          button: 'bg-transparent border border-warning-700',
          text: 'text-warning-700'
        },
        hover: {
          button: 'bg-warning/[.15] border border-warning-700',
          text: 'text-warning-700'
        },
        disabled: {
          button: 'bg-transparent border border-warning-300',
          text: 'text-warning-300'
        },
        loading: {
          button: 'bg-transparent border border-warning-100',
          text: colors.warning[100]
        },
        active: {
          button: 'bg-warning/[.25] border border-warning-700',
          text: 'text-warning-700'
        }
      },
      ghost: {
        base: {
          button: 'border-transparent border bg-transparent',
          text: 'text-warning-700'
        },
        hover: {
          button: 'bg-warning/[.15] border border-transparent',
          text: 'text-warning-700'
        },
        disabled: {
          button: 'border-transparent border bg-transparent',
          text: 'text-warning-300'
        },
        loading: {
          button: 'border-transparent border bg-transparent',
          text: colors.warning[100]
        },
        active: {
          button: 'bg-warning/[.25] border border-transparent',
          text: 'text-warning-700'
        }
      }
    },
    danger: {
      filled: {
        base: {
          button: 'bg-danger-600 border border-danger-600',
          text: 'text-white'
        },
        hover: {
          button: 'bg-danger-700 border border-danger-700',
          text: 'text-white'
        },
        disabled: {
          button: 'bg-danger-100 border border-danger-100',
          text: 'text-white'
        },
        loading: {
          button: 'bg-danger-100 border border-danger-100',
          text: colors.white
        },
        active: {
          button: 'bg-danger-800 border border-transparent',
          text: 'text-white'
        }
      },
      outlined: {
        base: {
          button: 'bg-transparent border border-danger-700',
          text: 'text-danger-700'
        },
        hover: {
          button: 'bg-danger/[.15] border border-danger-700',
          text: 'text-danger-700'
        },
        disabled: {
          button: 'bg-transparent border border-danger-100',
          text: 'text-danger-100'
        },
        loading: {
          button: 'bg-transparent border border-danger-100',
          text: colors.danger[100]
        },
        active: {
          button: 'bg-danger/[.25] border border-danger-700',
          text: 'text-danger-700'
        }
      },
      ghost: {
        base: {
          button: 'border-transparent border bg-transparent',
          text: 'text-danger-700'
        },
        hover: {
          button: 'bg-danger/[.15] border border-transparent',
          text: 'text-danger-700'
        },
        disabled: {
          button: 'border-transparent border bg-transparent',
          text: 'text-danger-100'
        },
        loading: {
          button: 'border-transparent border bg-transparent',
          text: colors.danger[100]
        },
        active: {
          button: 'bg-danger/[.25] border border-transparent',
          text: 'text-danger-700'
        }
      }
    }
  };
  return { ButtonStylesMap };
};
