import * as React from 'react';
import { StyleProp, View } from 'react-native';

export function DemoContainer({ children, style }: { children: React.ReactNode; className?: string; style?: StyleProp<any> }): JSX.Element {
  return (
    <View className="border border-gray-200 p-6 rounded-sm" style={style}>
      {children}
    </View>
  );
}
