//"da" | "de" | "en" | "en-CA" | "es" | "fr" | "it" | "jp" | "nb" | "nl" | "pl" | "pt" | "sk"

import { Calculation, Locale } from '@ecocart/entities';
import { ShopifyCart, fromBase64, isValidBase64, toBase64 } from '@ecocart/universal-utils';
import { FormikProps } from 'formik';
import { get } from 'lodash';

type TranslationElement = 'header' | 'subHeader' | 'subheader_in_progress' | 'subheader_complete' | 'modal_header' | 'modal_subheader';
export type TranslationPath = `elements.${TranslationElement}.text.${Locale}`;

export const EC_LOGO_VARIANT_LABELS = ['On Pill', 'Standard', 'Black Logo', 'White'] as const;
export const EC_LOGO_VARIANT_VALUES = ['on_pill', 'standard', 'black_logo', 'white'] as const;
export type ECLogoVariantLabel = (typeof EC_LOGO_VARIANT_LABELS)[number];
export type ECLogoVariantValue = (typeof EC_LOGO_VARIANT_VALUES)[number];

export const getTranslationPath = (element: TranslationElement, locale: Locale): TranslationPath => {
  return `elements.${element}.text.${locale}`;
};

export const fieldPaths = {
  name: 'name',
  // WRAPPER base
  backgroundImg: 'elements.wrapper.styles.backgroundImage',
  backgroundSize: 'elements.wrapper.styles.backgroundSize',
  backgroundColor: 'elements.wrapper.styles.backgroundColor',
  backgroundPosition: 'elements.wrapper.styles.backgroundPosition',
  wrapperMinHeight: 'elements.wrapper.styles.minHeight',
  wrapperPosition: 'elements.wrapper.styles.position',
  wrapperZIndex: 'elements.wrapper.styles.zIndex',
  wrapperWidth: 'elements.wrapper.styles.width',
  wrapperDisplay: 'elements.wrapper.styles.display',
  wrapperMargin: 'elements.wrapper.styles.margin',
  wrapperBorderRadius: 'elements.wrapper.styles.borderRadius',
  wrapperBorderWidth: 'elements.wrapper.styles.borderWidth',
  wrapperBorderColor: 'elements.wrapper.styles.borderColor',
  wrapperJustifyContent: 'elements.wrapper.styles.justifyContent',
  wrapperOverflow: 'elements.wrapper.styles.overflow',

  // WRAPPER SM
  wrapperMarginSM: 'elements.wrapper.overrides.sm.styles.margin',
  wrapperMinHeightSM: 'elements.wrapper.overrides.sm.styles.minHeight',

  // WRAPPER DESKTOP /MD
  wrapperWidthMD: 'elements.wrapper.overrides.md.styles.width',
  wrapperMinHeightMD: 'elements.wrapper.overrides.md.styles.minHeight',
  wrapperMarginMD: 'elements.wrapper.overrides.md.styles.margin',
  wrapperDisplayMD: 'elements.wrapper.overrides.md.styles.display',
  wrapperJustifyContentMD: 'elements.wrapper.overrides.md.styles.justifyContent',
  wrapperPositionMD: 'elements.wrapper.overrides.md.styles.position',
  wrapperZIndexMD: 'elements.wrapper.overrides.md.styles.zIndex',

  // WRAPPER LG
  wrapperMinHeightLG: 'elements.wrapper.overrides.lg.styles.minHeight',
  wrapperMarginLG: 'elements.wrapper.overrides.lg.styles.margin',

  // CONTAINER base
  maxWidth: 'elements.container.styles.maxWidth',
  paddingLeft: 'elements.container.styles.paddingLeft',
  paddingRight: 'elements.container.styles.paddingRight',
  paddingTop: 'elements.container.styles.paddingTop',
  paddingBottom: 'elements.container.styles.paddingBottom',
  containerBorderWidth: 'elements.container.styles.borderWidth',
  containerBorderColor: 'elements.container.styles.borderColor',
  containerBorderRadius: 'elements.container.styles.borderRadius',
  containerMargin: 'elements.container.styles.margin',
  containerPadding: 'elements.container.styles.padding',
  containerBackgroundColor: 'elements.container.styles.backgroundColor',

  // CONTAINER SM
  maxWidthSM: 'elements.container.overrides.sm.styles.maxWidth',
  paddingLeftSM: 'elements.container.overrides.sm.styles.paddingLeft',
  paddingRightSM: 'elements.container.overrides.sm.styles.paddingRight',
  paddingTopSM: 'elements.container.overrides.sm.styles.paddingTop',
  paddingBottomSM: 'elements.container.overrides.sm.styles.paddingBottom',

  // CONTAINER DESKTOP / MD
  containerMarginMD: 'elements.container.overrides.md.styles.margin',
  containerPaddingMD: 'elements.container.overrides.md.styles.padding',
  maxWidthMD: 'elements.container.overrides.md.styles.maxWidth',
  paddingLeftMD: 'elements.container.overrides.md.styles.paddingLeft',
  paddingRightMD: 'elements.container.overrides.md.styles.paddingRight',
  paddingTopMD: 'elements.container.overrides.md.styles.paddingTop',
  paddingBottomMD: 'elements.container.overrides.md.styles.paddingBottom',
  containerDisplay: 'elements.container.styles.display',
  containerJustifyContent: 'elements.container.styles.justifyContent',
  containerDisplayMD: 'elements.container.overrides.md.styles.display',
  containerJustifyContentMD: 'elements.container.overrides.md.styles.justifyContent',

  // CONTAINER LG
  maxWidthLG: 'elements.container.overrides.lg.styles.maxWidth',
  paddingLeftLG: 'elements.container.overrides.lg.styles.paddingLeft',
  paddingRightLG: 'elements.container.overrides.lg.styles.paddingRight',
  paddingTopLG: 'elements.container.overrides.lg.styles.paddingTop',
  paddingBottomLG: 'elements.container.overrides.lg.styles.paddingBottom',

  // CONTENT
  contentGap: 'elements.content.styles.gap',
  contentMinHeight: 'elements.content.styles.minHeight',
  contentMaxWidth: 'elements.content.styles.maxWidth',

  // CONTENT MD
  contentGapMD: 'elements.content.overrides.md.styles.gap',
  contentFlexBasisMD: 'elements.content.overrides.md.styles.flexBasis',
  contentMaxWidthMD: 'elements.content.overrides.md.styles.maxWidth',

  // CONTENT LG
  contentGapLG: 'elements.content.overrides.lg.styles.gap',
  contentFlexBasisLG: 'elements.content.overrides.lg.styles.flexBasis',
  contentMaxWidthLG: 'elements.content.overrides.lg.styles.maxWidth',

  // HEADER
  headerFontSize: 'elements.header.styles.fontSize',
  headerLineHeight: 'elements.header.styles.lineHeight',
  headerFontFamily: 'elements.header.styles.fontFamily',
  headerFontWeight: 'elements.header.styles.fontWeight',
  headerColor: 'elements.header.styles.color',
  headerTextEN: 'elements.header.text.en',
  headerTextTransform: 'elements.header.styles.textTransform',
  headerZIndex: 'elements.header.styles.zIndex',
  headerDisplay: 'elements.header.styles.display',
  headerJustifyContent: 'elements.header.styles.justifyContent',
  headerMargin: 'elements.header.styles.margin',
  headerPadding: 'elements.header.styles.padding',
  headerWidth: 'elements.header.styles.width',
  headerTextAlign: 'elements.header.styles.textAlign',

  // HEADER SM
  headerFontSizeSM: 'elements.header.overrides.sm.styles.fontSize',
  headerLineHeightSM: 'elements.header.overrides.sm.styles.lineHeight',
  headerMarginSM: 'elements.header.overrides.sm.styles.margin',

  // HEADER DESKTOP / MD
  headerTextAlignMD: 'elements.header.overrides.md.styles.textAlign',
  headerDisplayMD: 'elements.header.overrides.md.styles.display',
  headerJustifyContentMD: 'elements.header.overrides.md.styles.justifyContent',
  headerFontSizeMD: 'elements.header.overrides.md.styles.fontSize',
  headerLineHeightMD: 'elements.header.overrides.md.styles.lineHeight',
  headerMarginMD: 'elements.header.overrides.md.styles.margin',
  headerPaddingMD: 'elements.header.overrides.md.styles.padding',
  headerWidthMD: 'elements.header.overrides.md.styles.width',
  headerZIndexMD: 'elements.header.overrides.md.styles.zIndex',

  // HEADER LG
  headerFontSizeLG: 'elements.header.overrides.lg.styles.fontSize',
  headerLineHeightLG: 'elements.header.overrides.lg.styles.lineHeight',
  headerMarginLG: 'elements.header.overrides.lg.styles.margin',

  // SUBHEADER
  subHeaderDisabled: 'elements.subHeader.disable',
  subHeaderFontSize: 'elements.subHeader.styles.fontSize',
  subHeaderLineHeight: 'elements.subHeader.styles.lineHeight',
  subHeaderFontFamily: 'elements.subHeader.styles.fontFamily',
  subHeaderFontWeight: 'elements.subHeader.styles.fontWeight',
  subHeaderColor: 'elements.subHeader.styles.color',
  subHeaderTextEN: 'elements.subHeader.text.en',
  subHeaderTextTransform: 'elements.subHeader.styles.textTransform',
  subHeaderFontSizeXS: 'elements.subHeader.styles.fontSize',
  subHeaderLineHeightXS: 'elements.subHeader.styles.lineHeight',
  subheaderFontFamily: 'elements.subHeader.styles.fontFamily',
  subheaderFontSize: 'elements.subHeader.styles.fontSize',
  subheaderLineHeight: 'elements.subHeader.styles.lineHeight',
  subheaderFontWeight: 'elements.subHeader.styles.fontWeight',
  subheaderColor: 'elements.subHeader.styles.color',
  subheaderTextTransform: 'elements.subHeader.styles.textTransform',

  // SUBHEADER SM
  subHeaderFontSizeSM: 'elements.subHeader.overrides.sm.styles.fontSize',
  subHeaderLineHeightSM: 'elements.subHeader.overrides.sm.styles.lineHeight',

  // SUBHEADER DESKTOP / MD
  subHeaderFontSizeMD: 'elements.subHeader.overrides.md.styles.fontSize',
  subHeaderLineHeightMD: 'elements.subHeader.overrides.md.styles.lineHeight',

  // SUBHEADER LG
  subHeaderFontSizeLG: 'elements.subHeader.overrides.lg.styles.fontSize',
  subHeaderLineHeightLG: 'elements.subHeader.overrides.lg.styles.lineHeight',

  // Primary CTA
  ctaTextEN: 'elements.cta.text.en',
  ctaDisabled: 'elements.cta.disable',
  ctaFontFamily: 'elements.cta.styles.fontFamily',
  ctaFontWeight: 'elements.cta.styles.fontWeight',
  ctaFontSize: 'elements.cta.styles.fontSize',
  ctaFontColor: 'elements.cta.styles.color',
  ctaURL: 'elements.cta.custom.url',
  ctaBorderColor: 'elements.cta.styles.borderColor',
  ctaBackgroundColor: 'elements.cta.styles.backgroundColor',
  ctaBorderRadius: 'elements.cta.styles.borderRadius',

  // Secondary CTA
  cta2Disabled: 'elements.cta2.disable',
  cta2TextEN: 'elements.cta2.text.en',
  cta2FontFamily: 'elements.cta2.styles.fontFamily',
  cta2FontWeight: 'elements.cta2.styles.fontWeight',
  cta2FontSize: 'elements.cta2.styles.fontSize',
  cta2FontColor: 'elements.cta2.styles.color',
  cta2URL: 'elements.cta2.custom.url',
  cta2BorderColor: 'elements.cta2.styles.borderColor',
  cta2BackgroundColor: 'elements.cta2.styles.backgroundColor',
  cta2BorderRadius: 'elements.cta2.styles.borderRadius',

  // LEARN MORE MODAL
  learnMoreHeroUrl: 'elements.modal_hero_img.custom.url',
  learnMoreThemeColor: 'elements.modal_hero_cta.styles.color',
  learnMoreHeaderFontFamily: 'elements.modal_header.styles.fontFamily',
  learnMoreHeaderFontSize: 'elements.modal_header.styles.fontSize',
  learnMoreHeaderLineHeight: 'elements.modal_header.styles.lineHeight',
  learnMoreHeaderFontWeight: 'elements.modal_header.styles.fontWeight',
  learnMoreHeaderFontColor: 'elements.modal_header.styles.color',
  learnMoreItemsFontFamily: 'elements.modal_subheader.styles.fontFamily',
  learnMoreItemsFontSize: 'elements.modal_subheader.styles.fontSize',
  learnMoreItemsLineHeight: 'elements.modal_subheader.styles.lineHeight',
  learnMoreItemsFontWeight: 'elements.modal_subheader.styles.fontWeight',
  learnMoreItemsFontColor: 'elements.modal_subheader.styles.color',

  // ECOCART LOGO
  logoURL: 'elements.logo.custom.url',
  logoPaddingLeft: 'elements.logo.styles.paddingLeft',
  logoPaddingRight: 'elements.logo.styles.paddingRight',
  logoPaddingTop: 'elements.logo.styles.paddingTop',
  logoPaddingBottom: 'elements.logo.styles.paddingBottom',
  logoBorderRadius: 'elements.logo.styles.borderRadius',
  logoBorderBackgroundColor: 'elements.logo.styles.backgroundColor',
  logoDisable: 'elements.logo.disable',

  // MERCHANT LOGO
  logo2URL: 'elements.logo2.custom.url',
  logo2Size: 'elements.logo2.custom.size',

  // FLOATING IMAGE
  imgURL: 'elements.img.custom.url',
  imgPosition: 'elements.img.styles.backgroundPosition',
  imgPaddingTop: 'elements.img.styles.paddingTop',

  // FLOATING IMAGE MD
  imgFlexBasisMD: 'elements.img.overrides.md.styles.flexBasis',
  imgPaddingTopMD: 'elements.img.overrides.md.styles.paddingTop',

  // FLOATING IMAGE LG
  imgFlexBasisLG: 'elements.img.overrides.lg.styles.flexBasis',
  imgPaddingTopLG: 'elements.img.overrides.lg.styles.paddingTop',

  // IMPACT BOOST aka "upsell"
  impactBoostBorderRadius: 'elements.upsell.styles.borderRadius',
  impactBoostBorderWidth: 'elements.upsell.styles.borderWidth',
  impactBoostFontColor: 'elements.upsell.styles.color',
  impactBoostActiveBackgroundColor: 'elements.upsell.custom.activeBackgroundColor',
  impactBoostActiveFontColor: 'elements.upsell.custom.activeFontColor',
  impactBoostDisabled: 'elements.upsell.disable',

  // SOCIAL PROOF
  socialProofDisabled: 'elements.social_proof.disable',
  socialProofFontFamily: 'elements.social_proof.styles.fontFamily',
  socialProofFontSize: 'elements.social_proof.styles.fontSize',
  socialProofLineHeight: 'elements.social_proof.styles.lineHeight',
  socialProofFontWeight: 'elements.social_proof.styles.fontWeight',
  socialProofFontColor: 'elements.social_proof.styles.color',
  socialProofBackgroundColor: 'elements.social_proof.styles.backgroundColor',
  socialProofBorderWidth: 'elements.social_proof.styles.borderWidth',
  socialProofBorderColor: 'elements.social_proof.styles.borderColor',
  socialProofBorderRadius: 'elements.social_proof.styles.borderRadius',
  socialProofTextTransform: 'elements.social_proof.styles.textTransform',
  socialProofMargin: 'elements.social_proof.styles.margin',
  socialProofPadding: 'elements.social_proof.styles.padding',
  socialProofWidth: 'elements.social_proof.styles.width',
  socialProofZIndex: 'elements.social_proof.styles.zIndex',
  socialProofDisplay: 'elements.social_proof.styles.display',
  socialProofJustifyContent: 'elements.social_proof.styles.justifyContent',

  // SOCIAL PROOF Desktop
  socialProofMarginMD: 'elements.social_proof.overrides.md.styles.margin',
  socialProofPaddingMD: 'elements.social_proof.overrides.md.styles.padding',
  socialProofWidthMD: 'elements.social_proof.overrides.md.styles.width',
  socialProofZIndexMD: 'elements.social_proof.overrides.md.styles.zIndex',
  socialProofDisplayMD: 'elements.social_proof.overrides.md.styles.display',
  socialProofJustifyContentMD: 'elements.social_proof.overrides.md.styles.justifyContent',

  // DEFAULT PROGRESS BAR
  progressBarBackgroundColor: 'elements.progress_bar.styles.backgroundColor',
  progressBarBorderWidth: 'elements.progress_bar.styles.borderWidth',
  progressBarBorderColor: 'elements.progress_bar.styles.borderColor',
  progressBarBorderRadius: 'elements.progress_bar.styles.borderRadius',
  progressBarMargin: 'elements.progress_bar.styles.margin',
  progressBarHeight: 'elements.progress_bar.styles.height',

  // FILLED PROGRESS BAR
  progressBarFilledBackgroundColor: 'elements.progress_bar.custom.activeBackgroundColor',
  progressBarFilledBorderWidth: 'elements.progress_bar.custom.activeBorderWidth',
  progressBarFilledBorderColor: 'elements.progress_bar.custom.activeBorderColor',
  progressBarFilledBorderRadius: 'elements.progress_bar.custom.activeBorderRadius',

  // FOOTER / ATTRIBUTION
  footerFontFamily: 'elements.footer.styles.fontFamily',
  footerFontSize: 'elements.footer.styles.fontSize',
  footerLineHeight: 'elements.footer.styles.lineHeight',
  footerFontWeight: 'elements.footer.styles.fontWeight',
  footerColor: 'elements.footer.styles.color',
  footerTextTransform: 'elements.footer.styles.textTransform',

  // CHECKBOX
  checkMarkColor: 'elements.cta.styles.color',
  checkMarkBackgroundColor: 'elements.cta.styles.backgroundColor',
  checkMarkBorderColor: 'elements.cta.styles.borderColor',
  checkBoxOrder: 'elements.cta.styles.order',

  // PRICE
  amountDisabled: 'elements.amount.disable',
  amountFontFamily: 'elements.amount.styles.fontFamily',
  amountFontSize: 'elements.amount.styles.fontSize',
  amountLineHeight: 'elements.amount.styles.lineHeight',
  amountFontWeight: 'elements.amount.styles.fontWeight',
  amountColor: 'elements.amount.styles.color',

  // POWERED BY
  poweredByDisabled: 'elements.powered_by.disable'
} as const;

export type FieldPathValues = (typeof fieldPaths)[keyof typeof fieldPaths] | TranslationPath;

export const alignmentItems = [
  { label: 'None', value: '' },
  { label: 'Left Aligned', value: 'left' },
  { label: 'Center', value: 'center' },
  { label: 'Right Aligned', value: 'right' }
];

export const alignmentItemsNoneOrCenter = [
  { label: 'None', value: '' },
  { label: 'Center', value: 'center' }
];

export const ALIGNMENT_VALUES = ['left', 'center', 'right'] as const;
export type AlignmentValues = (typeof ALIGNMENT_VALUES)[number];

export function getSelectedAlignmentValue<T>(
  values: T,
  element: 'wrapper' | 'header' | 'container' | 'social_proof' = 'wrapper',
  mode: 'mobile' | 'desktop' = 'mobile'
): '' | 'left' | 'center' | 'right' {
  if (!['mobile', 'desktop'].includes(mode)) return '';
  const isMobile = mode === 'mobile';

  let justifyElement: string;

  switch (element) {
    case 'wrapper':
      justifyElement = isMobile ? fieldPaths.wrapperJustifyContent : fieldPaths.wrapperJustifyContentMD;
      break;
    case 'header':
      justifyElement = isMobile ? fieldPaths.headerJustifyContent : fieldPaths.headerJustifyContentMD;
      break;
    case 'container':
      justifyElement = isMobile ? fieldPaths.containerJustifyContent : fieldPaths.containerJustifyContentMD;
      break;
    case 'social_proof':
      justifyElement = isMobile ? fieldPaths.socialProofJustifyContent : fieldPaths.socialProofJustifyContentMD;
      break;
  }

  const currVal = get(values, justifyElement);
  if (currVal === 'flex-start') return 'left';
  if (currVal === 'center') return 'center';
  if (currVal === 'flex-end') return 'right';
  return '';
}

export async function handleAlignmentChange(
  formik: FormikProps<any>,
  val: AlignmentValues,
  mode: 'mobile' | 'desktop' = 'mobile',
  element: 'wrapper' | 'container' | 'header' | 'social_proof' = 'wrapper'
): Promise<void> {
  if (!['mobile', 'desktop'].includes(mode)) return;
  const { setFieldValue, validateForm } = formik;
  const isMobile = mode === 'mobile';
  const containerDisplay = isMobile ? fieldPaths.containerDisplay : fieldPaths.containerDisplayMD;
  const containerJustifyContent = isMobile ? fieldPaths.containerJustifyContent : fieldPaths.containerJustifyContentMD;
  const socialProofDisplay = isMobile ? fieldPaths.socialProofDisplay : fieldPaths.socialProofDisplayMD;
  const socialProofJustifyContent = isMobile ? fieldPaths.socialProofJustifyContent : fieldPaths.socialProofJustifyContentMD;
  const wrapperDisplay = isMobile ? fieldPaths.wrapperDisplay : fieldPaths.wrapperDisplayMD;
  const wrapperJustifyContent = isMobile ? fieldPaths.wrapperJustifyContent : fieldPaths.wrapperJustifyContentMD;
  const headerDisplay = isMobile ? fieldPaths.headerDisplay : fieldPaths.headerDisplayMD;
  const headerJustifyContent = isMobile ? fieldPaths.headerJustifyContent : fieldPaths.headerJustifyContentMD;
  const headerTextAlign = isMobile ? fieldPaths.headerTextAlign : fieldPaths.headerTextAlignMD;
  let displayElement: FieldPathValues | undefined = undefined;
  let justifyElement: FieldPathValues | undefined = undefined;
  let textAlign: FieldPathValues | undefined = undefined;

  switch (element) {
    case 'wrapper':
      displayElement = wrapperDisplay;
      justifyElement = wrapperJustifyContent;
      break;
    // When content alignment changes, we need to update social proof as well
    case 'container':
      if (!ALIGNMENT_VALUES.includes(val)) {
        setFieldValue(containerDisplay, undefined);
        setFieldValue(containerJustifyContent, undefined);
        setFieldValue(socialProofDisplay, undefined);
        setFieldValue(socialProofJustifyContent, undefined);
        break;
      }
      setFieldValue(containerDisplay, 'flex');
      setFieldValue(socialProofDisplay, 'flex');
      if (val === 'center') {
        setFieldValue(containerJustifyContent, 'center');
        setFieldValue(socialProofJustifyContent, 'center');
        break;
      }
      break;
    case 'social_proof':
      displayElement = socialProofDisplay;
      justifyElement = socialProofJustifyContent;
      break;
    case 'header':
      displayElement = headerDisplay;
      justifyElement = headerJustifyContent;
      textAlign = headerTextAlign;
      break;
    default:
      break;
  }

  if (!displayElement || !justifyElement) return;

  if (!ALIGNMENT_VALUES.includes(val)) {
    await setFieldValue(displayElement, undefined);
    await setFieldValue(justifyElement, undefined);
    if (element === 'header' && textAlign) await setFieldValue(textAlign, undefined);
    return;
  }
  await setFieldValue(displayElement, 'flex');

  if (val === 'left') {
    await setFieldValue(justifyElement, 'flex-start');
    if (element === 'header' && textAlign) await setFieldValue(textAlign, 'left');
  } else if (val === 'center') {
    await setFieldValue(justifyElement, 'center');
    if (element === 'header' && textAlign) setFieldValue(textAlign, 'center');
  } else if (val === 'right') {
    await setFieldValue(justifyElement, 'flex-end');
    if (element === 'header' && textAlign) setFieldValue(textAlign, 'right');
  }
  validateForm();
}

export const TEXT_TRANSFORM_VALUES = ['none', 'capitalize', 'uppercase', 'lowercase'] as const;
export type TextTransformValues = (typeof TEXT_TRANSFORM_VALUES)[number];

export const typeCaseItems = [
  { label: 'Default', value: 'none' },
  { label: 'Title Case', value: 'capitalize' },
  { label: 'All Caps', value: 'uppercase' },
  { label: 'Lowercase', value: 'lowercase' }
];
export const TYPECASE_ELEMENTS = ['header', 'subheader', 'social_proof', 'footer'] as const;
export type TypeCaseElements = (typeof TYPECASE_ELEMENTS)[number];

export async function handleTypeCaseChange(
  formik: FormikProps<any>,
  val: TextTransformValues,
  element: TypeCaseElements = 'header'
): Promise<void> {
  const { setFieldValue, validateForm } = formik;
  if (!TYPECASE_ELEMENTS.includes(element)) return;

  let textTransform: FieldPathValues;
  switch (element) {
    case 'header':
      textTransform = fieldPaths.headerTextTransform;
      break;
    case 'subheader':
      textTransform = fieldPaths.subHeaderTextTransform;
      break;
    case 'social_proof':
      textTransform = fieldPaths.socialProofTextTransform;
      break;
    case 'footer':
      textTransform = fieldPaths.footerTextTransform;
      break;
  }

  if (!TEXT_TRANSFORM_VALUES.includes(val)) {
    await setFieldValue(textTransform, undefined);
  } else {
    await setFieldValue(textTransform, val);
  }
  validateForm();
}

type PropsReturnType = {
  onBlur: () => void;
  onChangeText: (val: string) => void;
  value: string;
  errors: any;
  field: string;
  touched: any;
};

export function getProps(formik: FormikProps<any>, path: FieldPathValues, customCallback?: (val: string) => void): PropsReturnType {
  const { setFieldTouched, handleChange, values, errors, touched } = formik;
  const value = get(values, path, '') as string;
  const isTextPath = isATextPath(path);

  return {
    onBlur: () => setFieldTouched(path),
    onChangeText: (val: string) => {
      if (isTextPath) {
        handleChange(path)(toBase64(val));
      } else {
        handleChange(path)(val);
      }
      customCallback?.(val);
    },
    value: isValidBase64(value) && isTextPath ? fromBase64(value) : value,
    errors,
    field: path,
    touched
  };
}

type ZIndexPropsReturnType = {
  onBlur: () => void;
  onChangeText: (val: string) => void;
  value: string;
  errors: any;
  field: string;
  touched: any;
};

export function getZIndexProps(formik: FormikProps<any>, path: FieldPathValues): ZIndexPropsReturnType {
  const { setFieldTouched, setFieldValue, values, errors, touched } = formik;
  const isDesktop = path === fieldPaths.wrapperZIndexMD;
  const wrapperPosition = isDesktop ? fieldPaths.wrapperPositionMD : fieldPaths.wrapperPosition;
  const wrapperZIndex = isDesktop ? fieldPaths.wrapperZIndexMD : fieldPaths.wrapperZIndex;
  const value = get(values, path, '') as string;

  function handleZIndexChange(val: string) {
    setFieldValue(wrapperPosition, val ? 'relative' : 'static');
    setFieldValue(wrapperZIndex, val ?? undefined);
  }

  return {
    onBlur: () => setFieldTouched(path),
    onChangeText: handleZIndexChange,
    value,
    errors,
    field: path,
    touched
  };
}

export function handleBgImgUrlChange(formik: FormikProps<any>, val?: string): void {
  const { handleChange } = formik;
  handleChange(fieldPaths.backgroundImg)(val ? `url('${val}')` : '');
  handleChange(fieldPaths.backgroundSize)(val ? 'cover' : '');
  handleChange(fieldPaths.backgroundPosition)(val ? 'center' : '');
}

export const getPlainImageUrl = (str?: string): string => {
  if (!str) return '';
  return str.replace(/url\(|\)|'|"/g, '');
};

export const convertCountryCodeToEnglishName = (locale: Locale): string => {
  const languageNames = new Intl.DisplayNames(['en'], {
    type: 'language'
  });
  return languageNames.of(locale) || '';
};

export const isATextPath = (path: string): boolean => path.split('.').includes('text') || path.includes('name');

export const MOCK_CART: ShopifyCart = {
  note: null,
  token: '2c08590083ffc84824db69e9d7575622',
  attributes: {},
  original_total_price: 3500,
  total_price: 3500,
  total_discount: 0,
  total_weight: 2267.9619,
  item_count: 3,
  items: [
    {
      id: 41309014982701,
      properties: {},
      quantity: 1,
      variant_id: '41309014982701',
      key: '41309014982701:ff43df3b933d5beb99c800ee0b254492',
      title: 'Carbon Neutral Order - Offsets 69.09 lbs',
      price: 167,
      original_price: 167,
      discounted_price: 167,
      line_price: 167,
      original_line_price: 167,
      total_discount: 0,
      discounts: [],
      sku: '',
      grams: 0,
      vendor: 'EcoCart',
      taxable: true,
      product_id: 7241926508589,
      product_has_only_default_variant: false,
      gift_card: false,
      final_price: 167,
      final_line_price: 167,
      url: '/products/carbon-neutral-order?variant=41309014982701',
      featured_image: {
        aspect_ratio: 0.78,
        alt: 'Carbon Neutral Order',
        height: 930,
        url: 'https://cdn.shopify.com/s/files/1/0357/1000/9389/products/ecocart.png?v=1666895295',
        width: 725
      },
      image: 'https://cdn.shopify.com/s/files/1/0357/1000/9389/products/ecocart.png?v=1666895295',
      handle: 'carbon-neutral-order',
      requires_shipping: false,
      product_type: 'Carbon Offset',
      product_title: 'Carbon Neutral Order',
      product_description: 'Donation for offsetting the carbon footprint of your order.',
      variant_title: null,
      variant_options: [],
      options_with_values: [{ name: 'Title', value: 'Offsets 69.09 lbs' }],
      line_level_discount_allocations: [],
      line_level_total_discount: 0
    },
    {
      id: 40130466906157,
      properties: {},
      quantity: 1,
      variant_id: '40130466906157',
      key: '40130466906157:a0e953174a63cf416ba39e4c368ecd16',
      title: 'Environment Tshirt series - Small / Black',
      price: 2500,
      original_price: 2500,
      discounted_price: 2500,
      line_price: 2500,
      original_line_price: 2500,
      total_discount: 0,
      discounts: [],
      sku: '',
      grams: 907,
      vendor: 'EcoCart Merch',
      taxable: true,
      product_id: 6898711756845,
      product_has_only_default_variant: false,
      gift_card: false,
      final_price: 2500,
      final_line_price: 2500,
      url: '/products/environment-tshirt-series?variant=40130466906157',
      featured_image: {
        aspect_ratio: 0.752,
        alt: 'Environment Tshirt series',
        height: 958,
        url: 'https://cdn.shopify.com/s/files/1/0357/1000/9389/products/blacktshirt.jpg?v=1640377809',
        width: 720
      },
      image: 'https://cdn.shopify.com/s/files/1/0357/1000/9389/products/blacktshirt.jpg?v=1640377809',
      handle: 'environment-tshirt-series',
      requires_shipping: true,
      product_type: '',
      product_title: 'Environment Tshirt series',
      product_description: 'This is the first SKU to be tested for the variant scenarios',
      variant_title: null,
      variant_options: [],
      options_with_values: [
        { name: 'Size', value: 'Small' },
        { name: 'Color', value: 'Black' }
      ],
      line_level_discount_allocations: [],
      line_level_total_discount: 0
    }
  ],
  requires_shipping: true,
  currency: 'USD',
  items_subtotal_price: 5089,
  cart_level_discount_applications: []
};

export const MOCK_CALCULATION: Calculation = {
  id: 'aadsfo',
  price: 2.0,
  priceFormatted: 'US$2.00',
  currencyCode: 'USD',
  co2: 24,
  co2Unit: 'lb',
  quantity: 2,
  variantId: 'a812lksj',
  co2Shipping: 14,
  co2Manufacturing: 10
};
