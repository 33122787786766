import { formatNumber } from '@ecocart/universal-utils';
import { CurrentInvoice } from '@utils/api/payments';

interface CardProps {
  title: string;
  value: string | undefined;
  format: (item: CurrentInvoice) => string;
}

export const offsetCardsConfig: CardProps[] = [
  {
    title: 'Total Amount Due',
    value: undefined,
    format: (currentInvoice: CurrentInvoice) =>
      formatNumber(currentInvoice?.total_amount_charged_usd || 0, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'usd'
      })
  },
  {
    title: 'Offset Orders',
    value: undefined,
    format: (currentInvoice: CurrentInvoice) => formatNumber(currentInvoice?.confirmed_orders || 0)
  },
  {
    title: 'Merchant Fees',
    value: undefined,
    format: (currentInvoice: CurrentInvoice): string => {
      const merchantTotalAmount =
        (currentInvoice?.merchant_offset_amount_usd || 0) + (currentInvoice?.merchant_eco_incentives_contribution_usd || 0);
      // TODO, currency display always in USD ?
      return formatNumber(merchantTotalAmount, { minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: 'usd' });
    }
  },
  {
    title: 'Customer Fees',
    value: undefined,
    format: (currentInvoice: CurrentInvoice): string => {
      const customerTotalAmount = currentInvoice?.customer_offset_amount_usd || 0;
      return formatNumber(customerTotalAmount, { minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: 'usd' });
    }
  }
];

export const pkgProtectionCardsConfig: CardProps[] = [
  {
    title: 'Total Amount Due',
    value: undefined,
    format: (currentInvoice: CurrentInvoice) =>
      formatNumber(currentInvoice?.total_amount_charged_usd || 0, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'usd'
      })
  },
  {
    title: 'Protected Orders',
    value: undefined,
    format: (currentInvoice: CurrentInvoice) => formatNumber(currentInvoice?.confirmed_orders || 0)
  }
];
