import { colors } from '@utils/tailwind';
import CheckBox, { CheckboxProps } from 'expo-checkbox';
import { ReactNode } from 'react';
import { StyleProp, View } from 'react-native';

export interface EcoCheckboxProps extends CheckboxProps {
  value: boolean;
  containerStyle?: StyleProp<any>;
  children?: ReactNode;
  style?: StyleProp<any>;
}

export function EcoCheckbox({ value, containerStyle, style, children, color, ...otherProps }: EcoCheckboxProps): JSX.Element {
  return (
    <View className="flex-row items-center" style={containerStyle}>
      <CheckBox
        className="inline-block"
        accessibilityRole="checkbox"
        color={color || colors.secondary.DEFAULT}
        value={value || false}
        {...otherProps}
        style={style}
      />
      <View className="inline ml-2">{children}</View>
    </View>
  );
}
