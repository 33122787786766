import TranslationsForm from '@components/forms/TranslationsForm';
import { EcoAlert, EcoPicker, EcoTabContent, EcoTabs } from '@components/shared';
import EcoScrollView from '@components/views/EcoScrollView';
import { LOCALES, LOCALE_TO_LANGUAGE, Locale } from '@ecocart/entities';
import { Translation, translations as stored_translations } from '@ecocart/universal-utils';
import { useOverlay } from '@hooks/useOverlay';
import { GlobalScreenProps } from '@navigation/types';
import { InternalToolsTabs } from '@utils/constants/tabs';
import { Gap } from '@utils/layout';
import { useState } from 'react';
import { View } from 'react-native';
import { useFeaturePermission } from '../hooks/useFeaturePermission';

export default function TranslationsScreen({}: GlobalScreenProps): JSX.Element {
  const { showSuccess, showWarning } = useOverlay();
  const [activeLocale, setActiveLocale] = useState<Locale>('en');
  const [translations, setTranslations] = useState<Translation>(stored_translations);
  const { isFeaturePermissioned } = useFeaturePermission();

  const handleClipboardPress = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(JSON.stringify(translations))
        .then(() => {
          showSuccess('Updated translations copied! Please send it to Nate, Elvis, or Meggo for additional processing.', {
            shouldTruncate: false
          });
        })
        .catch((error) => {
          showWarning('Failed to copy to clipboard: ', error);
        });
    }
  };

  return (
    <EcoScrollView>
      <EcoTabs tabs={InternalToolsTabs} prefix="Internal Tools" activeScreen="Translations">
        <EcoTabContent>
          <View style={Gap()}>
            <View className="flex-row justify-end items-center" style={Gap()}>
              <EcoPicker
                // sort locales alphabetically by its corresponding LOCALE_TO_LANGUAGE value and create dropdown items
                items={[...LOCALES]
                  .sort((a, b) => LOCALE_TO_LANGUAGE[a].localeCompare(LOCALE_TO_LANGUAGE[b]))
                  .map((locale) => ({
                    label: `${LOCALE_TO_LANGUAGE[locale]} (${locale})`,
                    value: locale
                  }))}
                value={activeLocale}
                onValueChange={setActiveLocale}
              />
            </View>
            <EcoAlert
              style={{ border: 'none' }}
              variant="info"
              title="Translation Process"
              subtitle={`Choose a language from the options above to display translations in that specific language.${
                isFeaturePermissioned ? " Click on the 'Translate' icon next to the input field." : ''
              } After the translation update is complete, click on the 'Copy Translations' button. Finally, send the copied text to Nate, Elvis, or Meggo for additional processing.`}
              primaryButton={{
                label: 'Copy Translations',
                onPress: handleClipboardPress
              }}
              shouldTruncate={false}
            />
            <View className="border border-gray-200 rounded-sm p-4">
              {LOCALES.map((locale) =>
                locale === activeLocale ? (
                  <TranslationsForm
                    key={crypto.randomUUID()}
                    locale={locale}
                    translations={translations}
                    setTranslations={setTranslations}
                  />
                ) : null
              )}
            </View>
          </View>
        </EcoTabContent>
      </EcoTabs>
    </EcoScrollView>
  );
}
