import { isValidJSONString } from '@ecocart/universal-utils';
import { isNative } from './constants/device';

export const doDownload = (url: string): void => {
  if (isNative) return;

  window.open(url, '_blank');
};

export const doDownloadAsJSON = (data?: unknown, fileName: string = 'file'): void => {
  if (isNative || !data) return;

  const json = isValidJSONString(data) ? data : JSON.stringify(data);

  const a = document.createElement('a');
  a.href = URL.createObjectURL(new Blob([json], { type: 'application/json' }));
  a.download = `${fileName}.json`;
  a.click();
};
