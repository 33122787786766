import ProjectFundingList from '@components/dashboards/components/ProjectFundingList';
import SustainabilityJourney from '@components/dashboards/components/SustainabilityJourney';
import WelcomeBanner from '@components/dashboards/components/WelcomeBanner';
import { EcoLoader, EcoTab, EcoTabContent, EcoTabs } from '@components/shared';
import EcoScrollView from '@components/views/EcoScrollView';
import { BackLink } from '@navigation/BackLink';
import { GlobalScreenProps } from '@navigation/types';
import { useQuery } from '@tanstack/react-query';
import { getMerchantAdmin } from '@utils/api/merchant';
import { EcoCartApiMerchantTabs, EcoCartGlobalMerchantTabs } from '@utils/constants/tabs';
import React from 'react';
import { View } from 'react-native';

export default function MerchantDashboardScreen({ route }: GlobalScreenProps): JSX.Element {
  const shopName = route?.params?.shopName;
  const { data: merchantAdmin } = useQuery(['merchantAdmin', shopName], () => getMerchantAdmin(shopName));
  const EcoCartMerchantTabs = merchantAdmin?.platform === 'api' ? EcoCartApiMerchantTabs : EcoCartGlobalMerchantTabs;

  return (
    <EcoScrollView>
      <View className="flex-row">
        <BackLink href="/merchants" label="Back to Merchants" />
      </View>
      <EcoTabs
        tabs={EcoCartMerchantTabs.map(({ label, screenName }) => {
          return {
            label,
            screenName,
            screenParams: { shopName }
          } as EcoTab;
        })}
        prefix="Merchant"
        activeScreen="MerchantDashboard"
      >
        <EcoTabContent>
          {merchantAdmin ? (
            <View className="bg-tertiary-50 pb-11">
              <WelcomeBanner merchantAdmin={merchantAdmin} />
              <SustainabilityJourney merchantAdmin={merchantAdmin} />
              <ProjectFundingList merchantAdmin={merchantAdmin} />
            </View>
          ) : (
            <EcoLoader size="large" />
          )}
        </EcoTabContent>
      </EcoTabs>
    </EcoScrollView>
  );
}
