import { ProjectList } from '@components/cards/ProjectList';
import { Coordinates } from '@ecocart/entities';
import React from 'react';
import { GlobalScreenProps } from '../navigation/types';

export interface MarkerConfig {
  title: string;
  description: string;
  img?: string;
  onPress?: any;
  coordinates: Coordinates;
}

export default function ProjectsScreen({}: GlobalScreenProps): JSX.Element {
  return <ProjectList />;
}
