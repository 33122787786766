import { EcoButton, EcoInput, EcoText, EcoToggle, EcoTooltip, FormPicker } from '@components/shared';
import { FormFieldsConfig } from '@components/shared/EcoForm';
import { FormErrorProps } from '@components/shared/EcoInput';
import { MerchantAdmin } from '@ecocart/entities';
import { pascalToSpaced } from '@ecocart/universal-utils';
import { useGlobal } from '@hooks/useGlobal';
import { useOverlay } from '@hooks/useOverlay';
import { useRoute } from '@react-navigation/native';
import { useQuery } from '@tanstack/react-query';
import { getMerchant, getMerchantAdmin, updateMerchant, updateMerchantAdmin } from '@utils/api/merchant';
import { sendMerchantAdminUpdateMessage } from '@utils/api/slack';
import { GlobalFormProps } from '@utils/prop-types';
import { CreateValidationSchema } from '@utils/validation';
import { Formik } from 'formik';
import { isEmpty, set } from 'lodash';
import React, { ComponentProps, useState } from 'react';
import { TextInputProps, View } from 'react-native';
import { FormCheckbox } from '../shared/FormCheckbox';
import { MerchantSettingsValidationFieldNames } from './EditMerchantAdminForm.utils';

interface Props extends GlobalFormProps {
  merchantAdmin: MerchantAdmin;
  formFieldsConfig: FormFieldsConfig[];
}

export default function EditMerchantAdminForm({ merchantAdmin, formFieldsConfig, onSuccess }: Props): JSX.Element {
  const { session } = useGlobal();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { handleError, showSuccess, showWarning } = useOverlay();
  const route = useRoute();
  const shopName = (route.params as { shopName: string }).shopName;
  const { data: merchant, refetch } = useQuery(['merchant', shopName], () => getMerchant(shopName));

  // Disable upsell if payor mode is changed to "non-customer paying" i.e. upsell.diable = true
  const handleSyncWithWidget = async (newMerchantAdmin: MerchantAdmin) => {
    const { shopName, payorMode, pkgProtectionEnabled } = newMerchantAdmin;

    if (isEmpty(merchant) || isEmpty(merchant?.widgetMap)) {
      return;
    }

    /** Handle payor mode sync with widget settings */
    const newWidgetMap = { ...merchant.widgetMap };

    for (const [key, widget] of Object.entries(merchant.widgetMap)) {
      if (widget.template !== 'checkout_widget') {
        continue;
      }
      if (widget.elements?.upsell?.disable === false && payorMode !== 'customer_paying') {
        const newWidget = { ...widget };
        set(widget, 'elements.upsell.disable', true);
        newWidgetMap[key] = newWidget;
      }
    }
    /** End of handle payor mode sync with widget settings */

    for (const [key, widget] of Object.entries(newWidgetMap)) {
      if (widget.template === 'checkout_widget' || widget.template === 'order_protection_checkout') {
        const newWidget = { ...widget };
        newWidget.template = pkgProtectionEnabled ? 'order_protection_checkout' : 'checkout_widget';
        newWidgetMap[key] = newWidget;
      }
    }

    await updateMerchant(shopName, { widgetMap: newWidgetMap }, merchant);
    refetch();
  };

  const onSubmit = async (_merchantAdmin: MerchantAdmin) => {
    if (_merchantAdmin?.ecocartActive === false) _merchantAdmin.ecocartEnabled = false;

    try {
      const dbMerchantAdmin = await getMerchantAdmin(shopName);

      if (dbMerchantAdmin.setupStep === 'variant_creation') {
        showWarning(`System is currently creating ${shopName}'s variants. Please try again shortly.`);
      } else {
        setIsSubmitting(true);

        const newMerchantAdmin = await updateMerchantAdmin(_merchantAdmin.shopName, _merchantAdmin);

        showSuccess('Updated merchant admin!');
        await handleSyncWithWidget(newMerchantAdmin);
        onSuccess?.();
        setIsSubmitting(false);

        await sendMerchantAdminUpdateMessage('merchant_settings_change', session, {
          merchantAdmin: _merchantAdmin,
          dbMerchantAdmin: merchantAdmin
        });
      }
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={merchantAdmin}
      onSubmit={onSubmit}
      validationSchema={CreateValidationSchema(MerchantSettingsValidationFieldNames)}
    >
      {({ handleBlur, handleSubmit, setFieldValue, errors, touched, values, isValid }) => (
        <View className="flex flex-col">
          {formFieldsConfig.map(({ field, placeholder, helperText, type, disabled, items, label, tooltipConfig = {} }) => {
            if (type === 'toggle') {
              return (
                <View key={field} className="z-[10001] flex flex-row justify-between mb-3">
                  <EcoText>{label || pascalToSpaced(field)}</EcoText>
                  <EcoTooltip {...tooltipConfig}>
                    <EcoToggle
                      disabled={disabled}
                      value={(values as any)[field]}
                      onValueChange={(value: boolean) => !disabled && setFieldValue(field, value)}
                    />
                  </EcoTooltip>
                </View>
              );
            }

            if (type === 'checkbox') {
              return (
                <View key={field} className="mb-3">
                  <EcoTooltip {...tooltipConfig}>
                    <FormCheckbox
                      key={field}
                      disabled={disabled}
                      label={label || pascalToSpaced(field)}
                      value={(values as any)[field]}
                      onValueChange={(value: boolean) => !disabled && setFieldValue(field, value)}
                    />
                  </EcoTooltip>
                </View>
              );
            }

            if (type === 'picker') {
              return (
                <View key={field} className="mb-3">
                  <EcoTooltip {...tooltipConfig}>
                    <FormPicker
                      key={field}
                      disabled={disabled as boolean}
                      label={label || pascalToSpaced(field)}
                      items={items as any[]}
                      value={(values as any)[field]}
                      onValueChange={(value: string) => !disabled && setFieldValue(field, value)}
                    />
                  </EcoTooltip>
                </View>
              );
            }

            if (type === 'picker-other') {
              return (
                <PickerWithOther
                  label={label || pascalToSpaced(field)}
                  key={field}
                  items={items}
                  initialValue={(values as any)[field]}
                  value={(values as any)[field]}
                  setFieldValue={setFieldValue}
                  field={field}
                  disabled={disabled}
                  onBlur={handleBlur(field)}
                  errors={errors}
                  touched={touched}
                />
              );
            }

            return (
              <View key={field} className="mb-3">
                <EcoTooltip {...tooltipConfig}>
                  <EcoInput
                    key={field}
                    disabled={disabled}
                    label={label || pascalToSpaced(field)}
                    value={(values as any)[field]}
                    placeholder={placeholder || label || pascalToSpaced(field)}
                    onChangeText={(value: string) => !disabled && setFieldValue(field, value)}
                    onBlur={handleBlur(field)}
                    errors={errors}
                    touched={touched}
                    field={field}
                    helperText={helperText}
                  />
                </EcoTooltip>
              </View>
            );
          })}

          <EcoButton className="w-max mt-4" isDisabled={!isValid || isSubmitting} isLoading={isSubmitting} onPress={handleSubmit}>
            Save
          </EcoButton>
        </View>
      )}
    </Formik>
  );
}

const PickerWithOther = ({
  label,
  items,
  value,
  initialValue,
  setFieldValue,
  field,
  disabled,
  onBlur,
  errors,
  touched
}: {
  label: string;
  items?: ComponentProps<typeof FormPicker>['items'];
  value: string;
  initialValue: string;
  setFieldValue: (field: string, value: string) => void;
  field: string;
  disabled?: boolean;
} & FormErrorProps &
  TextInputProps) => {
  // Define known values excluding 'other'
  const knownValues: string[] = ((items as any[]) || [])
    .filter((item) => item.value !== 'other')
    .map((item) => (typeof item.value === 'number' ? String(item.value) : item.value));

  // Determine if initialValue is 'other'
  const isOther = !knownValues.includes(initialValue);

  const [selectedValue, setSelectedValue] = useState(isOther ? 'other' : initialValue);
  const [showInput, setShowInput] = useState(isOther);

  const handleValueChange = (itemValue: string) => {
    const isOtherValue = !knownValues.includes(itemValue);
    setSelectedValue(isOtherValue ? 'other' : itemValue);
    setFieldValue(field, isOtherValue ? value : itemValue);
    setShowInput(isOtherValue);
  };

  return (
    <View className="gap-2 mb-3">
      <FormPicker label={label} disabled={disabled} onValueChange={handleValueChange} value={selectedValue} items={items || []} />
      {showInput && (
        <EcoInput
          onBlur={onBlur}
          errors={errors}
          touched={touched}
          placeholder="Please specify..."
          value={value}
          onChangeText={(val) => setFieldValue(field, val)}
          disabled={disabled}
          field={field}
        />
      )}
    </View>
  );
};
