import { EcoCard, EcoTable, EcoTag, EcoText, EcoWidget } from '@components/shared';
import { ColumnConfig, FilterConfig, filterConfigToFilters } from '@components/shared/EcoTable.utils';
import { EXPERIMENT_KEYS, ExperimentKey, Merchant, MerchantFlat, Widget, WidgetMap, WidgetPlacement, WidgetUI } from '@ecocart/entities';
import { mergeWidgetData } from '@ecocart/fe-utils';
import { DataQuery } from '@ecocart/universal-utils';
import { KebabTemplate } from '@navigation/LinkingConfiguration';
import { useNavigation } from '@react-navigation/native';
import { useQuery } from '@tanstack/react-query';
import { getMerchants } from '@utils/api/merchant';
import { Gap } from '@utils/layout';
import clsx from 'clsx';
import { format } from 'date-fns';
import { kebabCase } from 'lodash';
import { useState } from 'react';
import { Pressable, View } from 'react-native';
import { WidgetABTestStatus } from './WidgetABTestStatus';

interface MerchantUI extends MerchantFlat {
  widgets: Widget[];
}

const hydrateForUI = (merchant: MerchantFlat): MerchantUI => {
  const merchantUI: MerchantUI = {
    ...merchant,
    widgets: mergeWidgetData(merchant.widgetMap as WidgetMap, merchant.widgetPlacements as WidgetPlacement[])
  };
  return merchantUI;
};

const defaultFilterConfig: FilterConfig<MerchantFlat>[] = [
  {
    field: 'ecocartEnabled',
    operator: 'eq',
    value: true,
    options: [true, false].map((ecocartEnabled) => ({ label: ecocartEnabled ? 'Visible' : 'Hidden', value: ecocartEnabled })),
    enabled: true
  }
  // {
  //   field: 'active',
  //   operator: 'eq',
  //   value: true,
  //   options: [true, false].map((active) => ({ label: active ? 'Installed' : 'Uninstalled', value: active })),
  //   enabled: true
  // }
];

export function WidgetCommandCenter(): JSX.Element {
  const navigation = useNavigation();
  const [filterConfig, setFilterConfig] = useState<FilterConfig<MerchantUI>[]>([
    ...defaultFilterConfig,
    {
      field: 'experimentKey',
      operator: 'eq',
      value: '',
      options: EXPERIMENT_KEYS.map((key) => ({ label: key, value: key })),
      enabled: true
    }
  ]);

  const defaultDataQuery: DataQuery<MerchantFlat> = {
    fields: [
      'active',
      'companyName',
      'experimentKey',
      'experimentVariations',
      'experimentWeights',
      'shopName',
      'widgetMap',
      'widgetPlacements',
      'ecocartEnabled',
      'updatedAt',
      'offsetMultiplier'
    ],
    sorts: [{ field: 'updatedAt', dir: 'desc' }],
    filters: filterConfigToFilters(defaultFilterConfig)
  };

  const { data, isLoading } = useQuery(['merchants'], () => getMerchants({ ...defaultDataQuery, take: 3000 }));

  const handleUpdateFilterConfig = (_filterConfig: FilterConfig<MerchantUI>[]) => {
    setFilterConfig([..._filterConfig]);
  };

  function WidgetLink({ widget, merchant }: { widget: WidgetUI; merchant: MerchantUI }): JSX.Element {
    return (
      <Pressable
        onPress={() =>
          navigation.navigate('MerchantsTab', {
            screen: 'MerchantWidgetEditor',
            params: {
              shopName: merchant.shopName,
              template: kebabCase(widget.template) as KebabTemplate,
              widgetKey: widget.widgetKey
            }
          })
        }
      >
        <EcoWidget widget={widget} merchant={merchant as unknown as Merchant} />
      </Pressable>
    );
  }

  const columnConfig: ColumnConfig<MerchantUI>[] = [
    {
      field: 'shopName',
      searchable: true,
      format: ({
        companyName,
        shopName,
        experimentKey,
        experimentVariations,
        experimentWeights,
        ecocartEnabled,
        updatedAt,
        offsetMultiplier
      }) => (
        <View className="flex flex-col" style={Gap(1)}>
          {experimentKey ? (
            <Pressable onPress={() => navigation.navigate('MerchantsTab', { screen: 'MerchantWidgets', params: { shopName } })}>
              <WidgetABTestStatus
                experiment={{ key: experimentKey as ExperimentKey, variations: experimentVariations, weights: experimentWeights }}
                style={{ borderRadius: 0 }}
              />
            </Pressable>
          ) : null}

          <EcoText fontWeight="semibold">{companyName}</EcoText>
          <Pressable onPress={() => navigation.navigate('MerchantsTab', { screen: 'MerchantSettings', params: { shopName } })}>
            <EcoText color="link" underline={true}>
              {shopName}
            </EcoText>
          </Pressable>
          <View className="flex-row" style={Gap(1)}>
            <EcoTag type={ecocartEnabled ? 'success' : 'error'}>{ecocartEnabled ? 'visible' : 'hidden'}</EcoTag>
            <EcoTag type={offsetMultiplier >= 2 ? 'success' : 'warning'}>
              {offsetMultiplier >= 2 ? 'Climate Positive' : 'Carbon Neutral'}
            </EcoTag>
          </View>
          <EcoText className={clsx(!ecocartEnabled && 'opacity-50')}>
            {updatedAt ? format(new Date(updatedAt), 'MMM d, yyyy') : '--'} (last updated)
          </EcoText>
        </View>
      )
    },
    {
      field: 'widgets',
      title: 'In Store / Product',
      format: (merchant) => (
        <View className="flex flex-col">
          {(merchant.widgets as WidgetUI[])
            .filter((widget) => ['in_store', 'product'].includes((widget as WidgetUI).pageType))
            .map((widget) => (
              <WidgetLink key={merchant.shopName + 'instore' + widget.id} widget={widget} merchant={merchant} />
            ))}
        </View>
      )
    },
    {
      field: 'widgets',
      title: 'Cart',
      format: (merchant) => (
        <View className="flex flex-col">
          {(merchant.widgets as WidgetUI[])
            .filter((widget) => (widget as WidgetUI).pageType === 'cart')
            .map((widget) => (
              <WidgetLink key={merchant.shopName + 'cart' + widget.id} widget={widget} merchant={merchant} />
            ))}
        </View>
      )
    },
    {
      field: 'widgets',
      title: 'Checkout',
      format: (merchant) => (
        <View className="flex flex-col">
          {(merchant.widgets as WidgetUI[])
            .filter((widget) => widget.pageType.startsWith('checkout_'))
            .map((widget) => (
              <WidgetLink key={merchant.shopName + 'checkout' + widget.id} widget={widget} merchant={merchant} />
            ))}
        </View>
      )
    }
  ];

  return (
    <EcoCard title="Widget Command Center">
      <EcoTable
        isLoading={isLoading}
        data={(data?.data || []).map(hydrateForUI)}
        columns={columnConfig}
        defaultDataQuery={defaultDataQuery}
        filterConfig={filterConfig}
        onFilterConfigChange={handleUpdateFilterConfig}
      />
    </EcoCard>
  );
}
