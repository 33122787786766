import { EcoButton, EcoImage, EcoInput, EcoLink, EcoLoader, EcoText } from '@components/shared';
import { GLOBAL_PUBLIC_CONFIG, IMAGE_ASSETS } from '@ecocart/universal-utils';
import { useClaimFormContext } from '@screens/ClaimsScreen';
import { useMutation } from '@tanstack/react-query';
import { validateClaim } from '@utils/api/claim';
import { Gap } from '@utils/layout';
import { colors } from '@utils/tailwind';
import { ClaimValidationSuccessResponse } from '@utils/types/claims';
import { CreateValidationSchema } from '@utils/validation';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { ReactNode, useState } from 'react';
import { View } from 'react-native';

export const ClaimsFormLogin = (): JSX.Element => {
  const { setStep, claimDetails, setClaimDetails } = useClaimFormContext();
  const [status, setStatus] = useState<keyof typeof HEADINGS>('default');
  const onSubmit = () => {
    const { orderNumber, email } = values;
    mutate({ customer_email: email, order_number: orderNumber });
  };

  const formik = useFormik({
    initialValues: {
      orderNumber: claimDetails.orderNumber,
      email: claimDetails.email
    },
    onSubmit,
    validateOnChange: true,
    validationSchema: CreateValidationSchema(['orderNumber', 'email'])
  });

  const { touched, errors, handleBlur, handleSubmit, values } = formik;

  const HEADINGS: Record<
    'Order not found' | 'default' | 'Claim already exists.' | 'Order not protected' | 'Order is canceled',
    { title: ReactNode; subtitle: ReactNode; icon: string }
  > = {
    'Order not found': {
      title: "We couldn't find your order",
      subtitle: (
        <EcoText>
          Please make sure you entered the correct order number and email. Visit our{' '}
          <EcoLink href="https://support.ecocart.io/knowledge/shipping-protection/">help center</EcoLink> for further assistance
        </EcoText>
      ),
      icon: '/broken.svg'
    },
    'Order is canceled': {
      title: 'Looks like this order was canceled',
      subtitle: <EcoText>Please reach out to the merchant's customer support</EcoText>,
      icon: '/broken.svg'
    },
    'default': {
      title: 'Get a resolution in a snap',
      subtitle: "We're sorry you're facing a problem with your order. Let's get it sorted out quickly",
      icon: '/box-lock.svg'
    },
    'Claim already exists.': {
      title: 'We are already working on your claim',
      subtitle: (
        <EcoText>
          Our team is currently investigating your issue with <b>Order No. {values.orderNumber}</b>. For any questions about your claim,
          please contact us at{' '}
          <a href="mailto:claims@ecocart.io" className="text-secondary-500 underline" target="_blank">
            claims@ecocart.io
          </a>
        </EcoText>
      ),
      icon: '/Clipboard.png'
    },
    'Order not protected': {
      title: "Seems like your order doesn't have package protection",
      subtitle: "Please reach out to the merchant's customer support",
      icon: '/broken.svg'
    }
  };

  const headings = HEADINGS[status];

  const { mutate, isLoading } = useMutation(validateClaim, {
    onSuccess: async (data: ClaimValidationSuccessResponse) => {
      setStep('IssueItem');
      setClaimDetails({
        ...claimDetails,
        companyLogo: data.company_logo,
        orderNumber: data.order_number,
        email: data.customer_email,
        shopName: data.shop_name
      });
    },
    onError: async (error: Error, variables) => {
      const validationError = JSON.parse(error.message);
      const claimAlreadyExists = validationError.Message === 'Claim already exists.';
      const orderNotFound = validationError.Message === 'Order not found';

      if (status !== validationError.Message) {
        setStatus(validationError.Message as keyof typeof HEADINGS);
      }
      if (claimAlreadyExists || orderNotFound) {
        setClaimDetails({ ...claimDetails, orderNumber: variables.order_number, email: variables.customer_email });
      }
    }
  });

  const isOrderNotFound = status === 'Order not found';

  return (
    <>
      <View className="gap-2">
        <EcoImage src={GLOBAL_PUBLIC_CONFIG.S3_PUBLIC_IMAGE_BUCKET + headings.icon} height={84} resizeMode="contain" className="mb-4" />
        <EcoText fontSize="4xl" fontWeight="semibold" textAlign="center">
          {headings.title}
        </EcoText>
        <EcoText fontSize="lg" textAlign="center" color="subdued">
          {headings.subtitle}
        </EcoText>
      </View>
      <View className={clsx('my-6 justify-center', status !== 'Claim already exists.' && 'min-h-[250px]')}>
        <form onSubmit={handleSubmit}>
          {isLoading ? (
            <EcoLoader size="large" color={colors.secondary.DEFAULT} />
          ) : status === 'Claim already exists.' ? (
            <EcoButton onPress={() => setStatus('default')} variant="outlined" fullWidth className="mt-4">
              Back
            </EcoButton>
          ) : (
            <View style={Gap(4)}>
              <EcoInput
                label="Order number"
                placeholder="Your order number"
                touched={touched}
                errors={errors}
                field="orderNumber"
                value={formik.values.orderNumber}
                onChangeText={formik.handleChange('orderNumber')}
                onBlur={handleBlur('orderNumber')}
                required
              />
              <EcoInput
                label="Email address"
                placeholder="Your email"
                touched={touched}
                errors={errors}
                field="email"
                value={formik.values.email}
                onChangeText={formik.handleChange('email')}
                onBlur={handleBlur('email')}
                required
              />
              <EcoButton onPress={handleSubmit} fullWidth className="mt-4">
                {isOrderNotFound ? 'Try again' : 'Continue'}
              </EcoButton>
            </View>
          )}
        </form>
      </View>
      <View className="flex-row justify-center items-center mt-8">
        <EcoText fontSize="xs">Powered By</EcoText>
        <EcoImage src={IMAGE_ASSETS.ECOCART} height={14} width={100} resizeMode="contain" />
      </View>
    </>
  );
};
