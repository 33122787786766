import {
  EcoAccordion,
  EcoAlert,
  EcoButton,
  EcoCard,
  EcoInput,
  EcoRadioButtons,
  EcoText,
  EcoToggle,
  EcoWidget,
  FormPicker
} from '@components/shared';
import { ConfigContainer } from '@components/views/ConfigContainer';
import { LOCALES, Merchant } from '@ecocart/entities';
import { IMAGE_ASSETS, translations } from '@ecocart/universal-utils';
import { useOverlay } from '@hooks/useOverlay';
import { BackLink } from '@navigation/BackLink';
import { useNavigation } from '@react-navigation/native';
import { MerchantContext } from '@screens/MerchantWidgetsScreen';
import { updateMerchant } from '@utils/api/merchant';
import { Gap } from '@utils/layout';
import { CreateValidationSchema } from '@utils/validation';
import { useFormik } from 'formik';
import { get } from 'lodash';
import { useContext, useState } from 'react';
import { View } from 'react-native';
import { getInitialValues } from './PromoWidgetDesigner.utils';
import {
  ECLogoVariantValue,
  alignmentItems,
  convertCountryCodeToEnglishName,
  fieldPaths,
  getProps,
  getSelectedAlignmentValue,
  getTranslationPath,
  getZIndexProps,
  handleAlignmentChange,
  handleTypeCaseChange,
  typeCaseItems
} from './utils';

export function PromoWidgetDesigner({ widgetKey }: { widgetKey: string }): JSX.Element {
  const navigation = useNavigation();
  const { showSuccess, handleError } = useOverlay();
  const { merchant, refetch } = useContext(MerchantContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { widgetMap } = merchant || {};
  // AB-TEST-PDP template is always 'standard' for AB Testing purposes
  const isABTest = widgetMap?.[widgetKey]?.template.includes('ab_test');
  const [variant, setVariant] = useState<ECLogoVariantValue>(isABTest ? 'standard' : 'on_pill');
  const isValidWidgetKey = widgetMap && widgetKey in widgetMap;

  if (!isValidWidgetKey) {
    navigateToWidgetList();
  }

  function navigateToWidgetList() {
    if (!merchant) return;
    return navigation.navigate('MerchantWidgetTemplates', { shopName: merchant?.shopName, template: 'promo' });
  }

  const onSubmit = async () => {
    if (!merchant) return;

    setIsSubmitting(true);
    const updatedMerchant: Partial<Merchant> = {
      widgetMap: {
        ...merchant.widgetMap,
        [widgetKey]: values
      }
    };

    try {
      await updateMerchant(merchant.shopName, updatedMerchant, merchant);
      showSuccess('Successfully updated merchant');
      setIsSubmitting(false);
      refetch && refetch();
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: widgetMap?.[widgetKey] || getInitialValues(),
    onSubmit,
    validateOnChange: true,
    validationSchema: CreateValidationSchema(['elements']),
    enableReinitialize: true
  });

  const { values, setFieldValue, isValid, handleSubmit } = formik;

  // when variant changes, set the corresponding color and EC logo
  const handleVariantChange = (variant: ECLogoVariantValue) => {
    let newHeaderColor;
    let newECLogoUrl;
    let newLogoPaddingHorizontal;
    let newLogoPaddingVertical;
    let newLogoBorderRadius;
    let newLogoBorderBackgroundColor;

    switch (variant) {
      case 'on_pill':
        newECLogoUrl = IMAGE_ASSETS.ON_PILL_LOGO;
        newLogoPaddingHorizontal = '8px';
        newLogoPaddingVertical = '6px';
        newLogoBorderRadius = '4px';
        newLogoBorderBackgroundColor = '#0F8354';
        break;
      case 'black_logo':
        newECLogoUrl = IMAGE_ASSETS.ECOCART_BLACK;
        break;
      case 'standard':
        newECLogoUrl = IMAGE_ASSETS.ECOCART;
        break;
      case 'white':
        newHeaderColor = 'white';
        newECLogoUrl = IMAGE_ASSETS.ECOCART_WHITE;
        break;
      default:
        break;
    }
    setFieldValue(fieldPaths.headerColor, newHeaderColor);
    setFieldValue(fieldPaths.logoURL, newECLogoUrl);
    setFieldValue(fieldPaths.logoPaddingLeft, newLogoPaddingHorizontal);
    setFieldValue(fieldPaths.logoPaddingRight, newLogoPaddingHorizontal);
    setFieldValue(fieldPaths.logoPaddingTop, newLogoPaddingVertical);
    setFieldValue(fieldPaths.logoPaddingBottom, newLogoPaddingVertical);
    setFieldValue(fieldPaths.logoBorderRadius, newLogoBorderRadius);
    setFieldValue(fieldPaths.logoBorderBackgroundColor, newLogoBorderBackgroundColor);
  };

  if (!get(values, fieldPaths.logoURL)) {
    handleVariantChange(variant);
  }

  const socialProofEnabled = get(values, fieldPaths.socialProofDisabled) === false;

  return (
    <>
      <View className="pb-4">
        <BackLink onPress={navigateToWidgetList} label="Back To Widgets" />
      </View>

      <View style={Gap()}>
        <EcoCard>
          {isABTest && <EcoAlert variant="warning" title="This is an AB Test widget" closeable={false} className="mb-3" />}
          <EcoText fontSize="xl" fontWeight="semibold" className="mb-2">
            Preview
          </EcoText>
          <View className="w-full">{values && merchant && <EcoWidget widget={values} merchant={merchant} />}</View>
        </EcoCard>
        <EcoCard>
          <ConfigContainer>
            {/* Configuration component */}
            <form onSubmit={formik.handleSubmit}>
              <View style={Gap(8)}>
                <View style={Gap(8)}>
                  <View style={Gap()}>
                    <EcoText fontSize="xl" fontWeight="medium">
                      Widget Name
                    </EcoText>
                    <EcoInput label="Name" {...getProps(formik, fieldPaths.name)} />
                  </View>

                  <View style={Gap()}>
                    <EcoText fontSize="xl" fontWeight="medium">
                      Container
                    </EcoText>

                    <View style={Gap(2)}>
                      <EcoText fontSize="lg" fontWeight="medium">
                        Behavior
                      </EcoText>
                      <View>
                        <EcoText fontSize="base" fontWeight="medium">
                          Mobile
                        </EcoText>
                        <View className="flex-row flex-wrap" style={Gap()}>
                          <EcoInput
                            className="flex-1"
                            label="Margins"
                            {...getProps(formik, fieldPaths.containerMargin)}
                            placeholder="0px"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Padding"
                            {...getProps(formik, fieldPaths.containerPadding)}
                            placeholder="12px"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Width"
                            {...getProps(formik, fieldPaths.wrapperWidth)}
                            placeholder="100px, 50%, etc"
                          />
                          <FormPicker
                            inputClassName="flex-1"
                            label="Default Alignment"
                            items={alignmentItems}
                            value={getSelectedAlignmentValue(values, 'wrapper', 'mobile')}
                            onValueChange={(val) => handleAlignmentChange(formik, val, 'mobile', 'wrapper')}
                          />
                          <EcoInput
                            className="flex-1"
                            label="Z-Index"
                            {...getZIndexProps(formik, fieldPaths.wrapperZIndex)}
                            placeholder="10, 20, 100, etc"
                          />
                        </View>
                      </View>
                      <View>
                        <EcoText fontSize="base" fontWeight="medium">
                          Desktop
                        </EcoText>
                        <View className="flex-row flex-wrap" style={Gap()}>
                          <EcoInput
                            className="flex-1"
                            label="Margins"
                            {...getProps(formik, fieldPaths.containerMarginMD)}
                            placeholder="0px"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Padding"
                            {...getProps(formik, fieldPaths.containerPaddingMD)}
                            placeholder="12px"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Width"
                            {...getProps(formik, fieldPaths.wrapperWidthMD)}
                            placeholder="100px, 50%, etc"
                          />
                          <FormPicker
                            inputClassName="flex-1"
                            label="Default Alignment"
                            items={alignmentItems}
                            value={getSelectedAlignmentValue(values, 'wrapper', 'desktop')}
                            onValueChange={(val) => handleAlignmentChange(formik, val, 'desktop', 'wrapper')}
                          />
                          <EcoInput
                            className="flex-1"
                            label="Z-Index"
                            {...getZIndexProps(formik, fieldPaths.wrapperZIndexMD)}
                            placeholder="10, 20, 100, etc"
                          />
                        </View>
                      </View>
                    </View>
                  </View>

                  {!isABTest && (
                    <View style={Gap()}>
                      <EcoText fontSize="xl" fontWeight="medium">
                        Content
                      </EcoText>
                      <View>
                        <EcoText fontSize="lg" fontWeight="medium">
                          Header
                        </EcoText>
                        <View style={Gap()}>
                          <EcoInput
                            className="flex-1 mb-3 md:mr-3 md:mb-0"
                            label="Header - English"
                            {...getProps(formik, getTranslationPath('header', 'en'))}
                            placeholder={translations.en.template_offsetYourOrderAtCheckoutWith}
                          />

                          <EcoAccordion header="Show Translations">
                            {LOCALES.map((locale) => (
                              <EcoInput
                                key={`header-${locale}`}
                                className="flex-1"
                                label={`Header - ${convertCountryCodeToEnglishName(locale)}`}
                                {...getProps(formik, getTranslationPath('header', locale))}
                                placeholder={translations[locale].template_offsetYourOrderAtCheckoutWith}
                              />
                            ))}
                          </EcoAccordion>
                        </View>
                      </View>

                      <View>
                        <EcoText fontSize="lg" fontWeight="medium">
                          Style
                        </EcoText>
                        <View className="flex-wrap" style={Gap()}>
                          <View className="flex-row" style={Gap()}>
                            <EcoInput
                              className="flex-1"
                              label="Font Family"
                              {...getProps(formik, fieldPaths.headerFontFamily)}
                              placeholder="system-ui"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Size"
                              {...getProps(formik, fieldPaths.headerFontSize)}
                              placeholder="14px"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Line Height"
                              {...getProps(formik, fieldPaths.headerLineHeight)}
                              placeholder="16px"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Weight"
                              {...getProps(formik, fieldPaths.headerFontWeight)}
                              placeholder="300, 500, bold..."
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Color"
                              {...getProps(formik, fieldPaths.headerColor)}
                              placeholder="#333333"
                            />
                          </View>
                          <FormPicker
                            label="Type Case"
                            inputClassName="md:w-1/5"
                            items={typeCaseItems}
                            value={get(values, fieldPaths.headerTextTransform, '') as string}
                            onValueChange={(val) => handleTypeCaseChange(formik, val, 'header')}
                          />
                        </View>
                      </View>
                    </View>
                  )}

                  {!isABTest && (
                    <View>
                      <View>
                        <EcoText className="pb-2" fontWeight="medium" fontSize="lg">
                          Behavior
                        </EcoText>
                        <EcoText fontWeight="medium">Mobile</EcoText>
                      </View>

                      <View className="flex-row flex-wrap" style={Gap()}>
                        <EcoInput className="flex-1" label="Margins" {...getProps(formik, fieldPaths.headerMargin)} placeholder="20px" />
                        <EcoInput className="flex-1" label="Padding" {...getProps(formik, fieldPaths.headerPadding)} placeholder="12px" />
                        <EcoInput className="flex-1" label="Width" {...getProps(formik, fieldPaths.headerWidth)} placeholder="100%" />
                        <FormPicker
                          inputClassName="flex-1"
                          label="Alignment"
                          items={alignmentItems}
                          value={getSelectedAlignmentValue(values, 'header', 'mobile')}
                          onValueChange={(val) => handleAlignmentChange(formik, val, 'mobile', 'header')}
                        />
                        <EcoInput
                          className="flex-1"
                          label="Z-Index"
                          {...getProps(formik, fieldPaths.headerZIndex)}
                          placeholder="10, 20, 100, etc"
                        />
                      </View>
                      <EcoText fontWeight="medium">Desktop</EcoText>
                      <View className="flex-row flex-wrap" style={Gap()}>
                        <EcoInput className="flex-1" label="Margins" {...getProps(formik, fieldPaths.headerMarginMD)} placeholder="20px" />
                        <EcoInput className="flex-1" label="Padding" {...getProps(formik, fieldPaths.headerPaddingMD)} placeholder="12px" />
                        <EcoInput className="flex-1" label="Width" {...getProps(formik, fieldPaths.headerWidthMD)} placeholder="100%" />
                        <FormPicker
                          inputClassName="flex-1"
                          label="Alignment"
                          items={alignmentItems}
                          value={getSelectedAlignmentValue(values, 'header', 'desktop')}
                          onValueChange={(val) => handleAlignmentChange(formik, val, 'desktop', 'header')}
                        />
                        <EcoInput
                          className="flex-1"
                          label="Z-Index"
                          {...getProps(formik, fieldPaths.headerZIndexMD)}
                          placeholder="10, 20, 100, etc"
                        />
                      </View>
                    </View>
                  )}

                  {!isABTest && (
                    <View style={Gap()}>
                      <EcoText fontSize="xl" fontWeight="medium">
                        Attribution
                      </EcoText>
                      <EcoRadioButtons
                        value={variant}
                        items={[
                          { label: 'On Pill', value: 'on_pill' },
                          { label: 'Standard', value: 'standard' },
                          { label: 'Black Logo', value: 'black_logo' },
                          { label: 'White', value: 'white' }
                        ]}
                        onValueChange={(variant: ECLogoVariantValue) => {
                          setVariant(variant);
                          handleVariantChange(variant);
                        }}
                      />
                    </View>
                  )}
                </View>
                {!isABTest && (
                  <View>
                    <View style={Gap(4)}>
                      <View className="flex-row" style={Gap()}>
                        <EcoText fontSize="xl" fontWeight="medium">
                          Social Proof
                        </EcoText>
                        <EcoToggle
                          value={socialProofEnabled}
                          onValueChange={(val: boolean) => {
                            setFieldValue(fieldPaths.socialProofDisabled, !val);
                          }}
                        />
                      </View>
                      {socialProofEnabled && (
                        <View>
                          <View style={Gap(2)}>
                            <EcoText fontSize="xl" fontWeight="medium">
                              Content
                            </EcoText>
                            <EcoText fontSize="lg" fontWeight="medium">
                              Header Style
                            </EcoText>
                          </View>

                          <View className="flex-row" style={Gap()}>
                            <EcoInput label="Font Family" {...getProps(formik, fieldPaths.socialProofFontFamily)} placeholder="system-ui" />
                            <EcoInput
                              className="flex-1"
                              label="Font Size"
                              {...getProps(formik, fieldPaths.socialProofFontSize)}
                              placeholder="11px"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Line Height"
                              {...getProps(formik, fieldPaths.socialProofLineHeight)}
                              placeholder="14px"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Weight"
                              {...getProps(formik, fieldPaths.socialProofFontWeight)}
                              placeholder="300, 500, bold..."
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Color"
                              {...getProps(formik, fieldPaths.socialProofFontColor)}
                              placeholder="#F5F5F5"
                            />
                          </View>
                          <View className="flex-row flex-wrap" style={Gap()}>
                            <FormPicker
                              className="mt-1 w-1/3 "
                              label="Type Case"
                              items={typeCaseItems}
                              value={get(values, fieldPaths.socialProofTextTransform, '') as string}
                              onValueChange={(val) => handleTypeCaseChange(formik, val, 'social_proof')}
                            />
                          </View>

                          <View className="pt-6 pb-2" style={Gap(4)}>
                            <EcoText fontSize="xl" fontWeight="medium">
                              Container
                            </EcoText>
                            <EcoText fontWeight="medium" fontSize="lg">
                              Behavior
                            </EcoText>
                          </View>

                          <EcoText fontWeight="medium">Mobile</EcoText>
                          <View className="flex-row flex-wrap" style={Gap()}>
                            <EcoInput
                              className="flex-1"
                              label="Margins"
                              {...getProps(formik, fieldPaths.socialProofMargin)}
                              placeholder="20px"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Padding"
                              {...getProps(formik, fieldPaths.socialProofPadding)}
                              placeholder="12px"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Width"
                              {...getProps(formik, fieldPaths.socialProofWidth)}
                              placeholder="100%"
                            />
                            <FormPicker
                              inputClassName="flex-1"
                              label="Alignment"
                              items={alignmentItems}
                              value={getSelectedAlignmentValue(values, 'social_proof', 'mobile')}
                              onValueChange={(val) => handleAlignmentChange(formik, val, 'mobile', 'social_proof')}
                            />
                            <EcoInput
                              className="flex-1"
                              label="Z-Index"
                              {...getProps(formik, fieldPaths.socialProofZIndex)}
                              placeholder="10, 20, 100, etc"
                            />
                          </View>
                          <EcoText fontWeight="medium">Desktop</EcoText>
                          <View className="flex-row flex-wrap" style={Gap()}>
                            <EcoInput
                              className="flex-1"
                              label="Margins"
                              {...getProps(formik, fieldPaths.socialProofMarginMD)}
                              placeholder="20px"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Padding"
                              {...getProps(formik, fieldPaths.socialProofPaddingMD)}
                              placeholder="12px"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Width"
                              {...getProps(formik, fieldPaths.socialProofWidthMD)}
                              placeholder="100%"
                            />
                            <FormPicker
                              inputClassName="flex-1"
                              label="Alignment"
                              items={alignmentItems}
                              value={getSelectedAlignmentValue(values, 'social_proof', 'desktop')}
                              onValueChange={(val) => handleAlignmentChange(formik, val, 'desktop', 'social_proof')}
                            />
                            <EcoInput
                              className="flex-1"
                              label="Z-Index"
                              {...getProps(formik, fieldPaths.socialProofZIndexMD)}
                              placeholder="10, 20, 100, etc"
                            />
                          </View>
                          <EcoText className="pt-4" fontSize="lg" fontWeight="medium">
                            Style
                          </EcoText>
                          <View className="flex-row" style={Gap()}>
                            <EcoInput
                              className="flex-1"
                              label="Background Color"
                              {...getProps(formik, fieldPaths.socialProofBackgroundColor)}
                              placeholder="#F5F5F5"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Border Width"
                              {...getProps(formik, fieldPaths.socialProofBorderWidth)}
                              placeholder="1px"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Border Color"
                              {...getProps(formik, fieldPaths.socialProofBorderColor)}
                              placeholder="#FFFFFF"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Border Radius"
                              {...getProps(formik, fieldPaths.socialProofBorderRadius)}
                              placeholder="0px, 0px, 4px, 4px"
                            />
                          </View>
                        </View>
                      )}
                    </View>
                  </View>
                )}

                <EcoButton isDisabled={isSubmitting || !isValid} onPress={handleSubmit}>
                  Save
                </EcoButton>
              </View>
            </form>

            {/* End of Configuration component */}
          </ConfigContainer>
        </EcoCard>
      </View>
    </>
  );
}
