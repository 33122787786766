import { ChartData } from '@utils/charts';

// An array of 8 objects, each with a date, offset_carbon, and total_carbon property dynamically generated
// The date is a string in the format of '2023-07-13T04:00:00.000Z' starting with today's date using Date Object, and increment by one day for each object
// The ecocart_orders property is a random number between 1000 and 2000
// The total_orders property is a random number between 2000 and 3000
export const DUMMY_DATA_TOTAL_EMISSIONS: ChartData = Array.from({ length: 8 }, (_, idx) => ({
  date: new Date(Date.now() + idx * 24 * 60 * 60 * 1000).toISOString(),
  offset_carbon: Math.floor(Math.random() * 1000) + 1000,
  total_carbon: Math.floor(Math.random() * 1000) + 2000
}));

// An array of 8 objects, each with a date, ecocart_order, and total_orders property dynamically generated
// The date is a string in the format of '2023-07-13T04:00:00.000Z' starting with today's date using Date Object, and increment by one day for each object
// The ecocart_orders property is a random number between 1000 and 2000
// The total_orders property is a random number between 2000 and 3000
export const DUMMY_DATA_CUSTOMER_ADOPTIONS: ChartData = Array.from({ length: 8 }, (_, idx) => ({
  date: new Date(Date.now() + idx * 24 * 60 * 60 * 1000).toISOString(),
  ecocart_orders: Math.floor(Math.random() * 1000) + 1000,
  total_orders: Math.floor(Math.random() * 1000) + 2000
}));

export const getDummyEmptyData = (startDate: number): ChartData =>
  Array.from({ length: 8 }, (_, idx) => ({
    date: new Date(startDate + idx * 24 * 60 * 60 * 1000).toISOString(),
    ecocart_orders: 0,
    total_orders: 0
  }));
