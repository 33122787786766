import LoginCard from '@components/cards/LoginCard';
import ForgotPasswordForm from '@components/forms/ForgotPasswordForm';
import { EcoIcon, EcoKeyboardAvoidingView, EcoLink, EcoText } from '@components/shared';
import { useOverlay } from '@hooks/useOverlay';
import { GlobalScreenProps } from '@navigation/types';
import { useNavigation } from '@react-navigation/native';
import { Gap } from '@utils/layout';
import { LoginScreenView } from '@utils/styles/screen-views';
import { colors } from '@utils/tailwind';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

export default function ForgotPasswordScreen({}: GlobalScreenProps): JSX.Element {
  const { showSuccess } = useOverlay();
  const navigation = useNavigation();

  const handleOnSuccess = (): void => {
    showSuccess('Instructions sent! Please check your email inbox or your phone messages and follow the instructions.');
    navigation.navigate('Login');
  };

  return (
    <SafeAreaView style={[LoginScreenView, { width: '100%', height: '100%', backgroundColor: colors.tertiary[800] }]}>
      <EcoKeyboardAvoidingView className="flex-row justify-center items-center w-full h-full">
        <LoginCard
          title="Forgot Password"
          subTitle="Please enter the email used to create your account. We will send you password reset instructions."
        >
          <View style={Gap()}>
            <ForgotPasswordForm onSuccess={handleOnSuccess} />
            <View className="flex-row justify-center">
              <EcoLink nativeID="forgot-password-form__back-to-login" href="/login" className="flex-row justify-center items-center">
                <EcoIcon name="chevron_left" className="text-secondary-500" />
                <EcoText color="link" className="pl-1">
                  Back to Login
                </EcoText>
              </EcoLink>
            </View>
          </View>
        </LoginCard>
      </EcoKeyboardAvoidingView>
    </SafeAreaView>
  );
}
