import { EcoText, FormPicker } from '@components/shared';
import { EcoIcon } from '@components/shared/EcoIcon';
import { IconSize, ICON_SIZES, MATERIAL_SYMBOLS } from '@components/shared/EcoIcon.utils';
import { InternalToolsCard } from '@components/views/InternalToolsCard';
import { Gap } from '@utils/layout';
import { useState } from 'react';
import { View } from 'react-native';
import { ConfigContainer } from '../views/ConfigContainer';
import { DemoContainer } from '../views/DemoContainer';

export function Icons(): JSX.Element {
  const [size, setSize] = useState<IconSize>();

  return (
    <InternalToolsCard componentName="Icons">
      <DemoContainer>
        <View className="flex-wrap flex-row" style={Gap(5)}>
          {MATERIAL_SYMBOLS.map((name) => (
            <View className="w-[160px] p-2 justify-center items-center" key={name}>
              <EcoIcon name={name} className={`text-${size}`} />
              <EcoText fontSize="sm">{name}</EcoText>
            </View>
          ))}
        </View>
      </DemoContainer>
      <ConfigContainer>
        {/* Configuration component */}
        <FormPicker label="Size" items={[...ICON_SIZES]} value={size} onValueChange={(val) => setSize(val)} />
        {/* End of Configuration component */}
      </ConfigContainer>
    </InternalToolsCard>
  );
}
