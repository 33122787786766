import { createNavigationContainerRef, NavigationAction, NavigationState, PartialState } from '@react-navigation/native';

export const navigationRef = createNavigationContainerRef();

type Action = NavigationAction | ((state: State) => NavigationAction);
type State = NavigationState;

export const RootNavigation = {
  navigate: (name: string, params?: Record<string, any>): void => {
    if (navigationRef.isReady()) {
      navigationRef.navigate(name as any, { ...params, initial: false });
    }
  },
  goBack: (): void => {
    if (navigationRef.isReady()) {
      navigationRef.goBack();
    }
  },
  dispatch: (action: Action): void => {
    if (navigationRef.isReady()) {
      navigationRef.dispatch(action);
    }
  },
  reset: (state: PartialState<State> | State): void => {
    if (navigationRef.isReady()) {
      navigationRef.reset(state);
    }
  }
};
