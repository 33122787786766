import { EcoButton, EcoIcon, EcoImage, EcoInput, EcoText } from '@components/shared';
import { MerchantFileUploader } from '@components/uploaders/MerchantFileUploader';
import { IMAGE_ASSETS } from '@ecocart/universal-utils';
import { useOverlay } from '@hooks/useOverlay';
import { useClaimFormContext } from '@screens/ClaimsScreen';
import { Gap } from '@utils/layout';
import { CreateValidationSchema } from '@utils/validation';
import { useFormik } from 'formik';
import { kebabCase } from 'lodash';
import { View } from 'react-native';

export const ClaimsFormIssueDetail = (): JSX.Element => {
  const { handleError } = useOverlay();
  const { setStep, claimDetails, setClaimDetails } = useClaimFormContext();

  const onSubmit = () => {
    setStep('Resolution');
    setClaimDetails({ ...claimDetails, ...formik.values });
  };

  const formik = useFormik({
    initialValues: {
      issueDetails: claimDetails.issueDetails
    },
    onSubmit,
    validateOnChange: true,
    validationSchema: CreateValidationSchema(['issueDetails'])
  });

  return (
    <>
      <View>
        <View className="mt-6" style={Gap(1)}>
          <EcoText fontSize="4xl" fontWeight="semibold" textAlign="center">
            What happened?
          </EcoText>
          <EcoText fontSize="lg" textAlign="center" color="subdued">
            Please provide more information to process your claim quickly
          </EcoText>
        </View>
      </View>

      <View className="my-6 min-h-[250px] justify-center" style={Gap(6)}>
        <View style={Gap(8)}>
          <EcoInput
            required
            label="Briefly describe the issue"
            multiline
            numberOfLines={8}
            value={formik.values.issueDetails}
            onChangeText={formik.handleChange('issueDetails')}
            placeholder="Description"
          />

          <View style={Gap(1)}>
            <EcoText fontSize="sm" color="subdued">
              Upload photos (jpg or png)
            </EcoText>
            <MerchantFileUploader
              shouldUploadFile={false}
              target="claims"
              type={['jpeg', 'png']}
              buttonProps={{ fullWidth: true }}
              style={{ width: '100%', flex: 1 }}
              transparentErrorColor
              onPick={(uri: string, formData: FormData) =>
                setClaimDetails((prev) => ({ ...prev, localFileUri: uri, file: formData.get('file') as File }))
              }
              onSuccess={(url) => setClaimDetails((prev) => ({ ...prev, fileUrl: url }))}
              onError={function (error: any): void {
                handleError('Error was thrown when uploading image file. Erorr is ' + error);
                throw new Error(`Error was thrown when uploading background image file. Erorr is ${error}`);
              }}
              shopName={claimDetails.shopName}
              fileName={`${kebabCase(claimDetails.shopName)}-${claimDetails.orderNumber}-${Date.now()}`}
            />
            {claimDetails.localFileUri ? (
              <View className="relative mt-2">
                <EcoImage src={claimDetails.localFileUri} height={100} resizeMode="contain" className="mb-4" />
                <View className="absolute top-0 right-0">
                  <EcoButton
                    variant="ghost"
                    size="md"
                    colorScheme="danger"
                    onPress={() => setClaimDetails({ ...claimDetails, fileUrl: '', localFileUri: '' })}
                    className="p-1"
                  >
                    <EcoIcon name="close" />
                  </EcoButton>
                </View>
              </View>
            ) : null}
          </View>
        </View>
        <View>
          <EcoButton onPress={formik.handleSubmit} fullWidth className="mt-4" isDisabled={!formik.values.issueDetails}>
            Continue
          </EcoButton>
          <EcoButton
            variant="ghost"
            onPress={() => {
              setStep('IssueItem');
            }}
            fullWidth
            className="mt-4"
          >
            Back
          </EcoButton>
        </View>
      </View>
      <View className="flex-row justify-center items-center mt-8">
        <EcoText fontSize="xs">Powered By</EcoText>
        <EcoImage src={IMAGE_ASSETS.ECOCART} height={14} width={100} resizeMode="contain" />
      </View>
    </>
  );
};
