import { MerchantOrderCountData, MerchantStatsPeriod, ReturnCustomerResponse } from '@ecocart/entities';
import { REPORTING_API, apiCall } from './api';

export interface ChartRequest {
  merchantId: string;
  startDate?: string;
  endDate?: string;
}

export const getStats = (shopName: string): Promise<MerchantStatsPeriod[] | null> => {
  if (!shopName) return Promise.resolve(null);
  return apiCall<MerchantStatsPeriod[]>('POST', `${REPORTING_API}/stats`, {
    merchantId: shopName
  });
};

export const getOrders = (shopName: string, dateRange: string): Promise<MerchantOrderCountData[] | null> => {
  if (!dateRange) return Promise.resolve(null);
  return apiCall<MerchantOrderCountData[]>('POST', `${REPORTING_API}/orders`, {
    merchantId: shopName,
    period: dateRange
  });
};

export const getReturnCustomerRates = (shopName: string): Promise<ReturnCustomerResponse | null> => {
  if (!shopName) return Promise.resolve(null);
  return apiCall<ReturnCustomerResponse>('POST', `${REPORTING_API}/return-customers`, {
    merchantId: shopName
  });
};
