import { EcoButton, EcoCard, EcoIcon, EcoInput, EcoPicker, EcoText, FormPicker } from '@components/shared';
import { PERMISSIONS, Permission, User } from '@ecocart/entities';
import { useGlobal } from '@hooks/useGlobal';
import { useOverlay } from '@hooks/useOverlay';
import { useNavigation } from '@react-navigation/native';
import { sendUserUpdateMessage } from '@utils/api/slack';
import { availableUserTypes, deleteUser, updateUser } from '@utils/api/user';
import { Gap } from '@utils/layout';
import { GlobalFormProps } from '@utils/prop-types';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Pressable, View } from 'react-native';
import AddMerchantForm from './AddMerchantForm';

interface Props extends GlobalFormProps {
  user: User;
}

function pruneValue<T>(value: unknown, referenceType: object): T | null {
  if (!value || !referenceType) return null;

  return Object.keys(referenceType).reduce((acc: any, prop) => {
    if ((value as any)[prop] !== undefined) {
      acc[prop] = (value as any)[prop];
    }
    return acc;
  }, {});
}

export default function EditUserForm({ user, onSuccess }: Props): JSX.Element {
  const { session } = useGlobal();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { handleError, showSuccess } = useOverlay();
  const navigation = useNavigation();

  const handleDeleteUser = async () => {
    const isConfirmed = confirm(`Are you sure? This will delete ${user.firstName} ${user.lastName} permanently!`);
    if (!isConfirmed) return;

    try {
      const updatedUser = await deleteUser(user.id);
      showSuccess('Successfully deleted user');
      setIsSubmitting(false);
      onSuccess && onSuccess(updatedUser);
      navigation.navigate('Users');
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  const onSubmit = async (_user: User) => {
    setIsSubmitting(true);

    try {
      const safeUser = pruneValue<User>(_user, new User());

      if (safeUser) {
        const updatedUser = await updateUser(_user.id, safeUser);
        showSuccess('Successfully updated user settings');
        setIsSubmitting(false);
        onSuccess && onSuccess(updatedUser);

        await sendUserUpdateMessage('user_profile_change', session, {
          user: updatedUser,
          dbUser: user
        });
      }
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  const getUpdatedPerm = (permissions: Permission[], i: number, text: string): Permission[] => {
    permissions[i] = text as unknown as Permission;
    return permissions;
  };

  return (
    <Formik initialValues={user} onSubmit={onSubmit}>
      {({ handleChange, setFieldValue, handleSubmit, values, isValid }) => (
        <View className="max-w-xl" style={Gap()}>
          {session?.user.type !== 'SHOPIFY_USER' && <EcoInput disabled label="Email" value={values.id} placeholder="Email/ID" />}
          <EcoInput label="First Name" value={values.firstName} onChangeText={handleChange('firstName')} placeholder="First Name" />
          <EcoInput label="Last Name" value={values.lastName} onChangeText={handleChange('lastName')} placeholder="Last Name" />
          {session?.user?.permissions.includes('create_all_users') ? (
            <View style={Gap()}>
              <FormPicker
                label="User Type"
                items={availableUserTypes}
                value={values.userType as unknown as string}
                onValueChange={handleChange('userType')}
              />
              <EcoCard subTitle="Permission">
                <View style={Gap()}>
                  {(values.permissions || []).map((permission, i) => (
                    <EcoPicker
                      key={'perm' + i}
                      items={PERMISSIONS as unknown as string[]}
                      value={permission as unknown as string}
                      onValueChange={(text: string) => setFieldValue('permissions', getUpdatedPerm(values.permissions, i, text))}
                    />
                  ))}
                  <EcoButton onPress={() => setFieldValue('permissions', [...values.permissions, 'get_all_merchants'])}>
                    Add Permission
                  </EcoButton>
                </View>
              </EcoCard>
            </View>
          ) : null}
          {session?.user?.userType === 'ecocart_admin' && (
            <EcoCard subTitle="Merchants">
              <View style={Gap()}>
                {Object.keys(values.merchants || {}).map((shopName) => (
                  <View style={Gap()} key={shopName} className="flex-row justify-between items-center">
                    <EcoText fontSize="lg">{shopName}</EcoText>

                    <Pressable
                      className="px-3"
                      onPress={() => {
                        const merchants = { ...values.merchants };
                        delete merchants[shopName];
                        setFieldValue('merchants', merchants);
                      }}
                    >
                      <EcoIcon name="delete" className="text-danger-500" size="3xl" />
                    </Pressable>
                  </View>
                ))}
                <AddMerchantForm
                  onSuccess={(shopName: string) => {
                    setFieldValue('merchants', { ...values.merchants, [shopName]: true });
                  }}
                />
              </View>
            </EcoCard>
          )}
          <View className="my-4">
            <View className="flex-row justify-between" style={Gap()}>
              <EcoButton
                nativeID="user-settings-page__save-button"
                isDisabled={!isValid || (values.userType === 'merchant_admin' && !Object.keys(values.merchants).length) || isSubmitting}
                isLoading={isSubmitting}
                onPress={handleSubmit}
              >
                Save Changes
              </EcoButton>
              {session?.user?.permissions.includes('create_all_users') ? (
                <EcoButton onPress={handleDeleteUser} colorScheme="danger" className="w-max">
                  Delete User
                </EcoButton>
              ) : null}
            </View>
          </View>
        </View>
      )}
    </Formik>
  );
}
