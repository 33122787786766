import { EcoAlert, EcoCard, EcoDropdown, EcoIcon, EcoLink, EcoTable } from '@components/shared';
import { EcoDropDownItem } from '@components/shared/EcoDropdown.utils';
import { ColumnConfig } from '@components/shared/EcoTable.utils';
import EcoScrollView from '@components/views/EcoScrollView';
import { Contact, EmailTemplateName, FullContact, Merchant } from '@ecocart/entities';
import { useFeaturePermission } from '@hooks/useFeaturePermission';
import { useOverlay } from '@hooks/useOverlay';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ManageSubscriptionParams, getContacts, subscribe, unsubscribe } from '@utils/api/email';
import { format, isValid, toDate } from 'date-fns';
import { View } from 'react-native';
import { GlobalScreenProps } from '../navigation/types';

interface FlattenedContact {
  email: string;
  merchant: Merchant['shopName'];
  lastContacted: number;
  templateName: EmailTemplateName;
  templateId: string;
  active: boolean;
}

export default function CampaignsScreen({}: GlobalScreenProps): JSX.Element {
  const { data, refetch, isLoading } = useQuery<FullContact[]>(['contacts'], () => getContacts());
  const { isFeaturePermissioned } = useFeaturePermission('qa');
  const { handleError, showSuccess, showInfo } = useOverlay();
  const mutation = useMutation<Contact | null, Error, ManageSubscriptionParams & { subscribe: boolean }>(
    (params) => (params.subscribe ? subscribe(params) : unsubscribe(params)),
    {
      onSuccess: () => {
        showSuccess('Updated user subscription');
        refetch();
      },
      onError: () => {
        handleError('Error updating user subscription. Please try again later.');
      }
    }
  );

  const columnConfig: ColumnConfig<FlattenedContact>[] = [
    {
      field: 'email',
      searchable: true,
      flex: 2
    },
    {
      field: 'merchant',
      searchable: true,
      format: ({ merchant }) => (
        <EcoLink href="/merchant/:shopName/campaigns" params={{ shopName: merchant }}>
          {merchant}
        </EcoLink>
      ),
      flex: 3
    },
    {
      field: 'templateName',
      searchable: true,
      flex: 2
    },
    {
      field: 'templateId',
      searchable: true,
      flex: 2
    },
    {
      field: 'lastContacted',
      title: 'Last Contacted (Local Time)',
      searchable: true,
      format: ({ lastContacted }) => {
        const date = toDate(Number(lastContacted));
        if (!isValid(date) || !lastContacted) return '--';
        return format(date, 'MMM d, yyyy - h:mm:ss a');
      },
      flex: 3
    },
    {
      field: 'active',
      flex: 0.5
    },
    {
      field: 'more',
      title: '',
      flex: 1,
      format: (contact: FlattenedContact) => {
        const dropdownItems: EcoDropDownItem[] = [
          {
            label: contact.active ? 'Unsubscribe' : 'Subscribe',
            onPress: () => {
              showInfo('Updating user subscription');
              mutation.mutate({
                email: contact.email,
                merchant: contact.merchant,
                emailTemplateName: contact.templateName,
                subscribe: !contact.active
              });
            }
          }
        ];

        const showDropdown = contact.templateId && contact.templateName;

        return showDropdown ? (
          <View className="flex items-end justify-center">
            <EcoDropdown items={dropdownItems} variant="ghost" showDownArrow={false} size="sm" isDisabled={mutation.isLoading}>
              <EcoIcon name="more_vert" size="2xl" className="text-gray" />
            </EcoDropdown>
          </View>
        ) : (
          <></>
        );
      }
    }
  ];

  const flattenedData: FlattenedContact[] = (data || []).reduce((acc: FlattenedContact[], curr: FullContact) => {
    return [...acc, ...curr.campaigns.map((campaign) => ({ email: curr.email, ...campaign }))];
  }, []);

  return (
    <EcoScrollView>
      <EcoCard title="Contacts">
        {isFeaturePermissioned ? (
          <EcoTable data={flattenedData} isLoading={isLoading} columns={columnConfig} />
        ) : (
          <EcoAlert title="Coming Soon" variant="warning" closeable={false} className="mt-2" />
        )}
      </EcoCard>
    </EcoScrollView>
  );
}
