import { EcoButton, EcoInput, FormPicker } from '@components/shared';
import { ButtonSize, BUTTON_SIZES } from '@components/shared/EcoButton';
import {
  ButtonColorScheme,
  ButtonState,
  ButtonVariant,
  BUTTON_COLOR_SCHEME,
  BUTTON_STATES,
  BUTTON_VARIANTS
} from '@components/shared/EcoButton.utils';
import { IconName } from '@components/shared/EcoIcon.utils';
import { ConfigContainer } from '@components/views/ConfigContainer';
import { DemoContainer } from '@components/views/DemoContainer';
import { InternalToolsCard } from '@components/views/InternalToolsCard';
import { Gap } from '@utils/layout';
import { useState } from 'react';
import { View } from 'react-native';

const LEFT_ICONS: IconName[] = ['mail', 'arrow_back'];
const RIGHT_ICONS: IconName[] = ['mail', 'arrow_forward'];

export function DSButton(): JSX.Element {
  const [leftIconName, setLeftIconName] = useState<IconName | ''>('');
  const [rightIconName, setRightIconName] = useState<IconName | ''>('');
  const [isFullWidth, setIsFullWidth] = useState<'true' | 'false'>('false');
  const [size, setSize] = useState<ButtonSize>('md');
  const [state, setState] = useState<ButtonState>('base');
  const [variant, setVariant] = useState<ButtonVariant>('filled');
  const [color, setColor] = useState<ButtonColorScheme>('default');
  const [content, setContent] = useState('Ipsum Lorem');

  const leftIconItems = [{ label: 'Please select', value: '' }, ...LEFT_ICONS.map((icon) => ({ label: icon, value: icon }))];
  const rightIconItems = [{ label: 'Please select', value: '' }, ...RIGHT_ICONS.map((icon) => ({ label: icon, value: icon }))];

  const handleSetFullWidth = (val: 'true' | 'false') => {
    if (val === 'true') return setIsFullWidth('true');
    return setIsFullWidth('false');
  };

  return (
    <InternalToolsCard componentName="EcoButton">
      <DemoContainer>
        <View style={Gap()}>
          {/* Actual demo component */}
          <EcoButton
            variant={variant}
            colorScheme={color}
            isLoading={state === 'loading'}
            isDisabled={state === 'disabled'}
            size={size}
            fullWidth={isFullWidth === 'true' || false}
            leftIcon={leftIconName || undefined}
            rightIcon={rightIconName || undefined}
            onPress={() => alert('Pressed')}
          >
            {content}
          </EcoButton>
          {/* End of actual demo component */}
        </View>
      </DemoContainer>

      <ConfigContainer>
        {/* Configuration component */}
        <View style={Gap()}>
          <EcoInput label="Button Text" value={content} onChangeText={(v: string) => setContent(v)} placeholder="Enter some text" />
          <View className="flex-row items-center flex-wrap" style={Gap()}>
            <FormPicker
              className="flex-1"
              label="Variant"
              items={[...BUTTON_VARIANTS]}
              value={variant}
              onValueChange={(val) => setVariant(val)}
            />
            <FormPicker
              className="flex-1"
              label="Color Scheme"
              items={[...BUTTON_COLOR_SCHEME]}
              value={color}
              onValueChange={(val) => setColor(val)}
            />
            <FormPicker
              className="flex-1"
              label="Full Width"
              items={['true', 'false']}
              value={isFullWidth}
              onValueChange={(val) => handleSetFullWidth(val)}
            />
          </View>
          <View className="flex-row items-center flex-wrap" style={Gap()}>
            <FormPicker
              className="flex-1"
              label="Button State"
              items={BUTTON_STATES.filter((state) => !(state === 'hover' || state === 'active'))}
              value={state}
              onValueChange={(val) => setState(val)}
            />
            <FormPicker className="flex-1" label="Size" items={[...BUTTON_SIZES]} value={size} onValueChange={(val) => setSize(val)} />
            <FormPicker
              className="flex-1"
              label="Left Icon"
              items={leftIconItems}
              value={leftIconName}
              onValueChange={(val) => setLeftIconName(val)}
            />
            <FormPicker
              className="flex-1"
              label="Right Icon"
              items={rightIconItems}
              value={rightIconName || ''}
              onValueChange={(val) => setRightIconName(val)}
            />
          </View>
        </View>
        {/* End of Configuration component */}
      </ConfigContainer>
    </InternalToolsCard>
  );
}
