import { Session } from '@context/global';
import { Merchant, MerchantAdmin, PageType, User, Widget, WidgetKey, WidgetMap, WidgetPlacement } from '@ecocart/entities';
import { pascalToSpaced } from '@ecocart/universal-utils';
import { isEqual, set } from 'lodash';
import { ENV, SHOPIFY_NODE_API, apiCall } from './api';
import { shouldTriggerBinPackUpdate, triggerBinPackUpdate } from './bin-pack';
import { syncMerchantAdminWithHubspot } from './merchant';

type SlackMessageType =
  | 'ecocart_active_change'
  | 'widgets_enabled_change'
  | 'project_change'
  | 'shopify_product_title_updated'
  | 'shopify_variants_regenerated'
  | 'merchant_settings_change'
  | 'merchant_widget_settings_change'
  | 'merchant_added_billing_info'
  | 'new_user_created'
  | 'user_email_confirmed'
  | 'existing_user_granted_access'
  | 'user_profile_change';

// ~ credito https://stackoverflow.com/questions/8431651/getting-a-getDiff-of-two-json-objects
const getDiff = <T = any>(oldObj: any, newObj: any): Partial<T> | undefined => {
  const result: any = {};
  if (Object.is(oldObj, newObj)) {
    return undefined;
  }
  if (!newObj || typeof newObj !== 'object') {
    return newObj;
  }

  Object.keys(oldObj || {})
    .concat(Object.keys(newObj || {}))
    .forEach((key) => {
      if (newObj[key] !== oldObj[key] && !Object.is(oldObj[key], newObj[key])) {
        result[key] = newObj[key];
      }
      if (typeof newObj[key] === 'object' && typeof oldObj[key] === 'object') {
        const value = getDiff(oldObj[key], newObj[key]);
        if (value !== undefined) {
          result[key] = value;
        }
      }
    });
  return result;
};

const MERCHANT_SETTINGS_TYPES: SlackMessageType[] = ['merchant_added_billing_info', 'project_change', 'widgets_enabled_change'];

/* ---------------------------- message creation ---------------------------- */
const createMerchantPropUpdateStr = (
  prop: keyof Merchant | keyof MerchantAdmin | keyof User,
  value: any,
  dbMerchant?: Partial<Merchant>
): string => {
  if (!dbMerchant) return createDefaultPropUpdateStr(prop, value);

  switch (prop) {
    case 'widgetMap':
      return createWidgetMapPropUpdateStr(value as WidgetMap, dbMerchant);
    case 'widgetPlacements':
      return createWidgetPlacementsPropUpdateStr(value as WidgetPlacement[], dbMerchant);
    default:
      return createDefaultPropUpdateStr(prop, value);
  }
};

const createDefaultPropUpdateStr = (prop: keyof Merchant | keyof MerchantAdmin | keyof User, value: any): string => {
  return `"${pascalToSpaced(prop as string)}" _is now_ *${typeof value === 'object' ? JSON.stringify(value) : value}*`;
};

const createWidgetMapPropUpdateStr = (merchantWidgetMap: WidgetMap, dbMerchant: Partial<Merchant>): string => {
  const dbMerchantWidgetMap = dbMerchant.widgetMap || {};
  const addedWidgetKeys = Object.keys(merchantWidgetMap).filter((widgetKey) => !dbMerchantWidgetMap[widgetKey]);
  const deletedWidgetKeys = Object.keys(dbMerchantWidgetMap).filter((widgetKey) => !merchantWidgetMap[widgetKey]);
  const updatedWidgetKeys = Object.keys(merchantWidgetMap).filter((widgetKey) => {
    return (
      !!dbMerchantWidgetMap[widgetKey] && JSON.stringify(merchantWidgetMap[widgetKey]) !== JSON.stringify(dbMerchantWidgetMap[widgetKey])
    );
  });

  const safeWidgetMap: WidgetMap = { ...dbMerchantWidgetMap, ...merchantWidgetMap };
  const renderString = (widgetKey: string, seperator: string) => {
    const widget = safeWidgetMap[widgetKey];
    const diff = getDiff<Widget>(dbMerchantWidgetMap[widgetKey], merchantWidgetMap[widgetKey]);

    if (diff) {
      return `*${widget?.template} (${widgetKey}) _${seperator}_*\n${Object.keys(diff)
        .map((prop) => `  "${prop}" _is now_ *${JSON.stringify(diff[prop as keyof Widget])}*`)
        .join('\n')}\nhttps://app.ecocart.io/merchant/${dbMerchant?.shopName}/widgets/${widget.template}/${widgetKey}`;
    } else {
      return '';
    }
  };

  return [
    ...addedWidgetKeys.map((widgetKey) => renderString(widgetKey, 'was created')),
    ...deletedWidgetKeys.map((widgetKey) => renderString(widgetKey, 'was deleted')),
    ...updatedWidgetKeys.map((widgetKey) => renderString(widgetKey, 'was updated'))
  ].join('\n');
};

type WidgetVisibleCount = Record<WidgetKey, { visible: number; hidden: number }>;
const createWidgetPlacementsPropUpdateStr = (merchantWidgetPlacements: WidgetPlacement[] = [], dbMerchant: Partial<Merchant>): string => {
  const dbMerchantWidgetPlacements = dbMerchant.widgetPlacements || [];

  const getPlacementMap = (placements: WidgetPlacement[] = []): Record<PageType, WidgetVisibleCount> => {
    return placements.reduce((acc, placement) => {
      const { pageType, pathname, widgetKey, visible } = placement;
      const currentCount = acc?.[pageType || pathname]?.[widgetKey]?.[visible ? 'visible' : 'hidden'] || 0;
      set(acc, `${pageType || pathname}.${widgetKey}.${visible ? 'visible' : 'hidden'}`, currentCount + 1);
      return acc;
    }, {} as Record<PageType, WidgetVisibleCount>);
  };

  const newPlacementMap = getPlacementMap(merchantWidgetPlacements);
  const oldPlacementMap = getPlacementMap(dbMerchantWidgetPlacements);
  const changed = JSON.stringify(newPlacementMap) !== JSON.stringify(oldPlacementMap);

  return changed
    ? `*Widget Placements* _are now_ \n${
        Object.keys(newPlacementMap).length > 0
          ? Object.keys(newPlacementMap).reduce((acc, curr) => {
              const pageType = curr as PageType;
              const widgetVisibleCount = newPlacementMap[pageType] as WidgetVisibleCount;
              const { visible, hidden } = Object.keys(widgetVisibleCount).reduce(
                (acc, curr) => {
                  const widget = widgetVisibleCount[curr as WidgetKey];
                  if (widget.visible) acc.visible += widget.visible;
                  if (widget.hidden) acc.hidden += widget.hidden;
                  return acc;
                },
                { visible: 0, hidden: 0 }
              );

              const parts = [`  ${pageType}:`];
              if (visible > 0) parts.push(`(${visible}) visible`);
              if (hidden > 0) parts.push(`(${hidden}) hidden`);
              parts.push('\n');
              acc += parts.join(' ');

              return acc;
            }, '')
          : 'REMOVED'
      }`
    : '';
};

const createUserStr = (session: Session | null | undefined): string => {
  if (!session) {
    return 'Unknown user';
  } else if (session.user.type === 'SHOPIFY_USER') {
    return `Shopify Store User - ${session?.user?.userType}`;
  } else {
    let msg = '';
    if (session?.user?.firstName) msg += session.user.firstName;
    if (session?.user?.lastName) msg += ` ${session.user.lastName}`;
    return msg + ` (${session?.user?.id}${session?.user?.userType ? ' | ' + session.user.userType : ''})`;
  }
};

const getChangedProps = <T>(newObj: Partial<T>, oldObj?: Partial<T>, excludedProps: Partial<keyof T>[] = []): Partial<T> => {
  if (!oldObj) return newObj;

  return Object.keys(newObj).reduce((acc, prop) => {
    const oldProp = oldObj[prop as keyof T];
    const newProp = newObj[prop as keyof T];

    if (!excludedProps.includes(prop as keyof T) && !isEqual(oldProp, newProp)) {
      (acc as any)[prop] = (newObj as any)[prop];
    }
    return acc;
  }, {} as Partial<T>);
};

/* -------------------------------- Merchant -------------------------------- */
export const EXCLUDED_WIDGET_PROPS: Partial<keyof Merchant>[] = ['variantIds', 'project', 'widgetAllSettings', 'createdAt', 'updatedAt'];
export const sendMerchantUpdateMessage = async (
  type: SlackMessageType,
  session: Session | null | undefined,
  { merchant, shpat, dbMerchant }: { merchant?: Partial<Merchant>; dbMerchant?: Partial<MerchantAdmin>; shpat?: string }
): Promise<any> => {
  if (ENV !== 'PROD') return Promise.resolve(null);

  const shopName = merchant?.shopName || dbMerchant?.shopName || '';
  const headers = shpat ? { 'X-Eco-Shopify-Access-Token': shpat } : (null as any);
  const title = `*WIDGET SETTINGS UPDATED (PORTAL)*`;

  let data;
  let updatedMerchantProps: Partial<Merchant>;

  switch (type) {
    case 'merchant_widget_settings_change':
    default:
      if (!merchant) return;

      updatedMerchantProps = getChangedProps<Merchant>(merchant, dbMerchant, EXCLUDED_WIDGET_PROPS);

      if (!Object.keys(updatedMerchantProps).length) return;

      data = Object.keys(updatedMerchantProps)
        .map((key) => {
          const _key = key as keyof Merchant;
          const value = updatedMerchantProps[_key];
          return createMerchantPropUpdateStr(_key, value, { shopName, ...dbMerchant });
        })
        .join('\n');
      break;
  }

  let message = title;
  if (shopName) message += `\nurl: ${shopName}`;
  message += `\nperformed by: ${createUserStr(session)}`;
  if (shopName) message += `\ndetails: https://app.ecocart.io/merchant/${shopName}/widgets`;

  if (data) message += `\n-----\n${data}`;

  return apiCall('POST', `${SHOPIFY_NODE_API}/send-slack-message`, { message }, headers);
};

/* ------------------------------ MerchantAdmin ----------------------------- */
export const EXCLUDED_MERCHANT_ADMIN_PROPS: Partial<keyof MerchantAdmin>[] = [
  'variantIds',
  'billingAddress',
  'manufacturingAddress',
  'fulfillmentAddress',
  'project',
  'shopifyCategories',
  'createdAt',
  'updatedAt'
];
export const sendMerchantAdminUpdateMessage = async (
  type: SlackMessageType,
  session: Session | null | undefined,
  {
    merchantAdmin,
    shpat,
    dbMerchantAdmin
  }: { merchantAdmin?: Partial<MerchantAdmin>; dbMerchantAdmin?: Partial<MerchantAdmin>; shpat?: string }
): Promise<any> => {
  if (ENV !== 'PROD') return Promise.resolve();

  const shopName = merchantAdmin?.shopName || dbMerchantAdmin?.shopName || session?.merchantAdmin?.shopName || '';
  const headers = shpat ? { 'X-Eco-Shopify-Access-Token': shpat } : (null as any);

  const UPDATE_MERCHANT_SETTINGS_PROMISES = [syncMerchantAdminWithHubspot(shopName)];

  let title = '';
  let data;
  let updatedMerchantAdminProps: Partial<MerchantAdmin>;

  switch (type) {
    case 'new_user_created':
      title = `*NEW USER CREATED*`;
      break;
    case 'user_email_confirmed':
      title = `*USER EMAIL CONFIRMED / FIRST LOGIN*`;
      break;
    case 'merchant_added_billing_info':
      title = `*MERCHANT ADDED BILLING INFO*`;
      break;
    case 'existing_user_granted_access':
      title = `*USER GRANTED STORE ACCESS*`;
      break;
    case 'project_change':
      title = `*MERCHANT PROJECT CHANGED*`;
      data = `"Project" _is now_ *${merchantAdmin?.project?.name}*`;
      break;
    case 'ecocart_active_change':
      if (merchantAdmin?.ecocartActive) {
        title = `*MERCHANT ACTIVATED ECOCART*`;
      } else {
        title = `*MERCHANT DEACTIVATED ECOCART (HIDES WIDGETS + DISABLES BILLING)*`;
      }
      break;
    case 'widgets_enabled_change':
      title = `*MERCHANT WIDGETS ${merchantAdmin?.ecocartEnabled ? 'ENABLED' : 'DISABLED'}*`;
      break;
    case 'shopify_variants_regenerated':
      title = `*SHOPIFY VARIANTS REGENERATED*`;
      break;
    case 'shopify_product_title_updated':
      title = `*SHOPIFY PRODUCT TITLE UPDATED*`;
      break;
    case 'merchant_widget_settings_change':
      break;
    case 'merchant_settings_change':
    default:
      if (!merchantAdmin) return;

      updatedMerchantAdminProps = getChangedProps<MerchantAdmin>(merchantAdmin, dbMerchantAdmin, EXCLUDED_MERCHANT_ADMIN_PROPS);

      if (!Object.keys(updatedMerchantAdminProps).length) return;

      if (dbMerchantAdmin && shouldTriggerBinPackUpdate(merchantAdmin, dbMerchantAdmin)) {
        title = `*MERCHANT SETTINGS UPDATED, INCLUDING FINANCIAL SETTINGS - TRIGGERING BIN-PACK PROCESS*...`;

        // default to `defra_store` as `lca` is deprecated
        UPDATE_MERCHANT_SETTINGS_PROMISES.push(triggerBinPackUpdate(shopName, merchantAdmin.manufacturingCalculationType || 'defra_store'));

        // ** DISABLE UNTIL AUTOMATIC UPDATE PLAN ALIGNED WITH CORE TEAM
        // const shopifyAccessToken = merchantAdmin.accessToken || dbMerchantAdmin.accessToken || '';
        // const offsetMultiplier = merchantAdmin.offsetMultiplier;
        // if (shopifyAccessToken && offsetMultiplier && shouldTriggerProductNameUpdate(merchantAdmin, dbMerchantAdmin)) {
        //   title = `*MERCHANT SETTINGS UPDATED, INCLUDING OFFSET-MULTIPLIER - TRIGGERING BIN-PACK PROCESS + UPDATING PRODUCT NAME*...`;
        //   UPDATE_MERCHANT_SETTINGS_PROMISES.push(triggerProductNameUpdate(shopName, offsetMultiplier, shopifyAccessToken));
        // }
      } else {
        title = `*MERCHANT SETTINGS UPDATED*`;
      }

      data = Object.keys(updatedMerchantAdminProps)
        .map((key) => {
          const _key = key as keyof MerchantAdmin;
          const value = updatedMerchantAdminProps[_key];
          return createDefaultPropUpdateStr(_key, value);
        })
        .join('\n');
      break;
  }

  let message = title;
  if (shopName) message += `\nurl: ${shopName}`;
  message += `\nperformed by: ${createUserStr(session)}`;
  if (shopName) message += `\ndetails: https://app.ecocart.io/merchant/${shopName}/settings`;

  if (data) message += `\n-----\n${data}`;

  return apiCall('POST', `${SHOPIFY_NODE_API}/send-slack-message`, { message }, headers).then(() => {
    return MERCHANT_SETTINGS_TYPES.includes(type)
      ? Promise.all(UPDATE_MERCHANT_SETTINGS_PROMISES)
      : new Promise((resolve) => resolve(null));
  });
};

/* ---------------------------------- User ---------------------------------- */
export const EXCLUDED_USER_PROPS: Partial<keyof User>[] = ['updatedAt'];
export const sendUserUpdateMessage = async (
  type: SlackMessageType,
  session: Session | null | undefined,
  { user, shpat, dbUser }: { user?: Partial<User>; dbUser?: Partial<User>; shpat?: string }
): Promise<any> => {
  if (ENV !== 'PROD') return Promise.resolve();

  const userId = user?.id || session?.user?.id || '';
  const headers = shpat ? { 'X-Eco-Shopify-Access-Token': shpat } : (null as any);
  const title = `*USER UPDATED*`;

  let data;
  let updatedUserProps: Partial<User>;

  switch (type) {
    case 'user_profile_change':
    default:
      if (!user) return;

      updatedUserProps = getChangedProps<User>(user, dbUser, EXCLUDED_USER_PROPS);

      if (!Object.keys(updatedUserProps).length) return;

      data = Object.keys(updatedUserProps)
        .map((key) => {
          const _key = key as keyof User;
          const value = updatedUserProps[_key];
          return createDefaultPropUpdateStr(_key, value);
        })
        .join('\n');
      break;
  }

  let message = title;
  message += `\nperfomed by: ${createUserStr(session)}`;
  if (userId) message += `\ndetails: https://app.ecocart.io/user/${userId}`;
  if (data) message += `\n-----\n${data}`;

  return apiCall('POST', `${SHOPIFY_NODE_API}/send-slack-message`, { message }, headers);
};

export const sendRewardRedemptionRequestMessage = async ({
  session,
  redemptionAmount,
  redemptionName
}: {
  session: Session | null | undefined;
  redemptionAmount: string;
  redemptionName: string;
}): Promise<any> => {
  if (ENV !== 'PROD') return Promise.resolve();
  const conversationId = 'C06SNN5HP5Y'; // Slack channel `eco-rewards-requests`
  const shopName = session?.merchantAdmin?.shopName || '';

  const data = `Request Amount: ${redemptionAmount}\nRedemption Type: ${redemptionName}`;

  let message = `*REDEMPTION REQUEST*`;
  const userId = session?.user?.id || '';
  if (shopName) message += `\nurl: ${shopName}`;
  message += `\nperfomed by: ${createUserStr(session)}`;
  if (userId) message += `\ndetails: https://app.ecocart.io/user/${userId}`;
  message += `\n-----\n${data}`;

  return apiCall('POST', `${SHOPIFY_NODE_API}/send-slack-message`, { message, conversationId });
};
