import { GlobalStylesProps } from '@utils/prop-types';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { View } from 'react-native';

interface Props extends GlobalStylesProps {
  children: ReactNode;
}

export function FormButtons({ children, style }: Props): JSX.Element {
  if (!children) {
    console.error('must configure at least one button!');
    return null as any;
  }

  return (
    <View className={clsx('flex-row w-full my-[12px] flex-auto')} style={style}>
      {children}
    </View>
  );
}
