import LoginCard from '@components/cards/LoginCard';
import LoginForm from '@components/forms/LoginForm';
import { EcoKeyboardAvoidingView, EcoLink } from '@components/shared';
import { GlobalScreenProps } from '@navigation/types';
import { Gap } from '@utils/layout';
import { LoginScreenView } from '@utils/styles/screen-views';
import { colors } from '@utils/tailwind';
import React from 'react';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

export default function LoginScreen({ route }: GlobalScreenProps): JSX.Element {
  return (
    <SafeAreaView style={[LoginScreenView, { width: '100%', height: '100%', backgroundColor: colors.tertiary[800] }]}>
      <EcoKeyboardAvoidingView className="flex-row justify-center items-center w-full h-full">
        <LoginCard title="Log In">
          <View style={Gap()}>
            <LoginForm email={route.params?.email || ''} />
            <View className="flex-row justify-center">
              <EcoLink href="/forgot-password" className="pt-2 flex-row justify-center" nativeID="login-form__forgot-password-button">
                Forgot Password?
              </EcoLink>
            </View>
          </View>
        </LoginCard>
      </EcoKeyboardAvoidingView>
    </SafeAreaView>
  );
}
