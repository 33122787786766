import { TooltipPlacement } from './EcoTooltip';

export const TooltipPlacementMap: Record<TooltipPlacement, string> = {
  top: 'bottom-0 left-1/2 mb-4 mx-0',
  bottom: 'top-full left-1/2 mt-4 mx-0',
  left: 'right-full top-1/2 mr-4 my-0',
  right: 'left-full top-1/2 ml-4 my-0'
};

export const ArrowPlacementMap: Record<TooltipPlacement, string> = {
  top: 'top-0 left-1/2 -mt-4',
  bottom: 'top-full left-1/2 mt-2',
  left: 'top-1/2 right-full mr-2',
  right: 'top-1/2 left-full mr-2'
};

export const ArrowTransformMap: Record<TooltipPlacement, any> = {
  top: { transform: [{ rotate: '180deg' }, { translateX: '50%' }] },
  bottom: { transform: [{ translateX: '-50%' }] },
  left: { transform: [{ rotate: '90deg' }, { translateX: '-25%' }, { translateY: '-50%' }] },
  right: { transform: [{ rotate: '270deg' }, { translateX: '25%' }, { translateY: '50%' }] }
};

export const TooltipTransformMap: Record<TooltipPlacement, any> = {
  top: { transform: [{ translateX: '-50%' }] },
  bottom: { transform: [{ translateX: '-50%' }] },
  left: { transform: [{ translateY: '-50%' }] },
  right: { transform: [{ translateY: '-50%' }] }
};
