import { EcoButton, EcoCard, EcoImage, EcoInput, EcoText } from '@components/shared';
import { FormCheckbox } from '@components/shared/FormCheckbox';
import { CreditCard } from '@utils/api/payments';
import { CC_HEIGHT_ASPECT_RATIO, CC_WIDTH, getCreditCardBrandLogo, getMonthYear } from '@utils/credit-card';
import { Gap } from '@utils/layout';
import { GlobalFormProps } from '@utils/prop-types';
import React, { useState } from 'react';
import { View } from 'react-native';

interface Props extends GlobalFormProps {
  creditCard: CreditCard;
  handleHideModal: () => void;
  handleSetAsDefault: () => Promise<void>;
  handleShowDeleteModal: () => void;
}

export default function EditCreditCardForm({ creditCard, handleHideModal, handleSetAsDefault, handleShowDeleteModal }: Props): JSX.Element {
  const [isDefault, setIsDefault] = useState(creditCard.default || false);
  const isValidUpdate = isDefault && !creditCard.default;

  return (
    <EcoCard className="md:w-[525px]">
      <EcoText fontWeight="semibold" fontSize="xl" textAlign="center" className="mb-2">
        Edit Credit Card - {creditCard.last4}
      </EcoText>
      <View style={Gap()}>
        <View>
          <EcoInput
            label="Card Number"
            placeholder="4242 4242 4242 4242"
            value={'**** **** ****' + creditCard.last4}
            disabled={true}
            endAdornment={
              <EcoImage
                enableSkeletonLoader={false}
                src={getCreditCardBrandLogo(creditCard.brand)}
                width={CC_WIDTH}
                height={CC_WIDTH * CC_HEIGHT_ASPECT_RATIO}
                resizeMode="contain"
                className="rounded-md"
              />
            }
          />
        </View>
        <View className="justify-evenly sm:flex-row" style={Gap(3)}>
          <EcoInput className="flex-1" value={getMonthYear(creditCard)} disabled={true} label="Exp Date" />
          <EcoInput className="flex-1" label="CVC" placeholder="123" value={creditCard.cvc} disabled={true} />
        </View>
        <View>
          <FormCheckbox
            value={isDefault}
            disabled={!!creditCard.default}
            onValueChange={(val: boolean) => setIsDefault(val)}
            label="Set as default payment"
          />
        </View>
        <View className="flex sm:flex-row flex-wrap" style={Gap()}>
          <EcoButton
            nativeID="add-payment-card-modal__add-card-button"
            colorScheme="danger"
            variant="outlined"
            onPress={handleShowDeleteModal}
            style={{ flex: 1 }}
          >
            Delete Card
          </EcoButton>

          <EcoButton
            nativeID="add-payment-card-modal__add-card-button"
            colorScheme="default"
            variant="filled"
            style={{ flex: 1 }}
            isDisabled={!isValidUpdate}
            onPress={() => {
              handleSetAsDefault();
              handleHideModal();
            }}
          >
            Save
          </EcoButton>
        </View>
      </View>
    </EcoCard>
  );
}
