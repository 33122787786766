import { Gap } from '@utils/layout';
import { GlobalStylesProps } from '@utils/prop-types';
import clsx from 'clsx';
import { truncate } from 'lodash';
import { useState } from 'react';
import { StyleProp, View } from 'react-native';
import { nativeID } from '../../utils/types/nativeID';
import { AlertColorMap, AlertVariant, CloseIconColorMap, IconColorMap, IconNameMap, TextColorMap } from './EcoAlert.utils';
import { EcoButton } from './EcoButton';
import { EcoIcon } from './EcoIcon';
import { EcoText } from './EcoText';

interface ButtonProps {
  nativeID?: nativeID;
  label: string;
  onPress: () => void;
  style?: StyleProp<any>;
}

export interface AlertProps extends GlobalStylesProps {
  title?: string;
  subtitle?: string;
  variant?: AlertVariant;
  closeable?: boolean;
  onClose?: () => void;
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  contentAlignment?: 'center' | 'left';
  style?: StyleProp<any>;
  shouldTruncate?: boolean;
}

export function EcoAlert({
  variant = 'info',
  primaryButton,
  secondaryButton,
  title,
  subtitle,
  onClose,
  closeable = true,
  style,
  contentAlignment = 'left',
  shouldTruncate = true
}: AlertProps): JSX.Element {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    onClose?.();
  };

  const TITLE_MAX_LENGTH = 80;
  const SUBTITLE_MAX_LENGTH = 120;

  const _title =
    typeof title === 'string' && title && title.length > TITLE_MAX_LENGTH && shouldTruncate
      ? truncate(title, { length: TITLE_MAX_LENGTH })
      : title;
  const _subtitle =
    typeof subtitle === 'string' && subtitle && subtitle.length > SUBTITLE_MAX_LENGTH && shouldTruncate
      ? truncate(subtitle, { length: SUBTITLE_MAX_LENGTH })
      : subtitle;

  return show ? (
    <View className={clsx('p-4 rounded-sm', AlertColorMap[variant])} style={style}>
      <View className={clsx('flex flex-col lg:flex-row', contentAlignment === 'center' && 'self-center')} style={Gap()}>
        <View className={clsx(closeable && 'mr-7', 'flex flex-row items-center flex-1')}>
          <EcoIcon name={IconNameMap[variant]} variant="filled" className={clsx('mr-2', IconColorMap[variant])} size="3xl" />
          <View className="flex-row flex-1 flex-wrap" style={Gap(1)}>
            {_title && (
              <EcoText textClassNames={TextColorMap[variant]} fontWeight="semibold">
                {_title}
              </EcoText>
            )}
            {_subtitle && <EcoText textClassNames={TextColorMap[variant]}>{_subtitle}</EcoText>}
          </View>
        </View>

        <View className={clsx({ 'mr-8': closeable }, { 'mt-2': subtitle }, 'flex flex-row items-center lg:mt-0 ml-7 lg:ml-0')}>
          {secondaryButton ? (
            <EcoButton
              nativeID={secondaryButton.nativeID}
              size="sm"
              colorScheme={variant === 'info' ? 'default' : variant}
              onPress={secondaryButton.onPress}
              style={secondaryButton.style}
              variant="ghost"
            >
              {secondaryButton.label}
            </EcoButton>
          ) : null}
          {primaryButton ? (
            <EcoButton
              nativeID={primaryButton.nativeID}
              size="sm"
              variant="outlined"
              colorScheme={variant === 'info' ? 'default' : variant}
              className={clsx(secondaryButton && 'ml-2')}
              style={primaryButton.style}
              onPress={primaryButton.onPress}
            >
              {primaryButton.label}
            </EcoButton>
          ) : null}
        </View>
        {closeable && (
          <View className={clsx('absolute top-1/2 right-0 flex flex-row items-center -translate-y-1/2')}>
            <EcoButton
              size="sm"
              variant="ghost"
              colorScheme={variant === 'info' ? 'default' : variant}
              leftIcon="close"
              onPress={handleClose}
              className={'ml-2'}
              iconClassNames={CloseIconColorMap[variant]}
            />
          </View>
        )}
      </View>
    </View>
  ) : (
    <></>
  );
}
