// Using react context to determine if a tooltip should be rendered
// If there is no value for the context, then the tooltip should not be rendered
// It contains a provider and a hook to access the context value

import { ElementMeasurement, TooltipPlacement } from '@components/shared/EcoTooltip';
import { ArrowPlacementMap, ArrowTransformMap, TooltipPlacementMap, TooltipTransformMap } from '@components/shared/EcoTooltip.utils';
import { BoxShadow } from '@utils/styles/box-shadow';
import clsx from 'clsx';
import React, { ReactNode, createContext, useCallback, useRef, useState } from 'react';
import { View } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withSpring, withTiming } from 'react-native-reanimated';
import { useHover } from 'react-native-web-hooks';

interface TooltipProps {
  element: ReactNode;
  config: TooltipConfig;
}
export interface TooltipContextProps {
  showTooltip: () => void;
  hideTooltip: () => void;
  setTooltip: React.Dispatch<React.SetStateAction<TooltipProps>>;
  setElementMeasurement: React.Dispatch<React.SetStateAction<any>>;
  setIsElementHovered: React.Dispatch<React.SetStateAction<boolean>>;
  setScrollY: React.Dispatch<React.SetStateAction<number>>;
  isTooltipHovered: boolean;
}

interface TooltipConfig {
  placement: TooltipPlacement;
}

const FADE_DURATION = 300;
const OFFSET = -5;

export const TooltipContext = createContext<TooltipContextProps | undefined>(undefined);

export const TooltipProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [tooltip, setTooltip] = useState<TooltipProps>({ element: null, config: { placement: 'bottom' } });
  const [isElementHovered, setIsElementHovered] = useState(false);
  const [elementMeasurement, setElementMeasurement] = useState<ElementMeasurement>();
  // To determine the scroll position of the page and adjust the tooltip position accordingly
  const [scrollY, setScrollY] = useState(0);
  const tooltipRef = useRef(null);
  const isTooltipHovered = useHover(tooltipRef);
  const { placement } = tooltip.config;

  const animatedOffset = useSharedValue(OFFSET);
  const pointerEvents = useSharedValue('none');
  const opacity = useSharedValue(0);

  const fadeIn = useCallback(() => {
    pointerEvents.value = 'auto';
    animatedOffset.value = withSpring(0, { damping: 100 });
    opacity.value = withTiming(1, { duration: FADE_DURATION });
  }, [opacity, animatedOffset, pointerEvents]);

  const fadeOut = useCallback(() => {
    pointerEvents.value = 'none';
    animatedOffset.value = withTiming(OFFSET, { duration: FADE_DURATION });
    opacity.value = withTiming(0, { duration: FADE_DURATION });
  }, [opacity, pointerEvents, animatedOffset]);

  if (isTooltipHovered) {
    fadeIn();
  }

  if (!isTooltipHovered && !isElementHovered) {
    fadeOut();
  }

  const left = elementMeasurement?.left || 0;
  const top = (elementMeasurement?.top || 0) - scrollY;
  const elementWidth = elementMeasurement?.width || 0;
  const elementHeight = elementMeasurement?.height || 0;

  const getTopValue = () => {
    if (placement === 'bottom') {
      return top + elementHeight + animatedOffset.value + scrollY;
    }
    if (placement === 'left' || placement === 'right') {
      return top + elementHeight / 2;
    }
    if (placement === 'top') {
      return top - animatedOffset.value;
    }
  };

  const getLeftValue = () => {
    if (placement === 'bottom') {
      return left + elementWidth / 2;
    }
    if (placement === 'left') {
      return left - animatedOffset.value;
    }
    if (placement === 'right') {
      return left + elementWidth + animatedOffset.value;
    }
    if (placement === 'top') {
      return left + elementWidth / 2;
    }
  };

  const getInitialTopValue = () => {
    if (placement === 'bottom') return top + elementHeight;
    if (placement === 'left' || placement === 'right') return top + elementHeight / 2;
    if (placement === 'top') return top;
  };

  const getInitialLeftValue = () => {
    if (['bottom', 'top'].includes(placement)) return left + elementWidth / 2;
    if (placement === 'left') return left;
    if (placement === 'right') return left + elementWidth;
  };

  const animatedStyle = useAnimatedStyle(() => ({
    opacity: opacity.value,
    top: getTopValue(),
    left: getLeftValue(),
    pointerEvents: pointerEvents.value
  }));

  return (
    <TooltipContext.Provider
      value={{
        showTooltip: fadeIn,
        setTooltip,
        setScrollY,
        hideTooltip: fadeOut,
        setIsElementHovered,
        isTooltipHovered,
        setElementMeasurement
      }}
    >
      <Animated.View
        ref={tooltipRef}
        nativeID="tooltip-wrapper"
        style={[
          {
            position: 'absolute',
            zIndex: 100,
            top: getInitialTopValue(),
            left: getInitialLeftValue()
          },
          // @ts-expect-error - TS doesn't like the translateX/Y
          ['top', 'bottom'].includes(placement) ? { transform: [{ translateX: '-50%' }] } : { transform: [{ translateY: '-50%' }] },
          animatedStyle
        ]}
      >
        {/* invisible element to capture the hovering state of the tooltip */}
        <View
          className={clsx('absolute', {
            'pt-6': placement === 'bottom',
            'pr-6 -left-5': placement === 'left',
            'pl-6 -right-5': placement === 'right',
            'pb-6': placement === 'top'
          })}
          style={[
            ['top', 'bottom'].includes(placement) ? { width: elementWidth } : { width: 20 },
            placement === 'left' || placement === 'right' ? { height: elementHeight } : {},
            // @ts-expect-error - TS doesn't like the translateX/Y
            ['top', 'bottom'].includes(placement) ? { transform: [{ translateX: '-150%' }] } : { transform: [{ translateY: '-50%' }] }
          ]}
        />
        <View className={clsx('arrow-up absolute', ArrowPlacementMap[placement])} style={ArrowTransformMap[placement]} />
        <View
          className={clsx('absolute p-4 rounded-sm bg-secondary-400 min-w-[200px]', TooltipPlacementMap[placement])}
          style={[BoxShadow(), TooltipTransformMap[placement]]}
        >
          {tooltip.element}
        </View>
      </Animated.View>
      {children}
    </TooltipContext.Provider>
  );
};
