import { DESKTOP_WIDTH, isWeb } from '@utils/constants/device';
import { screens } from '@utils/tailwind';
import { ScaledSize, useWindowDimensions } from 'react-native';

export type ScreenSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

interface Props extends ScaledSize {
  isWide: boolean;
  isDesktopWeb: boolean;
  currentBreakpoint: ScreenSize;
  breakpointWidths: Record<ScreenSize, number>;
  isMobileWeb: boolean;
  isWeb: boolean;
  isAboveSMScreen: boolean;
  isAboveMDScreen: boolean;
  isAboveLGScreen: boolean;
  isAboveXLScreen: boolean;
  isAbove2XLScreen: boolean;
}

type Screens = Record<ScreenSize, string>;

const getBreakpoint = (screenWidth: number): ScreenSize => {
  let currentBreakpoint: ScreenSize = 'xs';

  Object.entries(screens as Screens).forEach((screen, idx) => {
    const [key, width] = screen;
    const breakpointWidth = Number(width.replace('px', ''));
    // 99999 is a arbitrary large number for the "next breakpoint" for the last breakpoint
    const nextBreakpointWidth = screens[idx + 1] ? Number(screens[idx + 1].replace('px', '')) : 99999;

    if (screenWidth >= breakpointWidth && screenWidth < nextBreakpointWidth) {
      currentBreakpoint = key as ScreenSize;
    }
  });

  return currentBreakpoint;
};

// an object that stores the width of each breakpoint without the 'px' suffix
const breakpointWidths = Object.entries(screens as Screens).reduce((acc, screen) => {
  const [key, width] = screen;
  acc[key as ScreenSize] = Number(width.replace('px', ''));
  return acc;
}, {} as Record<ScreenSize, number>);

export const useWindow = (): Props => {
  const { width, height, ...remaining } = useWindowDimensions();
  const isWide = width >= DESKTOP_WIDTH;
  const isDesktopWeb = isWeb && isWide;
  const isMobileWeb = isWeb && !isWide;

  const currentBreakpoint = getBreakpoint(width);

  return {
    width,
    height,
    isWeb,
    isWide,
    isDesktopWeb,
    isMobileWeb,
    currentBreakpoint,
    breakpointWidths,
    isAboveSMScreen: width >= breakpointWidths.sm,
    isAboveMDScreen: width >= breakpointWidths.md,
    isAboveLGScreen: width >= breakpointWidths.lg,
    isAboveXLScreen: width >= breakpointWidths.xl,
    isAbove2XLScreen: width >= breakpointWidths['2xl'],
    ...remaining
  };
};
