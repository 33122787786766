import { EcoButton, EcoIcon, EcoImage, EcoInput, FormButtons } from '@components/shared';
import { useGlobal } from '@hooks/useGlobal';
import { useOverlay } from '@hooks/useOverlay';
import { useWindow } from '@hooks/useWindow';
import { addCreditCard, CreditCard, StripeCustomer } from '@utils/api/payments';
import { CC_HEIGHT_ASPECT_RATIO, CC_WIDTH, getCreditCardBrand, getCreditCardBrandLogo, getMonthYear } from '@utils/credit-card';
import { Gap } from '@utils/layout';
import { GlobalFormProps } from '@utils/prop-types';
import { CreateValidationSchema, YEAR_MONTH_MASK } from '@utils/validation';
import { Formik } from 'formik';
import { isEmpty, noop } from 'lodash';
import { useState } from 'react';
import { View } from 'react-native';
import { Masks } from 'react-native-mask-input';
import { EcoForm } from '../shared/EcoForm';

interface Props extends GlobalFormProps {
  creditCard?: CreditCard;
}

export default function EditPaymentMethodForm({ creditCard, onSuccess = noop }: Props): JSX.Element {
  const mode: 'add' | 'edit' = creditCard ? 'edit' : 'add';
  const card_number = creditCard?.card_number || '';
  const exp_monthyear = getMonthYear(creditCard);
  const { session } = useGlobal();
  const { handleError, showWarning } = useOverlay();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { isWide } = useWindow();

  const onSubmit = async ({
    card_number,
    exp_monthyear,
    cvc
  }: Partial<CreditCard> & Partial<StripeCustomer> & { exp_monthyear: string }) => {
    try {
      const [exp_month, exp_year] = exp_monthyear.split('/');

      setIsSubmitting(true);

      if (mode === 'edit') {
        showWarning('Not ready');
      } else {
        const addCreditCardResponse = await addCreditCard({
          store_domain: session?.merchantAdmin?.shopName,
          card_number: card_number?.replace(/ /g, ''),
          exp_month,
          exp_year,
          cvc,
          default: true
        });

        onSuccess && onSuccess(addCreditCardResponse);
      }

      setIsSubmitting(false);
    } catch (error: any) {
      handleError('There was an error adding credit card, please try again.');
      setIsSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        store_domain: '',
        card_number,
        exp_monthyear,
        cvc: ''
      }}
      validationSchema={CreateValidationSchema(['exp_monthyear', 'cvc', 'card_number'])}
      onSubmit={onSubmit}
    >
      {({ handleChange, handleSubmit, isValid, values, errors, touched, dirty, submitForm }) => (
        <EcoForm onEnter={submitForm}>
          <View className="flex flex-col" style={Gap(2)}>
            <View>
              <EcoInput
                mask={Masks.CREDIT_CARD as any}
                label="Card Number"
                placeholder="4242 4242 4242 4242"
                value={values.card_number as string}
                onChangeText={handleChange('card_number')}
                endAdornment={
                  <EcoImage
                    enableSkeletonLoader={false}
                    src={getCreditCardBrandLogo(getCreditCardBrand(values.card_number))}
                    width={CC_WIDTH}
                    height={CC_WIDTH * CC_HEIGHT_ASPECT_RATIO}
                    resizeMode="contain"
                    className="rounded-md"
                  />
                }
                errors={errors}
                touched={touched}
                field="card_number"
              />
            </View>

            <View className="sm:flex-row" style={Gap(isWide ? 2 : 0)}>
              <View>
                <EcoInput
                  mask={YEAR_MONTH_MASK}
                  label="Exp"
                  placeholder="MM/YY"
                  value={values.exp_monthyear}
                  onChangeText={handleChange('exp_monthyear')}
                  errors={errors}
                  touched={touched}
                  field="exp_monthyear"
                />
              </View>
              <View>
                <EcoInput
                  label="CVC"
                  placeholder="123"
                  value={values.cvc as string}
                  onChangeText={handleChange('cvc')}
                  endAdornment={<EcoIcon name="help" className="text-black opacity-50" />}
                  errors={errors}
                  touched={touched}
                  field="cvc"
                />
              </View>
            </View>
            <FormButtons>
              <EcoButton
                nativeID="add-payment-card-modal__add-card-button"
                fullWidth
                isDisabled={!dirty || !isEmpty(errors) || !isValid || isSubmitting}
                isLoading={isSubmitting}
                onPress={handleSubmit}
              >
                Add Card
              </EcoButton>
            </FormButtons>
          </View>
        </EcoForm>
      )}
    </Formik>
  );
}
