import EditStoreDetailsForm from '@components/forms/EditStoreDetailsForm';
import { EcoTabContent, EcoTabs } from '@components/shared';
import EcoScrollView from '@components/views/EcoScrollView';
import { MerchantAdmin } from '@ecocart/entities';
import { useGlobal } from '@hooks/useGlobal';
import { useSettingsTabs } from '@hooks/useSettingsTabs';
import { GlobalScreenProps } from '@navigation/types';
import { View } from 'react-native';

export default function SettingsStoreScreen({}: GlobalScreenProps): JSX.Element {
  const { session, updateSessionMerchantAdmin } = useGlobal();
  const tabs = useSettingsTabs();

  return (
    <EcoScrollView>
      <EcoTabs tabs={tabs} prefix="Settings" activeScreen="SettingsStore">
        <EcoTabContent title="Store Details" subTitle="Edit your store information for your account">
          <View className="w-full h-[1px] bg-black/[.1]" />
          <EditStoreDetailsForm merchantAdmin={session?.merchantAdmin as MerchantAdmin} onSuccess={updateSessionMerchantAdmin} />
        </EcoTabContent>
      </EcoTabs>
    </EcoScrollView>
  );
}
