import { EcoButton, EcoInput } from '@components/shared';
import { MerchantAdmin } from '@ecocart/entities';
import { useGlobal } from '@hooks/useGlobal';
import { useOverlay } from '@hooks/useOverlay';
import { updateShopifyVariantSku } from '@utils/api/shopify';
import { sendMerchantAdminUpdateMessage } from '@utils/api/slack';
import { GlobalFormProps } from '@utils/prop-types';
import { CreateValidationSchema } from '@utils/validation';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { View } from 'react-native';

interface Props extends GlobalFormProps {
  merchantAdmin: MerchantAdmin;
}

export default function EditSkuForm({ merchantAdmin, onSuccess }: Props): JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { handleError, showSuccess } = useOverlay();
  const { session } = useGlobal();

  const onSubmit = async ({ shopName, sku }: Partial<MerchantAdmin>) => {
    if (!shopName || !sku) return;

    try {
      const isConfirmed = confirm(
        `Are you sure you want to update ${shopName}'s sku to "${sku}"? This process will effect all of their store's variants and may take a few minutes to complete!`
      );
      if (!isConfirmed || !shopName || !sku) return;

      setIsSubmitting(true);

      const updatedMerchant = await updateShopifyVariantSku(shopName, sku);
      showSuccess('Successfully updated shopify variant sku!');
      setIsSubmitting(false);

      await sendMerchantAdminUpdateMessage('merchant_settings_change', session, {
        merchantAdmin: updatedMerchant,
        dbMerchantAdmin: merchantAdmin
      });

      onSuccess && onSuccess(updatedMerchant);
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  return (
    <Formik initialValues={merchantAdmin} validateOnMount onSubmit={onSubmit} validationSchema={CreateValidationSchema(['sku'])}>
      {({ handleChange, handleSubmit, values, errors, touched, isValid }) => (
        <View className="flex flex-col">
          <EcoInput
            label="SKU"
            value={values.sku}
            errors={errors}
            touched={touched}
            field="sku"
            onChangeText={handleChange('sku')}
            placeholder="Sku"
          />
          <EcoButton size="sm" className="w-max mt-2" isDisabled={!isValid || isSubmitting} isLoading={isSubmitting} onPress={handleSubmit}>
            Save
          </EcoButton>
        </View>
      )}
    </Formik>
  );
}
