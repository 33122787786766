import { ClaimResolution } from '@ecocart/entities';
import { ResolutionValue } from '@utils/types/claims';

export const ClaimIssueType = {
  stolen: 'Stolen',
  damaged: 'Damaged or Defective',
  missing_items: 'Missing Item',
  wrong_items: 'Wrong Item',
  not_received: 'Marked Delivered But Not Received',
  returned_to_store: 'Returned to Store',
  tracking_not_moving: 'Tracking Not Moving'
};

export const ClaimResolutionType: Record<ClaimResolution, ResolutionValue> = {
  replace: {
    label: 'Replace Item',
    description: 'We will reship the same item to your address',
    iconName: 'local_shipping'
  },
  refund_to_original_payment: {
    label: 'Refund Original Payment',
    description: 'Takes 1-3 business days',
    iconName: 'credit_card'
  }
};
