import EditUserForm from '@components/forms/EditUserForm';
import { EcoCard, EcoLoader, EcoText } from '@components/shared';
import EcoScrollView from '@components/views/EcoScrollView';
import { User } from '@ecocart/entities';
import React from 'react';
import { Pressable } from 'react-native';
import { useGlobal } from '../hooks/useGlobal';
import { GlobalScreenProps } from '../navigation/types';

export default function MyProfileScreen({}: GlobalScreenProps): JSX.Element {
  const { session, initUser, logout } = useGlobal();

  return session?.user ? (
    <EcoScrollView>
      <EcoCard title="Edit Profile">
        <EditUserForm user={session.user} onSuccess={(_user: User) => initUser(_user)} />
      </EcoCard>

      <Pressable className="p-5 items-center" onPress={logout}>
        <EcoText fontWeight="semibold">logout</EcoText>
      </Pressable>
    </EcoScrollView>
  ) : (
    <EcoLoader size="large" />
  );
}
