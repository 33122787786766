import { EcoInput, EcoText, FormPicker } from '@components/shared';
import { ButtonVariant, BUTTON_VARIANTS } from '@components/shared/EcoButton.utils';
import { EcoFilePicker, MIME_TYPES } from '@components/shared/EcoFilePicker';
import { ConfigContainer } from '@components/views/ConfigContainer';
import { DemoContainer } from '@components/views/DemoContainer';
import { InternalToolsCard } from '@components/views/InternalToolsCard';
import { Gap } from '@utils/layout';
import { type DocumentResult } from 'expo-document-picker';
import { useState } from 'react';
import { View } from 'react-native';

export function DSFilePicker(): JSX.Element {
  const [label, setLabel] = useState('Choose File');
  const [fileType, setFileType] = useState<keyof typeof MIME_TYPES>('all');
  const [result, setResult] = useState<DocumentResult | ''>('');
  const [variant, setVariant] = useState<ButtonVariant>('outlined');

  const handlePick = (result: DocumentResult) => {
    setResult(result);
  };

  return (
    <InternalToolsCard componentName="EcoFilePicker">
      <DemoContainer>
        {/* Actual demo component */}
        <EcoFilePicker onPick={handlePick} label={label} type={fileType} variant={variant} />
        {/* End of actual demo component */}
      </DemoContainer>

      <ConfigContainer>
        {/* Configuration component */}
        <View className="items-center flex-wrap flex-row" style={Gap()}>
          <EcoInput className="flex-1" label="Title" value={label} onChangeText={(v: string) => setLabel(v)} placeholder="Button label" />
          <FormPicker
            className="flex-1"
            label="File Type"
            items={Object.keys(MIME_TYPES)}
            value={fileType}
            onValueChange={(val) => setFileType(val)}
          />
          <FormPicker
            className="flex-1"
            label="Variant"
            items={[...BUTTON_VARIANTS]}
            value={variant}
            onValueChange={(val) => setVariant(val)}
          />
        </View>

        <View className="mt-6" style={Gap()}>
          <EcoText fontSize="lg" fontWeight="medium">
            Result:
          </EcoText>
          <EcoText>{JSON.stringify(result, null, 4)}</EcoText>
        </View>
        {/* End of Configuration component */}
      </ConfigContainer>
    </InternalToolsCard>
  );
}
