import { GlobalStylesProps } from '@utils/prop-types';
import { colors } from '@utils/tailwind';
import clsx from 'clsx';
import { FormikErrors, FormikTouched } from 'formik';
import { get } from 'lodash';
import { useContext, useRef, useState } from 'react';
import { Keyboard, Pressable, TextInput, TextInputProps, View } from 'react-native';
import MaskInput, { Mask } from 'react-native-mask-input';
import { useFocus, useHover } from 'react-native-web-hooks';
import { twMerge } from 'tailwind-merge';
import { FormContext } from './EcoForm';
import { EcoIcon } from './EcoIcon';
import { EcoText } from './EcoText';

export type FormErrorProps = { errors?: FormikErrors<any>; touched?: FormikTouched<any>; field?: string };

export type EcoInputProps = TextInputProps &
  FormErrorProps &
  GlobalStylesProps & {
    disabled?: boolean;
    startAdornment?: string | React.ReactNode;
    endAdornment?: string | React.ReactNode;
    mask?: Mask;
    hasError?: boolean;
    labelClassName?: string;
    inputClassName?: string;
    containerClassName?: string;
    type?: 'currency' | 'text' | 'password' | 'number';
    label?: string;
    required?: boolean;
    helperText?: string;
  };

export function EcoInput({
  value,
  label,
  type = 'text',
  disabled = false,
  style,
  startAdornment,
  endAdornment,
  mask,
  errors,
  field,
  touched,
  labelClassName,
  inputClassName,
  containerClassName,
  placeholder,
  required,
  helperText,
  ...inputProps
}: EcoInputProps): JSX.Element {
  const keyboardType = type === 'currency' ? 'decimal-pad' : type === 'number' ? 'numeric' : 'default';
  const disabledProps = disabled ? { editable: false, selectTextOnFocus: false } : {};
  const formContext = useContext(FormContext);
  const ref = useRef(null);
  const passwordIconFef = useRef(null);
  const isHovered = useHover(ref);
  const isFocused = useFocus(ref);
  const [showInputContent, setShowInputContent] = useState<boolean>(type !== 'password');
  const toggleShowInputContent = () => setShowInputContent(!showInputContent);
  const errorMsg = get(errors, field || '') as string | undefined;
  const hasError = errorMsg != null && get(touched, field || '');
  const _label = label || (type === 'password' && 'Password');
  const _placeholder = placeholder || (type === 'password' && 'Password') || '';

  const _endAdornment =
    type === 'password' ? (
      <Pressable ref={passwordIconFef} onPress={toggleShowInputContent}>
        <EcoIcon className="text-gray-600" name={showInputContent ? 'visibility' : 'visibility_off'} />
      </Pressable>
    ) : (
      endAdornment
    );

  const onSubmitEditing = () => {
    Keyboard.dismiss(); // for android, allegedly
    formContext?.onEnter();
  };

  // ** support <MaskInput> from react-native-mask-input, since it's
  // ** based on same prop contract as <TextInput>
  // TODO: evaluate lift for replicating MaskInput functionality
  const Input = mask ? MaskInput : TextInput;
  if (mask) inputProps = { ...inputProps, mask } as any;

  return (
    <View className={twMerge('h-max flex-auto', containerClassName)} style={style}>
      {_label && (
        <EcoText
          fontSize="xs"
          color={hasError ? 'danger' : isFocused && !disabled ? 'accent-secondary' : 'subdued'}
          className={clsx('py-1', labelClassName, disabled && 'text-gray-300')}
        >
          {_label}
          {required ? (
            <EcoText color="danger" className="ml-1">
              *
            </EcoText>
          ) : null}
        </EcoText>
      )}
      <View
        className={clsx(
          'flex flex-row flex-1 rounded-xs outline-none input-base p-0 overflow-hidden transition-colors duration-200',
          isFocused && !disabled && !hasError && 'border-secondary',
          disabled && 'border-gray-200 text-gray-300',
          isHovered && !disabled && 'bg-gray-50',
          hasError && 'border-danger-500 bg-danger-50'
        )}
      >
        <View className="flex flex-row grow items-center w-full">
          {startAdornment ? (
            typeof startAdornment === 'string' ? (
              <EcoText className="pl-4 mr-1 inline-flex flex-row items-center" color={disabled ? 'disabled' : 'subdued'}>
                {startAdornment}
              </EcoText>
            ) : (
              <View className="pl-4 mr-1">{startAdornment}</View>
            )
          ) : (
            <></>
          )}
          <Input
            ref={ref}
            placeholderTextColor={colors.gray[600]}
            value={value}
            blurOnSubmit={true} // for android, alegedly
            returnKeyType="done"
            keyboardType={keyboardType}
            onSubmitEditing={onSubmitEditing}
            className={clsx(
              'border-none outline-none h-full w-full text-lg py-3',
              !startAdornment && 'pl-4',
              !endAdornment && !hasError && 'pr-4!',
              inputClassName
            )}
            placeholder={_placeholder}
            secureTextEntry={!showInputContent}
            {...disabledProps}
            {...inputProps}
          />
          {_endAdornment ? (
            typeof _endAdornment === 'string' ? (
              <EcoText
                className={clsx(!hasError ? 'pr-4' : 'pr-2', 'ml-1 inline-flex flex-row items-center')}
                color={disabled ? 'disabled' : 'subdued'}
              >
                {_endAdornment}
              </EcoText>
            ) : (
              <View className={clsx(!hasError ? 'pr-4' : 'pr-2', 'pl-2')}>{_endAdornment}</View>
            )
          ) : (
            <></>
          )}
          {hasError && (
            <View className={clsx(!endAdornment && 'pl-2', 'pr-4')}>
              <EcoIcon className="text-danger-500" size="2xl" name="report" />
            </View>
          )}
        </View>
      </View>
      {helperText && !hasError && (
        <EcoText fontSize="2xs" color="subdued" className="pb-1 mt-2" style={style}>
          {helperText}
        </EcoText>
      )}
      {hasError && (
        <EcoText fontSize="2xs" color="danger" className="pb-1 mt-2" style={style}>
          {errorMsg}
        </EcoText>
      )}
    </View>
  );
}
