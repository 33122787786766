/* ---------------------------------- Logos --------------------------------- */

import { Text } from 'react-native';
import { EcoIconProps } from './EcoIcon.utils';
import { getFontSizeFromKey } from './EcoText.utils';

export function EcoIcon({ name, size = 'xl', variant = 'outlined', style }: EcoIconProps): JSX.Element {
  return (
    <Text
      className="material-symbols-rounded"
      style={[
        style,
        { fontSize: getFontSizeFromKey(size) },
        variant === 'filled' && { fontVariationSettings: "'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48" }
      ]}
    >
      {name}
    </Text>
  );
}
