import { TooltipContext } from '@context/tooltip';
import { GlobalStylesProps } from '@utils/prop-types';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Pressable } from 'react-native';

export const TOOLTIP_PLACEMENTS = ['top', 'bottom', 'left', 'right'] as const;
export type TooltipPlacement = (typeof TOOLTIP_PLACEMENTS)[number];
export type ElementMeasurement = { left: number; top: number; width: number; height: number };
export interface TooltipProps extends GlobalStylesProps {
  tooltip?: ReactNode;
  placement?: TooltipPlacement;
  children: React.ReactNode;
}

export function EcoTooltip({ tooltip, placement = 'bottom', style, children }: TooltipProps): JSX.Element {
  const [layout, setLayout] = useState<ElementMeasurement>();
  const _tooltipContext = useContext(TooltipContext);
  const { showTooltip, hideTooltip, setElementMeasurement, setTooltip, setIsElementHovered } = _tooltipContext || {};

  const onHoverIn = () => {
    if (!showTooltip || !hideTooltip || !setElementMeasurement || !setIsElementHovered) return;
    setIsElementHovered(true);
    setElementMeasurement(layout);

    if (tooltip && setTooltip) {
      setTooltip({ element: tooltip, config: { placement } });
      showTooltip();
    }
  };

  const onHoverOut = () => {
    if (!showTooltip || !hideTooltip || !setElementMeasurement || !setIsElementHovered) return;
    setIsElementHovered(false);
    hideTooltip();
  };

  // a react ref that refers to the Pressable element
  const elementRef = useRef<any>(null);
  const animationFrameRef = useRef(0);

  useEffect(() => {
    const updateElementPosition = () => {
      if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();
        setLayout({
          top: rect.top,
          left: rect.left,
          width: rect.width,
          height: rect.height
        });
      }
      animationFrameRef.current = requestAnimationFrame(updateElementPosition);
    };

    animationFrameRef.current = requestAnimationFrame(updateElementPosition);

    return () => {
      cancelAnimationFrame(animationFrameRef.current);
    };
  }, []);

  if (!tooltip) return <>{children}</>;

  return (
    <Pressable style={style} ref={elementRef} onHoverIn={onHoverIn} onHoverOut={onHoverOut}>
      {children}
    </Pressable>
  );
}
