import EditShopifyCharge from '@components/forms/EditShopifyCharge';
import { EcoButton, EcoCard, EcoIcon, EcoImage, EcoPill, EcoText } from '@components/shared';
import { GLOBAL_PUBLIC_CONFIG } from '@ecocart/universal-utils';
import { useOverlay } from '@hooks/useOverlay';
import { useWindow } from '@hooks/useWindow';
import { ShopifyCharge } from '@utils/api/payments';
import clsx from 'clsx';
import { useEffect } from 'react';
import { Linking, Pressable, View } from 'react-native';

interface Props {
  shopifyCharge: ShopifyCharge;
  handleSetAsDefault: () => Promise<void>;
  handleDelete: () => Promise<void>;
  readOnly?: boolean;
  setPrimaryPaymentMethod: (paymentMethod: string) => void;
}

export default function ShopifyChargeCard({
  shopifyCharge,
  setPrimaryPaymentMethod,
  handleSetAsDefault,
  handleDelete,
  readOnly = false
}: Props): JSX.Element {
  const { showModal, hideModal } = useOverlay();
  const handleHideModal = () => hideModal();
  const { isWide } = useWindow();

  const handleShowEditModal = () => {
    showModal({
      nativeID: 'edit-shopify-charge-modal',
      content: (
        <EditShopifyCharge
          shopifyCharge={shopifyCharge}
          handleHideModal={handleHideModal}
          handleSetAsDefault={handleSetAsDefault}
          handleShowDeleteModal={handleShowDeleteModal}
        />
      )
    });
  };

  const handleShowDeleteModal = () => {
    showModal({
      nativeID: 'delete-shopify-charge-confirmation-modal',
      content: (
        <EcoCard className="md:w-[525px]">
          <EcoText className="m-6" fontWeight="semibold" fontSize="xl" textAlign="center">
            Are you sure you want to delete {shopifyCharge.name || 'the bank account'} ending in {shopifyCharge?.name}?
          </EcoText>
          <View className="flex flex-row">
            <EcoButton
              className="flex-1 mr-2"
              nativeID="delete-shopify-charge-confirmation-modal__delete-button"
              variant="outlined"
              onPress={handleShowEditModal}
            >
              Cancel
            </EcoButton>
            <EcoButton
              className="flex-1 ml-2"
              nativeID="delete-shopify-charge-confirmation-modal__cancel-button"
              colorScheme="danger"
              onPress={() => {
                handleDelete();
                handleHideModal();
              }}
            >
              Delete
            </EcoButton>
          </View>
        </EcoCard>
      )
    });
  };

  useEffect(() => {
    if (shopifyCharge.default) {
      setPrimaryPaymentMethod(shopifyCharge.name || '');
    }
  }, [shopifyCharge.default, shopifyCharge.name, setPrimaryPaymentMethod]);

  const handleManageOnShopify = () => {
    if (shopifyCharge.status === 'pending') {
      location.href = shopifyCharge.confirmation_url;
    } else {
      Linking.openURL(shopifyCharge.confirmation_url);
    }
  };

  const ShopifyChargeStatus = ({ status }: ShopifyCharge): JSX.Element => {
    switch (status) {
      case 'active':
        return (
          <EcoText fontSize="sm" className="capitalize">
            Shopify Billing
          </EcoText>
        );
      case 'pending':
        return (
          <EcoText fontSize="sm" className="capitalize">
            Pending...
          </EcoText>
        );
      default:
        return (
          <>
            <EcoText fontSize="sm" className="capitalize">
              Shopify Billing
            </EcoText>
            <EcoText fontSize="sm" className="capitalize" color="danger">
              Failed
            </EcoText>
          </>
        );
    }
  };

  return (
    <View className="flex flex-row flex-wrap justify-between items-center p-3 mt-1 sm:p-5 rounded-sm border-[1px] border-gray-100 gap-4">
      <View className={clsx('flex flex-row justify-center items-start', shopifyCharge?.status !== 'active' && 'opacity-50')}>
        <EcoImage
          resizeMode="contain"
          height={isWide ? 40 : 30}
          width={isWide ? 60 : 50}
          src={`${GLOBAL_PUBLIC_CONFIG.S3_PUBLIC_IMAGE_BUCKET}/shopify-billing-badge.svg`}
        />
        <View className="flex flex-col items-start mx-4">
          <View className="flex flex-row items-center gap-2">
            <EcoText fontWeight="semibold">{shopifyCharge.name}</EcoText>
            {shopifyCharge?.default ? (
              <EcoPill variant="primary" className="ml-2">
                Primary
              </EcoPill>
            ) : null}
          </View>
          {ShopifyChargeStatus(shopifyCharge)}
        </View>
      </View>
      <View className="flex flex-row center gap-4">
        <Pressable onPress={handleManageOnShopify} className="flex flex-row items-center">
          <EcoText className="text-accent-secondary" fontSize="sm">
            Manage On Shopify
          </EcoText>
          <EcoIcon className="text-accent-secondary ml-1" size="lg" name="open_in_new" />
        </Pressable>
        {!readOnly && (
          <View className={clsx('flex justify-end items-end')}>
            <EcoButton
              nativeID="payment-card__edit-button"
              onPress={handleShowEditModal}
              colorScheme="default"
              variant="outlined"
              size="sm"
            >
              Edit
            </EcoButton>
          </View>
        )}
      </View>
    </View>
  );
}
