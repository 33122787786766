import EditBankAccountForm from '@components/forms/EditBankAccountForm';
import { EcoButton, EcoCard, EcoImage, EcoPill, EcoText } from '@components/shared';
import { useOverlay } from '@hooks/useOverlay';
import { useWindow } from '@hooks/useWindow';
import { BankAccount, SetupIntentStatus, StripeCustomer } from '@utils/api/payments';
import clsx from 'clsx';
import { useEffect } from 'react';
import { View } from 'react-native';

interface Props {
  customer: StripeCustomer;
  bankAccount: BankAccount;
  handleSetAsDefault: () => Promise<void>;
  handleDelete: () => Promise<void>;
  readOnly?: boolean;
  setPrimaryPaymentMethod: (paymentMethod: string) => void;
}

const pendingStatuses: SetupIntentStatus[] = ['pending', 'requires_payment_method', 'requires_action', 'requires_confirmation'];

export default function BankAccountCard({
  customer,
  bankAccount,
  setPrimaryPaymentMethod,
  handleSetAsDefault,
  handleDelete,
  readOnly = false
}: Props): JSX.Element {
  const { showModal, hideModal } = useOverlay();
  const handleHideModal = () => hideModal();
  const { isWide } = useWindow();

  const handleShowEditModal = () => {
    showModal({
      nativeID: 'edit-bank-account-modal',
      content: (
        <EditBankAccountForm
          bankAccount={bankAccount}
          handleHideModal={handleHideModal}
          handleSetAsDefault={handleSetAsDefault}
          handleShowDeleteModal={handleShowDeleteModal}
        />
      )
    });
  };

  const handleShowDeleteModal = () => {
    showModal({
      nativeID: 'delete-bank-account-confirmation-modal',
      content: (
        <EcoCard className="md:w-[525px]">
          <EcoText className="m-6" fontWeight="semibold" fontSize="xl" textAlign="center">
            Are you sure you want to delete {bankAccount.bank_name || 'the bank account'} ending in {bankAccount?.last4}?
          </EcoText>
          <View className="flex flex-row">
            <EcoButton
              className="flex-1 mr-2"
              nativeID="delete-bank-account-confirmation-modal__cancel-button"
              variant="outlined"
              onPress={handleShowEditModal}
            >
              Cancel
            </EcoButton>
            <EcoButton
              className="flex-1 ml-2"
              nativeID="delete-bank-account-confirmation-modal__delete-button"
              colorScheme="danger"
              onPress={() => {
                handleDelete();
                handleHideModal();
              }}
            >
              Delete
            </EcoButton>
          </View>
        </EcoCard>
      )
    });
  };

  useEffect(() => {
    if (bankAccount.default) {
      setPrimaryPaymentMethod('**** **** **** ' + bankAccount.last4 || '');
    }
  }, [bankAccount.default, bankAccount.last4, setPrimaryPaymentMethod]);

  return (
    <View
      className={clsx(
        'flex flex-row flex-wrap justify-between items-center p-3 mt-1 sm:p-5 rounded-sm border-[1px] border-gray-100 gap-4',
        bankAccount?.setup_intent_status !== 'succeeded' && 'opacity-50'
      )}
    >
      <View className="flex flex-row justify-center items-start">
        <EcoImage
          resizeMode="contain"
          height={isWide ? 40 : 30}
          width={isWide ? 60 : 50}
          src={'https://logomakercdn.truic.com/ux-flow/industry/bank-meta.png'}
        />
        <View className="flex flex-col items-start mx-4">
          <View className="flex flex-row items-center gap-2">
            <EcoText fontWeight="semibold">{bankAccount.bank_name}</EcoText>
            {bankAccount?.default ? (
              <EcoPill variant="primary" className="ml-2">
                Primary
              </EcoPill>
            ) : null}
          </View>
          {pendingStatuses.includes(bankAccount?.setup_intent_status) ? (
            <EcoText fontSize="sm" className="italic">
              Bank account is pending verification from Stripe. Verification instructions have been sent to {customer.customer_email} by
              Stripe.
            </EcoText>
          ) : (
            <EcoText fontSize="sm" className="capitalize">
              {bankAccount.account_type} Acct ending in {bankAccount.last4}
            </EcoText>
          )}
        </View>
      </View>
      {!readOnly && bankAccount?.setup_intent_status === 'succeeded' ? (
        <View className="flex justify-end items-end">
          <EcoButton nativeID="payment-card__edit-button" onPress={handleShowEditModal} colorScheme="default" variant="outlined" size="sm">
            Edit
          </EcoButton>
        </View>
      ) : null}
    </View>
  );
}
