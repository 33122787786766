import { EcoText, EcoTooltip, FormPicker } from '@components/shared';
import { useState } from 'react';
import { View } from 'react-native';
import { TOOLTIP_PLACEMENTS, TooltipPlacement } from '../shared/EcoTooltip';
import { ConfigContainer } from '../views/ConfigContainer';
import { DemoContainer } from '../views/DemoContainer';
import { InternalToolsCard } from '../views/InternalToolsCard';

export function DSTooltip(): JSX.Element {
  const [tooltipPlacement, setTooltipPlacement] = useState<TooltipPlacement>('bottom');

  return (
    <InternalToolsCard componentName="EcoTooltip">
      {/* Actual demo component */}

      <DemoContainer>
        <View className="self-center">
          <EcoTooltip
            tooltip={
              <EcoText fontSize="lg" fontWeight="medium" color="enabled-inverted">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut iure doloremque illum! Harum, expedita architecto!
              </EcoText>
            }
            placement={tooltipPlacement}
          >
            <EcoText fontSize="base" className="text-white bg-warning-700 py-3 px-4">
              Hover me
            </EcoText>
          </EcoTooltip>
          {/* End of actual demo component */}
        </View>
      </DemoContainer>

      <ConfigContainer>
        {/* Configuration component */}
        <FormPicker
          label="Placement"
          value={tooltipPlacement}
          items={TOOLTIP_PLACEMENTS.map((item) => ({ label: item, value: item }))}
          onValueChange={(val) => setTooltipPlacement(val)}
        />
      </ConfigContainer>
      {/* End of Configuration component */}
    </InternalToolsCard>
  );
}
