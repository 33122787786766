import { EcoCard, EcoIcon, EcoTab, EcoTabContent, EcoTable, EcoTabs, EcoText } from '@components/shared';
import EcoEmptyState from '@components/shared/EcoEmptyState';
import { EcoPageTitle } from '@components/shared/EcoPageTitle';
import EcoScrollView from '@components/views/EcoScrollView';
import { useRewards } from '@hooks/useRewards';
import { useRewardsHistoryTableConfig } from '@hooks/useRewardsHistoryTableConfig';
import { BackLink } from '@navigation/BackLink';
import { GlobalScreenProps } from '@navigation/types';
import { useQuery } from '@tanstack/react-query';
import { getMerchantAdmin } from '@utils/api/merchant';
import { EcoCartApiMerchantTabs, EcoCartGlobalMerchantTabs } from '@utils/constants/tabs';
import { Gap } from '@utils/layout';
import React from 'react';
import { View } from 'react-native';

export default function MerchantRewardsScreen({ route }: GlobalScreenProps): JSX.Element {
  const shopName = route?.params?.shopName;
  const { data, isFetching } = useRewards(shopName);
  const columnConfig = useRewardsHistoryTableConfig();
  const { data: merchantAdmin } = useQuery(['merchantAdmin', shopName], () => getMerchantAdmin(shopName));
  const EcoCartMerchantTabs = merchantAdmin?.platform === 'api' ? EcoCartApiMerchantTabs : EcoCartGlobalMerchantTabs;

  return (
    <EcoScrollView>
      <View className="flex-row">
        <BackLink href="/merchants" label="Back to Merchants" />
      </View>
      <EcoTabs
        tabs={EcoCartMerchantTabs.map(({ label, screenName }) => {
          return {
            label,
            screenName,
            screenParams: { shopName }
          } as EcoTab;
        })}
        prefix="Merchant"
        activeScreen="MerchantRewards"
      >
        <EcoTabContent>
          <EcoScrollView>
            <View className="flex-row">
              <BackLink href="/merchant/:shopName/ecorewards" params={{ shopName }} label="Back To Rewards" />
            </View>

            <EcoPageTitle title="History" />
            {isFetching || (data && data.redemptions.length > 0) ? (
              <EcoCard>
                <EcoText fontSize="lg" className="mb-6">
                  View all your rewards related history here
                </EcoText>
                <EcoTable
                  defaultDataQuery={{ sorts: [{ field: 'created_at', dir: 'desc' }] }}
                  data={data?.redemptions || []}
                  isLoading={!data}
                  columns={columnConfig}
                />
              </EcoCard>
            ) : (
              <EcoCard>
                <View className="items-center justify-center py-9" style={Gap(4)}>
                  <EcoIcon name="warning" className="text-warning-500" size="6xl" />
                  <EcoEmptyState title="No Rewards History Found" />
                </View>
              </EcoCard>
            )}
          </EcoScrollView>
        </EcoTabContent>
      </EcoTabs>
    </EcoScrollView>
  );
}
