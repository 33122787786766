import { CustomInvoiceForm } from '@components/forms/CustomInvoiceForm';
import { Merchant } from '@ecocart/entities';
import { useOverlay } from './useOverlay';

export const useCustomInvoiceModal = (): {
  showCustomInvoiceModal: (shopName: Merchant['shopName'], primaryPaymentMethod: string) => void;
  hideCustomInvoiceModal: () => void;
} => {
  const { showModal, hideModal } = useOverlay();

  const showCustomInvoiceModal = (shopName: Merchant['shopName'], primaryPaymentMethod: string): void => {
    showModal({
      nativeID: 'add-custom-invoice-modal',
      content: <CustomInvoiceForm shopName={shopName} primaryPaymentMethod={primaryPaymentMethod} />
    });
  };

  return { showCustomInvoiceModal, hideCustomInvoiceModal: hideModal };
};
