import { MetricCard } from '@components/cards/MetricCard';
import { DatePickerValue, MerchantAdmin, MerchantStatsPeriod, UserType, ValueWithTrend } from '@ecocart/entities';
import { IMPERIAL_LOCALES, convertKgToLb, formatNumber, getWeightUnit } from '@ecocart/universal-utils';
import { useGlobal } from '@hooks/useGlobal';
import { useQuery } from '@tanstack/react-query';
import { getStats } from '@utils/api/reporting';
import { baseCardsConfig } from './CoreMetricsCards.utils';

export function CoreMetricsCards({ merchantAdmin, dateRange }: { merchantAdmin?: MerchantAdmin; dateRange: DatePickerValue }): JSX.Element {
  const { session } = useGlobal();
  const shopName = merchantAdmin?.shopName || '';
  const { data } = useQuery(['getStats', shopName], () => getStats(shopName));
  const locale = merchantAdmin?.locale;
  const isImperial = locale && IMPERIAL_LOCALES.includes(locale);
  const hasNoData = data && 'message' in data;

  const weightConfigKeys = baseCardsConfig
    .filter((c) => ['totalCarbonOffset', 'averageCarbonOffset'].includes(c.key))
    .map((item) => item.key);

  // Filtered configs based on user type
  const filteredConfig = baseCardsConfig.filter((c) => c.permissions.includes((session?.user.userType as UserType) || ''));

  const cardsConfig = data
    ? filteredConfig.map((config) => {
        const dataPeriod: MerchantStatsPeriod = data.find((period) => period.period === dateRange) as unknown as MerchantStatsPeriod;

        return {
          ...config,
          title: weightConfigKeys.includes(config.key) ? `${config.title} in ${getWeightUnit(locale || 'en')}` : config.title,
          amount: dataPeriod
            ? isImperial && !hasNoData && (dataPeriod[config.key] as ValueWithTrend)?.value && weightConfigKeys.includes(config.key)
              ? convertKgToLb((dataPeriod[config.key] as ValueWithTrend)?.value)
              : (!hasNoData && (dataPeriod[config.key] as ValueWithTrend)?.value) || 0
            : 0,
          trend: dataPeriod ? (!hasNoData && (dataPeriod[config.key] as ValueWithTrend)?.trend) || 0 : 0,
          currencyCode: merchantAdmin?.currencyCode
          // TODO: add badge
        };
      })
    : filteredConfig;

  return (
    <>
      {cardsConfig.map((config) => {
        const { title, amount, trend, format, currencyCode, badge, minimumFractionDigits, maximumFractionDigits } = config;
        const isCurrencyFormat = format === 'currency';
        const options = {
          style: format,
          ...(isCurrencyFormat
            ? {
                locale: merchantAdmin?.locale,
                currency: currencyCode
              }
            : undefined),
          minimumFractionDigits: minimumFractionDigits,
          maximumFractionDigits: maximumFractionDigits
        };
        const formattedValue = amount !== undefined ? formatNumber(amount, options) : undefined;

        return <MetricCard key={title} title={title} badge={badge} trend={trend} value={formattedValue} />;
      })}
    </>
  );
}
