import { EcoCard, EcoText } from '@components/shared';
import { MerchantAdmin } from '@ecocart/entities';
import { Gap } from '@utils/layout';
import { colors, spacing } from '@utils/tailwind';
import React from 'react';
import { View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { twMerge } from 'tailwind-merge';
import { MerchantActivityCardDetails, useMerchantActivityCardConfig } from './MerchantActivityCard.utils';

interface Props {
  merchantAdmin: MerchantAdmin;
}

export default function MerchantActivityCard({ merchantAdmin }: Props): JSX.Element {
  const { shopName, active, createdAt, updatedAt, variantIds, ecocartEnabled, ecocartActive, platform } = merchantAdmin || {};
  const formConfig = useMerchantActivityCardConfig({ createdAt, updatedAt, variantIds, ecocartActive, ecocartEnabled, platform });

  let topRightComponent = undefined;
  if (platform !== 'api') {
    topRightComponent = active ? (
      <EcoText color="success" fontWeight="semibold">
        Installed
      </EcoText>
    ) : (
      <EcoText color="danger" fontWeight="semibold">
        Uninstalled
      </EcoText>
    );
  }

  return merchantAdmin ? (
    <EcoCard
      title={shopName}
      topRightComponent={topRightComponent}
      className={twMerge('border', active ? 'border-success' : 'border-danger-700')}
      style={{ padding: Number(spacing['4'].replace('px', '')), backgroundColor: active ? colors.success[50] : colors.danger[50] }}
    >
      <ScrollView horizontal className="mt-4" contentContainerStyle={{ width: '100%' }}>
        <View className="flex-col lg:flex-row space-evenly w-full" style={Gap()}>
          {formConfig.map(({ label, value }) => (
            <MerchantActivityCardDetails key={label} label={label} value={value} />
          ))}
        </View>
      </ScrollView>
    </EcoCard>
  ) : (
    <></>
  );
}
