import { EcoPicker, EcoText } from '@components/shared';
import { useWindow } from '@hooks/useWindow';
import { Gap } from '@utils/layout';
import { colors } from '@utils/tailwind';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { DateButtonProps, dateRangeItems, DateRangePickerProp } from './DateRangePicker.utils';

function DateButton({ label, value, isSelected, onPress }: DateButtonProps) {
  const ref = useRef(null);
  const isHovered = useHover(ref);

  return (
    <TouchableOpacity
      key={value}
      ref={ref}
      onPress={() => {
        onPress(value);
      }}
      className={clsx(
        'px-3 py-2 ease-in-out transition-colors rounded-xs duration-150',
        isSelected && 'bg-tertiary-200',
        isHovered && 'bg-tertiary-100'
      )}
    >
      <EcoText className="leading-xs uppercase" style={{ fontSize: 11 }}>
        {label}
      </EcoText>
    </TouchableOpacity>
  );
}

export default function DateRangePicker({ value, onDateClick }: DateRangePickerProp): JSX.Element {
  const { isMobileWeb } = useWindow();

  if (isMobileWeb) {
    return (
      <EcoPicker
        style={{ backgroundColor: colors.tertiary[200], borderColor: colors.tertiary[200] }}
        items={dateRangeItems}
        value={value}
        onValueChange={(value) => onDateClick(value)}
      />
    );
  }

  return (
    <View className="flex-row" style={Gap()}>
      {dateRangeItems.map(({ label, value: itemValue }) => (
        <DateButton key={itemValue} label={label} value={itemValue} isSelected={value === itemValue} onPress={onDateClick} />
      ))}
    </View>
  );
}
