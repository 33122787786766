import { Merchant, MerchantAdmin, MerchantAdminFlat, MerchantFlat } from '@ecocart/entities';
import { DataQuery, createDataQueryString } from '@ecocart/universal-utils';
import { HUBSPOT_API, apiCall } from './api';
import { sendMerchantUpdateMessage } from './slack';

/* -------------------------------- merchant -------------------------------- */
export const getMerchant = (shopName: string): Promise<Merchant> => {
  if (!shopName) return new Promise((resolve) => resolve(undefined as any));
  return apiCall<Merchant>('GET', `/merchant/${shopName}`);
};

export const updateMerchant = (
  shopName: string,
  merchantProps: Partial<Merchant>,
  dbMerchantProps?: Partial<Merchant>
): Promise<Merchant> => {
  return apiCall<Merchant>('PUT', `/merchant/${shopName}`, merchantProps).then((merchant) => {
    return Object.keys(dbMerchantProps || {}).length > 0
      ? sendMerchantUpdateMessage('merchant_widget_settings_change', window.session, {
          merchant: merchantProps,
          dbMerchant: dbMerchantProps
        })
      : Promise.resolve(merchant);
  });
};

export const getMerchants = (dataQuery?: DataQuery<MerchantFlat>): Promise<{ data: MerchantFlat[]; lastIndex: number }> => {
  return apiCall<{ data: MerchantFlat[]; lastIndex: number }>(
    'GET',
    dataQuery ? `/merchants?query=${createDataQueryString(dataQuery)}` : '/merchants'
  );
};

/* ----------------------------- merchant admin ----------------------------- */
export const getMerchantAdmin = (shopName: string): Promise<MerchantAdmin> => {
  if (!shopName) return new Promise((resolve) => resolve(undefined as any));
  return apiCall<MerchantAdmin>('GET', `/merchant-admin/${shopName}`);
};

// merchant admin 'Form' props will likely convert numbers to string, this adjust for that
const normalizeMerchantAdminProps = (merchantProps: Partial<MerchantAdmin>) => {
  const referenceMerchantAdmin = new MerchantAdmin();
  return Object.keys(merchantProps).reduce((acc, curr) => {
    if (typeof (referenceMerchantAdmin as any)[curr] === 'number') {
      acc[curr] = Number((merchantProps as any)[curr]);
    } else {
      acc[curr] = (merchantProps as any)[curr];
    }
    return acc;
  }, {} as Partial<MerchantAdmin> | any);
};

export const updateMerchantAdmin = (shopName: string, merchantProps: Partial<MerchantAdmin>, shpat?: string): Promise<MerchantAdmin> => {
  const normalizedMerchantAdminProps = normalizeMerchantAdminProps(merchantProps);
  const headers = shpat ? { 'X-Eco-Shopify-Access-Token': shpat } : (null as any);

  return apiCall<MerchantAdmin>('PUT', `/merchant-admin/${shopName}`, normalizedMerchantAdminProps, headers);
};

export const getMerchantAdmins = (dataQuery?: DataQuery<MerchantAdminFlat>): Promise<Partial<MerchantAdminFlat>[]> => {
  if (dataQuery?.filters?.length === 0) {
    delete dataQuery.filters;
  }

  return apiCall<Partial<MerchantAdminFlat>[]>(
    'GET',
    dataQuery ? `/merchant-admins?query=${createDataQueryString(dataQuery)}` : '/merchant-admins'
  );
};

export const searchMerchantAdmin = (query: string): Promise<string[]> => {
  const dataQuery: DataQuery<MerchantAdminFlat> = {
    fields: ['shopName'],
    filters: [
      {
        field: 'shopName',
        operator: 'like',
        value: query
      }
    ],
    take: 50
  };

  return apiCall<MerchantAdminFlat[]>('GET', `/merchant-admins?query=${createDataQueryString(dataQuery)}`).then((items) =>
    items.map((item) => item.shopName)
  );
};

export const syncMerchantAdminWithHubspot = (shopName: string): Promise<any> => {
  // resolve in all cases to avoid throwing to catch block for related calls
  return new Promise((resolve) => {
    apiCall('POST', `${HUBSPOT_API}/sync`, { shop_url: shopName }, { 'X-Eco-Key': '84ceb6f7-0559-41a2-b9f0-9185408f55b6', 'X-Eco-JWT': '' })
      .then((response) => resolve(response))
      .catch((error) => resolve(error));
  });
};
