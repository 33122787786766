import { EcoText } from '@components/shared';
import EcoScrollView from '@components/views/EcoScrollView';
import { GlobalScreenProps } from '@navigation/types';
import React from 'react';

export default function JobStatusScreen({}: GlobalScreenProps): JSX.Element {
  return (
    <EcoScrollView>
      <EcoText>Job Status Page</EcoText>
    </EcoScrollView>
  );
}
