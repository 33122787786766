import config from '../tailwind.config';

export const { colors, spacing, screens, fontSize, lineHeight } = config.theme;
export const { fontFamily } = config.theme.extend;

/* ---------------------------------- font ---------------------------------- */
// font-sora-regular font-sora-medium font-sora-semibold

/* ---------------------------------- text ---------------------------------- */
// input-base arrow-up label-base text-enabled text-enabled-inverted text-disabled text-disabled-inverted text-subdued text-subdued-inverted text-link text-link-hover text-success text-success-inverted text-warning text-warning-inverted text-danger text-danger-inverted text-accent-primary text-accent-secondary text-accent-algea
// active:border-secondary-400 active:bg-secondary-50

export const SPACING_SIZES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] as const;
export const LINE_HEIGHTS = ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl', '7xl', '8xl', '9xl', '10xl'] as const;
export const FONT_SIZES = [
  '2xs',
  'xs',
  'sm',
  'base',
  'lg',
  'xl',
  '2xl',
  '3xl',
  '4xl',
  '5xl',
  '6xl',
  '7xl',
  '8xl',
  '9xl',
  '10xl',
  '11xl'
] as const;
export const FONT_WEIGHTS = ['regular', 'medium', 'semibold'] as const;
export const TEXT_ALIGNS = ['left', 'center', 'right'] as const;

export const FONT_COLORS = [
  'white',
  'enabled',
  'enabled-inverted',
  'disabled',
  'disabled-inverted',
  'subdued',
  'subdued-inverted',
  'link',
  'link-hover',
  'success',
  'success-inverted',
  'warning',
  'warning-inverted',
  'danger',
  'danger-inverted',
  'accent-primary',
  'accent-secondary',
  'accent-algea'
] as const;

export type FontColor = (typeof FONT_COLORS)[number];
export type SpacingSize = (typeof SPACING_SIZES)[number];
export type FontSize = (typeof FONT_SIZES)[number];
export type FontWeight = (typeof FONT_WEIGHTS)[number];
export type LineHeight = (typeof LINE_HEIGHTS)[number];
export type TextAlign = (typeof TEXT_ALIGNS)[number];
