import { RedemptionConfirmationRequestParams, RedemptionType, RewardRedemptionResponse, User } from '@ecocart/entities';
import { apiCall, POWER_TOOLS_NODE_API, REWARDS_API } from './api';

export const getMonthlyRewardAnalytics = (shopName?: string): Promise<RewardRedemptionResponse> => {
  if (!shopName) return new Promise((resolve) => resolve(undefined as any));
  return apiCall<RewardRedemptionResponse>('GET', `${REWARDS_API}/analytics/monthly/${shopName}`);
};

export interface RedeemRewardsPayload {
  amount_usd: number;
  redemption_type: RedemptionType;
  shop_name: string;
  created_by: {
    id: User['id'];
    first_name: User['firstName'];
    last_name: User['lastName'];
  };
}

export const redeemRewards = (redeemRewardsPayload: RedeemRewardsPayload): Promise<RewardRedemptionResponse> => {
  return apiCall<RewardRedemptionResponse>('POST', `${REWARDS_API}/reward/redeem`, redeemRewardsPayload);
};

export const sendRedemptionConfirmationEmail = ({
  fullName,
  email,
  redemptionAmount,
  redemptionName
}: RedemptionConfirmationRequestParams): Promise<null> => {
  if (!prompt) return Promise.resolve(null);
  return apiCall<null>('POST', `${POWER_TOOLS_NODE_API}/reward-redemption-email`, {
    fullName,
    email,
    redemptionAmount,
    redemptionName
  });
};
