import { DATE_FORMAT } from '@components/dashboards/components/DateRangePicker.utils';
import { EcoText } from '@components/shared';
import { ColumnConfig } from '@components/shared/EcoTable.utils';
import { REDEMPTION_NAMES_MAP, RewardRedemptionResponse } from '@ecocart/entities';
import { formatNumber } from '@ecocart/universal-utils';
import { format, parseISO } from 'date-fns';
import { lowerCase, startCase } from 'lodash';
import { useMemo } from 'react';
import { View } from 'react-native';

export const useRewardsHistoryTableConfig = (): ColumnConfig<RewardRedemptionResponse['redemptions'][0]>[] => {
  const getSubmittedByString = (value: RewardRedemptionResponse['redemptions'][0]) => {
    return (
      <View>
        <EcoText>{`${value.created_by.first_name} ${value.created_by.last_name} (${value.created_by.id})`}</EcoText>
        <EcoText color="subdued" fontSize="sm">
          {value.identifier}
        </EcoText>
      </View>
    );
  };

  const columnConfig: ColumnConfig<RewardRedemptionResponse['redemptions'][0]>[] = useMemo(
    () => [
      {
        field: 'amount_usd',
        title: 'Amount (USD)',
        flex: 1.5,
        searchable: true,
        align: 'center',
        format: (value) => formatNumber(value.amount_usd, { style: 'currency', currency: 'USD' })
      },
      {
        field: 'created_by',
        title: 'Submitted By / Request ID',
        flex: 3,
        searchable: true,
        format: getSubmittedByString
      },
      {
        field: 'created_at',
        title: 'Submitted On',
        searchable: true,
        format: (value) => format(parseISO(String(value.created_at)), DATE_FORMAT)
      },
      {
        field: 'redemption_type',
        title: 'Category',
        flex: 2,
        searchable: true,
        format: (value) => startCase(lowerCase(REDEMPTION_NAMES_MAP[value.redemption_type])).replace('Eco Cart', 'EcoCart') || 'Unknown'
      }
    ],
    []
  );

  return columnConfig;
};
