const baseConfig = require('@ecocart/tailwind-config');

module.exports = {
  ...baseConfig,
  theme: {
    ...baseConfig.theme,
    extend: {
      ...baseConfig.theme.extend,
      fontFamily: {
        'sora-regular': 'sora-regular',
        'sora-medium': 'sora-medium',
        'sora-semibold': 'sora-semibold'
      }
    }
  },
  content: [
    './components/**/*.{js,jsx,ts,tsx}',
    './screens/**/*.{js,jsx,ts,tsx}',
    './navigation/**/*.{js,jsx,ts,tsx}',
    './utils/tailwind.ts',
    './context/**/*.{js,jsx,ts,tsx}'
  ]
};
/** @type {import('tailwindcss').Config} */
