import { useWindow } from '@hooks/useWindow';
import { ScreenParams } from '@navigation/LinkingConfiguration';
import {
  InternalToolsScreenName,
  MerchantsScreenName,
  RewardsScreenName,
  ScreenName,
  SettingsScreenName,
  TabScreenName,
  TabsName,
  TouchPointsScreenName
} from '@navigation/StackNavigators';
import { useNavigation } from '@react-navigation/native';
import { Gap } from '@utils/layout';
import clsx from 'clsx';
import React, { useState } from 'react';
import { ScrollView, View } from 'react-native';
import { nativeID } from '../../utils/types/nativeID';
import { EcoTab } from './EcoTab';
import { EcoText } from './EcoText';

type EcoTabScreens =
  | TabScreenName
  | TabsName
  | InternalToolsScreenName
  | SettingsScreenName
  | TouchPointsScreenName
  | MerchantsScreenName
  | RewardsScreenName;

export interface EcoTab {
  nativeID?: nativeID;
  label: string;
  isDisabled?: boolean;
  screenName: EcoTabScreens;
  screenParams?: ScreenParams;
}

interface Props {
  tabs: EcoTab[];
  children: React.ReactNode;
  activeScreen: ScreenName;
  prefix?: string;
  showLabelInBreadCrumb?: boolean;
  description?: string;
}

export function EcoTabs({ children, tabs = [], description, prefix = '', activeScreen, showLabelInBreadCrumb = true }: Props): JSX.Element {
  const [tabListWiderThanWindow, setTabListWiderThanWindow] = useState(false);
  const navigation = useNavigation();
  const activeTabIndex = tabs.findIndex((tab) => tab.screenName === activeScreen);
  const { isDesktopWeb, width: windowWidth } = useWindow();
  const shouldApplyLinearGradient = !isDesktopWeb && tabListWiderThanWindow;
  const shouldApplyMaxWidth = !tabListWiderThanWindow;

  return (
    <View>
      <View className="pb-3 mb-5" style={Gap()}>
        <EcoText>
          <EcoText fontSize="4xl" fontWeight={!showLabelInBreadCrumb ? 'medium' : undefined}>
            {prefix}
          </EcoText>
          {showLabelInBreadCrumb ? (
            <>
              <EcoText fontSize="4xl"> / </EcoText>
              <EcoText fontSize="4xl" fontWeight="semibold">
                {tabs[activeTabIndex].label}
              </EcoText>
            </>
          ) : (
            <></>
          )}
        </EcoText>
        {description && (
          <EcoText fontSize="lg" color="subdued">
            {description}
          </EcoText>
        )}
      </View>

      <View className={clsx('flex-row relative overflow-hidden', { 'w-max': shouldApplyMaxWidth })}>
        {shouldApplyLinearGradient && (
          <View
            className="absolute right-0 top-0 bottom-0 z-10 opacity-50 rounded-tr-sm"
            style={{
              width: '8px',
              height: 'calc(100% - 3px)',
              background: 'white',
              background: 'linear-gradient(90deg, white 0%, white 0%, rgba(224,224,224,1) 100%)'
            }}
          />
        )}
        <ScrollView horizontal accessibilityRole="tablist" className="bg-white rounded-t-sm">
          <View
            className="flex-row"
            onLayout={(event) => {
              const { width } = event.nativeEvent.layout;
              if (width > windowWidth) setTabListWiderThanWindow(true);
              else setTabListWiderThanWindow(false);
            }}
          >
            {Object.values(tabs).map((tab, index) => (
              <EcoTab
                key={tab.label}
                {...tab}
                isMatch={index === activeTabIndex}
                onPress={() => navigation.navigate(tab.screenName, tab.screenParams)}
              />
            ))}
          </View>
        </ScrollView>
      </View>
      {children}
    </View>
  );
}
