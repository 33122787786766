import { Merchant, Template, WidgetMap, WidgetPlacement } from '@ecocart/entities';
import { useQuery } from '@tanstack/react-query';
import { getMerchant } from '@utils/api/merchant';

type Widgets = (WidgetMap & WidgetPlacement & { name: string; template: Template })[] | undefined;

type ReturnType = {
  merchant?: Merchant;
  allWidgets: Widgets;
  productPageWidgets: Widgets;
  slideOutCartWidgets: Widgets;
  cartPageWidgets: Widgets;
  homepageWidgets: Widgets;
  checkoutWidgets: Widgets;
  postPurchaseWidgets: Widgets;
  otherPageWidgets: Widgets;
};

const isHomePage = (widget: WidgetPlacement) => widget.pathname === '/';
const isProductOrCollectionPage = (widget: WidgetPlacement) =>
  widget.pageType === 'product' || ['product', 'collection'].includes(widget.pathname || '');
const isSlideOutCart = (widget: WidgetPlacement) => widget.pageType === 'in_store' && widget.pathname === '';
const isCartPage = (widget: WidgetPlacement) => widget.pageType === 'cart';
const isCheckoutPage = (widget: WidgetPlacement) => widget.pageType.includes('checkout') && widget.pageType !== 'checkout_complete';
const isPostPurchasePage = (widget: WidgetPlacement) => widget.pageType === 'checkout_complete';

export const useWidgets = (shopName: string): ReturnType => {
  const { data: merchant } = useQuery({
    queryKey: ['widgets', shopName],
    queryFn: async () => getMerchant(shopName)
  });

  const mergedWidgets = merchant?.widgetPlacements.map((widget) => {
    return {
      ...widget,
      ...merchant.widgetMap[widget.widgetKey]
    };
  }) as Widgets;

  const allWidgets = mergedWidgets;
  const productPageWidgets = mergedWidgets?.filter(isProductOrCollectionPage);
  const slideOutCartWidgets = mergedWidgets?.filter(isSlideOutCart);
  const cartPageWidgets = mergedWidgets?.filter(isCartPage);
  const homepageWidgets = mergedWidgets?.filter(isHomePage);
  const checkoutWidgets = mergedWidgets?.filter(isCheckoutPage);
  const postPurchaseWidgets = mergedWidgets?.filter(isPostPurchasePage);
  // all other pages widgets that are neiether instore, cart, checkout, slideoutcart, or homepage
  const otherPageWidgets = mergedWidgets?.filter(
    (widget) =>
      !(
        isHomePage(widget) ||
        isProductOrCollectionPage(widget) ||
        isSlideOutCart(widget) ||
        isCartPage(widget) ||
        isCheckoutPage(widget) ||
        isPostPurchasePage(widget)
      )
  );

  return {
    merchant,
    allWidgets,
    productPageWidgets,
    slideOutCartWidgets,
    cartPageWidgets,
    homepageWidgets,
    checkoutWidgets,
    postPurchaseWidgets,
    otherPageWidgets
  };
};
