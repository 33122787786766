import { EcoToggle } from '@components/shared';
import { MerchantAdmin } from '@ecocart/entities';
import { useGlobal } from '@hooks/useGlobal';
import { updateMerchantAdmin } from '@utils/api/merchant';
import { sendMerchantAdminUpdateMessage } from '@utils/api/slack';
import { GlobalFormProps } from '@utils/prop-types';
import { FontSize } from '@utils/tailwind';
import { useState } from 'react';

interface Props extends GlobalFormProps {
  isDisabled?: boolean;
  merchantAdmin: MerchantAdmin;
  title?: string;
  subTitle?: string;
  titleSize?: FontSize;
  subTitleSize?: FontSize;
}

export function SetEcocartActiveToggleForm({ isDisabled, merchantAdmin, onSuccess }: Props): JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { session } = useGlobal();
  const _isDisabled = isDisabled || isSubmitting;

  const onSubmit = async (ecocartActive: boolean) => {
    setIsSubmitting(true);

    const updatedProps: Partial<MerchantAdmin> = { ecocartActive };
    if (!ecocartActive) updatedProps.ecocartEnabled = false;

    try {
      await updateMerchantAdmin(merchantAdmin.shopName, updatedProps).then((merchantAdmin) => {
        onSuccess?.(merchantAdmin);
      });

      setIsSubmitting(false);

      await sendMerchantAdminUpdateMessage('ecocart_active_change', session, {
        merchantAdmin: updatedProps,
        dbMerchantAdmin: merchantAdmin
      });
    } catch (error: any) {
      setIsSubmitting(false);
    }
  };

  const value = merchantAdmin.ecocartActive;

  return <EcoToggle nativeID="settings__ecocart-active-toggle" disabled={_isDisabled} value={value} onValueChange={onSubmit} />;
}
