import { EcoButton, EcoCard, EcoRadioButtonCards, FormPicker } from '@components/shared';
import { useOverlay } from '@hooks/useOverlay';
import { getBackup, getBackupDates } from '@utils/api/backups';
import { doDownloadAsJSON } from '@utils/file';
import { Gap } from '@utils/layout';
import { format } from 'date-fns';
import { orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { View } from 'react-native';
import { EcoPickerItem } from '../shared/EcoPicker';

interface Props {}

export default function DownloadBackupCard({}: Props): JSX.Element {
  const { handleError } = useOverlay();
  const [entity, setEntity] = useState<string>('merchant');
  const [availableBackupDatePickerItems, setAvailableBackupDatePickerItems] = useState<EcoPickerItem[]>([]);
  const [selectedBackupKey, setSelectedBackupKey] = useState<string>();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  useEffect(() => {
    if (!entity) return;

    getBackupDates(entity)
      .then((availableBackupDates) => {
        const items = orderBy(
          (availableBackupDates || []).map((date) => ({
            label: format(new Date(date.LastModified), 'MM/dd/yyyy'),
            value: date.Key
          })),
          (date) => date.label,
          'desc'
        );
        setAvailableBackupDatePickerItems(items);

        if (items[0].value) setSelectedBackupKey(items[0].value);
      })
      .catch(handleError);
  }, [entity, handleError]);

  const downloadBackup = () => {
    if (!selectedBackupKey) return;

    setIsDownloading(true);

    getBackup(selectedBackupKey)
      .then((res) => {
        const item = (availableBackupDatePickerItems || []).find((backup) => backup.value === selectedBackupKey);
        const fileName = `${entity}-${item?.label}`;

        if (res && fileName) doDownloadAsJSON(res, fileName);

        setIsDownloading(false);
      })
      .catch(handleError);
  };

  return (
    <EcoCard title="Backups">
      <View style={Gap()}>
        <EcoRadioButtonCards
          value={entity}
          items={[
            {
              label: 'Merchant',
              value: 'merchant',
              description: 'Public widget settings table'
            },
            {
              label: 'MerchantAdmin',
              value: 'merchantAdmin',
              description: 'Private merchant settings table'
            }
          ]}
          onValueChange={(value) => setEntity(value)}
        />
        {!!availableBackupDatePickerItems?.length && (
          <View style={Gap()}>
            <FormPicker
              label="Available Dates"
              value={selectedBackupKey as string}
              items={availableBackupDatePickerItems}
              onValueChange={(value) => setSelectedBackupKey(value)}
            />
            <EcoButton isDisabled={isDownloading} onPress={downloadBackup}>
              Download
            </EcoButton>
          </View>
        )}
      </View>
    </EcoCard>
  );
}
