import { EcoAutocomplete, EcoButton } from '@components/shared';
import { searchMerchantAdmin } from '@utils/api/merchant';
import { GlobalFormProps } from '@utils/prop-types';
import { colors } from '@utils/tailwind';
import React, { useState } from 'react';
import { View } from 'react-native';

interface Props extends GlobalFormProps {}

export default function AddMerchantForm({ onSuccess }: Props): JSX.Element {
  const [merchantName, setMerchantName] = useState<string>('');
  return (
    <View className="flex-row justify-between">
      <EcoAutocomplete
        className="grow"
        searchCall={searchMerchantAdmin as any}
        placeholderTextColor={colors.gray[500]}
        value={merchantName}
        placeholder="Search merchants..."
        onChangeText={(val) => setMerchantName(val)}
        onChangeSelection={(val) => setMerchantName(val)}
      />
      <EcoButton
        className="ml-2"
        isDisabled={!merchantName}
        onPress={() => {
          onSuccess && onSuccess(merchantName);
          setMerchantName('');
        }}
        leftIcon="add"
      />
    </View>
  );
}
