import { EcoButton, EcoInput, FormButtons } from '@components/shared';
import { useOverlay } from '@hooks/useOverlay';
import { resetPassword } from '@utils/api/auth';
import { Gap } from '@utils/layout';
import { GlobalFormProps } from '@utils/prop-types';
import { CreateValidationSchema } from '@utils/validation';
import { Formik } from 'formik';
import { noop } from 'lodash';
import React, { useState } from 'react';
import { View } from 'react-native';

interface Props extends GlobalFormProps {
  email: string;
  verificationCode: string;
}

export default function ResetPasswordForm({ email, verificationCode, onSuccess = noop }: Props): JSX.Element {
  const { showSuccess, handleError } = useOverlay();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmit = async ({
    email,
    verificationCode,
    createPassword
  }: {
    email: string;
    verificationCode: string;
    createPassword: string;
  }): Promise<void> => {
    setIsSubmitting(true);

    try {
      await resetPassword(email, verificationCode, createPassword);
      showSuccess('Password successfully reset');
      onSuccess && onSuccess({ email, password: createPassword });
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  return (
    <Formik
      validateOnMount
      initialValues={{
        verificationCode,
        createPassword: '',
        confirmPassword: '',
        email
      }}
      validationSchema={CreateValidationSchema(['createPassword', 'confirmPassword'])}
      onSubmit={onSubmit}
    >
      {({ handleChange, handleSubmit, handleBlur, values, isValid, errors, touched }) => (
        <View style={Gap(2)}>
          <EcoInput
            type="password"
            label="New Password"
            value={values.createPassword}
            onBlur={handleBlur('createPassword')}
            onChangeText={handleChange('createPassword')}
            placeholder="New Password"
            errors={errors}
            touched={touched}
            field="createPassword"
          />
          <EcoInput
            type="password"
            label="Confirm New Password"
            value={values.confirmPassword}
            onBlur={handleBlur('confirmPassword')}
            onChangeText={handleChange('confirmPassword')}
            placeholder="Confirm New Password"
            errors={errors}
            touched={touched}
            field="confirmPassword"
          />
          <FormButtons>
            <EcoButton
              nativeID="reset-password-form__save-button"
              fullWidth
              isDisabled={!isValid || isSubmitting}
              isLoading={isSubmitting}
              onPress={handleSubmit}
            >
              Save
            </EcoButton>
          </FormButtons>
        </View>
      )}
    </Formik>
  );
}
