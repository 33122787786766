import { BillingDashboard } from '@components/dashboards/BillingDashboard';
import { EcoText } from '@components/shared';
import { EcoPageTitle } from '@components/shared/EcoPageTitle';
import EcoScrollView from '@components/views/EcoScrollView';
import { useQuery } from '@tanstack/react-query';
import { getCurrentInvoice } from '@utils/api/payments';
import { addDays, format } from 'date-fns';
import React from 'react';
import { useGlobal } from '../hooks/useGlobal';
import { GlobalScreenProps } from '../navigation/types';

export default function BillingScreen({}: GlobalScreenProps): JSX.Element {
  const { session } = useGlobal();
  const { data: currentInvoice } = useQuery(['getCurrentInvoice', session?.merchantAdmin?.shopName], () =>
    getCurrentInvoice(session?.merchantAdmin?.shopName || '')
  );
  const currentInvoicePeriod = currentInvoice
    ? `${format(new Date(currentInvoice.start_date), 'MMM dd, yyyy')} - ${format(new Date(currentInvoice.end_date), 'MMM dd, yyyy')}`
    : '-';
  const nextInvoiceDate = currentInvoice ? format(addDays(new Date(currentInvoice.end_date), 1), 'MMM dd, yyyy') : '-';

  return (
    <EcoScrollView>
      <>
        <EcoPageTitle className="mb-5" title="Billing" subTitle={`Current billing period: ${currentInvoicePeriod}`} />
        {nextInvoiceDate ? (
          <EcoText className="mb-3" fontWeight="medium">
            Next invoice date: {nextInvoiceDate}
          </EcoText>
        ) : null}
        {session?.merchantAdmin ? <BillingDashboard merchantAdmin={session.merchantAdmin} currentInvoice={currentInvoice} /> : null}
      </>
    </EcoScrollView>
  );
}
