import { Gap } from '@utils/layout';
import React from 'react';
import { View } from 'react-native';
import { EcoText } from './EcoText';
import { EcoToggle, EcoToggleProps } from './EcoToggle';

interface Props extends EcoToggleProps {
  label: string;
  disabled?: boolean;
}

export function FormToggle({ label, onValueChange, disabled = false, value }: Props): JSX.Element {
  return (
    <View className="items-center flex-row justify-between" style={Gap(2)}>
      <EcoText fontSize="sm" className="label-base">
        {label}
      </EcoText>
      <EcoToggle value={value} onValueChange={onValueChange} disabled={disabled} />
    </View>
  );
}
