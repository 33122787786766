import { EcoCard, EcoText } from '@components/shared';
import { Gap } from '@utils/layout';
import React from 'react';
import { View } from 'react-native';
import EcoLogo from '../images/EcoLogo';

type LoginCardProps = {
  title: string;
  subTitle?: string;
  children: React.ReactNode;
};

const LoginCard: React.FC<LoginCardProps> = ({ children, title, subTitle }) => {
  return (
    <EcoCard className="flex flex-col items-center justify-center p-7 sm:p-9 max-w-[90%]" style={{ width: 440 }}>
      <View className="items-center w-full" style={Gap(2)}>
        <View className="mx-auto w-full justify-center flex-row">
          <EcoLogo />
        </View>
        <EcoText className="mt-3 w-full" textAlign="center" fontSize="4xl" fontWeight="semibold">
          {title}
        </EcoText>
        {subTitle && (
          <EcoText textAlign="center" fontSize="base" className="w-full">
            {subTitle}
          </EcoText>
        )}
        <View className="w-full mt-2">{children}</View>
      </View>
    </EcoCard>
  );
};

export default LoginCard;
