import { EcoButton, EcoCard, EcoInput, EcoLoader, EcoText, FormButtons, FormPicker } from '@components/shared';
import { Experiment, ExperimentKey, Merchant, features } from '@ecocart/entities';
import { useOverlay } from '@hooks/useOverlay';
import { MerchantContext } from '@screens/MerchantWidgetsScreen';
import { updateMerchant } from '@utils/api/merchant';
import { Formik } from 'formik';
import { useContext, useState } from 'react';
import { View } from 'react-native';
import { Gap } from '../../utils/layout';

// When ready, we can open up more ab testing options
const availableExperiments: (ExperimentKey | '--')[] = [
  '--',
  'show_checkout_widget',
  'vary_checkout_widget_templates',
  'vary_checkout_widget_precheck',
  'show_pdp_widget',
  'vary_pdp_widget_templates',
  'climate_positive_versus_climate_contribution',
  'default_versus_support_global_forestry_projects'
];

const getExperimentConfig = (key: ExperimentKey | '--'): Experiment | null => {
  if (key === '--' || !features?.[key]?.rules?.[0]?.variations) return null;

  const variations = features[key].rules?.[0].variations || [];

  return {
    key,
    variations,
    weights: variations.map(() => 1 / variations.length)
  };
};

export default function EditABTestForm(): JSX.Element {
  const { merchant, refetch } = useContext(MerchantContext);
  const { showSuccess, handleError } = useOverlay();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmit = async ({ experiment }: Merchant) => {
    if (!experiment?.key || !merchant?.shopName) return;

    setIsSubmitting(true);

    try {
      if ((experiment.key as ExperimentKey | '--') === '--') {
        experiment = null;
      } else {
        experiment.weights = experiment.weights.map((weight) => Number(weight));
      }

      await updateMerchant(merchant.shopName, { experiment }, merchant);
      showSuccess('Successfully updated a/b test settings');
      refetch?.();
      setIsSubmitting(false);
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  const getWeightsTotal = (weights: number[]): number => Number((weights || []).reduce((acc, curr) => acc + Number(curr), 0).toFixed(2));

  if (!merchant) {
    return <EcoLoader size="large" />;
  }

  return (
    <View style={Gap()} className="md:w-1/2">
      <EcoCard title="A/B Test">
        <Formik initialValues={merchant} onSubmit={onSubmit} initialTouched={true} validateOnChange={false} validateOnBlur={false}>
          {({ setFieldValue, handleSubmit, values, errors, setFieldError, isValid }) => (
            <View style={Gap(2)}>
              <View className="mb-2">
                <FormPicker
                  items={availableExperiments}
                  label="A/B Testing"
                  value={values?.experiment?.key || '--'}
                  onValueChange={(key) => {
                    const { variations, weights } = getExperimentConfig(key) || {};
                    setFieldValue('experiment.variations', variations);
                    setFieldValue('experiment.weights', weights);
                    setFieldValue('experiment.key', key);
                  }}
                />
              </View>

              {values?.experiment?.variations?.length && (
                <View className="flex-row justify-between items-center">
                  <EcoText fontWeight="semibold">Variation</EcoText>
                  <EcoText fontWeight="semibold">
                    Weighting{' '}
                    <EcoText fontWeight="medium" color="subdued">
                      (even split is {String(1 / (values?.experiment?.variations?.length || 1)).slice(0, 6)})
                    </EcoText>{' '}
                  </EcoText>
                </View>
              )}
              {(values?.experiment?.variations || []).map((variation, i) => (
                <View key={variation} className="flex-row justify-between items-center">
                  <EcoText fontWeight="regular">"{variation}"</EcoText>
                  <EcoInput
                    value={String(values?.experiment?.weights?.[i] || 0)}
                    onChangeText={(value) => {
                      setFieldValue(`experiment.weights[${i}]`, value);
                      setFieldError(
                        `experiment.weights[${i}]`,
                        Number(value) < 0 || Number(value) > 1 ? 'Must be between 0 and 1' : undefined
                      );
                    }}
                    errors={errors}
                    textAlign="right"
                    field={`experiment.weights[${i}]`}
                    placeholder=".5"
                    type="number"
                    className="max-w-[130px]"
                  />
                </View>
              ))}

              {values?.experiment?.variations?.length && (
                <EcoText textAlign="right" className="p-4">
                  Total:{' '}
                  <EcoText fontWeight="semibold" color={getWeightsTotal(values?.experiment?.weights || 0) === 1 ? 'success' : 'danger'}>
                    {getWeightsTotal(values?.experiment?.weights).toFixed(2)}
                    {getWeightsTotal(values?.experiment?.weights || 0) !== 1 && ' (must equal 1)'}
                  </EcoText>
                </EcoText>
              )}

              <FormButtons>
                <EcoButton
                  isDisabled={
                    !isValid ||
                    isSubmitting ||
                    (!['--', null, undefined].includes(values?.experiment?.key as any) &&
                      getWeightsTotal(values?.experiment?.weights || []) !== 1.0)
                  }
                  isLoading={isSubmitting}
                  onPress={handleSubmit}
                >
                  Save
                </EcoButton>
              </FormButtons>
            </View>
          )}
        </Formik>
      </EcoCard>
    </View>
  );
}
