import LoginCard from '@components/cards/LoginCard';
import NewMemberForm from '@components/forms/NewMemberForm';
import { EcoButton, EcoKeyboardAvoidingView } from '@components/shared';
import { User } from '@ecocart/entities';
import { useContactSupport } from '@hooks/useContactSupport';
import { useNavigation } from '@react-navigation/native';
import { updateMerchantAdmin } from '@utils/api/merchant';
import { sendMerchantAdminUpdateMessage } from '@utils/api/slack';
import { addMerchantAccessToUser } from '@utils/api/user';
import { StorageKey, storage } from '@utils/storage';
import { useEffect, useState } from 'react';
import { SafeAreaView, View } from 'react-native';
import { GlobalScreenProps } from '../navigation/types';

export default function NewMemberScreen({ route }: GlobalScreenProps): JSX.Element {
  const shopName = route.params?.shopName ? decodeURIComponent(route.params?.shopName) : undefined;
  const shpat = route.params?.shpat ? decodeURIComponent(route.params?.shpat) : undefined;
  const userIsReauthenticating = route.params?.welcome === 'back';
  const [step, setStep] = useState<'start' | 'user_exists' | 'user_created'>('start');
  const [user, setUser] = useState<User>();
  const [isNavigating, setIsNavigating] = useState<boolean>(false);
  const { showContactSupportModal } = useContactSupport();
  const navigation = useNavigation();

  useEffect(() => {
    if (!shopName || !shpat) {
      // TODO figure out why linkTo('/login') isn't working here
      location.href = '/login';
    }
  }, [shopName, shpat]);

  const onUserCreated = async (_user: User) => {
    setUser(_user);
    setStep('user_created');
    setMerchantAdminContactEmail(_user);

    await sendMerchantAdminUpdateMessage('new_user_created', { user: _user }, { merchantAdmin: { shopName }, shpat });
  };

  const setMerchantAdminContactEmail = async (_user: User): Promise<void> => {
    if (!user?.merchants?.[0]) return;

    const shopName = Object.keys(user.merchants)[0];
    await updateMerchantAdmin(shopName, { contactEmail: _user.id }, shpat);
  };

  const onUserExists = async (_user: User) => {
    setUser(_user);
    setStep('user_exists');
  };

  const handleAddMerchantAccessToUser = async () => {
    if (!shopName || !shpat || !user?.id) return;

    // used to avoid double click
    setIsNavigating(true);

    const updatedUser = user;
    updatedUser.merchants[shopName] = true;
    await addMerchantAccessToUser(updatedUser.id, updatedUser, shpat);
    await sendMerchantAdminUpdateMessage('existing_user_granted_access', { user: updatedUser }, { merchantAdmin: { shopName }, shpat });

    // store for subsequent login
    storage.setItem(StorageKey.last_active_shop_name, shopName);

    navigation.navigate('Login', { email: updatedUser.id });
    setIsNavigating(false);
  };

  const getLoginProps = (): { title: string; subTitle: string } => {
    switch (step) {
      case 'user_exists':
        return {
          title: userIsReauthenticating ? '' : 'Hello, again!',
          subTitle: 'There is already an account associated with that email address. Please log in to continue'
        };
      case 'user_created':
        if (userIsReauthenticating) {
          return {
            title: `You're Almost There`,
            subTitle: 'Woohoo! Please check your email to verify your account and set a new password.'
          };
        } else {
          return { title: 'Congratulations!', subTitle: 'Woohoo! Please check your email to verify your account and set up a password.' };
        }
      case 'start':
      default:
        if (userIsReauthenticating) {
          return { title: 'Hello again!', subTitle: 'Please reauthenticate your account to log in to EcoCart' };
        } else {
          return { title: 'Welcome!', subTitle: 'Thanks for choosing EcoCart! To get started, enter your store information below' };
        }
    }
  };

  return (
    <SafeAreaView className="w-full h-full bg-tertiary-800">
      <EcoKeyboardAvoidingView className="flex-row justify-center items-center w-full h-full">
        <LoginCard {...getLoginProps()}>
          {step === 'start' && shpat && shopName ? (
            <NewMemberForm
              user={{ userType: 'merchant_admin', merchants: { [shopName]: true } }}
              shpat={shpat}
              userIsReauthenticating={userIsReauthenticating}
              onSuccess={onUserCreated}
              onError={onUserExists}
            />
          ) : null}
          {step === 'user_exists' && shpat && shopName ? (
            <>
              <View className="flex flex-row justify-evenly w-full py-2">
                <EcoButton
                  nativeID="signup-form__go-back-button"
                  variant="outlined"
                  className="mr-3 flex-1"
                  onPress={() => setStep('start')}
                >
                  Go Back
                </EcoButton>
                <EcoButton
                  nativeID="signup-form__log-in-button"
                  isDisabled={isNavigating}
                  className="ml-3 flex-1"
                  onPress={handleAddMerchantAccessToUser}
                >
                  Log In
                </EcoButton>
              </View>
              <View className="flex-row justify-center">
                <EcoButton
                  nativeID="signup-form__contact-support-button"
                  size="sm"
                  variant="ghost"
                  onPress={() => {
                    showContactSupportModal({
                      email: user?.id,
                      support_form_submission: `I'm having issues logging into my store ${shopName} using email ${user?.id}.`
                    });
                  }}
                  className="pt-5 flex-row justify-center no-underline"
                >
                  Not you? Contact Support
                </EcoButton>
              </View>
            </>
          ) : null}
        </LoginCard>
      </EcoKeyboardAvoidingView>
    </SafeAreaView>
  );
}
