/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */
import { Template } from '@ecocart/entities';
import * as Linking from 'expo-linking';
import {
  BillingScreenName,
  CampaignsScreenName,
  ClaimsScreenName,
  HomeScreenName,
  IntegrationsScreenName,
  InternalToolsScreenName,
  ManageProjectsScreenName,
  MerchantsScreenName,
  ProfileScreenName,
  ProjectsScreenName,
  RewardsScreenName,
  ScreenName,
  SettingsScreenName,
  TouchPointsScreenName,
  UsersScreenName
} from './StackNavigators';

export const PUBLIC_ROUTES = [
  '/login',
  '/reset-password',
  '/forgot-password',
  '/signup',
  '/welcome',
  '/new-member',
  '/shopify',
  '/design-system',
  '/unsubscribe',
  '/claims'
] as const;
export const ROUTE_PATHS = [
  ...PUBLIC_ROUTES,
  '/home',
  '/merchants',
  '/merchant/:shopName/settings',
  '/merchant/:shopName/dashboard',
  '/merchant/:shopName/projects',
  '/merchant/:shopName/ecorewards',
  '/merchant/:shopName/ecorewards/history',
  '/merchant/:shopName/billing',
  '/merchant/:shopName/widgets',
  '/merchant/:shopName/widgets/testing',
  '/merchant/:shopName/widgets/:template',
  '/merchant/:shopName/widgets/:template/:widgetKey',
  '/merchant/:shopName/users',
  '/merchant/:shopName/campaigns',
  '/merchant/:shopName/offsetting',
  '/merchant/:shopName/protection',
  '/merchant/:shopName/setup',
  '/merchant/:shopName/cashback',
  '/users',
  '/user/:id',
  '/touchpoints/homepage',
  '/touchpoints/checkout-page',
  '/touchpoints/cart-page',
  '/touchpoints/product-page',
  '/touchpoints/post-purchase-page',
  '/touchpoints/other-pages',
  '/touchpoints/slide-out-cart',
  '/rewards',
  '/rewards/history',
  '/claims/homepage',
  '/settings/general',
  '/settings/store',
  '/settings/user',
  '/settings/apikey',
  '/profile',
  '/projects',
  '/project/:id',
  '/manage-projects',
  '/manage-project/:id',
  '/campaigns',
  '/billing',
  '/integrations',
  '/tools/translations',
  '/tools/design-system',
  '/tools/widget-designer'
] as const;

export type RoutePath = (typeof ROUTE_PATHS)[number];

// ~ Add new queryParams here (for type safety, always make optional)
type ScreenQueryParams = { email?: string };

export type SnakeToKebabCase<T extends string> = T extends `${infer First}_${infer Rest}`
  ? `${Lowercase<First>}-${SnakeToKebabCase<Rest>}`
  : Lowercase<T>;

export type SpaceToKebabCase<T extends string> = T extends `${infer First} ${infer Rest}`
  ? `${Lowercase<First>}-${SnakeToKebabCase<Rest>}`
  : Lowercase<T>;

export type KebabTemplate = SnakeToKebabCase<Template>;

// ~ Add new path params here (for type safety, always make optional)
export interface ScreenParams extends ScreenQueryParams {
  screen?: ScreenName;
  id?: string;
  shopName?: string;
  page?: number;
  // Global settings tab doesn't belong to any "template", and the route is /widgets
  template?: KebabTemplate | '';
  widgetKey?: string;
  period?: string; // DatePicker period in Home Screen
  params?: ScreenParams;
}

type ScreenNameFields = {
  [key in ScreenName]?: ScreenParams;
};

declare global {
  interface Window {
    Stripe: (publishable_key: string) => {
      collectBankAccountForSetup: any;
      confirmUsBankAccountSetup: any;
    };
  }
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'eco-widget': React.DetailedHTMLProps<
        { widget: string; merchant: string; calculation?: string; cart?: string } & React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    interface RootParamList extends ScreenNameFields {}
  }
}

interface LinkingConfigurationProps {
  prefixes: string[];
  config: {
    screens: {
      Tabs: {
        screens: {
          HomeTab: {
            initialRouteName: HomeScreenName;
            screens: Record<HomeScreenName, RoutePath>;
          };
          MerchantsTab: {
            initialRouteName: MerchantsScreenName;
            screens: Record<MerchantsScreenName, RoutePath>;
          };
          UsersTab: {
            initialRouteName: UsersScreenName;
            screens: Record<UsersScreenName, RoutePath>;
          };
          ProfileTab: {
            initialRouteName: ProfileScreenName;
            screens: Record<ProfileScreenName, RoutePath>;
          };
          SettingsTab: {
            initialRouteName: SettingsScreenName;
            screens: Record<SettingsScreenName, RoutePath>;
          };
          ProjectsTab: {
            initialRouteName: ProjectsScreenName;
            screens: Record<ProjectsScreenName, RoutePath>;
          };
          ManageProjectsTab: {
            initialRouteName: ManageProjectsScreenName;
            screens: Record<ManageProjectsScreenName, RoutePath>;
          };
          CampaignsTab: {
            initialRouteName: CampaignsScreenName;
            screens: Record<CampaignsScreenName, RoutePath>;
          };
          BillingTab: {
            initialRouteName: BillingScreenName;
            screens: Record<BillingScreenName, RoutePath>;
          };
          IntegrationsTab: {
            initialRouteName: IntegrationsScreenName;
            screens: Record<IntegrationsScreenName, RoutePath>;
          };
          TouchPointsTab: {
            initialRouteName: TouchPointsScreenName;
            screens: Record<TouchPointsScreenName, RoutePath>;
          };
          ClaimsTab: {
            initialRouteName: ClaimsScreenName;
            screens: Record<ClaimsScreenName, RoutePath>;
          };
          RewardsTab: {
            initialRouteName: RewardsScreenName;
            screens: Record<RewardsScreenName, RoutePath>;
          };
          InternalToolsTab: {
            initialRouteName: InternalToolsScreenName;
            screens: Record<InternalToolsScreenName, RoutePath>;
          };
        };
      };
      Login: RoutePath;
      NewMember: RoutePath;
      Welcome: RoutePath;
      ResetPassword: RoutePath;
      ForgotPassword: RoutePath;
      Shopify: RoutePath;
      DesignSystem: RoutePath;
      EmailUnsubscribe: RoutePath;
      Claims: RoutePath;
    };
  };
}

export const LinkingConfiguration: LinkingConfigurationProps = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Tabs: {
        screens: {
          HomeTab: {
            initialRouteName: 'Home',
            screens: {
              Home: '/home'
            }
          },
          MerchantsTab: {
            initialRouteName: 'Merchants',
            screens: {
              Merchants: '/merchants',
              MerchantSettings: '/merchant/:shopName/settings',
              MerchantDashboard: '/merchant/:shopName/dashboard',
              MerchantRewards: '/merchant/:shopName/ecorewards',
              MerchantRewardsHistory: '/merchant/:shopName/ecorewards/history',
              MerchantProjects: '/merchant/:shopName/projects',
              MerchantBilling: '/merchant/:shopName/billing',
              MerchantWidgetEditor: '/merchant/:shopName/widgets/:template/:widgetKey',
              MerchantWidgetTesting: '/merchant/:shopName/widgets/testing',
              MerchantWidgetTemplates: '/merchant/:shopName/widgets/:template',
              MerchantWidgets: '/merchant/:shopName/widgets',
              MerchantUsers: '/merchant/:shopName/users',
              MerchantCampaigns: '/merchant/:shopName/campaigns',
              MerchantOffsetting: '/merchant/:shopName/offsetting',
              MerchantProtection: '/merchant/:shopName/protection',
              MerchantSetup: '/merchant/:shopName/setup',
              MerchantCashbackSetting: '/merchant/:shopName/cashback'
            }
          },
          UsersTab: {
            initialRouteName: 'Users',
            screens: {
              Users: '/users',
              User: '/user/:id'
            }
          },
          RewardsTab: {
            initialRouteName: 'Rewards',
            screens: {
              Rewards: '/rewards',
              RewardsHistory: '/rewards/history'
            }
          },
          ClaimsTab: {
            initialRouteName: 'Claims',
            // @ts-expect-error - Claims screen is public
            screens: {
              ClaimsHome: '/claims/homepage'
            }
          },
          TouchPointsTab: {
            initialRouteName: 'TouchPointsHomepage',
            screens: {
              TouchPointsHomepage: '/touchpoints/homepage',
              TouchPointsCheckoutPage: '/touchpoints/checkout-page',
              TouchPointsCartPage: '/touchpoints/cart-page',
              TouchPointsProductPage: '/touchpoints/product-page',
              TouchPointsPostPurchasePage: '/touchpoints/post-purchase-page',
              TouchPointsOtherPages: '/touchpoints/other-pages',
              TouchPointsSlideOutCart: '/touchpoints/slide-out-cart'
            }
          },
          SettingsTab: {
            initialRouteName: 'SettingsGeneral',
            screens: {
              SettingsGeneral: '/settings/general',
              SettingsStore: '/settings/store',
              SettingsUser: '/settings/user',
              SettingsApiKey: '/settings/apikey'
            }
          },
          ProfileTab: {
            initialRouteName: 'MyProfile',
            screens: {
              MyProfile: '/profile'
            }
          },
          ProjectsTab: {
            initialRouteName: 'Projects',
            screens: {
              Projects: '/projects',
              Project: '/project/:id'
            }
          },
          ManageProjectsTab: {
            initialRouteName: 'ManageProjects',
            screens: {
              ManageProjects: '/manage-projects',
              ManageProject: '/manage-project/:id'
            }
          },
          CampaignsTab: {
            initialRouteName: 'Campaigns',
            screens: {
              Campaigns: '/campaigns'
            }
          },
          BillingTab: {
            initialRouteName: 'Billing',
            screens: {
              Billing: '/billing'
            }
          },
          IntegrationsTab: {
            initialRouteName: 'Integrations',
            screens: {
              Integrations: '/integrations'
            }
          },
          InternalToolsTab: {
            initialRouteName: 'Translations',
            screens: {
              Translations: '/tools/translations',
              DesignSystem: '/tools/design-system',
              WidgetDesigner: '/tools/widget-designer'
            }
          }
        }
      },
      Login: '/login',
      NewMember: '/new-member',
      Welcome: '/welcome',
      ResetPassword: '/reset-password',
      ForgotPassword: '/forgot-password',
      Shopify: '/shopify',
      DesignSystem: '/design-system',
      EmailUnsubscribe: '/unsubscribe',
      Claims: '/claims'
    }
  }
};

const getRoutePathScreenNameMap = (): Record<RoutePath, ScreenName> => {
  const { Tabs, ...SingleScreens } = LinkingConfiguration.config.screens;

  const TabScreenNames = Object.values(Tabs.screens).reduce((acc, curr) => {
    Object.keys(curr.screens).forEach((ScreenName) => ((acc as any)[(curr as any).screens[ScreenName]] = ScreenName));
    return acc;
  }, {} as Record<RoutePath, ScreenName>);

  return {
    ...TabScreenNames,
    ...Object.keys(SingleScreens).reduce((acc, ScreenName) => {
      (acc as any)[(SingleScreens as any)[ScreenName]] = ScreenName;
      return acc;
    }, {} as Record<RoutePath, ScreenName>)
  };
};
const routePathScreenNameMap = getRoutePathScreenNameMap();

export const getScreenNameFromRoutePath = (path: RoutePath): any => routePathScreenNameMap[path];
