import React from 'react';
import { View } from 'react-native';
import { EcoCheckbox, EcoCheckboxProps } from './EcoCheckbox';
import { EcoText } from './EcoText';

interface Props extends EcoCheckboxProps {
  label: string;
}

export function FormCheckbox({ label, onValueChange, value, disabled, ...otherProps }: Props): JSX.Element {
  return (
    <View className="items-center flex-row">
      <EcoCheckbox value={value} onValueChange={onValueChange} disabled={disabled ?? false} {...otherProps} />
      <EcoText fontSize="sm" className="label-base">
        {label}
      </EcoText>
    </View>
  );
}
