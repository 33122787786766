import { GlobalStylesProps } from '@utils/prop-types';
import { FormikErrors, FormikTouched } from 'formik';
import { get } from 'lodash';
import { EcoText } from './EcoText';

export interface FormErrorProps extends GlobalStylesProps {
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  field: string;
}

export function FormError({ field, errors, touched, style }: FormErrorProps): JSX.Element {
  const errorMsg = get(errors, field) as string | undefined;
  const showError = errorMsg != null && get(touched, field);

  return showError ? (
    <EcoText fontSize="2xs" color="danger" className="pb-1 mt-1" style={style}>
      {errorMsg}
    </EcoText>
  ) : (
    <></>
  );
}
