import { EcoButton, EcoCard, EcoCheckbox, EcoInput, EcoLoader, EcoText, FormButtons, FormPicker } from '@components/shared';
import { CART_REFRESH_OVERRIDES, OFFSET_TYPES, WidgetAllSettings } from '@ecocart/entities';
import { pascalToSpaced } from '@ecocart/universal-utils';
import { useOverlay } from '@hooks/useOverlay';
import { MerchantContext } from '@screens/MerchantWidgetsScreen';
import { updateMerchant } from '@utils/api/merchant';
import { Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Gap } from '../../utils/layout';

export default function EditMerchantWidgetSettingsForm(): JSX.Element {
  const { merchant, refetch } = useContext(MerchantContext);
  const { showSuccess, handleError } = useOverlay();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [merchantEnableAnalytics, setMerchantEnableAnalytics] = useState<boolean>(true);
  const [merchantEnableCartTagging, setMerchantEnableCartTagging] = useState<boolean>(true);
  const [merchantWidgetAllSettings, setMerchantWidgetAllSettings] = useState<WidgetAllSettings>();
  const [hidePriceInCheckoutExtension, setHidePriceInCheckoutExtension] = useState<boolean>(!!merchant?.hidePriceInCheckoutExtension);

  useEffect(() => {
    if (!merchant) return;

    if (merchant?.enableAnalytics !== undefined) setMerchantEnableAnalytics(merchant.enableAnalytics);
    if (merchant?.enableCartTagging !== undefined) setMerchantEnableCartTagging(merchant.enableCartTagging);

    const { breakpoints, ...defaultSettings } = { ...new WidgetAllSettings(), ...merchant.widgetAllSettings };
    setMerchantWidgetAllSettings(defaultSettings);
  }, [merchant]);

  const onSubmit = async (settings: WidgetAllSettings) => {
    if (!merchant) return;

    setIsSubmitting(true);

    try {
      await updateMerchant(
        merchant.shopName,
        {
          widgetAllSettings: settings,
          enableAnalytics: merchantEnableAnalytics,
          enableCartTagging: merchantEnableCartTagging,
          hidePriceInCheckoutExtension
        },
        merchant
      );
      showSuccess('Successfully updated merchant');
      refetch?.();
      setIsSubmitting(false);
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  if (!merchant) {
    return <EcoLoader size="large" />;
  }

  return (
    <View style={Gap()} className="md:w-1/2">
      {merchantWidgetAllSettings ? (
        <EcoCard title="General">
          <Formik initialValues={merchantWidgetAllSettings} onSubmit={onSubmit}>
            {({ setFieldValue, handleChange, handleSubmit, values, isValid }) => (
              <View style={Gap(2)}>
                <View className="flex-row align-center">
                  <EcoCheckbox value={merchantEnableAnalytics} onValueChange={(value: any) => setMerchantEnableAnalytics(value)}>
                    <EcoText>Enable Analytics</EcoText>
                  </EcoCheckbox>
                </View>
                <View className="flex-row align-center">
                  <EcoCheckbox value={merchantEnableCartTagging} onValueChange={(value: any) => setMerchantEnableCartTagging(value)}>
                    <EcoText>Enable Cart-Tagging </EcoText>
                    <EcoText color="subdued">
                      (sets <EcoText fontWeight="semibold">_esid</EcoText> and <EcoText fontWeight="semibold">_ecid</EcoText> values in cart
                      attributes)
                    </EcoText>
                  </EcoCheckbox>
                </View>

                <View className="pt-4" style={Gap()}>
                  <EcoText fontSize="xl" fontWeight="semibold">
                    Shopify Extension Settings
                  </EcoText>
                  <EcoCheckbox value={hidePriceInCheckoutExtension} onValueChange={setHidePriceInCheckoutExtension}>
                    <EcoText>Hide Checkout Extension Price</EcoText>
                  </EcoCheckbox>
                </View>

                <View className="pt-4">
                  <EcoText fontSize="xl" fontWeight="semibold">
                    Cart-Widget Settings
                  </EcoText>
                  {Object.keys(values)
                    .filter((key) => key !== 'offsets')
                    .map((key) => {
                      if (typeof (values as any)[key] === 'boolean') {
                        return (
                          <View key={key} className="py-4 flex-row align-center">
                            <EcoCheckbox value={(values as any)[key] || false} onValueChange={(value) => setFieldValue(key, value)}>
                              <EcoText>{pascalToSpaced(key)}</EcoText>
                            </EcoCheckbox>
                          </View>
                        );
                      } else {
                        return (
                          <FormPicker
                            key={key}
                            label={pascalToSpaced(key)}
                            items={CART_REFRESH_OVERRIDES as any}
                            value={(values as any)[key]}
                            onValueChange={handleChange(key)}
                          />
                        );
                      }
                    })}
                </View>

                <View style={Gap()}>
                  <EcoText fontSize="xl" fontWeight="semibold">
                    Offsets
                  </EcoText>
                  {(values.offsets || []).map((offset, i) => (
                    <View key={offset.type + i} className="flex-row items-center" style={Gap()}>
                      <FormPicker
                        label=""
                        items={OFFSET_TYPES as any}
                        value={offset.type}
                        onValueChange={(value) => {
                          const updatedOffsets = values.offsets || [];
                          updatedOffsets[i].type = value;
                          setFieldValue('offsets', updatedOffsets);
                        }}
                      />
                      <EcoInput
                        value={offset.maxAllowable as any}
                        placeholder="Max Allowable"
                        onChangeText={(value: any) => {
                          const updatedOffsets = values.offsets || [];
                          updatedOffsets[i].maxAllowable = Number(value);
                          setFieldValue('offsets', updatedOffsets);
                        }}
                      />
                    </View>
                  ))}
                </View>

                <FormButtons>
                  <EcoButton isDisabled={!isValid || isSubmitting} isLoading={isSubmitting} onPress={handleSubmit}>
                    Save
                  </EcoButton>
                </FormButtons>
              </View>
            )}
          </Formik>
        </EcoCard>
      ) : (
        <></>
      )}
    </View>
  );
}
