import { EcoButton, EcoCard, EcoCheckbox, EcoInput, EcoRadioButtons, EcoText, EcoToggle, EcoWidget, FormPicker } from '@components/shared';
import { MerchantFileUploader } from '@components/uploaders/MerchantFileUploader';
import { LOCALES, Merchant } from '@ecocart/entities';
import { IMAGE_ASSETS, fromBase64, isValidBase64, toBase64 } from '@ecocart/universal-utils';
import { useOverlay } from '@hooks/useOverlay';
import { BackLink } from '@navigation/BackLink';
import { useNavigation } from '@react-navigation/native';
import { MerchantContext } from '@screens/MerchantWidgetsScreen';
import { updateMerchant } from '@utils/api/merchant';
import { Gap } from '@utils/layout';
import { CreateValidationSchema } from '@utils/validation';
import { useFormik } from 'formik';
import { get, noop, pick } from 'lodash';
import { useContext, useState } from 'react';
import { View } from 'react-native';
import { ConfigContainer } from '../views/ConfigContainer';
import {
  ECLogo,
  FLOATING_IMAGE_POSITIONS,
  FloatingImagePositionValue,
  MERCHANT_LOGO_ITEMS,
  MerchantLogoSize,
  SizeMap,
  getInitialValues
} from './ImpactCounterDesigner.utils';
import {
  FieldPathValues,
  convertCountryCodeToEnglishName,
  fieldPaths,
  getPlainImageUrl,
  getProps,
  getTranslationPath,
  getZIndexProps,
  handleBgImgUrlChange,
  isATextPath
} from './utils';

export function ImpactCounterDesigner({ widgetKey }: { widgetKey: string }): JSX.Element {
  const { showSuccess, handleError } = useOverlay();
  const navigation = useNavigation();
  const { merchant, refetch } = useContext(MerchantContext);
  const [isSecondaryCTASameAsPrimaryCTA, setIsSecondaryCTASameAsPrimaryCTA] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showHeaderLang, setShowHeaderLang] = useState<boolean>(false);
  const [showSubHeaderLang, setShowSubHeaderLang] = useState<boolean>(false);
  const { widgetMap } = merchant || {};
  const isValidWidgetKey = widgetMap && widgetKey in widgetMap;
  if (merchant && !isValidWidgetKey) {
    navigateToWidgetList();
  }

  function navigateToWidgetList() {
    if (!merchant) return;
    return navigation.navigate('MerchantWidgetTemplates', { shopName: merchant?.shopName, template: 'impact-counter' });
  }

  const onSubmit = async () => {
    if (!merchant) return;

    setIsSubmitting(true);

    const updatedMerchant: Partial<Merchant> = {
      widgetMap: {
        ...merchant.widgetMap,
        [widgetKey]: values
      }
    };

    try {
      await updateMerchant(merchant.shopName, updatedMerchant, merchant);
      showSuccess('Successfully updated merchant');
      setIsSubmitting(false);
      refetch && refetch();
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: widgetMap?.[widgetKey] || getInitialValues(),
    onSubmit,
    validateOnChange: true,
    validationSchema: CreateValidationSchema(['elements'])
  });

  const { values, handleChange, touched, isValid, setFieldTouched, handleSubmit, errors, setFieldValue } = formik;

  function getCTAProps(shouldSync: boolean, path: FieldPathValues, pathToSyncVal: FieldPathValues) {
    const value = get(values, path, '') as string;
    const isTextPath = isATextPath(path);

    return {
      onBlur: () => setFieldTouched(path),
      onChangeText: (val: string) => {
        const _val = isTextPath ? toBase64(val) : val;
        handleChange(path)(_val);
        shouldSync ? handleChange(pathToSyncVal)(_val) : noop;
      },
      value: isValidBase64(value) && isTextPath ? fromBase64(value) : value,
      errors,
      field: path,
      touched
    };
  }

  function handleSyncCTAStyles(val: boolean) {
    setIsSecondaryCTASameAsPrimaryCTA(val);

    if (val) {
      setFieldValue(fieldPaths.cta2FontFamily, get(values, fieldPaths.ctaFontFamily));
      setFieldValue(fieldPaths.cta2FontSize, get(values, fieldPaths.ctaFontSize));
      setFieldValue(fieldPaths.cta2FontWeight, get(values, fieldPaths.ctaFontWeight));
      setFieldValue(fieldPaths.cta2FontColor, get(values, fieldPaths.ctaFontColor));
      setFieldValue(fieldPaths.cta2BackgroundColor, get(values, fieldPaths.ctaBackgroundColor));
      setFieldValue(fieldPaths.cta2BorderColor, get(values, fieldPaths.ctaBorderColor));
      setFieldValue(fieldPaths.cta2BorderRadius, get(values, fieldPaths.ctaBorderRadius));
    }
  }

  function handleFloatingImageUrlChange(imageUrl: string) {
    handleChange(fieldPaths.imgURL)(imageUrl);
  }

  function handleFloatingImagePositionChange(imagePosition: FloatingImagePositionValue) {
    handleChange(fieldPaths.imgPosition)(imagePosition === 'default' ? '' : imagePosition);
  }

  function handleLogoImageUrlChange(imageUrl: string) {
    handleChange(fieldPaths.logo2URL)(imageUrl);
  }

  function handleMerchantLogoSizeChange(size: MerchantLogoSize) {
    handleChange(fieldPaths.logo2Size)(SizeMap[size]);
  }

  const getMerchantLogoSize = () => {
    const value = get(values, fieldPaths.logo2Size);
    for (const key in SizeMap) {
      if (SizeMap[key as MerchantLogoSize] === value) {
        return key;
      }
    }
    return '';
  };

  const ctaDisabled = Boolean(values.elements?.cta?.disable);
  const cta2Disabled = Boolean(values.elements?.cta2?.disable);
  const subHeaderDisabled = Boolean(values.elements?.subHeader?.disable);

  return (
    <>
      <View className="pb-4 flex-row">
        <BackLink onPress={navigateToWidgetList} label="Back To Widgets" />
      </View>
      <View style={Gap()}>
        <EcoCard>
          <EcoText fontSize="xl" fontWeight="semibold" className="mb-2">
            Preview
          </EcoText>
          <View style={{ minHeight: 400 }}>{values && merchant && <EcoWidget widget={values} merchant={merchant} />}</View>
        </EcoCard>
        <EcoCard>
          <ConfigContainer>
            <>
              <EcoText fontSize="xl" fontWeight="semibold" className="my-6">
                Configurations
              </EcoText>
              {/* Configuration component */}
              <form onSubmit={formik.handleSubmit}>
                <View style={Gap(8)}>
                  <View>
                    <View style={Gap(8)}>
                      <View style={Gap()}>
                        <EcoText fontSize="lg" fontWeight="medium">
                          EcoCart Logo
                        </EcoText>
                        <EcoRadioButtons
                          value={get(values, fieldPaths.logoURL)}
                          items={[
                            { label: 'White', value: IMAGE_ASSETS.ECOCART_WHITE },
                            { label: 'Black', value: IMAGE_ASSETS.ECOCART_BLACK },
                            { label: 'Standard', value: IMAGE_ASSETS.ECOCART },
                            { label: 'Inverted', value: IMAGE_ASSETS.ECOCART_COLOR_WHITE }
                          ]}
                          onValueChange={(value: ECLogo) => handleChange('elements.logo.custom.url')(value)}
                        />
                      </View>
                      <View style={Gap()}>
                        <EcoText fontSize="lg" fontWeight="medium">
                          Merchant Assets
                        </EcoText>
                        <View className="flex-row" style={Gap()}>
                          <View className="w-1/5">
                            <FormPicker
                              label="Logo Size"
                              items={MERCHANT_LOGO_ITEMS}
                              value={getMerchantLogoSize()}
                              onValueChange={handleMerchantLogoSizeChange}
                            />
                          </View>
                          <EcoInput label="Merchant Logo URL" {...getProps(formik, fieldPaths.logo2URL)} placeholder="https://" />
                          <View className="flex-row items-end">
                            <MerchantFileUploader
                              transparentErrorColor
                              {...pick(getProps(formik, fieldPaths.logo2URL), ['errors', 'touched', 'field'])}
                              onSuccess={handleLogoImageUrlChange}
                              onError={function (error: any): void {
                                throw new Error(`Error was thrown when uploading merchant logo file. Erorr is ${error}`);
                              }}
                              shopName={merchant?.shopName || ''}
                              fileName={`${widgetKey}_logo`}
                            />
                          </View>
                        </View>

                        <View style={Gap()}>
                          <View className="flex-row" style={Gap()}>
                            <View className="w-1/5">
                              <FormPicker
                                label="Floating Image Position"
                                items={FLOATING_IMAGE_POSITIONS}
                                value={get(values, fieldPaths.imgPosition, 'default')}
                                onValueChange={handleFloatingImagePositionChange}
                              />
                            </View>
                            <EcoInput
                              label="Floating Image URL (jpg or png)"
                              {...getProps(formik, fieldPaths.imgURL)}
                              value={getPlainImageUrl(get(values, fieldPaths.imgURL) as string)}
                              placeholder="https://"
                            />
                            <View className="flex-row items-end">
                              <MerchantFileUploader
                                transparentErrorColor
                                {...pick(getProps(formik, fieldPaths.imgURL), ['errors', 'touched', 'field'])}
                                onSuccess={handleFloatingImageUrlChange}
                                onError={function (error: any): void {
                                  throw new Error(`Error was thrown when uploading floating image file. Erorr is ${error}`);
                                }}
                                shopName={merchant?.shopName || ''}
                                fileName={`${widgetKey}_floating`}
                              />
                            </View>
                          </View>
                          <View style={Gap()}>
                            <View style={Gap()}>
                              <EcoText fontSize="base" fontWeight="medium">
                                Behavior
                              </EcoText>
                            </View>
                            <View className="flex-row flex-wrap items-start" style={Gap(8)}>
                              <View>
                                <EcoText fontSize="sm" fontWeight="medium">
                                  Mobile
                                </EcoText>
                                <View style={Gap()}>
                                  <EcoInput
                                    label="Spacing Between Image and Content"
                                    {...getProps(formik, fieldPaths.contentGap)}
                                    placeholder="72px"
                                  />
                                  <EcoInput
                                    label="Floating Image Height/Width Ratio (%)"
                                    {...getProps(formik, fieldPaths.imgPaddingTop)}
                                    placeholder="100%"
                                  />
                                </View>
                              </View>
                              <View>
                                <EcoText fontSize="sm" fontWeight="medium">
                                  Desktop
                                </EcoText>
                                <View style={Gap()}>
                                  <EcoInput
                                    label="Spacing Between Image and Content"
                                    {...getProps(formik, fieldPaths.contentGapMD)}
                                    placeholder="72px"
                                  />
                                  <EcoInput
                                    // Using padding to set different aspect ratio to keep the image height dynamic
                                    // We call it "height" for CS team, but it's actually padding-top
                                    label="Floating Image Height/Width Ratio (%)"
                                    {...getProps(formik, fieldPaths.imgPaddingTopMD)}
                                    placeholder="100%"
                                  />
                                  <EcoInput
                                    label="Floating Image Width"
                                    {...getProps(formik, fieldPaths.imgFlexBasisMD)}
                                    placeholder="25%"
                                  />
                                  <EcoInput label="Content Width" {...getProps(formik, fieldPaths.contentFlexBasisMD)} placeholder="75%" />
                                </View>
                              </View>
                              <View>
                                <EcoText fontSize="sm" fontWeight="medium">
                                  Desktop (Large)
                                </EcoText>
                                <View style={Gap()}>
                                  <EcoInput
                                    label="Spacing Between Image and Content"
                                    {...getProps(formik, fieldPaths.contentGapLG)}
                                    placeholder="72px"
                                  />
                                  <EcoInput
                                    label="Floating Image Height/Width Ratio (%)"
                                    {...getProps(formik, fieldPaths.imgPaddingTopLG)}
                                    placeholder="100%"
                                  />
                                  <EcoInput
                                    label="Floating Image Width"
                                    {...getProps(formik, fieldPaths.imgFlexBasisLG)}
                                    placeholder="25%"
                                  />
                                  <EcoInput label="Content Width" {...getProps(formik, fieldPaths.contentFlexBasisLG)} placeholder="75%" />
                                </View>
                              </View>
                            </View>
                          </View>

                          <View className="flex-row mt-4">
                            <EcoRadioButtons
                              value={values.elements?.img?.styles?.order === '1' ? 'after' : 'before'}
                              items={[
                                { label: 'Image Before Content', value: 'before' },
                                { label: 'Image After Content', value: 'after' }
                              ]}
                              onValueChange={(value: 'before' | 'after') =>
                                handleChange('elements.img.styles.order')(value === 'after' ? '1' : '0')
                              }
                            />
                          </View>
                        </View>
                        <View style={Gap()}>
                          <View className="flex-row" style={Gap()}>
                            <EcoInput
                              label="Background Image"
                              className="w-2/3"
                              {...getProps(formik, fieldPaths.backgroundImg)}
                              value={getPlainImageUrl(get(values, fieldPaths.backgroundImg) as string)}
                              onChangeText={(val: string) => handleBgImgUrlChange(formik, val)}
                              placeholder="https://"
                            />
                            <View className="flex-row items-end">
                              <MerchantFileUploader
                                transparentErrorColor
                                {...pick(getProps(formik, fieldPaths.backgroundImg), ['errors', 'touched', 'field'])}
                                onSuccess={(val: string) => handleBgImgUrlChange(formik, val)}
                                onError={function (error: any): void {
                                  throw new Error(`Error was thrown when uploading background image file. Erorr is ${error}`);
                                }}
                                shopName={merchant?.shopName || ''}
                                fileName={`${widgetKey}_background`}
                              />
                            </View>
                          </View>
                          <EcoInput
                            style={{ flex: 1 }}
                            label="Background Color"
                            {...getProps(formik, fieldPaths.backgroundColor)}
                            placeholder="#123456"
                            inputClassName="max-w-xs"
                          />
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={Gap()}>
                    <View style={Gap()}>
                      <EcoText fontSize="lg" fontWeight="medium">
                        Container
                      </EcoText>
                      <View style={Gap()}>
                        <EcoText fontSize="base" fontWeight="medium">
                          Styles
                        </EcoText>
                        <View className="flex-row" style={Gap()}>
                          <EcoInput
                            label="Z-Index"
                            className="w-1/5"
                            {...getZIndexProps(formik, fieldPaths.wrapperZIndex)}
                            placeholder="10, 20, 100, etc"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Border Width"
                            {...getProps(formik, fieldPaths.containerBorderWidth)}
                            placeholder="1px"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Border Color"
                            {...getProps(formik, fieldPaths.containerBorderColor)}
                            placeholder="#0F8354"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Border Radius"
                            {...getProps(formik, fieldPaths.wrapperBorderRadius, (val) =>
                              setFieldValue(fieldPaths.wrapperOverflow, val ? 'hidden' : '')
                            )}
                            placeholder="4px"
                          />
                        </View>
                      </View>
                      <View style={Gap()}>
                        <EcoText fontSize="base" fontWeight="medium">
                          Behavior
                        </EcoText>
                        <View className="flex-row items-start" style={Gap()}>
                          <View className="flex-1">
                            <EcoText fontSize="base" fontWeight="medium">
                              "xs" (0px to 489px)
                            </EcoText>
                            <EcoInput label="Max Width" {...getProps(formik, fieldPaths.maxWidth)} placeholder="500px, none, etc" />
                            <EcoInput label="Minimum Height" {...getProps(formik, fieldPaths.wrapperMinHeight)} placeholder="500px" />
                            <EcoInput label="Margin" {...getProps(formik, fieldPaths.wrapperMargin)} placeholder="40px" />
                          </View>
                          <View className="flex-1">
                            <EcoText fontSize="base" fontWeight="medium">
                              "sm" (490px and above)
                            </EcoText>
                            <EcoInput label="Max Width" {...getProps(formik, fieldPaths.maxWidthSM)} placeholder="500px, none, etc" />
                            <EcoInput label="Minimum Height" {...getProps(formik, fieldPaths.wrapperMinHeightSM)} placeholder="500px" />
                            <EcoInput label="Margin" {...getProps(formik, fieldPaths.wrapperMarginSM)} placeholder="40px" />
                          </View>
                          <View className="flex-1">
                            <EcoText fontSize="base" fontWeight="medium">
                              "md" (768px and above)
                            </EcoText>
                            <EcoInput label="Max Width" {...getProps(formik, fieldPaths.maxWidthMD)} placeholder="1280px, none, etc" />
                            <EcoInput label="Minimum Height" {...getProps(formik, fieldPaths.wrapperMinHeightMD)} placeholder="500px" />
                            <EcoInput label="Margin" {...getProps(formik, fieldPaths.wrapperMarginMD)} placeholder="40px" />
                          </View>
                          <View className="flex-1">
                            <EcoText fontSize="base" fontWeight="medium">
                              "lg" (1024px and above)
                            </EcoText>
                            <EcoInput label="Max Width" {...getProps(formik, fieldPaths.maxWidthLG)} placeholder="1280px, none, etc" />
                            <EcoInput label="Minimum Height" {...getProps(formik, fieldPaths.wrapperMinHeightLG)} placeholder="500px" />
                            <EcoInput label="Margin" {...getProps(formik, fieldPaths.wrapperMarginLG)} placeholder="40px" />
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={Gap()}>
                    <EcoText fontSize="lg" fontWeight="medium">
                      Content
                    </EcoText>
                    <View style={Gap()}>
                      <EcoText fontSize="base" fontWeight="medium">
                        Behavior
                      </EcoText>
                      <View className="flex-row" style={Gap()}>
                        <View className="flex-1">
                          <EcoText fontSize="base" fontWeight="medium">
                            "sm" (490px and above)
                          </EcoText>
                          <View>
                            <EcoInput label="Max Width" {...getProps(formik, fieldPaths.contentMaxWidth)} placeholder="600px" />
                          </View>
                        </View>
                        <View className="flex-1">
                          <EcoText fontSize="base" fontWeight="medium">
                            "md" (768px and above)
                          </EcoText>
                          <View>
                            <EcoInput label="Max Width" {...getProps(formik, fieldPaths.contentMaxWidthMD)} placeholder="800px" />
                          </View>
                        </View>
                        <View className="flex-1">
                          <EcoText fontSize="base" fontWeight="medium">
                            "lg" (1024px and above)
                          </EcoText>
                          <View>
                            <EcoInput label="Max Width" {...getProps(formik, fieldPaths.contentMaxWidthLG)} placeholder="1000px" />
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={Gap()}>
                    <EcoText fontSize="lg" fontWeight="medium">
                      Header Styles & Behavior
                    </EcoText>
                    <View>
                      <EcoText fontSize="lg" fontWeight="medium">
                        Header Text
                      </EcoText>
                      <EcoInput label="Header - English" {...getProps(formik, fieldPaths.headerTextEN)} placeholder="Some headline" />
                    </View>

                    <View style={Gap()}>
                      <EcoButton
                        size="sm"
                        rightIcon={showHeaderLang ? 'expand_less' : 'expand_more'}
                        variant="ghost"
                        onPress={() => setShowHeaderLang(!showHeaderLang)}
                      >
                        Show Translations
                      </EcoButton>
                      <View>
                        {showHeaderLang &&
                          LOCALES.map((locale) => (
                            <EcoInput
                              label={`Header - ${convertCountryCodeToEnglishName(locale)}`}
                              {...getProps(formik, getTranslationPath('header', locale))}
                              placeholder="Some headline"
                            />
                          ))}
                      </View>
                    </View>

                    <View>
                      <EcoText fontSize="base" fontWeight="medium">
                        Header Style
                      </EcoText>
                      <View className="flex-row flex-wrap" style={Gap()}>
                        <EcoInput label="Font Family Name" {...getProps(formik, fieldPaths.headerFontFamily)} placeholder="Roboto" />
                        <EcoInput label="Font Weight" {...getProps(formik, fieldPaths.headerFontWeight)} placeholder="300, 500, bold..." />
                        <EcoInput label="Font Color" {...getProps(formik, fieldPaths.headerColor)} placeholder="#123456" />{' '}
                      </View>
                    </View>

                    <View>
                      <EcoText fontSize="lg" fontWeight="medium">
                        Header Behavior
                      </EcoText>
                      <View className="flex-row items-start" style={Gap()}>
                        <View className="flex-1">
                          <EcoText fontSize="base" fontWeight="medium">
                            Default Styles
                          </EcoText>
                          <EcoInput label="Font Size" {...getProps(formik, fieldPaths.headerFontSize)} placeholder="24px, 3.5rem..." />
                          <EcoInput label="Line Height" {...getProps(formik, fieldPaths.headerLineHeight)} placeholder="30px, 1.25..." />
                          <EcoInput label="Margin" {...getProps(formik, fieldPaths.headerMargin)} placeholder="20px 0" />
                        </View>
                        <View className="flex-1">
                          <EcoText fontSize="base" fontWeight="medium">
                            "sm" (490px and above)
                          </EcoText>
                          <EcoInput label="Font Size" {...getProps(formik, fieldPaths.headerFontSizeSM)} placeholder="24px, 3.5rem..." />
                          <EcoInput label="Line Height" {...getProps(formik, fieldPaths.headerLineHeightSM)} placeholder="30px, 1.25..." />
                          <EcoInput label="Margin" {...getProps(formik, fieldPaths.headerMarginSM)} placeholder="20px 0" />
                        </View>
                        <View className="flex-1">
                          <EcoText fontSize="base" fontWeight="medium">
                            "md" (768px and above)
                          </EcoText>
                          <EcoInput label="Font Size" {...getProps(formik, fieldPaths.headerFontSizeMD)} placeholder="24px, 3.5rem..." />
                          <EcoInput label="Line Height" {...getProps(formik, fieldPaths.headerLineHeightMD)} placeholder="30px, 1.25..." />
                          <EcoInput label="Margin" {...getProps(formik, fieldPaths.headerMarginMD)} placeholder="20px 0" />
                        </View>
                        <View className="flex-1">
                          <EcoText fontSize="base" fontWeight="medium">
                            "lg" (1024px and above)
                          </EcoText>
                          <EcoInput label="Font Size" {...getProps(formik, fieldPaths.headerFontSizeLG)} placeholder="24px, 3.5rem..." />
                          <EcoInput label="Line Height" {...getProps(formik, fieldPaths.headerLineHeightLG)} placeholder="30px, 1.25..." />
                          <EcoInput label="Margin" {...getProps(formik, fieldPaths.headerMarginLG)} placeholder="20px 0" />
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={Gap()}>
                    <View className="flex-row items-center" style={Gap()}>
                      <EcoText fontSize="lg" fontWeight="medium">
                        Body Styles & Behavior
                      </EcoText>
                      <EcoToggle
                        value={!subHeaderDisabled}
                        onValueChange={(val: boolean) => {
                          setFieldValue(fieldPaths.subHeaderDisabled, !val);
                        }}
                      />
                    </View>
                    {!subHeaderDisabled && (
                      <View style={Gap()}>
                        <View style={Gap()}>
                          <View>
                            <EcoText fontSize="lg" fontWeight="medium">
                              Body Text
                            </EcoText>
                            <EcoInput
                              label="Body - English"
                              {...getProps(formik, fieldPaths.subHeaderTextEN)}
                              placeholder="Some subheading"
                            />
                          </View>

                          <View style={Gap()}>
                            <EcoButton
                              size="sm"
                              rightIcon={showSubHeaderLang ? 'expand_less' : 'expand_more'}
                              variant="ghost"
                              onPress={() => setShowSubHeaderLang(!showSubHeaderLang)}
                            >
                              Show Translations
                            </EcoButton>
                            <View>
                              {showSubHeaderLang &&
                                LOCALES.map((locale) => (
                                  <EcoInput
                                    label={`Body - ${convertCountryCodeToEnglishName(locale)}`}
                                    {...getProps(formik, getTranslationPath('subHeader', locale))}
                                    placeholder="Body text..."
                                  />
                                ))}
                            </View>
                          </View>

                          <View>
                            <EcoText fontSize="lg" fontWeight="medium">
                              Body Text Style
                            </EcoText>
                            <View className="flex-row" style={Gap()}>
                              <EcoInput
                                label="Font Family Name"
                                {...getProps(formik, fieldPaths.subHeaderFontFamily)}
                                placeholder="Inter"
                              />
                              <EcoInput
                                label="Font Weight"
                                {...getProps(formik, fieldPaths.subHeaderFontWeight)}
                                placeholder="300, 500, bold..."
                              />
                              <EcoInput label="Font Color" {...getProps(formik, fieldPaths.subHeaderColor)} placeholder="#123456" />
                            </View>
                          </View>
                        </View>

                        <View>
                          <EcoText fontSize="lg" fontWeight="medium">
                            Body Behavior
                          </EcoText>
                          <View className="flex-row items-start" style={Gap()}>
                            <View className="flex-1">
                              <EcoText fontSize="base" fontWeight="medium">
                                Default Styles
                              </EcoText>
                              <EcoInput
                                label="Font Size"
                                {...getProps(formik, fieldPaths.subHeaderFontSizeXS)}
                                placeholder="24px, 3.5rem..."
                              />
                              <EcoInput
                                label="Line Height"
                                {...getProps(formik, fieldPaths.subHeaderLineHeightXS)}
                                placeholder="30px, 1.25..."
                              />
                            </View>
                            <View className="flex-1">
                              <EcoText fontSize="base" fontWeight="medium">
                                "sm" (490px and above)
                              </EcoText>
                              <EcoInput
                                label="Font Size"
                                {...getProps(formik, fieldPaths.subHeaderFontSizeSM)}
                                placeholder="24px, 3.5rem..."
                              />
                              <EcoInput
                                label="Line Height"
                                {...getProps(formik, fieldPaths.subHeaderLineHeightSM)}
                                placeholder="30px, 1.25..."
                              />
                            </View>
                            <View className="flex-1">
                              <EcoText fontSize="base" fontWeight="medium">
                                "md" (768px and above)
                              </EcoText>
                              <EcoInput
                                label="Font Size"
                                {...getProps(formik, fieldPaths.subHeaderFontSizeMD)}
                                placeholder="24px, 3.5rem..."
                              />
                              <EcoInput
                                label="Line Height"
                                {...getProps(formik, fieldPaths.subHeaderLineHeightMD)}
                                placeholder="30px, 1.25..."
                              />
                            </View>
                            <View className="flex-1">
                              <EcoText fontSize="base" fontWeight="medium">
                                "lg" (1024px and above)
                              </EcoText>
                              <EcoInput
                                label="Font Size"
                                {...getProps(formik, fieldPaths.subHeaderFontSizeLG)}
                                placeholder="24px, 3.5rem..."
                              />
                              <EcoInput
                                label="Line Height"
                                {...getProps(formik, fieldPaths.subHeaderLineHeightLG)}
                                placeholder="30px, 1.25..."
                              />
                            </View>
                          </View>
                        </View>
                      </View>
                    )}
                  </View>

                  <View style={Gap()}>
                    <View className="flex-row items-center" style={Gap()}>
                      <EcoText fontSize="lg" fontWeight="medium">
                        Primary Button
                      </EcoText>
                      <EcoToggle
                        value={!ctaDisabled}
                        onValueChange={(val: boolean) => {
                          setFieldValue(fieldPaths.ctaDisabled, !val);
                        }}
                      />
                    </View>
                    {!ctaDisabled && (
                      <View>
                        <View className="flex flex-col md:flex-row" style={Gap()}>
                          <EcoInput label="CTA Text" {...getProps(formik, fieldPaths.ctaTextEN)} placeholder="CTA button text" />
                          <EcoInput style={{ flex: 2 }} {...getProps(formik, fieldPaths.ctaURL)} label="CTA URL" placeholder="https://" />
                        </View>
                        <View className="flex flex-col md:flex-row" style={Gap()}>
                          <EcoInput
                            label="Font Family Name"
                            {...getCTAProps(isSecondaryCTASameAsPrimaryCTA, fieldPaths.ctaFontFamily, fieldPaths.cta2FontFamily)}
                            placeholder="Roboto"
                          />
                          <EcoInput
                            label="Font Size"
                            {...getCTAProps(isSecondaryCTASameAsPrimaryCTA, fieldPaths.ctaFontSize, fieldPaths.cta2FontSize)}
                            placeholder="24px, 3.5rem..."
                          />
                          <EcoInput
                            label="Font Weight"
                            {...getCTAProps(isSecondaryCTASameAsPrimaryCTA, fieldPaths.ctaFontWeight, fieldPaths.cta2FontWeight)}
                            placeholder="300, 500, bold..."
                          />
                          <EcoInput
                            className="flex-1 mb-3 md:mb-0"
                            label="Font Color"
                            {...getCTAProps(isSecondaryCTASameAsPrimaryCTA, fieldPaths.ctaFontColor, fieldPaths.cta2FontColor)}
                            placeholder="#123456"
                          />
                        </View>
                        <View className="flex flex-col md:flex-row" style={Gap()}>
                          <EcoInput
                            label="Background Color"
                            {...getCTAProps(isSecondaryCTASameAsPrimaryCTA, fieldPaths.ctaBackgroundColor, fieldPaths.cta2BackgroundColor)}
                            placeholder="#123456"
                          />
                          <EcoInput
                            label="Border Color"
                            {...getCTAProps(isSecondaryCTASameAsPrimaryCTA, fieldPaths.ctaBorderColor, fieldPaths.cta2BorderColor)}
                            placeholder="#123456"
                          />
                          <EcoInput
                            className="flex-1 mb-3 md:mb-0"
                            label="Border Radius"
                            {...getCTAProps(isSecondaryCTASameAsPrimaryCTA, fieldPaths.ctaBorderRadius, fieldPaths.cta2BorderRadius)}
                            placeholder="4px"
                          />
                        </View>
                      </View>
                    )}
                  </View>

                  <View style={Gap()}>
                    <View className="flex-row items-center" style={Gap()}>
                      <EcoText fontSize="lg" fontWeight="medium">
                        Secondary Button
                      </EcoText>
                      <EcoToggle value={!cta2Disabled} onValueChange={(val: boolean) => setFieldValue(fieldPaths.cta2Disabled, !val)} />
                    </View>
                    {!cta2Disabled && (
                      <View>
                        <View className="flex flex-col md:flex-row" style={Gap()}>
                          <EcoInput label="CTA Text" {...getProps(formik, fieldPaths.cta2TextEN)} placeholder="CTA button text (English)" />
                          <EcoInput style={{ flex: 2 }} label="CTA URL" {...getProps(formik, fieldPaths.cta2URL)} placeholder="https://" />
                        </View>
                        <View className="my-4">
                          <EcoCheckbox value={isSecondaryCTASameAsPrimaryCTA} onValueChange={handleSyncCTAStyles}>
                            <EcoText>Match primary style</EcoText>
                          </EcoCheckbox>
                        </View>
                        {!isSecondaryCTASameAsPrimaryCTA && (
                          <>
                            <View className="flex flex-col md:flex-row">
                              <EcoInput label="Font Family Name" {...getProps(formik, fieldPaths.cta2FontFamily)} placeholder="Roboto" />
                              <EcoInput label="Font Size" {...getProps(formik, fieldPaths.cta2FontSize)} placeholder="24px, 3.5rem..." />
                              <EcoInput
                                label="Font Weight"
                                {...getProps(formik, fieldPaths.cta2FontWeight)}
                                placeholder="300, 500, bold..."
                              />
                              <EcoInput
                                className="flex-1 mb-3 md:mb-0"
                                label="Font Color"
                                {...getProps(formik, fieldPaths.cta2FontColor)}
                                placeholder="#123456"
                              />
                            </View>
                            <View className="flex flex-col md:flex-row">
                              <EcoInput
                                label="Background Color"
                                {...getProps(formik, fieldPaths.cta2BackgroundColor)}
                                placeholder="#123456"
                              />
                              <EcoInput label="Border Color" {...getProps(formik, fieldPaths.cta2BorderColor)} placeholder="#123456" />
                              <EcoInput
                                className="flex-1 mb-3 md:mb-0"
                                label="Border Radius"
                                {...getProps(formik, fieldPaths.cta2BorderRadius)}
                                placeholder="4px"
                              />
                            </View>
                          </>
                        )}
                      </View>
                    )}
                    <EcoButton isDisabled={isSubmitting || !isValid} onPress={handleSubmit}>
                      Save
                    </EcoButton>
                  </View>
                </View>
              </form>
            </>
            {/* End of Configuration component */}
          </ConfigContainer>
        </EcoCard>
      </View>
    </>
  );
}
