import { EcoIcon, EcoImage, EcoText } from '@components/shared';
import { IMAGE_ASSETS } from '@ecocart/universal-utils';
import { Gap } from '@utils/layout';
import { View } from 'react-native';

export const ClaimsFormConfirmation = (): JSX.Element => {
  return (
    <>
      <View style={Gap(8)}>
        <View className="rounded-full bg-secondary-50 p-3 w-max self-center">
          <EcoIcon name="check" size="6xl" className="text-secondary-500" />
        </View>
        <View style={Gap()}>
          <EcoText fontSize="3xl" fontWeight="semibold" textAlign="center">
            Your claim has been submitted
          </EcoText>
          <EcoText textAlign="center" color="subdued">
            Please check your inbox for a confirmation email
          </EcoText>
        </View>
      </View>

      <View className="flex-row justify-center items-center mt-8">
        <EcoText fontSize="xs">Powered By</EcoText>
        <EcoImage src={IMAGE_ASSETS.ECOCART} height={14} width={100} resizeMode="contain" />
      </View>
    </>
  );
};
