import { CreditCard } from './api/payments';
import { PUBLIC_IMG_URL } from './constants/config';

export const CC_WIDTH = 40;
export const CC_HEIGHT_ASPECT_RATIO = 0.685;

export const getMonthYear = (creditCard: CreditCard | undefined): string => {
  return creditCard?.exp_month && creditCard?.exp_year ? `${creditCard?.exp_month}/${creditCard?.exp_year}` : '';
};

export const getCreditCardBrand = (card_number: string | number = ''): string => {
  card_number = String(card_number);

  if (card_number.startsWith('4')) return 'Visa';
  if (card_number.startsWith('5')) return 'Mastercard';
  if (card_number.startsWith('3')) return 'Amex';
  if (card_number.startsWith('6')) return 'Discover';

  return '';
};

export const getCreditCardBrandLogo = (brand: string = ''): string => {
  switch (brand.toLowerCase().replaceAll(' ', '')) {
    case 'discover':
      return PUBLIC_IMG_URL + '/credit-cards/Discover.svg';
    case 'mastercard':
      return PUBLIC_IMG_URL + '/credit-cards/Mastercard.svg';
    case 'amex':
    case 'americanexpress':
      return PUBLIC_IMG_URL + '/credit-cards/Amex.svg';
    case 'visa':
      return PUBLIC_IMG_URL + '/credit-cards/Visa.svg';
    default:
      return '';
  }
};

export const isCreditCardExpired = (creditCard: CreditCard): boolean => {
  const today = new Date();
  const currMonth: number = today.getMonth();
  const currYear: number = today.getFullYear();
  return currYear > Number(creditCard.exp_year) || (currYear === Number(creditCard.exp_year) && currMonth >= Number(creditCard.exp_month));
};
