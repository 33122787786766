import { EcoButton, EcoCard, EcoCheckbox, EcoText } from '@components/shared';
import { GlobalFormProps } from '@utils/prop-types';
import React, { useState } from 'react';
import { View } from 'react-native';

interface Props extends GlobalFormProps {}

export default function ConfirmAddBankAccountForm({ onSuccess, onCancel }: Props): JSX.Element {
  const [addBankAccountConfirmed, setAddBankAccountConfirmed] = useState<boolean>(false);

  return (
    <EcoCard className="p-6 md:w-[525px]">
      <View className="flex flex-col items-center justify-center pb-4">
        <EcoText fontSize="2xl" fontWeight="semibold">
          Debit Confirmation
        </EcoText>
        <EcoText className="mt-2" textAlign="center">
          EcoCart is free to use and will only debit your account for the exact amount of contributions that you have collected.
        </EcoText>
        <View className="text-center mt-6">
          <EcoCheckbox value={addBankAccountConfirmed} onValueChange={(value: boolean) => setAddBankAccountConfirmed(value)}>
            <EcoText>I authorize EcoCart to debit this bank account pursuant to EcoCart’s website and terms.</EcoText>
          </EcoCheckbox>
        </View>
      </View>
      <EcoButton
        className="w-max mt-2 mx-auto"
        isDisabled={!addBankAccountConfirmed}
        onPress={() => {
          if (addBankAccountConfirmed) {
            onSuccess && onSuccess();
          } else {
            onCancel && onCancel();
          }
        }}
      >
        Confirm
      </EcoButton>
    </EcoCard>
  );
}
