import EcoCartAdminDashboard from '@components/dashboards/Dashboard.EcoCartAdmin';
import MerchantAdminDashboard from '@components/dashboards/Dashboard.MerchantAdmin';
import EcoScrollView from '@components/views/EcoScrollView';
import { useGlobal } from '@hooks/useGlobal';
import { GlobalScreenProps } from '@navigation/types';
import { useNavigation } from '@react-navigation/native';
import React from 'react';

export default function HomeScreen({}: GlobalScreenProps): JSX.Element {
  const { session } = useGlobal();
  const navigation = useNavigation();
  const { merchantAdmin } = session || {};

  if (!session) return <></>;
  const isEcoCartAdmin = session?.user.userType === 'ecocart_admin';

  if (isEcoCartAdmin)
    return (
      <EcoScrollView>
        <EcoCartAdminDashboard user={session.user} />
      </EcoScrollView>
    );

  if (merchantAdmin?.pkgProtectionEnabled && !merchantAdmin?.enableShipping && !merchantAdmin?.enableManufacturing) {
    navigation.navigate('Claims');
    return <></>;
  }

  return <MerchantAdminDashboard />;
}
