import { EcoCard, EcoIcon, EcoTable, EcoText } from '@components/shared';
import EcoEmptyState from '@components/shared/EcoEmptyState';
import { EcoPageTitle } from '@components/shared/EcoPageTitle';
import EcoScrollView from '@components/views/EcoScrollView';
import { useGlobal } from '@hooks/useGlobal';
import { useRewards } from '@hooks/useRewards';
import { useRewardsHistoryTableConfig } from '@hooks/useRewardsHistoryTableConfig';
import { BackLink } from '@navigation/BackLink';
import { Gap } from '@utils/layout';
import React from 'react';
import { View } from 'react-native';
import { GlobalScreenProps } from '../navigation/types';

export default function RewardsHistoryScreen({}: GlobalScreenProps): JSX.Element {
  const { session } = useGlobal();
  const shopName = session?.merchantAdmin?.shopName || '';
  const { data, isFetching } = useRewards(shopName);
  const columnConfig = useRewardsHistoryTableConfig();

  return (
    <EcoScrollView>
      <View className="flex-row">
        <BackLink href="/rewards" label="Back To Rewards" />
      </View>

      <EcoPageTitle title="History" />
      {isFetching || (data && data.redemptions.length > 0) ? (
        <EcoCard>
          <EcoText fontSize="lg" className="mb-6">
            View all your rewards related history here
          </EcoText>
          <EcoTable
            defaultDataQuery={{ sorts: [{ field: 'created_at', dir: 'desc' }] }}
            data={data?.redemptions || []}
            isLoading={!data}
            columns={columnConfig}
          />
        </EcoCard>
      ) : (
        <EcoCard>
          <View className="items-center justify-center py-9" style={Gap(4)}>
            <EcoIcon name="warning" className="text-warning-500" size="6xl" />
            <EcoEmptyState title="No Rewards History Found" />
          </View>
        </EcoCard>
      )}
    </EcoScrollView>
  );
}
