import { RECHARGE_API, apiCall } from './api';

const REBUY_API = 'https://rebuyengine.com/api';
const CLEAR_HEADERS = { 'X-Eco-JWT': '', 'X-Eco-Shopify-Access-Token': '' };

export const getRechargeAuthorizationUrl = (shopName: string): Promise<string> => {
  return new Promise((resolve) => {
    apiCall<{ authorization_url: 'string'; message: 'string' }>(
      'POST',
      `${RECHARGE_API}/default/recharge/ecoinstall`,
      { shopify_domain: shopName },
      CLEAR_HEADERS
    )
      .then((res) => resolve(res?.authorization_url || ''))
      .catch(() => resolve(''));
  });
};

export const isRechargeInstalled = (shopName: string): Promise<boolean> => {
  return new Promise((resolve) => {
    apiCall<{ is_installed: true }>(
      'POST',
      `${RECHARGE_API}/default/recharge/ecoinstall?is_installed=true`,
      { shopify_domain: shopName },
      CLEAR_HEADERS
    )
      .then((res) => resolve(res?.is_installed || false))
      .catch(() => resolve(false));
  });
};

export const isRebuyInstalled = (shopName: string): Promise<boolean> => {
  const queryParams = new URLSearchParams({
    myshopify_domain: shopName,
    format: 'pretty'
  });

  return new Promise((resolve) => {
    // TODO: make real!
    resolve(false);
    // apiCall('GET', `${REBUY_API}/installed?${queryParams}`, null, CLEAR_HEADERS)
    //   .then(() => resolve(true))
    //   .catch(() => resolve(false));
  });
};
