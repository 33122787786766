import { MetricCard } from '@components/cards/MetricCard';
import { formatNumber } from '@ecocart/universal-utils';
import { useRewards } from '@hooks/useRewards';
import { Gap } from '@utils/layout';
import { format } from 'date-fns';
import { View } from 'react-native';

export const RewardsOverview = ({ shopName }: { shopName: string }): JSX.Element => {
  const { data: rewards, currentMonthValue, redeemableAmount } = useRewards(shopName);

  if (!shopName) return <></>;

  return (
    <View className="flex-row flex-wrap" style={Gap(4)}>
      <MetricCard
        title="Available balance"
        value={rewards != undefined ? formatNumber(redeemableAmount, { style: 'currency', currency: 'USD' }) : undefined}
        trend={0}
        infoText="Available balance doesn't reflect rewards earned this month"
      />
      <MetricCard
        title={'Amount earned this month (' + format(new Date(), 'MMM yyyy') + ')'}
        value={rewards != undefined ? formatNumber(currentMonthValue, { style: 'currency', currency: 'USD' }) : undefined}
        trend={0}
        infoText="Rewards earned this month will be available for redemption in the next month"
      />
      <MetricCard
        title="Total amount earned all time"
        value={rewards != undefined ? formatNumber(rewards.total_rewards_usd, { style: 'currency', currency: 'USD' }) : undefined}
        trend={0}
      />
    </View>
  );
};
