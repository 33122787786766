import { EcoButton, FormButtons, FormError, FormPassword } from '@components/shared';
import { useOverlay } from '@hooks/useOverlay';
import { AddlLoginData, updateTempPassword } from '@utils/api/auth';
import { Gap } from '@utils/layout';
import { logUserEvt } from '@utils/log';
import { GlobalFormProps } from '@utils/prop-types';
import { CreateValidationSchema } from '@utils/validation';
import { Formik } from 'formik';
import { noop } from 'lodash';
import { useState } from 'react';
import { View } from 'react-native';

interface Props extends GlobalFormProps {
  email?: string;
  addlData: AddlLoginData;
}

export default function UpdateTempPasswordForm({ email, addlData, onSuccess = noop }: Props): JSX.Element {
  const { handleError } = useOverlay();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmit = async ({ createPassword }: { createPassword: string }): Promise<void> => {
    setIsSubmitting(true);

    try {
      const newPassword = await updateTempPassword(createPassword, addlData);
      // showSuccess('Successfully updated password');
      onSuccess && onSuccess(newPassword);
      logUserEvt('signup_complete');
    } catch (error: any) {
      alert(error);
      handleError(error);
      setIsSubmitting(false);
    }
  };

  return (
    <Formik
      validateOnMount
      validateOnBlur
      initialValues={{
        createPassword: '',
        confirmPassword: ''
      }}
      validationSchema={CreateValidationSchema(['createPassword', 'confirmPassword'])}
      onSubmit={onSubmit}
    >
      {({ handleChange, handleSubmit, handleBlur, values, isValid, errors, touched }) => (
        <View style={Gap(1)}>
          <FormPassword
            label="New Password"
            value={values.createPassword}
            autoFocus={true}
            onBlur={handleBlur('createPassword')}
            onChangeText={handleChange('createPassword')}
            placeholder="Password"
          />
          <FormError errors={errors} touched={touched} field="createPassword" />
          <FormPassword
            label="Confirm Password"
            value={values.confirmPassword}
            onBlur={handleBlur('confirmPassword')}
            onChangeText={handleChange('confirmPassword')}
            placeholder="Confirm Password"
          />
          <FormError errors={errors} touched={touched} field="confirmPassword" />
          <FormButtons>
            <EcoButton
              nativeID="signup-form__create-password"
              fullWidth
              isDisabled={!isValid || isSubmitting}
              isLoading={isSubmitting}
              onPress={handleSubmit}
            >
              Create Password
            </EcoButton>
          </FormButtons>
        </View>
      )}
    </Formik>
  );
}
