import { EcoTabContent, EcoTabs, EcoText, EcoToggle, EcoWidget } from '@components/shared';
import EcoScrollView from '@components/views/EcoScrollView';
import { EmptyState } from '@components/widgets/merchant_admin/EmptyState';
import { useGlobal } from '@hooks/useGlobal';
import { useTouchPointsTabs } from '@hooks/useTouchPointsTabs';
import { useWidgets } from '@hooks/useWidgets';
import { GlobalScreenProps } from '@navigation/types';
import { Gap } from '@utils/layout';
import { isEmpty } from 'lodash';
import { View } from 'react-native';
import { v4 as uuidv4 } from 'uuid';

export default function TouchPointsCartPageScreen({}: GlobalScreenProps): JSX.Element {
  const tabs = useTouchPointsTabs();
  const { session } = useGlobal();
  const shopName = session?.merchantAdmin?.shopName || '';
  const { merchant, cartPageWidgets } = useWidgets(shopName);

  return (
    <EcoScrollView>
      <EcoTabs
        showLabelInBreadCrumb={false}
        description="Promote your sustainability initiatives on your store pages using Ecocart Shopper Touchpoints"
        tabs={tabs}
        prefix="Shopper Touchpoints"
        activeScreen="TouchPointsCartPage"
      >
        <EcoTabContent>
          <View style={Gap(5)}>
            <View className="py-3">
              <EcoText fontSize="3xl" fontWeight="semibold">
                Cart Page
              </EcoText>
            </View>
            {merchant && !isEmpty(cartPageWidgets) ? (
              (cartPageWidgets || []).map((widget) => {
                return (
                  <View key={uuidv4()} className="bg-gray-50 p-6 rounded-sm" style={Gap(6)}>
                    <View style={Gap()}>
                      <EcoText fontSize="2xl" fontWeight="semibold">
                        {widget.name}
                      </EcoText>
                      <View className="items-center flex-row justify-between w-max" style={Gap(2)}>
                        <EcoToggle value={!!widget.visible} onValueChange={() => {}} />
                        <EcoText fontSize="base" className="label-base">
                          Show this widget
                        </EcoText>
                      </View>
                    </View>
                    <EcoWidget widget={widget} merchant={merchant} />
                  </View>
                );
              })
            ) : (
              <EmptyState text="There are no Shopper Touchpoints on your store's Cart Page" />
            )}
          </View>
        </EcoTabContent>
      </EcoTabs>
    </EcoScrollView>
  );
}
