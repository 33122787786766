import { EcoTab } from '@components/shared';
import { UserType } from '@ecocart/entities';
import { ApiMerchantSettingsTabs, EcoCartGlobalMerchantTabs, MerchantSettingsTabs } from '@utils/constants/tabs';
import { useGlobal } from './useGlobal';

const tabMap: Record<UserType, EcoTab[]> = {
  api_admin: ApiMerchantSettingsTabs,
  merchant_admin: MerchantSettingsTabs,
  ecocart_admin: EcoCartGlobalMerchantTabs
};

export const useSettingsTabs = (): EcoTab[] => {
  const { session } = useGlobal();
  return session?.user.userType ? tabMap[session.user.userType] : [];
};
