import { EcoText } from '@components/shared';
import { View } from 'react-native';

export const EmptyState = ({ text }: { text: string }): JSX.Element => {
  return (
    <View className="justify-center items-center h-[600px]">
      <EcoText fontSize="lg" color="subdued">
        {text}
      </EcoText>
    </View>
  );
};
