import { Picker as NativePicker, PickerProps } from '@react-native-picker/picker';
import { isNative } from '@utils/constants/device';
import { GlobalStylesProps } from '@utils/prop-types';
import clsx from 'clsx';
import React, { useState } from 'react';

export interface EcoPickerItem {
  label: string;
  value: string | number | boolean | undefined;
}
export interface EcoPickerProps extends GlobalStylesProps {
  items: readonly string[] | string[] | number[] | EcoPickerItem[];
  value: string | number | boolean | undefined;
  onValueChange: (value: any) => void;
  disabled?: boolean;
  id?: string;
}

export function EcoPicker({
  // id = Math.random().toString(),
  items = [
    { label: 'label 1', value: 1 },
    { label: 'label 2', value: 2 }
  ],
  value,
  onValueChange,
  disabled = false,
  style
}: EcoPickerProps): JSX.Element {
  const [isFocused, setIsFocused] = useState(false);

  // The purpose of this wrapper is so that we can style it using tailwind classes
  // If we pass classname directly to NativePicker, nativewind cannot convert it into
  // style for some reason. That's why we create another component just to let native wind
  // do the style transpilation

  const getItemProps = (item: EcoPickerItem): EcoPickerItem => {
    return typeof item === 'object' ? item : { label: item, value: item };
  };

  function NativePickerWrapper({ style }: PickerProps) {
    return (
      <NativePicker
        mode="dropdown"
        itemStyle={{
          fontFamily: 'sora-regular',
          fontSize: 18,
          lineHeight: 24,
          borderBottomWidth: 1,
          marginVertical: -10,
          marginHorizontal: -25
        }}
        aria-disabled={disabled}
        style={style}
        selectedValue={value}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onValueChange={onValueChange}
      >
        {items.map((item: any, i: number) => (
          <NativePicker.Item key={'picker-item-' + i} {...getItemProps(item)} />
        ))}
      </NativePicker>
    );
  }

  return (
    <NativePickerWrapper
      accessibilityState={{ disabled }}
      className={clsx(
        !isNative && 'input-base picker-down-arrow-bg min-h-[44px] !pr-7',
        isFocused && 'border-primary outline-none',
        disabled ? 'bg-gray-200 opacity-50 border border-gray-400 pointer-events-none' : 'bg-white cursor-pointer'
      )}
      style={style}
    />
  );
}
