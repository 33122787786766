import { EcoButton, EcoInput } from '@components/shared';
import { Locale } from '@ecocart/entities';
import { TextKey, Translation, set, translations } from '@ecocart/universal-utils';
import { useFeaturePermission } from '@hooks/useFeaturePermission';
import { useOverlay } from '@hooks/useOverlay';
import { useMutation } from '@tanstack/react-query';
import { getTranslations } from '@utils/api/translations';
import { Gap } from '@utils/layout';
import { GlobalFormProps } from '@utils/prop-types';
import { useFormik } from 'formik';
import { ChatCompletion } from 'openai/resources';
import React from 'react';
import { View } from 'react-native';

interface Props extends GlobalFormProps {
  locale: Locale;
  translations: Translation;
  setTranslations: React.Dispatch<React.SetStateAction<Translation>>;
}

const ENGLISH_TRANSLATIONS = translations['en'] || '';

export default function TranslationsForm({ locale, translations, setTranslations }: Props): JSX.Element {
  const [indexInTranslation, setIndexInTranslation] = React.useState(0);
  const { showInfo, showSuccess, showWarning } = useOverlay();
  const { isFeaturePermissioned } = useFeaturePermission('qa');

  const mutation = useMutation<ChatCompletion | null, Error, { locale: Locale; textKey: string }>(
    ({ textKey, locale }) => {
      showInfo('Translation In Progress. Please wait...');
      return getTranslations({ text: ENGLISH_TRANSLATIONS[textKey as TextKey], targetLocale: locale });
    },
    {
      onSuccess: (data, { locale, textKey }) => {
        const translatedContent = data?.choices[0].message.content || '{}';
        const newTranslations = { ...translations };
        set(newTranslations, `${locale}.${textKey}`, translatedContent);
        setTranslations(newTranslations);
        showSuccess('Translation Complete');
      },
      onError: (e) => {
        showWarning(`Error translating text. Please try again in 1 minute: ${e}`, { dismissTimeMs: 5000 });
      }
    }
  );

  const onSubmit = () => {
    const newTranslations = {
      ...translations,
      [locale]: values
    };
    setTranslations(newTranslations);
  };

  const { values, setFieldValue } = useFormik({
    initialValues: translations[locale],
    onSubmit: onSubmit
  });

  return (
    <form onSubmit={onSubmit}>
      <View style={Gap()}>
        {Object.keys(values).map((textKey, idx) => {
          const unTranslated = (values as any)[textKey] === (ENGLISH_TRANSLATIONS as any)[textKey];
          return (
            <View key={textKey} className="flex-row" style={Gap(2)}>
              <EcoInput
                label={(ENGLISH_TRANSLATIONS as any)[textKey]}
                value={(values as any)[textKey]}
                onBlur={onSubmit}
                onChangeText={(value: string) => {
                  setFieldValue(textKey, value);
                }}
              />
              {locale !== 'en' && isFeaturePermissioned && (
                <EcoButton
                  isLoading={mutation.isLoading && indexInTranslation === idx}
                  variant={unTranslated ? 'outlined' : 'filled'}
                  colorScheme={unTranslated ? undefined : 'danger'}
                  isDisabled={mutation.isLoading}
                  size="md"
                  leftIcon="translate"
                  className="self-end"
                  onPress={() => {
                    mutation.mutate({ locale, textKey });
                    setIndexInTranslation(idx);
                  }}
                />
              )}
            </View>
          );
        })}
      </View>
    </form>
  );
}
