import { EcoAccordion, EcoButton, EcoCard, EcoInput, EcoText, EcoWidget, FormPicker } from '@components/shared';
import { ConfigContainer } from '@components/views/ConfigContainer';
import { LOCALES, Merchant } from '@ecocart/entities';
import { translations } from '@ecocart/universal-utils';
import { useOverlay } from '@hooks/useOverlay';
import { BackLink } from '@navigation/BackLink';
import { useNavigation } from '@react-navigation/native';
import { MerchantContext } from '@screens/MerchantWidgetsScreen';
import { updateMerchant } from '@utils/api/merchant';
import { Gap } from '@utils/layout';
import { CreateValidationSchema } from '@utils/validation';
import { useFormik } from 'formik';
import { get } from 'lodash';
import { useContext, useState } from 'react';
import { View } from 'react-native';
import { getInitialValues } from './PromoWidgetDesigner.utils';
import {
  alignmentItems,
  convertCountryCodeToEnglishName,
  fieldPaths,
  getProps,
  getSelectedAlignmentValue,
  getTranslationPath,
  getZIndexProps,
  handleAlignmentChange,
  handleTypeCaseChange,
  typeCaseItems
} from './utils';

export function EnhancedPdpDesigner({ widgetKey }: { widgetKey: string }): JSX.Element {
  const navigation = useNavigation();
  const { showSuccess, handleError } = useOverlay();
  const { merchant, refetch } = useContext(MerchantContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { widgetMap } = merchant || {};
  const isValidWidgetKey = widgetMap && widgetKey in widgetMap;

  if (!isValidWidgetKey) {
    navigateToWidgetList();
  }

  function navigateToWidgetList() {
    if (!merchant) return;
    return navigation.navigate('MerchantWidgetTemplates', { shopName: merchant?.shopName, template: 'enhanced-pdp' });
  }

  const onSubmit = async () => {
    if (!merchant) return;

    setIsSubmitting(true);
    const updatedMerchant: Partial<Merchant> = {
      widgetMap: {
        ...merchant.widgetMap,
        [widgetKey]: values
      }
    };

    try {
      await updateMerchant(merchant.shopName, updatedMerchant, merchant);
      showSuccess('Successfully updated merchant');
      setIsSubmitting(false);
      refetch && refetch();
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: widgetMap?.[widgetKey] || getInitialValues(),
    onSubmit,
    validateOnChange: true,
    validationSchema: CreateValidationSchema(['elements']),
    enableReinitialize: true
  });

  const { values, isValid, handleSubmit } = formik;

  return (
    <>
      <View className="pb-4">
        <BackLink onPress={navigateToWidgetList} label="Back To Widgets" />
      </View>

      <View style={Gap()}>
        <EcoCard>
          <EcoText fontSize="xl" fontWeight="semibold" className="mb-2">
            Preview
          </EcoText>
          <View className="w-full">{values && merchant && <EcoWidget widget={values} merchant={merchant} />}</View>
        </EcoCard>
        <EcoCard>
          <ConfigContainer>
            {/* Configuration component */}
            <form onSubmit={formik.handleSubmit}>
              <View style={Gap(8)}>
                <View style={Gap(8)}>
                  <View style={Gap()}>
                    <EcoText fontSize="xl" fontWeight="medium">
                      Widget Name
                    </EcoText>
                    <EcoInput label="Name" {...getProps(formik, fieldPaths.name)} />
                  </View>

                  <View style={Gap()}>
                    <EcoText fontSize="xl" fontWeight="medium">
                      Container
                    </EcoText>

                    <View style={Gap(2)}>
                      <EcoText fontSize="lg" fontWeight="medium">
                        Behavior
                      </EcoText>
                      <View>
                        <EcoText fontSize="base" fontWeight="medium">
                          Mobile
                        </EcoText>
                        <View className="flex-row flex-wrap" style={Gap()}>
                          <EcoInput
                            className="flex-1"
                            label="Margins"
                            {...getProps(formik, fieldPaths.containerMargin)}
                            placeholder="0px"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Padding"
                            {...getProps(formik, fieldPaths.containerPadding)}
                            placeholder="12px"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Width"
                            {...getProps(formik, fieldPaths.wrapperWidth)}
                            placeholder="100px, 50%, etc"
                          />
                          <FormPicker
                            inputClassName="flex-1"
                            label="Default Alignment"
                            items={alignmentItems}
                            value={getSelectedAlignmentValue(values, 'wrapper', 'mobile')}
                            onValueChange={(val) => handleAlignmentChange(formik, val, 'mobile', 'wrapper')}
                          />
                          <EcoInput
                            className="flex-1"
                            label="Z-Index"
                            {...getZIndexProps(formik, fieldPaths.wrapperZIndex)}
                            placeholder="10, 20, 100, etc"
                          />
                        </View>
                      </View>
                      <View>
                        <EcoText fontSize="base" fontWeight="medium">
                          Desktop
                        </EcoText>
                        <View className="flex-row flex-wrap" style={Gap()}>
                          <EcoInput
                            className="flex-1"
                            label="Margins"
                            {...getProps(formik, fieldPaths.containerMarginMD)}
                            placeholder="0px"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Padding"
                            {...getProps(formik, fieldPaths.containerPaddingMD)}
                            placeholder="12px"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Width"
                            {...getProps(formik, fieldPaths.wrapperWidthMD)}
                            placeholder="100px, 50%, etc"
                          />
                          <FormPicker
                            inputClassName="flex-1"
                            label="Default Alignment"
                            items={alignmentItems}
                            value={getSelectedAlignmentValue(values, 'wrapper', 'desktop')}
                            onValueChange={(val) => handleAlignmentChange(formik, val, 'desktop', 'wrapper')}
                          />
                          <EcoInput
                            className="flex-1"
                            label="Z-Index"
                            {...getZIndexProps(formik, fieldPaths.wrapperZIndexMD)}
                            placeholder="10, 20, 100, etc"
                          />
                        </View>
                      </View>
                    </View>

                    <View className="mt-2">
                      <EcoText fontSize="lg" fontWeight="medium">
                        Style
                      </EcoText>
                      <View className="flex-row flex-wrap" style={Gap()}>
                        <EcoInput
                          className="flex-1"
                          label="Border Width"
                          {...getProps(formik, fieldPaths.containerBorderWidth)}
                          placeholder="1px"
                        />
                        <EcoInput
                          className="flex-1"
                          label="Border Color"
                          {...getProps(formik, fieldPaths.containerBorderColor)}
                          placeholder="#0F8354"
                        />
                        <EcoInput
                          className="flex-1"
                          label="Border Radius"
                          {...getProps(formik, fieldPaths.containerBorderRadius)}
                          placeholder="4px"
                        />
                      </View>
                    </View>
                  </View>

                  <View style={Gap()}>
                    <EcoText fontSize="xl" fontWeight="medium">
                      Content
                    </EcoText>
                    <View>
                      <View>
                        <EcoText fontSize="lg" fontWeight="medium">
                          Header
                        </EcoText>
                        <View style={Gap()}>
                          <EcoInput
                            className="flex-1 mb-3 md:mr-3 md:mb-0"
                            label="Header - English"
                            {...getProps(formik, getTranslationPath('header', 'en'))}
                            placeholder={translations.en.purchasing_this_will_offset_amount_carbon}
                          />

                          <EcoAccordion header="Show Translations">
                            {LOCALES.map((locale) => (
                              <EcoInput
                                key={`header-${locale}`}
                                className="flex-1"
                                label={`Header - ${convertCountryCodeToEnglishName(locale)}`}
                                {...getProps(formik, getTranslationPath('header', locale))}
                                placeholder={translations[locale].template_offsetYourOrderAtCheckoutWith}
                              />
                            ))}
                          </EcoAccordion>
                        </View>
                      </View>

                      <View>
                        <EcoText fontSize="lg" fontWeight="medium">
                          Style
                        </EcoText>
                        <View className="flex-wrap" style={Gap()}>
                          <View className="flex-row" style={Gap()}>
                            <EcoInput
                              className="flex-1"
                              label="Font Family"
                              {...getProps(formik, fieldPaths.headerFontFamily)}
                              placeholder="system-ui"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Size"
                              {...getProps(formik, fieldPaths.headerFontSize)}
                              placeholder="14px"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Line Height"
                              {...getProps(formik, fieldPaths.headerLineHeight)}
                              placeholder="16px"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Weight"
                              {...getProps(formik, fieldPaths.headerFontWeight)}
                              placeholder="300, 500, bold..."
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Color"
                              {...getProps(formik, fieldPaths.headerColor)}
                              placeholder="#333333"
                            />
                          </View>
                          <FormPicker
                            label="Type Case"
                            inputClassName="md:w-1/5"
                            items={typeCaseItems}
                            value={get(values, fieldPaths.headerTextTransform, '') as string}
                            onValueChange={(val) => handleTypeCaseChange(formik, val, 'header')}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={Gap()} className="mt-2">
                      <EcoText fontSize="lg" fontWeight="medium">
                        Subheader
                      </EcoText>
                      <View>
                        <EcoText fontSize="lg" fontWeight="medium">
                          Style
                        </EcoText>
                        <View className="flex-wrap" style={Gap()}>
                          <View className="flex-row" style={Gap()}>
                            <EcoInput
                              className="flex-1"
                              label="Font Family"
                              {...getProps(formik, fieldPaths.subheaderFontFamily)}
                              placeholder="system-ui"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Size"
                              {...getProps(formik, fieldPaths.subheaderFontSize)}
                              placeholder="14px"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Line Height"
                              {...getProps(formik, fieldPaths.subheaderLineHeight)}
                              placeholder="16px"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Weight"
                              {...getProps(formik, fieldPaths.subheaderFontWeight)}
                              placeholder="300, 500, bold..."
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Color"
                              {...getProps(formik, fieldPaths.subheaderColor)}
                              placeholder="#333333"
                            />
                          </View>
                          <FormPicker
                            label="Type Case"
                            inputClassName="md:w-1/5"
                            items={typeCaseItems}
                            value={get(values, fieldPaths.subheaderTextTransform, '') as string}
                            onValueChange={(val) => handleTypeCaseChange(formik, val, 'header')}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={Gap()} className="mt-2">
                      <EcoText fontSize="lg" fontWeight="medium">
                        Icon Text
                      </EcoText>
                      <View>
                        <EcoText fontSize="lg" fontWeight="medium">
                          Style
                        </EcoText>
                        <View className="flex-wrap" style={Gap()}>
                          <View className="flex-row" style={Gap()}>
                            <EcoInput
                              className="flex-1"
                              label="Font Family"
                              {...getProps(formik, fieldPaths.footerFontFamily)}
                              placeholder="system-ui"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Size"
                              {...getProps(formik, fieldPaths.footerFontSize)}
                              placeholder="14px"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Line Height"
                              {...getProps(formik, fieldPaths.footerLineHeight)}
                              placeholder="16px"
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Weight"
                              {...getProps(formik, fieldPaths.footerFontWeight)}
                              placeholder="300, 500, bold..."
                            />
                            <EcoInput
                              className="flex-1"
                              label="Font Color"
                              {...getProps(formik, fieldPaths.footerColor)}
                              placeholder="#333333"
                            />
                          </View>
                          <FormPicker
                            label="Type Case"
                            inputClassName="md:w-1/5"
                            items={typeCaseItems}
                            value={get(values, fieldPaths.footerTextTransform, '') as string}
                            onValueChange={(val) => handleTypeCaseChange(formik, val, 'header')}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <EcoButton isDisabled={isSubmitting || !isValid} onPress={handleSubmit}>
                  Save
                </EcoButton>
              </View>
            </form>

            {/* End of Configuration component */}
          </ConfigContainer>
        </EcoCard>
      </View>
    </>
  );
}
