import { RewardRedemptionResponse } from '@ecocart/entities';
import { useQuery } from '@tanstack/react-query';
import { getMonthlyRewardAnalytics } from '@utils/api/rewards';
import { format, getYear } from 'date-fns';

export const useRewards = (
  shopName: string
): {
  data: RewardRedemptionResponse | undefined;
  currentMonthValue: number;
  monthlyValues: number[];
  redeemableAmount: number;
  isFetching: boolean;
  refetch: () => void;
} => {
  const { data, refetch, isFetching } = useQuery(['rewards', shopName], () => getMonthlyRewardAnalytics(shopName), { enabled: !!shopName });

  const currentYear = getYear(new Date());

  // Each key in the lookup object is a month in the format 'yyyy-MM',
  // and each value is the corresponding reward value.
  // Example output:
  // rewardsLookup = {
  //   '2022-01': 100,
  //   '2022-02': 200,
  //   '2022-03': 150,
  //   ...
  // }
  const rewardsLookup = data?.rewards.reduce((acc, reward) => {
    const key = Object.keys(reward)[0];
    acc[key] = reward[key];
    return acc;
  }, {});

  // For each month, it creates a key in the format 'yyyy-MM' and looks up the corresponding value in `rewardsLookup`.
  // If the key is not found in `rewardsLookup`, it defaults to 0.
  // The result is an array of 12 numbers representing the reward values for each month of the current year.
  //
  // Example output:
  // monthlyValues = [100, 200, 150, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  const monthlyValues = Array.from({ length: 12 }, (_, month) => {
    const monthKey = format(new Date(currentYear, month), 'yyyy-MM');
    return rewardsLookup?.[monthKey] || 0;
  });

  const currentMonth = new Date().getMonth();
  const currentMonthValue = monthlyValues[currentMonth];
  const redeemableAmount = data ? data.total_rewards_usd - data.total_redemptions_usd - currentMonthValue : 0;

  return { data, monthlyValues, currentMonthValue, redeemableAmount, refetch, isFetching };
};
