import { EcoIcon, EcoSkeleton, EcoText, EcoTooltip } from '@components/shared';
import { formatNumber } from '@ecocart/universal-utils';
import { useWindow } from '@hooks/useWindow';
import { Gap } from '@utils/layout';
import clsx from 'clsx';
import { View } from 'react-native';

interface MetricCardProps {
  title: string;
  badge?: string;
  trend?: number;
  value?: string;
  infoText?: string;
}

function Trend({ trend }: { trend?: number }) {
  if (trend === undefined) return <EcoSkeleton height="xs" />;
  const _trend = Number(trend);
  const showTrend = _trend !== 0;

  return showTrend ? (
    <View className="flex-row items-center" style={Gap(1)}>
      {_trend !== 1 && (
        <EcoIcon
          name={trend > 1 ? 'north_east' : 'south_east'}
          className={clsx(_trend > 1 ? 'text-primary-500' : 'text-danger-700', 'text-sm')}
        />
      )}
      <EcoText fontWeight="semibold" fontSize="sm" color={_trend > 1 ? 'accent-primary' : 'danger'}>
        {_trend === 0 ? '0.00%' : formatNumber(_trend - 1, { minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'percent' })}
      </EcoText>
    </View>
  ) : (
    <></>
  );
}

export const MetricCard = (props: MetricCardProps): JSX.Element => {
  const { width, breakpointWidths } = useWindow();
  const mdScreen = width >= breakpointWidths.md;
  const lgScreen = width >= breakpointWidths.lg;
  const { title, badge, trend, value, infoText } = props;

  const getFontSize = () => {
    if (lgScreen) return '5xl';
    if (mdScreen) return '4xl';
    return '2xl';
  };

  return (
    <View key={title} className="relative flex-1 min-w-[180px] bg-white rounded-md p-4" style={Gap(2)}>
      {badge && (
        <View className="flex justify-center absolute bg-secondary-500 rounded-full w-[40px] h-[40px]" style={{ top: -20, right: -20 }}>
          <EcoText className="text-center uppercase" fontWeight="semibold" color="enabled-inverted" style={{ fontSize: 8, lineHeight: 12 }}>
            Top
          </EcoText>
          <EcoText className="text-center uppercase" fontWeight="semibold" color="enabled-inverted" fontSize="sm">
            {badge}
          </EcoText>
        </View>
      )}
      <View className="flex-row items-center" style={Gap(1)}>
        <EcoText fontSize="sm" fontWeight="regular" color="subdued">
          {title}
        </EcoText>
        {infoText && (
          <EcoTooltip
            tooltip={
              <View style={Gap(2)} className="w-[300px]">
                <EcoText fontSize="sm" fontWeight="medium" color="enabled-inverted">
                  {infoText}
                </EcoText>
              </View>
            }
            placement="top"
          >
            <EcoIcon name="info" className="text-gray-700" size="base"></EcoIcon>
          </EcoTooltip>
        )}
      </View>
      <EcoText fontSize={getFontSize()} fontWeight="medium" fallback={<EcoSkeleton height="3xl" />}>
        {value}
      </EcoText>
      <Trend trend={trend} />
    </View>
  );
};
