import { EcoButton, EcoText } from '@components/shared';
import { formatNumber } from '@ecocart/universal-utils';
import { PaymentMethods } from '@utils/api/payments';
import { Gap } from '@utils/layout';
import dayjs from 'dayjs';
import { View } from 'react-native';

export const InvoiceConfirmForm = ({
  values,
  onSubmit,
  setShowInvoiceConfirmForm,
  primaryPaymentMethod
}: {
  values: Record<string, any>;
  onSubmit: () => void;
  paymentMethods?: PaymentMethods | null;
  primaryPaymentMethod?: string;
  setShowInvoiceConfirmForm: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
  const { start_date, end_date, amount_usd, auto_pay, memo } = values;
  return (
    <View>
      <View style={Gap(6)}>
        <View className="flex-row items-center" style={Gap(8)}>
          <View style={Gap(2)} className="flex-1">
            <EcoText fontWeight="medium">Billing Period</EcoText>
            <EcoText color="subdued">
              {start_date && end_date ? `${dayjs(start_date).format('MMM DD, YYYY')} - ${dayjs(end_date).format('MMM DD, YYYY')}` : '-'}
            </EcoText>
          </View>
          <View style={Gap(2)} className="flex-1">
            <EcoText fontWeight="medium">Balance</EcoText>
            <EcoText color="subdued">{formatNumber(+amount_usd, { style: 'currency', currency: 'usd' })}</EcoText>
          </View>
        </View>
        <View style={Gap(2)} className="flex-1">
          <EcoText fontWeight="medium">Payment Method</EcoText>
          <EcoText color="subdued">{primaryPaymentMethod || 'Not Available'}</EcoText>
        </View>
        <View style={Gap(2)}>
          <EcoText fontWeight="medium">Auto Charge Invoice</EcoText>
          <EcoText color="subdued">{auto_pay ? '🟢 On' : '🔴 Off'}</EcoText>
        </View>
        <View style={Gap(2)}>
          <EcoText fontWeight="medium">Memo</EcoText>
          <EcoText color="subdued">{memo || '-'}</EcoText>
        </View>
        <View className="flex-row justify-end" style={Gap()}>
          <EcoButton onPress={() => setShowInvoiceConfirmForm(false)} variant="outlined" size="sm">
            Edit Invoice
          </EcoButton>
          <EcoButton size="sm" onPress={onSubmit}>
            Confirm
          </EcoButton>
        </View>
      </View>
    </View>
  );
};
