import EditEcoCartSettingsForm from '@components/forms/EditEcoCartSettingsForm';
import { EnableStoreWidgetsToggleForm } from '@components/forms/EnableStoreWidgetsToggleForm';
import { SetEcocartActiveToggleForm } from '@components/forms/SetEcocartActiveToggleForm';
import { EcoAlert, EcoTabContent, EcoTabs, EcoText } from '@components/shared';
import { EcoHeadings } from '@components/shared/EcoHeadings';
import { EcoTooltip } from '@components/shared/EcoTooltip';
import EcoScrollView from '@components/views/EcoScrollView';
import { MerchantAdmin, PayorMode } from '@ecocart/entities';
import { useContactSupport } from '@hooks/useContactSupport';
import { useGlobal } from '@hooks/useGlobal';
import { useMerchantStatusConfigs } from '@hooks/useMerchantStatusConfigs';
import { useSettingsTabs } from '@hooks/useSettingsTabs';
import { GlobalScreenProps } from '@navigation/types';
import { Gap } from '@utils/layout';
import { BoxShadow } from '@utils/styles/box-shadow';
import { View } from 'react-native';

export default function SettingsGeneralScreen({}: GlobalScreenProps): JSX.Element {
  const { session, statuses, updateSessionMerchantAdmin } = useGlobal();
  const { user, merchantAdmin } = session || {};
  const { showContactSupportModal } = useContactSupport();
  const { getFormTooltipConfig } = useMerchantStatusConfigs({ merchantAdmin, statuses });
  const tabs = useSettingsTabs();

  const payorModesWithDisableNotifications: PayorMode[] = ['merchant_paying', 'customer_matching'];

  return (
    <EcoScrollView>
      <EcoTabs tabs={tabs} prefix="Settings" activeScreen="SettingsGeneral">
        <EcoTabContent
          title="General Settings"
          subTitle={`Manage how you want ${merchantAdmin?.pkgProtectionEnabled ? 'ShipInsure' : 'EcoCart'} to function`}
        >
          <View className="w-full h-[1px] bg-black/[.1]" />
          <View className="flex flex-col max-w-4xl" style={Gap(5)}>
            {merchantAdmin && user?.userType !== 'api_admin' ? (
              <View className="max-w-2xl" style={Gap()}>
                <View>
                  <View className="flex flex-row justify-between">
                    <View>
                      <EcoText fontSize="lg" fontWeight="semibold">
                        {merchantAdmin.pkgProtectionEnabled ? 'ShipInsure' : 'EcoCart'}
                      </EcoText>
                      <EcoText color="subdued" fontSize="sm">
                        Toggle calculations, offsets, and widgets on or off
                      </EcoText>
                    </View>
                    <EcoTooltip {...getFormTooltipConfig('ecocart_active')} className="self-center">
                      <SetEcocartActiveToggleForm
                        isDisabled={!merchantAdmin.ecocartActive && (!statuses?.areVariantsCreated || !statuses?.isBillingEnabled)}
                        merchantAdmin={merchantAdmin}
                        onSuccess={updateSessionMerchantAdmin}
                      />
                    </EcoTooltip>
                  </View>
                </View>

                <View className="mb-6">
                  <View className="flex flex-row justify-between" style={Gap()}>
                    <View className="flex-1">
                      <EcoText fontSize="lg" fontWeight="semibold">
                        Widget Visibility
                      </EcoText>
                      <EcoText className="block">
                        <EcoText color="subdued" fontSize="sm">
                          Hide/Show all widgets across your store.
                        </EcoText>
                        {payorModesWithDisableNotifications.includes(merchantAdmin.payorMode) ? (
                          <View className="inline">
                            <EcoText className="inline" fontWeight="semibold" color="subdued" fontSize="sm">
                              {'  Merchant Paying'}
                            </EcoText>
                            <EcoText className="inline" color="subdued" fontSize="sm">
                              {' orders will continue to be be calculated and offset unless EcoCart is disabled.'}
                            </EcoText>
                          </View>
                        ) : null}
                      </EcoText>
                    </View>
                    <EcoTooltip {...getFormTooltipConfig('widgets_visible')} className="self-center">
                      <EnableStoreWidgetsToggleForm
                        isDisabled={!merchantAdmin.ecocartActive}
                        merchantAdmin={merchantAdmin}
                        onSuccess={updateSessionMerchantAdmin}
                      />
                    </EcoTooltip>
                  </View>
                </View>
              </View>
            ) : null}

            <EcoHeadings title="Payor Modes" titleSize="4xl" subTitleClassName="text-sm text-subdued" />
            <EcoAlert
              className="max-w-2xl"
              closeable={false}
              style={[BoxShadow('none'), { zIndex: -1 }]}
              subtitle="Please contact Customer Support to change settings."
              primaryButton={{ nativeID: 'settings-page__contact-support', label: 'Contact', onPress: showContactSupportModal }}
            />
            <EditEcoCartSettingsForm merchantAdmin={merchantAdmin as MerchantAdmin} onSuccess={updateSessionMerchantAdmin} />
          </View>
        </EcoTabContent>
      </EcoTabs>
    </EcoScrollView>
  );
}
