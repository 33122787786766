import { Coordinates } from '@ecocart/entities';
import { GoogleMap, InfoWindow, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { MarkerConfig } from '@screens/ProjectsScreen';
import { PUBLIC_IMG_URL } from '@utils/constants/config';
import { Gap } from '@utils/layout';
import React, { useCallback, useState } from 'react';
import { StyleProp, View } from 'react-native';
import { EcoImage } from './EcoImage';
import { EcoText } from './EcoText';

interface Props {
  center?: Coordinates;
  markers?: MarkerConfig[];
  height?: number;
  width?: number;
  style?: StyleProp<any> | any;
}

const mapOptions = {
  styles: []
};

const markerOptions: google.maps.MarkerOptions = {
  icon: PUBLIC_IMG_URL + '/location-marker.svg',
  clickable: true,
  visible: true
};

export function EcoMap({ center, markers, height = 400, width = 400 }: Props): JSX.Element {
  const [activeMarkerIndex, setActiveMarkerIndex] = useState<number | null>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const [zoom, setZoom] = useState<number>(markers?.length === 1 ? 10 : 1.5);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBZJHa4n2C_1LK8W9Itu8ZJqUkr4vNuZFw'
  });

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: google.maps.Map) {
    setMap(null as any);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ height: `${height}px`, width: `${width}px` }}
      options={mapOptions}
      center={center}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {(markers || []).map((marker, i) => (
        <MarkerF
          key={marker.title}
          onClick={marker.onPress}
          onMouseOver={() => setActiveMarkerIndex(i)}
          onMouseOut={() => setActiveMarkerIndex(null)}
          position={marker.coordinates}
          options={markerOptions}
        >
          {activeMarkerIndex === i ? (
            <InfoWindow position={marker.coordinates} options={{ minWidth: 250 }} onCloseClick={() => setActiveMarkerIndex(null)}>
              <View style={Gap(2)} className="justify-center items-center">
                {marker.img ? <EcoImage src={marker.img} width={220} height={60} /> : null}
                <EcoText fontSize="2xl" fontWeight="semibold" textAlign="center">
                  {marker.title}
                </EcoText>
                <EcoText fontSize="2xs" textAlign="center">
                  {marker.title}
                </EcoText>
              </View>
            </InfoWindow>
          ) : null}
        </MarkerF>
      ))}
    </GoogleMap>
  ) : (
    <></>
  );
}
