import { EcoIFrame } from '@components/shared';
import { useGlobal } from './useGlobal';
import { useOverlay } from './useOverlay';

interface FormFieldValues {
  firstname?: string;
  lastname?: string;
  email?: string;
  shopify_url?: string;
  support_form_submission?: string;
}

export const useContactSupport = (): {
  showContactSupportModal: (values?: FormFieldValues) => void;
} => {
  const { session } = useGlobal();
  const { showModal } = useOverlay();

  const email = session?.user.type === 'SHOPIFY_USER' ? session?.merchantAdmin?.contactEmail : session?.user.id;
  const defaultValues: FormFieldValues = {
    firstname: session?.user.firstName,
    lastname: session?.user.lastName,
    email,
    shopify_url: session?.merchantAdmin?.shopName,
    support_form_submission: ''
  };

  const showContactSupportModal = (values?: FormFieldValues): void => {
    const queryParams = new URLSearchParams({ ...defaultValues, ...values });
    const hubspotUrl = `https://share.hsforms.com/1IcBhfXKvTtyLvw4HhypdKA4knn4?${queryParams}`;

    showModal({
      nativeID: 'contact-support-modal',
      content: (
        <EcoIFrame
          href={hubspotUrl}
          style={{ height: 660, width: 400, overflow: 'hidden' }}
          iFrameStyle={{ position: 'absolute', top: -30 }}
        />
      )
    });
  };

  return { showContactSupportModal };
};
