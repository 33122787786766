import CognitoIdentityServiceProvider, {
  ConfirmForgotPasswordRequest,
  InitiateAuthRequest,
  InitiateAuthResponse
} from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { StorageKey, storage } from '../storage';
import { MINUTE } from '../time';
import { COGNITO, USER_MGMT_API, apiCall } from './api';

export interface LoginArgs {
  email: string;
  password: string;
}

const LOGIN_RESULTS = [
  'VALID_CREDENTIALS',
  'INVALID_CREDENTIALS',
  'EXPIRED_CREDENTIALS',
  'PASSWORD_ATTEMPTS_EXCEEDED',
  'NEW_PASSWORD_REQUIRED'
] as const;
export type LoginResult = (typeof LOGIN_RESULTS)[number];

export interface AddlLoginData {
  email: string;
  responseData: Partial<InitiateAuthResponse>;
}

let _cognitoIdentityServiceProvider: CognitoIdentityServiceProvider;
const cognitoIdentityServiceProvider = () => {
  if (!_cognitoIdentityServiceProvider) _cognitoIdentityServiceProvider = new CognitoIdentityServiceProvider({ region: COGNITO.region });
  return _cognitoIdentityServiceProvider;
};

const storeTokens = async (jwt: string, refresh: string, expireTime: number, accessToken: string): Promise<void> => {
  if (refresh) await storage.setItem(StorageKey.cognito_refresh, refresh);
  if (expireTime) await storage.setItem(StorageKey.cognito_expire, expireTime);
  if (jwt) await storage.setItem(StorageKey.cognito_jwt, jwt);
  if (accessToken) await storage.setItem(StorageKey.cognito_access_token, accessToken);
};

export const refreshToken = async (): Promise<{ cognitoJWT: string; result: LoginResult; addlData?: AddlLoginData }> => {
  const expireTime = await storage.getItem(StorageKey.cognito_expire);
  const cognitoJWT = await storage.getItem(StorageKey.cognito_jwt);
  const refresh = await storage.getItem(StorageKey.cognito_refresh);
  const FIVE_MINUES = MINUTE * 5;

  return new Promise((resolve, reject) => {
    // GIVE 5 MINUTES OF BUFFER BEFORE EOL
    if (Date.now() < expireTime - FIVE_MINUES) {
      resolve({ result: 'VALID_CREDENTIALS', cognitoJWT });
    } else {
      try {
        const authParams: InitiateAuthRequest = {
          AuthFlow: 'REFRESH_TOKEN_AUTH',
          ClientId: COGNITO.clientId,
          AuthParameters: {
            REFRESH_TOKEN: refresh
          }
        };
        cognitoIdentityServiceProvider().initiateAuth(authParams, (error, data) => {
          if (error) {
            reject({ result: JSON.stringify(error) });
          } else {
            const cognitoJWT = data.AuthenticationResult?.IdToken as string;
            const refreshToken = data.AuthenticationResult?.RefreshToken as string;
            const accessToken = data.AuthenticationResult?.AccessToken as string;
            const expireTime = Date.now() + (data.AuthenticationResult?.ExpiresIn as number) * 1000;

            storeTokens(cognitoJWT, refreshToken, expireTime, accessToken).then(() => {
              resolve({ result: 'VALID_CREDENTIALS', cognitoJWT });
            });
          }
        });
      } catch (error: any) {
        reject({ result: JSON.stringify(error) });
      }
    }
  });
};

export const signIn = async ({
  email,
  password
}: LoginArgs): Promise<{ cognitoJWT: string; result: LoginResult; addlData?: AddlLoginData }> => {
  return new Promise((resolve, reject) => {
    try {
      const authParams = {
        AuthFlow: 'USER_PASSWORD_AUTH',
        ClientId: COGNITO.clientId,
        AnalyticsMetadata: {
          AnalyticsEndpointId: email.trim()
        },
        AuthParameters: {
          USERNAME: email.trim(),
          PASSWORD: password.trim()
        }
      };

      cognitoIdentityServiceProvider().initiateAuth(authParams, (err, data) => {
        if (err) {
          if (err.name === 'PasswordResetRequiredException') {
            //this will happen if a user resets their password and then tries to login like normal... not sure how you want to handle that @nate @elvis -mw 22-11-2022
          }

          reject({ result: 'INVALID_CREDENTIALS' });
        } else {
          if (data.ChallengeName && data.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
            resolve({
              result: 'NEW_PASSWORD_REQUIRED',
              cognitoJWT: '',
              addlData: { email: email, responseData: data }
            });
          }

          const cognitoJWT = data.AuthenticationResult?.IdToken as string;
          const refreshToken = data.AuthenticationResult?.RefreshToken as string;
          const expireTime = Date.now() + (data.AuthenticationResult?.ExpiresIn as number) * 1000;
          const accessToken = data.AuthenticationResult?.AccessToken as string;

          storeTokens(cognitoJWT, refreshToken, expireTime, accessToken).then(() => {
            resolve({ result: 'VALID_CREDENTIALS', cognitoJWT });
          });
        }
      });
    } catch (error: any) {
      switch (error.message.toLowerCase()) {
        case 'password attempts exceeded':
          reject({ result: 'PASSWORD_ATTEMPTS_EXCEEDED' });
          break;
        case 'temporary password has expired and must be reset by an administrator.':
          reject({ result: 'EXPIRED_CREDENTIALS' });
          break;
        default:
          reject({ result: 'INVALID_CREDENTIALS' });
          break;
      }
      reject({ result: JSON.stringify(error) });
    }
  });
};

export const forgotPassword = (email: string): Promise<any> => {
  return apiCall<any>('GET', `${USER_MGMT_API}/user/reset/${email}`);
};

export const updateTempPassword = (newPassword: string, addlData: AddlLoginData): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (newPassword) {
      cognitoIdentityServiceProvider().respondToAuthChallenge(
        {
          AnalyticsMetadata: {
            AnalyticsEndpointId: addlData.email
          },
          ChallengeName: addlData.responseData.ChallengeName as string,
          ChallengeResponses: {
            NEW_PASSWORD: newPassword,
            USERNAME: addlData.email
          },
          ClientId: COGNITO.clientId,
          Session: addlData.responseData.Session
        },
        (err, data) => {
          if (err) {
            reject({ result: JSON.stringify(err) });
          } else {
            resolve(newPassword);
          }
        }
      );
    } else {
      reject({ isUpdated: false, error: 'User cancelled prompt' });
    }
  });
};

export const resetPassword = (email: string, verificationCode: string, newPassword: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const resetParams: ConfirmForgotPasswordRequest = {
      ClientId: COGNITO.clientId,
      ConfirmationCode: verificationCode,
      Password: newPassword,
      Username: email,
      AnalyticsMetadata: {
        AnalyticsEndpointId: email
      }
    };

    cognitoIdentityServiceProvider().confirmForgotPassword(resetParams, (err, data) => {
      if (err?.message) {
        reject(err.message);
      } else {
        resolve(data);
      }
    });
  });
};
