import * as DocumentPicker from 'expo-document-picker';
import { omit } from 'lodash';
import { ButtonSize, EcoButton, EcoButtonProps } from './EcoButton';
import { ButtonVariant } from './EcoButton.utils';

// https://en.wikipedia.org/wiki/Media_type
export const MIME_TYPES: Record<string, string> = {
  // TODO incorporate commented out types to file uploader
  all: '*/*',
  // pdf: 'application/pdf',
  jpeg: 'image/jpeg',
  png: 'image/png'
};

interface EcoFilePickerProps extends Omit<EcoButtonProps, 'onPress'> {
  label?: string;
  type?: keyof typeof MIME_TYPES | (keyof typeof MIME_TYPES)[];
  multiple?: boolean;
  onPick: (result: DocumentPicker.DocumentResult) => void;
  variant?: ButtonVariant;
  onError?: (error: any) => void;
  size?: ButtonSize;
}

export function EcoFilePicker({
  variant = 'outlined',
  label = 'Choose File',
  type = 'all',
  multiple = false,
  onPick,
  onError = () => {},
  size = 'md',
  ...rest
}: EcoFilePickerProps): JSX.Element {
  const handlePress = async () => {
    const mimeType = Array.isArray(type) ? type.map((t) => MIME_TYPES[t]) : MIME_TYPES[type];

    try {
      const result = await DocumentPicker.getDocumentAsync({ type: mimeType, multiple });
      onPick(result);
    } catch (err) {
      onError(err);
      console.log('FilePicker caught error on picking file', err);
    }
  };

  return (
    <EcoButton size={size} variant={variant} leftIcon="cloud_upload" onPress={handlePress} {...omit(rest, 'onPress')}>
      {label}
    </EcoButton>
  );
}
