import { EcoButton, EcoInput, EcoLoader, EcoText, FormPicker } from '@components/shared';
import { MerchantAdmin } from '@ecocart/entities';
import { useGlobal } from '@hooks/useGlobal';
import { useOverlay } from '@hooks/useOverlay';
import { useWindow } from '@hooks/useWindow';
import { updateMerchantAdmin } from '@utils/api/merchant';
import { sendMerchantAdminUpdateMessage } from '@utils/api/slack';
import { US_STATES } from '@utils/constants/geography';
import { Gap } from '@utils/layout';
import { GlobalFormProps } from '@utils/prop-types';
import clsx from 'clsx';
import { Formik } from 'formik';
import { useState } from 'react';
import { View } from 'react-native';

interface Props extends GlobalFormProps {
  merchantAdmin: MerchantAdmin;
}

export default function EditStoreDetailsForm({ merchantAdmin, onSuccess }: Props): JSX.Element {
  const { session } = useGlobal();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { handleError, showSuccess } = useOverlay();
  const { isWide } = useWindow();

  const onSubmit = async (_merchantAdmin: MerchantAdmin) => {
    setIsSubmitting(true);

    try {
      const response = await updateMerchantAdmin(_merchantAdmin.shopName, _merchantAdmin);

      showSuccess('Successfully updated store details');
      setIsSubmitting(false);
      onSuccess && onSuccess(response);

      await sendMerchantAdminUpdateMessage('merchant_settings_change', session, {
        merchantAdmin: _merchantAdmin,
        dbMerchantAdmin: merchantAdmin
      });
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  return merchantAdmin ? (
    <Formik initialValues={merchantAdmin} onSubmit={onSubmit}>
      {({ handleChange, handleSubmit, values, isValid }) => (
        <View className="max-w-xl" style={Gap(7)}>
          <View>
            <EcoText fontSize="2xl" fontWeight="semibold" className="pb-2">
              Store Information
            </EcoText>
            <View style={Gap(2)}>
              <EcoInput disabled={true} label="Shop Name" value={values.shopName} placeholder="Shop Name" />
              <EcoInput
                label="Company Name"
                value={values.companyName}
                onChangeText={handleChange('companyName')}
                placeholder="Company Name"
              />
            </View>
            {/* <FormPicker label="Locale" items={LOCALES as any} value={values.locale} onValueChange={handleChange('locale')} /> */}
          </View>
          <View className="w-full h-[1px] bg-black/[.1]" />
          <View>
            <EcoText fontSize="2xl" fontWeight="semibold" className="pb-2">
              Mailing Address
            </EcoText>
            <View style={Gap(2)}>
              <EcoInput
                label="Address 1"
                value={values.billingAddress?.address1}
                dataDetectorTypes="address"
                placeholder="Address 1"
                onChangeText={handleChange('billingAddress.address1')}
              />
              <EcoInput
                label="Address 2"
                value={values.billingAddress?.address2}
                dataDetectorTypes="address"
                placeholder="Address 2"
                onChangeText={handleChange('billingAddress.address2')}
              />
              <View className={clsx('justify-between', isWide ? 'flex-row' : 'flex-col')} style={Gap(isWide ? 3 : 0)}>
                <EcoInput
                  className="flex-1"
                  label="City"
                  textContentType="addressCity"
                  value={values.billingAddress?.city}
                  placeholder="City"
                  onChangeText={handleChange('billingAddress.city')}
                />
                <FormPicker
                  className="flex-1"
                  label="State/Province"
                  items={US_STATES as any}
                  value={values.billingAddress?.province}
                  onValueChange={handleChange('billingAddress.province')}
                />
              </View>
              <EcoInput
                style={{ maxWidth: '150px' }}
                textContentType="postalCode"
                label="Zip Code"
                value={values.billingAddress?.zip}
                placeholder="Zip Code"
                onChangeText={handleChange('billingAddress.zip')}
              />
            </View>
          </View>
          <View className="w-max">
            <EcoButton
              nativeID="store-details-page__save-button"
              isDisabled={!isValid || isSubmitting}
              isLoading={isSubmitting}
              onPress={handleSubmit}
            >
              Save Changes
            </EcoButton>
          </View>
        </View>
      )}
    </Formik>
  ) : (
    <EcoLoader size="large" />
  );
}
