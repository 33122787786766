import { EcoButton, EcoCard, EcoInput } from '@components/shared';
import { useGlobal } from '@hooks/useGlobal';
import { useOverlay } from '@hooks/useOverlay';
import { Gap } from '@utils/layout';
import { GlobalFormProps } from '@utils/prop-types';
import { CreateValidationSchema } from '@utils/validation';
import { Formik, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { View } from 'react-native';
import { EcoForm } from '../shared/EcoForm';

import UpdateTempPasswordForm from './UpdateTempPasswordForm';
interface Props extends GlobalFormProps {
  email?: string;
}

type Values = { email: string; password: string };

export default function LoginForm({ email: _email }: Props): JSX.Element {
  const { login } = useGlobal();
  const { showModal, hideModal } = useOverlay();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const formikRef = useRef<FormikProps<Values> | any>();

  const onSubmit = async ({ email, password }: any) => {
    setIsSubmitting(true);

    try {
      const { result, addlData } = await login({ email, password });

      if (result === 'NEW_PASSWORD_REQUIRED' && addlData) {
        showModal({
          content: (
            <EcoCard title="Update Password">
              <UpdateTempPasswordForm
                email={email}
                addlData={addlData}
                onSuccess={(newPassword: string) => {
                  onSubmit({ email, password: newPassword });
                }}
              />
            </EcoCard>
          )
        });
      } else {
        hideModal();
      }
    } catch (error: any) {
      formikRef.current?.setFieldError('password', 'Invalid credentials');
      // handleError(error);
      setIsSubmitting(false);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      validateOnMount
      initialValues={{
        email: _email || '',
        password: ''
      }}
      validationSchema={CreateValidationSchema(['email', 'password'])}
      onSubmit={onSubmit}
    >
      {({ handleChange, handleSubmit, handleBlur, isValid, values, errors, touched, submitForm }) => (
        <EcoForm onEnter={submitForm}>
          <View style={Gap(2)}>
            <EcoInput
              label="Email"
              value={values.email}
              onBlur={handleBlur('email')}
              onChangeText={handleChange('email')}
              placeholder="Email"
              autoComplete="email"
              keyboardType="email-address"
              errors={errors}
              touched={touched}
              field="email"
            />
            <EcoInput
              type="password"
              value={values.password}
              onBlur={handleBlur('password')}
              onChangeText={handleChange('password')}
              errors={errors}
              touched={touched}
              field="password"
            />
          </View>
          <EcoButton
            nativeID="login-form__login-button"
            className="mt-2"
            fullWidth
            isDisabled={!isValid || isSubmitting}
            isLoading={isSubmitting}
            onPress={handleSubmit}
          >
            Log In
          </EcoButton>
        </EcoForm>
      )}
    </Formik>
  );
}
