import { useWindow } from '@hooks/useWindow';
import { Gap } from '@utils/layout';
import { GlobalStylesProps } from '@utils/prop-types';
import { BoxShadow } from '@utils/styles/box-shadow';
import clsx from 'clsx';
import { kebabCase } from 'lodash';
import { Pressable, StyleProp, View } from 'react-native';
import { twMerge } from 'tailwind-merge';
import { EcoCard } from './EcoCard';
import { EcoIcon } from './EcoIcon';
import { EcoRadioButtonItem } from './EcoRadioButtons.utils';
import { EcoText } from './EcoText';

interface EcoRadioButtonCardsProps extends GlobalStylesProps {
  items: EcoRadioButtonItem[];
  value: string;
  disabled?: boolean;
  direction?: 'auto' | 'vertical';
  dotPosition?: 'left' | 'bottom' | 'none';
  style?: StyleProp<any>;
  itemStyle?: StyleProp<any>;
  onValueChange: (value: string) => void;
  cardClassName?: string;
  iconPosition?: 'top' | 'left';
}

export function EcoRadioButtonCards({
  items,
  value,
  style,
  itemStyle,
  dotPosition = 'none',
  disabled = false,
  onValueChange,
  direction = 'auto',
  cardClassName,
  iconPosition = 'top'
}: EcoRadioButtonCardsProps): JSX.Element {
  const { isWide } = useWindow();

  return (
    <View
      accessibilityRole="radiogroup"
      className={clsx(isWide ? 'flex-row' : 'flex-col', 'justify-start', direction === 'vertical' && 'flex-col')}
      style={[Gap(5), style]}
    >
      {(items || []).map((item: any, i: number) => (
        <Pressable
          className={clsx('flex flex-row flex-1', (disabled || item.disabled) && 'opacity-50')}
          nativeID={`radio__${kebabCase(item.value)}`}
          accessibilityRole="radio"
          disabled={disabled || item.disabled}
          key={i}
          onPress={() => onValueChange(item.value)}
          style={itemStyle}
        >
          <EcoCard
            wrapperClassName={twMerge(
              'min-h-[130px] border border-2 p-6',
              value === item.value
                ? disabled || item.disabled
                  ? 'border-gray-400'
                  : 'border-secondary-500 bg-gray-50'
                : 'border-black/[.1]',
              cardClassName
            )}
            style={BoxShadow('none')}
          >
            <View className={clsx((dotPosition === 'left' || iconPosition === 'left') && 'flex-row flex-start')} style={Gap(2)}>
              {dotPosition === 'left' ? (
                <EcoIcon name="circle" size="sm" className={value === item.value ? 'bg-primary' : 'bg-transparent'} />
              ) : null}

              {item.iconName ? <EcoIcon name={item.iconName} className="mb-4 text-black" /> : null}
              <View style={Gap(2)} className="flex-1">
                <EcoText fontWeight="semibold">{item.label}</EcoText>
                {item.description ? <EcoText fontSize="sm">{item.description}</EcoText> : null}
              </View>

              {dotPosition === 'bottom' ? (
                <EcoIcon name="circle" size="sm" className={value === item.value ? 'bg-primary' : 'bg-transparent'} />
              ) : null}
            </View>
          </EcoCard>
        </Pressable>
      ))}
    </View>
  );
}
