import { REWARD_DESCRIPTIONS } from '@components/rewards/RewardsRedemption';
import { EcoButton, EcoCard, EcoImage, EcoInput, EcoText } from '@components/shared';
import { REDEMPTION_NAMES_MAP, RedemptionType } from '@ecocart/entities';
import { formatNumber } from '@ecocart/universal-utils';
import { useGlobal } from '@hooks/useGlobal';
import { useOverlay } from '@hooks/useOverlay';
import { useRewards } from '@hooks/useRewards';
import { useRewardsModal } from '@hooks/useRewardsModal';
import { useMutation } from '@tanstack/react-query';
import { redeemRewards, sendRedemptionConfirmationEmail } from '@utils/api/rewards';
import { sendRewardRedemptionRequestMessage } from '@utils/api/slack';
import { PUBLIC_IMG_URL } from '@utils/constants/config';
import { Gap } from '@utils/layout';
import { useFormik } from 'formik';
import { isEmpty, lowerCase, startCase } from 'lodash';
import { View } from 'react-native';
import * as Yup from 'yup';

interface Props {
  redemptionType: RedemptionType;
}

export const RewardRedemptionForm = ({ redemptionType }: Props): JSX.Element => {
  const { session } = useGlobal();
  const shopName = session?.merchantAdmin?.shopName || '';
  const isEcoCartAdmin = session?.user.userType === 'ecocart_admin';
  const { redeemableAmount, refetch } = useRewards(shopName);
  const { showSuccess, showWarning } = useOverlay();
  const { hideRewardsFormModal } = useRewardsModal();

  const { mutate, isLoading: isSubmitting } = useMutation(redeemRewards, {
    onSuccess: () => {
      showSuccess('Your rewards redemption request has been submitted successfully');
      refetch();
      // Send email confirmation
      const email = session?.user.type === 'SHOPIFY_USER' ? session.merchantAdmin?.contactEmail : session?.user.id;
      if (!session?.user) {
        throw new Error('User not found');
      }
      if (!email) {
        throw new Error('Email contact not found');
      }

      const redemptionName = REDEMPTION_NAMES_MAP[redemptionType];
      const redemptionAmount = formatNumber(Number(values.amount), { style: 'currency', currency: 'USD' });

      sendRedemptionConfirmationEmail({
        fullName: session.user.firstName + ' ' + session.user.lastName,
        email,
        redemptionName,
        redemptionAmount
      });
      sendRewardRedemptionRequestMessage({ session, redemptionName, redemptionAmount });
    },
    onError: (error: Error) => {
      showWarning(`An error occurred while submitting your request. ${error.message}`);
    },
    onSettled: () => {
      hideRewardsFormModal();
    }
  });

  const onSubmit = () => {
    if (!session?.merchantAdmin || !session?.user) return;
    mutate({
      amount_usd: Number(values.amount),
      redemption_type: redemptionType,
      shop_name: session.merchantAdmin.shopName,
      created_by: {
        id: session.user.id,
        first_name: session.user.firstName,
        last_name: session.user.lastName
      }
    });
  };

  const formattedRedeemableAmount = formatNumber(redeemableAmount, { style: 'currency', currency: 'USD' });

  const formik = useFormik({
    initialValues: { amount: '' },
    onSubmit,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      amount: Yup.number()
        .typeError('Amount must be a number.')
        .min(1, 'Minimum redemption amount is $1.00')
        .max(redeemableAmount, 'Please enter an amount less than ' + formattedRedeemableAmount)
        .required('Required')
    })
  });
  const { handleSubmit, handleChange, handleBlur, errors, touched, values } = formik;

  const getLogoUrl = () => {
    switch (redemptionType) {
      case 'tapcart':
        return '/tapcart.svg';
      case 'klaviyo':
        return '/klaviyo.svg';
      case 'treet':
        return '/treet.png';
      case 'maergo':
        return '/maergo.svg';
    }
  };

  const getSize = () => {
    switch (redemptionType) {
      case 'tapcart':
        return { width: 120, height: 28 };
      case 'klaviyo':
        return { width: 91, height: 28 };
      case 'treet':
        return { width: 88, height: 24 };
      case 'maergo':
        return { width: 103, height: 24 };
    }
  };

  const size = getSize();

  const shouldHideDescription = ['tapcart', 'klaviyo', 'treet', 'maergo'].includes(redemptionType);

  // EcoCart Admins should not see the redemption form / submit redemption requests
  if (isEcoCartAdmin) return <></>;

  return (
    <EcoCard className="p-6" size="lg" titleSize="2xl" title="Redeem Your Rewards">
      <View className="py-4" style={Gap(2)}>
        <EcoText fontSize="sm">Selected Option</EcoText>
        {getLogoUrl() ? <EcoImage src={PUBLIC_IMG_URL + getLogoUrl()} width={size?.width} height={size?.height} /> : <></>}
        <View style={Gap(1)}>
          <EcoText fontSize="base" fontWeight="medium">
            {startCase(lowerCase(redemptionType))}
          </EcoText>
          {!shouldHideDescription && (
            <EcoText fontSize="sm" color="subdued">
              {REWARD_DESCRIPTIONS[redemptionType]}
            </EcoText>
          )}
        </View>
        <View style={Gap(1)} className="mt-4 mb-2">
          <EcoText fontSize="xs" color="subdued">
            Available Balance
          </EcoText>
          <EcoText fontSize="base">{formattedRedeemableAmount}</EcoText>
        </View>
        <form onSubmit={handleSubmit}>
          <View style={Gap(6)}>
            <EcoInput
              startAdornment="$"
              type="currency"
              label="Enter Amount To Redeem"
              value={values.amount}
              onChangeText={(value) => {
                handleChange('amount')(value);
              }}
              keyboardType="numeric"
              errors={errors}
              touched={touched}
              onBlur={handleBlur('amount')}
              field="amount"
            />
            <View className="flex-row justify-end" style={Gap()}>
              <EcoButton onPress={hideRewardsFormModal} variant="ghost" size="sm">
                Close
              </EcoButton>
              <EcoButton size="sm" isDisabled={!values.amount || !isEmpty(errors)} isLoading={isSubmitting} onPress={handleSubmit}>
                Submit
              </EcoButton>
            </View>
          </View>
        </form>
      </View>
    </EcoCard>
  );
};
