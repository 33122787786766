import { Calculation, Merchant, WidgetUI } from '@ecocart/entities';
import { encodeString, ShopifyCart } from '@ecocart/universal-utils';
import usePrevious from '@hooks/usePrevious';
import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';
import { View } from 'react-native';

interface Props {
  widget: WidgetUI;
  merchant: Merchant;
  calculation?: Calculation;
  cart?: ShopifyCart;
}

export function EcoWidget({ widget, merchant, cart, calculation }: Props): JSX.Element {
  const prevWidget = usePrevious(widget);
  const ref = useRef<View>(null);

  useEffect(() => {
    if (!ref?.current || isEqual(prevWidget, widget)) return;

    // set them as variable and then put it inside innerHTML so that it doesn't accidentally combine with the other attribute
    const cartProp = cart ? `cart=${encodeString(cart)}` : '';
    const calculationProp = calculation ? `calculation=${encodeString(calculation)}` : '';

    (ref.current as unknown as HTMLDivElement).innerHTML = `<eco-widget widget=${encodeString(widget)} merchant=${encodeString(
      merchant
    )} ${cartProp} ${calculationProp} />`;
  }, [prevWidget, widget, cart, merchant, calculation]);

  return <View className="max-w-full" ref={ref} />;
}
