import clsx from 'clsx';
import { StyleProp, View } from 'react-native';

interface Props {
  activeStep: number;
  stepCount: number;
  style?: StyleProp<any>;
}

export function EcoSteps({ stepCount = 2, activeStep = 1, style }: Props): JSX.Element {
  const arr = [...Array(stepCount).keys()];

  return (
    <View className="flex-row justify-start" style={style}>
      {arr.map((i) => (
        <View key={`step_${i}`} className={clsx('mx-3 h-[7px] w-[120px]', activeStep === i + 1 ? 'bg-primary-300' : 'bg-gray-600')} />
      ))}
    </View>
  );
}
