import { FontSize } from '@utils/tailwind';
import clsx from 'clsx';
import { StyleProp, View } from 'react-native';
import { IconName } from './EcoIcon.utils';
import { EcoText } from './EcoText';

export const AVATAR_SIZES = ['sm', 'md', 'lg', 'xl'];
export type AvatarSize = (typeof AVATAR_SIZES)[number];
export type AvatarStatus = 'online' | 'offline' | 'update' | 'info';
export type StatusPosition = 'topRight' | 'bottomRight' | 'topLeft' | 'bottomLeft';

interface AvatarProps {
  /** Use in 'alt' tag if avatar image is provided, or as initials*/
  name?: string;
  src?: string;
  size?: AvatarSize;
  status?: AvatarStatus;
  statusPosition?: StatusPosition;
  fallbackIconName?: IconName;
  style?: StyleProp<any>;
  className?: string;
}

const avatarSizeMap: Record<AvatarSize, string> = {
  sm: 'w-5 h-5',
  md: 'w-6 h-6',
  lg: 'w-7 h-7',
  xl: 'w-8 h-8'
};

function getInitials(name: string) {
  const nameArr = name.split(' ');
  if (nameArr.length === 1) return name.charAt(0).toUpperCase();
  const firstInitial = nameArr[0].charAt(0);
  const lastInitial = nameArr.at(-1)?.charAt(0);
  return `${firstInitial}${lastInitial}`.toUpperCase();
}

export function EcoAvatar({ name = '', size = 'lg', style }: AvatarProps): JSX.Element {
  return (
    <View className={clsx('bg-primary items-center justify-center rounded-full border-primary', avatarSizeMap[size])} style={style}>
      <EcoText color="enabled-inverted" fontSize={size as Partial<FontSize>}>
        {getInitials(name)}
      </EcoText>
    </View>
  );
}
