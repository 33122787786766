import AsyncStorage from '@react-native-async-storage/async-storage';
import Storage from 'react-native-storage';

export enum StorageKey {
  nav_state = 'navstate',
  cognito_jwt = 'cognitojwt',
  cognito_refresh = 'cognitorefresh',
  cognito_expire = 'cognitoexpire',
  cognito_access_token = 'cognitoaccesstoken',
  shpat = 'shpat',
  translations = 'translations',
  login_redirect = 'loginredirect',
  last_active_shop_name = 'lastactiveshopname'
}

const _storage = new Storage({
  // Use AsyncStorage for RN apps, or window.localStorage for web apps.
  // If storageBackend is not set, data will be lost after reload.
  storageBackend: AsyncStorage, // for web: window.localStorage
  size: 100, // maximum capacity, default 100 key-ids
  defaultExpires: null, // expire time, default: 1 day (1000 * 3600 * 24 milliseconds) = can be null, which means never expire.
  enableCache: true, // cache data in the memory. default is true.
  // if data was not found in storage or expired data was found,
  // the corresponding sync method will be invoked returning
  // the latest data.
  sync: {} // we'll talk about the details later.
});

export const storage = {
  getItem: (key: StorageKey): Promise<any> => {
    return new Promise((resolve) => {
      _storage
        .load({ key })
        .then((res) => resolve(res))
        .catch(() => resolve(null));
    });
  },
  setItem: (key: StorageKey, value: unknown): Promise<void> => {
    return new Promise((resolve) => {
      _storage
        .save({ key, data: value })
        .then((res) => resolve(res))
        .catch(() => resolve());
    });
  },
  removeItem: (key: StorageKey): Promise<any> => {
    return new Promise((resolve) => {
      _storage
        .remove({ key })
        .then((res) => resolve(res))
        .catch(() => resolve(null));
    });
  }
};
