import { Merchant, Widget } from '@ecocart/entities';
import { IMAGE_ASSETS } from '@ecocart/universal-utils';
import { cloneDeep, get, set } from 'lodash';
import { fieldPaths } from './utils';

export const getInitialValues = (merchant?: Merchant): Widget => {
  const widget = new Widget();
  widget.name = 'Checkout Widget';
  widget.template = 'checkout_widget';
  widget.elements = {
    ...widget.elements,
    wrapper: { styles: { display: undefined, justifyContent: undefined } },
    logo: { custom: { url: IMAGE_ASSETS.ECOCART } }
  };

  return normalizeInitialValues(widget, merchant);
};

const normalizeInitialValues = (initialValues: Widget, merchant?: Merchant): Widget => {
  if (!merchant) return initialValues;

  const _values = cloneDeep(initialValues);

  if (get(_values, fieldPaths.impactBoostDisabled) === undefined) {
    set(_values, fieldPaths.impactBoostDisabled, true);
  }

  return _values;
};

// To get only the literal values as the type
export type Theme = 'light' | 'dark';
export type CheckMarkColor = 'white' | 'black';
export type CheckboxPosition = 'left' | 'right';
export type ImpactBoostActiveFontColor = 'white' | 'black';
