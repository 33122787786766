import { storage, StorageKey } from '@utils/storage';
import { ClaimsData, ClaimSubmissionResponse, ClaimValidationRequest, ClaimValidationSuccessResponse } from '@utils/types/claims';
import { apiCall, CLAIMS_API } from './api';

export const validateClaim = (claim: ClaimValidationRequest): Promise<ClaimValidationSuccessResponse> => {
  return apiCall<ClaimValidationSuccessResponse>('POST', `${CLAIMS_API}/claims/validate`, claim);
};

export const submitClaim = (claim: FormData): Promise<{ data: ClaimSubmissionResponse }> => {
  return apiCall<{ data: ClaimSubmissionResponse }>('POST', `${CLAIMS_API}/claims`, claim, {
    'Content-Type': 'multipart/form-data'
  });
};

export const getClaims = async (shopName: string, query?: Record<string, string | number | string[]>): Promise<ClaimsData> => {
  if (!shopName) return new Promise((resolve) => resolve(undefined as any));

  const shpat = await storage.getItem(StorageKey.shpat);
  const headers = shpat ? { 'X-Eco-Shopify-Access-Token': shpat } : ({} as any);

  const queryParams = new URLSearchParams();

  if (query) {
    Object.entries(query).forEach(([key, value]) => {
      if (key === 'status' && Array.isArray(value)) {
        // For status, join multiple values with commas
        queryParams.append(key, value.join(','));
      } else if (Array.isArray(value)) {
        // For other array values, append each value separately
        value.forEach((v) => queryParams.append(key, v));
      } else if (value !== undefined && value !== null) {
        // For single values, just append them as is
        queryParams.append(key, value);
      }
    });
  }

  const url = queryParams.toString() ? `${CLAIMS_API}/si/claims/${shopName}?${queryParams.toString()}` : `${CLAIMS_API}/claims/${shopName}`;
  return apiCall<ClaimsData>('GET', url, null, headers);
};
