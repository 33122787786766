import { Merchant, Project, User } from '@ecocart/entities';
import { Filter } from '@ecocart/universal-utils';
import { GlobalStylesProps } from '@utils/prop-types';
import { FlexAlignType } from 'react-native';
import { EcoPickerItem } from './EcoPicker';

export type TableItemType = 'text' | 'checkbox' | 'link' | 'widget' | 'toggle';

export interface ColumnConfig<T = Record<string, any>> extends GlobalStylesProps {
  /** 'more' is for the action dropdown menu */
  field: Partial<keyof T> | 'more';
  /** Determines whether the field is searchable */
  searchable?: boolean;
  /**
   * Used specific for the fuzzy search, specifying the path to the key that holds the data.
   * Normally you don't need this and the data is just the `field` name. But sometimes the data is nested a few levels deep in the data object.
   **/
  dataKey?: string;
  width?: string;
  title?: string;
  align?: FlexAlignType;
  type?: TableItemType;
  format?: (item: T) => any;
  onPress?: (item: T, value?: boolean) => Promise<void> | void;
  flex?: number;
  disabled?: boolean;
}

export interface FilterConfig<T = Record<string, any>> extends Filter<T> {
  options: EcoPickerItem[];
  enabled: boolean;
}

export type Entity = Merchant | User | Project;

export const filterConfigToFilters = <T>(filterConfig: FilterConfig<T>[]): Filter<T>[] => {
  return filterConfig.filter(({ enabled }) => enabled).map(({ options, ...filter }) => ({ ...filter }));
};

export const evalDataQueryFilters = <T>(items: T[], filters: Filter<T>[]): T[] => {
  return items.filter((item) => {
    return filters.every(({ field, value, operator }) => {
      switch (operator) {
        case 'eq':
          return item[field] === value;
        case 'gt':
          return item[field] > value;
        case 'gte':
          return item[field] >= value;
        case 'lt':
          return item[field] < value;
        case 'lte':
          return item[field] <= value;
        default:
          return true;
      }
    });
  });
};
