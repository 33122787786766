import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { useState } from 'react';

type ReturnType = [
  { isFetching: boolean },
  (urls: string[], { filename, type }: { filename: string; type: 'csv' | 'pdf'; ids: string[] }) => Promise<void>
];

export const useFetchAndZipFiles = (): ReturnType => {
  const [isFetching, setIsFetching] = useState(false);

  const fetchAndZipFiles = async (
    fileUrls: string[],
    { filename, type = 'csv', ids }: { filename: string; type: 'csv' | 'pdf'; ids: string[] }
  ): Promise<void> => {
    if (!fileUrls || fileUrls.length === 0) return;

    const zip = new JSZip();

    const downloadPromises = fileUrls.map((url, index) => {
      setIsFetching(true);

      return fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const fileExtension = type === 'pdf' ? 'pdf' : 'csv';
          zip.file(`${ids[index]}.${fileExtension}`, blob);
        });
    });

    Promise.all(downloadPromises)
      .then(() => zip.generateAsync({ type: 'blob' }))
      .then((zipBlob) => {
        saveAs(zipBlob, `${filename}.zip`);
        setIsFetching(false);
      })
      .catch((error) => {
        console.error(`Error fetching or zipping ${type}s:`, error);
      });
  };

  return [{ isFetching }, fetchAndZipFiles];
};
