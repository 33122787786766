import { Gap } from '@utils/layout';
import clsx from 'clsx';
import { Pressable, StyleProp, View } from 'react-native';
import { EcoRadioButtonItem } from './EcoRadioButtons.utils';
import { EcoText } from './EcoText';

interface Props<T> {
  items: EcoRadioButtonItem[];
  value: T;
  disabled?: boolean;
  style?: StyleProp<any>;
  onValueChange: <T>(value: T) => void;
}

export function EcoRadioButtons({ items, value, style, disabled = false, onValueChange }: Props): JSX.Element {
  return (
    <View accessibilityRole="radiogroup" className="flex-row" style={[Gap(5), style]}>
      {(items || []).map((item: any, i: number) => (
        <Pressable
          className={clsx('flex flex-row', disabled && 'opacity-50')}
          accessibilityRole="radio"
          disabled={disabled}
          key={i}
          onPress={() => onValueChange(item.value)}
        >
          <View className="flex h-[20px] w-[20px] rounded-full border items-center justify-center">
            <View
              className={clsx(
                'h-[12px] w-[12px] rounded-full',
                item.value === value ? (disabled ? 'bg-gray-400' : 'bg-secondary-500') : 'bg-transparent'
              )}
            />
          </View>
          <EcoText className="ml-1">{item.label}</EcoText>
          {item.description ? <EcoText fontSize="sm">{item.description}</EcoText> : null}
        </Pressable>
      ))}
    </View>
  );
}
