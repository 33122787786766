import { ColorPalette } from '@components/design_system/ColorPalette';
import { DSAlert } from '@components/design_system/DSAlert';
import { DSAvatar } from '@components/design_system/DSAvatar';
import { DSButton } from '@components/design_system/DSButton';
import { DSFilePicker } from '@components/design_system/DSFilePicker';
import { DSEcoInput } from '@components/design_system/DSInput';
import { DSPill } from '@components/design_system/DSPill';
import { DSSkeleton } from '@components/design_system/DSSkeleton';
import { DSTabs } from '@components/design_system/DSTabs';
import { DSTooltip } from '@components/design_system/DSTooltip';
import { DSTypography } from '@components/design_system/DSTypography';
import { Icons } from '@components/design_system/Icons';
import { EcoButton, EcoTabContent, EcoTabs, EcoText } from '@components/shared';
import EcoScrollView from '@components/views/EcoScrollView';
import { GlobalScreenProps } from '@navigation/types';
import { InternalToolsTabs } from '@utils/constants/tabs';
import { Gap } from '@utils/layout';
import { useState } from 'react';
import { ScrollView, View } from 'react-native';

const DS_COMPONENTS = [
  'Colors',
  'Alert',
  'Avatar',
  'Button',
  'File Picker',
  'Icons',
  'Input',
  'Pill',
  'Skeleton',
  'Tabs',
  'Tooltip',
  'Typography'
] as const;
type DSComponent = (typeof DS_COMPONENTS)[number];

const componentMap: Record<DSComponent, JSX.Element> = {
  'Colors': <ColorPalette />,
  'Icons': <Icons />,
  'Alert': <DSAlert />,
  'Avatar': <DSAvatar />,
  'Button': <DSButton />,
  'Input': <DSEcoInput />,
  'Tooltip': <DSTooltip />,
  'Pill': <DSPill />,
  'Typography': <DSTypography />,
  'Tabs': <DSTabs />,
  'File Picker': <DSFilePicker />,
  'Skeleton': <DSSkeleton />
};

export default function DesignSystemScreen({ route }: GlobalScreenProps): JSX.Element {
  const [component, setComponent] = useState<DSComponent>(DS_COMPONENTS[0]);
  const isPublicPage = route.path === '/design-system';

  function DesignSystem(): JSX.Element {
    return (
      <View style={Gap(6)}>
        <ScrollView horizontal>
          <View className="flex-row" style={Gap(2)}>
            {DS_COMPONENTS.map((comp) => (
              <EcoButton size="sm" key={comp} variant={comp === component ? 'filled' : 'outlined'} onPress={() => setComponent(comp)}>
                {comp}
              </EcoButton>
            ))}
          </View>
        </ScrollView>
        <View>{componentMap[component]}</View>
      </View>
    );
  }

  return (
    <EcoScrollView>
      {isPublicPage ? (
        <View className="flex-col">
          <View className="pb-3 flex-row justify-start">
            <EcoText fontSize="4xl">EcoCart / </EcoText>
            <EcoText fontSize="4xl" fontWeight="semibold">
              Design System
            </EcoText>
          </View>
          <DesignSystem />
        </View>
      ) : (
        <EcoTabs tabs={InternalToolsTabs} prefix="Internal Tools" activeScreen="DesignSystem">
          <EcoTabContent>
            <DesignSystem />
          </EcoTabContent>
        </EcoTabs>
      )}
    </EcoScrollView>
  );
}
