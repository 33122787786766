import { getScreenNameFromRoutePath, RoutePath } from '@navigation/LinkingConfiguration';
import { useNavigation } from '@react-navigation/native';
import { nativeID } from '@utils/types/nativeID';
import clsx from 'clsx';
import * as React from 'react';
import { Linking, Pressable } from 'react-native';
import { EcoText } from './EcoText';

type Href = RoutePath | `https://${string}`;

interface Props {
  href: Href;
  params?: Record<string, string>;
  style?: any;
  isDisabled?: boolean;
  children?: React.ReactNode;
  nativeID?: nativeID;
  className?: string;
}

export const hrefIsExternal = (_href: string): boolean => {
  return _href.startsWith('https://') || _href.startsWith('http://') || _href.startsWith('mailto:');
};

export const getParsedPath = (path: Href, params?: Record<string, string>): Href => {
  const props = Object.keys(params ?? {});

  return props.reduce((acc, prop) => {
    acc = acc.replace(`:${prop}`, (params ?? {})[prop]);
    return acc;
  }, path) as RoutePath;
};

export function EcoLink({ nativeID, href, params, isDisabled = false, children, style }: Props): JSX.Element {
  const navigation = useNavigation();

  const handleOnPress = (): void => {
    if (isDisabled) return;

    const safeHref = getParsedPath(href, params);

    if (hrefIsExternal(safeHref)) {
      Linking.openURL(safeHref);
    } else {
      navigation.navigate(getScreenNameFromRoutePath(href as RoutePath), params);
    }
  };

  return (
    <Pressable
      nativeID={nativeID}
      accessibilityRole="link"
      onPress={handleOnPress}
      className={clsx('flex-row justify-center', isDisabled && 'cursor-default')}
      style={style}
    >
      {typeof children === 'string' ? (
        <EcoText color="link" className="no-underline">
          {children}
        </EcoText>
      ) : (
        children
      )}
    </Pressable>
  );
}
